import { observer } from 'mobx-react-lite';
import { EvidenceAttachmentStore } from '../mobx/evidence-attachment-store';
import {
  AttachmentTag,
  EvidenceTableAttachmentsLayout
} from '../components/evidence-table-attachments';
import { File } from '../../fileupload/types';
import {
  AttachmentIcon,
  Icon,
  IconSize,
  Text,
  TextSize
} from '@yarmill/components';
import { FileIcon } from '../../files-overview/file-icon';
import { limitFileName } from '../../utils/limit-file-name';
import { Tippy } from '../../components/tippy/tippy';

interface EvidenceTableAttachmentsProps {
  readonly attribute: EvidenceAttachmentStore;
}

interface AttachmentProps {
  readonly file: File;
  readonly showName: boolean;
}
function Attachment({ file, showName }: AttachmentProps) {
  return (
    <Tippy
      tooltipContent={file.FileName}
      isEnabled={!showName || (showName && file.FileName.length > 20)}
      translateValue={false}
      noWrapper
    >
      <AttachmentTag file={file} showName={showName}>
        <AttachmentIcon fileType={file.FileType}>
          <Icon size={IconSize.s12}>
            <FileIcon fileType={file.FileType} />
          </Icon>
        </AttachmentIcon>
        {showName && (
          <Text size={TextSize.s12} monoSpace>
            {limitFileName(file.FileName, 16)}
          </Text>
        )}
      </AttachmentTag>
    </Tippy>
  );
}

export const EvidenceTableAttachments = observer(
  function EvidenceTableAttachments({
    attribute
  }: EvidenceTableAttachmentsProps) {
    const attachments = attribute.attachments;

    return (
      <EvidenceTableAttachmentsLayout>
        {attachments.map(attachment => (
          <Attachment
            key={attachment.FileId}
            file={attachment}
            showName={attachments.length === 1}
          />
        ))}
      </EvidenceTableAttachmentsLayout>
    );
  }
);
