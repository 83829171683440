import { useDefaultMainRoute } from '../app/hooks';
import { Redirect } from 'react-router-dom';
import { BaseLoadingScreen } from '../app/base-loading-screen';
import { useIsLoggedIn } from '../auth/hooks';
import { useRootStore } from '../app/root-store-context';
import { AsyncStatus } from '../api/mobx/request-store';
import { observer } from 'mobx-react-lite';
import { FatalError } from '../fatal-error/fatal-error';
import { useLocation } from './hooks';
import { Announcement } from '../announcement/announcement';

function InternalBaseRoute(): JSX.Element {
  const rootStore = useRootStore();
  const initialConfigLoaded = rootStore.isReady;
  const configLoaded =
    initialConfigLoaded && rootStore.status === AsyncStatus.resolved;
  const isLoggedIn = useIsLoggedIn();
  const defaultRoutePath = useDefaultMainRoute();
  const location = useLocation();
  const currentUser = rootStore.currentUserStore;

  if (!isLoggedIn) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  if (configLoaded) {
    return <Redirect to={defaultRoutePath} />;
  }

  if (
    rootStore.status === AsyncStatus.rejected &&
    currentUser.status !== AsyncStatus.pending
  ) {
    if (
      currentUser.status === AsyncStatus.resolved &&
      currentUser.acceptedLegalConsent !== null
    ) {
      return <Announcement />;
    } else {
      return <FatalError />;
    }
  }
  return <BaseLoadingScreen />;
}

export const BaseRoute = observer(InternalBaseRoute);
