import { Redirect, Route, Switch } from 'react-router-dom';

import { NotFound } from '../not-found/not-found';
import { ModalRouter } from './modal-router';
import { useSameLocationTransitionBlocker } from './hooks';
import { useCurrentUserStore } from '../users/hooks';
import { BaseRoute } from './base-route';
import { AsyncStatus } from '../api/mobx/request-store';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../app/root-store-context';
import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from './types';
import {
  Button,
  ButtonAppearance,
  ContentBox,
  PageMainContent,
  Text,
  TextSize
} from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { RoutesList } from './routes-list';
import { Page } from '../page/page';
import { useIsLoggedIn } from '../auth/hooks';

const StyledGoBackButton = styled(Button)`
  margin-left: 10px;
`;

export const Router = observer(function Router(): JSX.Element {
  useSameLocationTransitionBlocker();
  const currentUser = useCurrentUserStore();
  const rootStore = useRootStore();
  const isLoggedIn = useIsLoggedIn();

  if (rootStore.isReady) {
    const user =
      rootStore.historyService.searchParams.get(ATHLETE_SEARCH_PARAM);
    const group = rootStore.historyService.searchParams.get(GROUP_SEARCH_PARAM);
    if (
      isLoggedIn &&
      user &&
      group &&
      (!rootStore.groupsStore.getGroupById(Number(group)) ||
        !rootStore.usersStore.getUserById(Number(user)))
    ) {
      return <Redirect to="/" />;
    }

    if (
      rootStore.isReady &&
      currentUser.status === AsyncStatus.resolved &&
      (user || group) &&
      ((user && !currentUser.hasPermissionToUser(Number(user))) ||
        (!user && group && !currentUser.hasPermissionToGroup(Number(group))))
    ) {
      return (
        <Page>
          <PageMainContent>
            <ContentBox>
              <Text size={TextSize.s14}>
                <FormattedMessage
                  id="general.noRights"
                  values={{
                    link: (
                      <StyledGoBackButton
                        onClick={() => (window.location.href = '/')}
                        type="button"
                        appearance={ButtonAppearance.Primary}
                        noShadow
                      >
                        <FormattedMessage id="general.noRights.back" />
                      </StyledGoBackButton>
                    )
                  }}
                />
              </Text>
            </ContentBox>
          </PageMainContent>
        </Page>
      );
    }
  }

  return (
    <>
      <Switch>
        {rootStore.status === AsyncStatus.rejected && (
          <Route>
            <BaseRoute />
          </Route>
        )}
        {rootStore.status !== AsyncStatus.rejected && <RoutesList />}
        {currentUser.status === AsyncStatus.resolved ? (
          <Route>
            <NotFound />
          </Route>
        ) : (
          <Route>
            <BaseRoute />
          </Route>
        )}
      </Switch>
      <ModalRouter />
    </>
  );
});
