import { observer } from 'mobx-react-lite';
import {
  AxisValue,
  HorizontalCategoricalChart,
  TooltipData
} from '@yarmill/components';
import { AsyncStatus } from '../api/mobx/request-store';
import { useCallback, useEffect } from 'react';
import {
  HorizontalCategoricalChartReportData,
  HorizontalCategoricalChartReportStore
} from './types';
import { ReportWrapper } from './report-wrapper';
import { useReportingDataStore } from './hooks/use-reporting-data-store';

const REPORT_HEIGHT = 400;

export interface ChartReportProps {
  readonly report: HorizontalCategoricalChartReportStore;
  readonly sectionRatio?: number;
  readonly data?: HorizontalCategoricalChartReportData;
  readonly noReportWrapper?: boolean;
}

export const HorizontalCategoricalChartReport = observer(
  function CategoricalChartReport(props: ChartReportProps): JSX.Element {
    const { report, sectionRatio, data: passedData, noReportWrapper } = props;

    const dataStore = useReportingDataStore();

    const data =
      passedData ??
      (dataStore?.getReportData(report.code) as
        | HorizontalCategoricalChartReportData
        | undefined);

    const status = passedData ? AsyncStatus.resolved : dataStore?.status;

    const getTooltipData = useCallback(
      (xValue: AxisValue): TooltipData => {
        return report.getTooltipData(xValue, data?.Data || [], 'y');
      },
      [data?.Data, report]
    );

    useEffect(() => {
      report.xy.setConfigOverride(data?.ConfigOverride);
    }, [data?.ConfigOverride, report]);

    const chartElementsConfig = report.chartElementsConfig;
    const height = report.xy.height;
    const showAxisYLabels = report.xy.showAxisYLabels;
    const noDataMessage = report.xy.noDataMessage;

    return (
      <ReportWrapper
        hasData={!dataStore || Boolean(data && data.Data.length > 0)}
        height={height ?? REPORT_HEIGHT}
        status={status || AsyncStatus.idle}
        sectionRatio={sectionRatio}
        noDataMessage={noDataMessage}
        noReportWrapper={noReportWrapper}
      >
        {width => (
          <HorizontalCategoricalChart
            width={width || 800}
            height={height ?? REPORT_HEIGHT}
            categories={data?.Metadata?.AxisYValues || []}
            data={data?.Data || []}
            configs={chartElementsConfig}
            domainX={data?.Metadata?.AxisXDomain || [0, 0]}
            getTooltipData={getTooltipData}
            formatAxisYTick={report.xy.formatAxisYTick}
            showYAxisLabels={showAxisYLabels}
          />
        )}
      </ReportWrapper>
    );
  }
);
