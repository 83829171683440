import { styled } from '../theme-provider';
import { AlertLayerContent } from '../alert-layer';

export const RenameFileInputWrappers = styled.div<{
  readonly extensionLength: number;
}>`
  display: grid;
  grid-template-columns: 1fr ${({ extensionLength }) =>
      `calc(${extensionLength}ch + 34px)`};
  column-gap: 8px;

  @media (min-width: 560px) {
    min-width: 350px;
  }
`;

export const RenameFileAlertLayerContainer = styled(
  AlertLayerContent.AlertLayerContainer
)`
  @media (min-width: 560px) {
    width: unset;
  }
`;
