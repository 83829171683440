import { css, Icon, styled } from '@yarmill/components';
import { Text } from '../../text';

import {
  getAppearanceColors,
  IconWrapper,
  TextInput,
  TextInputAppearance,
  TextInputProps
} from '../../text-input';
import { FlexCenterContainer } from '../../flex-utils';
import { DropdownButton } from '../../dropdown-provider/dropdown-provider';
import { Button } from '../../button';

export const KeyResultLayout = styled.tr``;

export const KeyResultDataCell = styled.td``;

export const KeyResultTitleContainer = styled(Text)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: ${({ theme }) => theme.size.x2};
`;

export const KeyResultTitleCheckboxWrapper = styled.div`
  height: ${({ theme }) => theme.size.x3};
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.size.x15};
`;

export const KeyResultTitle = styled(Text)<{ readonly isCompleted: boolean }>`
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme, isCompleted }) =>
    isCompleted ? getAppearanceColors('green', theme).text : theme.color.text};

  text-decoration: ${({ isCompleted }) =>
    isCompleted ? 'line-through' : 'none'};

  display: flex;
  column-gap: ${({ theme }) => theme.size.x2};
  align-items: flex-start;
  padding-top: ${({ theme }) => theme.size.x05};
`;

export const DeleteIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.red};
`;

export const KeyResultDataContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: ${({ theme }) => theme.size.x1};

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    column-gap: ${({ theme }) => theme.size.x1};
  }
`;
export const KeyResultValuesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.size.x1};
`;

export const ConnectionIconWrapper = styled(Icon)<{
  readonly $appearance: TextInputProps['appearance'];
}>`
  position: absolute;
  color: ${({ theme, $appearance }) =>
    getAppearanceColors($appearance, theme).text};
  top: ${({ theme }) => theme.size.x4};
  left: calc(${({ theme }) => theme.size.x1} - 1px);
`;
export const KeyResultTargetValueInput = styled(TextInput)`
  color: ${({ theme, appearance }) =>
    getAppearanceColors(appearance, theme).text};
  background-color: ${({ theme }) => theme.color.white};
  border-color: ${({ theme }) => theme.color.white};

  & + ${IconWrapper} {
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme, appearance }) =>
      getAppearanceColors(appearance, theme).text};
  }
  opacity: 1;
  -webkit-text-fill-color: ${({ theme, appearance }) =>
    getAppearanceColors(appearance, theme).text};
`;

export const KeyResultMetricLabel = styled(Text)<{
  readonly size?: 'default' | 'compact';
}>`
  color: ${({ theme }) => theme.color.neutral};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.size.x075};

  ${({ size }) =>
    size === 'compact' &&
    css`
      font-size: 10px;
    `}
`;

export const KeyResultTdBg = styled.div<{
  readonly appearance?: TextInputAppearance;
}>`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  margin: ${({ theme }) => theme.size.x025};
  background-color: ${({ theme, appearance }) =>
    getAppearanceColors(appearance, theme).background};
  border-radius: ${({ theme }) => theme.size.x1};
  border: ${({ theme }) => theme.size.x025} solid
    ${({ theme, appearance }) =>
      getAppearanceColors(appearance, theme).background};
`;

export const KeyResultsTableTd = styled.td<{
  readonly textAlign?: 'left' | 'center' | 'right';
  readonly appearance?: TextInputAppearance;
  readonly showHover?: boolean;
}>`
  color: ${({ theme }) => theme.color.neutral_24};
  vertical-align: middle;
  text-align: ${({ textAlign = 'center' }) => textAlign};
  background-color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.size.x025};
  position: relative;

  &:first-child,
  &:last-child {
    background-color: unset;
  }

  ${DropdownButton} {
    width: 100%;
  }

  transition-property: border, background-color, color, opacity;
  transition-duration: 150ms;

  ${({ showHover, appearance, theme }) =>
    showHover &&
    css`
      & div,
      & button {
        transition: none;
        margin-left: 2px;
        margin-right: 2px;
      }
      :hover {
        & > div:first-child {
          background-color: ${getAppearanceColors(appearance, theme).focus};
        }
        & button,
        & button div {
          background-color: transparent;
          border-color: transparent;
        }
      }
    `};

  :focus-within {
    textarea {
      border-color: transparent;
      background-color: transparent;
    }

    ${KeyResultTdBg} {
      border-color: ${({ theme, appearance }) =>
        getAppearanceColors(appearance, theme).focus};
    }
  }
`;

export const KeyResultDragHandle = styled(FlexCenterContainer)<{
  readonly isDragged?: boolean;
}>`
  cursor: ${({ isDragged }) => (isDragged ? 'grabbing' : 'grab')};
`;

export const DatepickerButton = styled(Button)<{ readonly readOnly?: boolean }>`
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  text-align: left;
  ${({ readOnly }) =>
    readOnly &&
    css`
      cursor: default;
    `}
`;
