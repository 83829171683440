import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';

export function getIsInstanceActive(): AxiosPromise<boolean> {
  return axios.get(`${coreApiTarget}/api/instance/active`, {
    params: {
      instanceCode: devMode ? instance : undefined
    }
  });
}
