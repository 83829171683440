import { useCallback } from 'react';
import { Location } from 'history';

import { NavItem } from '../nav-item';
import { useDefaultCommonSearchParams } from '../hooks';
import moment from 'moment';
import { ROUTE_DATE_FORMAT } from '../../diary/utils';
import { LinkCallback } from '../../routes/types';
import { appendDefaultSearchParams } from '../utils';
import { useAllowedViewType } from './reality-link';
import { observer } from 'mobx-react-lite';

export interface PlanLinkProps {
  className?: string;
  onClick?(): void;
}

export function usePlanLink(): LinkCallback {
  const viewType = useAllowedViewType('plan');
  const defaultParams = useDefaultCommonSearchParams({
    week: moment().format(ROUTE_DATE_FORMAT)
  });

  return useCallback(
    (location: Location) => {
      const searchParams = appendDefaultSearchParams(
        location.search,
        defaultParams
      );
      return `/plan/${viewType}?${searchParams}`;
    },
    [defaultParams, viewType]
  );
}

export const PlanLink = observer(function PlanLink(
  props: PlanLinkProps
): JSX.Element {
  const { className, onClick } = props;
  const link = usePlanLink();

  return (
    <NavItem
      to={link}
      moduleKey="plan"
      icon="ClipboardList"
      className={className}
      onClick={onClick}
    />
  );
});
