import { TransitionGroup } from 'react-transition-group';
import { RightPanelTransition } from '../../components-2/right-panel-transition';
import { EventSetSelector } from './event-set-selector';
import { EventsSelector } from './events-selector';
import { useCallback, useEffect, useState } from 'react';
import { ImportPanelStep, PlannerEventSet } from '../types';
import { AttributeFilters } from './attribute-filters';
import { usePrevious } from '../../utils/use-previous';

interface ImporterFormProps {
  readonly closeImporter: () => void;
}
export function ImporterForm({
  closeImporter
}: ImporterFormProps): JSX.Element {
  const [selectedEventSet, setSelectedEventSet] =
    useState<PlannerEventSet | null>(null);
  const [currentStep, setCurrentStep] = useState<ImportPanelStep>('eventSets');
  const [attributeFilters, setAttributeFilters] = useState<number[]>([]);
  const previousStep = usePrevious(currentStep);

  const hideFilters = useCallback(() => {
    setCurrentStep('events');
  }, []);

  const selectEventSet = useCallback((eventSet: PlannerEventSet) => {
    setSelectedEventSet(eventSet);
    setCurrentStep('events');
  }, []);

  const transitionDirection =
    previousStep === 'eventSets' ||
    (previousStep === 'events' && currentStep === 'eventFilters')
      ? 'right'
      : 'left';

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        if (currentStep === 'eventFilters') {
          setCurrentStep('events');
        } else if (currentStep === 'events') {
          setCurrentStep('eventSets');
        } else {
          closeImporter();
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document?.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentStep, closeImporter]);

  return (
    <TransitionGroup component={null}>
      <RightPanelTransition
        stepName="eventSets"
        transition={transitionDirection}
        active={currentStep === 'eventSets'}
      >
        <EventSetSelector setEventSet={selectEventSet} close={closeImporter} />
      </RightPanelTransition>
      <RightPanelTransition
        stepName="events"
        transition={transitionDirection}
        active={currentStep === 'events'}
      >
        {selectedEventSet && (
          <EventsSelector
            attributeFilters={attributeFilters}
            eventSet={selectedEventSet}
            setAttributeFilters={setAttributeFilters}
            setCurrentStep={setCurrentStep}
            close={closeImporter}
          />
        )}
      </RightPanelTransition>
      <RightPanelTransition
        stepName="eventFilters"
        transition={transitionDirection}
        active={currentStep === 'eventFilters'}
      >
        <AttributeFilters
          selectedAttributes={attributeFilters}
          setAttributeFilters={setAttributeFilters}
          hideFilters={hideFilters}
        />
      </RightPanelTransition>
    </TransitionGroup>
  );
}
