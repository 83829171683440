import { ToastPosition } from 'react-toastify';

import { toast } from '../components/toast-message';
import {
  ANNOUNCEMENT_PERSIST_PREFIX,
  Announcement,
  AnnouncementType,
  AnnouncementCode
} from './types';
import { instance } from '../api/api-mapping';
import { AnnouncementStore } from './mobx/announcement-store';
import { CurrentUserStore } from '../users/mobx/current-user-store';

function createLocalStorageKey(announcement: AnnouncementCode): string {
  return `${ANNOUNCEMENT_PERSIST_PREFIX}${announcement}`;
}

export function getNumberOfViews(announcement: AnnouncementCode): number {
  const storageValue = window.localStorage.getItem(
    createLocalStorageKey(announcement)
  );
  const views = storageValue !== null ? parseInt(storageValue, 10) : 0;

  return isNaN(views) ? 0 : views;
}

export function incrementNumberOfViews(
  announcementCode: AnnouncementCode
): void {
  const numberOfViews = getNumberOfViews(announcementCode);
  window.localStorage.setItem(
    createLocalStorageKey(announcementCode),
    String(numberOfViews + 1)
  );
}

function getNotificationPosition(type: AnnouncementType): ToastPosition {
  switch (type) {
    case AnnouncementType['notification-top']:
      return 'top-center';
    case AnnouncementType['notification-top-left']:
      return 'top-left';
    case AnnouncementType['notification-top-right']:
      return 'top-right';
    case AnnouncementType['notification-bottom']:
      return 'bottom-center';
    case AnnouncementType['notification-bottom-left']:
      return 'bottom-left';
    default:
      return 'bottom-right';
  }
}

export function shouldShowAnnouncement(
  currentUser: CurrentUserStore,
  announcement: Announcement
): boolean {
  return (
    shouldShowAnnouncementForCurrentUser(currentUser, announcement) &&
    (announcement.displays === -1 ||
      getNumberOfViews(announcement.code) < announcement.displays)
  );
}

function shouldShowAnnouncementForCurrentUser(
  currentUser: CurrentUserStore,
  announcement: Announcement
): boolean {
  if (!announcement.permissions) {
    return true;
  }
  const { users, roles } = announcement.permissions;

  if (users?.length && users.includes(currentUser.id)) {
    return true;
  }

  if (roles?.length && roles.includes(currentUser.role)) {
    return true;
  }

  return false;
}

export function showNotificationAnnouncement(
  announcement: AnnouncementStore
): void {
  const position = getNotificationPosition(announcement.type);
  toast(
    `announcement.${announcement.code}.text`,
    undefined,
    { instanceUrl: instance },
    {
      autoClose: false,
      position,
      onClose: () => announcement.close()
    }
  );
}
