import {
  AlertLayer,
  AlertLayerContent,
  Button,
  ButtonAppearance,
  Checkbox,
  Text,
  TextSize,
  TextTag
} from '@yarmill/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormEvent, useState } from 'react';
import { useAuthStore } from '../auth/hooks';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import styled from 'styled-components';

const Description = styled(Text)`
  a {
    color: #4a90e2;
    :hover {
      text-decoration: underline;
    }
  }
`;
export function FatalError(): JSX.Element {
  const [resetClient, setResetClient] = useState(true);
  const intl = useIntl();
  const authStore = useAuthStore();

  function handleRefresh(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    if (resetClient) {
      window.localStorage.clear();
    }
    window.location.href = '/';
  }

  async function handleLogout(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    if (resetClient) {
      window.localStorage.clear();
    }
    return authStore.logOut().finally(() => {
      window.location.href = '/';
    });
  }
  return (
    <AlertLayer active>
      <AlertLayerContent.AlertLayerContainer>
        <AlertLayerContent.ContentWrapper
          onSubmit={handleLogout}
          onReset={handleRefresh}
        >
          <AlertLayerContent.HeadlineWrapper>
            <Text tag={TextTag.h2} size={TextSize.s16} medium>
              <FormattedMessage id="fatalError.headline" />
            </Text>
          </AlertLayerContent.HeadlineWrapper>
          <Description tag={TextTag.p} size={TextSize.s14}>
            <FormattedHTMLMessage id="fatalError.description" />
          </Description>

          <Checkbox
            id="link"
            label={intl.formatMessage({
              id: 'fatalError.resetClient.checkbox'
            })}
            checked={resetClient}
            onChange={() => setResetClient(r => !r)}
            value="reset"
            name="reset"
          />

          <Text tag={TextTag.p} size={TextSize.s14}>
            <FormattedMessage id="fatalError.resetClient.description" />
          </Text>
          <AlertLayerContent.ButtonsWrapper>
            <Button
              type="reset"
              appearance={ButtonAppearance.Primary}
              noShadow
              data-cy="reset"
              value="refresh"
              name="action"
            >
              {resetClient ? (
                <FormattedMessage id="fatalError.button.reset" />
              ) : (
                <FormattedMessage id="fatalError.button.refresh" />
              )}
            </Button>
            <Button
              type="submit"
              appearance={ButtonAppearance.Secondary}
              noShadow
              name="action"
              data-cy="logout"
              value="logout"
            >
              <FormattedMessage id="fatalError.button.logout" />
            </Button>
          </AlertLayerContent.ButtonsWrapper>
        </AlertLayerContent.ContentWrapper>
      </AlertLayerContent.AlertLayerContainer>
    </AlertLayer>
  );
}
