import { HamburgerReportData } from './types';
import {
  HamburgerChart,
  HamburgerChartOrientation,
  TooltipData
} from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { HamburgerReportStore } from './mobx/hamburger-report-store';
import { Fragment, useCallback } from 'react';
import { ReportContentBox } from './report-content-box';
import { useReportingDataStore } from './hooks/use-reporting-data-store';

export interface HamburgerReportProps {
  readonly report: HamburgerReportStore;
  readonly data?: HamburgerReportData;
  readonly noReportWrapper?: boolean;
}

const REPORT_HEIGHT = 200;

export const HamburgerReport = observer(function HamburgerReport(
  props: HamburgerReportProps
): JSX.Element {
  const { report, data: passedData, noReportWrapper } = props;
  const dataStore = useReportingDataStore();

  const data =
    passedData ??
    (dataStore?.getReportData(report.code) as HamburgerReportData | undefined);

  const getTooltipData = useCallback(
    (value: number): TooltipData => {
      return report.getTooltipData(value, data?.Data);
    },
    [data, report]
  );

  const Wrapper = noReportWrapper ? Fragment : ReportContentBox;

  return (
    <Wrapper>
      <HamburgerChart
        layers={report.layers}
        points={report.points}
        highlights={report.highlightedLayers}
        data={data?.Data}
        startValue={data?.Metadata?.StartValue ?? 0}
        getTooltipData={getTooltipData}
        height={report.height ?? REPORT_HEIGHT}
        orientation={
          report.orientation.toLowerCase() as HamburgerChartOrientation
        }
        layersGap={report.gap}
        borderColor={report.borderColor}
      />
    </Wrapper>
  );
});
