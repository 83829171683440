import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction
} from 'mobx';
import { User, UserAccountState, UserId } from '../types';
import { MaskCode } from '../../activities/types';
import { SettingsKey } from '../../settings/mobx/settings-service';
import { SeasonId } from '../../seasons/types';
import { getUserName, isAdmin, isAthlete, isCoach } from '../utils';
import { RootStore } from '../../app/mobx/root-store';
import { deleteUser } from '../api/delete-user';
import { resendInvitation } from '../api/resend-invitation';
import { GroupStore } from '../../groups/mobx/group-store';
import { updateEmail } from '../api/update-email';
import { AsyncStatus } from '../../api/mobx/request-store';
import { AxiosError } from 'axios';
import {
  isServerError,
  ServerErrorResponse
} from '../../api/server-error-response';

export class UserStore {
  private readonly _rootStore: RootStore;

  @observable
  private _user: User;

  @observable
  private settings: User['Settings'];

  @observable
  private _groups: GroupStore[] = [];

  constructor(rootStore: RootStore, user: User) {
    makeObservable(this);
    this._rootStore = rootStore;
    this._user = user;
    this.settings = this._user.Settings;
  }

  public get id(): UserId {
    return this._user.UserId;
  }

  public get avatar(): string | null {
    return this._user.AvatarFileName;
  }

  public get isActive(): boolean {
    return (
      this._user.AccountStateId === UserAccountState.ACTIVE ||
      this._user.AccountStateId === UserAccountState.PENDING
    );
  }

  public get isAthlete(): boolean {
    return isAthlete(this._user);
  }

  public get isAdmin(): boolean {
    return isAdmin(this._user);
  }

  public get isCoach(): boolean {
    return isCoach(this._user);
  }

  @computed
  public get displayName(): string {
    return getUserName(this._user);
  }

  public get activityMask(): MaskCode | undefined {
    return this.settings['mask.selectedMask'] || this._user.DefaultMaskCode;
  }

  public getSeasonStart(seasonId: SeasonId): string | undefined {
    return this.settings[`season.startDate.${seasonId}`] as string | undefined;
  }

  @action
  public updateSetting(key: SettingsKey | string, value: string | boolean) {
    this.settings[key] = value;
    this._user.Settings[key] = value;
  }

  @action
  public removeGroup(group: GroupStore): void {
    (this._groups as IObservableArray).remove(group);
  }

  public get internalUser(): User {
    return this._user;
  }

  public async delete(): Promise<boolean> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      deleteUser(this.id)
    );

    const response = await request.getResponse();

    return runInAction(() => {
      if (response) {
        this.groups.forEach(group => {
          group?.removeDeactivatedUser(this);
        });

        this._rootStore.usersStore.removeUser(this.id);

        return true;
      } else {
        return false;
      }
    });
  }

  @action
  public update(updatedUser: User): void {
    this._user = { ...this._user, ...updatedUser };
  }

  public async resendInvitation(): Promise<boolean> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      resendInvitation({ Email: this._user.Email })
    );
    const response = await request.getResponse();

    return runInAction(() => {
      if (response) {
        this._user.TokenValidity = response;
        return true;
      } else {
        return false;
      }
    });
  }

  public async updateEmail(email: string): Promise<true | ServerErrorResponse> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      updateEmail(this.id, email)
    );
    await request.getResponse();

    return runInAction(() => {
      if (request.status === AsyncStatus.resolved) {
        this._user.Email = email;
        const currentUser = this._rootStore.currentUserStore;
        if (this.id === currentUser.id) {
          currentUser.updateEmail(email);
        }
        return true;
      } else {
        const errorData = (request?.error as AxiosError)?.response?.data;

        return new ServerErrorResponse(
          'Unable to rename attachment',
          isServerError(errorData)
            ? errorData
            : {
                Id: 'unknownError',
                Values: {},
                Field: ''
              }
        );
      }
    });
  }

  public get groups(): GroupStore[] {
    return this._groups;
  }

  @action
  public addGroup(group: GroupStore) {
    this._groups.push(group);
  }

  public get availableMasks(): MaskCode[] {
    return this._user.AvailableMasks;
  }

  @computed
  public get coaches(): UserStore[] {
    return (
      this.internalUser.Coaches?.map(c => {
        return this._rootStore.usersStore.getUserById(c);
      }).filter<UserStore>((u): u is UserStore => Boolean(u)) ?? []
    );
  }
}
