import { ChartConfig } from './types';
import { Line } from './elements/line';
import { ErrorBoundary } from '@yarmill/components';
import { Fragment } from 'react';
import { MultiLine } from './elements/multi-line';
import { Bars } from './elements/bars';
import { ClusteredBars } from './elements/clustered-bars';
import { StackedBars } from './elements/stacked-bars';
import { DataItem } from '../reporting/types';

interface ChartElementsProps {
  readonly configs: ChartConfig[];
  readonly data: DataItem[];
}
export function ChartElements({
  configs,
  data
}: ChartElementsProps): JSX.Element {
  return (
    <ErrorBoundary fallback={<Fragment />} onCatch={console.error}>
      {configs.map(config => {
        switch (config.type) {
          case 'Line':
            return <Line config={config} data={data} key={config.code} />;
          case 'MultiLine':
            return <MultiLine config={config} data={data} key={config.code} />;
          case 'Bar':
            return <Bars config={config} data={data} key={config.code} />;
          case 'BarGroup':
            return (
              <ClusteredBars config={config} data={data} key={config.code} />
            );
          case 'BarStack':
            return (
              <StackedBars config={config} data={data} key={config.code} />
            );
          default:
            return null;
        }
      })}
    </ErrorBoundary>
  );
}
