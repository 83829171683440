import { Editor } from '@tiptap/react';
import { useContext, useEffect, useState } from 'react';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  size,
  useDismiss,
  useFloating,
  useInteractions
} from '@floating-ui/react';
import { Button } from './button';
import { HighlightColorPicker } from '../extensions/highlight';
import { OtherButtonsMenuContext } from './other-button';
import { Tippy } from '../../tippy/tippy';
import { useIntl } from 'react-intl';
import { Highlight } from '../icons/highlight';

export function HighlightButton({
  editor,
  setKeepControlsVisible,
  tabIndex
}: {
  readonly editor: Editor;
  readonly tabIndex: number;
  readonly setKeepControlsVisible: (v: (prev: number) => number) => void;
}): JSX.Element {
  const [isOpened, setIsOpened] = useState(false);
  const { closeMenu } = useContext(OtherButtonsMenuContext);
  const intl = useIntl();
  const label = 'richtext.toolbar.highlight';
  const { refs, floatingStyles, context } = useFloating({
    open: isOpened,
    onOpenChange: setIsOpened,
    placement: 'top',
    middleware: [flip(), size()],
    whileElementsMounted: autoUpdate
  });

  function closePicker() {
    setIsOpened(false);
    closeMenu();
    editor.chain().focus().run();
  }

  const dismiss = useDismiss(context, {
    bubbles: true
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  return (
    <>
      <Tippy tooltipContent={label} noWrapper>
        <Button
          type="button"
          ref={refs.setReference}
          isActive={editor.isActive('highlight')}
          tabIndex={tabIndex}
          aria-label={intl.formatMessage({ id: label })}
          onMouseDown={e => {
            e.stopPropagation();
            if (isOpened) {
              closePicker();
            } else {
              setKeepControlsVisible(v => v + 1);
              setIsOpened(true);
            }
          }}
          {...getReferenceProps()}
        >
          <Highlight />
        </Button>
      </Tippy>
      {isOpened && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, zIndex: 3 }}
            {...getFloatingProps()}
          >
            <HighlightControls
              editor={editor}
              onSelect={closePicker}
              setKeepControlsVisible={setKeepControlsVisible}
            />
          </div>
        </FloatingPortal>
      )}
    </>
  );
}

interface HighlightControlsProps {
  readonly editor: Editor;
  readonly onSelect: () => void;
  readonly setKeepControlsVisible: (v: (prev: number) => number) => void;
}

function HighlightControls({
  editor,
  onSelect,
  setKeepControlsVisible
}: HighlightControlsProps): JSX.Element {
  useEffect(() => {
    return () => {
      setKeepControlsVisible(v => Math.max(0, v - 1));
    };
  }, [setKeepControlsVisible]);

  return <HighlightColorPicker editor={editor} onSelect={onSelect} />;
}
