import { styled } from '@yarmill/components';
import { IconWrapper } from '../../text-input';

export const OkrFormWrapper = styled.div`
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  padding: ${({ theme }) => theme.size.x15};
  border-radius: ${({ theme }) => theme.borderRadius.x1};
`;

export const OkrForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.size.x2};
`;

export const DescriptionIconWrapper = styled(IconWrapper)<{
  readonly $isFilled?: boolean;
}>`
  color: ${({ theme, $isFilled }) =>
    $isFilled ? theme.color.text : theme.color.neutral_40};
  background-color: transparent;
  left: 0;
  align-items: flex-start;
`;
export const DescriptionWrapper = styled.div`
  padding: ${({ theme }) => theme.size.x05} ${({ theme }) => theme.size.x3}
    ${({ theme }) => theme.size.x05} ${({ theme }) => theme.size.x5};
  border: ${({ theme }) => theme.size.x05} solid
    ${({ theme }) => theme.color.neutral_8};
  border-radius: ${({ theme }) => theme.borderRadius.x1};
  background: ${({ theme }) => theme.color.neutral_8};
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  position: relative;

  :focus-within {
    border-color: ${({ theme }) => theme.color.navy_24};
    outline: none;

    ${DescriptionIconWrapper} {
      color: ${({ theme }) => theme.color.navy};
      background-color: ${({ theme }) => theme.color.navy_24};
    }
  }
`;
export const ObjectiveAttributesLayout = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, 50%) auto;
  row-gap: ${({ theme }) => theme.size.x1};
  column-gap: ${({ theme }) => theme.size.x1};
  justify-items: stretch;
`;

export const ObjectiveAttributeLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.size.x1};
`;

export const ObjectiveAttributeLabel = styled.label`
  font-family: ${({ theme }) => theme.text.font.default};
  font-size: 11px;
  line-height: normal;
  font-weight: 700;
  color: ${({ theme }) => theme.color.neutral};
  text-transform: uppercase;
`;

export const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const SupervisorsDropdownTextWrapper = styled.div`
  min-width: calc(19 * ${({ theme }) => theme.size.x1});
`;

export const CategoryDropdownIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.sand};
`;
