import { ObjectiveListKeyResultLayout } from '../components-2/okrs/objectives-list';
import { observer } from 'mobx-react-lite';
import { KeyResultStore } from './mobx/key-result-store';
import { Text } from '../components-2/text';
import { Icon, IconSize } from '@yarmill/components';
import { Bullet } from '@yarmill/icons-2';
import { FormattedMessage } from 'react-intl';

interface ObjectiveListKeyResultProps {
  readonly keyResult: KeyResultStore;
}
export const ObjectiveListKeyResult = observer(function ObjectiveListKeyResult({
  keyResult
}: ObjectiveListKeyResultProps): JSX.Element {
  const isCompleted = keyResult.status === 'completed';

  return (
    <ObjectiveListKeyResultLayout>
      <Icon size={IconSize.s16}>
        <Bullet />
      </Icon>
      <Text
        appearance="task13"
        ellipsis
        whiteSpace="noWrap"
        inheritColor
        strikeThrough={isCompleted}
      >
        {keyResult.title?.trim() || (
          <FormattedMessage id="okrs.objectiveList.keyResult.missingTitle" />
        )}
      </Text>
    </ObjectiveListKeyResultLayout>
  );
});
