import { HeartRateZonesSetStore } from './mobx/heart-rate-zones-set-store';
import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  Icon,
  IconSize,
  StyledTd,
  StyledTr,
  Text,
  TextSize,
  WhiteSpace
} from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { ZonesDiagram } from './zones-diagram';
import { useRootStore } from '../app/root-store-context';
import { HeartRateZoneStore } from './mobx/heart-rate-zone-store';
import { uniqId } from '../app/utils';
import { Tippy } from '../components/tippy/tippy';
import styled from 'styled-components';
import { toast } from '../components/toast-message';
import moment from 'moment';

interface HeartRateZonesTableRowProps {
  readonly heartRateZonesSet: HeartRateZonesSetStore;
  setFormItem(heartRateZonesSet: HeartRateZonesSetStore): void;
}

const Td = styled(StyledTd)<{ outdated: boolean }>`
  ${({ outdated }) => outdated && 'opacity: 0.4;'};
`;

const StyledOverlap = styled(StyledTd)`
  padding: 0;
  vertical-align: middle;
  color: #d0021b;
`;

export const HeartRateZonesTableRow = observer(function HeartRateZonesTableRow({
  heartRateZonesSet,
  setFormItem
}: HeartRateZonesTableRowProps): JSX.Element {
  const rootStore = useRootStore();
  const heartRateZonesManager = rootStore.heartRateZonesManagerStore;
  const intl = useIntl();

  async function duplicateZoneSet() {
    const duplicate = new HeartRateZonesSetStore(rootStore);
    duplicate.setSport(heartRateZonesSet.sport);

    duplicate.heartRateZones.clear();
    duplicate.heartRateZones.push(
      ...heartRateZonesSet.heartRateZones.map(
        zone =>
          new HeartRateZoneStore({
            id: uniqId(),
            max: zone.max,
            name: zone.name
          })
      )
    );

    setFormItem(duplicate);
  }

  async function deleteSet() {
    const message = intl.formatMessage({
      id: 'settings.heartRateZones.remove.confirm'
    });

    if (window.confirm(message)) {
      heartRateZonesManager.heartRateZonesSets.remove(heartRateZonesSet);
      if (await heartRateZonesManager.save()) {
        toast(`toast.success.heartRateZones.removeZone`, 'success');
      } else {
        toast(`toast.error.heartRateZones.removeZone`, 'error');
      }
    }
  }

  const cannotBeRemoved = heartRateZonesSet.isDefault;

  const isOutdated =
    Boolean(heartRateZonesSet.validTo) &&
    moment(heartRateZonesSet.validTo).diff(moment()) < 0;

  return (
    <StyledTr>
      <Td outdated={isOutdated}>
        <Text size={TextSize.s12} whiteSpace={WhiteSpace.noWrap}>
          <FormattedMessage
            id={
              heartRateZonesManager.availableSports.find(
                sport => sport.value === heartRateZonesSet.sport
              )?.label ?? 'sports.general'
            }
          />
        </Text>
      </Td>
      <StyledOverlap>
        {heartRateZonesManager.hasOverlap(heartRateZonesSet) && (
          <Tippy
            tooltipContent="settings.profile.heartRateZones.overlap"
            theme="red"
          >
            <Icon size={IconSize.s18}>
              <ExternalIcon name="AlertTriangle" />
            </Icon>
          </Tippy>
        )}
      </StyledOverlap>
      <Td outdated={isOutdated}>
        <Text size={TextSize.s12} whiteSpace={WhiteSpace.noWrap}>
          {heartRateZonesSet.validFrom ? (
            <FormattedDate value={heartRateZonesSet.validFrom} />
          ) : (
            '-'
          )}
        </Text>
      </Td>
      <Td outdated={isOutdated}>
        <Text size={TextSize.s12} whiteSpace={WhiteSpace.noWrap}>
          {heartRateZonesSet.validTo ? (
            <FormattedDate value={heartRateZonesSet.validTo} />
          ) : (
            '-'
          )}
        </Text>
      </Td>
      <StyledTd>
        <ZonesDiagram zones={heartRateZonesSet.heartRateZones} />
      </StyledTd>
      <StyledTd align="right" whiteSpace={WhiteSpace.noWrap}>
        <Tippy tooltipContent="settings.heartRateZones.edit">
          <Button
            appearance={ButtonAppearance.Link}
            onClick={() => setFormItem(heartRateZonesSet)}
            square
          >
            <Icon size={IconSize.s16}>
              <ExternalIcon name="Pencil" />
            </Icon>
          </Button>
        </Tippy>
        <Tippy tooltipContent="settings.heartRateZones.duplicate">
          <Button
            appearance={ButtonAppearance.Link}
            square
            onClick={duplicateZoneSet}
          >
            <Icon size={IconSize.s16}>
              <ExternalIcon name="Copy" />
            </Icon>
          </Button>
        </Tippy>
        <Tippy
          tooltipContent={
            cannotBeRemoved
              ? 'settings.heartRateZones.requiredGeneralZone'
              : 'settings.heartRateZones.remove'
          }
        >
          <Button
            appearance={ButtonAppearance.Link}
            square
            disabled={cannotBeRemoved}
            onClick={cannotBeRemoved ? undefined : deleteSet}
          >
            <Icon size={IconSize.s16}>
              <ExternalIcon name="Trash" />
            </Icon>
          </Button>
        </Tippy>
      </StyledTd>
    </StyledTr>
  );
});
