import { useCallback } from 'react';
import { Location } from 'history';

import { NavItem } from '../nav-item';
import { useDefaultCommonSearchParams } from '../hooks';
import { appendDefaultSearchParams } from '../utils';
import { LinkCallback } from '../../routes/types';
import moment from 'moment';
import { ROUTE_DATE_FORMAT } from '../../diary/utils';

export interface SeasonEvaluationLinkProps {
  className?: string;
  onClick?(): void;
}

export function useSeasonEvaluationLink(): LinkCallback {
  const defaultSearchParams = useDefaultCommonSearchParams({
    week: moment().format(ROUTE_DATE_FORMAT)
  });

  return useCallback(
    (location: Location) => {
      const searchParams = appendDefaultSearchParams(
        location.search,
        defaultSearchParams
      );
      return `/seasonEvaluation/?${searchParams}`;
    },
    [defaultSearchParams]
  );
}

export function SeasonEvaluationLink(
  props: SeasonEvaluationLinkProps
): JSX.Element {
  const { className, onClick } = props;
  const link = useSeasonEvaluationLink();

  return (
    <NavItem
      to={link}
      moduleKey="seasonEvaluation"
      icon="Report"
      className={className}
      onClick={onClick}
    />
  );
}
