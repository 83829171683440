import {
  action,
  computed,
  observable,
  makeObservable,
  IObservableArray,
  ObservableMap
} from 'mobx';
import { AdditionalRegistrationRequirement } from '../types';

export class TermsAndConditionsStore {
  @observable
  private _conditions: boolean = false;

  @observable
  private _personalData: boolean = false;

  @observable
  private _news: boolean = false;

  @observable
  public _customRequirements: IObservableArray<AdditionalRegistrationRequirement> =
    observable.array();

  @observable
  public _customRequirementsState: ObservableMap<string, boolean> =
    observable.map();

  constructor() {
    makeObservable(this);
  }

  @action
  public acceptConditions() {
    this._conditions = true;
  }

  @action
  public acceptPersonalData = (): void => {
    this._personalData = true;
  };

  @action
  public acceptNews = (): void => {
    this._news = true;
  };

  @action
  public acceptAll = (): void => {
    this._conditions = true;
    this._personalData = true;
    this._news = true;
    this._customRequirements.forEach(r => {
      this._customRequirementsState.set(r.code, true);
    });
  };

  @action
  public rejectConditions = (): void => {
    this._conditions = false;
  };

  @action
  public rejectPersonalData = (): void => {
    this._personalData = false;
  };

  @action
  public rejectNews = (): void => {
    this._news = false;
  };

  @action
  public setCustomRequirements = (
    requirements: AdditionalRegistrationRequirement[]
  ): void => {
    this._customRequirements.replace(requirements);
    requirements.forEach(requirement => {
      this._customRequirementsState.set(requirement.code, false);
    });
  };

  public get isConditionsAccepted(): boolean {
    return this._conditions;
  }

  public get isPersonalDataAccepted(): boolean {
    return this._personalData;
  }

  public get isNewsAccepted(): boolean {
    return this._news;
  }

  public acceptCustomRequirement(code: string): void {
    this._customRequirementsState.set(code, true);
  }

  public rejectCustomRequirement(code: string): void {
    this._customRequirementsState.set(code, false);
  }

  public isCustomRequirementAccepted(code: string): boolean {
    return Boolean(this._customRequirementsState.get(code));
  }

  @computed
  public get areRequiredCheckBoxesAccepted(): boolean {
    const requiredCustomRequirements = this._customRequirements.filter(
      r => r.isRequired
    );

    return (
      this._conditions &&
      this._personalData &&
      requiredCustomRequirements.every(r =>
        this._customRequirementsState.get(r.code)
      )
    );
  }

  public get customRequirements() {
    return this._customRequirements;
  }
}
