import {
  Button,
  ButtonAppearance,
  ProfileHeader as ProfileHeaderLayout
} from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { trackEditProfileClick } from '../google-analytics/utils';
import { Status } from '../navbar/status';
import { ProfileDetailForm } from './profile-detail-form';
import { Avatar } from './avatar';
import { observer } from 'mobx-react-lite';
import { useCurrentUserStore } from '../users/hooks';
import { UserStore } from '../users/mobx/user-store';
import { RoleTag } from '../components/role-tag';
import { useLayer } from '../layer-manager/hooks';
import { LayerPortal } from '../layer-manager/layer-portal';

export interface ProfileHeaderProps {
  readonly user: UserStore;
  readonly noPadding?: boolean;
  readonly noEditButton?: boolean;
}

const StyledButtonWrapper = styled.span`
  @media (max-width: 768px) {
    button {
      width: 100%;
    }
  }
`;

export const ProfileHeader = observer(function ProfileHeader(
  props: ProfileHeaderProps
): JSX.Element {
  const { user, noEditButton } = props;
  const currentUser = useCurrentUserStore();
  const editLayer = useLayer('full-screen');

  function onEditClick(): void {
    trackEditProfileClick();
    if (!editLayer.isOpened) {
      editLayer.open();
    }
  }

  return (
    <>
      <ProfileHeaderLayout
        avatar={<Avatar id={user.avatar} alt={user.displayName} />}
        name={user.displayName}
        email={user.internalUser.Email}
        role={<RoleTag role={user.internalUser.Role} />}
        editButton={
          currentUser?.id === user.id && !noEditButton ? (
            <>
              <Status />
              <StyledButtonWrapper>
                <Button
                  appearance={ButtonAppearance.Primary}
                  onClick={onEditClick}
                  wide
                  noShadow
                >
                  <FormattedMessage id="settings.profile.editProfile" />
                </Button>
              </StyledButtonWrapper>
            </>
          ) : undefined
        }
      />
      <LayerPortal
        layerHandle={editLayer}
        getContent={layer => <ProfileDetailForm layer={layer} />}
      />
    </>
  );
});
