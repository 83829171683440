import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';

export interface PublishRequestParams {
  userId: UserId | undefined;
  userGroupId: UserGroupId | undefined;
  startDate: string;
  endDate: string;
}

export function publish(
  params: PublishRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<boolean> {
  return axios.post(`${coreApiTarget}/api/planner/publish`, params, {
    cancelToken: cancelToken?.token,
    params: {
      ...params,
      instanceCode: devMode ? instance : undefined
    }
  });
}
