import { BarGroupChart, DataLabelDefinition } from '../types';
import { DataItem, getSSRStringWidth } from '@yarmill/components';
import { useScale } from '../hooks/use-scale';
import { createKeysScale } from './create-keys-scale';
import { getBarLabelY } from './get-bar-label-y';
import { getAxisPosition } from '../../reporting/utils/get-axis-position';
import { getBarLabelOffset } from './get-bar-label-offset';
import { getRotatedHeight } from '../../reporting/utils/get-rotated-height';
import { LABEL_FONT_SIZE } from '../../reporting/const';

export function getBarGroupLabel(
  item: DataItem,
  xScale: ReturnType<typeof useScale>,
  yScale: ReturnType<typeof useScale>,
  chartConfig: BarGroupChart,
  priority: number
): (DataLabelDefinition | undefined)[] | undefined {
  if (!('bandwidth' in xScale) || 'bandwidth' in yScale) {
    return undefined;
  }

  const keysScale = createKeysScale(chartConfig.keys, xScale.bandwidth());
  const domainY = yScale.domain();
  const axisPosition = getAxisPosition(domainY as number[]);

  return chartConfig.keys.map(key => {
    const showLabel = chartConfig.getShowLabels(item, key);
    if (!showLabel) {
      return undefined;
    }

    const xValue = chartConfig.getXValue(item) as string;
    const yValue = item[key] as number;
    const position = chartConfig.labelPosition;
    const barHeight = Math.abs(yScale(yValue) - yScale(axisPosition));
    const barWidth = keysScale.bandwidth() * chartConfig.getBarWidth(item, key);
    const groupX = xScale(xValue)!;
    const x = groupX + (keysScale(key) || 0) + barWidth / 2;
    const y = getBarLabelY(yScale, yValue);
    const formattedValue = chartConfig.formatLabelValue(
      item[key] as number,
      key
    );
    const angle = chartConfig.getLabelAngle(item, key);
    const labelWidth = getSSRStringWidth(formattedValue);
    const labelOffset = getBarLabelOffset(
      barHeight,
      yValue,
      position,
      angle,
      labelWidth
    );

    const rotatedLabelHeight = getRotatedHeight(
      labelWidth,
      LABEL_FONT_SIZE,
      angle
    );

    if (
      rotatedLabelHeight + Math.abs(labelOffset) > barHeight &&
      position !== 'outside'
    ) {
      return undefined;
    }

    const maxWidth =
      position !== 'outside'
        ? barWidth
        : chartConfig.keys.length > 1
        ? keysScale.step()
        : xScale.step();

    return {
      x,
      y: y - labelOffset,
      originalValue: yValue,
      text: formattedValue,
      angle,
      color: chartConfig.getLabelColor(item, key),
      priority,
      maxWidth,
      maxHeight: chartConfig.keys.length > 1 ? keysScale.step() : xScale.step()
    };
  });
}
