import { Avatar } from '../profile/avatar';
import { Text, TextSize, TextTag, WhiteSpace } from '@yarmill/components';
import { Tippy } from '../components/tippy/tippy';
import styled, { css } from 'styled-components';

interface AthleteWithAvatarProps {
  readonly name: string;
  readonly avatar?: string | null;
  readonly ellipsis?: boolean;
  readonly monoSpace?: boolean;
}

export const AthleteWithAvatarWrapper = styled.div`
  display: grid;
  grid-template-columns: 25px auto;
  grid-column-gap: 15px;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
`;

const AthleteName = styled(Text)<{ ellipsis?: boolean }>`
  ${({ ellipsis }) =>
    ellipsis &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

export function AthleteWithAvatar({
  avatar,
  name,
  ellipsis,
  monoSpace
}: AthleteWithAvatarProps) {
  return (
    <Tippy
      tooltipContent={name}
      Wrapper={AthleteWithAvatarWrapper}
      translateValue={false}
    >
      <>
        <Avatar id={avatar || null} alt={name} />
        <AthleteName
          size={TextSize.inherit}
          whiteSpace={WhiteSpace.noWrap}
          tag={TextTag.div}
          ellipsis={ellipsis}
          monoSpace={monoSpace}
        >
          {name}
        </AthleteName>
      </>
    </Tippy>
  );
}
