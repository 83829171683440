import { ChartReportDataItem, ReportItem } from '../types';
import { XyChartStore } from './xy-chart-store';
import { computed, makeObservable } from 'mobx';
import {
  AxisValue,
  HorizontalStackedBarChart,
  StackedBarChart,
  TooltipData
} from '@yarmill/components';
import { createBarGroupLabelFormatter } from '../utils';
import { BaseReportStore } from './base-report-store';

export class StackedColumnChartStore extends BaseReportStore {
  private readonly _xyChartStore: XyChartStore;

  constructor(item: ReportItem, pageCode: string) {
    super(item);
    makeObservable(this);
    this._xyChartStore = new XyChartStore(item, pageCode);
  }

  public get xy(): XyChartStore {
    return this._xyChartStore;
  }

  @computed
  public get chartElementsConfig():
    | [StackedBarChart] & [HorizontalStackedBarChart<ChartReportDataItem>] {
    return [
      {
        ...this.xy.baseConfig,
        code: this.code,
        type: 'BarStack',
        keys: this.xy.dataColumns.map(def => def.Name),
        formatLabelValue: createBarGroupLabelFormatter(
          this.xy.dataColumnsByName
        ),
        sort: this._item.Params?.SortBy || 'category',
        getShowLabels: (item: ChartReportDataItem, key?: string) =>
          this.xy.getShowLabels(item, key),
        getOpacity: (item: ChartReportDataItem, key?: string) =>
          this.xy.getOpacity(item, key),
        showCategoryGaps: this._item.Params?.ShowCategoryGaps
      }
    ];
  }

  public readonly getTooltipData = (
    value: AxisValue,
    data: ChartReportDataItem[],
    mainAxis: 'x' | 'y'
  ): TooltipData => {
    const item = data.find(
      d =>
        (mainAxis === 'x' ? this.xy.getXValue(d) : this.xy.getYValue(d)) ===
        value
    );
    const values = item ? this.xy.mapTooltipValues(item, mainAxis) : [];

    return {
      key: String(value),
      values,
      showLabel: this.xy.showAxisXLabelsInTooltip,
      label: this.xy.formatTooltipLabel(value, mainAxis, item),
      ...this.xy.tooltipTableLayoutConfig
    };
  };
}
