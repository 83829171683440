import { FormikTextInput } from '../../components-2/formik-elements/formik-text-input';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { PlannerEvent, PlannerEventType } from '../types';
import { Button, ButtonAppearance } from '../../components-2/button';
import { PlannerEventFormDetailsStepLayout } from '../../#-components/planner/form';
import { useCallback, useContext } from 'react';
import { usePlannerStore } from '../planner-store-context';
import { InlinePlannerEventTypeSelector } from '../../#-components/planner/inline-planner-event-type-selector';
import { TagInput, TagInputValue } from '../../components-2/tag-input';
import { Icon, IconSize } from '@yarmill/components';
import { Cal, Location, Tag } from '@yarmill/icons-2';
import {
  DetailsStepAttendeesButton,
  DetailsStepAttendeesHeading
} from '../../#-components/planner/attendees';
import { PlannerDateInput } from '../../#-components/planner/planner-date-input';
import { SelectedLocation } from '../../#-components/planner/selected-location';
import { useCurrentUserStore } from '../../users/hooks';
import { Header } from '../../okrs/header';
import { RightPanelContent } from '../../components-2/right-panel';
import { FormStepHeader } from './form-step-header';
import { PlannerFormContext } from './planner-form-context';
import { useCurrentUserIsAllowedToWrite } from '../hooks/use-current-user-is-allowed-to-write';

export function EventDetailsStep(): JSX.Element {
  const { setCurrentStep } = useContext(PlannerFormContext);
  const intl = useIntl();
  const plannerStore = usePlannerStore();
  const formik = useFormikContext<PlannerEvent>();
  const currentUser = useCurrentUserStore();
  const currentUserIsAllowedToWrite = useCurrentUserIsAllowedToWrite();
  const { values } = formik;
  const disableEdit = !currentUserIsAllowedToWrite || !values.isEditable;

  const setEventType = useCallback(
    (eventType: PlannerEventType) => {
      formik.setFieldValue('eventTypeId', eventType.eventTypeId);
    },
    [formik]
  );

  const selectedEventType =
    plannerStore.eventTypes.find(t => t.eventTypeId === values.eventTypeId) ??
    plannerStore.eventTypes[0];

  const usersFromGroupCount = values.users.filter(u =>
    plannerStore.group?.allUsers.find(gu => gu.id === u)
  ).length;

  const dateStepButton = !disableEdit ? (
    <Button
      type="button"
      onClick={() => setCurrentStep('date')}
      small
      $square
      $appearanceStyle="tangerine"
    >
      <Icon size={IconSize.s16}>
        <Cal />
      </Icon>
    </Button>
  ) : undefined;

  const allowAttendeesDetail =
    values.users.length ||
    values.otherSubscribersCount ||
    plannerStore.formEvent?.isAttendeesEditable;

  return (
    <>
      <FormStepHeader />
      <RightPanelContent noPadding>
        <PlannerEventFormDetailsStepLayout>
          <FormikTextInput
            name="title"
            appearance="black"
            autoFocus={!Boolean(values.id)}
            label={intl.formatMessage({ id: 'planner.event.title' })}
            disabled={disableEdit}
            placeholder={plannerStore.formEvent?.titlePlaceholder}
            as="textarea"
            rows={3}
          />

          <InlinePlannerEventTypeSelector
            eventTypes={plannerStore.eventTypes}
            selectedEventType={selectedEventType}
            onClick={setEventType}
            onDetailsClick={() => setCurrentStep('eventType')}
            disabled={disableEdit}
            intl={intl}
          />
          <PlannerDateInput
            name="startDate"
            label={intl.formatMessage({ id: 'planner.event.startDate' })}
            value={values.startDate ?? ''}
            additionalButton={dateStepButton}
            onChange={value => formik.setFieldValue('startDate', value)}
            disabled={disableEdit}
            max={values.endDate ?? undefined}
          />
          <PlannerDateInput
            name="endDate"
            label={intl.formatMessage({ id: 'planner.event.endDate' })}
            value={values.endDate ?? ''}
            additionalButton={dateStepButton}
            onChange={value => formik.setFieldValue('endDate', value)}
            disabled={disableEdit}
            min={values.startDate ?? undefined}
          />
          {values.location && values.locationType === 'place' ? (
            <SelectedLocation
              title={values.location?.displayName?.text ?? '---'}
              description={values.location.formattedAddress}
              goToDetails={
                disableEdit ? () => null : () => setCurrentStep('location')
              }
              remove={() => formik.setFieldValue('location', null)}
              disabled={disableEdit}
            />
          ) : (
            <TagInput
              disabled={disableEdit}
              name="location"
              label={intl.formatMessage({ id: 'planner.event.location' })}
              emptyText={intl.formatMessage({
                id: !disableEdit
                  ? 'planner.event.addLocation'
                  : 'planner.event.noLocation'
              })}
              additionalButton={
                !disableEdit ? (
                  <Button
                    type="button"
                    onClick={() => setCurrentStep('location')}
                    small
                    $square
                    $appearanceStyle="navy"
                  >
                    <Icon size={IconSize.s16}>
                      <Location />
                    </Icon>
                  </Button>
                ) : undefined
              }
              tagAppearance="navy"
              value={[]}
              disableCreate
              onClick={
                !disableEdit ? () => setCurrentStep('location') : undefined
              }
            />
          )}

          <TagInput
            name="attributes"
            label={intl.formatMessage({ id: 'planner.event.attributes' })}
            emptyText={intl.formatMessage({
              id: !disableEdit
                ? 'planner.event.addAttribute'
                : 'planner.event.noAttribute'
            })}
            disabled={disableEdit}
            tagIcon={<Tag />}
            additionalButton={
              !disableEdit ? (
                <Button
                  type="button"
                  onClick={() => setCurrentStep('attributes')}
                  small
                  $square
                  $appearanceStyle="sand"
                >
                  <Icon size={IconSize.s16}>
                    <Tag />
                  </Icon>
                </Button>
              ) : undefined
            }
            tagAppearance="sand"
            value={
              (values.attributes
                ?.map(valueId => {
                  const attr = plannerStore.attributeValues.find(
                    a => a.valueId === valueId
                  );
                  if (!attr) {
                    return undefined;
                  } else {
                    return {
                      label: intl.formatMessage({ id: attr.value }),
                      value: attr.valueId
                    };
                  }
                })
                .filter(Boolean) as TagInputValue[]) ?? []
            }
            onRemove={attributes =>
              formik.setFieldValue(
                'attributes',
                attributes.map(a => (typeof a === 'string' ? a : a.value))
              )
            }
            disableCreate
            onClick={
              values.attributes?.length || disableEdit
                ? undefined
                : () => setCurrentStep('attributes')
            }
          />

          {plannerStore.athleteId === null && !currentUser.isAthlete && (
            <div>
              <DetailsStepAttendeesHeading
                as="h3"
                appearance="button10"
                upperCase
              >
                <FormattedMessage id="planner.attendees.heading" />
              </DetailsStepAttendeesHeading>

              <DetailsStepAttendeesButton
                onClick={
                  allowAttendeesDetail
                    ? () => setCurrentStep('attendees')
                    : undefined
                }
              >
                <FormattedMessage
                  id={
                    plannerStore.group?.athletes.every(athlete =>
                      values.users.includes(athlete.id)
                    )
                      ? `planner.event.attendees.allAthletes`
                      : `planner.event.attendees.selectedAthletes`
                  }
                  values={{
                    count: values.users.length,
                    group: plannerStore?.group?.name,
                    usersFromGroupCount,
                    otherUsersCount:
                      values.users.length -
                      usersFromGroupCount +
                      values.otherSubscribersCount
                  }}
                />
              </DetailsStepAttendeesButton>
            </div>
          )}

          <FormikTextInput
            as="textarea"
            rows={3}
            name="notes"
            appearance="black"
            disabled={disableEdit}
            label={intl.formatMessage({ id: 'planner.event.notes' })}
            placeholder={intl.formatMessage({
              id: disableEdit
                ? 'planner.event.notes.placeholder.disabled'
                : 'planner.event.notes.placeholder'
            })}
          />
        </PlannerEventFormDetailsStepLayout>
      </RightPanelContent>
      {currentUserIsAllowedToWrite &&
        (plannerStore.formEvent?.isEditable ||
          plannerStore.formEvent?.isAttendeesEditable) && (
          <Header
            dark
            center={
              <Button
                $appearance={ButtonAppearance.Primary}
                $appearanceStyle="tangerine"
                type="submit"
                stretch
              >
                {formik.values.id ? (
                  <FormattedMessage id="planner.event.save" />
                ) : (
                  <FormattedMessage id="planner.event.create" />
                )}
              </Button>
            }
          />
        )}
    </>
  );
}
