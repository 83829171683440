import { observer } from 'mobx-react-lite';
import { Layer } from '../layer-manager/mobx/layer';
import {
  AlertLayerCloseButtonWrapper,
  AlertLayerContent,
  Button,
  ButtonAppearance,
  Checkbox,
  ExternalIcon,
  Icon,
  SegmentedButton,
  SegmentedControl,
  Text,
  TextSize,
  TextTag
} from '@yarmill/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDiaryStore } from './diary-store-context';
import { useRootStore } from '../app/root-store-context';
import { ExportDiaryAttributesToPdfRequestParams } from '../export/api/export-diary-attributes-to-pdf';
import { FormEvent, Fragment, useState } from 'react';
import { AttributesLayout, FontSize, PdfOrientation } from '../export/types';
import styled from 'styled-components';
import {
  getWeekEndString,
  getWeekStartString,
  ROUTE_DATE_FORMAT
} from './utils';
import {
  TrainingDayAttribute,
  TrainingDayAttributeId
} from '../training-day/types';
import { CloseButton } from '../components/close-button/close-button';
import moment from 'moment';
import { useCurrentUserStore } from '../users/hooks';
import { useSettingsService } from '../settings/hooks';

interface ExportAttributesDialogProps {
  layer: Layer;
}

const AttributesLabel = styled(Text)`
  margin: 10px 0 15px;
`;

const Checkboxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 24px;
  gap: 8px;
`;

const SegmentedControlRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 60px;
  white-space: nowrap;
`;

const FormElements = styled.div`
  display: grid;
  grid-template-columns: 100%;
  row-gap: 20px;
  padding-bottom: 10px;
`;

const ButtonIcon = styled(Icon)<{ readonly rotate?: boolean }>`
  margin-right: 5px;
  ${({ rotate }) => rotate && 'transform: rotate(90deg);'}
`;

export const PRINTABLE_ATTRIBUTES: TrainingDayAttribute['DataTypeIdent'][] = [
  'textarea-basic'
];

interface ExportAttributesDialogMemoryStoreValue {
  selectedAttributes: TrainingDayAttributeId[];
  orientation: PdfOrientation;
  layout: AttributesLayout;
  fontSize: FontSize;
  showAttributeLabels: boolean;
}

interface AttributeCheckboxProps {
  attributeId: TrainingDayAttributeId;
  name: string;
  checked: boolean;
  setSelectedAttributes: (
    value:
      | TrainingDayAttributeId[]
      | ((prevValue: TrainingDayAttributeId[]) => TrainingDayAttributeId[])
  ) => void;
}
function AttributeCheckbox({
  name,
  attributeId,
  setSelectedAttributes,
  checked
}: AttributeCheckboxProps): JSX.Element {
  return (
    <Fragment key={attributeId}>
      <Text
        tag={TextTag.label}
        id={`attribute-${attributeId}`}
        size={TextSize.s14}
      >
        <FormattedMessage id={name} />
      </Text>
      <Checkbox
        id={`attribute-${attributeId}`}
        onChange={() => {
          window.getSelection()?.empty();
          setSelectedAttributes(prevState => {
            const newState = [...prevState];
            newState.includes(attributeId)
              ? newState.splice(prevState.indexOf(attributeId), 1)
              : newState.push(attributeId);
            return newState;
          });
        }}
        value={String(attributeId)}
        checked={checked}
      />
    </Fragment>
  );
}

export const ExportAttributesDialog = observer(function ExportAttributesDialog(
  props: ExportAttributesDialogProps
): JSX.Element {
  const { layer } = props;
  const diaryStore = useDiaryStore();
  const rootStore = useRootStore();
  const currentUser = useCurrentUserStore();
  const settingValue = currentUser.getSetting(
    `diary.attributesPrintDialogSettings.${diaryStore.diaryType}`
  );
  const previousState = settingValue
    ? (JSON.parse(settingValue) as ExportAttributesDialogMemoryStoreValue)
    : undefined;
  const intl = useIntl();
  const attributesStore = rootStore.trainingDayAttributesStore;
  const exportService = rootStore.exportService;
  const language = rootStore.intlStore.locale;
  const weekGoalAttribute = diaryStore.goalsAttributes?.week;
  const attributes = (
    diaryStore.diaryType === 'plan'
      ? attributesStore.planAttributes
      : attributesStore.realityAttributes
  ).filter(attr => PRINTABLE_ATTRIBUTES.includes(attr.DataTypeIdent));
  const settingsService = useSettingsService();
  const [selectedAttributes, setSelectedAttributes] = useState<
    TrainingDayAttributeId[]
  >(
    previousState?.selectedAttributes ??
      (weekGoalAttribute
        ? [weekGoalAttribute, ...attributes.map(attr => attr.AttributeItemId)]
        : attributes.map(attr => attr.AttributeItemId))
  );
  const [orientation, setOrientation] = useState<PdfOrientation>(
    previousState?.orientation ?? 'portrait'
  );
  const [layout, setLayout] = useState<AttributesLayout>(
    previousState?.layout ?? 'horizontal'
  );
  const [fontSize, setFontSize] = useState<FontSize>(
    previousState?.fontSize ?? 'small'
  );
  const [showAttributeLabels, setShowAttributesLabels] = useState<boolean>(
    previousState?.showAttributeLabels ?? true
  );

  async function handleExport(e: FormEvent) {
    e.preventDefault();
    layer.close();
    const name = diaryStore.athleteId
      ? rootStore.usersStore.getUserById(diaryStore.athleteId)?.displayName
      : rootStore.groupsStore.getGroupById(diaryStore.groupId)?.name;
    const startDate = getWeekStartString(diaryStore.week);
    const endDate = getWeekEndString(diaryStore.week);
    const formatDate = (date: string) => moment(date).format(ROUTE_DATE_FORMAT);

    const fileName = `${name} - ${formatDate(startDate)} - ${formatDate(
      endDate
    )} - ${intl.formatMessage({ id: `export.info.${diaryStore.diaryType}` })}`;
    const params: ExportDiaryAttributesToPdfRequestParams = {
      startDate,
      endDate,
      state: diaryStore.diaryType === 'plan' ? 'P' : 'R',
      userId: diaryStore.athleteId || undefined,
      userGroupId: diaryStore.athleteId
        ? undefined
        : diaryStore.groupId || undefined,
      language,
      orientation,
      attributeIds: selectedAttributes,
      showAttributeLabels,
      layout,
      fontSize
    };
    void settingsService.saveSettings(
      `diary.attributesPrintDialogSettings.${diaryStore.diaryType}`,
      JSON.stringify({
        fontSize,
        layout,
        orientation,
        selectedAttributes,
        showAttributeLabels
      }),
      {
        userId: currentUser.id
      }
    );
    await exportService.exportDiaryAttributesToPdf(params, fileName);
  }

  return (
    <AlertLayerContent.AlertLayerContainer autoWidth>
      <AlertLayerContent.StickyWrapper>
        <AlertLayerCloseButtonWrapper>
          <CloseButton onClick={layer.close} hideText />
        </AlertLayerCloseButtonWrapper>
      </AlertLayerContent.StickyWrapper>
      <AlertLayerContent.ContentWrapper onSubmit={handleExport}>
        <AlertLayerContent.HeadlineWrapper>
          <Text tag={TextTag.h2} size={TextSize.s16} medium>
            <FormattedMessage id="diary.exportAttributes.headline" />
          </Text>
        </AlertLayerContent.HeadlineWrapper>
        <FormElements>
          <div>
            <AttributesLabel tag={TextTag.h3} bold size={TextSize.s14}>
              <FormattedMessage id="diary.exportAttributes.attributes" />
            </AttributesLabel>
            <Checkboxes>
              {weekGoalAttribute && (
                <AttributeCheckbox
                  attributeId={weekGoalAttribute}
                  name="trainingDay.attributes.weekGoal"
                  checked={selectedAttributes.includes(weekGoalAttribute)}
                  setSelectedAttributes={setSelectedAttributes}
                />
              )}
              {attributes.map(attribute => (
                <AttributeCheckbox
                  key={attribute.AttributeItemId}
                  attributeId={attribute.AttributeItemId}
                  name={attribute.Name}
                  checked={selectedAttributes.includes(
                    attribute.AttributeItemId
                  )}
                  setSelectedAttributes={setSelectedAttributes}
                />
              ))}
            </Checkboxes>
          </div>

          <SegmentedControlRow>
            <Text bold tag={TextTag.span} size={TextSize.s14}>
              <FormattedMessage id="export.layout.label" />
            </Text>
            <SegmentedControl>
              <SegmentedButton
                onClick={() => setLayout('horizontal')}
                type="button"
                active={layout === 'horizontal'}
              >
                <ButtonIcon rotate>
                  <ExternalIcon name="LayoutList" />
                </ButtonIcon>
                <FormattedMessage id="export.layout.horizontal" />
              </SegmentedButton>
              <SegmentedButton
                onClick={() => setLayout('vertical')}
                type="button"
                active={layout === 'vertical'}
              >
                <ButtonIcon>
                  <ExternalIcon name="LayoutList" />
                </ButtonIcon>
                <FormattedMessage id="export.layout.vertical" />
              </SegmentedButton>
            </SegmentedControl>
          </SegmentedControlRow>

          {layout === 'vertical' && (
            <Checkboxes>
              <Text bold tag={TextTag.span} size={TextSize.s14}>
                <FormattedMessage id="export.showAttributeLabels.label" />
              </Text>
              <Checkbox
                id="showAttributeLabels"
                onChange={() => {
                  window.getSelection()?.empty();
                  setShowAttributesLabels(s => !s);
                }}
                value="showAttributeLabels"
                checked={showAttributeLabels}
              />
            </Checkboxes>
          )}

          <SegmentedControlRow>
            <Text bold tag={TextTag.span} size={TextSize.s14}>
              <FormattedMessage id="export.orientation.label" />
            </Text>
            <SegmentedControl>
              <SegmentedButton
                onClick={() => setOrientation('portrait')}
                type="button"
                active={orientation === 'portrait'}
              >
                <ButtonIcon>
                  <ExternalIcon name="File" />
                </ButtonIcon>
                <FormattedMessage id="export.orientation.portrait" />
              </SegmentedButton>
              <SegmentedButton
                onClick={() => setOrientation('landscape')}
                type="button"
                active={orientation === 'landscape'}
              >
                <ButtonIcon>
                  <ExternalIcon name="FileHorizontal" />
                </ButtonIcon>
                <FormattedMessage id="export.orientation.landscape" />
              </SegmentedButton>
            </SegmentedControl>
          </SegmentedControlRow>

          <SegmentedControlRow>
            <Text bold tag={TextTag.span} size={TextSize.s14}>
              <FormattedMessage id="export.fontSize.label" />
            </Text>
            <SegmentedControl>
              <SegmentedButton
                onClick={() => setFontSize('small')}
                type="button"
                active={fontSize === 'small'}
              >
                <FormattedMessage id="export.fontSize.small" />
              </SegmentedButton>
              <SegmentedButton
                onClick={() => setFontSize('medium')}
                type="button"
                active={fontSize === 'medium'}
              >
                <FormattedMessage id="export.fontSize.medium" />
              </SegmentedButton>
              <SegmentedButton
                onClick={() => setFontSize('large')}
                type="button"
                active={fontSize === 'large'}
              >
                <FormattedMessage id="export.fontSize.large" />
              </SegmentedButton>
            </SegmentedControl>
          </SegmentedControlRow>
        </FormElements>
        <AlertLayerContent.ButtonsWrapper>
          <Button
            type="button"
            appearance={ButtonAppearance.Link}
            noShadow
            data-cy="cancel"
            onClick={layer.close}
          >
            <FormattedMessage id="diary.exportAttributes.buttons.cancel" />
          </Button>
          <Button
            type="submit"
            appearance={ButtonAppearance.Primary}
            noShadow
            data-cy="save"
          >
            <FormattedMessage id="diary.exportAttributes.buttons.export" />
          </Button>
        </AlertLayerContent.ButtonsWrapper>
      </AlertLayerContent.ContentWrapper>
    </AlertLayerContent.AlertLayerContainer>
  );
});
