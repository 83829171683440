import { observer } from 'mobx-react-lite';
import {
  ChartWidthProvider,
  ContentBox,
  Icon,
  IconSize,
  isBrowser,
  OverviewTable,
  getOverviewTableMinimalWidth,
  ExternalIconName
} from '@yarmill/components';
import { OverviewTableCellData, OverviewTableReportData } from './types';
import { getBusinessFormatFormatter } from './data-formats';
import { fillStringTemplate, getIcon, mapColor, PRINT_WIDTH } from './utils';
import styled from 'styled-components';
import { Fragment, ReactNode, useCallback, useState } from 'react';
import { Tippy } from '../components/tippy/tippy';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import { useWindowSize } from '../utils/use-window-size';
import { Link } from '../components/link';
import { OverviewTableStore } from './mobx/overview-table-store';
import { useReportingDataStore } from './hooks/use-reporting-data-store';

export interface OverviewTableReportProps {
  readonly report: OverviewTableStore;
  readonly sectionRatio?: number;
  readonly data?: OverviewTableReportData;
}

const ScrollableContentBox = styled(ContentBox)<{ scrollable: boolean }>`
  ${({ scrollable }) => (scrollable ? 'overflow-x: auto;' : 'padding-top: 0;')}
  position: relative;
  max-width: 100%;
`;

const StyledIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledRowLabel = styled.div`
  font-size: 14px;
  background-color: #ffffff;
  padding-right: 5px;
`;

const EmptyValue = styled.div`
  width: 20px;
  height: 20px;
`;

const OverflowWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  height: min-content;
  border-radius: 8px;
`;

const TableWidthMeasurer = styled(ScrollableContentBox)`
  width: 100%;
  height: 0;
  position: absolute;
  pointer-events: none;
  visibility: hidden;
`;

function getWrappedIcon(name?: ExternalIconName): JSX.Element {
  return (
    <StyledIcon size={IconSize.s20}>{getIcon(name, <EmptyValue />)}</StyledIcon>
  );
}

interface RowLabelProps {
  children?: ReactNode;
  value?: string | number;
  cellData?: OverviewTableCellData;
}

export const OverviewTableReport = observer(function OverviewTableReport(
  props: OverviewTableReportProps
) {
  const { report, sectionRatio, data: passedData } = props;
  const dataStore = useReportingDataStore();
  const windowSize = useWindowSize();
  const forceLabelColumn = isBrowser && (windowSize?.width || 0) < 560;
  const [width, setWidth] = useState<number>(
    isBrowser
      ? 0
      : report.printWidth || PRINT_WIDTH * (sectionRatio || 1) - 26 * 2
  );

  const data =
    passedData ??
    (dataStore?.getReportData(report.code) as
      | OverviewTableReportData
      | undefined);

  const reportData = data?.Data || [];
  const hasRowLabel = forceLabelColumn || report.hasRowLabel;

  const rowLabel = useCallback(
    function RowLabel(props: RowLabelProps): JSX.Element {
      const { children, cellData, value } = props;
      const labelLink = isBrowser ? report.rowKey?.LabelLink : '';
      const filters = dataStore?.apiFilters || {};
      const isExternalLink = labelLink?.startsWith('http');
      const Wrapper = labelLink ? Link : Fragment;
      const to = labelLink
        ? fillStringTemplate(labelLink, {
            Key: String(value),
            ...cellData,
            ...filters
          })
        : '';

      return (
        <Wrapper to={to} target={isExternalLink ? '_blank' : undefined}>
          <StyledRowLabel>{children}</StyledRowLabel>
        </Wrapper>
      );
    },
    [report, dataStore]
  );

  const valueFormatter = useCallback(
    (name?: string, cellData?: OverviewTableCellData): JSX.Element => {
      if (!report.dataTooltip || !cellData) {
        return getWrappedIcon(name as ExternalIconName);
      }

      return (
        <Tippy
          tooltipContent={
            <FormattedHTMLMessage id={report.dataTooltip} values={cellData} />
          }
        >
          {getWrappedIcon(name as ExternalIconName)}
        </Tippy>
      );
    },
    [report.dataTooltip]
  );

  const minWidth = getOverviewTableMinimalWidth(
    hasRowLabel,
    data?.Columns.length || 0
  );
  return (
    <OverflowWrapper>
      <TableWidthMeasurer scrollable={false}>
        <ChartWidthProvider setWidth={setWidth} useStaticPosition />
      </TableWidthMeasurer>
      <ScrollableContentBox scrollable={width < minWidth}>
        {width !== 0 && (
          <OverviewTable
            columns={data?.Columns || []}
            data={reportData}
            headerFormatter={getBusinessFormatFormatter(
              report.columnKey?.BusinessFormat ?? 'date',
              report.columnKey?.Format ?? null
            )}
            rowLabelFormatter={getBusinessFormatFormatter(
              report.rowKey?.BusinessFormat ?? 'athleteWithAvatar',
              null,
              rowLabel,
              { ellipsis: true }
            )}
            categoryFormatter={mapColor}
            valueFormatter={valueFormatter}
            hasHeaderRow={report.hasHeader}
            hasRowLabel={hasRowLabel}
            width={Math.max(width, minWidth)}
          />
        )}
      </ScrollableContentBox>
    </OverflowWrapper>
  );
});
