import {
  Dispatch,
  FormEvent,
  FormEventHandler,
  RefObject,
  SetStateAction,
  useCallback,
  useState
} from 'react';
import { useRootStore } from '../../app/root-store-context';
import { YollandaInteractionId } from '../types';

export function useSubmitHandler(
  layoutRef: RefObject<HTMLDivElement>,
  setShowLoadingMessageFallback: Dispatch<SetStateAction<boolean>>
): [string, YollandaInteractionId, boolean, FormEventHandler] {
  const rootStore = useRootStore();
  const yollandaService = rootStore.yollandaService;
  const [isPending, setIsPending] = useState(false);
  const [streamedMessage, setStreamedMessage] = useState<string>('');
  const [interactionId, setInteractionId] = useState<string>('');

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsPending(true);
      setStreamedMessage('');
      setInteractionId('');
      setShowLoadingMessageFallback(false);
      const values = new FormData(e.currentTarget);
      const query = (values.get('term') as string) ?? '';

      if (!query) {
        return;
      }
      const timer = new Date().getMilliseconds();

      yollandaService.askYollanda(query).then(response => {
        if (!response.ok) {
          console.error(`HTTP error! Status: ${response.status}`);
          return;
        }

        if (!response.body || !(response.body instanceof ReadableStream)) {
          console.error('Response is not a readable stream');
          return;
        }
        const stream = response.body;
        const reader = stream.getReader();
        const interactionId =
          response.headers.get('x-yollanda-interaction-id') ??
          response.headers.get('X-Yollanda-Interaction-Id');

        if (interactionId) {
          setInteractionId(interactionId);
        }

        setIsPending(false);

        const now = new Date().getMilliseconds();
        if (now - timer < 15000) {
          setShowLoadingMessageFallback(true);
          setTimeout(() => {
            setShowLoadingMessageFallback(false);
          }, 1500);
        }

        const readChunk = async () => {
          const { done, value } = await reader.read();

          if (done) {
            reader.releaseLock();
          } else {
            let stringValue: string = '';
            try {
              stringValue = new TextDecoder().decode(value);
              const splitted = stringValue.split('\n');

              const message = splitted
                .map(msg => (msg ? JSON.parse(msg.trim()).message : ''))
                .join('');
              setStreamedMessage(m => m + message ?? '');
              layoutRef.current?.parentElement?.scrollBy({ top: 20 });
            } catch (e) {
              console.groupCollapsed(e);
              console.error(e);
              console.log('Received value', value);
              console.log('String value', stringValue);
              console.groupEnd();
            }
            readChunk();
          }
        };

        readChunk();
      });
    },
    [layoutRef, yollandaService]
  );

  return [streamedMessage, interactionId, isPending, handleSubmit];
}
