import { useIntl } from 'react-intl';
import { BackButton } from '../../components-2/okrs/detail/objective-detail';
import { ButtonAppearance } from '../../components-2/button';
import { Link } from 'react-router-dom';
import { Icon, IconSize } from '@yarmill/components';
import { Back } from '@yarmill/icons-2';
import { Tippy } from '../../components/tippy/tippy';

export function BackToOkrsList() {
  const intl = useIntl();

  return (
    <Tippy tooltipContent="okrs.detail.back" noWrapper>
      <BackButton
        $appearance={ButtonAppearance.Secondary}
        as={Link}
        to={l => ({ ...l, pathname: `/okr` })}
        aria-label={intl.formatMessage({ id: 'okrs.detail.back' })}
        $appearanceStyle="neutral"
        $iconOnly
      >
        <Icon size={IconSize.s24}>
          <Back />
        </Icon>
      </BackButton>
    </Tippy>
  );
}
