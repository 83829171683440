import { FormattedMessage, useIntl } from 'react-intl';
import { MouseEvent, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import {
  AttachmentIcon,
  AttachmentItemWrapper,
  AttachmentName,
  Button,
  ButtonAppearance,
  ExternalIcon,
  Icon,
  IconSize,
  SingleLinePopoverItem,
  Text,
  TextSize
} from '@yarmill/components';

import { AttachmentStore } from '../mobx/attachment-store';
import { FileLink } from './file-link';
import { FileIcon } from '../../files-overview/file-icon';
import { toast } from '../../components/toast-message';

import { Popover } from '../../components/popover';
import { PopoverItem } from '../../components/popover/popover-item';
import { useFileLink } from '../../files-overview/hooks/use-file-link';
import { useRenameFileHandler } from '../../files-overview/hooks/use-rename-file-handler';

interface AttachmentItemProps {
  store: AttachmentStore;
}

function InternalAttachmentItem(props: AttachmentItemProps): JSX.Element {
  const { store } = props;
  const intl = useIntl();
  const file = store.attachment;
  const disabled = !store.isAllowedToDeleteFile(file);
  const onDownloadClick = useFileLink(file, true);
  const [onRenameClick, renameFileLayer] = useRenameFileHandler(store);

  const onRemoveClick = useCallback(async () => {
    const fileName = store.attachment.FileName;
    const message = intl.formatMessage(
      {
        id: 'trainingDay.attributes.attachment.removeConfirm'
      },
      { file: fileName }
    );

    if (window.confirm(message)) {
      const result = await store.remove();
      if (result) {
        toast('toast.success.removeAttachment', 'success', { file: fileName });
      } else {
        toast('toast.error.removeAttachment', 'error', { file: fileName });
      }
    }
  }, [store, intl]);

  return (
    <AttachmentItemWrapper data-cy="attachment">
      <FileLink file={store.attachment}>
        <AttachmentIcon fileType={file.FileType}>
          <Icon>
            <FileIcon fileType={file.FileType} />
          </Icon>
        </AttachmentIcon>
      </FileLink>
      <FileLink file={store.attachment}>
        <AttachmentName title={file.FileName}>
          <Text size={TextSize.s14} inheritColor>
            {file.FileName}
          </Text>
        </AttachmentName>
      </FileLink>

      <Popover
        content={tippy => (
          <>
            <PopoverItem
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                void onDownloadClick(e);
                tippy.hide();
              }}
              dataTest="download-attachment"
            >
              <SingleLinePopoverItem>
                <FormattedMessage id="trainingDay.attributes.attachment.download" />
              </SingleLinePopoverItem>
            </PopoverItem>
            <PopoverItem
              dataTest="rename-attachment"
              onClick={
                disabled
                  ? undefined
                  : () => {
                      onRenameClick();
                      tippy.hide();
                    }
              }
              appearance={disabled ? 'disabled' : 'default'}
            >
              <SingleLinePopoverItem>
                <FormattedMessage id="trainingDay.attributes.attachment.rename" />
              </SingleLinePopoverItem>
            </PopoverItem>
            <PopoverItem
              dataTest="remove-attachment"
              onClick={
                disabled
                  ? undefined
                  : () => {
                      void onRemoveClick();
                      tippy.hide();
                    }
              }
              appearance={disabled ? 'disabled' : 'danger'}
            >
              <SingleLinePopoverItem>
                <FormattedMessage id="trainingDay.attributes.attachment.remove" />
              </SingleLinePopoverItem>
            </PopoverItem>
          </>
        )}
      >
        <Button
          as="button"
          appearance={ButtonAppearance.Link}
          square
          data-cy="attachment-menu"
        >
          <Icon size={IconSize.s24}>
            <ExternalIcon name="DotsCircleHorizontal" />
          </Icon>
        </Button>
      </Popover>
      {renameFileLayer}
    </AttachmentItemWrapper>
  );
}

export const AttachmentItem = observer(InternalAttachmentItem);
