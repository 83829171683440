import { Icon, IconSize, styled } from '@yarmill/components';
import { CheckboxChecked, CheckboxEmpty } from '@yarmill/icons-2';
import { getAppearanceColors, TextInputAppearance } from './text-input';

interface CheckboxProps {
  readonly isChecked?: boolean;
  readonly hideInput?: boolean;
  readonly onClick?: () => void;
  readonly appearance?: TextInputAppearance;
  readonly disabled?: boolean;
}

const StyledInput = styled.input`
  position: absolute;
  left: -200vw;
`;

const CheckboxIcon = styled(Icon)<{
  readonly disabled?: boolean;
  readonly $appearance: TextInputAppearance;
}>`
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  color: ${({ theme, $appearance }) =>
    getAppearanceColors($appearance, theme).base};
`;
export function Checkbox({
  isChecked,
  onClick,
  hideInput,
  appearance = 'navy',
  disabled
}: CheckboxProps): JSX.Element {
  return (
    <CheckboxIcon
      size={IconSize.s16}
      disabled={disabled}
      $appearance={appearance}
    >
      {isChecked ? <CheckboxChecked /> : <CheckboxEmpty />}
      {!hideInput && (
        <StyledInput
          type="checkbox"
          checked={isChecked}
          onChange={onClick}
          readOnly={!onClick}
        />
      )}
    </CheckboxIcon>
  );
}
