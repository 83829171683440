import { MutableRefObject, useCallback, useEffect, useState } from 'react';

import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  Icon,
  IconSize,
  IntegerInput,
  NumberInput,
  TableFilterOption,
  TableFilters,
  TableFilterType
} from '@yarmill/components';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { SortConfig } from './types';

import { PatternInputDate } from '../../components/pattern-input-date';
import { extractDateFormat } from '../../utils/extract-date-format';
import { PatternInputTime } from '../../components/pattern-input-time';
import { PatternInputTimeOfDay } from '../../components/pattern-input-time-of-day';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions
} from '@floating-ui/react';

type EvidenceTableFilterType =
  | TableFilterType
  | 'time-of-day'
  | 'decimal-number';

export interface FiltersProps {
  readonly attributeId: number | string;
  readonly filter: string | string[] | undefined;
  readonly sort: SortConfig['order'] | null;
  readonly options?: TableFilterOption[];
  readonly filterType?: EvidenceTableFilterType;
  readonly hideFilters?: boolean;
  readonly timeFormat?: string;
  readonly hidden?: boolean;
  onChange(
    attributeId: number | string,
    value: string | string[],
    sort: SortConfig['order'] | null
  ): void;
  readonly openFiltersRef?: MutableRefObject<(() => void) | null>;
}

const FiltersWrapper = styled.span<{ readonly $hidden?: boolean }>`
  position: relative;
  padding-left: 5px;
  display: flex;
  align-items: center;
  ${({ $hidden }) =>
    $hidden &&
    css`
      visibility: hidden;
      pointer-events: none;
    `}
`;

const FilterButton = styled(Button)<{ isActive: boolean }>`
  ${props =>
    props.isActive &&
    css`
      color: #fff;
      background: #4a90e2;
      :hover {
        color: #ecebeb;
      }
    `};
  :focus,
  :active {
    color: #fff;
    background: #4a90e2;
  }
  min-height: unset;
  padding: 0 4px;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
`;

const StyledFilterIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function getTableFilterType(
  type: EvidenceTableFilterType | undefined
): TableFilterType {
  switch (type) {
    case 'time-of-day':
      return 'time';
    case 'decimal-number':
      return 'number';
    default:
      return type ?? 'text';
  }
}

function getInputComponent(type: EvidenceTableFilterType | undefined) {
  switch (type) {
    case 'date':
      return PatternInputDate;
    case 'time':
      return PatternInputTime;
    case 'time-of-day':
      return PatternInputTimeOfDay;
    case 'number':
      return IntegerInput;
    case 'decimal-number':
      return NumberInput;
    default:
      return undefined;
  }
}

export function Filters(props: FiltersProps): JSX.Element {
  const {
    attributeId,
    options,
    onChange,
    filter,
    sort,
    hideFilters,
    filterType,
    timeFormat,
    hidden,
    openFiltersRef
  } = props;
  const intl = useIntl();
  const filtersLayerState = useState<boolean>(false);
  const [isLayerOpened, setIsLayerOpened] = filtersLayerState;
  const { refs, floatingStyles, context } = useFloating({
    open: isLayerOpened,
    onOpenChange: setIsLayerOpened,
    placement: 'bottom-start',
    middleware: [flip()],
    whileElementsMounted: autoUpdate
  });

  useEffect(() => {
    if (openFiltersRef) {
      openFiltersRef.current = () => setIsLayerOpened(true);
    }
  }, [openFiltersRef, setIsLayerOpened]);

  const dismiss = useDismiss(context, {
    bubbles: true
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  // Select filter should have checked all fields if no items are selected
  const activeFilter =
    (!options && filter) ||
    (options && Array.isArray(filter) && filter.length !== options.length);

  const onApply = useCallback(
    (value: string | string[], sort: SortConfig['order'] | null): void => {
      onChange(attributeId, value, sort);
      setIsLayerOpened(false);
    },
    [attributeId, onChange, setIsLayerOpened]
  );
  const onClear = useCallback((): void => {
    onChange(attributeId, '', null);
    setIsLayerOpened(false);
  }, [attributeId, onChange, setIsLayerOpened]);

  return (
    <FiltersWrapper $hidden={hidden}>
      <FilterButton
        isActive={Boolean(activeFilter || sort || isLayerOpened)}
        appearance={ButtonAppearance.Link}
        onClick={() => setIsLayerOpened(true)}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <StyledFilterIcon size={IconSize.s12}>
          <ExternalIcon name="FilterFilled" />
        </StyledFilterIcon>
      </FilterButton>
      {isLayerOpened && (
        <FloatingPortal>
          <div
            style={floatingStyles}
            {...getFloatingProps()}
            ref={refs.setFloating}
          >
            <TableFilters
              applyLabel={intl.formatMessage({ id: 'evidence.filters.apply' })}
              clearLabel={intl.formatMessage({ id: 'evidence.filters.clear' })}
              filtersLabel={intl.formatMessage({
                id: 'evidence.filters.filters'
              })}
              ascLabel={intl.formatMessage({ id: 'evidence.filters.asc' })}
              descLabel={intl.formatMessage({ id: 'evidence.filters.desc' })}
              onClose={() => setIsLayerOpened(false)}
              options={options}
              type={getTableFilterType(filterType)}
              onApply={onApply}
              onClear={onClear}
              sort={sort}
              value={filter}
              hideFilters={hideFilters}
              inputComponent={getInputComponent(filterType)}
              translate={(key: string) => intl.formatMessage({ id: key })}
              format={
                filterType === 'date'
                  ? extractDateFormat(intl)
                  : filterType === 'time'
                  ? timeFormat
                  : undefined
              }
            />
          </div>
        </FloatingPortal>
      )}
    </FiltersWrapper>
  );
}
