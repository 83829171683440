import { observer } from 'mobx-react-lite';
import { PageHeader, Text, TextSize, TextTag } from '@yarmill/components';

import { useFilesOverviewStore } from './files-overview-store-context';
import { FormattedMessage } from 'react-intl';
import { AsyncStatus } from '../api/mobx/request-store';

function InternalFilesOverviewSummary(): JSX.Element {
  const store = useFilesOverviewStore();
  const summary = store.summary;
  const isLoading = store.status === AsyncStatus.pending;

  return (
    <PageHeader>
      <Text tag={TextTag.h1} size={TextSize.s24}>
        <FormattedMessage
          id="filesOverview.heading"
          values={{ count: isLoading ? '-' : store.files.length }}
        />
      </Text>

      <Text tag={TextTag.p} size={TextSize.s14}>
        <FormattedMessage id="filesOverview.description" />
      </Text>

      <Text tag={TextTag.p} size={TextSize.s12}>
        <FormattedMessage
          id="filesOverview.summary"
          values={{
            videos: isLoading ? '-' : summary.videos,
            images: isLoading ? '-' : summary.images,
            others: isLoading ? '-' : summary.others
          }}
        />
      </Text>
    </PageHeader>
  );
}

export const Header = observer(InternalFilesOverviewSummary);
