import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { Export } from '../export/export';
import { ExportSeasonEvaluationToXlsxRequestParams } from '../export/api/export-season-evaluation-to-xlsx';
import { useSeasonEvaluationStore } from './season-evaluation-store-context';
import { useLocale } from '../intl/hooks';
import { useUsersStore } from '../users/hooks';
import { useGroupsStore } from '../groups/hooks';
import { ExportItem, ApiExportType } from '../export/types';
import { useRootStore } from '../app/root-store-context';
import { ExportSeasonEvaluationToPdfRequestParams } from '../export/api/export-season-evaluation-to-pdf';
import { useIntl } from 'react-intl';
import { SeasonEvaluationDiaryType } from './types';

const diaryTypesMapping: Record<SeasonEvaluationDiaryType, ApiExportType> = {
  plan: 'plan',
  reality: 'reality',
  annualPlan: 'opponency'
};

function mapExportedDiaryTypes(
  diaryTypes: SeasonEvaluationDiaryType[]
): ApiExportType[] {
  return diaryTypes.map(type => diaryTypesMapping[type]);
}

export const ExportSingleSeasonEvaluationButton = observer(
  function ExportSingleSeasonEvaluation(): JSX.Element {
    const exportService = useRootStore().exportService;
    const seasonEvaluationStore = useSeasonEvaluationStore();
    const language = useLocale();
    const usersStore = useUsersStore();
    const groupsStore = useGroupsStore();
    const intl = useIntl();
    const group = groupsStore.getGroupById(seasonEvaluationStore.groupId);
    const singleSeasonStore = seasonEvaluationStore.singleSeasonEvaluationStore;
    const user = usersStore.getUserById(seasonEvaluationStore.athleteId);
    const exportFor = user ? user.displayName : group?.name;
    const fileName = `${intl.formatMessage({
      id: 'header.navigation.seasonEvaluation'
    })} - ${exportFor} - ${singleSeasonStore.currentSeason?.label}`;

    const exportToXlsx = useCallback(async () => {
      const params: ExportSeasonEvaluationToXlsxRequestParams = {
        language,
        seasonId: singleSeasonStore.currentSeason?.id || 0,
        users: seasonEvaluationStore.athleteId
          ? [seasonEvaluationStore.athleteId]
          : group?.athletes.map(u => u.id) || [],
        groups:
          !seasonEvaluationStore.athleteId && seasonEvaluationStore.groupId
            ? [seasonEvaluationStore.groupId]
            : [],
        exportTypes: mapExportedDiaryTypes(singleSeasonStore.enabledDiaryTypes)
      };

      return exportService.exportSeasonEvaluationToXlsx(params, fileName);
    }, [
      language,
      singleSeasonStore,
      seasonEvaluationStore,
      group,
      exportService,
      fileName
    ]);

    const exportToPdf = useCallback(async () => {
      const params: ExportSeasonEvaluationToPdfRequestParams = {
        language,
        seasonId: singleSeasonStore.currentSeason?.id || 0,
        userId: seasonEvaluationStore.athleteId || undefined,
        groupId:
          !seasonEvaluationStore.athleteId && seasonEvaluationStore.groupId
            ? seasonEvaluationStore.groupId
            : undefined,
        exportTypes: mapExportedDiaryTypes(singleSeasonStore.enabledDiaryTypes)
      };

      return exportService.exportSeasonEvaluationToPdf(params, fileName);
    }, [
      language,
      singleSeasonStore.currentSeason,
      singleSeasonStore.enabledDiaryTypes,
      seasonEvaluationStore.athleteId,
      seasonEvaluationStore.groupId,
      exportService,
      fileName
    ]);

    const exportItems: ExportItem[] = useMemo(
      () => [
        { format: 'xlsx', doExport: exportToXlsx },
        { format: 'pdf', doExport: exportToPdf }
      ],
      [exportToXlsx, exportToPdf]
    );

    return <Export exportItems={exportItems} />;
  }
);
