import LibTippy, { TippyProps as LibTippyProps } from '@tippyjs/react';

import { FormattedHTMLMessage } from '../../intl/formatted-html-message';
import { ComponentType } from 'react';
import styled from 'styled-components';
import { TippyContainer } from '@yarmill/components';

export interface TippyProps {
  readonly tooltipContent: LibTippyProps['content'];
  readonly isEnabled?: boolean;
  readonly children: LibTippyProps['children'];
  readonly maxWidth?: number;
  readonly isVisible?: boolean;
  readonly manual?: boolean;
  readonly placement?: LibTippyProps['placement'];
  readonly theme?: string;
  readonly trigger?: LibTippyProps['trigger'];
  readonly Wrapper?: 'span' | 'div' | 'li' | 'tspan' | ComponentType;
  readonly noWrapper?: boolean;
  readonly translateValue?: boolean;
}

const noop = () => {
  /* void */
};

const StyledTippy = styled(LibTippy)`
  ${TippyContainer};
  word-break: break-word;
`;

export function Tippy(props: TippyProps): JSX.Element {
  const {
    tooltipContent,
    children,
    isEnabled = true,
    maxWidth = 250,
    isVisible,
    manual,
    placement,
    theme,
    trigger,
    Wrapper = 'span',
    noWrapper,
    translateValue = true
  } = props;
  const tooltipId =
    typeof tooltipContent === 'string' && translateValue && isEnabled
      ? tooltipContent
      : false;
  const content = tooltipId ? (
    <FormattedHTMLMessage id={String(tooltipId)} />
  ) : (
    tooltipContent
  );

  const uncontroleddProps =
    isVisible !== undefined
      ? {}
      : {
          trigger: manual ? 'manual' : trigger || 'mouseenter',
          hideOnClick: isVisible ? undefined : !manual
        };

  const tooltipChildren = noWrapper ? children : <Wrapper>{children}</Wrapper>;

  return (
    <StyledTippy
      {...uncontroleddProps}
      arrow={true}
      content={content}
      duration={250}
      delay={250}
      disabled={!isEnabled}
      maxWidth={maxWidth}
      visible={isVisible}
      placement={placement || 'top'}
      theme={theme || 'dark'}
      moveTransition="none"
      touch={false}
      onShow={isEnabled ? noop : tooltipId ? noop : () => false}
    >
      {tooltipChildren}
    </StyledTippy>
  );
}
