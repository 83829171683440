import { useCallback, useRef } from 'react';
import { DropdownLinks } from './dropdown-links';
import { SettingsLink } from './links/settings-link';
import { useCurrentUserStore } from '../users/hooks';
import { observer } from 'mobx-react-lite';
import {
  ExternalIcon,
  NavigationLayout,
  NavigationList,
  useOutsideClick
} from '@yarmill/components';
import { isReportingLink } from './utils';
import { useMainNavButtons, useMaxVisibleItems } from './hooks';
import { NavigationLink } from './navigation-link';
import { ActiveNavigationItem } from './active-navigation-item';
import { FormattedMessage } from 'react-intl';
import { NavigationContext } from './navigation-context';
import { NavigationStore } from './mobx/navigation-store';
import { lock, unlock } from 'tua-body-scroll-lock';
import * as React from 'react';

export const Navigation = observer(function Navigation(): JSX.Element {
  const navigationStore = useRef(new NavigationStore()).current;
  const currentUser = useCurrentUserStore();
  const items = useMainNavButtons();
  const navigationLayoutRef = useRef<HTMLDivElement>(null);
  const navigationListRef = useRef<HTMLUListElement>(null);
  const [visibleElements, showNavigation] = useMaxVisibleItems(
    items.length,
    navigationListRef,
    navigationLayoutRef
  );

  const onClickOutside = useCallback(() => {
    if (navigationStore.isOpened) {
      navigationStore.closeNavigation();
    }
  }, [navigationStore]);
  useOutsideClick(navigationLayoutRef, onClickOutside);

  React.useEffect(() => {
    const ref = navigationListRef.current;

    if (navigationStore.isOpened && ref) {
      lock(ref);
    }
    return () => {
      if (ref) {
        unlock(ref);
      }
    };
  }, [navigationStore.isOpened]);

  const rootNavLinks = items.filter(i => !isReportingLink(i));

  return (
    <NavigationContext.Provider value={navigationStore}>
      <NavigationLayout
        ref={navigationLayoutRef}
        $isHidden={!showNavigation}
        data-cy="navigation"
      >
        <ActiveNavigationItem
          items={items}
          isOpened={navigationStore.isOpened}
          onClick={navigationStore.toggleNavigation}
        />
        <NavigationList
          isOpened={navigationStore.isOpened}
          ref={navigationListRef}
        >
          {rootNavLinks.slice(0, visibleElements).map((item, idx) => (
            <NavigationLink item={item} allItems={items} key={idx} />
          ))}
          <DropdownLinks
            label={<FormattedMessage id="header.navigation.other" />}
            icon={<ExternalIcon name="Diamond" />}
            links={rootNavLinks.slice(visibleElements)}
          />
          {currentUser.isAllowedTo('settings') && (
            <li>
              <SettingsLink />
            </li>
          )}
        </NavigationList>
      </NavigationLayout>
    </NavigationContext.Provider>
  );
});
