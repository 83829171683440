import { instance } from '../api/api-mapping';
import packageJson from '../../package.json';
import { when } from 'mobx';
import { AsyncStatus } from '../api/mobx/request-store';
import { RootStore } from '../app/mobx/root-store';

export let Sentry: typeof import('@sentry/browser');

export function setUpSentry(rootStore: RootStore): void {
  import('@sentry/browser')
    .then(module => {
      Sentry = module;
    })
    .then(() => {
      Sentry?.init({
        enabled: process.env.NODE_ENV === 'production' && instance !== 'test',
        attachStacktrace: true,
        dsn: 'https://d3b1819adbd546fdac75d3494c476b78@sentry.io/1369234',
        environment:
          process.env.NODE_ENV === 'production' &&
          !window.location.href.includes('test.yarmill') &&
          !window.location.href.includes('demo.yarmill')
            ? 'prod'
            : 'test',
        maxBreadcrumbs: 200,
        release: packageJson.version,
        ignoreErrors: [/_avast_submit/],
        beforeSend: event => {
          event.extra = {
            ...event.extra,
            'google-translator': document.querySelector('html')?.className
          };
          return event;
        }
      });

      when(
        () => rootStore.currentUserStore.status === AsyncStatus.resolved,
        () => {
          Sentry?.configureScope(scope => {
            const currentUser = rootStore.currentUserStore;
            scope.setUser({
              email: currentUser?.data?.Email,
              id: String(currentUser?.id),
              segment: currentUser?.data?.Role
            });
          });
        }
      );
    });
}
