import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { Permission } from '../../permissions/types';

export function getCurrentUserRightsToGroups(
  userId: number
): AxiosPromise<Permission[]> {
  return axios.get(`api/UserGroupUserPermission`, {
    params: {
      userId
    }
  });
}
