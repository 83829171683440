import { GeneralDashboardStore } from './mobx/general-dashboard-store';
import { SlcrDashboardStore } from './mobx/slcr-dashboard-store';

export interface Filter {
  Ident: string;
  Title: string;
  Type: 'select-box' | 'radio';
  Options: FilterValue[];
  Value: string;
}

export interface FilterValue {
  Title: string;
  Value: string | number;
}

export interface ApiFilters {
  [key: string]: string | number | undefined;
}

export interface Dashboard {
  Ident: DashboardType;
  Label: string;
  Description: string;
}

export type DashboardStore = GeneralDashboardStore | SlcrDashboardStore;

export type DashboardType =
  | 'general.defaultDashboard'
  | 'slcr.perGroupDashboard'
  | 'slcr.perActivityDashboard'
  | 'sps-slcr.perGroupDashboard'
  | 'sps-slcr.perActivityDashboard';

export const dashboardTypes: DashboardType[] = [
  'general.defaultDashboard',
  'slcr.perActivityDashboard',
  'slcr.perGroupDashboard',
  'sps-slcr.perActivityDashboard',
  'sps-slcr.perGroupDashboard'
];

export interface AnalyticsRouteParams {
  activeDashboard: DashboardType;
}

export const USER_URL_FILTER = 'UserId';
export const GROUP_URL_FILTER = 'UserGroupId';
