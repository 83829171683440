import { FormattedMessage } from 'react-intl';
import { Text } from '../components-2/text';
import { KeyResultCheckbox } from '../components-2/okrs/key-result-checkbox';
import {
  KeyResultsTableSelectAll,
  KeyResultsTableTh
} from '../components-2/okrs/detail/key-results-list';
import { FlexCenterContainer } from '../components-2/flex-utils';
import { forwardRef, useCallback, useContext } from 'react';
import { KeyResultsSelectionContext } from './key-results-selection-context';
import { observer } from 'mobx-react-lite';
import { useOkrsStore } from './okrs-store-context';
import { ObjectiveStore } from './mobx/objective-store';

function HeaderCell({ label }: { label: string }): JSX.Element {
  return (
    <KeyResultsTableTh>
      <FlexCenterContainer>
        <Text align="center" appearance="button10" inheritColor upperCase>
          <FormattedMessage id={label} />
        </Text>
      </FlexCenterContainer>
    </KeyResultsTableTh>
  );
}

interface SelectionHeaderProps {
  readonly objective: ObjectiveStore;
}
const SelectionHeader = observer(function SelectionHeader({
  objective
}: SelectionHeaderProps): JSX.Element {
  const okrsStore = useOkrsStore();
  const selectionStore = useContext(KeyResultsSelectionContext);
  const keyResults = objective.keyResults;
  const isAllSelected =
    keyResults.length === selectionStore.selectedKeyResults.size;
  const handleClick = useCallback(() => {
    const ids = keyResults.map(k => k.uid);

    if (ids.length === selectionStore.selectedKeyResults.size) {
      selectionStore.unSelectKeyResults(ids);
    } else {
      selectionStore.selectKeyResults(ids);
    }
  }, [keyResults, selectionStore]);

  const isDisabled = !okrsStore.isCurrentUserAllowedToWrite(objective);

  return (
    <>
      <KeyResultsTableTh textAlign="left">
        <KeyResultsTableSelectAll disabled={isDisabled}>
          <KeyResultCheckbox
            onClick={handleClick}
            isChecked={isAllSelected}
            disabled={isDisabled}
          />
        </KeyResultsTableSelectAll>
      </KeyResultsTableTh>
      <KeyResultsTableTh />
    </>
  );
});

interface KeyResultsTableHeaderProps {
  readonly objective: ObjectiveStore;
}

// const otherColumnsWidth = 270;
export const KeyResultsTableHeader = forwardRef<
  HTMLTableSectionElement,
  KeyResultsTableHeaderProps
>(function KeyResultsTableHeader({ objective }, ref): JSX.Element {
  return (
    <>
      <thead ref={ref}>
        <tr>
          <KeyResultsTableTh />
          <SelectionHeader objective={objective} />
          <HeaderCell label="okrs.form.keyResult.title.label" />
          <HeaderCell label="okrs.form.keyResult.startValue.label" />
          <HeaderCell label="okrs.form.keyResult.currentValue.label" />
          <HeaderCell label="okrs.form.keyResult.targetValue.label" />
          <HeaderCell label="okrs.form.keyResult.targetDate.label" />
          <KeyResultsTableTh>&nbsp;</KeyResultsTableTh>
        </tr>
      </thead>
    </>
  );
});
