export const translations = {
  en: {
    'disabledInstance.header': '🚧 Under maintenance',
    'disabledInstance.text': "We'll be back soon with updated Yarmill."
  },
  sk: {
    'disabledInstance.header': '🚧 Under maintenance',
    'disabledInstance.text': "We'll be back soon with updated Yarmill."
  },
  cs: {
    'disabledInstance.header': '🚧 Probíhají úpravy',
    'disabledInstance.text':
      'Brzo bude aktualizovaná verze Yarmilla zase fungovat.'
  }
};
