import { Button, ButtonAppearance } from '../../components-2/button';
import { Icon, IconSize } from '@yarmill/components';
import { Cancel } from '@yarmill/icons-2';
import { useIntl } from 'react-intl';
import { useContext } from 'react';
import { KeyResultsSelectionContext } from '../key-results-selection-context';
import { Tippy } from '../../components/tippy/tippy';

export function CancelSelection(): JSX.Element {
  const intl = useIntl();
  const selectionStore = useContext(KeyResultsSelectionContext);
  async function handleClick() {
    selectionStore.clear();
  }

  return (
    <Tippy tooltipContent="okrs.detail.keyResults.cancelSelection" noWrapper>
      <Button
        $appearance={ButtonAppearance.Tertiary}
        $appearanceStyle="neutral"
        onClick={handleClick}
        $iconOnly
        aria-label={intl.formatMessage({
          id: 'okrs.detail.keyResults.cancelSelection'
        })}
      >
        <Icon size={IconSize.s24}>
          <Cancel />
        </Icon>
      </Button>
    </Tippy>
  );
}
