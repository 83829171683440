import {
  AddGroupButtonsContainer,
  AddGroupFieldSet,
  AddGroupLayout,
  Button,
  FormControlVariant,
  FullScreenLayerCloseButtonWrapper,
  Text,
  TextInput,
  TextSize,
  TextTag
} from '@yarmill/components';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import escape from 'validator/lib/escape';
import { ServerError } from '../app/app-types';
import { AsyncButton } from '../components/async-button/async-button';

import { UserSelect } from './user-select';
import { toast } from '../components/toast-message';
import { useRootStore } from '../app/root-store-context';
import { observer } from 'mobx-react-lite';
import { Layer } from '../layer-manager/mobx/layer';
import { CloseButton } from '../components/close-button/close-button';

export interface PassedAddGroupProps {
  layer: Layer;
}

export const AddGroup = observer(function AddGroup({
  layer
}: PassedAddGroupProps) {
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState<{
    error: boolean;
    errorMessages: ServerError[];
  }>({
    error: false,
    errorMessages: []
  });
  const [name, setName] = React.useState('');
  const [userIds, setUserIds] = React.useState<number[]>([]);
  const rootStore = useRootStore();
  const intl = useIntl();

  React.useEffect(() => {
    function handleClose(evt: KeyboardEvent): void {
      if (evt.key === 'Escape') {
        layer.close();
      }
    }

    window.addEventListener('keydown', handleClose);

    return () => window.removeEventListener('keydown', handleClose);
    // we don't want to run useEffect on location change
    // eslint-disable-next-line
  }, []);

  const onNameChange = (evt: React.FormEvent<HTMLInputElement>): void => {
    const { target } = evt;
    if (!(target instanceof HTMLInputElement)) {
      return;
    }
    const { value } = target;
    setName(value);
  };

  const onKeyDown = (evt: React.KeyboardEvent): void => {
    if (evt.key === 'Enter') {
      submit();
    }
  };

  const onUserChange = (userIds: { label: string; value: number }[]): void => {
    const ids = userIds ? userIds.map(option => option.value) : [];
    setUserIds(ids);
  };

  const submit = async (): Promise<void> => {
    if (name && name.trim()) {
      const validName = escape(name.trim());

      setLoading(true);
      const groupsStore = rootStore.groupsStore;

      try {
        const group = await groupsStore.createGroup(validName, userIds);
        if (group) {
          setLoading(false);
          setLoaded(true);
          toast('toast.success.createGroup', 'success', { group: name });
          setTimeout(() => layer.close(), 800);
        }
      } catch (e: unknown) {
        toast('toast.error.createGroup', 'error', { group: name });
        setLoaded(true);
        setLoading(false);
        setError({
          error: true,
          errorMessages: e as ServerError[]
        });
      }
    }
  };

  const nameError = error.errorMessages
    ? error.errorMessages.find(
        e => e.Field === 'Name' && e.Values?.name === name
      )
    : null;

  return (
    <>
      <AddGroupLayout>
        <Text size={TextSize.s24} tag={TextTag.h1}>
          <FormattedMessage id="settings.groups.createNew" />
        </Text>
        <form>
          <AddGroupFieldSet disabled={loading}>
            <div>
              <TextInput
                required
                variant={FormControlVariant.big}
                autoFocus
                onKeyDown={onKeyDown}
                label={intl.formatMessage({
                  id: 'settings.groups.createNew.name.label'
                })}
                id="group-name"
                error={
                  nameError
                    ? intl.formatMessage(
                        { id: nameError.Id },
                        { group: nameError.Values?.name }
                      )
                    : ''
                }
                onChange={onNameChange}
                value={name}
                type="text"
                placeholder={intl.formatMessage({
                  id: 'settings.groups.createNew.name.placeholder'
                })}
              />
            </div>

            <div>
              <Text tag={TextTag.div} medium size={TextSize.s14}>
                <FormattedMessage id="settings.groups.createNew.invites.title">
                  {msg => <>{msg}</>}
                </FormattedMessage>
                &nbsp;
                <Text light size={TextSize.s14}>
                  <FormattedMessage id="settings.groups.createNew.invites.optional">
                    {msg => <>{msg}</>}
                  </FormattedMessage>
                </Text>
              </Text>
              <UserSelect onChange={onUserChange} handleSubmit={submit} />
            </div>

            <AddGroupButtonsContainer>
              <Button
                type="button"
                variant={FormControlVariant.big}
                onClick={layer.close}
              >
                <FormattedMessage id="settings.groups.createNew.reset" />
              </Button>
              <AsyncButton
                onClick={submit}
                disabled={!name || Boolean(nameError)}
                loading={loading}
                success={loaded && !error.error}
                variant={FormControlVariant.big}
                dataTest="create-group"
              >
                <FormattedMessage id="settings.groups.createNew.submit" />
              </AsyncButton>
            </AddGroupButtonsContainer>
          </AddGroupFieldSet>
        </form>
      </AddGroupLayout>
      <FullScreenLayerCloseButtonWrapper>
        <CloseButton onClick={layer.close} />
      </FullScreenLayerCloseButtonWrapper>
    </>
  );
});
