import { RootStore } from '../../app/mobx/root-store';
import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap,
  ObservableSet,
  runInAction,
  when
} from 'mobx';
import { intlCookies, Locale, LocaleVersion } from '../types';
import { cs, en, sk } from '../default-messages';
import { AsyncStatus } from '../../api/mobx/request-store';
import { getTranslations } from '../api/get-translations';
import { sendMissingTranslations } from '../api/send-missing-translations';

export class IntlStore {
  private readonly _rootStore: RootStore;

  @observable
  private _version: LocaleVersion | null = null;

  @observable
  private _locale: Locale | null = null;

  @observable
  private messageFallbacks: ObservableMap<
    Locale,
    ObservableMap<string, string>
  > = observable.map();

  @observable
  private _missingTranslations: ObservableMap<string, string> =
    new ObservableMap();

  @observable
  private _reportedMissingTranslations: ObservableSet<string> =
    new ObservableSet();

  private _missingTranslationsTimeout: ReturnType<typeof setTimeout> | null =
    null;

  @observable
  private readonly _messages: Record<Locale, Record<string, string>> = {
    cs,
    en,
    sk,
    de: {},
    it: {},
    fr: {}
  };

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeObservable(this);
    this.restorePersistedLocalMessages();
  }

  public async load(): Promise<void> {
    const loadPromise = this.loadDefaultTranslations();

    when(
      () => this._rootStore.currentUserStore.status === AsyncStatus.resolved,
      () => this.loadPersonalizedTranslations()
    );

    await loadPromise;
  }

  @computed
  public get messages(): Record<string, string> {
    return {
      ...this._messages[this.locale],
      ...Object.fromEntries(
        this.messageFallbacks.get(this.locale)?.entries() ?? []
      )
    };
  }

  public get locale(): Locale {
    return this._locale ?? 'en';
  }

  @computed
  public get shouldReportMissingTranslations(): boolean {
    return this._version === 'api';
  }

  private async loadDefaultTranslations(): Promise<void> {
    if (this._version === null) {
      await this.loadTranslations();
    }
  }

  private async loadPersonalizedTranslations(): Promise<void> {
    const personalLocale =
      this._rootStore.currentUserStore.getSetting('app.language');

    if (
      (personalLocale && personalLocale !== this._locale) ||
      this._version === 'local'
    ) {
      await this.loadTranslations(personalLocale);
    }
    // load defaults
    if (this._locale !== 'en' && personalLocale !== 'en') {
      await this.loadTranslations('en', true);
    }
  }

  public async loadTranslations(
    locale?: Locale,
    doNotSwitchLocale?: boolean
  ): Promise<void> {
    const localeToLoad = locale ?? this._locale;
    const request = this._rootStore.requestsStore.createRequest(cancelToken =>
      getTranslations(localeToLoad, cancelToken)
    );
    const response = await request.getResponse();

    if (response) {
      runInAction(() => {
        if (!doNotSwitchLocale) {
          this._locale = localeToLoad;
          this._version = 'api';
        }
        if (!localeToLoad) {
          const language = response.language;
          if (!doNotSwitchLocale) {
            this._locale = language as Locale;
          }
          this._messages[language] = response;
        } else {
          this._messages[localeToLoad] = response;
        }

        this.persistCurrentMessages();
      });
    }
  }

  @action
  public reportMissingTranslation(
    code: string,
    url: string,
    errorCode: string
  ): void {
    if (!this._reportedMissingTranslations.has(code) && this._locale) {
      this._missingTranslations.set(code, url);
      if (!this.messageFallbacks.has(this._locale)) {
        this.messageFallbacks.set(this._locale, observable.map());
      }
      if (errorCode !== 'FORMAT_ERROR') {
        this.messageFallbacks
          .get(this._locale)
          ?.set(code, this._messages['en'][code]);
      }
    }

    if (this._missingTranslationsTimeout) {
      clearTimeout(this._missingTranslationsTimeout);
    }
    this._missingTranslationsTimeout = setTimeout(async () => {
      await this.sendMissingTranslations();
    }, 5000);
  }

  private async sendMissingTranslations(): Promise<void> {
    const codes = Array.from(this._missingTranslations.entries());
    if (codes.length === 0) {
      return;
    }

    this._missingTranslations.clear();
    const data = codes.map(([code, url]) => ({ code, url }));
    data.forEach(t => this._reportedMissingTranslations.add(t.code));

    const request = this._rootStore.requestsStore.createRequest(() =>
      sendMissingTranslations(this._locale, data)
    );

    await request.getResponse();
  }

  @action
  private restorePersistedLocalMessages(): void {
    const persistedLanguage = window.localStorage.getItem(intlCookies.LOCALE);
    const persistedMessages = window.localStorage.getItem(intlCookies.MESSAGES);

    if (persistedLanguage && persistedMessages) {
      try {
        this._locale = persistedLanguage as Locale;
        this._messages[this._locale] = JSON.parse(persistedMessages);
        this._version = 'local';
      } catch (e) {
        console.warn('INTL: unable to load persisted translations');
      }
    }
  }

  private persistCurrentMessages(): void {
    if (this._locale) {
      window.localStorage.setItem(intlCookies.LOCALE, this._locale);
    }
    window.localStorage.setItem(
      intlCookies.MESSAGES,
      JSON.stringify(this.messages)
    );
  }

  public get version() {
    return this._version;
  }
}
