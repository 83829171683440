import { UserId } from '../../users/types';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { CsvTableRowData } from '../../csv-table/types';

export interface GetSeasonOverSeasonParams {
  userId?: UserId;
}

export type GetSeasonOverSeasonResponse = {
  Data: CsvTableRowData[];
}[];

export function getSeasonOverSeasonData(
  params: GetSeasonOverSeasonParams,
  cancelToken: CancelTokenSource
): AxiosPromise<GetSeasonOverSeasonResponse> {
  return axios.get('/api/rtc', {
    params,
    cancelToken: cancelToken.token
  });
}
