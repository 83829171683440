import { FormattedMessage, useIntl } from 'react-intl';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import { EventLocation, PlannerEvent } from '../types';
import { useFormikContext } from 'formik';
import { Search, Location as LocationIcon } from '@yarmill/icons-2';
import { IntegratedRightPanelInput } from '../../components-2/integrated-right-panel-input';
import { Location } from '../../#-components/planner/location';
import { useRootStore } from '../../app/root-store-context';
import debounce from 'lodash.debounce';
import {
  LocationSearchResultsHeading,
  LocationStepLayout
} from '../../#-components/planner/form';
import { PlannerFormContext } from './planner-form-context';
import { RightPanelContent } from '../../components-2/right-panel';
import { FormStepHeader } from './form-step-header';
import {
  SelectionList,
  SelectionListItem
} from '../../components-2/selection-list';
import {
  KeyboardListNavigationContext,
  useKeyboardListNavigation
} from '../../utils/use-keyboard-list-navigation';

export function LocationStep(): JSX.Element {
  const intl = useIntl();
  const { setCurrentStep } = useContext(PlannerFormContext);
  const formik = useFormikContext<PlannerEvent>();
  const rootStore = useRootStore();
  const googleMapsService = rootStore.googleMapsService;
  const selectedLocation = formik.values.location;
  const [searchResults, setSearchResults] = useState<EventLocation[]>();
  const keyboardNavigationContext = useKeyboardListNavigation();

  const searchPlaces = useRef(
    debounce(async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target instanceof HTMLInputElement) {
        const searchTerm = e.target.value;
        if (searchTerm.length >= 3) {
          const searchResults = await googleMapsService.searchPlaces(
            searchTerm
          );
          if (searchResults) {
            setSearchResults(searchResults);
          }
        }
      }
    }, 500)
  );

  const onSelectLocation = useCallback(
    (item: SelectionListItem | null) => {
      if (!item) {
        return;
      }
      const location = searchResults?.[item.value];
      formik.setFieldValue('location', location);
      formik.setFieldValue('locationType', 'place');
      setCurrentStep('details');
    },
    [searchResults, formik, setCurrentStep]
  );

  const searchResultItems: SelectionListItem[] = useMemo(
    () =>
      searchResults?.map((result, idx) => ({
        icon: <LocationIcon />,
        value: String(idx),
        title: result.displayName?.text ?? result.name ?? 'No Name',
        description: result.formattedAddress
      })) ?? [],
    [searchResults]
  );

  return (
    <>
      <FormStepHeader />
      <KeyboardListNavigationContext.Provider value={keyboardNavigationContext}>
        <RightPanelContent
          noPadding
          {...keyboardNavigationContext.getListProps()}
        >
          <IntegratedRightPanelInput
            name="search-location"
            placeholder={intl.formatMessage({ id: 'planner.searchLocation' })}
            icon={<Search />}
            onChange={searchPlaces.current}
            autoFocus={!selectedLocation}
          />
          <LocationStepLayout>
            {selectedLocation && formik.values.locationType === 'place' && (
              <Location
                title={selectedLocation?.displayName?.text ?? '---'}
                description={selectedLocation.formattedAddress}
                onClick={() => setCurrentStep('details')}
                isSelected
              />
            )}
            <Location
              noLocation
              title={intl.formatMessage({ id: 'planner.location.noLocation' })}
              onClick={() => onSelectLocation(null)}
              isSelected={!selectedLocation}
            />

            {searchResults?.length && (
              <>
                <LocationSearchResultsHeading
                  as="h3"
                  appearance="button10"
                  upperCase
                >
                  <FormattedMessage id="planner.location.searchResults" />
                </LocationSearchResultsHeading>

                <SelectionList
                  items={searchResultItems}
                  inputType="radio"
                  onClick={onSelectLocation}
                />
              </>
            )}
          </LocationStepLayout>
        </RightPanelContent>
      </KeyboardListNavigationContext.Provider>
    </>
  );
}
