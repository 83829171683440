import {
  FormControlVariant,
  SelectAppearance,
  SelectBox,
  SelectValueProps,
  Text,
  TextSize,
  TextTag,
  WhiteSpace
} from '@yarmill/components';
import { useCallback } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { getWeekEnd, getWeekStart } from '../diary/utils';
import { trackCopyModeChange } from '../google-analytics/utils';
import { CopyMode } from './types';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../app/root-store-context';
import { useDiaryStore } from '../diary/diary-store-context';

const StyledHeadline = styled.h1`
  text-align: center;
  font-weight: normal;
  margin: 0 0 12px;

  @media (min-width: 576px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
StyledHeadline.displayName = 'StyledHeadline';

const StyledHeadlineText = styled(Text)`
  display: inline-block;
`;

const StyledSelectWrapper = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 330px;
  margin-top: 15px;
  text-align: left;

  .react_select__single-value {
    margin-left: 25px;
    font-size: 24px;
    font-weight: 400;
  }

  @media (min-width: 576px) {
    margin-left: 25px;
    margin-top: 0;
  }
`;
export interface HeadlineProps {
  onChange(mode: CopyMode): void;
}

export const Header = observer(function Header(
  props: HeadlineProps
): JSX.Element {
  const rootStore = useRootStore();
  const diaryStore = useDiaryStore();
  const name = diaryStore.athleteId
    ? rootStore.usersStore.getUserById(diaryStore.athleteId)?.displayName
    : rootStore.groupsStore.getGroupById(diaryStore.groupId)?.name;
  const intl = useIntl();

  const onChange = useCallback(
    (select: SelectValueProps): void => {
      props.onChange(select.value as CopyMode);
      trackCopyModeChange(select.value as CopyMode);
    },
    [props]
  );

  const getTranslateId = (mode: CopyMode): string => {
    let id = mode.replace('season', '');
    id = id.charAt(0).toLowerCase() + id.slice(1);

    return `plan.copy.${id}`;
  };

  return (
    <StyledHeadline>
      <StyledHeadlineText
        data-cy="copy-headline"
        tag={TextTag.div}
        size={TextSize.s24}
      >
        <FormattedMessage
          id={`${
            diaryStore.viewType === 'goals' ? 'goals' : 'plan'
          }.copy.headline`}
          values={{ name: <strong>{name}</strong> }}
        />
        {diaryStore.viewType === 'goals' && (
          <Text
            tag={TextTag.span}
            whiteSpace={WhiteSpace.noWrap}
            size={TextSize.s24}
          >
            :&nbsp;
            <FormattedDate
              value={getWeekStart(diaryStore.week).toDate()}
              day="2-digit"
              month="2-digit"
            />
            &nbsp;-&nbsp;
            <FormattedDate
              value={getWeekEnd(diaryStore.week).toDate()}
              day="2-digit"
              month="2-digit"
            />
          </Text>
        )}
      </StyledHeadlineText>
      &nbsp;
      <StyledSelectWrapper>
        <SelectBox
          id="copy-mode"
          data-cy="copy-mode-select"
          appearance={SelectAppearance.Secondary}
          label=""
          variant={FormControlVariant.big}
          defaultValue={{
            label: intl.formatMessage({
              id: getTranslateId(diaryStore.copyModes[0])
            }),
            value: diaryStore.copyModes[0]
          }}
          options={diaryStore.copyModes.map(mode => ({
            label: intl.formatMessage({ id: getTranslateId(mode) }),
            value: mode
          }))}
          isSearchable={false}
          onChange={onChange}
          noSeparator
          noExtraLabel
          disablePortal
        />
      </StyledSelectWrapper>
    </StyledHeadline>
  );
});
