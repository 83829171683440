export enum AttendanceValue {
  Present = 1,
  Free = 2,
  Excused = 3,
  Unexcused = 4
}

export const attendanceValues = [
  AttendanceValue.Present,
  AttendanceValue.Free,
  AttendanceValue.Excused,
  AttendanceValue.Unexcused
] as const;

export interface WeekAttendanceItem {
  Value: AttendanceValue;
  Date: string;
  UserId: number;
}

export interface UserSummaryAttendance {
  UserId: number;
  TotalPresent: number;
  TotalMissed: number;
  TotalPercentage: number;
  Attendances: SummaryAttendanceItem[];
}

export interface SummaryAttendanceItem {
  Date: string;
  Present: number;
  Missed: number;
  Percentage: number;
}

export type SumColumnType = 'present' | 'missed' | 'percentage';

export function isSumColumnType(x: string): x is SumColumnType {
  return x === 'present' || x === 'missed' || x === 'percentage';
}

export type ViewType = 'week' | 'season';

export const PHASE_SEARCH_PARAM = 'phase';

export interface AttendancePhase {
  module: string;
  name: string;
  activityItemId: number | null;
  sortCode: number;
  isEditable: boolean;
}
