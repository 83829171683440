import {
  Button,
  ButtonAppearance,
  DescriptiveText,
  GroupsHeaderLayout,
  Text,
  TextSize,
  TextTag
} from '@yarmill/components';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Tippy } from '../components/tippy/tippy';
import { trackInviteUsersToGroupClick } from '../google-analytics/utils';
import { AddUserToGroup } from './add-user-to-group';
import { observer } from 'mobx-react-lite';
import { useGroupsStore } from './hooks';
import { useCurrentUserStore } from '../users/hooks';
import { useLayer } from '../layer-manager/hooks';
import { LayerPortal } from '../layer-manager/layer-portal';

export interface GroupsToolbarProps {
  readonly groupId: number;
}

const StyledOpenButtonWrapper = styled.div`
  display: inline-block;
`;
StyledOpenButtonWrapper.displayName = 'StyledOpenButtonWrapper';

const StyledCloseButtonWrapper = styled.div`
  z-index: 1000;
  position: absolute;
  top: 80px;
  right: 10px;

  @media (min-width: 576px) {
    top: 100px;
  }

  @media (min-width: 991px) {
    top: 120px;
    right: 40px;
  }

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
StyledCloseButtonWrapper.displayName = 'StyledCloseButtonWrapper';

export const GroupsToolbar = observer(function GroupsToolbar(
  props: GroupsToolbarProps
): JSX.Element {
  const { groupId } = props;
  const group = useGroupsStore().getGroupById(groupId);
  const currentUser = useCurrentUserStore();
  const athletes = group?.athletes;
  const coaches = group?.coaches;
  const inviteUsersLayer = useLayer('full-screen');

  const showLayer = useCallback(() => {
    trackInviteUsersToGroupClick();
    if (!inviteUsersLayer.isOpened) {
      inviteUsersLayer.open();
    }
  }, [inviteUsersLayer]);

  return (
    <>
      <GroupsHeaderLayout>
        <div>
          <Text tag={TextTag.h2} size={TextSize.s16} medium>
            <FormattedMessage id="settings.groups.toolsbar.members" />
          </Text>
          <DescriptiveText size={TextSize.s14} tag={TextTag.div}>
            <FormattedMessage
              id="settings.groups.toolsbar.membersCount"
              values={{ athletes: athletes?.length, coaches: coaches?.length }}
            />
          </DescriptiveText>
        </div>
        <div>
          <Tippy
            tooltipContent="settings.groups.tooltip.adminAction.text"
            isEnabled={!currentUser.isAdmin}
          >
            <StyledOpenButtonWrapper>
              <Button
                appearance={ButtonAppearance.Primary}
                onClick={currentUser.isAdmin ? showLayer : undefined}
                disabled={!currentUser.isAdmin}
                square
                data-cy="add-users-to-group"
              >
                +
              </Button>
            </StyledOpenButtonWrapper>
          </Tippy>
        </div>
      </GroupsHeaderLayout>
      <LayerPortal
        layerHandle={inviteUsersLayer}
        getContent={layer => <AddUserToGroup closeLayer={layer.close} />}
      />
    </>
  );
});
