import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Sentry } from '../../sentry/set-up-sentry';
import { FormattedMessage } from 'react-intl';

export class RichTextErrorBoundary extends React.PureComponent<
  PropsWithChildren,
  { error?: boolean }
> {
  public readonly state = { error: false };

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error: true });
    Sentry?.captureException(error);
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    return error ? <FormattedMessage id="richtext.error" /> : children;
  }
}
