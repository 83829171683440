import {
  CSSProperties,
  MouseEvent,
  useCallback,
  useEffect,
  useRef
} from 'react';
import { Line } from '@visx/shape';
import styled from 'styled-components';
import { useTooltip } from '@visx/tooltip';
import { FunctionComponent } from 'react';
import { TooltipInPortalProps } from '@visx/tooltip/lib/hooks/useTooltipInPortal';

import { localPoint } from '@visx/event';

import { ScaleTypeToD3Scale } from '@visx/scale';
import { BaseWorkoutData } from './index';
import { bisector } from 'd3-array';
import * as React from 'react';
import { Text } from '@visx/text';

export interface SyncTooltipHandlers {
  showTooltip: (index: number, tooltipTop: number, tooltipLeft: number) => void;
  hideTooltip: () => void;
}

export interface TooltipProps {
  xScale: ScaleTypeToD3Scale<number>['linear'];
  yScale: ScaleTypeToD3Scale<number>['linear'];
  TooltipInPortal: FunctionComponent<TooltipInPortalProps>;
  showXAxisLabelsInTooltip?: boolean;
  width: number;
  height: number;
  innerHeight: number;
  leftMargin: number;
  data: BaseWorkoutData[];
  formatTooltip?: (value: BaseWorkoutData) => React.ReactNode;
  formatTooltipCursor?: (value: number) => React.ReactNode;
  xAxisKey: string;
  yAxisKey: string;
  yMax: number;
  syncTooltipHandlers?: SyncTooltipHandlers;
  setSyncTooltipHandlers?: (handlers: SyncTooltipHandlers) => () => void;
}

const StyledTooltipBar = styled.rect`
  fill: transparent;
`;

const tooltipStyle: CSSProperties = {
  top: 0,
  left: 0,
  position: 'absolute',
  backgroundColor: 'transparent',
  pointerEvents: 'none',
  zIndex: 10000
};

const StyledChartTooltip = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-size: 12px;
  font-family: 'Roboto Mono', serif;
  transform: translateY(10%) translateX(50%);
  transform-origin: center;
`;

const TOOLTIP_TIMEOUT = 50;

function getClosestValue(
  bisectValue: (data: BaseWorkoutData[], value: number) => number,
  data: BaseWorkoutData[],
  axisValue: number,
  xAxisKey: string
): BaseWorkoutData {
  const index = bisectValue(data, axisValue);
  const d0 = data[index - 1];
  const d1 = data[index];
  let d = d0;
  const dataValue0 = d0?.[xAxisKey];
  const dataValue1 = d1?.[xAxisKey];

  if (d1 && dataValue1 && dataValue0) {
    d = axisValue - dataValue0 > (dataValue1 || 0) - dataValue0 ? d1 : d0;
    if (d === d1) {
    }
  } else if (!d && d1) {
    d = d1;
  }

  return d;
}

export function Tooltip(props: TooltipProps): JSX.Element {
  const {
    TooltipInPortal,
    width,
    height,
    xScale,
    innerHeight,
    leftMargin,
    data,
    formatTooltip,
    formatTooltipCursor,
    xAxisKey,
    yAxisKey,
    syncTooltipHandlers,
    setSyncTooltipHandlers,
    yScale
  } = props;
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
  } = useTooltip<BaseWorkoutData>();
  const tooltipTimeout = useRef<number>(0);
  const bisectValue = bisector<BaseWorkoutData, number>((d, x) => {
    const value = d[xAxisKey];
    return value ? value - x : Number.MAX_VALUE;
  }).left;

  const handleMouseLeave = () => {
    tooltipTimeout.current = window.setTimeout(() => {
      hideTooltip();
      syncTooltipHandlers?.hideTooltip();
    }, TOOLTIP_TIMEOUT);
  };

  const showSyncedTooltip = useCallback(
    (axisValue: number, tooltipTop: number, tooltipLeft: number) => {
      const lastDataItem = data[data.length - 1];
      if (axisValue > (lastDataItem?.[xAxisKey] ?? 0)) {
        hideTooltip();
        return;
      }

      const d = getClosestValue(bisectValue, data, axisValue, xAxisKey);

      showTooltip({
        tooltipData: d,
        tooltipTop,
        tooltipLeft
      });
    },
    [bisectValue, data, showTooltip, xAxisKey, hideTooltip]
  );

  useEffect(() => {
    const unmount = setSyncTooltipHandlers?.({
      hideTooltip,
      showTooltip: showSyncedTooltip
    });

    return () => {
      unmount?.();
    };
  }, [hideTooltip, setSyncTooltipHandlers, showSyncedTooltip]);

  return (
    <>
      <StyledTooltipBar
        width={width}
        x={0}
        y={0}
        height={height}
        onMouseMove={(event: MouseEvent) => {
          if (tooltipTimeout.current) {
            clearTimeout(tooltipTimeout.current);
          }

          const mousePosition = localPoint(event);
          const tooltipLeft = (mousePosition?.x || 0) - leftMargin;
          const axisValue = xScale.invert(tooltipLeft);
          const tooltipTop = mousePosition?.y || 0;

          syncTooltipHandlers
            ? syncTooltipHandlers?.showTooltip(
                axisValue,
                tooltipTop,
                tooltipLeft
              )
            : showTooltip({
                tooltipData: getClosestValue(
                  bisectValue,
                  data,
                  axisValue,
                  xAxisKey
                ),
                tooltipTop,
                tooltipLeft
              });
        }}
        onMouseLeave={handleMouseLeave}
      />
      {tooltipOpen && (
        <>
          <Line
            from={{ x: tooltipLeft, y: 0 }}
            to={{ x: tooltipLeft, y: innerHeight }}
            stroke="#ccc"
            strokeWidth={1}
            pointerEvents="none"
          />
          {tooltipData &&
            tooltipData[xAxisKey] !== null &&
            tooltipData[yAxisKey] !== null && (
              <circle
                cx={xScale(tooltipData[xAxisKey] || 0)}
                cy={yScale(tooltipData[yAxisKey] || 0)}
                r={4}
                fill="#4a4a4a"
              />
            )}
          {tooltipData && tooltipData[xAxisKey] !== null && (
            <Text
              y={innerHeight}
              x={tooltipLeft}
              textAnchor="middle"
              verticalAnchor="end"
              fill="#4A4A4A"
              fontSize={12}
              fontFamily="Roboto Mono"
              dy="1.65em"
            >
              {(formatTooltipCursor?.(tooltipData[xAxisKey] || 0) as string) ??
                tooltipData[xAxisKey]}
            </Text>
          )}
        </>
      )}
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyle}
        >
          <StyledChartTooltip
            key={`${tooltipData[xAxisKey]}-${tooltipData[yAxisKey]}`}
          >
            {formatTooltip?.(tooltipData)}
          </StyledChartTooltip>
        </TooltipInPortal>
      )}
    </>
  );
}
