import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import { ApiExportType } from '../types';
import { Locale } from '../../intl/types';

export interface ExportSeasonEvaluationToPdfRequestParams {
  seasonId: number;
  userId: UserId | undefined;
  groupId: UserGroupId | undefined;
  exportTypes: ApiExportType[];
  language: Locale;
}

export function exportSeasonEvaluationToPdf(
  params: ExportSeasonEvaluationToPdfRequestParams,
  cancelToken: CancelTokenSource
): AxiosPromise<BlobPart> {
  return axios.post(`api/print/rtc`, params, {
    responseType: 'blob',
    cancelToken: cancelToken.token
  });
}
