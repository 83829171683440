export type LessOrEqualValue = { type: 'lessorequal'; values: [string] };
export type GreaterOrEqualValue = { type: 'greaterorequal'; values: [string] };
export type RangeValue = { type: 'range'; from: string; to: string };
export type MatchValue = { type: 'match'; values: [string] };

export type PredefinedDateValue =
  | 'thisweek'
  | 'lastweek'
  | 'thismonth'
  | 'lastmonth';

export type DateFilter =
  | { type: PredefinedDateValue }
  | RangeValue
  | MatchValue;

export type TimeFilter =
  | LessOrEqualValue
  | GreaterOrEqualValue
  | RangeValue
  | MatchValue;

export type NumberFilter = TimeFilter;

export type TableFilterType = 'text' | 'options' | 'date' | 'time' | 'number';
export type Value = string | string[] | DateFilter | TimeFilter | undefined;
export type Sort = 'ASC' | 'DESC' | null;

export function formatFilterValue(value: Value, type: TableFilterType): Value {
  if (type === 'date' || type === 'time') {
    if (
      typeof value === 'object' &&
      'type' in value &&
      value.type === 'range'
    ) {
      if (!value.from && !value.to) {
        return '';
      } else if (!value.from) {
        return { type: 'lessorequal', values: [value.to] };
      } else if (!value.to) {
        return { type: 'greaterorequal', values: [value.from] };
      }
    } else if (
      typeof value === 'object' &&
      'values' in value &&
      !value.values[0]
    ) {
      return '';
    }
  }

  return value;
}
