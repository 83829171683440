import {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';

interface KeyboardShortcutsContextValue {
  readonly enabled: MutableRefObject<boolean>;
  readonly enable: () => void;
  readonly disable: () => void;
}
export const KeyboardShortcutsContext =
  createContext<KeyboardShortcutsContextValue>({
    enabled: { current: true },
    enable: () => {},
    disable: () => {}
  });

export function KeyboardShortcutsContextProvider({
  children
}: PropsWithChildren): JSX.Element {
  const enabled = useRef(true);
  const contextValue: KeyboardShortcutsContextValue = useMemo(
    () => ({
      enabled,
      enable: () => (enabled.current = true),
      disable: () => (enabled.current = false)
    }),
    []
  );

  return (
    <KeyboardShortcutsContext.Provider value={contextValue}>
      {children}
    </KeyboardShortcutsContext.Provider>
  );
}
export function useKeyboardShortcut(
  key: string | undefined,
  callback: (keyboardShortcutsContext: KeyboardShortcutsContextValue) => void
): void {
  const context = useContext(KeyboardShortcutsContext);

  useEffect(() => {
    if (!key) {
      return;
    }
    function handleKeyboardShortcut(e: KeyboardEvent) {
      if (!context.enabled.current) {
        return;
      }

      if (e.key === key?.toLowerCase()) {
        e.preventDefault();
        e.stopPropagation();
        callback(context);
      }
    }
    // document.addEventListener('keydown', handleKeyboardShortcut);
    return () => {
      document.removeEventListener('keydown', handleKeyboardShortcut);
    };
  }, [key, callback, context]);
}
