export enum TermsCheckBox {
  CONDITIONS = 'conditions',
  PERSONAL_DATA = 'personalData',
  NEWS = 'news'
}

export interface AdditionalRegistrationRequirement {
  readonly code: string;
  readonly isRequired: boolean;
  readonly text: string;
  readonly toolTip: string;
}
