import { AxiosPromise, CancelTokenSource } from 'axios';

import { axios } from '../../api/axios';
import { DiaryType, WeekDataResponse } from '../types';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';

export interface GetWeekPlanForAthleteRequestParams {
  userId: number;
  startDate: string;
  endDate: string;
  referenceTime?: string;
}

export function getWeekDataForAthlete(
  diaryType: DiaryType,
  params: GetWeekPlanForAthleteRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<WeekDataResponse> {
  return axios.get(`${coreApiTarget}/api/${diaryType}/week/athlete`, {
    cancelToken: cancelToken?.token,
    params: {
      ...params,
      instanceCode: devMode ? instance : undefined
    }
  });
}
