import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import {
  PlannerAttributeDefinition,
  PlannerEventSet,
  PlannerEventType
} from '../types';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';

interface PlannerDefinitionResponse {
  attributes: PlannerAttributeDefinition[];
  eventTypes: PlannerEventType[];
  eventSets: PlannerEventSet[];
}
export function getDefinition(
  cancelToken?: CancelTokenSource
): AxiosPromise<PlannerDefinitionResponse> {
  return axios.get(`${coreApiTarget}/api/planner/def`, {
    cancelToken: cancelToken?.token,
    params: {
      instanceCode: devMode ? instance : undefined
    }
  });
}
