import { observer } from 'mobx-react-lite';
import { FormFieldProps } from './form-field';
import { useErrorMessage, useInvalidFieldFocusHandler } from '../hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFormFieldAttributes } from '../utils';
import {
  createDateFormatValidator,
  extractDateFormat,
  extractDelimiters
} from '../../utils/extract-date-format';
import moment from 'moment';
import { ROUTE_DATE_FORMAT } from '../../diary/utils';
import { useEffect, useState } from 'react';
import { PatternInputDate } from '../../components/pattern-input-date';
import { PATTERN_INPUT_EMPTY_VALUE } from '@yarmill/components';

export const Date = observer(function Date(props: FormFieldProps): JSX.Element {
  const { attributeStore, autoFocus } = props;
  const intl = useIntl();
  const datePattern = extractDateFormat(intl);
  const [value, setValue] = useState(
    attributeStore.formValue
      ? moment(
          attributeStore.formValue,
          attributeStore.formValue.includes('/') ? 'YYYY/MM/DD' : 'YYYY-MM-DD'
        ).format(datePattern)
      : ''
  );
  const elementRef =
    useInvalidFieldFocusHandler<HTMLInputElement>(attributeStore);
  const commonAttributes = getFormFieldAttributes(attributeStore, intl);
  const error = useErrorMessage(attributeStore);
  const delimiters = extractDelimiters(datePattern);

  useEffect(() => {
    if (attributeStore.formValue) {
      const val = moment(
        attributeStore.formValue,
        attributeStore.formValue.includes('/') ? 'YYYY/MM/DD' : 'YYYY-MM-DD'
      ).format(datePattern);
      setValue(val);
    }
  }, [attributeStore.formValue, datePattern]);

  function onChange(value: string): void {
    setValue(value);
    if (
      value.length === datePattern.length &&
      !value.includes(PATTERN_INPUT_EMPTY_VALUE)
    ) {
      const date = moment(value, datePattern);
      if (date.isValid()) {
        attributeStore.onChange(date.format(ROUTE_DATE_FORMAT));
      }
    } else if (!value) {
      attributeStore.onChange('');
    }
  }

  const isCondensedObject =
    attributeStore.objectDataStore.objectStore.definition.Features?.Condensed;

  return (
    <PatternInputDate
      {...commonAttributes}
      autoFocus={autoFocus}
      id={String(attributeStore.id)}
      onChange={onChange}
      onBlur={attributeStore.onBlur}
      onFocus={attributeStore.onFocus}
      error={error}
      pattern={datePattern}
      value={value}
      delimiter={delimiters}
      ref={elementRef}
      validateValue={createDateFormatValidator(datePattern)}
      todayButtonLabel={<FormattedMessage id="navbar.calendar.todayButton" />}
      noLabel={isCondensedObject && !commonAttributes.label}
      noError={isCondensedObject && !error}
    />
  );
});
