import { HeartRateZoneStore } from './mobx/heart-rate-zone-store';
import {
  Breakpoints,
  Button,
  ButtonAppearance,
  ExternalIcon,
  FormControlVariant,
  Icon,
  IconSize,
  TextInput
} from '@yarmill/components';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { ChangeEvent } from 'react';

const MaxWrapper = styled.div`
  position: relative;
`;

const RemoveButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(120%, -50%);
  svg {
    color: #d0021b;
  }

  @media (min-width: ${Breakpoints.tablet}px) {
    transform: translate(150%, -50%);
  }
`;
interface HeartRateZoneRowProps {
  readonly isFirst: boolean;
  readonly isLast: boolean;
  readonly zone: HeartRateZoneStore;
  readonly prevZone?: HeartRateZoneStore;
  readonly nextZone?: HeartRateZoneStore;
  readonly autoFocus?: boolean;
  removeZone(): void;
}

export const HeartRateZoneRow = observer(function HeartRateZoneRow(
  props: HeartRateZoneRowProps
): JSX.Element {
  const { zone, isFirst, isLast, prevZone, nextZone, removeZone, autoFocus } =
    props;
  const intl = useIntl();

  function handleMaxChange(e: ChangeEvent<HTMLInputElement>): void {
    zone.setMax(Number(e.target.value));
  }

  return (
    <>
      <div>
        <TextInput
          id={`${zone.id}-name`}
          variant={FormControlVariant.big}
          label={
            isFirst
              ? intl.formatMessage({
                  id: 'settings.heartRateZones.form.name'
                })
              : undefined
          }
          noLabel={!isFirst}
          noError
          value={zone.name}
          onChange={e => zone.setName(e.target.value)}
        />
      </div>
      <div>
        <TextInput
          id={`${zone.id}-min`}
          variant={FormControlVariant.big}
          label={
            isFirst
              ? intl.formatMessage({
                  id: 'settings.heartRateZones.form.min'
                })
              : undefined
          }
          disabled
          align="right"
          readOnly
          noLabel={!isFirst}
          noError
          value={prevZone ? prevZone?.max + 1 : 0}
        />
      </div>
      <MaxWrapper>
        <TextInput
          autoFocus={autoFocus}
          align="right"
          id={`${zone.id}-max`}
          variant={FormControlVariant.big}
          label={
            isFirst
              ? intl.formatMessage({
                  id: 'settings.heartRateZones.form.max'
                })
              : undefined
          }
          noLabel={!isFirst}
          noError
          value={zone.max || ''}
          onChange={handleMaxChange}
          error={
            (nextZone && nextZone.max <= zone.max) ||
            (prevZone && prevZone.max >= zone.max) ||
            !zone.max ||
            zone.max > 250
              ? ' '
              : undefined
          }
          placeholder={
            isLast
              ? intl.formatMessage({
                  id: 'settings.heartRateZones.form.max.placeholder'
                })
              : undefined
          }
        />
        <RemoveButtonWrapper>
          {!isFirst && !isLast && (
            <Button
              appearance={ButtonAppearance.Link}
              type="button"
              onClick={removeZone}
              square
            >
              <Icon size={IconSize.s22}>
                <ExternalIcon name="CircleX" />
              </Icon>
            </Button>
          )}
        </RemoveButtonWrapper>
      </MaxWrapper>
    </>
  );
});
