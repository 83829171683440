import { FormattedMessage, useIntl } from 'react-intl';
import { RatingScore } from '../../components-2/okrs/detail/rating-score';
import { RatingMetric } from '../../components-2/okrs/detail/rating-metric';
import {
  calculateObjectiveValidationScore,
  OBJECTIVE_VALIDATION_CHECKS_COUNT
} from '../utils';
import { ObjectiveStore } from '../mobx/objective-store';
import { observer } from 'mobx-react-lite';
import { RightPanelSectionHeading } from '../../components-2/right-panel';

interface ObjectiveValidationRatingProps {
  readonly objective: ObjectiveStore;
}
export const ObjectiveValidationRating = observer(
  function ObjectiveValidationRating({
    objective
  }: ObjectiveValidationRatingProps): JSX.Element {
    const intl = useIntl();
    const ratingScore = calculateObjectiveValidationScore(objective);
    const issuesCount = objective.errors.length + objective.tips.length;

    return (
      <>
        <RightPanelSectionHeading as="h3" appearance="button10" upperCase>
          <FormattedMessage id="okrs.detail.validation.rating" />
        </RightPanelSectionHeading>
        <RatingScore
          score={ratingScore}
          message={intl.formatMessage(
            { id: 'okrs.detail.validation.rating.description' },
            { rating: ratingScore }
          )}
          metrics={
            <>
              <RatingMetric
                score={objective.errors.length}
                appearance="red"
                text={intl.formatMessage({
                  id: 'okrs.detail.validation.rating.errors'
                })}
              />
              <RatingMetric
                score={objective.tips.length}
                appearance="sand"
                text={intl.formatMessage({
                  id: 'okrs.detail.validation.rating.tips'
                })}
              />
              <RatingMetric
                score={OBJECTIVE_VALIDATION_CHECKS_COUNT - issuesCount}
                appearance="green"
                text={intl.formatMessage({
                  id: 'okrs.detail.validation.rating.solved'
                })}
              />
            </>
          }
        />
      </>
    );
  }
);
