import { ChartConfig } from '../types';
import { useMemo } from 'react';
import { AxisValue, DataItem, YAxisConfig } from '../../reporting/types';
import { getSSRStringWidth } from '../../reporting/utils/get-ssr-string-width';
import { getRotatedHeight } from '../../reporting/utils/get-rotated-height';
import {
  DEFAULT_MARKER_SIZE,
  LABEL_FONT_SIZE,
  LABEL_OFFSET
} from '../../reporting/const';

export function useCalculateTopPadding(
  configs: ChartConfig[],
  data: DataItem[],
  yAxisConfig?: YAxisConfig<AxisValue>
) {
  return useMemo(
    () =>
      Math.max(
        yAxisConfig?.showTickLabels ? LABEL_FONT_SIZE / 2 : 0,
        ...data.flatMap(item =>
          configs.flatMap(config => {
            if (config.type === 'BarGroup') {
              if (config.labelPosition !== 'outside') {
                return 0;
              }

              return Math.max(
                ...config.keys.map(key => {
                  const show = config.getShowLabels(item, key);
                  if (!show) {
                    return 0;
                  }

                  const angle = config.getLabelAngle(item, key);
                  const value = item[key];
                  const label = config.formatLabelValue(value as number, key);
                  const width = getSSRStringWidth(label);

                  return getRotatedHeight(width, LABEL_FONT_SIZE, angle ?? 0);
                })
              );
            } else if (config.type === 'Bar') {
              const show = config.getShowLabels(item);
              if (!show) {
                return 0;
              }

              const angle = config.getLabelAngle(item);
              const value = config.getYValue(item);
              const label = config.formatLabelValue(value);
              const width = getSSRStringWidth(label);

              return (
                getRotatedHeight(width, LABEL_FONT_SIZE, angle ?? 0) +
                LABEL_OFFSET
              );
            } else if (config.type === 'BarStack') {
              return 0;
            } else {
              const show = config.getShowLabels(item);
              const labelMargin = show ? LABEL_FONT_SIZE + LABEL_OFFSET : 0;
              const markerMargin =
                config.getMarkerSize?.(item) ?? DEFAULT_MARKER_SIZE;

              return Math.max(labelMargin, markerMargin);
            }
          })
        )
      ),
    [configs, data]
  );
}
