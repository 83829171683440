import { Editor } from '@tiptap/react';
import { useRootStore } from '../../../app/root-store-context';
import { useLayer } from '../../../layer-manager/hooks';
import { Video } from '../../../videoselector/types';
import { getSelectedText } from '../utils/get-selected-text';
import { Button } from './button';
import { LayerPortal } from '../../../layer-manager/layer-portal';
import { VideoSelector } from '../../../videoselector/video-selector';
import { useContext } from 'react';
import { OtherButtonsMenuContext } from './other-button';
import { getFileNameWithoutExtension } from '../../../utils/get-file-name-without-extension';
import { Tippy } from '../../tippy/tippy';
import { useIntl } from 'react-intl';
import { BrandYoutube } from '../icons/brand-youtube';

interface VideoLinkButtonProps {
  readonly editor: Editor;
  readonly setKeepControlsVisible: (v: (prev: number) => number) => void;
  readonly tabIndex: number;
}

export function VideoLinkButton({
  editor,
  setKeepControlsVisible,
  tabIndex
}: VideoLinkButtonProps): JSX.Element {
  const videoSelector = useRootStore().videoSelectorService;
  const layer = useLayer('interaction', {
    closeOnShimClick: true,
    showShim: true,
    returnFocus: false,
    onClose: () => {
      setKeepControlsVisible(v => Math.max(0, v - 1));
      editor.chain().focus().run();
    }
  });
  const { closeMenu } = useContext(OtherButtonsMenuContext);
  const intl = useIntl();

  function formatLink(video: Video) {
    if (!video) {
      if (editor.isActive('link')) {
        editor.chain().focus().unsetLink().run();
      }
      return;
    }

    const selectedText = getSelectedText(editor);
    const href = `${window.location.origin}/video/${video.FileId}`;
    const text = selectedText || getFileNameWithoutExtension(video.FileName);

    editor.chain().focus().setVideoLink({ href }).insertContent(text).run();
    editor.commands.toggleMark('videoLink');
    editor.chain().focus().insertContent(' ').run();
    closeMenu();
  }

  const label = 'richtext.toolbar.video';

  return (
    <>
      <Tippy tooltipContent={label} noWrapper>
        <Button
          type="button"
          tabIndex={tabIndex}
          isActive={editor.isActive('videoLink')}
          aria-label={intl.formatMessage({ id: label })}
          onMouseDown={() => {
            if (layer.isOpened) {
              layer.layer.close();
            } else {
              if (!getSelectedText(editor) && editor.isActive('videoLink')) {
                editor.commands.toggleMark('videoLink');
              } else {
                setKeepControlsVisible(v => v + 1);
                videoSelector.openVideoSelector(formatLink);
                layer.open();
              }
            }
          }}
        >
          <BrandYoutube />
        </Button>
      </Tippy>
      <LayerPortal
        layerHandle={layer}
        getContent={layer => <VideoSelector layer={layer} />}
      />
    </>
  );
}
