import { FormattedMessage, useIntl } from 'react-intl';
import { useMemo } from 'react';
import { usePlannerStore } from '../planner-store-context';
import {
  NavigationList,
  NavigationListItem
} from '../../components-2/navigation-list';
import { Folder } from '@yarmill/icons-2';
import { PlannerEventSet } from '../types';
import { EventSetEventsListHeading } from '../../#-components/planner/importer';
import { observer } from 'mobx-react-lite';
import { RightPanelContent } from '../../components-2/right-panel';
import { ImporterHeader } from './importer-header';
import { CloseEventPanel } from '../buttons/close-event-panel';

interface PlannerImporterProps {
  readonly close: () => void;
  readonly setEventSet: (eventSet: PlannerEventSet) => void;
}
export const EventSetSelector = observer(function EventSetSelector({
  setEventSet,
  close
}: PlannerImporterProps): JSX.Element {
  const plannerStore = usePlannerStore();
  const eventSets = plannerStore.eventSets;
  const intl = useIntl();

  const navigationListItems: NavigationListItem[] = useMemo(
    () =>
      eventSets.map(eventSet => ({
        icon: <Folder />,
        title: intl.formatMessage({ id: eventSet.name ?? 'Missing name' }),
        description: eventSet.description,
        onClick: () => setEventSet(eventSet)
      })),
    [eventSets, intl, setEventSet]
  );

  return (
    <>
      <ImporterHeader backButton={<CloseEventPanel close={close} />} />
      <RightPanelContent noPadding>
        <EventSetEventsListHeading as="h3" appearance="button10" upperCase>
          <FormattedMessage id="planner.importer.eventSets.headline" />
        </EventSetEventsListHeading>
        <NavigationList items={navigationListItems} />
      </RightPanelContent>
    </>
  );
});
