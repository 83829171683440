import { Group } from '@visx/group';
import { Bar as VisxBar } from '@visx/shape';
import { ScaleTypeToD3Scale } from '@visx/scale';
import { animated, SpringValue } from 'react-spring';
import { Text } from '@visx/text';
import {
  getBarLabelX,
  getHorizontalBarLabelTextAnchor,
  shouldShowBarLabelHorizontal
} from './utils';
import { ReactNode } from 'react';
import { LabelPosition } from '../reporting/types';
import { getAxisPosition } from '../reporting/utils/get-axis-position';
import { getBarRadius } from '../reporting/utils/get-bar-radius';
import { LABEL_FONT_SIZE } from '../reporting/const';
import { getSSRStringWidth } from '../reporting/utils/get-ssr-string-width';

export interface HorizontalBarProps {
  xValue: number;
  yValue: string;
  animation: SpringValue<number>;
  xScale: ScaleTypeToD3Scale<number>['linear'];
  yScale: ScaleTypeToD3Scale<string, string>['band'];
  xMax: number;
  color: string;
  showLabel?: boolean;
  labelPosition?: LabelPosition;
  labelColor?: string;
  formatLabelValue?: (value?: number) => ReactNode;
  barY: number;
  barHeight: number;
  opacity: number;
}

const AnimatedBar = animated(VisxBar);
const AnimatedText = animated(Text);

export function HorizontalBar(props: HorizontalBarProps): JSX.Element {
  const {
    xScale,
    xValue,
    yValue,
    animation,
    xMax,
    color,
    showLabel,
    labelColor,
    labelPosition,
    formatLabelValue,
    barY,
    barHeight,
    opacity
  } = props;
  const xDomain = xScale.domain();
  const [xDomainMin, xDomainMax] = xDomain;
  const zeroPoint = getAxisPosition(xDomain);

  const barWidth = Math.abs(
    xScale(xValue) -
      (xValue >= 0
        ? xScale(Math.max(0, xDomainMin))
        : xScale(Math.min(0, xDomainMax)))
  );

  const barX =
    xValue < 0
      ? animation.to(s => xScale(zeroPoint) - s * barWidth)
      : xScale(zeroPoint);

  const labelXFunction = getBarLabelX(
    xScale,
    xMax,
    barWidth,
    xValue,
    labelPosition
  );

  const labelX = animation.isAnimating
    ? animation.to(labelXFunction)
    : labelXFunction(1);

  return (
    <Group key={yValue}>
      <AnimatedBar
        x={barX}
        y={barY}
        width={animation.to(s => s * barWidth)}
        height={barHeight}
        fill={color}
        rx={getBarRadius(barHeight)}
        pointerEvents="none"
        opacity={opacity ?? 1}
      />
      {shouldShowBarLabelHorizontal(
        showLabel,
        labelPosition,
        barWidth,
        barHeight,
        getSSRStringWidth(String(formatLabelValue?.(xValue) || xValue)),
        xValue
      ) && (
        <AnimatedText
          x={labelX}
          y={barY + barHeight / 2}
          width={barWidth}
          height={barHeight}
          fill={labelColor || '#4a4a4a'}
          fontSize={LABEL_FONT_SIZE}
          textAnchor={getHorizontalBarLabelTextAnchor(labelPosition, xValue)}
          verticalAnchor="middle"
          pointerEvents="none"
        >
          {animation.isAnimating
            ? animation.to(s => {
                const val = xValue * s;
                return (formatLabelValue?.(val) as string) || val;
              })
            : ((formatLabelValue?.(xValue) || xValue) as string)}
        </AnimatedText>
      )}
    </Group>
  );
}
