import {
  AthletesListWrapper,
  ExternalIcon,
  Icon,
  IconSize,
  SidebarList,
  useTheme
} from '@yarmill/components';
import { Team } from '@yarmill/icons-2';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { trackSidebarLinkClick } from '../google-analytics/utils';
import { HistoricalAthletesList } from './historical-athletes-list';
import { ListItem } from './list-item';
import { persistSelectedAthlete } from './utils';
import { ATHLETE_SEARCH_PARAM } from '../routes/types';
import { useCommonUrlParams } from '../routes/hooks';
import { Location } from 'history';
import { generateUrl } from '../utils/generate-url';
import { GroupStore } from '../groups/mobx/group-store';
import { useCurrentUserStore, useUsersStore } from '../users/hooks';
import { observer } from 'mobx-react-lite';
import { AthleteLink } from './athlete-link';
import { NavigationItem } from '../sidebar/navigation-item';
import { useSidebarStore } from '../sidebar/hooks/use-sidebar-store';

export interface AthletesListProps {
  readonly disableWholeGroup?: boolean;
  readonly disableWholeGroupTooltip?: string;
  readonly group: GroupStore;
  readonly isVisible: boolean;
  readonly toggleGroups: () => void;
  readonly includeCoaches?: boolean;
  readonly hideCurrentUser?: boolean;
  readonly showWholeGroupItem?: boolean;
}

export const ALL_ATHLETES_ICON = () => {
  const theme = useTheme();

  return (
    <Icon size={theme.name === 'main' ? IconSize.s10 : IconSize.s16}>
      <ExternalIcon name="Layout2" />
    </Icon>
  );
};

function generateRootPath(location: Location): string | undefined {
  if (location.pathname.includes('okr')) {
    return `${window.location.origin}/okr`;
  }
  return undefined;
}

export const AthletesList = observer(function AthletesList(
  props: AthletesListProps
): JSX.Element | null {
  const {
    group,
    isVisible,
    toggleGroups,
    disableWholeGroup,
    disableWholeGroupTooltip,
    includeCoaches,
    hideCurrentUser,
    showWholeGroupItem
  } = props;
  const intl = useIntl();
  const usersStore = useUsersStore();
  const currentUser = useCurrentUserStore();
  const athletesInGroup = group.athletes;
  const allUsersInGroup = group.allUsers;
  const { athlete } = useCommonUrlParams();
  const selectedAthlete = usersStore.getUserById(athlete);
  const theme = useTheme();
  const groupLink = useCallback((location: Location) => {
    const searchParams = new URLSearchParams(location.search);
    const search = Object.fromEntries(searchParams.entries());
    const path = generateRootPath(location);
    return generateUrl(
      {
        ...search,
        [ATHLETE_SEARCH_PARAM]: null
      },
      path
    );
  }, []);
  const sidebarStore = useSidebarStore();

  const usersToShow = includeCoaches ? allUsersInGroup : athletesInGroup;

  useEffect(() => {
    persistSelectedAthlete(selectedAthlete);
  }, [selectedAthlete]);

  const onListItemClick = useCallback(
    (name: string): void => {
      toggleGroups();
      trackSidebarLinkClick(name);
      if (window.innerWidth < 768) {
        window.scrollTo({ top: 0 });
        if (theme.name === 'main') {
          sidebarStore.hide();
        }
      }
    },
    [toggleGroups, theme, sidebarStore]
  );

  return (
    <AthletesListWrapper isVisible={isVisible}>
      {usersToShow.length > 0 && (
        <SidebarList>
          {theme.name === 'main' && !showWholeGroupItem ? null : theme.name ===
            'main' ? (
            <NavigationItem
              icon={<Team />}
              text={intl.formatMessage({ id: 'sidebar.athletes.allAthletes' })}
              to={groupLink}
              isAthlete
              isActive={!selectedAthlete}
            />
          ) : (
            <ListItem
              disabled={disableWholeGroup}
              disableWholeGroupTooltip={disableWholeGroupTooltip}
              onClick={onListItemClick}
              isActive={!selectedAthlete}
              link={groupLink}
              name={intl.formatMessage({ id: 'sidebar.athletes.allAthletes' })}
              icon={<ALL_ATHLETES_ICON />}
              data-cy={'sidebar-list-item-whole-group'}
            />
          )}
          {usersToShow.map(athlete =>
            hideCurrentUser && currentUser.id === athlete.id ? null : (
              <AthleteLink
                key={athlete.id}
                onClick={onListItemClick}
                athlete={athlete}
                isActive={athlete === selectedAthlete}
              />
            )
          )}
        </SidebarList>
      )}
      {(group.historicalAthletes.length > 0 ||
        group.group.HistoricalAthletesCount > 0) &&
        theme.name !== 'main' && (
          <HistoricalAthletesList
            group={group}
            selectedAthlete={selectedAthlete}
            toggleDropdown={toggleGroups}
          />
        )}
    </AthletesListWrapper>
  );
});
