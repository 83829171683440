import {
  Button,
  ButtonAppearance,
  ContentBox,
  ContentBoxHead,
  ResponsiveTableWrapper,
  Text,
  TextSize
} from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import { useLayer } from '../layer-manager/hooks';
import { LayerPortal } from '../layer-manager/layer-portal';
import { HeartRateZoneForm } from './heart-rate-zone-form';
import { observer } from 'mobx-react-lite';
import { HeartRateZonesTable } from './heart-rate-zones-table';
import { Fragment, useEffect, useState } from 'react';
import { HeartRateZonesSetStore } from './mobx/heart-rate-zones-set-store';
import { useRootStore } from '../app/root-store-context';

export const HeartRateZones = observer(function HeartRateZones(): JSX.Element {
  const layer = useLayer('full-screen');
  const rootStore = useRootStore();
  const heartRateZonesManager = rootStore.heartRateZonesManagerStore;
  const [formItem, setFormItem] = useState<HeartRateZonesSetStore | null>(null);

  useEffect(() => {
    if (heartRateZonesManager.heartRateZonesSets.length === 0) {
      void heartRateZonesManager.loadHeartRateZones();
    }
    if (heartRateZonesManager.availableSports.length === 0) {
      void heartRateZonesManager.loadAvailableSports();
    }
  }, [heartRateZonesManager]);

  return (
    <>
      <ContentBox>
        <ContentBoxHead>
          <Text size={TextSize.s16} medium>
            <FormattedMessage id="settings.profile.heartRateZones" />
          </Text>
          <Button
            appearance={ButtonAppearance.Primary}
            square
            noShadow
            onClick={() => {
              if (!layer.isOpened) {
                const zoneSet = new HeartRateZonesSetStore(rootStore);
                zoneSet.createDefaultZones();
                setFormItem(zoneSet);
                layer.open();
              }
            }}
          >
            <strong>+</strong>
          </Button>
        </ContentBoxHead>
        <ResponsiveTableWrapper>
          <HeartRateZonesTable
            setFormItem={zoneSet => {
              if (!layer.isOpened) {
                setFormItem(zoneSet);
                layer.open();
              }
            }}
          />
        </ResponsiveTableWrapper>
      </ContentBox>
      <LayerPortal
        layerHandle={layer}
        getContent={layer =>
          formItem ? (
            <HeartRateZoneForm layer={layer} heartRateZonesSet={formItem} />
          ) : (
            <Fragment />
          )
        }
      />
    </>
  );
});
