import { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import {
  AlertLayerCloseButtonWrapper,
  Button,
  ButtonAppearance,
  FormControlVariant,
  Text,
  TextSize
} from '@yarmill/components';

import { useCurrentUserStore } from '../users/hooks';
import {
  StyledTermsPart,
  TermsAndConditionsCheckboxes
} from '../register/terms-and-conditions';
import { TermsAndConditionsStore } from '../register/mobx/terms-and-conditions-store';
import styled from 'styled-components';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import { CloseButton } from '../components/close-button/close-button';
import { LayerPortal } from '../layer-manager/layer-portal';
import { useLayer } from '../layer-manager/hooks';
import { Layer } from '../layer-manager/mobx/layer';
import { useRootStore } from '../app/root-store-context';
import { when } from 'mobx';
import { AsyncStatus } from '../api/mobx/request-store';

const ContentWrapper = styled.div`
  padding-bottom: 40px;

  @media (min-width: 768px) {
    padding-bottom: 60px;
  }

  @media (min-width: 992px) {
    padding-bottom: 70px;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const ErrorWrapper = styled.div`
  padding: 15px;
  color: #ffffff;
  background: #dc3545;
  margin-bottom: 20px;
  border-radius: 8px;
`;

const StyledAlert = styled.div`
  padding: 40px 30px;

  @media (min-width: 768px) {
    padding: 60px 70px 0;
  }

  @media (min-width: 992px) {
    padding: 70px 70px 0;
  }
`;

function shouldShowLayer(agreementValidTo?: string | null): boolean {
  return agreementValidTo !== null;
}

function isClosable(agreementValidTo?: string | null): boolean {
  if (!agreementValidTo) {
    return true;
  }
  const date = moment(agreementValidTo);

  return date.diff(moment()) > 0;
}

interface NewTermsAgreementContentProps {
  layer: Layer;
}
const NewTermsAgreementContent = observer(function NewTermsAgreementContent({
  layer
}: NewTermsAgreementContentProps): JSX.Element {
  const [showError, setShowError] = useState<boolean>(false);
  const currentUser = useCurrentUserStore();
  const rootStore = useRootStore();
  const showCloseButton = isClosable(currentUser.data?.AgreementValidTo);
  const termsAndConditionsStore = useRef(new TermsAndConditionsStore()).current;

  useEffect(() => {
    when(
      () => rootStore.configStore.status === AsyncStatus.resolved,
      () => {
        termsAndConditionsStore.setCustomRequirements(
          rootStore.configStore.additionalRegistrationRequirements
        );
      }
    );
  }, [rootStore, termsAndConditionsStore]);

  const acceptNewConditions = useCallback(async () => {
    const success = await currentUser.acceptNewTermsAndConditions(
      termsAndConditionsStore.isNewsAccepted
    );

    if (success) {
      window.location.href = '/';
      layer.close();
    } else {
      setShowError(true);
    }
  }, [currentUser, termsAndConditionsStore.isNewsAccepted, layer]);

  return (
    <StyledAlert>
      {showCloseButton && (
        <AlertLayerCloseButtonWrapper>
          <CloseButton onClick={layer.close} hideText />
        </AlertLayerCloseButtonWrapper>
      )}
      <ContentWrapper>
        <Text bold size={TextSize.s20}>
          <FormattedMessage id="newTermsAnnouncement.heading" />
        </Text>
        <StyledTermsPart size={TextSize.s14}>
          <FormattedHTMLMessage id="newTermsAnnouncement.description" />
        </StyledTermsPart>
        <TermsAndConditionsCheckboxes checkBox={termsAndConditionsStore} />
        {showError && (
          <ErrorWrapper>
            <FormattedHTMLMessage id="newTermsAnnouncement.saveError" />
          </ErrorWrapper>
        )}
        <ButtonWrapper>
          <Button
            disabled={!termsAndConditionsStore.areRequiredCheckBoxesAccepted}
            appearance={ButtonAppearance.Primary}
            onClick={acceptNewConditions}
            variant={FormControlVariant.big}
            data-cy="accept-new-conditions-button"
          >
            <FormattedMessage id="newTermsAnnouncement.accept" />
          </Button>
        </ButtonWrapper>
      </ContentWrapper>
    </StyledAlert>
  );
});

function InternalNewTermsAgreement(): JSX.Element | null {
  const currentUser = useCurrentUserStore();
  const alertLayer = useLayer('alert', {
    showShim: true,
    closeOnShimClick: false
  });

  useEffect(() => {
    if (
      !alertLayer.isOpened &&
      shouldShowLayer(currentUser.data?.AgreementValidTo)
    ) {
      alertLayer.open();
    }
  }, [alertLayer, currentUser]);

  return (
    <LayerPortal
      layerHandle={alertLayer}
      getContent={layer => <NewTermsAgreementContent layer={layer} />}
    />
  );
}

export const NewTermsAgreement = observer(InternalNewTermsAgreement);
