import { forwardRef, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  ButtonAppearance,
  PageHeader,
  Text,
  TextSize,
  TextTag
} from '@yarmill/components';
import styled from 'styled-components';
import { Status } from '../navbar/status';
import { InviteUsers } from './invite-users';
import { useCurrentUserStore, useUsersStore } from './hooks';
import { observer } from 'mobx-react-lite';
import { useLayer } from '../layer-manager/hooks';
import { LayerPortal } from '../layer-manager/layer-portal';

const StyledUserHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 12px;

  @media (min-width: 768px) {
    align-items: center;
  }
`;

const StyledButtonWrapper = styled.div`
  margin-left: auto;
  display: flex;
  gap: 20px;
`;

export const UsersHeader = observer(
  forwardRef<HTMLDivElement>(function UsersHeader(_, ref): JSX.Element {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const currentUser = useCurrentUserStore();
    const usersStore = useUsersStore();
    const inviteUsersLayer = useLayer('full-screen');

    const count = usersStore.count;
    const paid = usersStore.paid;
    const athletes = usersStore.athletes.length;
    const coaches = usersStore.coaches.length;

    const showInviteUserForm = (): void => {
      if (!inviteUsersLayer.isOpened) {
        inviteUsersLayer.open();
      }
    };

    return (
      <StyledUserHeader ref={ref}>
        <PageHeader>
          <Text tag={TextTag.h1} size={TextSize.s24}>
            <FormattedMessage
              id="settings.users.header"
              values={{ count, paid }}
            />
          </Text>
          <Text tag={TextTag.p} size={TextSize.s14}>
            <FormattedMessage id="settings.users.description" />
          </Text>
          <Text tag={TextTag.p} size={TextSize.s12}>
            <FormattedMessage
              id="settings.groups.toolsbar.membersCount"
              values={{ athletes, coaches }}
            />
          </Text>
        </PageHeader>
        {currentUser.isAllowedTo('settings.users.invite') && (
          <StyledButtonWrapper>
            <Status />
            <Button
              ref={buttonRef}
              onClick={showInviteUserForm}
              appearance={ButtonAppearance.Primary}
              noShadow
              data-cy="invite-users"
            >
              + &nbsp;
              <FormattedMessage id="settings.users.inviteNewButton" />
            </Button>
          </StyledButtonWrapper>
        )}
        <LayerPortal
          layerHandle={inviteUsersLayer}
          getContent={layer => <InviteUsers hideForm={layer.close} />}
        />
      </StyledUserHeader>
    );
  })
);
