import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { UserId } from '../types';

export function impersonateUser(userId: UserId): AxiosPromise<{
  Token: string;
}> {
  return axios.post('api/account/impersonate', {
    userId
  });
}
