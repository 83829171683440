import { observer } from 'mobx-react-lite';
import {
  PrimarySidebarAvatarWrapper,
  PrimarySidebarHeaderContainer,
  PrimarySidebarHeaderLayout,
  PrimarySidebarHeaderSpacer
} from '../components-2/primary-sidebar';
import { Logo } from './logo';
import { Avatar } from '../okrs/avatar';
import { useCurrentUserStore } from '../users/hooks';

export const Header = observer(function Header(): JSX.Element {
  const currentUser = useCurrentUserStore();
  const avatar = currentUser.data?.AvatarFileName;

  return (
    <PrimarySidebarHeaderLayout>
      <PrimarySidebarHeaderContainer>
        <Logo />
        <PrimarySidebarHeaderSpacer />
        <PrimarySidebarAvatarWrapper>
          <Avatar avatar={avatar} />
        </PrimarySidebarAvatarWrapper>
      </PrimarySidebarHeaderContainer>
    </PrimarySidebarHeaderLayout>
  );
});
