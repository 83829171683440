import { observable, makeObservable, runInAction, action } from 'mobx';
import axios, { AxiosPromise, CancelTokenSource } from 'axios';
import { Sentry } from '../../sentry/set-up-sentry';

export enum AsyncStatus {
  'idle',
  'pending',
  'resolved',
  'rejected'
}

export class RequestStore<T> {
  @observable
  public status: AsyncStatus = AsyncStatus.idle;

  private readonly request: AxiosPromise<T>;

  private readonly cancelToken: CancelTokenSource;

  private _error: Error | undefined;

  private _canceled: boolean = false;

  private _statusCode: number | undefined = undefined;

  constructor(request: (cancelToken: CancelTokenSource) => AxiosPromise<T>) {
    makeObservable(this);
    this.cancelToken = axios.CancelToken.source();
    this.request = request(this.cancelToken);
    this.status = AsyncStatus.pending;
  }

  @action
  public cancel(): void {
    this.status = AsyncStatus.idle;
    this._canceled = true;
    this.cancelToken.cancel();
  }

  public get error(): Error | undefined {
    return this._error;
  }

  public get statusCode(): number | undefined {
    return this._statusCode;
  }

  public async getResponse(): Promise<T | undefined> {
    try {
      const response = await this.request;
      const { data, status } = response;
      Sentry?.addBreadcrumb({
        type: 'http',
        category: 'request',
        level: status >= 400 || status <= 100 ? 'error' : 'info',
        message: `${response.config.method?.toUpperCase()} ${
          response.config.url
        }`,
        data: {
          status,
          params: response.config.params,
          body: response.config.data,
          responseData: status >= 400 || status <= 100 ? data : 'ok'
        }
      });
      this._statusCode = status;
      runInAction(() => {
        this.status = AsyncStatus.resolved;
      });

      return data;
    } catch (e: unknown) {
      runInAction(() => {
        this._error = e as Error;
        if (axios.isAxiosError(e)) {
          this._statusCode = e.response?.status;

          Sentry?.addBreadcrumb({
            type: 'http',
            category: 'request',
            level:
              (e.response?.status ?? -1) >= 400 ||
              (e.response?.status ?? -1) <= 100
                ? 'error'
                : 'info',
            message: `${e.response?.config.method?.toUpperCase()} ${
              e.response?.config.url
            }`,
            data: {
              status: this._statusCode,
              params: e.response?.config.params,
              body: e.response?.config.data,
              responseData:
                (e.response?.status ?? -1) >= 400 ||
                (e.response?.status ?? -1) <= 100
                  ? e.response?.data
                  : 'ok'
            }
          });
        }

        if (this.status !== AsyncStatus.idle) {
          this.status = AsyncStatus.rejected;

          if (axios.isAxiosError(e)) {
            const status = e.response?.status ?? 0;

            if (
              (status >= 500 || status <= 100) &&
              status !== 0 &&
              status !== 401
            ) {
              if (!this._canceled) {
                Sentry?.captureEvent({
                  request: e.response?.request,
                  message: e.message,
                  level: 'error',
                  extra: {
                    canceled: this._canceled,
                    response: e.response
                  }
                });
              }
            }
          }
        }
      });
    }
  }

  public get wasCanceled(): boolean {
    return this._canceled;
  }
}
