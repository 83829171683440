import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledTd, Text, TextSize } from '@yarmill/components';
import styled, { css } from 'styled-components';
import { repeat } from 'ramda';
import { Tippy } from '../components/tippy/tippy';
import { ExpandableTree } from './mobx/expandable-tree';
import { ExpandButton } from './expand-button';
import { CsvTableAlignment, CsvTableFirstColumnItem } from './types';

export interface CsvTableFirstColumnCellProps {
  rowIndex: number;
  item: CsvTableFirstColumnItem;
  labelIsTranslateString?: boolean;
  expandable?: ExpandableTree<CsvTableFirstColumnItem>;
  alignment?: CsvTableAlignment | null;
}

interface FirstColumnCellProps {
  readonly textColor?: string | null;
  readonly backgroundColor?: string | null;
  readonly $isExpandable?: boolean;
}

const FirstColumnCell = styled(StyledTd)<FirstColumnCellProps>`
  ${props =>
    props.backgroundColor && `background-color: ${props.backgroundColor};`}
  color: ${({ textColor }) => textColor ?? '#4a4a4a'};
  white-space: nowrap;
  position: sticky;
  left: 0;
  background-color: #ffffff;
  padding: 4px 8px;

  tr:hover > & {
    background-color: inherit;
  }

  ${({ $isExpandable }) => $isExpandable && css``}

  @media print {
    page-break-inside: avoid;
  }
`;

const FakeExpandButton = styled.div`
  height: 32px;
  min-height: 32px;
  width: 26px;
  padding: 4px;
  position: absolute;
  left: -22px;
  top: 0;
  background-color: #ffffff;

  @media (min-width: 768px) {
    left: -22px;
  }
`;

export function CsvTableFirstColumnCell(
  props: CsvTableFirstColumnCellProps
): JSX.Element {
  const { item, labelIsTranslateString, expandable, rowIndex, alignment } =
    props;

  const label = labelIsTranslateString ? (
    <FormattedMessage id={item.Label} />
  ) : (
    item.Label
  );

  const level = useMemo(
    () => (item.Level ? repeat('\u00A0', item.Level * 3) : ''),
    [item.Level]
  );

  const expandButton =
    expandable && expandable.isNodeExpandable(rowIndex) ? (
      <ExpandButton expandable={expandable} id={rowIndex} />
    ) : (
      <FakeExpandButton />
    );

  return (
    <FirstColumnCell
      backgroundColor={item.BackgroundColor}
      textColor={item.Color}
      align={alignment || 'left'}
      $isExpandable={Boolean(expandable)}
    >
      {level}
      <Tippy
        tooltipContent={item.Tooltip}
        isEnabled={Boolean(item.Tooltip)}
        translateValue={false}
      >
        <Text size={TextSize.s12} bold={Boolean(item.IsBold)} inheritColor>
          {label}
        </Text>
      </Tippy>
      {expandButton}
    </FirstColumnCell>
  );
}
