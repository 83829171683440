import { Text, TextSize, TextTag } from '@yarmill/components';
import { MouseEvent, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { CloseButton } from '../components/close-button/close-button';
import { Search } from './search';
import { VideoList } from './video-list';
import { VideoSelectorStoreContextProvider } from './video-selector-context';
import { useRootStore } from '../app/root-store-context';
import { observer } from 'mobx-react-lite';
import { Layer } from '../layer-manager/mobx/layer';

const StyledVideoSelector = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 96px 32px 32px;
`;

const StyledCloseButton = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
`;

interface VideoSelectorProps {
  layer: Layer;
}
export const VideoSelector = observer(function InternalVideoSelector({
  layer
}: VideoSelectorProps): JSX.Element {
  const videoSelectorService = useRootStore().videoSelectorService;
  const containerRef = useRef<HTMLDivElement>(null);

  function closeVideoSelector(e: MouseEvent): void {
    e.preventDefault();
    videoSelectorService.closeVideoSelector();
    layer.close();
  }

  return (
    <VideoSelectorStoreContextProvider done={layer.close}>
      <StyledVideoSelector ref={containerRef}>
        <StyledCloseButton>
          <CloseButton onClick={closeVideoSelector} />
        </StyledCloseButton>
        <FormattedMessage id="videoSelector.header">
          {msg => (
            <Text tag={TextTag.div} size={TextSize.s20} bold>
              {msg}
            </Text>
          )}
        </FormattedMessage>
        <Search />
        <VideoList containerRef={containerRef} />
      </StyledVideoSelector>
    </VideoSelectorStoreContextProvider>
  );
});
