import {
  TrainingDayContentBox,
  TrainingWeekSummaryContainer
} from '@yarmill/components';

import { WeekSummaryStore } from './mobx/week-summary-store';
import { observer } from 'mobx-react-lite';
import { TrainingDayHeader } from './training-day-header';
import { RichtextAttribute } from '../attributes/richtext/richtext-attribute';

export interface WeekSummaryProps {
  weekSummary: WeekSummaryStore;
}

function InternalWeekSummary(props: WeekSummaryProps): JSX.Element | null {
  const { weekSummary } = props;
  const isPinned = weekSummary.isPinned;

  return (
    <TrainingWeekSummaryContainer isPinned={isPinned}>
      <TrainingDayContentBox noShadow>
        <TrainingDayHeader day={weekSummary} />
        <RichtextAttribute attribute={weekSummary.weekGoal} hideLabel />
      </TrainingDayContentBox>
    </TrainingWeekSummaryContainer>
  );
}

export const WeekSummary = observer(InternalWeekSummary);
