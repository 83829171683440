import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { PlannerEvent } from '../types';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';

export interface UpdateEventRequestParams {
  userId: number | undefined;
  userGroupId: number | undefined;
  event: PlannerEvent;
}

export function updateEvent(
  params: UpdateEventRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<PlannerEvent> {
  return axios.put(`${coreApiTarget}/api/planner`, params, {
    cancelToken: cancelToken?.token,
    params: {
      ...params,
      instanceCode: devMode ? instance : undefined
    }
  });
}
