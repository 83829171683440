import styled from 'styled-components';

import { AlertLayerCloseButtonWrapper } from '@yarmill/components';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';

import { Video } from './video';
import { observer } from 'mobx-react-lite';
import { useVideoService } from './hooks';
import { AsyncStatus } from '../api/mobx/request-store';
import { LayerPortal } from '../layer-manager/layer-portal';
import { useLayer } from '../layer-manager/hooks';
import { useEffect } from 'react';
import { CloseButton } from '../components/close-button/close-button';

const StyledErrorWrapper = styled.div`
  padding: 12px 24px;
`;

function InternalVideoPlayer(): JSX.Element {
  const videoService = useVideoService();
  const videoPlayerLayer = useLayer('alert', { showShim: true });
  const video = videoService.video;

  useEffect(() => {
    if (video && !videoPlayerLayer.isOpened) {
      videoPlayerLayer.open({
        closeOnShimClick: true,
        onClose: () => videoService.closeVideoPlayer()
      });
    }
  }, [video, videoPlayerLayer, videoService]);

  return (
    <LayerPortal
      layerHandle={videoPlayerLayer}
      getContent={layer => (
        <>
          {video && <Video src={video} />}
          {videoService.status === AsyncStatus.pending && (
            <ContentLoader
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
              viewBox="0 0 320 180"
            >
              <rect x="0" y="0" rx="8" ry="8" width="320" height="180" />
            </ContentLoader>
          )}
          {videoService.status === AsyncStatus.rejected && (
            <StyledErrorWrapper>
              <FormattedMessage id="videoPlayer.loadingError" />
            </StyledErrorWrapper>
          )}
          <AlertLayerCloseButtonWrapper>
            <CloseButton onClick={layer.close} hideText autoFocus />
          </AlertLayerCloseButtonWrapper>
        </>
      )}
    />
  );
}

export const VideoPlayer = observer(InternalVideoPlayer);
