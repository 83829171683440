import { WeekAttendanceItemStore } from './mobx/week-attendance-item-store';
import { AttendanceValue } from './types';
import { UserStore } from '../users/mobx/user-store';
import { useAttendanceStore } from './attendance-store-context';
import { trackAttendanceValueButtonClick } from '../google-analytics/utils';
import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  Icon,
  IconSize
} from '@yarmill/components';
import styled from 'styled-components';
import { Tippy } from '../components/tippy/tippy';

interface AttendanceValueButtonProps {
  item?: WeekAttendanceItemStore;
  date: string;
  type: AttendanceValue;
  user?: UserStore;
  disabled?: boolean;
  handleClick?: (type: AttendanceValue) => void;
}

export function getIconForValueType(
  type: AttendanceValue | undefined
): JSX.Element {
  switch (type) {
    case AttendanceValue.Present:
      return <ExternalIcon name="CircleCheck" />;
    case AttendanceValue.Excused:
      return <ExternalIcon name="CircleMinus" />;
    case AttendanceValue.Unexcused:
      return <ExternalIcon name="CircleX" />;
    case AttendanceValue.Free:
    default:
      return <ExternalIcon name="Coffee" />;
  }
}

export function getBackgroundForValueType(
  type: AttendanceValue | undefined
): string {
  switch (type) {
    case AttendanceValue.Present:
      return '#F2F8E8';
    case AttendanceValue.Excused:
      return '#E4EFFB';
    case AttendanceValue.Unexcused:
      return '#F9D7D7';
    case AttendanceValue.Free:
    default:
      return '#F5F4F5';
  }
}

export function getBackgroundHoverForValueType(
  type: AttendanceValue | undefined
): string {
  switch (type) {
    case AttendanceValue.Present:
      return '#DDF0CE';
    case AttendanceValue.Excused:
      return '#C9DDF2';
    case AttendanceValue.Unexcused:
      return '#F0BBBB';
    case AttendanceValue.Free:
      return '#DEDDDE';
    default:
      return '#EBEAEB';
  }
}

export const StyledAttendanceValueButton = styled(Button)<{
  $backgroundColor: string | undefined;
  $hoverBackgroundColor: string;
}>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: transparent;
  ${({ $backgroundColor }) =>
    $backgroundColor && `background-color: ${$backgroundColor};`};

  :hover,
  :focus {
    color: #4a4a4a;
    background-color: ${({ $hoverBackgroundColor }) => $hoverBackgroundColor};
  }
`;

export function AttendanceValueButton(
  props: AttendanceValueButtonProps
): JSX.Element {
  const { type, item, date, user, disabled, handleClick } = props;
  const attendanceStore = useAttendanceStore();

  const onClick = async () => {
    trackAttendanceValueButtonClick(type);
    if (handleClick) {
      handleClick(type);
    } else if (item) {
      await item.setValue(type);
    } else {
      await attendanceStore
        .createItem(
          { UserId: user?.id || -1, Value: type, Date: date },
          attendanceStore.currentPhase?.activityItemId || null
        )
        .setValue(type);
    }
  };

  return (
    <Tippy tooltipContent={`attendance.value.${type}`}>
      <StyledAttendanceValueButton
        type="button"
        disabled={disabled}
        small
        appearance={ButtonAppearance.Link}
        square
        onClick={onClick}
        $backgroundColor={
          item?.value === type ? getBackgroundForValueType(type) : undefined
        }
        $hoverBackgroundColor={getBackgroundHoverForValueType(
          item?.value === type ? type : undefined
        )}
      >
        <Icon size={IconSize.s16}>{getIconForValueType(type)}</Icon>
      </StyledAttendanceValueButton>
    </Tippy>
  );
}
