import { loadFacts as slcrLoadFacts } from './load-facts';
import { loadIndicators as slcrLoadIndicators } from './load-indicators';
import { loadStressCompare as slcrLoadStressCompare } from './load-stress-compare';
import { loadTrend as slcrLoadTrend } from './load-trend';
import { loadVerticalDimensions as slcrLoadVerticalDimensions } from './load-vertical-dimensions';

export const api = {
  loadFacts: slcrLoadFacts,
  loadIndicators: slcrLoadIndicators,
  loadStressCompare: slcrLoadStressCompare,
  loadTrend: slcrLoadTrend,
  loadVerticalDimensions: slcrLoadVerticalDimensions
};
