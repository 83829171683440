import { action, makeObservable } from 'mobx';
import { TrainingDayStore } from '../../training-day/mobx/training-day-store';
import { RootStore } from '../../app/mobx/root-store';
import moment from 'moment';
import { ConflictResolution, CopyMode } from '../types';
import { api } from '../api';
import { CopyGoalsRequestParams } from '../api/copy-goals-from-user';
import {
  copyAttendance,
  CopyAttendanceRequestParams
} from '../../attendance/api/copy-attendance';
import { getWeekEndString, getWeekStartString } from '../../diary/utils';
import { UserGroupId } from '../../groups/types';
import { CopyConflictErrorResponse } from '../copy-conflict-error-response';
import { ServerErrorResponse } from '../../api/server-error-response';

export class CopyService {
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  public isAllowedToCopyPlanToReality(day: TrainingDayStore): boolean {
    const validDays = this.rootStore.configStore.copyPlanToRealityValidDays;

    if (validDays === -1) {
      return true;
    }

    const now = moment().startOf('day');
    const validTo = moment(day.currentDate).add(validDays, 'days');

    return validTo.diff(now) >= 0;
  }

  @action
  public async copyPlanToReality(
    day: TrainingDayStore,
    resolution: ConflictResolution = 'FailOnConflict'
  ): Promise<boolean | CopyConflictErrorResponse | ServerErrorResponse> {
    const transaction = this.rootStore.navbarStore.createTransaction(
      'loading',
      'diary'
    );
    try {
      await api.copyUserPlanToReality({
        UserId: day.diaryStore.athleteId as number,
        SourceDay: day.currentDate,
        ConflictResolution: resolution
      });

      transaction.success();
      return true;
    } catch (e: any) {
      if (e?.response?.status === 406) {
        transaction.finished();
        return new CopyConflictErrorResponse(
          'Copy Conflict Error',
          e.response.data
        );
      } else if (e?.response?.status === 400 && e.response.data?.Id) {
        transaction.error();
        return new ServerErrorResponse('copy server error', e.response.data);
      } else {
        transaction.error();
        return false;
      }
    }
  }

  @action
  public async copy(
    mode: CopyMode,
    sourceType: 'athlete' | 'group',
    diaryType: 'Plan',
    type: 'Week' | 'Goals' | 'Cycle' | 'Season' | 'SeasonGoals',
    values: Record<string, unknown>,
    resolution: ConflictResolution = 'FailOnConflict'
  ): Promise<boolean | CopyConflictErrorResponse | ServerErrorResponse> {
    try {
      if (type === 'Goals') {
        if (sourceType === 'athlete') {
          await api.copyGoalsFromUser({
            ...values,
            ConflictResolution: resolution
          } as unknown as CopyGoalsRequestParams);
        } else {
          await api.copyGoalsFromGroup({
            ...values,
            ConflictResolution: resolution
          } as unknown as CopyGoalsRequestParams);
        }
      } else {
        await api.copy({
          data: { ...values, ConflictResolution: resolution },
          mode,
          diaryType,
          type
        });
      }

      return true;
    } catch (e: any) {
      if (e?.response?.status === 406) {
        return new CopyConflictErrorResponse(
          'Copy Conflict Error',
          e.response.data
        );
      } else if (e?.response?.status === 400 && e.response.data?.Id) {
        return new ServerErrorResponse('copy server error', e.response.data);
      }

      return false;
    }
  }

  @action
  public async copyAttendance(
    groupId: UserGroupId,
    week: string,
    resolution: ConflictResolution = 'FailOnConflict'
  ): Promise<boolean | CopyConflictErrorResponse | ServerErrorResponse> {
    const params: CopyAttendanceRequestParams = {
      UserGroupId: groupId,
      StartDate: getWeekStartString(week),
      EndDate: getWeekEndString(week),
      ConflictResolution: resolution
    };

    const transaction = this.rootStore.navbarStore.createTransaction(
      'loading',
      'diary'
    );
    try {
      await copyAttendance(params);
      transaction.finished();
      return true;
    } catch (e: any) {
      transaction.finished();
      if (e?.response?.status === 406) {
        return new CopyConflictErrorResponse(
          'Copy Conflict Error',
          e.response.data
        );
      } else if (e?.response?.status === 400 && e.response.data?.Id) {
        return new ServerErrorResponse('copy server error', e.response.data);
      }
      return false;
    }
  }
}
