import {
  OVERVIEW_TABLE_CELL_SIZE,
  OVERVIEW_TABLE_STICKY_TOP,
  OverviewTableCellData,
  OverviewTableDataFormatter
} from './utils';
import { Value } from './value';
import styled from 'styled-components';

export interface HeaderRowProps {
  headerFormatter?: OverviewTableDataFormatter;
  columns: string[];
  hasRowLabel: boolean;
  sticky: boolean;
}

const emptyValue: OverviewTableCellData = {
  Value: '',
  ColumnKey: '',
  Category: '',
  Flag: false
};

const StickyBackground = styled.div<{ column: number }>`
  position: sticky;
  top: ${OVERVIEW_TABLE_STICKY_TOP};
  height: ${OVERVIEW_TABLE_CELL_SIZE + 26}px;
  background-color: #ffffff;
  grid-row: 1 / 2;
  grid-column: 1 / ${({ column }) => column + 1};
  z-index: 1;
  padding: 5px;
  padding-top: 31px;
`;

export function HeaderRow(props: HeaderRowProps): JSX.Element {
  const { columns, headerFormatter, hasRowLabel, sticky } = props;

  return (
    <>
      {!sticky && hasRowLabel && (
        <Value
          value={emptyValue}
          row={0}
          column={0}
          hasRowLabel={hasRowLabel}
          sticky={sticky}
        />
      )}

      {sticky && (
        <StickyBackground column={columns.length + (hasRowLabel ? 1 : 0)} />
      )}

      {columns.map((cell, columnIndex) => (
        <Value
          key={cell}
          value={{ ...emptyValue, Value: cell }}
          row={0}
          column={columnIndex + (hasRowLabel ? 1 : 0)} // +1 for label column}
          formatter={headerFormatter}
          sticky={sticky}
        />
      ))}
    </>
  );
}
