import { BarStack } from '@visx/shape/lib/types';
import { DataItem } from '../../reporting/types';
import { GroupBy } from '../../helpers/group-by';

export function groupStackedBarsByCategories(
  barStacks: BarStack<DataItem, string>[]
) {
  return Array.from(
    GroupBy(barStacks.map(barStack => barStack.bars).flat(), 'x').entries()
  ).map(([, categoryBars]) => categoryBars);
}
