import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tippy } from '../../components/tippy/tippy';
import { RichTextEditor } from '../../components/richtext';
import {
  trackDiaryAttributeBlur,
  trackDiaryAttributeFocus
} from '../../google-analytics/utils';
import { RichtextAttributeStore } from '../mobx/richtext-attribute-store';
import { observer } from 'mobx-react-lite';
import { getDisabledReasonMessage } from '../../activities/utils';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import {
  TrainingDayAttributeLabel,
  TrainingDayAttributeLayout
} from '@yarmill/components';
import { RichTextErrorBoundary } from './rich-text-error-boundary';
import { isTouchDevice } from '../../utils/is-touch-device';
import { Editor } from '@tiptap/react';
import {
  GoalPlannerEvent,
  GoalsEventsWrapper
} from '../../goals/components/goal-planner-event';
import { useFetchAutoCompletion } from '../../yollanda/hooks/use-fetch-auto-completion';

type RichtextAttributeProps = PropsWithChildren<{
  readonly attribute: RichtextAttributeStore;
  readonly hideLabel?: boolean;
}>;

export const RichtextAttribute = observer(function RichtextAttribute(
  props: RichtextAttributeProps
): JSX.Element | null {
  const { attribute, hideLabel } = props;
  const disabledReason = attribute.disabledReason;
  const [editor, setEditor] = useState<Editor | null>(null);
  const { athleteId, groupId } = attribute.dataId;
  const fetchAutoCompletion = useFetchAutoCompletion(athleteId, groupId);

  const onBeforeUnload = React.useCallback((e: Event): void => {
    e.preventDefault();
    e.returnValue = false;
  }, []);

  const onChange = React.useCallback(
    (value: string) => {
      const formattedValue = value === '<p></p>' ? '' : value;
      attribute.onChange(formattedValue);
    },
    [attribute]
  );

  const onFocus = React.useCallback((): void => {
    window.addEventListener('beforeunload', onBeforeUnload);
    attribute.onFocus();
    trackDiaryAttributeFocus(attribute.name);
  }, [attribute, onBeforeUnload]);

  const onBlur = React.useCallback((): void => {
    window.removeEventListener('beforeunload', onBeforeUnload);
    attribute.onBlur();
    trackDiaryAttributeBlur(attribute.name);
  }, [attribute, onBeforeUnload]);

  useEffect(() => {
    attribute.setEditor(editor);
  }, [editor, attribute]);

  const getEditor = useCallback((e: Editor | null) => {
    setEditor(e);
  }, []);

  if (attribute.isHidden) {
    return null;
  }

  return (
    <TrainingDayAttributeLayout>
      {!hideLabel && (
        <TrainingDayAttributeLabel htmlFor={attribute.htmlId}>
          <Tippy tooltipContent={attribute.tooltip}>
            <FormattedMessage id={attribute.name} />
          </Tippy>
        </TrainingDayAttributeLabel>
      )}

      <RichTextErrorBoundary>
        <Tippy
          isEnabled={Boolean(disabledReason) && !isTouchDevice()}
          tooltipContent={getDisabledReasonMessage(disabledReason)}
          Wrapper="div"
        >
          <RichTextEditor
            asInput
            data-cy={attribute.name}
            id={attribute.htmlId}
            key={`${attribute.htmlId}-${attribute.isDisabled}-${attribute.dataId.week}`}
            disabled={attribute.isDisabled}
            placeholder={attribute.placeholder}
            onFocus={onFocus}
            onChange={onChange}
            onBlur={onBlur}
            content={attribute.formattedValue}
            enableVideos={attribute.isVideoEnabled}
            getEditor={getEditor}
            disableLazyEditor={attribute.isExtra}
            fetchAutocompletion={fetchAutoCompletion}
          >
            <GoalsEventsWrapper>
              {attribute.events.map(e => (
                <GoalPlannerEvent
                  title={e.Title}
                  color={e.Color}
                  key={e.EventId}
                />
              ))}
            </GoalsEventsWrapper>
          </RichTextEditor>
        </Tippy>
      </RichTextErrorBoundary>
    </TrainingDayAttributeLayout>
  );
});
