import { MultiLineChart, LineChart } from '../types';
import { useMemo } from 'react';
import { groupBy } from 'ramda';
import { Line } from './line';
import { getFirstDataItem } from '../utils/get-first-data-item';
import { DataItem } from '../../reporting/types';

interface MultiLineProps {
  readonly config: MultiLineChart;
  readonly data: DataItem[];
}

export function MultiLine({ config, data }: MultiLineProps) {
  const groupedData = useMemo(
    () => groupBy(item => String(item[config.categoryKey]), data),
    [data, config]
  );

  const lineConfigs = useMemo(
    () =>
      Object.entries(groupedData)
        .map<[string, LineChart]>(([category]) => {
          const singleLineConfig: LineChart = {
            ...config,
            code: category,
            type: 'Line'
          };
          return [category, singleLineConfig];
        })
        .sort(([aKey], [bKey]) => {
          const a = getFirstDataItem(groupedData[aKey]);
          const b = getFirstDataItem(groupedData[bKey]);
          return (
            (a ? config.getLineOrder(a) : 0) - (b ? config.getLineOrder(b) : 0)
          );
        }),
    [groupedData, config]
  );

  return (
    <>
      {lineConfigs.map(([category, lineConfig]) => (
        <Line key={category} data={groupedData[category]} config={lineConfig} />
      ))}
    </>
  );
}
