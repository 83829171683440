import { ActivityItem } from './types';
import { useMemo, MouseEvent, useCallback } from 'react';
import { repeat } from 'ramda';
import { ActivityTitleCell, TextSize, Text } from '@yarmill/components';
import { Tippy } from '../components/tippy/tippy';
import { useOptionalTranslation } from '../intl/hooks';
import { useIntl } from 'react-intl';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import { observer } from 'mobx-react-lite';

interface ActivityTitleProps {
  activityItem: ActivityItem;
  onClick?: (e: MouseEvent, activityItem: ActivityItem) => void;
}

export const ActivityTitle = observer(function ActivityTitle(
  props: ActivityTitleProps
) {
  const { activityItem, onClick } = props;
  const intl = useIntl();

  const level = useMemo(
    () => (activityItem.Level ? repeat('\u00A0', activityItem.Level * 3) : ''),
    [activityItem.Level]
  );
  const name = useOptionalTranslation(activityItem?.Name);
  const tooltipContent =
    activityItem?.ToolTip && intl.messages[activityItem?.ToolTip] ? (
      <FormattedHTMLMessage id={activityItem.ToolTip} />
    ) : (
      activityItem?.ToolTip
    );

  const handleClick = useCallback(
    (e: MouseEvent) => {
      onClick?.(e, activityItem);
    },
    [onClick, activityItem]
  );

  return (
    <ActivityTitleCell onClick={handleClick} className="activity-title-cell">
      <div
        style={{
          color: activityItem.Color || undefined
        }}
        className="activity-title-wrapper"
      >
        {level}
        <Tippy
          tooltipContent={tooltipContent}
          isEnabled={Boolean(tooltipContent)}
          translateValue={false}
        >
          <Text
            size={TextSize.s12}
            className="activity-title-cell"
            inheritColor
            bold={activityItem.Type === 'S'}
            light={activityItem.Type === 'I'}
          >
            {name}
          </Text>
        </Tippy>
      </div>
    </ActivityTitleCell>
  );
});
