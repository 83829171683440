import * as React from 'react';
import { Link } from '../components/link';
import { Tippy } from '../components/tippy/tippy';
import { Location } from 'history';
import { SidebarLinkContent } from '@yarmill/components';

export interface ListItemProps {
  disabled?: boolean;
  disableWholeGroupTooltip?: string;
  icon?: JSX.Element;
  isActive: boolean;
  name: string;
  secondaryLine?: JSX.Element;
  ['data-cy']?: string;
  link?(location: Location): string;
  onClick?(name: string): void;
}

export function ListItem(props: ListItemProps): JSX.Element {
  const {
    isActive,
    link,
    name,
    icon,
    disabled,
    secondaryLine,
    onClick,
    disableWholeGroupTooltip = ''
  } = props;

  const testAttributes = {
    'data-cy': props['data-cy'] || 'sidebar-list-item'
  };

  const handleClick = React.useCallback(() => {
    if (onClick) {
      if (typeof name === 'string') {
        onClick(name);
      } else {
        onClick('whole group');
      }
    }
  }, [name, onClick]);

  return (
    <Tippy
      tooltipContent={disableWholeGroupTooltip}
      isEnabled={Boolean(disabled)}
      maxWidth={200}
      Wrapper="li"
    >
      <>
        {disabled || !link ? (
          <SidebarLinkContent disabled={disabled} icon={icon}>
            {name}
          </SidebarLinkContent>
        ) : (
          <Link
            $isActive={isActive}
            to={link}
            onClick={handleClick}
            {...testAttributes}
          >
            <SidebarLinkContent
              icon={icon}
              secondaryInfo={secondaryLine}
              isActive={isActive}
            >
              {name}
            </SidebarLinkContent>
          </Link>
        )}
      </>
    </Tippy>
  );
}
