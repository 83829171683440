import { createContext, Dispatch, HTMLProps, SetStateAction } from 'react';

interface DropdownContextValue<V> {
  readonly getItemProps: (
    userProps?: HTMLProps<HTMLElement>
  ) => Record<string, unknown>;
  readonly activeIndex: number | null;
  readonly setActiveIndex: Dispatch<SetStateAction<number | null>>;
  readonly setHasFocusInside: Dispatch<SetStateAction<boolean>>;
  readonly isOpen: boolean;
  readonly selectedValue: V | undefined;
  readonly handleSelect: (
    index: number | null,
    value: V,
    isCheckboxClick?: boolean
  ) => void;
  readonly hasSubcategories: boolean;
  readonly multiSelect: boolean;
}

export const DropdownContext = createContext<DropdownContextValue<any>>({
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: () => {},
  setHasFocusInside: () => {},
  isOpen: false,
  selectedValue: undefined,
  handleSelect: () => ({}),
  hasSubcategories: false,
  multiSelect: false
});
