import {
  BaseReportItem,
  ConfigOverride,
  DataDefinitionV2,
  ReportItem
} from '../types';
import { CsvTable, CsvTableHeader, CsvTableUnit } from '../../csv-table/types';
import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap
} from 'mobx';
import { BaseReportStore } from './base-report-store';
import { fillStringTemplate, formatValueByBusinessFormat } from '../utils';
import { RootStore } from '../../app/mobx/root-store';
import { ReportingPageFilterStore } from './reporting-page-filter-store';
import { GlobalIntl } from '../../intl/global-intl-provider';

export class TableChartStore extends BaseReportStore {
  private readonly _rootStore: RootStore;

  @observable
  private configOverride: ConfigOverride | undefined;

  constructor(item: ReportItem, rootStore: RootStore) {
    super(item);
    this._rootStore = rootStore;
    makeObservable(this);
  }

  public get table(): CsvTable {
    return {
      Configuration: {
        Title: this.params?.Title || '',
        HasFirstColumn: false,
        HasHeader: this.params?.HasHeader ?? true,
        ColumnLabels: true,
        HeaderLabels: true,
        RowsCount: 0,
        ColumnsCount: this.header.length
      },
      Header: this.header,
      FirstColumn: []
    };
  }

  @action
  public setConfigOverride(config: ConfigOverride | undefined) {
    this.configOverride = config;
  }

  @computed
  private get dataDefinition(): DataDefinitionV2[] {
    return this.configOverride?.DataDefinition ?? this._item.DataDefinition;
  }

  @computed
  private get params(): BaseReportItem['Params'] {
    return this.configOverride?.Params ?? this._item.Params;
  }

  @computed
  private get labelMetadataColumn(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(def => def.Purpose === 'ValueForRow');
  }

  @computed
  private get dataColumns(): DataDefinitionV2[] {
    return this.dataDefinition.filter(def => def.Purpose === 'DataValue');
  }

  @computed
  private get header(): CsvTableHeader[] {
    const labelColumn = this.labelMetadataColumn
      ? [this.mapColumnParams(this.labelMetadataColumn)]
      : [];

    return [
      ...labelColumn,
      ...this.dataColumns.map(def => this.mapColumnParams(def))
    ].sort((a, b) => a.Index - b.Index);
  }

  private mapColumnParams(definition: DataDefinitionV2): CsvTableHeader {
    return {
      BusinessFormat: definition.BusinessFormat ?? null,
      BorderLeft: Boolean(definition.BorderLeft),
      BorderRight: Boolean(definition.BorderRight),
      Color: definition.Color,
      ColumnName: definition.Name ?? '',
      ColSpan: definition.ColSpan,
      DataType: (definition.DataType as CsvTableUnit) ?? 'String',
      Format: definition.Format ?? null,
      GroupSameValues: Boolean(definition.GroupSameValues),
      HeaderAlignment: definition.HeaderAlignment ?? 'left',
      ValueAlignment: definition.ValueAlignment ?? 'left',
      ValueVerticalAlignment: definition.ValueVerticalAlignment,
      Width: definition.Width || '',
      Label: definition.Label || null,
      Tooltip: definition.TooltipLabel || '',
      TranslateValue: Boolean(definition.TranslateValue),
      TranslateHeader: Boolean(definition.TranslateHeader ?? true),
      Index: definition.Index,
      NoWrap: definition.NoWrap,
      Link: definition.Link
        ? item => {
            if (typeof item === 'object') {
              return '';
            }
            const link = fillStringTemplate(definition.Link || '', item);
            const url = new URL(link, window.location.origin);
            const currentSearchParams = new URLSearchParams(
              window.location.search
            );
            currentSearchParams.forEach((value, key) => {
              if (!url.searchParams.has(key)) {
                url.searchParams.set(key, value);
              }
            });

            return `${url.pathname}?${url.searchParams}`;
          }
        : undefined,
      HandleLinkClick:
        definition.Link && definition.LinkFilters
          ? item => {
              const memoryStore = this._rootStore.memoryStore;
              let persistedFilters = memoryStore.getItem<
                ObservableMap<string, string>
              >(ReportingPageFilterStore.MEMORY_STORE_KEY);

              if (!persistedFilters) {
                persistedFilters = new ObservableMap<string, string>();
                memoryStore.setItem(
                  ReportingPageFilterStore.MEMORY_STORE_KEY,
                  persistedFilters
                );
              }

              Object.entries(definition.LinkFilters || {}).forEach(
                ([filter, columnKey]) => {
                  persistedFilters!.set(filter, String(item[columnKey]));
                }
              );
            }
          : undefined,
      GetTooltipContent: definition.TooltipValue
        ? item => {
            const isReferencedColumn =
              definition.TooltipValue !== definition.Name;
            const def = isReferencedColumn
              ? this.dataDefinition.find(
                  d => d.Name === definition.TooltipValue
                )
              : definition;

            if (!def) {
              return undefined;
            }

            const value = item[def.Name];

            if (typeof value === 'object') {
              return;
            }

            const translatedValue = def.TranslateValue
              ? GlobalIntl.formatMessage({ id: String(value) }, item as any)
              : value;

            return formatValueByBusinessFormat(
              translatedValue,
              def.BusinessFormat,
              def.Format
            );
          }
        : undefined,
      GetColor:
        definition.Color || definition.ColorValue
          ? item => {
              if (definition.Color) {
                return definition.Color;
              }
              const def = this.dataDefinition.find(
                d => d.Name === definition.ColorValue
              );

              if (!def) {
                return undefined;
              }

              return String(item[def.Name]);
            }
          : undefined
    };
  }
}
