import { RootStore } from '../../app/mobx/root-store';
import { EvidenceDefinitionStore } from './evidence-definition-store';
import { EvidenceModuleConfiguration, EvidenceObject } from '../types';
import { EvidenceDataStore } from './evidence-data-store';
import {
  computed,
  IReactionDisposer,
  observable,
  reaction,
  when,
  makeObservable
} from 'mobx';
import { AsyncStatus } from '../../api/mobx/request-store';
import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from '../../routes/types';
import { UserGroupId } from '../../groups/types';

export class EvidenceStore {
  private readonly rootStore: RootStore;
  private static readonly DEFAULT_DEFINITION_URL =
    'api/evidence/def?moduleKey={moduleKey}';
  public static readonly DEFAULT_DATA_URL = 'api/evidence';

  @observable
  private _moduleKey: string | null = null;

  @observable
  private _moduleConfiguration: EvidenceModuleConfiguration | null = null;

  @observable
  private definitionStore: EvidenceDefinitionStore | null = null;

  @observable
  private _dataStore: EvidenceDataStore | null = null;

  @observable
  private _userId: number | null = null;

  @observable
  private _groupId: UserGroupId | null = null;

  @observable
  private _scrolledCategory: string | null = null;

  private reactions: IReactionDisposer[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;

    when(
      () => rootStore.status === AsyncStatus.resolved,
      async () => {
        if (!rootStore.modulesStore.evidence) {
          return;
        }
        this.registerReactions();
      }
    );
  }

  private registerReactions() {
    const observeModuleConfiguration = reaction(
      () => this._moduleConfiguration,
      async definition => {
        if (definition) {
          this.definitionStore = new EvidenceDefinitionStore(
            this.rootStore,
            definition.definitionUrl || EvidenceStore.DEFAULT_DEFINITION_URL,
            definition.moduleKey
          );
          await this.definitionStore.loadDefinition();
        }
      }
    );

    const observePathname = reaction(
      () => this.rootStore.historyService.pathname,
      pathname => {
        if (!pathname.includes('evidence')) {
          this._moduleKey = null;
        }
        const match = pathname.match(/\/evidence\/(.+)$/);
        this._moduleKey = match?.[1] || null;
      },
      {
        fireImmediately: true
      }
    );

    const observerAthleteId = reaction(
      () =>
        this.rootStore.historyService.searchParams.get(ATHLETE_SEARCH_PARAM),
      id => {
        this._userId = id !== undefined ? Number(id) : null;
      },
      {
        fireImmediately: true
      }
    );
    const observeGroupId = reaction(
      () => this.rootStore.historyService.searchParams.get(GROUP_SEARCH_PARAM),
      id => {
        this._groupId = id !== undefined ? Number(id) : null;
      },
      {
        fireImmediately: true
      }
    );

    const observeModuleKey = reaction(
      () => this._moduleKey,
      () => {
        this._moduleConfiguration =
          this.rootStore.configStore.evidenceModuleConfigurations.find(
            conf => conf.moduleKey === this._moduleKey
          ) || null;
        this._dataStore = null;
      },
      {
        fireImmediately: true
      }
    );

    const dataStore = reaction(
      () => ({
        definition: this.definition,
        userId: this._userId,
        groupId: this._groupId
      }),
      ({ definition }) => {
        if (definition && this._moduleKey) {
          this.createDataStore();
        }
      }
    );

    this.reactions.push(
      observePathname,
      observerAthleteId,
      observeGroupId,
      observeModuleConfiguration,
      dataStore,
      observeModuleKey
    );
  }

  private createDataStore(): void {
    if (this.moduleKey && this.definition) {
      this._dataStore = new EvidenceDataStore(
        this.rootStore,
        this.definition,
        this.moduleKey,
        this._userId,
        this._groupId,
        this.dataUrl!,
        this.isValidParamsCombination
      );

      if (this.isValidParamsCombination) this._dataStore.load();
    }
  }

  public setScrolledCategory(category: string): void {
    this._scrolledCategory = category;
  }

  public get scrolledCategory(): string | null {
    return this._scrolledCategory;
  }

  @computed
  private get dataUrl(): string {
    return this._moduleConfiguration?.dataUrl || EvidenceStore.DEFAULT_DATA_URL;
  }

  @computed
  public get definition(): EvidenceObject[] | undefined {
    return this.definitionStore?.definition;
  }

  public get userId(): number | null {
    return this._userId;
  }

  public get groupId(): number | null {
    return this._groupId;
  }

  public get moduleKey(): string | null {
    return this._moduleKey;
  }

  public get moduleConfiguration(): EvidenceModuleConfiguration | null {
    return this._moduleConfiguration;
  }

  public disposeReactions(): void {
    this.reactions.forEach(dispose => dispose());
  }

  @computed
  public get isCurrentUserAllowedToWrite(): boolean {
    const currentUser = this.rootStore.currentUserStore;

    return (
      currentUser.isAllowedTo(`evidence.${this.moduleKey}.write`) &&
      currentUser.hasWritePermission(this._groupId, this._userId)
    );
  }

  @computed
  public get isValidParamsCombination(): boolean {
    const cfg = this._moduleConfiguration;
    return Boolean(
      cfg &&
        ((cfg.athleteEnabled && this._userId) || // athlete is enabled so we require athlete id
          (cfg.groupEnabled && this._groupId) || // group is enabled so we require group id
          (!cfg.groupEnabled && !cfg.athleteEnabled))
    ); // athlete and group are disabled so we show data but they will not be editable
  }

  public get dataStore() {
    return this._dataStore;
  }
}
