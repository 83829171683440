import { useState } from 'react';
import { Image } from './image';
import { observer } from 'mobx-react-lite';
import { AlertLayerCloseButtonWrapper } from '@yarmill/components';
import { CloseButton } from '../components/close-button/close-button';

interface ImagePreviewProps {
  readonly closeLayer: () => void;
  readonly src: string | Promise<string> | null;
}
function InternalImagePreviewLayerContent({
  closeLayer,
  src
}: ImagePreviewProps): JSX.Element {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isValidSrc, setIsValidSrc] = useState(Boolean(src));

  if (!src) {
    return <></>;
  }

  return (
    <>
      <Image
        src={src}
        key={typeof src === 'string' ? src : 'loading-image'}
        isImageLoaded={isImageLoaded}
        isValidSrc={isValidSrc}
        setIsImageLoaded={setIsImageLoaded}
        setIsValidSrc={setIsValidSrc}
      />
      {isImageLoaded && (
        <AlertLayerCloseButtonWrapper>
          <CloseButton
            onClick={closeLayer}
            hideText
            autoFocus
            data-cy="close-image-preview"
          />
        </AlertLayerCloseButtonWrapper>
      )}
    </>
  );
}

export const ImagePreviewLayerContent = observer(
  InternalImagePreviewLayerContent
);
