import { MaskCode } from '../activities/types';
import { PermissionScope } from '../permissions/types';
import { PersonalizedSettingsConfiguration } from '../settings/types';

export type UserRole = 'athlete' | 'coach' | 'admin';

export type UserId = number;

export interface User {
  AccountId: number | null;
  AccountStateId: UserAccountState;
  AgreementValidTo: string | null;
  AvailableMasks: MaskCode[];
  AvatarFileName: string | null;
  Birthday: string;
  CommercialConsent: boolean;
  DefaultMaskCode: MaskCode;
  Email: string;
  ExternalServices: {
    polar?: boolean;
    suunto?: boolean;
    garmin?: boolean;
    google?: boolean;
    dexcom?: boolean;
  };
  FirstName: string;
  Gender: string;
  Instances: Instance[];
  LastName: string;
  MemberSince: string;
  MemberUntil: string | null;
  MiddleName: string;
  NewsletterConsent: boolean;
  Password?: string;
  Permissions: { [key in PermissionScope]: boolean };
  Phone: string;
  Role: UserRole;
  TokenValidity: string | null;
  UserId: UserId;
  Settings: PersonalizedSettingsConfiguration;
  Coaches: UserId[] | null;
}

export interface UserInvitation {
  FirstName: string;
  LastName: string;
  Email: string;
  Role: UserRole;
  EmailAlreadyExist?: boolean;
}

export enum UserAccountState {
  ACTIVE = 1,
  INACTIVE = 2,
  CANCELED = 3,
  REMOVED = 4,
  PENDING = 5
}

export interface Instance {
  Ident: string;
  Name: string;
  FeUrl: string;
}

export enum userCookies {
  USER = 'yarmill-current-user',
  IMPERSONATED_USER = 'yarmill-imp-user'
}
