import { useMemo } from 'react';
import { OverviewTableDataFormatter, OverviewTableDataRow } from './utils';
import { Category } from './category';

export interface CategoriesProps {
  columns: string[];
  row: number;
  data: OverviewTableDataRow;
  hasRowLabel?: boolean;
  formatter?: OverviewTableDataFormatter<string>;
}

interface CategoryDefinition {
  start: number;
  end: number;
  category: string;
}

export function findCategories(
  data: OverviewTableDataRow,
  columns: string[]
): CategoryDefinition[] {
  const sortedValues = data.Values.slice().sort(
    (a, b) => columns.indexOf(a.ColumnKey) - columns.indexOf(b.ColumnKey)
  );

  const categories: CategoryDefinition[] = [];

  let currentCategory: Partial<CategoryDefinition> | null = null;

  sortedValues.forEach((value, index) => {
    const next = sortedValues[index + 1];
    if (!currentCategory) {
      currentCategory = {
        start: index + 1,
        end: undefined,
        category: value.Category
      };
    }

    if (!next || next.Category !== currentCategory.category) {
      currentCategory.end = index + 1;
      categories.push(currentCategory as CategoryDefinition);
      currentCategory = null;
      return;
    }
  });

  return categories;
}

export function Categories(props: CategoriesProps): JSX.Element {
  const { data, columns, row, formatter, hasRowLabel } = props;
  const categories = useMemo(
    () => findCategories(data, columns),
    [data, columns]
  );

  return (
    <>
      {categories.map((category, idx) => (
        <Category
          key={idx}
          row={row}
          columnStart={category.start}
          columnEnd={category.end}
          formatter={formatter}
          category={category.category}
          hasRowLabel={hasRowLabel}
        />
      ))}
    </>
  );
}
