import { Icon, IconSize, styled } from '@yarmill/components';
import { TextInputAppearance, TextInputLabel } from './text-input';
import {
  ChangeEventHandler,
  forwardRef,
  KeyboardEvent,
  KeyboardEventHandler,
  RefObject,
  useRef
} from 'react';
import { Text } from './text';
import { StyledTag, Tag } from './tag';

export type TagInputValue =
  | string
  | {
      label: string;
      value: number;
    };
interface TagInputProps {
  readonly id?: string;
  readonly value: TagInputValue[];
  readonly name: string;
  readonly label?: string;
  readonly icon?: JSX.Element;
  readonly tagIcon?: JSX.Element;
  readonly disableCreate?: boolean;
  readonly onRemove?: (value: TagInputValue[]) => void;
  readonly additionalButton?: JSX.Element;
  readonly emptyText?: string;
  readonly tagAppearance: TextInputAppearance;
  readonly onClick?: () => void;
  readonly disabled?: boolean;
  readonly isSearchInput?: boolean;
  readonly onInput?: ChangeEventHandler;
  readonly placeholder?: string;
  readonly searchValue?: string;
  readonly autoFocus?: boolean;
  readonly inputRef?: RefObject<HTMLInputElement>;
  readonly onKeyDown?: KeyboardEventHandler;
}

const TagInputLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.size.x1};
`;

const TagsWrapper = styled.div<{ readonly hasAdditionalButton?: boolean }>`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.size.x05};
  max-width: ${({ hasAdditionalButton, theme }) =>
    hasAdditionalButton
      ? `calc(
    100% - ${theme.size.x3} - ${theme.size.x2} -
      ${theme.size.x1}`
      : '100%'};
  );
`;

const Input = styled.input<{ readonly disableCreate?: boolean }>`
  width: ${({ disableCreate, theme }) =>
    disableCreate ? theme.size.x2 : 'unset'};
  flex-grow: 1;
  border: 0;
  background: transparent;
  color: ${({ theme }) => theme.color.white};
  height: ${({ theme }) => theme.size.x3};

  :focus,
  :focus-visible {
    outline: none;
  }
`;

const EmptyTag = styled(StyledTag)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.neutral};
  border-color: ${({ theme }) => theme.color.text};
  background: ${({ theme }) => theme.color.text};
`;

const TagInputContainer = styled.div<{
  readonly noBorderRadius?: boolean;
  readonly doublePadding?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  border-radius: ${({ theme, noBorderRadius }) =>
    noBorderRadius ? 0 : theme.borderRadius.x075};
  background: ${({ theme }) => theme.color.blackHover};
  padding: ${({ theme, doublePadding }) =>
    doublePadding ? `${theme.size.x05} ${theme.size.x1}` : theme.size.x05};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.size.x3};
  height: ${({ theme }) => theme.size.x3};
  color: ${({ theme }) => theme.color.neutralDark};
`;

export const TagInput = forwardRef<HTMLDivElement, TagInputProps>(
  function TagInput(
    {
      value,
      tagIcon,
      disableCreate,
      onRemove,
      label,
      id,
      additionalButton,
      emptyText,
      tagAppearance,
      onClick,
      disabled,
      icon,
      isSearchInput,
      onInput,
      placeholder,
      searchValue,
      autoFocus,
      inputRef: passedRef,
      onKeyDown
    }: TagInputProps,
    ref
  ): JSX.Element {
    const localRef = useRef<HTMLInputElement>(null);
    const inputRef = passedRef ?? localRef;
    function handleKeyDown(e: KeyboardEvent): void {
      e.stopPropagation();
      if (disableCreate) {
        e.preventDefault();
      }
      if (value.length && e.key === 'Backspace' && !searchValue) {
        e.preventDefault();
        onRemove?.([...value.slice(0, -1)]);
      }
      onKeyDown?.(e);
    }

    return (
      <TagInputLayout
        onClick={onClick ?? (() => inputRef.current?.focus())}
        ref={ref}
      >
        {label && (
          <TextInputLabel
            as="label"
            appearance="button10"
            htmlFor={id}
            whiteSpace="noWrap"
            upperCase
            appearanceStyle="black"
          >
            {label}
          </TextInputLabel>
        )}
        <TagInputContainer
          noBorderRadius={isSearchInput}
          doublePadding={isSearchInput}
        >
          <TagsWrapper>
            {icon && (
              <IconWrapper>
                <Icon size={IconSize.s16}>{icon}</Icon>
              </IconWrapper>
            )}
            {value.map(tag => (
              <Tag
                key={typeof tag === 'string' ? tag : tag.value}
                appearance={tagAppearance}
                disabled={disabled}
                icon={tagIcon}
                label={typeof tag === 'string' ? tag : tag.label}
                value={typeof tag === 'string' ? tag : tag.value}
              />
            ))}
            {value.length === 0 && emptyText && (
              <EmptyTag appearance="black" disabled={disabled}>
                <Text
                  inheritColor
                  upperCase
                  appearance="label10strong"
                  ellipsis
                >
                  {emptyText}
                </Text>
              </EmptyTag>
            )}
            {(!disableCreate || (disableCreate && value.length !== 0)) &&
              !disabled && (
                <Input
                  ref={inputRef}
                  onKeyDown={handleKeyDown}
                  onChange={onInput}
                  id={id}
                  disableCreate={disableCreate}
                  placeholder={value.length === 0 ? placeholder : undefined}
                  value={searchValue}
                  autoFocus={autoFocus}
                />
              )}
          </TagsWrapper>
          {additionalButton}
        </TagInputContainer>
      </TagInputLayout>
    );
  }
);
