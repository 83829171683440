import {
  BreakpointWrapper,
  Breakpoints,
  Text,
  TextSize
} from '@yarmill/components';
import moment from 'moment';
import { FormattedDate } from 'react-intl';
import { useAttendanceWeekViewColumns } from './hooks';
import { SummaryView } from './summary-view';
import { useCallback } from 'react';

export function WeekSummaryView(): JSX.Element {
  const columns = useAttendanceWeekViewColumns();

  const formatHeader = useCallback((column: string) => {
    const date = moment(column).toDate();

    return (
      <BreakpointWrapper min={Breakpoints.tablet}>
        <FormattedDate value={date} weekday="short">
          {(msg: string) => (
            <Text bold size={TextSize.s14}>
              {msg}
            </Text>
          )}
        </FormattedDate>
        <br />
        <FormattedDate value={date} month="numeric" day="numeric">
          {(msg: string) => (
            <Text bold size={TextSize.s14}>
              {msg}
            </Text>
          )}
        </FormattedDate>
        <br />
      </BreakpointWrapper>
    );
  }, []);

  return <SummaryView columns={columns} formatHeader={formatHeader} />;
}
