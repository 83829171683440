import { createElement } from 'react';

export function Italic() {
  return createElement(
    'svg',
    {
      viewBox: '0 0 18 18',
      xmlns: 'http://www.w3.org/2000/svg',
      fill: 'currentColor',
      role: 'img'
    },
    createElement('path', {
      fill: 'none',
      stroke: 'currentColor',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.6',
      d: 'M7 4h6M5 14h6M8 14l2-10'
    })
  );
}
