import { FormattedMessage } from 'react-intl';
import { Service } from '../components/service';
import { observer } from 'mobx-react-lite';
import { useConfig } from '../app/hooks';

import { CurrentUserStore } from '../users/mobx/current-user-store';
import { useAuthStore } from '../auth/hooks';
import { ContentBox, ContentBoxHead, Text } from '@yarmill/components';

export interface ServicesProps {
  user: CurrentUserStore;
}

export const Services = observer(function Services(
  props: ServicesProps
): JSX.Element | null {
  const { user } = props;
  const availableServices = useConfig('availableExternalServices');
  const token = useAuthStore().token;

  if (availableServices.length === 0 || !token) {
    return null;
  }

  return (
    <ContentBox>
      <ContentBoxHead>
        <Text medium>
          <FormattedMessage id="settings.profile.services" />
        </Text>
      </ContentBoxHead>
      {availableServices.map(service => (
        <Service
          key={service}
          ident={service}
          name={
            <FormattedMessage
              id={`settings.profile.services.${service}.name`}
            />
          }
          description={
            <FormattedMessage
              id={`settings.profile.services.${service}.description.${user.data?.Role}`}
            />
          }
          connected={user.data?.ExternalServices[service]}
          userId={user.id}
          token={token}
          editable={service !== 'apple-health'}
          hideBorder={
            service === 'garmin' || service === 'suunto' || service === 'oura'
          }
        />
      ))}
    </ContentBox>
  );
});
