import {
  EvidenceAttribute,
  EvidenceAttributeId,
  EvidenceAttributeType,
  EvidenceDataObjectAttribute
} from '../types';
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable
} from 'mobx';
import { EvidenceObjectDataStore } from './evidence-object-data-store';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import { File } from '../../fileupload/types';

export class EvidenceAttachmentStore {
  private readonly _objectDataStore: EvidenceObjectDataStore;
  private readonly _definition: EvidenceAttribute;
  private readonly _objectKey: string;
  private readonly _userId: UserId | null;
  private readonly _groupId: UserGroupId | null;

  @observable
  private _attachments: IObservableArray<File> = observable.array([]);

  private _apiValue: File[] = [];

  constructor(
    objectDataStore: EvidenceObjectDataStore,
    objectKey: string,
    definition: EvidenceAttribute,
    userId: UserId | null,
    groupId: UserGroupId | null
  ) {
    makeObservable(this);
    this._objectDataStore = objectDataStore;
    this._objectKey = objectKey;
    this._definition = definition;
    this._userId = userId;
    this._groupId = groupId;
  }

  public get definition(): EvidenceAttribute {
    return this._definition;
  }

  public get objectKey(): string {
    return this._objectKey;
  }

  @action
  public setApiValue(value: File[]): void {
    this._attachments.replace(value);
    this._apiValue = value;
  }

  @computed
  public get attachments(): File[] {
    return this._attachments;
  }

  @computed
  public get hasValue(): boolean {
    return this.attachments.length > 0;
  }

  @computed
  public get isRequired(): boolean {
    return !this._definition.IsOptional && !this._definition.IsCalculated;
  }

  @computed
  public get attributeValueObject(): EvidenceDataObjectAttribute {
    return {
      AttributeId: this.definition.AttributeId,
      AttributeValue: this.attachments
    };
  }

  public get id(): EvidenceAttributeId {
    return this.definition.AttributeId;
  }

  public get attributeType(): EvidenceAttributeType {
    return this.definition.AttributeTypeKey;
  }

  public get objectDataStore(): EvidenceObjectDataStore {
    return this._objectDataStore;
  }

  public get userId(): UserId | null {
    return this._userId;
  }

  public get groupId(): UserGroupId | null {
    return this._groupId;
  }

  @action
  public clear(): void {
    this._attachments.clear();
    this._apiValue = [];
  }

  @action
  public reset(): void {
    this._attachments.replace(this._apiValue);
  }

  @action
  public readonly prefill = (value: File[]): void => {
    this._attachments.replace(value);
  };

  @action
  public readonly removeAttachment = (value: File): void => {
    this._attachments.remove(value);
  };

  @action
  public readonly addAttachment = (value: File): void => {
    this._attachments.push(value);
  };
}
