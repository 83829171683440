const charactersWidths = {
  a: 7.3125,
  b: 8.25,
  c: 6.515625,
  d: 8.25,
  e: 7.828125,
  f: 5.40625,
  g: 8.09375,
  h: 8,
  i: 3.546875,
  j: 3.546875,
  k: 7.3125,
  l: 3.828125,
  m: 12.0546875,
  n: 8.0390625,
  o: 8.265625,
  p: 8.25,
  q: 8.25,
  r: 5.40625,
  s: 6.25,
  t: 5.6328125,
  u: 8.0390625,
  v: 7.03125,
  w: 10.8828125,
  x: 7.15625,
  y: 6.9609375,
  z: 6.6015625,
  A: 9.2890625,
  B: 9.0078125,
  C: 8.6875,
  D: 9.984375,
  E: 8,
  F: 7.5234375,
  G: 9.4140625,
  H: 9.875,
  I: 3.7734375,
  J: 7,
  K: 8.8125,
  L: 7.2734375,
  M: 12.1953125,
  N: 10.1953125,
  O: 10.8984375,
  P: 8.515625,
  Q: 10.8984375,
  R: 8.8125,
  S: 7.453125,
  T: 7.9140625,
  U: 9.6328125,
  V: 9.1875,
  W: 13.0078125,
  X: 8.8359375,
  Y: 8.375,
  Z: 8.0234375,
  0: 6.2109375,
  1: 6.2109375,
  2: 6.2109375,
  3: 6.2109375,
  4: 6.2109375,
  5: 6.2109375,
  6: 6.2109375,
  7: 6.2109375,
  8: 6.2109375,
  9: 6.2109375,
  10: 6.2109375
};

export function getSSRStringWidth(text: string): number {
  if (!text) {
    return 0;
  }

  return String(text)
    .split('')
    .map(char => charactersWidths[char] || 10)
    .reduce((partialSum, a) => partialSum + a, 0);
}
