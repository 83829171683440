import {
  autoUpdate,
  flip,
  offset,
  Placement,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles
} from '@floating-ui/react';
import { useState } from 'react';

export function useEventPreview(
  getPreviewContent?: (placement: Placement) => JSX.Element
): [
  { setReference: (el: HTMLDivElement | null) => void },
  () => Record<string, unknown>,
  JSX.Element | null
] {
  const [isPreviewOpened, setIsPreviewOpened] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isPreviewOpened,
    onOpenChange: setIsPreviewOpened,
    placement: 'right-start',
    middleware: [
      flip(({ elements }) => ({
        mainAxis: true,
        crossAxis: true,
        padding: 7
      })),
      offset(({ elements, placement }) => ({
        mainAxis: 7,
        crossAxis:
          ((placement.endsWith('start') ? 1 : -1) *
            elements.reference.getBoundingClientRect().height) /
            2 +
          (placement.endsWith('start') ? -20 : 20)
      }))
    ],
    whileElementsMounted: autoUpdate
  });
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context);
  const dismiss = useDismiss(context, {
    bubbles: true
  });
  const click = useClick(context, { enabled: window.innerWidth >= 768 });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    click
  ]);

  const preview =
    isPreviewOpened && isMounted ? (
      <div
        style={{ ...floatingStyles, ...transitionStyles, zIndex: 1 }}
        {...getFloatingProps()}
        ref={refs.setFloating}
      >
        {getPreviewContent?.(context.placement)}
      </div>
    ) : null;

  return [refs, getReferenceProps, preview];
}
