import { observer } from 'mobx-react-lite';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { TrainingDayStore } from './mobx/training-day-store';
import { WeekSummaryStore } from './mobx/week-summary-store';
import moment from 'moment';
import { Text, TextSize, TextTag } from '@yarmill/components';

export interface TrainingDayDateProps {
  day: TrainingDayStore;
}

const StyledDay = styled.span`
  font-weight: bold;
  text-transform: capitalize;
  padding-right: 15px;
`;

function InternalTrainingDayDate(props: TrainingDayDateProps): JSX.Element {
  const { day } = props;
  const date = day.currentDate;
  const momentDate = moment(date);

  if (day instanceof WeekSummaryStore) {
    const weekNumber = momentDate.isoWeek();

    return (
      <Text tag={TextTag.div} size={TextSize.inherit}>
        <StyledDay>
          {weekNumber}
          . <FormattedMessage id="trainingDay.attributes.week" />
        </StyledDay>
      </Text>
    );
  }

  return (
    <Text tag={TextTag.div} size={TextSize.inherit}>
      <StyledDay>
        <FormattedDate value={momentDate.toDate()} weekday="long" />
      </StyledDay>
      <FormattedDate
        value={momentDate.toDate()}
        day="numeric"
        month="numeric"
        year="numeric"
      />
      <br />
    </Text>
  );
}

export const TrainingDayDate = observer(InternalTrainingDayDate);
