import { observable, makeObservable } from 'mobx';
import { KeyResult, OkrStatus } from '../types';
import { uniqId } from '../../app/utils';
import moment from 'moment';
import { ROUTE_DATE_FORMAT } from '../../diary/utils';

export class KeyResultStore {
  @observable
  private _id: number | null = null;
  @observable
  private _uid: string = uniqId();
  @observable
  private _title: string | null = null;
  @observable
  private _targetDate: string | null = null;
  @observable
  private _status: OkrStatus | null = null;
  @observable
  private _sortCode: number | null = null;
  @observable
  private _metric: KeyResult['metric'] = 'numeric';
  @observable
  private _progressValue: KeyResult['progressValue'] = 0;
  @observable
  private _startValue: KeyResult['startValue'] = null;
  @observable
  private _currentValue: KeyResult['currentValue'] = null;
  @observable
  private _targetValue: KeyResult['targetValue'] = null;
  @observable
  private _lastUpdateDate: string | null = moment().format(ROUTE_DATE_FORMAT);

  constructor() {
    makeObservable(this);
  }
  public get id(): Readonly<typeof this._id> {
    return this._id;
  }

  public get uid(): Readonly<typeof this._uid> {
    return this._uid;
  }
  public get title(): Readonly<typeof this._title> {
    return this._title;
  }

  public get targetDate(): Readonly<typeof this._targetDate> {
    return this._targetDate?.slice(0, 10) ?? null;
  }

  public get metric(): Readonly<typeof this._metric> {
    return this._metric;
  }

  public get status(): Readonly<typeof this._status> {
    return this._status;
  }

  public get startValue(): Readonly<typeof this._startValue> {
    return this._startValue;
  }

  public get progressValue(): Readonly<typeof this._progressValue> {
    return this._progressValue;
  }

  public get currentValue(): Readonly<typeof this._currentValue> {
    return this._currentValue;
  }

  public get targetValue(): Readonly<typeof this._targetValue> {
    return this._targetValue;
  }

  public get sortCode(): Readonly<typeof this._sortCode> {
    return this._sortCode;
  }

  public get lastUpdateDate(): Readonly<typeof this._lastUpdateDate> {
    return this._lastUpdateDate;
  }

  public setId(value: number | null): this {
    this._id = value;
    return this;
  }

  public setUid(value: string): this {
    this._uid = value;
    return this;
  }

  public setTitle(value: string | null): this {
    this._title = value;
    return this;
  }

  public setTargetDate(value: string | null): this {
    this._targetDate = value;
    return this;
  }

  public setStatus(value: OkrStatus | null): this {
    this._status = value;
    return this;
  }

  public setMetric(value: KeyResult['metric']): this {
    this._metric = value;
    return this;
  }

  public setProgressValue(value: KeyResult['progressValue']): this {
    this._progressValue = value;
    return this;
  }

  public setStartValue(value: KeyResult['startValue']): this {
    this._startValue = value;
    return this;
  }

  public setCurrentValue(value: KeyResult['currentValue']): this {
    this._currentValue = value;
    return this;
  }

  public setTargetValue(value: KeyResult['targetValue']): this {
    this._targetValue = value;
    return this;
  }
  public setSortCode(value: KeyResult['sortCode']): this {
    this._sortCode = value;
    return this;
  }

  public setLastUpdateDate(value: KeyResult['lastUpdated']): this {
    this._lastUpdateDate = value;
    return this;
  }

  public toFormValues(): KeyResult {
    return {
      id: this.id,
      uid: this.uid,
      title: this.title ?? '',
      targetDate: this.targetDate ?? '',
      startValue: this.startValue ?? '',
      targetValue: this.targetValue ?? '',
      status: this.status,
      currentValue: this.currentValue ?? '',
      metric: this.metric,
      progressValue: this.progressValue,
      lastUpdated: this.lastUpdateDate,
      sortCode: this.sortCode
    };
  }

  public toJS(): KeyResult {
    return {
      id: this.id,
      uid: this.uid,
      title: this.title,
      targetDate: this.targetDate || null,
      startValue: this.startValue,
      targetValue: this.targetValue,
      status: this.status,
      currentValue: this.currentValue,
      metric: this.metric,
      progressValue: this.progressValue,
      sortCode: this.sortCode,
      lastUpdated: this.lastUpdateDate
    };
  }
}
