import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';

export function getTranslations(
  locale: string | null,
  cancelToken: CancelTokenSource
): AxiosPromise<Record<string, string>> {
  return axios.get(`api/Dictionary${locale ? `/${locale}` : ''}`, {
    cancelToken: cancelToken.token
  });
}
