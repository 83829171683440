import styled from 'styled-components';

const AlertLayerContainer = styled.div<{ readonly autoWidth?: boolean }>`
  padding: 0 32px;
  ${({ autoWidth }) => !autoWidth && 'width: 90vw'};

  @media (min-width: 560px) {
    ${({ autoWidth }) => !autoWidth && 'width: 500px'};
  }
`;

const StickyWrapper = styled.div`
  position: sticky;
  top: 0;
  margin: 0 -32px;
  z-index: 1;
`;

const ContentWrapper = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const HeadlineWrapper = styled.div`
  position: sticky;
  top: 0;
  padding-top: 32px;
  padding-bottom: 8px;
  background-color: #ffffff;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  gap: 20px;
  position: sticky;
  bottom: 0;
  padding-bottom: 32px;
  padding-top: 20px;
  background-color: #ffffff;

  @media (max-width: 450px) {
    grid-template-columns: repeat(3, auto);
  }
`;

export const AlertLayerContent = {
  AlertLayerContainer,
  ButtonsWrapper,
  ContentWrapper,
  StickyWrapper,
  HeadlineWrapper
};
