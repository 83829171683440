export type KPIType =
  | 'DZ AbsDiff'
  | 'DZ Plan'
  | 'DZ Reality'
  | 'DZ RelDiff'
  | 'HZ AbsDiff'
  | 'HZ Cycle AbsDiff'
  | 'HZ Cycle Plan'
  | 'HZ Cycle Reality'
  | 'HZ Cycle RelDiff'
  | 'HZ Plan'
  | 'HZ Reality'
  | 'HZ RelDiff'
  | 'HZ Season AbsDiff'
  | 'HZ Season Plan'
  | 'HZ Season Reality'
  | 'HZ Season RelDiff'
  | 'HZ Week AbsDiff'
  | 'HZ Week Plan'
  | 'HZ Week Reality'
  | 'HZ Week RelDiff'
  | 'Nem Reality';

export interface KPI {
  MeasureCode: KPIType;
  UserId: number;
  Value: number;
}

export interface Trend {
  StateId: TrendState;
  OwnerId: number;
  Value: number;
  CurrentFlag: number;
}

export interface WeekTrend extends Trend {
  DayNum: number;
}

export interface SeasonTrend extends Trend {
  CycleNum: number;
}

export interface CycleTrend extends Trend {
  WeekNumCycle: number;
}

export type TrendType = WeekTrend | SeasonTrend | CycleTrend;

export enum TrendState {
  plan = 1,
  reality = 2
}

export function isWeekTrend(
  trend: WeekTrend | SeasonTrend | CycleTrend
): trend is WeekTrend {
  return (trend as Partial<WeekTrend>).DayNum !== undefined;
}

export function isSeasonTrend(
  trend: WeekTrend | SeasonTrend | CycleTrend
): trend is SeasonTrend {
  return (trend as Partial<SeasonTrend>).CycleNum !== undefined;
}

export function isCycleTrend(
  trend: WeekTrend | SeasonTrend | CycleTrend
): trend is CycleTrend {
  return (trend as Partial<CycleTrend>).WeekNumCycle !== undefined;
}
