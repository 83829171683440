import { useEffect } from 'react';
import {
  ATHLETE_SEARCH_PARAM,
  GROUP_SEARCH_PARAM,
  WEEK_SEARCH_PARAM
} from '../../routes/types';
import moment from 'moment/moment';
import { ROUTE_DATE_FORMAT } from '../../diary/utils';
import { useRootStore } from '../../app/root-store-context';
import { useHistory } from 'react-router-dom';
import { useOkrsStore } from '../okrs-store-context';
import { useCurrentUserStore } from '../../users/hooks';
import { useSidebarStore } from '../../sidebar/hooks/use-sidebar-store';

export function useEnsureUrlParameters(): void {
  const rootStore = useRootStore();
  const history = useHistory();
  const okrsStore = useOkrsStore();
  const currentUser = useCurrentUserStore();
  const sidebarStore = useSidebarStore();

  useEffect(() => {
    if (!rootStore.isReady) {
      return;
    }
    if (!okrsStore.groupId || !okrsStore.week) {
      const { location } = history;
      const search = new URLSearchParams(location.search);
      const group = okrsStore.group ?? rootStore.groupsStore.sortedGroups[0];

      if (!okrsStore.week) {
        search.set(WEEK_SEARCH_PARAM, moment().format(ROUTE_DATE_FORMAT));
      }

      if (!okrsStore.groupId) {
        if (group) {
          search.set(GROUP_SEARCH_PARAM, String(group.id));
        }
        search.set(ATHLETE_SEARCH_PARAM, String(currentUser.id));
        if (window.innerWidth < 768) {
          sidebarStore.hide();
        }
      }

      history.replace({ ...location, search: search.toString() });
    }
  }, [
    history,
    okrsStore,
    rootStore,
    rootStore.isReady,
    okrsStore.athleteId,
    okrsStore.groupId,
    okrsStore.week,
    currentUser.id,
    sidebarStore
  ]);
}
