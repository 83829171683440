import { TrainingCycleEvent, TrainingEvent } from './training-event';
import { Color } from '@yarmill/components';
import moment from 'moment';
import {
  MouseEvent as ReactMouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

export interface CreateEventHandlers {
  newEvent: JSX.Element | null;
  onMouseDown: MouseEventHandler;
  onMouseOver: MouseEventHandler | undefined;
  onDoubleClick: MouseEventHandler;
}

export type OnEventCreatedCallback = (createdEvent: TrainingCycleEvent) => void;

function handleMouseEvent(e: ReactMouseEvent | MouseEvent): string | null {
  const { target } = e;
  if (
    target instanceof HTMLDivElement &&
    target.getAttribute('data-type') === 'day'
  ) {
    const date = target.getAttribute('data-date');

    if (!date) {
      return null;
    }

    return date;
  }

  return null;
}

export function useCreateEventHandlers(
  onEventCreated: OnEventCreatedCallback,
  newEventLabel: string,
  disableAdd?: boolean,
  lastColor?: Color,
  onCreateStart?: () => void
): CreateEventHandlers {
  const [dragStart, setDragStart] = useState<string | null>(null);
  const [start, setStart] = useState<string | null>(null);
  const [end, setEnd] = useState<string | null>(null);
  const hasEnd = useRef(false);
  const [finished, setFinished] = useState<boolean>(false);
  const allowOneDayEvent = useRef(false);

  const onMouseDown = useCallback(
    (e: ReactMouseEvent<HTMLDivElement>) => {
      if (disableAdd || e.button !== 0) {
        return;
      }
      const mouseY = e.clientY;
      const mouseX = e.clientX;
      e.preventDefault();
      allowOneDayEvent.current = false;

      const startLocator = handleMouseEvent(e);
      if (startLocator) {
        onCreateStart?.();
        setStart(startLocator);
        setDragStart(startLocator);
        setFinished(false);
      }

      function onMouseMove(e: MouseEvent) {
        if (Math.abs(e.clientY - mouseY) < 2) {
          return;
        }
        document.removeEventListener('mousemove', onMouseMove);
        allowOneDayEvent.current = true;
        setEnd(end => (end === null ? startLocator : end));
      }

      function onMouseUp(e: MouseEvent) {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);

        function cancelEvent() {
          setStart(null);
          setDragStart(null);
          setEnd(null);
          setFinished(false);
        }

        if (
          Math.abs(mouseY - e.clientY) < 2 &&
          Math.abs(mouseX - e.clientX) < 2
        ) {
          cancelEvent();
          return;
        }

        const endLocator = handleMouseEvent(e);
        if (
          (endLocator || hasEnd.current) &&
          (startLocator !== endLocator || allowOneDayEvent.current)
        ) {
          setFinished(true);
        } else {
          cancelEvent();
        }
      }

      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('mousemove', onMouseMove);
    },
    [disableAdd, onCreateStart]
  );

  const onMouseOver = useCallback(
    (e: ReactMouseEvent<HTMLDivElement>) => {
      const locator = handleMouseEvent(e);
      const hoveredDay = moment(locator);
      const dragStartDay = moment(dragStart);

      if (locator && hoveredDay.isValid()) {
        if (!hoveredDay.isSame(dragStartDay, 'day')) {
          allowOneDayEvent.current = true;
        }
        if (hoveredDay.isBefore(dragStartDay)) {
          setStart(locator);
          setEnd(dragStart);
        } else {
          setEnd(locator);
        }
        hasEnd.current = true;
      }
    },
    [dragStart]
  );

  useEffect(() => {
    if (finished && start && (end || allowOneDayEvent.current)) {
      const evt: TrainingCycleEvent = {
        start,
        end: end || start,
        name: 'New event'
      };

      onEventCreated(evt);
      setFinished(false);
      setStart(null);
      setDragStart(null);
      setEnd(null);
    }
  }, [finished, start, end, setStart, setEnd, setFinished, onEventCreated]);

  const newEvent = useMemo(
    () =>
      start && (end || allowOneDayEvent.current) ? (
        <TrainingEvent
          name={newEventLabel}
          start={start}
          end={end || start}
          color={lastColor}
          showEditHandles
        />
      ) : start ? (
        <span />
      ) : null,

    [start, end, newEventLabel, lastColor]
  );

  const onDoubleClick = useCallback(
    (e: ReactMouseEvent<HTMLDivElement>) => {
      if (disableAdd || e.button !== 0) {
        return;
      }

      e.preventDefault();
      allowOneDayEvent.current = false;

      const startLocator = handleMouseEvent(e);
      if (startLocator) {
        onCreateStart?.();
        setStart(startLocator);
        setEnd(startLocator);
        setFinished(true);
      }
    },
    [disableAdd, onCreateStart]
  );

  return {
    newEvent,
    onMouseDown,
    onMouseOver: newEvent ? onMouseOver : undefined,
    onDoubleClick
  };
}
