import { FilesOverviewStore } from './mobx/files-overview-store';
import {
  memo,
  useContext,
  createContext,
  PropsWithChildren,
  useRef
} from 'react';
import { useRootStore } from '../app/root-store-context';

export const FilesOverviewStoreContext = createContext<
  FilesOverviewStore | undefined
>(undefined);

export function useFilesOverviewStore(): FilesOverviewStore {
  const diaryStore = useContext(FilesOverviewStoreContext);
  if (!diaryStore) {
    throw new Error('FilesOverview Store not available');
  }

  return diaryStore;
}

export function InternalFilesOverviewStoreContextProvider(
  props: PropsWithChildren<{}>
): JSX.Element {
  const rootStore = useRootStore();
  const diaryStore = useRef(new FilesOverviewStore(rootStore)).current;

  return (
    <FilesOverviewStoreContext.Provider value={diaryStore}>
      {props.children}
    </FilesOverviewStoreContext.Provider>
  );
}

export const FilesOverviewStoreContextProvider = memo(
  InternalFilesOverviewStoreContextProvider
);
