import { UserSelect } from '../groups/user-select';
import { useRootStore } from '../app/root-store-context';
import { useDiaryStore } from '../diary/diary-store-context';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AthletesInGroupSelector } from './athletes-in-group-selector';
import { GroupsSelector } from './groups-selector';
import { SelectSourceDay } from './select-source-days';
import { getDaysInWeek, getWeekStartString } from '../diary/utils';
import { SelectTargetDays } from './select-target-days';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
  Checkbox,
  ContentBox,
  ContentBoxHead,
  CopySourceDaysContentBoxHead,
  SelectBox,
  Text,
  TextAlignment,
  TextSize
} from '@yarmill/components';
import { CopyElement, CopyMode } from './types';
import { SeasonStore } from '../seasons/mobx/season-store';
import { observer } from 'mobx-react-lite';

export interface CopyElementProps {
  elementType: CopyElement;
  onChange(
    name: string,
    value: number | number[] | string | string[] | boolean
  ): void;
}

export const Athletes = observer(function Athletes(
  props: CopyElementProps
): JSX.Element {
  const { onChange, elementType } = props;
  const rootStore = useRootStore();
  const diaryStore = useDiaryStore();
  const currentUser = rootStore.currentUserStore;

  const excludedUsers = useMemo(
    () => [
      // We want only athletes that current user has permissions write to
      ...Array.from(rootStore.usersStore.activeUsers.values())
        .filter(
          u =>
            u.internalUser.Role !== 'athlete' ||
            !currentUser.hasWritePermission(null, u.id)
        )
        .map(u => u.id),
      diaryStore.athleteId as number // we do not want to show athlete we are copying from
    ],
    [rootStore.usersStore.activeUsers, diaryStore.athleteId, currentUser]
  );
  return (
    <ContentBox>
      <ContentBoxHead>
        <Text medium size={TextSize.s16}>
          <FormattedMessage id="plan.copy.selectAthletes" />
        </Text>
      </ContentBoxHead>
      <UserSelect
        onChange={options =>
          onChange(
            elementType,
            options.map(o => o.value)
          )
        }
        excludedUsers={excludedUsers}
      />
    </ContentBox>
  );
});

export const AthletesInGroup = observer(function AthletesInGroup(
  props: CopyElementProps
): JSX.Element {
  const { onChange, elementType } = props;
  const diaryStore = useDiaryStore();

  return (
    <AthletesInGroupSelector
      groupId={diaryStore.groupId!}
      onChange={userIds => onChange(elementType, userIds)}
    />
  );
});

export const Groups = observer(function Groups(
  props: CopyElementProps & { copyMode: CopyMode }
): JSX.Element {
  const { onChange, elementType, copyMode } = props;
  const diaryStore = useDiaryStore();

  return (
    <GroupsSelector
      excludedGroupId={
        copyMode !== 'athleteToGroups' ? diaryStore.groupId! : undefined
      }
      onChange={groupIds => onChange(elementType, groupIds)}
    />
  );
});

export const SourceDay = observer(function SourceDay(
  props: CopyElementProps
): JSX.Element | null {
  const { onChange, elementType } = props;
  const diaryStore = useDiaryStore();
  const weekDays = useMemo(
    () => getDaysInWeek(diaryStore.week),
    [diaryStore.week]
  );
  const [selectedDays, setSelectedDays] = useState(weekDays);
  const [includeGoal, setIncludeGoal] = useState(true);

  const handleChange = useCallback(
    (selectedDays: string[]) => {
      setSelectedDays(selectedDays);
      onChange(elementType, selectedDays);
    },
    [onChange, elementType]
  );

  const handleWeekGoalChange = useCallback(() => {
    setIncludeGoal(!includeGoal);
    onChange('includeWeekGoal', !includeGoal);
  }, [onChange, includeGoal]);

  useEffect(() => {
    // Set defaults
    handleChange(weekDays);
    onChange('includeWeekGoal', true);
  }, [handleChange, weekDays, diaryStore.viewType, onChange]);

  return (
    <ContentBox>
      <CopySourceDaysContentBoxHead>
        <Text medium size={TextSize.s16}>
          <FormattedMessage id="plan.copy.selectSourceDays" />
        </Text>

        <Checkbox
          value="include-goal"
          checked={includeGoal}
          onClick={handleWeekGoalChange}
          narrow
          label={
            <Text size={TextSize.s14} textAlign={TextAlignment.right}>
              <FormattedMessage id="plan.copy.includeWeekGoal.label" />
            </Text>
          }
        />
      </CopySourceDaysContentBoxHead>
      <SelectSourceDay
        weekDays={weekDays}
        selectedDays={selectedDays}
        onChange={handleChange}
      />
    </ContentBox>
  );
});

export const SourceWeek = observer(function SourceWeek(
  props: CopyElementProps
): null {
  const { onChange, elementType } = props;
  const diaryStore = useDiaryStore();

  useEffect(() => {
    onChange(elementType, getWeekStartString(diaryStore.week));
  }, [diaryStore.week, onChange, elementType]);

  return null;
});

export const TargetWeek = observer(function TargetWeek(
  props: CopyElementProps
): JSX.Element {
  const { onChange, elementType } = props;
  const diaryStore = useDiaryStore();
  const [targetWeek, setTargetWeek] = useState(
    getWeekStartString(diaryStore.week)
  );

  const handleChange = useCallback(
    (week: string) => {
      setTargetWeek(week);
      onChange(elementType, week);
    },
    [onChange, elementType]
  );

  return (
    <SelectTargetDays
      week={targetWeek}
      selectMode="week"
      onChange={handleChange}
    />
  );
});

const StyledSeasonSelectWrapper = styled.div`
  display: inline-block;
  width: 120px;
  margin-left: 20px;
  vertical-align: middle;
`;

const SeasonSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TargetSeason = observer(function TargetSeason(
  props: CopyElementProps
): JSX.Element {
  const { onChange, elementType } = props;
  const diaryStore = useDiaryStore();
  const seasonsStore = useRootStore().seasonsStore;
  const futureSeasons = useMemo(
    () =>
      diaryStore.currentSeason
        ? seasonsStore.getFutureSeasons(diaryStore.currentSeason)
        : [],
    [diaryStore.currentSeason, seasonsStore]
  );

  const options = useMemo(
    () =>
      futureSeasons.reverse().map((season: SeasonStore) => ({
        label: season.label,
        value: season.id
      })),
    [futureSeasons]
  );

  const firstSeason = options[0];
  const [targetSeason, setTargetSeason] = useState<number>(firstSeason?.value);

  useEffect(() => {
    if (firstSeason) {
      onChange(elementType, firstSeason.value);
    }
  }, [firstSeason, elementType, onChange]);

  const handleChange = useCallback(
    (season: { label: string; value: number }) => {
      setTargetSeason(season.value);
      onChange(elementType, season.value);
    },
    [onChange, elementType]
  );

  return options.length > 0 ? (
    <ContentBox>
      <SeasonSelector>
        <Text medium size={TextSize.s16}>
          <FormattedMessage id="plan.copy.selectTargetSeason" />
        </Text>
        <StyledSeasonSelectWrapper>
          <SelectBox
            id="target-season"
            options={options}
            value={options.find(o => o.value === targetSeason) ?? firstSeason}
            onChange={handleChange}
            disablePortal
          />
        </StyledSeasonSelectWrapper>
      </SeasonSelector>
    </ContentBox>
  ) : (
    <FormattedMessage id="attendance.noSeason" />
  );
});

export const SourceSeasonSelector = observer(function SourceSeasonSelector(
  props: CopyElementProps
): JSX.Element {
  const { onChange, elementType } = props;
  const seasonsStore = useRootStore().seasonsStore;
  const seasons = seasonsStore.seasons;

  const options = useMemo(
    () =>
      seasons.map((season: SeasonStore) => ({
        label: season.label,
        value: season.id
      })),
    [seasons]
  );

  const firstSeason = options[0];
  const [sourceSeason, setSourceSeason] = useState(firstSeason?.value);

  useEffect(() => {
    if (firstSeason) {
      onChange(elementType, firstSeason.value);
    }
  }, [firstSeason, elementType, onChange]);

  const handleChange = useCallback(
    (season: { label: string; value: number }) => {
      setSourceSeason(season.value);
      onChange(elementType, season.value);
    },
    [onChange, elementType]
  );

  return options.length > 0 ? (
    <ContentBox>
      <SeasonSelector>
        <Text medium size={TextSize.s14}>
          <FormattedMessage id="plan.copy.selectSourceSeason" />
        </Text>
        <StyledSeasonSelectWrapper>
          <SelectBox
            id="source-season"
            options={options}
            value={options.find(o => o.value === sourceSeason) ?? firstSeason}
            onChange={handleChange}
            disablePortal
          />
        </StyledSeasonSelectWrapper>
      </SeasonSelector>
    </ContentBox>
  ) : (
    <FormattedMessage id="plan.copy.noSeasons" />
  );
});

export const SourceSeason = observer(function SourceSeason(
  props: CopyElementProps
): null {
  const { onChange, elementType } = props;
  const diaryStore = useDiaryStore();

  useEffect(() => {
    const seasonId = diaryStore.currentSeason?.id;
    if (seasonId) {
      onChange(elementType, seasonId);
    }
  }, [diaryStore.currentSeason, onChange, elementType]);
  return null;
});

export const SourceSeasonSections = observer(function SourceSeasonSections(
  props: CopyElementProps
): null {
  const { onChange, elementType } = props;
  const diaryStore = useDiaryStore();

  useEffect(() => {
    const currentSeason = diaryStore.currentSeason;
    if (!currentSeason) {
      return;
    }
    const viewType = currentSeason.seasonViewType;

    if (
      (viewType === 'month' && elementType === 'sourceMonths') ||
      (viewType === 'cycle' && elementType === 'sourceCycles')
    ) {
      const sections = currentSeason.seasonSections;
      onChange(elementType, sections);
    }
  }, [diaryStore.currentSeason, onChange, elementType]);
  return null;
});
