import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { createCssBoxShadow } from '../alert-layer';
import { PropsWithChildren } from 'react';

export enum InteractionLayerSize {
  A = 'A',
  B = 'B',
  C = 'C'
}

export type InteractionLayerProps = PropsWithChildren<{
  /**
   * Defines the width of the layer
   */
  readonly size: InteractionLayerSize;
  /**
   * Indicates whether the layer instance is the currently active layer
   */
  readonly active: boolean;
}>;

const LayerSizes: { [size in InteractionLayerSize]: string | undefined } = {
  A: '50vw',
  B: '40vw',
  C: '30vw'
};

const animationClassName = 'animation';
const animationTimeout = 300;
const animationTimingFunction = 'ease';

const StyledBaseLayer = styled.div<InteractionLayerProps>`
  background-color: #ffffff;

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 90vw;
  transform: translateX(0);

  @media (min-width: 768px) {
    width: ${props => LayerSizes[props.size]};
  }

  ${createCssBoxShadow()}

  transition: transform ${animationTimeout}ms ${animationTimingFunction};

  &.${animationClassName}-exit {
    transform: translateX(0px);
  }

  &.${animationClassName}-enter,
    &.${animationClassName}-exit-active,
    &.${animationClassName}-exit-done {
    transform: translateX(100%);
  }

  // this must be separate from the animation-exit definition because the order in the CSS must be in a specific order
  // for the animation to work and some of the values for the animation are breakpoint specific.
  // * <name>-<state> => breakpoint specific for enter
  // * <name>-<state>-active => breakpoint specific for exit
  // * <name>-<state>-done (if needed) => breakpoint specific for exit
  &.${animationClassName}-enter-active {
    transform: translateX(0px);
  }
`;
StyledBaseLayer.displayName = 'StyledBaseLayer';

const UnanimatedInteractionLayer: React.FunctionComponent<
  InteractionLayerProps
> = props => {
  const { active, children, size } = props;

  return (
    <StyledBaseLayer
      role="dialog"
      aria-modal={active}
      size={size}
      active={active}
    >
      {children}
    </StyledBaseLayer>
  );
};

export interface InteractionLayerPropsWithAnimation
  extends InteractionLayerProps {
  /**
   * This is provided by the react-transition-group. No need to use it when instantiating the component.
   * Note: If you write a wrapper for this component, take care to pass these props through to ensure that the
   * animations still work
   */
  readonly in?: boolean;
  /**
   * This is provided by the react-transition-group. No need to use it when instantiating the component.
   * Note: If you write a wrapper for this component, take care to pass these props through to ensure that the
   * animations still work
   */
  onExited?(): void;
}

export function InteractionLayer(
  props: InteractionLayerPropsWithAnimation
): JSX.Element {
  const { active, children, in: visible = true, onExited, size } = props;

  return (
    <CSSTransition
      classNames={animationClassName}
      onExited={onExited}
      in={visible}
      timeout={animationTimeout}
    >
      <UnanimatedInteractionLayer active={active} size={size}>
        {children}
      </UnanimatedInteractionLayer>
    </CSSTransition>
  );
}
