import { FormattedMessage } from 'react-intl';
import { Tippy } from '../../components/tippy/tippy';
import { InputArrayAttributeStore } from '../mobx/input-array-attribute-store';
import { observer } from 'mobx-react-lite';
import { Input } from './input';
import {
  InputArrayInputsContainer,
  InputArrayLayout,
  TrainingDayAttributeLabel
} from '@yarmill/components';

export interface InputArrayAttributeProps {
  readonly attribute: InputArrayAttributeStore;
}

function InternalInputArray(
  props: InputArrayAttributeProps
): JSX.Element | null {
  const { attribute } = props;

  if (attribute.isHidden) {
    return null;
  }

  return (
    <InputArrayLayout colSize={attribute.size}>
      <Tippy tooltipContent={attribute.tooltip} Wrapper="div">
        <TrainingDayAttributeLabel htmlFor={attribute.htmlId}>
          <FormattedMessage id={attribute.name} />
        </TrainingDayAttributeLabel>
      </Tippy>
      <InputArrayInputsContainer colSize={attribute.size}>
        {attribute.inputs.map(
          inputStore => (
            <Input
              attribute={attribute}
              inputStore={inputStore}
              key={inputStore.index}
            />
          ),
          attribute.size
        )}
      </InputArrayInputsContainer>
    </InputArrayLayout>
  );
}

export const InputArrayAttribute = observer(InternalInputArray);
