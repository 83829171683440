import { toast } from '../components/toast-message';

export function showNewVersionAnnouncement() {
  toast(
    `announcement.version.text`,
    undefined,
    {
      link: window.location.href,
      a: content => <a href={window.location.href}>{content}</a>
    },
    {
      autoClose: false,
      position: 'top-center',
      onClose: () => {
        const url = window.location.href;
        window.location.href = url;
      }
    }
  );
}
