import { useCallback, useMemo } from 'react';
import { SelectBox } from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { FormFieldProps } from './form-field';
import { useIntl } from 'react-intl';
import { getFormFieldAttributes } from '../utils';
import { useErrorMessage, useInvalidFieldFocusHandler } from '../hooks';

export const Dropdown = observer(function Dropdown(
  props: FormFieldProps
): JSX.Element {
  const { attributeStore, autoFocus } = props;
  const intl = useIntl();
  const commonAttributes = getFormFieldAttributes(attributeStore, intl);
  const elementRef =
    useInvalidFieldFocusHandler<HTMLInputElement>(attributeStore);
  const error = useErrorMessage(attributeStore);

  const handleChange = useCallback(
    (option: { label: string; value: string }) => {
      attributeStore?.onChange(option?.value || '');
    },
    [attributeStore]
  );

  const options = useMemo(
    () =>
      attributeStore.definition.SourceData.map(item => ({
        label: intl.formatMessage({ id: item.Value }),
        value: item.Key
      })),
    [attributeStore.definition.SourceData, intl]
  );

  const selectedValue = useMemo(
    () => options.find(o => o.value === attributeStore.formValue),
    [options, attributeStore.formValue]
  );

  const isCondensedObject =
    attributeStore.objectDataStore.objectStore.definition.Features?.Condensed;

  return (
    <SelectBox
      {...commonAttributes}
      autoFocus={autoFocus}
      id={String(attributeStore.id)}
      onChange={handleChange}
      options={options}
      value={selectedValue}
      isClearable
      placeholder={intl.formatMessage({
        id: attributeStore.definition.SourceKey
      })}
      ref={elementRef}
      error={error}
      disablePortal
      noLabel={isCondensedObject && !commonAttributes.label}
      noError={isCondensedObject && !error}
    />
  );
});
