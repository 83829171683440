import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import zenScroll from 'zenscroll';

import {
  getTrainingDayElementId,
  getWeekEndString,
  getWeekStartString,
  ROUTE_DATE_FORMAT
} from './utils';
import { FocusedDayService } from './mobx/focused-day-service';
import { useRootStore } from '../app/root-store-context';
import { getGoalRowId } from '../goals/season-goal-row';
import { useWindowSize } from '../utils/use-window-size';
import { ExportDiaryActivitiesToPdfRequestParams } from '../export/api/export-diary-activities-to-pdf';
import { useDiaryStore } from './diary-store-context';
import { useIntlStore } from '../intl/hooks';
import { useIntl } from 'react-intl';
import { ExportAttributesDialog } from './export-attributes-dialog';
import { LayerPortal } from '../layer-manager/layer-portal';
import { useLayer } from '../layer-manager/hooks';
import moment from 'moment/moment';
export function useFocusedDayScroller(): MutableRefObject<HTMLDivElement | null> {
  const focusedDayService = useFocusedDayService();
  const focusedDayIndex = focusedDayService.focusedDay;
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollContainer = scrollContainerRef.current;
  const diaryStore = useDiaryStore();

  useEffect(() => {
    if (!scrollContainer) {
      return;
    }
    const weekSummary = diaryStore.trainingWeekStore?.weekSummary;

    const scroller = zenScroll.createScroller(
      scrollContainer,
      500,
      weekSummary?.isPinned ? 300 : 90
    );

    const element = document.getElementById(
      getTrainingDayElementId(focusedDayIndex)
    );
    if (element && scroller) {
      scroller.to(element);
    } else {
      scroller.toY(0);
    }
  }, [focusedDayIndex, scrollContainer, diaryStore]);

  return scrollContainerRef;
}

export function useFocusedDayService(): FocusedDayService {
  const rootStore = useRootStore();

  return rootStore.focusedDayService;
}

export function useFocusedCycleScroller(
  cyclesContentBox: RefObject<HTMLDivElement>,
  weeksContentBox: RefObject<HTMLDivElement>,
  daysContentBox: RefObject<HTMLDivElement>,
  focusedCycle: number,
  focusedWeek: number,
  scrollCycles: boolean,
  scrollWeeks: boolean,
  scrollDays: boolean
) {
  useEffect(() => {
    if (
      !weeksContentBox.current ||
      !daysContentBox.current ||
      !cyclesContentBox.current
    ) {
      return;
    }
    const cyclesScroller = zenScroll.createScroller(
      cyclesContentBox.current,
      500,
      20
    );
    const weeksScroller = zenScroll.createScroller(
      weeksContentBox.current,
      500,
      20
    );
    const daysScroller = zenScroll.createScroller(
      daysContentBox.current,
      500,
      20
    );

    if (focusedCycle !== -1) {
      const cycle = document.getElementById(
        getGoalRowId(focusedCycle, false, true)
      );
      const week = document.getElementById(
        getGoalRowId(focusedCycle * 4, true)
      );

      if (cycle && cyclesScroller && scrollCycles) {
        cyclesScroller.to(cycle);
      }

      if (week && weeksScroller && scrollWeeks) {
        weeksScroller.to(week);
      }
      if (daysScroller && scrollDays) {
        const dayIndex =
          focusedWeek === -1 ? focusedCycle * 28 : focusedWeek * 7;
        const day = document.getElementById(getGoalRowId(dayIndex));
        if (day) {
          daysScroller.to(day);
        }
      }
    }
  }, [
    cyclesContentBox,
    daysContentBox,
    focusedCycle,
    focusedWeek,
    scrollCycles,
    scrollDays,
    scrollWeeks,
    weeksContentBox
  ]);
}

export function useSeasonGoalsContentBoxMaxHeight(
  cyclesContentBox: RefObject<HTMLDivElement>
): string {
  const screenSize = useWindowSize();
  const [maxHeight, setMaxHeight] = useState<string>('auto');

  useEffect(() => {
    const contentBox = cyclesContentBox.current;
    if (contentBox) {
      const { top } = contentBox.getBoundingClientRect();
      setMaxHeight(`calc(100vh - ${top}px - 20px)`); //20px margin bottom
    }
  }, [screenSize, cyclesContentBox]);

  return maxHeight;
}

export function useAttributesPdfExport(): [() => Promise<void>, JSX.Element] {
  const layer = useLayer('alert', { showShim: true, closeOnShimClick: true });

  const dialog = (
    <LayerPortal
      layerHandle={layer}
      getContent={layer => <ExportAttributesDialog layer={layer} />}
    />
  );

  const handler = useCallback((): Promise<void> => {
    if (!layer.isOpened) {
      layer.open();
    }

    return Promise.resolve();
  }, [layer]);

  return [handler, dialog];
}

export function useActivitiesToPdfExportHandler(): () => Promise<void> {
  const diaryStore = useDiaryStore();
  const intlStore = useIntlStore();
  const rootStore = useRootStore();
  const intl = useIntl();

  return useCallback(async () => {
    const name = diaryStore.athleteId
      ? rootStore.usersStore.getUserById(diaryStore.athleteId)?.displayName
      : rootStore.groupsStore.getGroupById(diaryStore.groupId)?.name;
    const startDate = getWeekStartString(diaryStore.week);
    const endDate = getWeekEndString(diaryStore.week);
    const formatDate = (date: string) => moment(date).format(ROUTE_DATE_FORMAT);
    const fileName = `${name} - ${formatDate(startDate)} - ${formatDate(
      endDate
    )} - ${intl.formatMessage({ id: `export.info.${diaryStore.diaryType}` })}`;
    const params: ExportDiaryActivitiesToPdfRequestParams = {
      startDate,
      endDate,
      state: diaryStore.diaryType === 'plan' ? 'P' : 'R',
      userId: diaryStore.athleteId || undefined,
      userGroupId: diaryStore.athleteId
        ? undefined
        : diaryStore.groupId || undefined,
      language: intlStore.locale,
      orientation: 'portrait'
    };
    await rootStore.exportService.exportDiaryActivitiesToPdf(params, fileName);
  }, [diaryStore, intlStore, rootStore, intl]);
}
