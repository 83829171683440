import { AsyncToastMessage } from '@yarmill/components';

import {
  toast as reactToastify,
  toast,
  Id as ToastId,
  ToastOptions
} from 'react-toastify';
import { AsyncStatus } from '../api/mobx/request-store';
import { IntlShape, useIntl } from 'react-intl';

type GetTranslatedText = (intl: IntlShape) => string;

function getTitle(state: AsyncStatus): string {
  switch (state) {
    case AsyncStatus.resolved:
      return 'toast.success.googleCalendarSubscribe.title';
    case AsyncStatus.rejected:
      return 'toast.error.googleCalendarSubscribe.title';
    default:
      return 'toast.progress.googleCalendarSubscribe.title';
  }
}

export interface GoogleCalendarToastMessageProps {
  getText: GetTranslatedText;
  status: AsyncStatus;
}

export function GoogleCalendarToastMessage(
  props: GoogleCalendarToastMessageProps
): JSX.Element {
  const { getText, status } = props;
  const title = getTitle(status);
  const intl = useIntl();

  return (
    <AsyncToastMessage
      title={intl.formatMessage({ id: title })}
      description={getText(intl)}
      status={status}
    />
  );
}

const showOptions: ToastOptions = {
  autoClose: false,
  closeOnClick: true,
  position: reactToastify.POSITION.BOTTOM_RIGHT,
  className: 'ytd-toast',
  hideProgressBar: true,
  closeButton: false,
  icon: false
};

const successGoogleCalendarOptions: ToastOptions = {
  ...showOptions,
  autoClose: 5000,
  className: 'ytd-toast success'
};

const errorGoogleCalendarOptions: ToastOptions = {
  ...showOptions,
  autoClose: 5000,
  className: 'ytd-toast error'
};

export const showGoogleCalendarToastMessage = (
  getText: GetTranslatedText,
  cancelRequest: () => void
): ToastId =>
  toast(
    <GoogleCalendarToastMessage
      getText={getText}
      status={AsyncStatus.pending}
    />,
    {
      ...showOptions,
      onClose: cancelRequest
    }
  );

export const showGoogleCalendarSuccessToastMessage = (
  id: ToastId,
  getText: GetTranslatedText
) =>
  toast.update(id, {
    ...successGoogleCalendarOptions,
    render: (
      <GoogleCalendarToastMessage
        getText={getText}
        status={AsyncStatus.resolved}
      />
    )
  });

export const showGoogleCalendarErrorToastMessage = (
  id: ToastId,
  getText: GetTranslatedText
) =>
  toast.update(id, {
    ...errorGoogleCalendarOptions,
    render: (
      <GoogleCalendarToastMessage
        getText={getText}
        status={AsyncStatus.rejected}
      />
    )
  });
