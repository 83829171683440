import { AsyncToastMessage } from '@yarmill/components';

import {
  toast as reactToastify,
  toast,
  Id as ToastId,
  ToastOptions
} from 'react-toastify';
import { useIntl } from 'react-intl';
import { AsyncStatus } from '../../api/mobx/request-store';

interface GenericAsyncToastMessageProps {
  message: string;
  status: AsyncStatus;
}

function GenericAsyncToastMessage(
  props: GenericAsyncToastMessageProps
): JSX.Element {
  const { message, status } = props;
  const intl = useIntl();

  return (
    <AsyncToastMessage
      title={intl.formatMessage({ id: message })}
      status={status}
    />
  );
}

const showOptions: ToastOptions = {
  autoClose: false,
  closeOnClick: true,
  position: reactToastify.POSITION.BOTTOM_RIGHT,
  className: 'ytd-toast',
  icon: false,
  hideProgressBar: true,
  closeButton: false
};

const successGenericAsyncToastOptions: ToastOptions = {
  ...showOptions,
  autoClose: 5000,
  className: 'ytd-toast success'
};

const errorGenericAsyncToastOptions: ToastOptions = {
  ...showOptions,
  autoClose: 5000,
  className: 'ytd-toast error'
};

export const showGenericAsyncToastMessage = (
  message: string,
  cancelGenericAsyncToast: () => void
): ToastId =>
  toast(
    <GenericAsyncToastMessage message={message} status={AsyncStatus.pending} />,
    {
      ...showOptions,
      onClose: cancelGenericAsyncToast
    }
  );

export const showGenericAsyncSuccessToastMessage = (
  id: ToastId,
  message: string
) =>
  toast.update(id, {
    ...successGenericAsyncToastOptions,
    render: (
      <GenericAsyncToastMessage
        message={message}
        status={AsyncStatus.resolved}
      />
    )
  });

export const showGenericAsyncErrorToastMessage = (
  id: ToastId,
  message: string
) => {
  const toastContent = (
    <GenericAsyncToastMessage message={message} status={AsyncStatus.rejected} />
  );

  if (!id) {
    toast(toastContent, errorGenericAsyncToastOptions);
  } else {
    toast.update(id, {
      ...errorGenericAsyncToastOptions,
      render: toastContent
    });
  }
};
