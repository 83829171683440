import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { GroupsHeader } from './header';
// import { GroupsSidebar } from './Sidebar';
import { GroupsToolbar } from './toolsbar';
import { UsersInGroupList } from './users-in-group-list';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import { useConfig } from '../app/hooks';
import { observer } from 'mobx-react-lite';
import { useGroupsStore } from './hooks';
import { Page } from '../page/page';
import { Sidebar } from '../settings/sidebar';
import {
  ContentBox,
  PageMainContent,
  PageScrollContainer,
  Text,
  TextSize
} from '@yarmill/components';
import { SecondarySidebar } from './secondary-sidebar';

export interface GroupRouteProps {
  groupId?: string;
}

function Groups(props: RouteComponentProps<GroupRouteProps>): JSX.Element {
  const { match } = props;
  const { params } = match;
  const groupId = params.groupId ? Number(params.groupId) : undefined;
  const adminName = useConfig('adminName');
  const adminEmail = useConfig('adminEmail');
  const noGroups = useGroupsStore().sortedGroups.length === 0;

  return (
    <Page doubleSidebar>
      <Sidebar activeView="groups" />
      <SecondarySidebar currentGroupId={groupId} />
      <PageMainContent>
        <PageScrollContainer>
          {groupId !== undefined && <GroupsHeader groupId={groupId} />}
          <ContentBox>
            {groupId === undefined ? (
              noGroups ? (
                <Text size={TextSize.s14}>
                  <FormattedHTMLMessage
                    id="noGroup.noGroupMessage"
                    values={{ adminName, adminEmail }}
                  />
                </Text>
              ) : (
                <Text size={TextSize.s14}>
                  <FormattedMessage id="settings.groups.selectGroup" />
                </Text>
              )
            ) : (
              <Fragment>
                <GroupsToolbar groupId={groupId} />
                <UsersInGroupList groupId={groupId} key={groupId} />
              </Fragment>
            )}
          </ContentBox>
        </PageScrollContainer>
      </PageMainContent>
    </Page>
  );
}

const WithRouter = withRouter(observer(Groups));
export { WithRouter as Groups };
