import { RootStore } from '../../app/mobx/root-store';
import {
  action,
  computed,
  IObservableArray,
  IReactionDisposer,
  makeObservable,
  observable,
  ObservableMap,
  reaction,
  runInAction,
  when
} from 'mobx';
import {
  ATHLETE_SEARCH_PARAM,
  GROUP_SEARCH_PARAM,
  WEEK_SEARCH_PARAM
} from '../../routes/types';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import { ObjectiveStore } from './objective-store';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import {
  ObjectiveActivityLogTab,
  ObjectivePreview,
  ObjectiveRightPanelType
} from '../types';
import {
  getObjectivesForUser,
  GetObjectivesForUserResponse
} from '../api/get-objectives-for-user';
import { UserStore } from '../../users/mobx/user-store';
import { GroupStore } from '../../groups/mobx/group-store';
import { SeasonStore } from '../../seasons/mobx/season-store';
import { putObjective } from '../api/put-objective';
import { deleteObjective } from '../api/delete-objective';
import {
  getObjectivesForGroup,
  GetObjectivesForGroupResponse
} from '../api/get-objectives-for-group';
import { createObjectiveStore } from '../utils';

export class OkrsStore {
  private readonly _rootStore: RootStore;

  @observable
  public athleteId: UserId | null = null;

  @observable
  public groupId: UserGroupId | null = null;

  @observable
  private _objectives: IObservableArray<ObjectiveStore> = observable.array();

  @observable
  private _objectivesPerUser: ObservableMap<
    UserId,
    IObservableArray<ObjectivePreview>
  > = observable.map();

  @observable
  public week: string = '';

  @observable
  private _currentSeason: SeasonStore | undefined = undefined;

  @observable
  private request: RequestStore<
    GetObjectivesForUserResponse | GetObjectivesForGroupResponse
  > | null = null;

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  @observable
  private _visibleSidePanel: ObjectiveRightPanelType = null;

  @observable
  private _activityLogTab: ObjectiveActivityLogTab = 'comments';

  private reactions: IReactionDisposer[] = [];
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this._rootStore = rootStore;
    when(
      () => rootStore.isReady,
      () => this.registerReactions()
    );
  }

  private registerReactions(): void {
    const observeGroupId = reaction(
      () => this._rootStore.historyService.searchParams.get(GROUP_SEARCH_PARAM),
      id => {
        this.groupId = id !== undefined ? Number(id) : null;
        this._objectives.clear();
        this._objectivesPerUser.clear();
      },
      {
        fireImmediately: true
      }
    );

    const observerAthleteId = reaction(
      () =>
        this._rootStore.historyService.searchParams.get(ATHLETE_SEARCH_PARAM),
      id => {
        this.athleteId = id !== undefined ? Number(id) : null;
        this._objectives.clear();
        this._objectivesPerUser.clear();
      },
      {
        fireImmediately: true
      }
    );

    const observeWeek = reaction(
      () => this._rootStore.historyService.searchParams.get(WEEK_SEARCH_PARAM),
      week => {
        this.week = week || '';
        this._currentSeason = this._rootStore.seasonsStore.getSeasonByWeek(
          this.week
        );
        this._objectives.clear();
        this._objectivesPerUser.clear();
      },
      {
        fireImmediately: true
      }
    );

    const dataLoader = reaction(
      () => ({
        athleteId: this.athleteId,
        groupId: this.groupId,
        week: this.week
      }),
      () => {
        this.loadObjectives();
      },
      {
        fireImmediately: true
      }
    );

    this.reactions.push(
      observerAthleteId,
      observeGroupId,
      observeWeek,
      dataLoader
    );
  }

  public disposeReactions(): void {
    this.reactions.forEach(dispose => dispose());
  }

  @computed
  public get athlete(): UserStore | undefined {
    return this._rootStore.usersStore.getUserById(this.athleteId);
  }

  @computed
  public get group(): GroupStore | undefined {
    return this._rootStore.groupsStore.getGroupById(this.groupId);
  }

  public get currentSeason(): SeasonStore | undefined {
    return this._currentSeason;
  }

  public get visibleSidePanel(): ObjectiveRightPanelType {
    return this._visibleSidePanel;
  }

  public get activityLogTab(): ObjectiveActivityLogTab {
    return this._activityLogTab;
  }

  private async loadObjectives(): Promise<void> {
    this._status = AsyncStatus.pending;
    if (this.request) {
      this.request.cancel();
      this._rootStore.requestsStore.removeRequest(this.request);
      this.request = null;
    }

    if (this.athleteId) {
      this.loadObjectivesForUser();
    } else {
      this.loadObjectivesForGroup();
    }
  }

  private async loadObjectivesForUser(): Promise<void> {
    const athleteId = this.athleteId;
    const seasonId = this.currentSeason?.id;

    if (!athleteId || !seasonId) {
      return;
    }

    const request = (this.request = this._rootStore.requestsStore.createRequest(
      cancelToken =>
        getObjectivesForUser(
          {
            userId: athleteId || undefined,
            seasonId
          },
          cancelToken
        )
    ));

    const response = await request.getResponse();

    if (response) {
      runInAction(() => {
        this._objectives.clear();
        this._objectives.push(
          ...response.map(o =>
            createObjectiveStore(o, this._rootStore.usersStore)
          )
        );
        this._status = AsyncStatus.resolved;
      });
    } else {
      this._status = AsyncStatus.rejected;
    }
  }
  private async loadObjectivesForGroup(): Promise<void> {
    const groupId = this.groupId;
    const seasonId = this.currentSeason?.id;

    if (!groupId || !seasonId) {
      return;
    }

    const request = (this.request = this._rootStore.requestsStore.createRequest(
      cancelToken =>
        getObjectivesForGroup(
          {
            userGroupId: groupId,
            seasonId
          },
          cancelToken
        )
    ));

    const response = await request.getResponse();

    if (response) {
      runInAction(() => {
        this._objectivesPerUser.clear();
        response.forEach(data => {
          this._objectivesPerUser.set(
            data.UserId,
            observable.array(data.Objectives)
          );
        });

        this._status = AsyncStatus.resolved;
      });
    } else {
      this._status = AsyncStatus.rejected;
    }
  }

  @computed
  public get objectives(): Readonly<Array<ObjectiveStore>> {
    return this._objectives;
  }

  @computed
  public get objectivesPerUser() {
    return this._objectivesPerUser;
  }

  @computed
  public get isReady(): boolean {
    return this._status === AsyncStatus.resolved;
  }

  public getObjective(id: number): ObjectiveStore | undefined {
    return this._objectives.find(o => o.id === id);
  }

  public addObjective(objective: ObjectiveStore): this {
    this._objectives.push(objective);
    return this;
  }

  public setVisibleRightPanel(panel: ObjectiveRightPanelType) {
    this._visibleSidePanel = panel;
  }

  public setActivityLogTab(tab: ObjectiveActivityLogTab) {
    this._activityLogTab = tab;
  }

  public isCurrentUserAllowedToWrite(objective?: ObjectiveStore): boolean {
    const currentUser = this._rootStore.currentUserStore;

    return (
      currentUser.isAllowedTo('okr.write') &&
      (Boolean(
        objective?.supervisors.find(
          supervisor => supervisor.id === currentUser.id
        )
      ) ||
        currentUser.hasWritePermission(this.groupId, this.athleteId))
    );
  }

  public async removeObjective(objective: ObjectiveStore): Promise<void> {
    this._objectives.remove(objective);
    const objectiveId = objective.id;
    const userId = this.athleteId;
    const userGroupId = this.groupId;

    if (!objectiveId) {
      return;
    }

    const request = this._rootStore.requestsStore.createRequest(cancelToken =>
      deleteObjective({ objectiveId, userId, userGroupId }, cancelToken)
    );

    await request.getResponse();
  }

  @action
  public async saveObjective(
    objective: ObjectiveStore,
    saveAttempt = 1
  ): Promise<void> {
    const { id, ...data } = objective.toJS();
    const objectiveData = id === null ? data : { id, ...data };
    const userId = this.athleteId;
    const seasonId = this.currentSeason?.id;

    if (!userId || !seasonId) {
      return;
    }

    const request = this._rootStore.requestsStore.createRequest(cancelToken =>
      putObjective({ userId, seasonId, objective: objectiveData }, cancelToken)
    );

    const response = await request.getResponse();
    if (request.statusCode === 200 && response) {
      objective.setId(response.id);
      objective.setLastUpdateDate(response.lastUpdated);
      objective.setActivities(response.activities ?? []);
      response.keyResults.forEach(keyResult => {
        objective
          .getKeyResult(keyResult.uid)
          ?.setId(keyResult.id)
          .setLastUpdateDate(keyResult.lastUpdated);
      });
      objective.sortKeyResults();
      objective.hasPendingUpdate = false;
      objective.saveFailed = false;
    } else {
      objective.saveFailed = true;

      if (saveAttempt < 5) {
        setTimeout(() => {
          this.saveObjective(objective, saveAttempt + 1);
        }, saveAttempt * 2000);
      }
    }
  }
}
