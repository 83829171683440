import { ChartReportDataItem, DataDefinitionV2, ReportItem } from '../types';
import { XyChartStore } from './xy-chart-store';
import { computed, makeObservable } from 'mobx';
import {
  AxisValue,
  BarChart,
  LineChart,
  MultiLineChart,
  TooltipData,
  TooltipValue
} from '@yarmill/components';
import { createLabelFormatter } from '../utils';
import { BaseReportStore } from './base-report-store';
import { ReactNode } from 'react';

export class ColumnLineChartStore extends BaseReportStore {
  private readonly _xyChartStore: XyChartStore;
  private readonly _isMultiLine: boolean;

  constructor(item: ReportItem, pageCode: string) {
    super(item);
    makeObservable(this);
    this._xyChartStore = new XyChartStore(item, pageCode);
    this._isMultiLine = Boolean(this._xyChartStore.category);
  }

  public get xy(): XyChartStore {
    return this._xyChartStore;
  }

  @computed
  public get chartElementsConfig(): (BarChart | LineChart | MultiLineChart)[] {
    return this.xy.dataColumns.map(def => {
      switch (def.VisualizationType) {
        case 'bar':
          return this.getBarConfig(def);
        case 'line':
          return this.getLineConfig(def);
        case 'multi-line':
        default:
          return this.getMultiLineConfig(def);
      }
    });
  }

  public get aspectRatio(): number | undefined {
    return this._item.Params?.AspectRatio;
  }

  public get backgroundImage(): string | undefined {
    return this._item.Params?.BackgroundImage;
  }

  private getBarConfig(def: DataDefinitionV2): BarChart {
    return {
      ...this.xy.baseConfig,
      type: 'Bar',
      code: def.Name,
      formatLabelValue: createLabelFormatter(def.BusinessFormat, def.Format),
      getYValue: (item: ChartReportDataItem) => item[def.Name] as number,
      getColor: (item: ChartReportDataItem) => this.xy.getColor(item, def.Name),
      getLabelColor: (item: ChartReportDataItem) =>
        this.xy.getLabelColor(item, def.Name),
      getShowLabels: (item: ChartReportDataItem): boolean =>
        this.xy.getShowLabels(item, def.Name),
      getBarWidth: (item: ChartReportDataItem): number =>
        this.xy.getWidth(item, def.Name),
      getOpacity: (item: ChartReportDataItem): number | undefined =>
        this.xy.getOpacity(item, def.Name),
      getLabelAngle: (item: ChartReportDataItem): number =>
        this.xy.getLabelAngle(item, def.Name)
    };
  }

  private getLineConfig(def: DataDefinitionV2): LineChart {
    return {
      ...this.xy.baseConfig,
      type: 'Line',
      code: def.Name,
      formatLabelValue: createLabelFormatter(def.BusinessFormat, def.Format),
      getCurveType: item => this.xy.getCurveType(item, def.Name),
      getYValue: item => item[def.Name] as number,
      getLineStyle: item => this.xy.getLineStyle(item, def.Name),
      getColor: item => this.xy.getColor(item, def.Name),
      getLabelColor: item => this.xy.getLabelColor(item, def.Name),
      getStrokeWidth: item => this.xy.getStrokeWidth(item, def),
      getOpacity: item => this.xy.getOpacity(item, def.Name),
      getStrokeDasharray: item => this.xy.getStrokeDasharray(item, def),
      getMarkerSize: item => this.xy.getMarkerSize(item, def.Name),
      getShowLabels: (item: ChartReportDataItem): boolean =>
        this.xy.getShowLabels(item, def.Name),
      getMarkerStrokeWidth: (item: ChartReportDataItem) =>
        this.xy.getMarkerStrokeWidth(item, def),
      getMarkerStrokeColor: (item: ChartReportDataItem) =>
        this.xy.getMarkerStrokeColor(item, def)
    };
  }

  private getMultiLineConfig(def: DataDefinitionV2): MultiLineChart {
    return {
      ...this.xy.baseConfig,
      type: 'MultiLine',
      code: def.Name,
      formatLabelValue: createLabelFormatter(def.BusinessFormat, def.Format),
      getYValue: item => item[def.Name] as number,
      getLineStyle: item => this.xy.getLineStyle(item, def.Name),
      getCurveType: item => this.xy.getCurveType(item, def.Name),
      getStrokeWidth: item => this.xy.getStrokeWidth(item, def),
      getOpacity: (item, key) => this.xy.getOpacity(item, key),
      getStrokeDasharray: item => this.xy.getStrokeDasharray(item, def),
      categoryKey: this.xy.category?.Name ?? '',
      getShowLabels: (item: ChartReportDataItem, key): boolean =>
        this.xy.getShowLabels(item, key),
      getLabelColor: (item: ChartReportDataItem) =>
        this.xy.getLabelColor(item, def.Name),
      getLineOrder: (item: ChartReportDataItem) => this.xy.getLineOrder(item),
      getMarkerStrokeWidth: (item: ChartReportDataItem) =>
        this.xy.getMarkerStrokeWidth(item, def),
      getMarkerStrokeColor: (item: ChartReportDataItem) =>
        this.xy.getMarkerStrokeColor(item, def),
      getMarkerSize: item => this.xy.getMarkerSize(item, def.Name)
    };
  }

  public readonly getTooltipData = (
    xValue: AxisValue,
    data: ChartReportDataItem[]
  ): TooltipData => {
    const [values, label] = this._isMultiLine
      ? this.getMultiLineTooltipData(xValue, data)
      : this.getCombinedTooltipData(xValue, data);

    return {
      key: String(xValue),
      values,
      showLabel: this.xy.showAxisXLabelsInTooltip,
      label,
      ...this.xy.tooltipTableLayoutConfig
    };
  };

  private getCombinedTooltipData(
    xValue: AxisValue,
    data: ChartReportDataItem[]
  ): [TooltipValue[], ReactNode] {
    const item = data.find(d => this.xy.getXValue(d) === xValue);
    return [
      item ? this.xy.mapTooltipValues(item, 'x') : [],
      this.xy.formatTooltipLabel(xValue, 'x', item)
    ];
  }

  private getMultiLineTooltipData(
    xValue: AxisValue,
    data: ChartReportDataItem[]
  ): [TooltipValue[], ReactNode] {
    const items = data.filter(d => this.xy.getXValue(d) === xValue);
    return [
      this.xy.mapMultiLineTooltipValues(items, 'x'),
      this.xy.formatTooltipLabel(xValue, 'x', items[0])
    ];
  }
}
