import { Icon, IconSize, styled } from '@yarmill/components';
import { RadioChecked, RadioEmpty } from '@yarmill/icons-2';
import { MouseEvent } from 'react';
import { getAppearanceColors, TextInputAppearance } from './text-input';

interface RadioProps {
  readonly isChecked?: boolean;
  readonly hideInput?: boolean;
  readonly onClick?: (e: MouseEvent) => void;
  readonly appearance?: TextInputAppearance;
  readonly disabled?: boolean;
}

const StyledInput = styled.input`
  position: absolute;
  left: -200vw;
`;

const RadioIcon = styled(Icon)<{
  readonly disabled?: boolean;
  readonly $appearance: TextInputAppearance;
}>`
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  color: ${({ theme, $appearance }) =>
    getAppearanceColors($appearance, theme).base};
`;
export function Radio({
  isChecked,
  onClick,
  hideInput,
  appearance = 'navy',
  disabled
}: RadioProps): JSX.Element {
  return (
    <RadioIcon size={IconSize.s16} disabled={disabled} $appearance={appearance}>
      {isChecked ? <RadioChecked /> : <RadioEmpty />}
      {!hideInput && (
        <StyledInput
          type="checkbox"
          checked={isChecked}
          onClick={onClick}
          readOnly={!onClick}
        />
      )}
    </RadioIcon>
  );
}
