import { Icon, IconSize, styled, ThemeDefinition } from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import { Priority1, Priority2, Priority3 } from '@yarmill/icons-2';
import { TextInputProps } from './text-input';
import { Text } from './text';

interface PriorityProps {
  readonly priority?: 'low' | 'medium' | 'high';
  readonly muted?: boolean;
  readonly hideText?: boolean;
  readonly variant?: 'compact' | 'default';
}

export function getAppearanceForPriority(
  priority: PriorityProps['priority'] | undefined
): TextInputProps['appearance'] {
  switch (priority) {
    case 'low':
      return 'neutral';
    case 'high':
      return 'red';
    case 'medium':
      return 'navy';
    default:
      return 'neutral';
  }
}
function getColorForPriority(
  priority: PriorityProps['priority'] | undefined,
  colors: ThemeDefinition['color']
): string {
  switch (priority) {
    case 'low':
      return colors.neutral;
    case 'high':
      return colors.orangine;
    case 'medium':
      return colors.navy;
    default:
      return colors.navy;
  }
}

function getIconForPriority(priority: PriorityProps['priority']): JSX.Element {
  switch (priority) {
    case 'low':
      return <Priority1 />;
    case 'high':
      return <Priority3 />;
    case 'medium':
    default:
      return <Priority2 />;
  }
}

const PriorityLayout = styled.div<PriorityProps>`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.size.x05};
  color: ${({ theme, priority }) => getColorForPriority(priority, theme.color)};
`;

const PriorityText = styled(Text)<{ readonly variant?: 'compact' | 'default' }>`
  font-size: ${({ variant }) => (variant === 'compact' ? '10px' : '13px')};
  line-height: ${({ variant }) => (variant === 'compact' ? '12px' : '16px')};
  text-transform: uppercase;
  font-weight: 700;
`;
export function Priority({
  priority,
  muted,
  hideText,
  variant
}: PriorityProps): JSX.Element {
  return (
    <PriorityLayout priority={muted ? undefined : priority}>
      <Icon size={IconSize.s16}>{getIconForPriority(priority)}</Icon>
      {!hideText && (
        <PriorityText inheritColor variant={variant}>
          <FormattedMessage id={`okrs.priority.${priority}`} />
        </PriorityText>
      )}
    </PriorityLayout>
  );
}

interface PriorityIconProps {
  readonly priority: PriorityProps['priority'];
}
const StyledPriorityIcon = styled(Icon)<{
  readonly $priority: PriorityProps['priority'];
}>`
  width: ${({ theme }) => theme.size.x3};
  height: ${({ theme }) => theme.size.x3};
  color: ${({ theme, $priority }) =>
    getColorForPriority($priority, theme.color)};
  flex-shrink: 0;
`;

export function PriorityIcon({ priority }: PriorityIconProps) {
  return (
    <StyledPriorityIcon size={IconSize.s24} $priority={priority}>
      {priority === 'low' ? <span /> : getIconForPriority(priority)}
    </StyledPriorityIcon>
  );
}
