import {
  createContext,
  Dispatch,
  KeyboardEvent,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';

interface KeyboardListNavigationContextValue {
  readonly activeItem: number | null;
  readonly disableMouseMove: boolean;
  readonly register: (item: HTMLElement) => void;
  readonly unregister: (item: HTMLElement) => void;
  readonly reset: () => void;
  readonly getListProps: () => Record<string, unknown>;
  readonly setActiveItem: Dispatch<SetStateAction<number | null>>;
  readonly items: Map<HTMLElement, number | null>;
}

function sortByDocumentPosition(a: Node, b: Node) {
  const position = a.compareDocumentPosition(b);

  if (
    position & Node.DOCUMENT_POSITION_FOLLOWING ||
    position & Node.DOCUMENT_POSITION_CONTAINED_BY
  ) {
    return -1;
  }

  if (
    position & Node.DOCUMENT_POSITION_PRECEDING ||
    position & Node.DOCUMENT_POSITION_CONTAINS
  ) {
    return 1;
  }

  return 0;
}

function areMapsEqual(
  map1: Map<Node, number | null>,
  map2: Map<Node, number | null>
) {
  if (map1.size !== map2.size) {
    return false;
  }
  for (const [key, value] of map1.entries()) {
    if (value !== map2.get(key)) {
      return false;
    }
  }
  return true;
}
export const KeyboardListNavigationContext =
  createContext<KeyboardListNavigationContextValue | null>(null);

export function useKeyboardListNavigation(
  onEsc?: () => void
): KeyboardListNavigationContextValue {
  const [activeItem, setActiveItem] = useState<number | null>(null);
  const [items, setItems] = useState(
    () => new Map<HTMLElement, number | null>()
  );

  const [disableMouseMove, setDisableMouseMove] = useState<boolean>(true);

  const register = useCallback((item: HTMLElement) => {
    setItems(prevItems => new Map(prevItems).set(item, null));
  }, []);

  const unregister = useCallback((item: HTMLElement) => {
    setItems(prevItems => {
      const map = new Map(prevItems);
      map.delete(item);
      return map;
    });
  }, []);

  const reset = useCallback(() => {
    setActiveItem(null);
  }, []);

  const getListProps = useCallback(
    () => ({
      onKeyDown: (e: KeyboardEvent) => {
        if (e.key === 'ArrowDown') {
          setDisableMouseMove(true);
          e.preventDefault();
          setActiveItem(idx =>
            idx === null ? 0 : Math.min(idx + 1, items.size - 1)
          );
        } else if (e.key === 'ArrowUp') {
          setDisableMouseMove(true);
          e.preventDefault();
          setActiveItem(idx =>
            idx === null ? items.size - 1 : Math.max(0, idx - 1)
          );
        } else if (e.key === 'Enter') {
          e.preventDefault();
          if (activeItem === null) {
            return;
          }
          const [result] =
            Array.from(items.entries()).find(
              ([_, index]) => index === activeItem
            ) ?? [];

          if (result) {
            result.click();
          }
        }
        if (onEsc && e.key === 'Escape') {
          e.preventDefault();
          onEsc();
        }
      },
      onMouseOut: () => {
        if (disableMouseMove) {
          return;
        }
        setActiveItem(null);
      },
      onMouseMove: () => {
        setDisableMouseMove(false);
      }
    }),
    [items, activeItem, disableMouseMove, onEsc]
  );

  useLayoutEffect(() => {
    const newMap = new Map(items);
    const nodes = Array.from(newMap.keys()).sort(sortByDocumentPosition);

    nodes.forEach((node, index) => {
      newMap.set(node, index);
    });

    if (!areMapsEqual(items, newMap)) {
      setItems(newMap);
    }
  }, [items]);

  return useMemo(
    () => ({
      activeItem,
      disableMouseMove,
      getListProps,
      register,
      reset,
      setActiveItem,
      unregister,
      items
    }),
    [
      activeItem,
      register,
      unregister,
      getListProps,
      disableMouseMove,
      reset,
      items
    ]
  );
}

export function useKeyboardListNavigationItem(
  isSelected?: boolean
): [(ref: HTMLElement | null) => void, boolean, MouseEventHandler, boolean] {
  const itemRef = useRef<HTMLElement | null>(null);
  const [index, setIndex] = useState<number | null>(null);
  const context = useContext(KeyboardListNavigationContext);
  if (!context) {
    throw new Error('keyboard list navigation context not available');
  }
  const {
    activeItem,
    disableMouseMove,
    register,
    unregister,
    setActiveItem,
    items
  } = context;

  const isActive = activeItem !== null && activeItem === index;

  const onMouseEnter = useCallback(() => {
    if (disableMouseMove) {
      return;
    }
    if (typeof index === 'number') {
      setActiveItem(index);
    }
  }, [index, setActiveItem, disableMouseMove]);

  useLayoutEffect(() => {
    const item = itemRef.current;
    if (item) {
      register(item);
    }

    return () => {
      if (item) {
        unregister(item);
      }
    };
  }, [register, unregister]);

  useLayoutEffect(() => {
    const index = itemRef.current ? items.get(itemRef.current) : null;
    if (index != null) {
      setIndex(index);
    }
  }, [items]);

  const setRef = useCallback((element: HTMLElement | null) => {
    itemRef.current = element;
  }, []);

  useLayoutEffect(() => {
    if (isSelected && index) {
      setActiveItem(index);
    }
  }, [isSelected, index, setActiveItem]);

  useEffect(() => {
    if (isActive && itemRef.current) {
      itemRef.current.scrollIntoView({ block: 'nearest' });
    }
  }, [isActive]);

  return [setRef, isActive, onMouseEnter, disableMouseMove];
}
