import { MaskCode } from '../activities/types';
import { PersonalizedSettingsConfiguration } from '../settings/types';

export type UserGroupId = number;

export interface Group {
  Athletes: number[];
  AvailableMasks: MaskCode[];
  Coaches: number[];
  DefaultMaskCode: MaskCode;
  HistoricalAthletesCount: number;
  HistoricalMembersCount: number;
  IsEditable: boolean;
  IsVisible: boolean;
  Name: string;
  OwnerId: number;
  SortCode: number;
  UserGroupId: UserGroupId;
  ValidityDates: UserInGroupValidity[];
  Settings: PersonalizedSettingsConfiguration;
}

export interface UserInGroupValidity {
  UserId: number;
  ValidFrom: string;
  ValidTo: string | null;
}

export enum groupCookies {
  GROUP = 'yarmill-selected-group'
}
