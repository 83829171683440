import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';

export * from './content';

export interface AlertLayerProps {
  readonly wide?: boolean;
  readonly autoSize?: boolean;
  readonly transparent?: boolean;
  readonly center?: boolean;
  /**
   * Indicates whether the layer instance is the currently active layer
   */
  readonly active: boolean;

  /**
   * This is provided by the react-transition-group. No need to use it when instantiating the component.
   * Note: If you write a wrapper for this component, take care to pass these props through to ensure that the
   * animations still work
   */
  readonly in?: boolean;
  /**
   * This is provided by the react-transition-group. No need to use it when instantiating the component.
   * Note: If you write a wrapper for this component, take care to pass these props through to ensure that the
   * animations still work
   */
  onExited?(): void;

  children?: React.ReactNode;
}

/**
 * Create a box shadow depending on a shadow color
 *
 * @param themeShadowColor The color of the drop shadow as provided by the current theme
 */
// intentionally used hard coded px values here since they aren't theme-related and probably don't need a reusable variable
export function createCssBoxShadow(): string {
  return `box-shadow: 0 8px 32px 0 #4a4a4a, 0 16px 48px 0 #c2c2c2;`;
}

interface StyledLayerProps {
  wide?: boolean;
  autoSize?: boolean;
  transparent?: boolean;
  center?: boolean;
}

const animationClassName = 'animation';
const animationTimeout = 300;
const animationTimingFunction = 'ease';

const baseTransform = css<{ center?: boolean }>`translate(-50%, ${({
  center
}) => (center ? '-50%' : '-30%')});`;

const StyledLayer = styled.div<StyledLayerProps>`
  position: absolute;
  top: ${({ center }) => (center ? '50%' : '30vh')};
  left: 50%;
  display: flex;
  flex-direction: column;

  width: ${props => (props.wide ? '100%' : 'auto')};
  max-width: ${({ autoSize }) => (autoSize ? '90vw' : '60vw')};

  ${({ autoSize }) =>
    !autoSize &&
    css`
      @media (max-width: 1280px) {
        max-width: 70vw;
      }

      @media (max-width: 1024px) {
        max-width: 80vw;
      }

      @media (max-width: 600px) {
        max-width: 90vw;
      }
    `}

  height: auto;
  max-height: ${props =>
    props.wide
      ? 'calc(100vh - 50px - var(--vh-offset, 0px));'
      : 'calc(100vh - 150px - var(--vh-offset, 0px))'};
  overflow: hidden;

  ${props =>
    props.transparent
      ? 'background-color: transparent;'
      : css`
          background-color: #fff;
          ${createCssBoxShadow()};
        `}

  opacity: 1;
  transform: ${baseTransform};

  border-radius: 8px;

  transition: opacity ${animationTimeout}ms ${animationTimingFunction},
    transform ${animationTimeout}ms ${animationTimingFunction};

  &.${animationClassName}-exit {
    opacity: 1;
    transform: ${baseTransform} translateY(0);
  }

  &.${animationClassName}-enter,
    &.${animationClassName}-exit-active,
    &.${animationClassName}-exit-done {
    opacity: 0;
    transform: ${baseTransform} 100px;
  }

  &.${animationClassName}-enter-active {
    opacity: 1;
    transform: ${baseTransform} translateY(0);
  }
`;

export const AlertLayerCloseButtonWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
`;

export interface AlertLayerPropsWithAnimation extends AlertLayerProps {
  /**
   * This is provided by the react-transition-group. No need to use it when instantiating the component.
   * Note: If you write a wrapper for this component, take care to pass these props through to ensure that the
   * animations still work
   */
  readonly in?: boolean;
  /**
   * This is provided by the react-transition-group. No need to use it when instantiating the component.
   * Note: If you write a wrapper for this component, take care to pass these props through to ensure that the
   * animations still work
   */
  onExited?(): void;
}

export const AlertLayer = React.forwardRef<
  HTMLDivElement,
  AlertLayerPropsWithAnimation
>((props, ref) => {
  const {
    active,
    children,
    in: visible,
    onExited,
    wide,
    transparent,
    autoSize,
    center = true
  } = props;
  const timeout = 300;

  return (
    <CSSTransition
      classNames={animationClassName}
      onExited={onExited}
      in={visible}
      timeout={timeout}
    >
      <StyledLayer
        role="dialog"
        aria-modal={active}
        wide={wide}
        ref={ref}
        transparent={transparent}
        autoSize={autoSize}
        center={center}
      >
        {children}
      </StyledLayer>
    </CSSTransition>
  );
});
