import {
  Breakpoints,
  Button,
  ButtonAppearance,
  Checkbox,
  DescriptiveText,
  FormControlVariant,
  StyledFullScreenLayerContent,
  Text,
  TextSize,
  TextTag
} from '@yarmill/components';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { UserConsents } from '../users/api/change-email-preferences';
import { observer } from 'mobx-react-lite';

const StyledEmailPreferences = styled(StyledFullScreenLayerContent)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.tablet}px) {
    margin: auto;
    max-width: 1000px;
  }
`;
const StyledContentWrapper = styled.div``;
const StyledContent = styled.div`
  display: flex;
  margin-bottom: 30px;

  @media (min-width: ${Breakpoints.tablet}px) {
    margin-bottom: 50px;
  }
`;
const StyledLabelWrapper = styled.div`
  h2 {
    margin-bottom: 0;
  }

  label {
    font-weight: 500;
    color: #9a9a9a;
    font-size: 14px;
    margin-bottom: 0;
  }
`;
const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  button:first-child {
    margin-bottom: 20px;
  }

  button {
    @media (max-width: ${Breakpoints.tablet}px) {
      width: 100%;
    }
  }

  @media (min-width: ${Breakpoints.tablet}px) {
    display: block;
    text-align: right;

    button:first-child {
      margin-right: 20px;
    }
  }
`;
const StyledHeadline = styled(Text)`
  margin-bottom: 40px;

  @media (min-width: ${Breakpoints.tablet}px) {
    margin-bottom: 60px;
  }
`;

interface EmailPreferencesProps {
  commercialConsent: boolean;
  newsletterConsent: boolean;
  closeLayer(): void;
  saveData(userConsents: UserConsents): void;
}

export const EmailPreferences = observer(function EmailPreferences(
  props: EmailPreferencesProps
): JSX.Element {
  const { newsletterConsent, commercialConsent, closeLayer, saveData } = props;
  const [newsletterConsentCheckbox, setNewsletterConsentCheckbox] =
    React.useState<boolean>(newsletterConsent);
  const [commercialConsentCheckbox, setCommercialConsentCheckbox] =
    React.useState<boolean>(commercialConsent);

  const handleCheckbox = React.useCallback(
    (e: React.SyntheticEvent<HTMLElement>) => {
      const target = e.target as HTMLInputElement;

      if (target.name === 'NewsletterConsent') {
        setNewsletterConsentCheckbox(prev => !prev);
      } else {
        setCommercialConsentCheckbox(prev => !prev);
      }
    },
    []
  );

  const onSaveChanges = React.useCallback(() => {
    saveData({
      NewsletterConsent: newsletterConsentCheckbox,
      CommercialConsent: commercialConsentCheckbox
    });
  }, [newsletterConsentCheckbox, commercialConsentCheckbox, saveData]);

  const isButtonDisabled =
    props.commercialConsent === commercialConsentCheckbox &&
    props.newsletterConsent === newsletterConsentCheckbox;

  return (
    <StyledEmailPreferences>
      <StyledHeadline size={TextSize.s24}>
        <FormattedMessage id="settings.profile.emailPreferences" />
      </StyledHeadline>
      <StyledContentWrapper>
        <StyledContent>
          <Checkbox
            name={'NewsletterConsent'}
            value={String(newsletterConsentCheckbox)}
            onClick={handleCheckbox}
            checked={newsletterConsentCheckbox}
            label={
              <StyledLabelWrapper>
                <Text tag={TextTag.div} medium>
                  <FormattedMessage id="settings.profile.emailPreferences.newsletterConsent.header" />
                </Text>
                <DescriptiveText tag={TextTag.div} size={TextSize.s14}>
                  <FormattedMessage id="settings.profile.emailPreferences.newsletterConsent.description" />
                </DescriptiveText>
              </StyledLabelWrapper>
            }
          />
        </StyledContent>
        <StyledContent>
          <Checkbox
            name={'CommercialConsent'}
            value={String(commercialConsentCheckbox)}
            onClick={handleCheckbox}
            checked={commercialConsentCheckbox}
            label={
              <StyledLabelWrapper>
                <Text tag={TextTag.div} medium>
                  <FormattedMessage id="settings.profile.emailPreferences.commercialConsent.header" />
                </Text>
                <DescriptiveText tag={TextTag.div} size={TextSize.s14}>
                  <FormattedMessage id="settings.profile.emailPreferences.commercialConsent.description" />
                </DescriptiveText>
              </StyledLabelWrapper>
            }
          />
        </StyledContent>
      </StyledContentWrapper>
      <StyledButtonsWrapper>
        <Button
          wide
          appearance={ButtonAppearance.Secondary}
          onClick={closeLayer}
          variant={FormControlVariant.default}
          data-cy="email-button-cancel"
          inverted
        >
          <FormattedMessage id="settings.profile.form.back" />
        </Button>
        <Button
          wide
          disabled={isButtonDisabled}
          appearance={ButtonAppearance.Primary}
          onClick={onSaveChanges}
          variant={FormControlVariant.default}
          data-cy="email-button-save"
        >
          <FormattedMessage id="settings.profile.submit" />
        </Button>
      </StyledButtonsWrapper>
    </StyledEmailPreferences>
  );
});
