import { times } from 'ramda';
import * as React from 'react';

import { BaseWorkoutData, HeartRateZone, ZoneData } from '@yarmill/components';

import { Workout } from './types';
import { sortHeartRateZones } from './utils';
import { WorkoutDetailStore } from './mobx/workout-detail-store';
import { useRootStore } from '../app/root-store-context';

export function useWorkoutDetailStore(): WorkoutDetailStore {
  return useRootStore().workoutDetailStore;
}

export function useContainerWidth(
  container: React.RefObject<HTMLDivElement>
): number {
  const [width, setWidth] = React.useState(window.innerWidth / 2);

  React.useEffect(() => {
    function measureContainerWidth(): void {
      if (container.current) {
        const rect = container.current.getBoundingClientRect();
        setWidth(rect.width);
      }
    }
    measureContainerWidth();

    window.addEventListener('resize', measureContainerWidth);

    return (): void => {
      window.removeEventListener('resize', measureContainerWidth);
    };
  }, [container]);

  return width;
}

export type ChartsData = BaseWorkoutData;

export interface SampledWorkoutData {
  chartsData: ChartsData[];
  validHR: boolean;
  validAltitude: boolean;
  validDistance: boolean;
}

function getPrevValue(
  data: ChartsData[],
  index: number,
  xAxisKey: string
): number | undefined | null {
  if (index === 0) {
    return undefined;
  }

  let currentIndex = index - 1;
  while (data?.[currentIndex]?.[xAxisKey] === null) {
    currentIndex--;
  }

  return data?.[currentIndex]?.[xAxisKey];
}

export function useSampledWorkoutData(
  detail: Workout | null,
  xAxisKey: string
): SampledWorkoutData {
  const fullData: ChartsData[] = React.useMemo(() => {
    if (!detail) {
      return [];
    }

    return times(
      (i: number) => ({
        time:
          detail.TimeEvolution && typeof detail.TimeEvolution[i] === 'number'
            ? detail.TimeEvolution[i]
            : null,
        hr:
          detail.HeartRateEvolution &&
          typeof detail.HeartRateEvolution[i] === 'number'
            ? detail.HeartRateEvolution[i]
            : null,
        altitude:
          detail.AltitudeEvolution &&
          typeof detail.AltitudeEvolution[i] === 'number'
            ? detail.AltitudeEvolution[i]
            : null,
        distance:
          detail.DistanceEvolution &&
          typeof detail.DistanceEvolution[i] === 'number'
            ? detail.DistanceEvolution[i]
            : null,
        x:
          detail.TimeEvolution && typeof detail.TimeEvolution[i] === 'number'
            ? detail.TimeEvolution[i]
            : 0,
        y:
          detail.HeartRateEvolution &&
          typeof detail.HeartRateEvolution[i] === 'number'
            ? detail.HeartRateEvolution[i]
            : 0
      }),
      detail.TimeEvolution?.length || 0
    );
  }, [detail]);

  const chartsData: ChartsData[] =
    xAxisKey === 'time'
      ? fullData.filter(datum => datum[xAxisKey] !== null)
      : fullData.filter(
          (datum, i, data) =>
            datum[xAxisKey] !== null &&
            datum[xAxisKey] !== getPrevValue(data, i, xAxisKey)
        );

  const validHR = Boolean(detail && detail.HeartRateEvolution !== null);
  const validAltitude = Boolean(detail && detail.AltitudeEvolution !== null);
  const validDistance = Boolean(detail && detail.DistanceEvolution !== null);

  return { chartsData, validHR, validAltitude, validDistance };
}

export function useZonesData(detail: Workout | null): ZoneData[] {
  return React.useMemo(() => {
    if (!detail) {
      return [];
    }
    const zoneTimes = detail.TotalZoneTimes || [];
    const hrZones = detail.HeartRateZones || [];

    return zoneTimes
      .map(totalZoneTime => {
        const zone = hrZones.find(z => z.ZoneId === totalZoneTime.ZoneId);

        return {
          id: totalZoneTime.ZoneId,
          time: totalZoneTime.TimeInZone,
          name: zone?.Name ?? null,
          top: zone ? zone.UpperLimit : 0,
          bottom: zone ? zone.LowerLimit : 0
        };
      })
      .sort(sortHeartRateZones);
  }, [detail]);
}

export function useZonesDefinition(detail: Workout | null): HeartRateZone[] {
  return React.useMemo(() => {
    if (!detail) {
      return [];
    }

    const hrZones = detail.HeartRateZones || [];

    return hrZones
      .map(zone => ({
        bottom: zone.LowerLimit,
        top: zone.UpperLimit,
        name: zone.Name,
        id: zone.ZoneId
      }))
      .sort(sortHeartRateZones);
  }, [detail]);
}
