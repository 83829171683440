import { UserGroupId } from '../../groups/types';
import { UserId } from '../../users/types';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { SeasonId } from '../../seasons/types';
import { ActivityData } from '../../activities/types';

export interface GetSingleSeasonParams {
  userGroupId?: UserGroupId;
  userId?: UserId;
  seasonId: SeasonId;
}

export interface GetSingleSeasonResponse {
  Plan: ActivityData[];
  Reality: ActivityData[];
  AnnualPlan: ActivityData[];
}

export function getSingleSeasonData(
  params: GetSingleSeasonParams,
  cancelToken: CancelTokenSource
): AxiosPromise<GetSingleSeasonResponse> {
  return axios.get('api/rtc/season', {
    params,
    cancelToken: cancelToken.token
  });
}
