import { Page } from '../page/page';
import { Attendance } from './attendance';
import { Navbar } from '../navbar/navbar';
import { ViewSelector } from '../navbar/view-selector';
import { WeekSelector } from '../navbar/week-selector';
import { useIsMobile } from '../utils/use-is-mobile';
import { SeasonSelector } from '../navbar/season-selector';
import { WeekDaySelector } from '../navbar/week-day-selector';
import { CopyAttendanceButton } from './copy-attendance-button';
import { ViewType } from './types';
import { Sidebar } from '../athletes-sidebar/sidebar';
import { useCurrentUserStore } from '../users/hooks';
import { observer } from 'mobx-react-lite';
import { PhaseSelector } from './phase-selector';
import styled from 'styled-components';
import { memo } from 'react';
import {
  AttendanceStoreContextProvider,
  useAttendanceStore
} from './attendance-store-context';
import { ContentBox, PageMainContent, Text } from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { useRootStore } from '../app/root-store-context';

const VIEW_TYPES: ViewType[] = ['week', 'season'];

const LeftItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 8px;

  @media (min-width: 768px) {
    margin-top: -10px;
    margin-left: -15px;

    & > * {
      margin-top: 10px;
      margin-left: 15px;
    }
  }

  @media (min-width: 991px) {
    margin-left: -25px;
    & > * {
      margin-left: 25px;
    }
    & > :first-child {
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const ObserverAttendancePageRoot = observer(
  function AttendancePageRoot(): JSX.Element {
    const attendanceStore = useAttendanceStore();
    const rootStore = useRootStore();

    const viewType = attendanceStore.viewType;
    const group = attendanceStore.group;
    const isMobile = useIsMobile();
    const currentUser = useCurrentUserStore();
    const phases = attendanceStore.phases;

    if (!currentUser.isAthlete && !attendanceStore.group && rootStore.isReady) {
      return <Redirect to="/noGroup" />;
    }

    return (
      <Page>
        {!currentUser.isAthlete ? <Sidebar hideAthletesInGroup /> : undefined}
        <PageMainContent>
          <Navbar
            leftItems={
              <LeftItemsWrapper>
                <ViewSelector
                  moduleKey="attendance"
                  activeViewType={viewType}
                  allowedViewTypes={VIEW_TYPES}
                />
                {viewType === 'week' &&
                  (isMobile ? <WeekDaySelector /> : <WeekSelector />)}
                {viewType === 'season' && <SeasonSelector />}
                {phases.length > 2 && <PhaseSelector />}
              </LeftItemsWrapper>
            }
            rightItems={<>{<CopyAttendanceButton />}</>}
          />
          {!group && rootStore.isReady ? (
            <ContentBox>
              <Text>
                <FormattedMessage id="attendence.noAttendence" />
              </Text>
            </ContentBox>
          ) : (
            <Attendance />
          )}
        </PageMainContent>
      </Page>
    );
  }
);

export const AttendancePageRoot = memo(() => (
  <AttendanceStoreContextProvider>
    <ObserverAttendancePageRoot />
  </AttendanceStoreContextProvider>
));
