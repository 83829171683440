import {
  ReportingModuleConfig,
  ReportingNavigationLink
} from '../reporting/types';
import {
  EvidenceNavigationLink,
  HeaderLink,
  MainNavigationLink,
  SettingsNavigationLink
} from './types';
import { createContext } from 'react';

export const DropdownContext = createContext<(() => void) | undefined>(
  undefined
);
export function appendDefaultSearchParams(
  searchParams: string | undefined,
  defaultParams: string
): string {
  const urlSearchParams = new URLSearchParams(searchParams);
  const defaults = new URLSearchParams(defaultParams);

  for (const defaultParam of defaults.entries()) {
    const [key, value] = defaultParam;
    if (!urlSearchParams.has(key)) {
      urlSearchParams.set(key, value);
    }
  }

  return urlSearchParams.toString();
}

export function findActiveReportingModulePage(
  reportingModules: ReportingModuleConfig,
  reportingLinks: ReportingNavigationLink[],
  moduleName: string | undefined
): ReportingNavigationLink | undefined {
  return reportingModules
    .filter(page =>
      page.Dashboards.find(dashboard => dashboard.ReportPageCode === moduleName)
    )
    .map(page => reportingLinks.find(link => link.title === page.Title))
    .find(Boolean);
}

export function isMainNavLink(x: HeaderLink): x is MainNavigationLink {
  return x && 'module' in x && !isSettingsLink(x);
}

export function isReportingLink(x: HeaderLink): x is ReportingNavigationLink {
  return x && 'dashboard' in x;
}

export function isSettingsLink(x: HeaderLink): x is SettingsNavigationLink {
  return x && 'module' in x && x.module === 'settings';
}

export function isEvidenceLink(x: HeaderLink): x is EvidenceNavigationLink {
  return x && !isMainNavLink(x) && !isReportingLink(x) && !isSettingsLink(x);
}
