import { Icon, IconSize, styled } from '@yarmill/components';
import { Validation } from '@yarmill/icons-2';
import { Text } from '../../text';
import { getAppearanceColors, TextInputProps } from '../../text-input';

interface RatingScoreProps {
  readonly score: number;
  readonly message: string;
  readonly metrics?: JSX.Element;
}

export function getScoreAppearance(
  score: number
): TextInputProps['appearance'] {
  if (score < 10) {
    return 'neutral';
  } else if (score < 30) {
    return 'red';
  } else if (score < 60) {
    return 'orangine';
  } else if (score < 100) {
    return 'sand';
  } else {
    return 'green';
  }
}

const RatingScoreLayout = styled.div<{ readonly score: number }>`
  color: ${({ score, theme }) =>
    getAppearanceColors(getScoreAppearance(score), theme).base};
  margin-bottom: ${({ theme }) => theme.size.x2};
`;
const Percentage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: ${({ theme }) => theme.size.x05};
  margin-bottom: ${({ theme }) => theme.size.x1};
`;

const MetricsLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${({ theme }) => theme.size.x1};
  margin-top: ${({ theme }) => theme.size.x15};
`;

export function RatingScore({
  score,
  message,
  metrics
}: RatingScoreProps): JSX.Element {
  return (
    <RatingScoreLayout score={score}>
      <Percentage>
        <Icon size={IconSize.s32}>
          <Validation />
        </Icon>
        <Text appearance="h28" inheritColor>
          {score}%
        </Text>
      </Percentage>
      <Text as="div" appearance="label10" align="center" inheritColor>
        {message}
      </Text>
      {metrics && <MetricsLayout>{metrics}</MetricsLayout>}
    </RatingScoreLayout>
  );
}
