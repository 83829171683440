import { Popover } from '../components/popover';
import { EllipsisButton } from '../components/ellipsis-button';
import { TrainingDayStore } from './mobx/training-day-store';
import { TrainingDayDate } from './training-day-date';
import { ExtraItemsMenu } from './extra-items-menu';
import { observer } from 'mobx-react-lite';
import { TrainingDayHeaderLayout } from '@yarmill/components';

export interface TrainingDayHeaderProps {
  day: TrainingDayStore;
}

function InternalTrainingDayHeader(props: TrainingDayHeaderProps): JSX.Element {
  const { day } = props;

  return (
    <TrainingDayHeaderLayout>
      <TrainingDayDate day={day} />
      {day.showExtraDropdown && (
        <Popover
          content={tippy => <ExtraItemsMenu day={day} closeMenu={tippy.hide} />}
        >
          <EllipsisButton data-cy="toggle-training-day-dropdown" />
        </Popover>
      )}
    </TrainingDayHeaderLayout>
  );
}

export const TrainingDayHeader = observer(InternalTrainingDayHeader);
