import { Button, ButtonAppearance } from '../../components-2/button';
import { Icon, IconSize } from '@yarmill/components';
import { Done } from '@yarmill/icons-2';
import { FormattedMessage } from 'react-intl';
import { ObjectiveStore } from '../mobx/objective-store';
import { useCallback, useContext } from 'react';
import { KeyResultsSelectionContext } from '../key-results-selection-context';

interface SelectAllKeyResultsProps {
  readonly objective: ObjectiveStore;
}
export function SelectAllKeyResults({
  objective
}: SelectAllKeyResultsProps): JSX.Element {
  const selectionStore = useContext(KeyResultsSelectionContext);

  const handleClick = useCallback(() => {
    const ids = objective.keyResults.map(k => k.uid);

    if (ids.length === selectionStore.selectedKeyResults.size) {
      selectionStore.unSelectKeyResults(ids);
    } else {
      selectionStore.selectKeyResults(ids);
    }
  }, [objective, selectionStore]);

  return (
    <Button
      $appearance={ButtonAppearance.Secondary}
      $appearanceStyle="navy"
      onClick={handleClick}
      $iconOnly
    >
      <Icon size={IconSize.s16}>
        <Done />
      </Icon>
      <FormattedMessage
        id={
          objective.keyResults.length === selectionStore.selectedKeyResults.size
            ? 'okrs.detail.unSelectAllKeyResults'
            : 'okrs.detail.selectAllKeyResults'
        }
      />
    </Button>
  );
}
