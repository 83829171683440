import baseStyled, {
  ThemedCssFunction,
  ThemedStyledInterface,
  css as baseCSS,
  useTheme as baseUseTheme,
  createGlobalStyle as baseCreateGlobalStyle,
  ThemedStyledComponentsModule,
  keyframes as baseKeyframes
} from 'styled-components';
import { ThemeDefinition } from './theme-definition';

export * from './theme-provider';
export * from './theme-definition';
export type Color = keyof ThemeDefinition['color'];
export const styled = baseStyled as ThemedStyledInterface<ThemeDefinition>;
export const keyframes = baseKeyframes;
export const css = baseCSS as ThemedCssFunction<ThemeDefinition>;
export const createGlobalStyle =
  baseCreateGlobalStyle as ThemedStyledComponentsModule<ThemeDefinition>['createGlobalStyle'];
export const useTheme = baseUseTheme as () => ThemeDefinition;
