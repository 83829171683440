import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Text, TextSize, TextTag } from '../text';
import { css } from '../theme-provider';

type SectionType = 'default' | 'condensed';
export type ReportingSectionProps = PropsWithChildren<{
  readonly title?: ReactNode;
  readonly description?: ReactNode;
  readonly layoutConfig: string;
  readonly shouldBreakInPrint?: boolean;
  readonly type?: SectionType;
  readonly isHighlighted?: boolean;
  readonly isSubSection?: boolean;
  readonly useSubgrid?: boolean;
}>;

const CONFIGURABLE_TITLE_LAYOUT_AREA = 'SectionHeader';

const SectionHeader = styled.div<{
  readonly useArea?: boolean;
  readonly useSubgrid?: boolean;
}>`
  display: grid;
  grid-template-columns: 100%;
  row-gap: 10px;
  grid-template-rows: ${({ useSubgrid }) => (useSubgrid ? 'subgrid' : 'auto')};

  ${({ useArea }) =>
    useArea &&
    css`
      grid-area: ${CONFIGURABLE_TITLE_LAYOUT_AREA};
      align-self: center;
      grid-template-columns: auto;
    `};

  @media print {
    page-break-after: avoid;
    margin-bottom: 20px;
  }
`;

const ReportDescription = styled(Text)`
  color: #9b9b9b;
`;

interface ReportingSectionLayoutProps {
  readonly layoutConfig: string;
  readonly numberOfColumns: number;
  readonly useSubgrid?: boolean;
  readonly isHighlighted?: boolean;
}

const ReportingSectionLayout = styled.div<ReportingSectionLayoutProps>`
  display: grid;
  ${({ layoutConfig, useSubgrid, numberOfColumns }) =>
    layoutConfig
      ? css`
          grid-template-areas: ${`"${layoutConfig}"\n`.repeat(
            useSubgrid ? 2 : 1
          )};
          grid-template-columns: repeat(${numberOfColumns}, 1fr);
          grid-template-rows: ${useSubgrid ? 'auto auto' : 'auto'};
          column-gap: ${numberOfColumns > 1 ? '20px' : 0};
          align-content: stretch;
          align-items: stretch;
        `
      : css`
          row-gap: 20px;
        `};

  position: relative;
  max-width: 100%;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: #ffffff;
      border-radius: 8px;
      padding: 12px;
      box-shadow: 0 6px 8px 3px rgba(0, 0, 0, 0.06);

      @media print {
        box-shadow: none;
        border: 1px solid #4a4a4a;
      }

      @media (min-width: 768px) {
        padding: 26px;
      }
    `};

  @media screen and (max-width: 560px) {
    display: block;
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const ReportingSectionIconWrapper = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  align-items: center;
`;

const StyledReportingSection = styled.div<{
  readonly type: SectionType;
}>`
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 20px;

  ${({ type }) =>
    type === 'condensed' &&
    css`
      @media (min-width: 768px) {
        grid-template-columns: auto 1fr;
        column-gap: 20px;
        align-items: center;
        align-content: center;
      }
    `};

  @media print {
    display: block;
    background-color: #ffffff;
  }
`;

const Title = styled(Text)`
  display: flex;
  align-content: center;
  column-gap: 16px;
`;

export function ReportingSection(props: ReportingSectionProps): JSX.Element {
  const {
    children,
    title,
    description,
    layoutConfig,
    type = 'default',
    isHighlighted,
    useSubgrid,
    isSubSection
  } = props;

  const includeHeaderInLayout = layoutConfig.includes(
    CONFIGURABLE_TITLE_LAYOUT_AREA
  );

  const header = (
    <SectionHeader
      useArea={includeHeaderInLayout}
      useSubgrid={useSubgrid && includeHeaderInLayout}
    >
      {title && (
        <Title
          size={isSubSection ? TextSize.s14 : TextSize.s16}
          tag={TextTag.div}
          medium
        >
          {title}
        </Title>
      )}
      {description && (
        <ReportDescription size={TextSize.s12} tag={TextTag.div}>
          {description}
        </ReportDescription>
      )}
    </SectionHeader>
  );

  return (
    <StyledReportingSection type={includeHeaderInLayout ? 'default' : type}>
      {(title || description) && !includeHeaderInLayout && header}
      <ReportingSectionLayout
        layoutConfig={layoutConfig}
        numberOfColumns={layoutConfig.split(' ').length}
        useSubgrid={useSubgrid}
        isHighlighted={isHighlighted}
      >
        {includeHeaderInLayout && header}
        {children}
      </ReportingSectionLayout>
    </StyledReportingSection>
  );
}
