import { ReactElement } from 'react';
import {
  ExternalIcon,
  Icon,
  styled,
  Text,
  TextSize
} from '@yarmill/components';

export const GoalsEventsWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 8px;
  margin: 6px 12px 0 12px;

  :empty {
    display: none;
  }
`;

const EventLayout = styled.div<{
  readonly color: string;
}>`
  display: inline-flex;
  align-items: flex-start;
  background-color: ${({ theme, color }) =>
    theme.color[color ? `${color}_8` : 'navy_8']};
  color: ${({ theme, color }) => theme.color[color ?? 'navy']};
  border-radius: 4px;
  padding: 2px 8px;
  gap: 4px;
  line-height: 16px;
`;

const EventText = styled(Text)`
  line-height: 16px;
`;
interface GoalPlannerEventProps {
  readonly title: string;
  readonly color: string;
}
export function GoalPlannerEvent({
  title,
  color
}: GoalPlannerEventProps): ReactElement {
  return (
    <EventLayout color={color}>
      <Icon>
        <ExternalIcon name="Calendar" />
      </Icon>
      <EventText size={TextSize.s12} inheritColor>
        {title}
      </EventText>
    </EventLayout>
  );
}
