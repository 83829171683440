import { css, Icon, IconSize, styled } from '@yarmill/components';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  offset,
  Placement,
  safePolygon,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole
} from '@floating-ui/react';
import {
  ChangeEvent,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Key } from '../key';
import { normalizeString } from '../../command-palette/utils';
import { DropdownContext } from './dropdown-context';
import { Option } from './option';
import { Down, Search, Up } from '@yarmill/icons-2';
import { Text } from '../text';

export interface DropdownOption<V> {
  readonly label?: string;
  readonly value?: V;
  readonly icon?: JSX.Element;
  readonly children?: DropdownOption<V>[];
  readonly onClick?: (e: MouseEvent) => void;
  readonly isAdditional?: boolean;
  readonly isSelected?: boolean;
  readonly showRadio?: boolean;
}

type DropdownProviderProps<V> = PropsWithChildren<{
  readonly options: DropdownOption<V>[];
  readonly searchInputPlaceholder?: string;
  readonly multiSelect?: boolean;
  readonly keyboardShortcut?: string;
  readonly selectedValue?: V | V[];
  readonly handleSelect?: (value: V) => void;
  readonly searchable?: boolean;
  readonly label?: string;
  readonly placement?: Placement;
  readonly additionalOption?: DropdownOption<V>;
  readonly showRadio?: boolean;
  readonly onFocus?: FocusEventHandler;
  readonly onMouseEnter?: MouseEventHandler;
  readonly disabled?: boolean;
}>;

export const DropdownButton = styled.button<{
  readonly $isNested?: boolean;
  readonly $hasFocusInside?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.borderRadius.x075};
  transition-property: border, background-color, color, opacity;
  transition-duration: 150ms;
  user-select: none;
  text-align: left;
  flex-shrink: 1;
  flex-grow: 0;
  justify-content: flex-start;
  border: 0;
  box-shadow: none;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  outline: none;
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  color: inherit;

  :focus-visible {
    outline: none;
  }

  ${({ $isNested }) =>
    $isNested &&
    css`
      border-radius: ${({ theme }) => theme.borderRadius.x1};
      padding: ${({ theme }) => `${theme.size.x1}`};
      :hover,
      :focus {
        background-color: ${({ theme }) => theme.color.blackHover};
      }
    `}

  ${({ $hasFocusInside, $isNested }) =>
    $hasFocusInside &&
    $isNested &&
    css`
      background-color: ${({ theme }) => theme.color.blackHover};
    `}
`;
const DropdownContainer = styled.div<{ readonly hasLabel?: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 200px;

  outline: none;
  background-color: ${({ theme }) => theme.color.text};
  border-radius: ${({ theme }) => theme.borderRadius.x15};
  box-shadow: ${({ theme }) => theme.boxShadow.bs2};
  padding: ${({ theme }) => theme.size.x1} 0;
  ${({ hasLabel }) => hasLabel && `padding-top: 0;`};
  z-index: 1;

  & > svg {
    fill: ${({ theme }) => theme.color.blackHover};
  }
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
`;

const SearchFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  height: ${({ theme }) => theme.size.x4};
  padding: ${({ theme }) => `0 ${theme.size.x15}`};
`;

const SearchInput = styled.input`
  font-family: ${({ theme }) => theme.text.font.default};
  font-weight: 700;
  padding-right: ${({ theme }) => `${theme.size.x15}`};
  padding-left: ${({ theme }) => `${theme.size.x25}`};
  grid-area: 1 / 1 / auto / auto;
  margin: 0;
  border: none;
  appearance: none;
  font-size: ${({ theme }) => theme.size.x15};
  height: ${({ theme }) => theme.size.x4};
  background: transparent;
  color: ${({ theme }) => theme.color.white};
  caret-color: ${({ theme }) => theme.color.white};
  outline: none;
  width: 100%;
  :focus,
  :hover {
    outline: none;
  }
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ theme }) => theme.size.x1};
  color: ${({ theme }) => theme.color.white};
`;

const DropdownContainerIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.white};
  cursor: pointer;
`;

const DropdownLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.blackHover};
  padding: ${({ theme }) => theme.size.x15};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.x15};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.x15};
`;

const OptionsList = styled.div<{ readonly showBorderTop: boolean }>`
  height: 100%;
  overflow: hidden auto;
  padding: ${({ theme }) => theme.size.x025};
  ${({ theme, showBorderTop }) =>
    showBorderTop &&
    css`
      border-top: 1px solid ${theme.color.blackHover};
    `}

  :empty {
    display: none;
  }

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const OptionsListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function getDefaultActiveIndex<V>(
  options: DropdownOption<V>[],
  selectedValue: V | V[],
  isNested: boolean
): number | null {
  const selected = Array.isArray(selectedValue)
    ? selectedValue[0]
    : selectedValue;

  const optionIndex = options.findIndex(
    o => o.value === selected || o.children?.find(c => c.value === selected)
  );

  if (optionIndex !== -1 && optionIndex !== null) {
    return optionIndex;
  }

  return isNested ? null : 0;
}

function sortSelectedOptionsFirst<V extends string | number | undefined>(
  options: DropdownOption<V>[],
  selectedValue: V | V[] | undefined
): DropdownOption<V>[] {
  if (
    !selectedValue ||
    (Array.isArray(selectedValue) && selectedValue.length === 0)
  ) {
    return options;
  }

  return options.sort((a, b) => {
    let aValue;
    let bValue;
    if (
      Array.isArray(selectedValue) &&
      a.value !== undefined &&
      b.value !== undefined
    ) {
      aValue = selectedValue.includes(a.value) ? 1 : 0;
      bValue = selectedValue.includes(b.value) ? 1 : 0;
    } else {
      aValue = a.value === selectedValue ? 1 : 0;
      bValue = b.value === selectedValue ? 1 : 0;
    }

    return bValue - aValue;
  });
}
function getSelectedIndex<V>(
  options: DropdownOption<V>[],
  selectedValue: V
): number | undefined {
  const selected = Array.isArray(selectedValue)
    ? selectedValue[0]
    : selectedValue;
  const optionIndex = options.findIndex(
    o => o.value === selected || o.children?.find(c => c.value === selected)
  );
  if (optionIndex !== -1) {
    return optionIndex;
  }
  return undefined;
}

const ARROW_HEIGHT = 7;
const GAP = 8;

const DropdownComponent = forwardRef(function DropdownProvider<
  V extends string | number | undefined
>(
  {
    children,
    options,
    searchInputPlaceholder,
    keyboardShortcut,
    selectedValue,
    handleSelect: passedHandleSelect,
    searchable,
    multiSelect,
    placement,
    additionalOption,
    label,
    showRadio,
    disabled,
    ...otherProps
  }: DropdownProviderProps<V>,
  forwardedRef: ForwardedRef<HTMLButtonElement>
): JSX.Element {
  const parentId = useFloatingParentNodeId();
  const tree = useFloatingTree();
  const nodeId = useFloatingNodeId();
  const item = useListItem();
  const arrowRef = useRef(null);
  const elementsRef = useRef<Array<HTMLElement | null>>([]);
  const parent = useContext(DropdownContext);
  const isNested = parentId != null;

  const [isOpened, setIsOpened] = useState(false);
  const sortedOptions = useMemo(
    () =>
      multiSelect ? sortSelectedOptionsFirst(options, selectedValue) : options,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, multiSelect, isOpened]
  );
  const [hasFocusInside, setHasFocusInside] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(
    getDefaultActiveIndex(sortedOptions, selectedValue, isNested)
  );
  const [filterValue, setFilter] = useState<string>('');

  const { refs, floatingStyles, context } = useFloating({
    nodeId,
    open: isOpened,
    onOpenChange: setIsOpened,
    placement: isNested ? 'right-start' : placement ?? 'bottom',
    middleware: [
      flip(),
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            // Minimum acceptable height is 50px.
            // `flip` will then take over.
            maxHeight: `${Math.max(50, availableHeight - 20)}px`
          });
        }
      }),
      offset(state => ({
        crossAxis: isNested ? (state.placement === 'top' ? 10 : -10) : 0,
        mainAxis: label ? ARROW_HEIGHT + GAP : 0
      })),
      shift(),
      arrow({
        element: arrowRef,
        padding: {
          left: 16,
          right: 16
        }
      })
    ],
    whileElementsMounted: autoUpdate
  });

  const hasSubcategories = useMemo(
    () => Boolean(sortedOptions.find(o => o.children?.length)),
    [sortedOptions]
  );

  const hover = useHover(context, {
    enabled: isNested,
    delay: { open: 75 },
    handleClose: safePolygon({ blockPointerEvents: true })
  });
  const click = useClick(context, {
    event: 'mousedown',
    toggle: !isNested,
    ignoreMouse: isNested,
    enabled: !disabled
  });

  const filteredOptions = filterValue
    ? sortedOptions.filter(o =>
        normalizeString(o.label?.toLowerCase() ?? '').includes(
          normalizeString(filterValue.toLowerCase())
        )
      )
    : sortedOptions;

  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context, { bubbles: true });
  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex: multiSelect
      ? undefined
      : getSelectedIndex(filteredOptions, selectedValue),
    onNavigate: setActiveIndex,
    nested: isNested,
    virtual: searchable
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [hover, click, role, dismiss, listNav]
  );

  const handleSelect = useCallback(
    (_index: number | null, value: V, isCheckboxClick = false) => {
      if (!isCheckboxClick) {
        setIsOpened(false);
      }
      passedHandleSelect?.(value);
    },
    [passedHandleSelect]
  );

  const handleFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setFilter(e.target.value);
      setActiveIndex(0);
    },
    [setActiveIndex]
  );

  const handleOpen = useCallback(() => setIsOpened(o => !o), []);

  const dropdownContext = useMemo(
    () => ({
      activeIndex,
      setActiveIndex,
      getItemProps,
      setHasFocusInside,
      isOpen: isOpened,
      selectedValue,
      handleSelect,
      hasSubcategories,
      multiSelect: multiSelect ?? false
    }),
    [
      activeIndex,
      setActiveIndex,
      getItemProps,
      isOpened,
      selectedValue,
      handleSelect,
      hasSubcategories,
      multiSelect
    ]
  );
  const onInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Enter' &&
      activeIndex != null &&
      filteredOptions[activeIndex]
    ) {
      const value = filteredOptions[activeIndex].value;
      if (
        ((hasSubcategories && isNested) || !hasSubcategories) &&
        value !== undefined
      )
        handleSelect(activeIndex, value);
    } else if (e.key === ' ' && activeIndex !== null && multiSelect) {
      e.preventDefault();
      e.stopPropagation();
      const value = filteredOptions[activeIndex].value;
      if (value !== undefined) {
        handleSelect(activeIndex, value, true);
      }
    }
  };

  // Event emitter allows you to communicate across tree components.
  // This effect closes all menus when an item gets clicked anywhere
  // in the tree.
  useEffect(() => {
    if (!tree) return;

    function handleTreeClick() {
      setIsOpened(false);
    }

    function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
      if (event.nodeId !== nodeId && event.parentId === parentId) {
        setIsOpened(false);
      }
    }

    tree.events.on('click', handleTreeClick);
    tree.events.on('menuopen', onSubMenuOpen);

    return () => {
      tree.events.off('click', handleTreeClick);
      tree.events.off('menuopen', onSubMenuOpen);
    };
  }, [tree, nodeId, parentId]);

  useEffect(() => {
    if (isOpened && tree) {
      tree.events.emit('menuopen', { parentId, nodeId });
    }
  }, [tree, isOpened, nodeId, parentId]);

  useEffect(() => {
    if (!isOpened) {
      setFilter('');
    }
  }, [isOpened]);

  return (
    <FloatingNode id={nodeId}>
      <DropdownButton
        ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}
        onClick={handleOpen}
        type="button"
        disabled={disabled}
        $isNested={isNested}
        data-nested={isNested ? '' : undefined}
        $hasFocusInside={
          hasFocusInside || (isNested && parent.activeIndex === item.index)
        }
        tabIndex={
          !isNested ? undefined : parent.activeIndex === item.index ? 0 : -1
        }
        {...getReferenceProps(
          parent.getItemProps({
            ...otherProps,
            onFocus(event) {
              otherProps.onFocus?.(event);
              setHasFocusInside(false);
              parent.setHasFocusInside(true);
            }
          })
        )}
      >
        {children}
      </DropdownButton>
      <DropdownContext.Provider value={dropdownContext}>
        <FloatingList elementsRef={elementsRef}>
          {isOpened && (
            <FloatingPortal>
              <FloatingFocusManager
                context={context}
                modal={false}
                initialFocus={searchable ? -1 : 0}
                returnFocus={!isNested}
              >
                <DropdownContainer
                  ref={refs.setFloating}
                  style={{ ...floatingStyles, zIndex: 2 }}
                  hasLabel={Boolean(label)}
                  {...getFloatingProps()}
                >
                  {context.placement.includes('bottom') &&
                    (label ? (
                      <>
                        <FloatingArrow context={context} ref={arrowRef} />
                        <DropdownLabelWrapper>
                          <Text appearance="task13strong" inheritColor>
                            {label}
                          </Text>
                        </DropdownLabelWrapper>
                      </>
                    ) : (
                      <DropdownContainerIconWrapper
                        onClick={() => setIsOpened(false)}
                      >
                        <Icon size={IconSize.s24}>
                          <Up />
                        </Icon>
                      </DropdownContainerIconWrapper>
                    ))}
                  {searchable && (
                    <SearchFieldWrapper>
                      <SearchInput
                        autoFocus
                        spellCheck={false}
                        autoComplete="off"
                        placeholder={searchInputPlaceholder}
                        onChange={handleFilter}
                        value={filterValue}
                        onKeyDown={onInputKeyDown}
                      />
                      <SearchIcon size={IconSize.s16}>
                        <Search />
                      </SearchIcon>
                      {keyboardShortcut && <Key>{keyboardShortcut}</Key>}
                    </SearchFieldWrapper>
                  )}
                  {filteredOptions.length > 0 && (
                    <OptionsList showBorderTop={Boolean(searchable)}>
                      <OptionsListContainer>
                        {additionalOption && (
                          <Option
                            {...additionalOption}
                            isAdditional
                            showRadio={showRadio}
                          />
                        )}
                        {filteredOptions.map((option, idx) => (
                          <Option
                            key={
                              option.value !== undefined
                                ? String(option.value)
                                : idx
                            }
                            {...option}
                            showRadio={showRadio}
                          />
                        ))}
                      </OptionsListContainer>
                    </OptionsList>
                  )}
                  {context.placement.includes('top') && (
                    <DropdownContainerIconWrapper
                      onClick={() => setIsOpened(false)}
                    >
                      <Icon size={IconSize.s24}>
                        <Down />
                      </Icon>
                    </DropdownContainerIconWrapper>
                  )}
                </DropdownContainer>
              </FloatingFocusManager>
            </FloatingPortal>
          )}
        </FloatingList>
      </DropdownContext.Provider>
    </FloatingNode>
  );
});

export const DropdownProvider = forwardRef(function DropdownProvider<V>(
  props: DropdownProviderProps<V>,
  ref: ForwardedRef<HTMLButtonElement>
): JSX.Element {
  const parentId = useFloatingParentNodeId();

  if (parentId === null) {
    return (
      <FloatingTree>
        <DropdownComponent {...(props as any)} ref={ref} />
      </FloatingTree>
    );
  }

  return <DropdownComponent {...(props as any)} ref={ref} />;
});
