import { RootStore } from '../../app/mobx/root-store';
import { Announcement } from '../../announcement/types';
import { computed, makeObservable } from 'mobx';
import { ReportingModuleConfig } from '../../reporting/types';

export class ModulesStore {
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @computed
  public get plan(): boolean {
    return this.rootStore.configStore.modules.get('plan');
  }
  @computed
  public get reality(): boolean {
    return this.rootStore.configStore.modules.get('reality');
  }
  @computed
  public get analytics(): boolean {
    return this.rootStore.configStore.modules.get('analytics');
  }
  @computed
  public get attendance(): boolean {
    return this.rootStore.configStore.modules.get('attendance');
  }
  @computed
  public get externalServices(): boolean {
    return this.rootStore.configStore.modules.get('externalServices');
  }
  @computed
  public get video(): boolean {
    return this.rootStore.configStore.modules.get('video');
  }
  @computed
  public get userEvidence(): boolean {
    return this.rootStore.configStore.modules.get('userEvidence');
  }
  @computed
  public get settings(): boolean {
    return this.rootStore.configStore.modules.get('settings');
  }
  @computed
  public get announcement(): Announcement[] {
    return this.rootStore.configStore.modules.get('announcement') || [];
  }
  @computed
  public get filesOverview(): boolean {
    return this.rootStore.configStore.modules.get('filesOverview');
  }
  @computed
  public get seasonEvaluation(): boolean {
    return this.rootStore.configStore.modules.get('seasonEvaluation');
  }

  @computed
  public get reporting(): ReportingModuleConfig {
    return this.rootStore.configStore.modules.get('reporting');
  }

  @computed
  public get evidence(): boolean {
    return this.rootStore.configStore.modules.get('evidence');
  }

  @computed
  public get okr(): boolean {
    return this.rootStore.configStore.modules.get('okr');
  }

  @computed
  public get yollanda(): boolean {
    return this.rootStore.configStore.modules.get('yollanda');
  }

  @computed
  public get planner(): boolean {
    return this.rootStore.configStore.modules.get('planner');
  }
}
