import { AxisBottom, AxisLeft } from '@visx/axis';

import { useXYChartContext } from './xy-chart-context';
import { AxisValue, XAxisConfig, YAxisConfig } from '../reporting/types';
import { getAxisXTickLabelFormatter } from './utils/get-axis-x-tick-label-formatter';
import { getAxisYTickLabelFormatter } from './utils/get-axis-y-tick-label-formatter';

interface AxesProps {
  readonly xAxisConfig?: XAxisConfig<AxisValue>;
  readonly yAxisConfig?: YAxisConfig<AxisValue>;
  readonly yAxisTicks?: AxisValue[];
}

export function Axes({ xAxisConfig, yAxisConfig, yAxisTicks }: AxesProps) {
  const { xScale, yScale, chartRect } = useXYChartContext();

  return (
    <>
      {xAxisConfig?.showTickLabels && (
        <AxisBottom
          hideAxisLine={xAxisConfig.hideAxisLine ?? true}
          top={chartRect.bottom}
          scale={xScale}
          hideTicks
          tickLabelProps={getAxisXTickLabelFormatter(xAxisConfig.tickAngle)}
          tickFormat={xAxisConfig.formatTick as any}
          numTicks={xAxisConfig.numberOfTicks}
        />
      )}
      {yAxisConfig?.showTickLabels && (
        <AxisLeft
          left={chartRect.left}
          hideAxisLine={yAxisConfig.hideAxisLine ?? true}
          scale={yScale}
          hideTicks
          tickLabelProps={getAxisYTickLabelFormatter(
            yAxisConfig.tickAngle || 360
          )}
          tickValues={yAxisTicks}
          tickFormat={yAxisConfig.formatTick as any}
          numTicks={yAxisConfig.numberOfTicks}
        />
      )}
    </>
  );
}
