import { EvidenceAttributeStore } from '../mobx/evidence-attribute-store';
import { Text } from './text';
import { Dropdown } from './dropdown';
import { Time } from './time';
import { Date } from './date';
import { TimeOfDay } from './time-of-day';
import { RichText } from './rich-text';

export interface FormFieldProps {
  attributeStore: EvidenceAttributeStore;
  autoFocus?: boolean;
}

export function FormField(props: FormFieldProps): JSX.Element {
  const { attributeStore, autoFocus } = props;

  switch (attributeStore.attributeType) {
    case 'dropdown':
      return <Dropdown attributeStore={attributeStore} autoFocus={autoFocus} />;
    case 'time':
      return <Time attributeStore={attributeStore} autoFocus={autoFocus} />;
    case 'time-of-day':
      return (
        <TimeOfDay attributeStore={attributeStore} autoFocus={autoFocus} />
      );
    case 'date':
      return <Date attributeStore={attributeStore} autoFocus={autoFocus} />;
    case 'rich-text':
      return <RichText attributeStore={attributeStore} autoFocus={autoFocus} />;
    default:
      return <Text attributeStore={attributeStore} autoFocus={autoFocus} />;
  }
}
