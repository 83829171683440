import { PatternInputProps, PatternInput } from '@yarmill/components';
import {
  validateFullTimeValueWithMs,
  validateHhMmSsTimeValue,
  validateTimeSssValue,
  validateTimeSsValue,
  validateTimeValue,
  validateTimeValueWithMs
} from '../../evidence/validation';
import { forwardRef } from 'react';

export const TIME_PATTERN = 'MM:SS';
export const TIME_PATTERN_WITH_MS = 'MM:SS.SS';
export const FULL_TIME_PATTERN_WITH_MS = 'HH:MM:SS.SS';
export const TIME_PATTERN_SS = 'SS.SS';
export const TIME_PATTERN_SSS = 'SS.SSS';
export const TIME_PATTERN_HHMMSS = 'HH:MM:SS';
const INCOMING_PATTERN = 'HH:MM:SS.SSS';

function getPatternConfiguration(pattern: string | null): {
  pattern: string;
  delimiter: string | string[];
  validateValue: (value: string) => boolean;
} {
  switch (pattern) {
    case TIME_PATTERN_WITH_MS:
      return {
        pattern: TIME_PATTERN_WITH_MS,
        delimiter: [':', '.'],
        validateValue: validateTimeValueWithMs
      };
    case FULL_TIME_PATTERN_WITH_MS:
      return {
        pattern: FULL_TIME_PATTERN_WITH_MS,
        delimiter: [':', ':', '.'],
        validateValue: validateFullTimeValueWithMs
      };
    case TIME_PATTERN_SS:
      return {
        pattern: TIME_PATTERN_SS,
        delimiter: ['.'],
        validateValue: validateTimeSsValue
      };
    case TIME_PATTERN_SSS:
      return {
        pattern: TIME_PATTERN_SSS,
        delimiter: ['.'],
        validateValue: validateTimeSssValue
      };
    case TIME_PATTERN_HHMMSS:
      return {
        pattern: TIME_PATTERN_HHMMSS,
        delimiter: [':'],
        validateValue: validateHhMmSsTimeValue
      };
    default:
      return {
        pattern: TIME_PATTERN,
        delimiter: ':',
        validateValue: validateTimeValue
      };
  }
}

export function preformatTimeValue(
  value: string,
  format: string | null = TIME_PATTERN
): string {
  const validFormat = format || TIME_PATTERN;
  const start = INCOMING_PATTERN.indexOf(validFormat);

  return value.slice(start, start + validFormat.length);
}

export const PatternInputTime = forwardRef<HTMLInputElement, PatternInputProps>(
  function PatternInputTime(props, ref): JSX.Element {
    const { pattern, ...otherProps } = props;
    return (
      <PatternInput
        {...otherProps}
        ref={ref}
        {...getPatternConfiguration(pattern)}
      />
    );
  }
);
