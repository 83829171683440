import { styled } from '@yarmill/components';
import { Text } from '../text';

type ActivityLogItemAppearance = 'sand' | 'neutral' | 'neutralDark';
interface ActivityLogItemProps {
  readonly avatar: JSX.Element;
  readonly label: string;
  readonly description: string;
  readonly date: JSX.Element;
  readonly appearance?: ActivityLogItemAppearance;
}

const ActivityLogItemLayout = styled.div<{
  readonly appearance: ActivityLogItemAppearance;
}>`
  overflow: hidden;
  display: grid;
  align-items: center;
  justify-items: flex-start;
  grid-template-columns: ${({ theme, appearance }) =>
      appearance === 'neutralDark' ? theme.size.x2 : theme.size.x35} auto 1fr;
  column-gap: ${({ theme }) => theme.size.x1};
  row-gap: ${({ theme }) => theme.size.x025};
  padding: ${({ theme, appearance }) =>
      appearance === 'neutralDark' ? theme.size.x1 : theme.size.x2}
    ${({ theme }) => theme.size.x1};
  margin-left: ${({ theme }) => theme.size.x2};
  border-bottom: ${({ theme }) => theme.size.x0125} solid
    ${({ theme }) => theme.color.neutral_8};
`;

const Label = styled(Text)<{ readonly $appearance: ActivityLogItemAppearance }>`
  color: ${({ theme, $appearance }) => theme.color[$appearance]};
  max-width: 100%;
`;

const Date = styled(Text)`
  color: ${({ theme }) => theme.color.neutral_40};
  justify-self: flex-end;
`;

const Description = styled(Text)`
  grid-column: 2 / 4;
  grid-row: 2 / 3;
  color: ${({ theme }) => theme.color.neutralDark};
`;
export function ActivityLogItem({
  appearance = 'neutralDark',
  avatar,
  label,
  date,
  description
}: ActivityLogItemProps): JSX.Element {
  return (
    <ActivityLogItemLayout appearance={appearance}>
      {avatar}
      <Label
        appearance="button10"
        upperCase
        $appearance={appearance}
        ellipsis
        whiteSpace="noWrap"
        as="div"
      >
        {label}
      </Label>
      <Date appearance="label10" whiteSpace="noWrap">
        {date}
      </Date>
      <Description appearance="label10">{description}</Description>
    </ActivityLogItemLayout>
  );
}
