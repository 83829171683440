import { ComponentType, useState } from 'react';
import { RadioButton } from '../radio-button';
import styled from 'styled-components';
import { PatternInputProps } from '../pattern-input';
import { TimeFilter } from './utils';

const TimeFiltersWrapper = styled.div`
  display: grid;
  padding-bottom: 10px;
  row-gap: 8px;
`;
const CustomTimesWrapper = styled.div`
  display: grid;
  row-gap: 5px;
  padding: 5px 0 5px 36px;
`;

const noop = () => null;

export interface TimeFiltersProps {
  PatternInput: ComponentType<PatternInputProps>;
  timeFormat?: string;
  value: TimeFilter | undefined;
  setCurrentValue(value: TimeFilter): void;
  translate?(key: string): string;
}

const TIME_PATTERN = 'MM:SS';
const TIME_PATTERN_WITH_MS = 'MM:SS.SS';
const FULL_TIME_PATTERN_WITH_MS = 'HH:MM:SS.SS';
const TIME_PATTERN_SS = 'SS.SS';
const TIME_PATTERN_SSS = 'SS.SSS';
const INCOMING_PATTERN = 'HH:MM:SS.SSS';
export const TIME_PATTERN_HHMMSS = 'HH:MM:SS';

function preformatTimeValue(
  value: string,
  format: string | null = TIME_PATTERN
): string {
  const validFormat = format || TIME_PATTERN;
  const start = INCOMING_PATTERN.indexOf(validFormat);

  return value.slice(start, start + validFormat.length);
}

function formatOutputTime(value: string, format: string): string {
  if (!value) {
    return '';
  }

  switch (format) {
    case TIME_PATTERN_WITH_MS:
      return `00:${value}0`;
    case FULL_TIME_PATTERN_WITH_MS:
      return `${value}0`;
    case TIME_PATTERN_HHMMSS:
      return `${value}.000`;
    case TIME_PATTERN_SS:
      return `00:00:${value}0`;
    case TIME_PATTERN_SSS:
      return `00:00:${value}`;
    case TIME_PATTERN:
    default:
      return `00:${value}.000`;
  }
}

export function TimeFilters(props: TimeFiltersProps): JSX.Element {
  const {
    value,
    setCurrentValue,
    PatternInput,
    timeFormat = 'MM:SS',
    translate
  } = props;
  const [inputValues, setInputValue] = useState({
    from:
      value?.type === 'range' && value.from
        ? preformatTimeValue(value.from, timeFormat)
        : value?.type === 'greaterorequal' && value?.values?.[0]
        ? preformatTimeValue(value.values[0], timeFormat)
        : '',
    to:
      value?.type === 'range' && value.to
        ? preformatTimeValue(value.to, timeFormat)
        : value?.type === 'lessorequal' && value?.values?.[0]
        ? preformatTimeValue(value.values[0], timeFormat)
        : '',
    match:
      value?.type === 'match' && value?.values?.[0]
        ? preformatTimeValue(value.values[0], timeFormat)
        : ''
  });

  function onInputChange(input: string, inputValue: string): void {
    if (input === 'from-time') {
      setInputValue(v => ({ ...v, from: inputValue }));
    } else if (input === 'to-time') {
      setInputValue(v => ({ ...v, to: inputValue }));
    } else if (input === 'match') {
      setInputValue(v => ({ ...v, match: inputValue }));
    }
  }

  function onRangeBlur(): void {
    const from =
      inputValues.from.length === timeFormat.length
        ? formatOutputTime(inputValues.from, timeFormat)
        : '';
    const to =
      inputValues.to.length === timeFormat.length
        ? formatOutputTime(inputValues.to, timeFormat)
        : '';

    setCurrentValue({
      type: 'range',
      from,
      to
    });
  }

  function onFromBlur(): void {
    if (inputValues.from.length !== timeFormat.length) {
      return;
    }
    setCurrentValue({
      type: 'greaterorequal',
      values: [formatOutputTime(inputValues.from, timeFormat)]
    });
  }

  function onMatchBlur(): void {
    if (inputValues.match.length !== timeFormat.length) {
      return;
    }
    setCurrentValue({
      type: 'match',
      values: [formatOutputTime(inputValues.match, timeFormat)]
    });
  }

  function onToBlur(): void {
    if (inputValues.to.length !== timeFormat.length) {
      return;
    }
    setCurrentValue({
      type: 'lessorequal',
      values: [formatOutputTime(inputValues.to, timeFormat)]
    });
  }

  function selectFilterType(value: TimeFilter): void {
    setCurrentValue(value);
    setInputValue({ from: '', to: '', match: '' });
  }

  return (
    <TimeFiltersWrapper>
      <RadioButton
        value="greaterorequal"
        label={translate?.('table.filters.greaterOrEqualThan')}
        checked={value?.type === 'greaterorequal'}
        onClick={() =>
          selectFilterType({ type: 'greaterorequal', values: [''] })
        }
        onChange={noop}
      />
      {value?.type === 'greaterorequal' && (
        <CustomTimesWrapper>
          <PatternInput
            autoFocus
            pattern={timeFormat}
            id="from"
            noError
            noLabel
            value={inputValues.from}
            onChange={val => onInputChange('from-time', val)}
            onBlur={onFromBlur}
          />
        </CustomTimesWrapper>
      )}
      <RadioButton
        value="lessorequal"
        label={translate?.('table.filters.lowerOrEqualThan')}
        checked={value?.type === 'lessorequal'}
        onClick={() => selectFilterType({ type: 'lessorequal', values: [''] })}
        onChange={noop}
      />
      {value?.type === 'lessorequal' && (
        <CustomTimesWrapper>
          <PatternInput
            autoFocus
            pattern={timeFormat}
            id="to"
            noError
            noLabel
            value={inputValues.to}
            onChange={val => onInputChange('to-time', val)}
            onBlur={onToBlur}
          />
        </CustomTimesWrapper>
      )}
      <RadioButton
        value="match"
        label={translate?.('table.filters.matchTime')}
        checked={value?.type === 'match'}
        onChange={noop}
        onClick={() => selectFilterType({ type: 'match', values: [''] })}
      />
      {value?.type === 'match' && (
        <CustomTimesWrapper>
          <PatternInput
            autoFocus
            pattern={timeFormat}
            id="match"
            noError
            noLabel
            value={inputValues.match}
            onChange={val => onInputChange('match', val)}
            onBlur={onMatchBlur}
          />
        </CustomTimesWrapper>
      )}
      <RadioButton
        value="range"
        label={translate?.('table.filters.range')}
        checked={value?.type === 'range'}
        onChange={noop}
        onClick={() => selectFilterType({ type: 'range', from: '', to: '' })}
      />
      {value?.type === 'range' && (
        <CustomTimesWrapper>
          <PatternInput
            autoFocus
            pattern={timeFormat}
            id="from-time"
            noError
            label={translate?.('table.filters.range.from')}
            value={inputValues.from}
            onChange={val => onInputChange('from-time', val)}
            onBlur={onRangeBlur}
          />
          <PatternInput
            pattern={timeFormat}
            id="to-time"
            noError
            label={translate?.('table.filters.range.to')}
            value={inputValues.to}
            onChange={val => onInputChange('to-time', val)}
            onBlur={onRangeBlur}
          />
        </CustomTimesWrapper>
      )}
    </TimeFiltersWrapper>
  );
}
