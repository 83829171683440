import { FormattedMessage, IntlProvider } from 'react-intl';
import {
  ContentBox,
  ContentBoxHead,
  IntegratorRoot
} from '@yarmill/components';
import { translations } from './translations';
import styled from 'styled-components';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';

const DisabledInstanceWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CenteredContentBox = styled(ContentBox)`
  max-width: 720px;
  white-space: pre-wrap;
`;

export function DisabledInstance(): JSX.Element {
  const locale = 'cs';

  return (
    <IntlProvider locale={locale} messages={translations[locale]}>
      <IntegratorRoot>
        <DisabledInstanceWrapper>
          <CenteredContentBox>
            <ContentBoxHead>
              <FormattedMessage id="disabledInstance.header" />
            </ContentBoxHead>
            <FormattedHTMLMessage id="disabledInstance.text" />
          </CenteredContentBox>
        </DisabledInstanceWrapper>
      </IntegratorRoot>
    </IntlProvider>
  );
}
