import { Button, ButtonAppearance } from '../../components-2/button';
import { Icon, IconSize } from '@yarmill/components';
import { useIntl } from 'react-intl';
import { Cancel } from '@yarmill/icons-2';

interface CloseEventPanelProps {
  readonly close: () => void;
}
export function CloseEventPanel({ close }: CloseEventPanelProps): JSX.Element {
  const intl = useIntl();
  return (
    <Button
      $appearance={ButtonAppearance.Secondary}
      $appearanceStyle="white"
      $iconOnly
      onClick={close}
      type="button"
      aria-label={intl.formatMessage({
        id: 'sidebar.close'
      })}
    >
      <Icon size={IconSize.s24}>
        <Cancel />
      </Icon>
    </Button>
  );
}
