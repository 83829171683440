import { Bar, Line } from '@visx/shape';
import { styled } from '../../theme-provider';
import { useXYChartContext } from '../xy-chart-context';
import { useTooltipContext } from '../hooks/use-tooltip';
import { useRef, MouseEvent, useCallback } from 'react';
import { localPoint } from '@visx/event';
import { TOOLTIP_TIMEOUT } from '../../reporting/const';
import { TooltipData } from '../../reporting/types';

const TooltipBar = styled(Bar)`
  fill: transparent;
`;

export function ContinuousTooltip() {
  const { xScale, chartRect } = useXYChartContext();
  const { tooltipLeft, hideTooltip, tooltipOpen, showTooltip, getTooltipData } =
    useTooltipContext();
  const tooltipTimeout = useRef<number>(0);

  if ('bandwidth' in xScale) {
    throw new Error('Continuous tooltip requires continuous scale');
  }

  const handleMouseLeave = useCallback(() => {
    tooltipTimeout.current = window.setTimeout(
      () => hideTooltip(),
      TOOLTIP_TIMEOUT
    );
  }, [hideTooltip]);

  return (
    <>
      <TooltipBar
        width={chartRect.right - chartRect.left}
        x={chartRect.left}
        y={chartRect.top}
        height={chartRect.bottom - chartRect.top}
        onMouseMove={(event: MouseEvent) => {
          if (tooltipTimeout.current) {
            clearTimeout(tooltipTimeout.current);
          }

          const mousePosition = localPoint(event);
          const left = mousePosition?.x || 0;
          const axisDate = xScale.invert(left);
          const tooltipData: TooltipData = getTooltipData(axisDate, false);
          const tooltipTop = mousePosition?.y;

          showTooltip({
            tooltipData,
            tooltipTop,
            tooltipLeft: left
          });
        }}
        onMouseLeave={handleMouseLeave}
      />
      {tooltipOpen && (
        <Line
          from={{ x: tooltipLeft, y: chartRect.top }}
          to={{ x: tooltipLeft, y: chartRect.bottom }}
          stroke="#c2c2c2"
          strokeWidth={2}
          pointerEvents="none"
          strokeDasharray="2,2"
        />
      )}
    </>
  );
}
