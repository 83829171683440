import { Text, TextSize } from '@yarmill/components';
import * as React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

import { Video as IVideo } from './types';
import { getNormalizedString } from '../utils/get-normalized-string';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../app/root-store-context';
import { useVideoSelectorStore } from './video-selector-context';
import { useRef } from 'react';

export interface VideoProps {
  style: React.CSSProperties;
  video?: IVideo;
}

const StyledVideo = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  border-top: 1px solid #c2c2c2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:last-child {
    border-bottom: 1px solid #c2c2c2;
  }

  &:hover {
    background-color: #f8f9fa;
    color: #4a90e2;
  }
`;

const StyledHighlightedQuery = styled.span`
  background-color: #e9eff4;
  font-weight: bold;
`;

interface Chunk {
  start: number;
  end: number;
}

function escapeRegex(regexString: string): string {
  return regexString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function findChunks(fileName: string, queryTerms: string[]): Chunk[] {
  let chunks: Chunk[] = [];
  const normalizedFileName = getNormalizedString(fileName);

  queryTerms.forEach(term => {
    let result: RegExpExecArray | null;
    const regex = new RegExp(escapeRegex(getNormalizedString(term)), 'giu');
    while ((result = regex.exec(normalizedFileName))) {
      chunks.push({
        start: result.index,
        end: result.index + term.length
      });
    }
  });

  return chunks.sort((a, b) => a.start - b.start);
}

function getHighlightedFileName(
  fileName: string,
  searchQuery: string
): (JSX.Element | string)[] {
  const chunks = findChunks(fileName, searchQuery.split(' ').filter(Boolean));

  let lastIndex = 0;
  const stringParts: (JSX.Element | string)[] = [];
  chunks.forEach(chunk => {
    stringParts.push(fileName.slice(lastIndex, chunk.start));
    stringParts.push(
      <StyledHighlightedQuery key={stringParts.length}>
        {fileName.slice(chunk.start, chunk.end)}
      </StyledHighlightedQuery>
    );
    lastIndex = chunk.end;
  });

  stringParts.push(fileName.slice(lastIndex, fileName.length));

  return stringParts;
}

const loaders = [200, 250, 300, 350, 400, 450, 500];

export const Video = observer(function Video(props: VideoProps): JSX.Element {
  const { video, style } = props;
  const videoSelectorService = useRootStore().videoSelectorService;
  const videoSelectorStore = useVideoSelectorStore();
  const searchQuery = videoSelectorStore.highlightedSearchTerm;
  const loaderWidth = useRef(
    loaders[Math.floor(loaders.length * Math.random())]
  ).current;

  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    if (video) {
      videoSelectorStore.doneCallback();
      setTimeout(() => {
        videoSelectorService.callback?.(video);
        videoSelectorService.closeVideoSelector();
      });
    }
  };

  return (
    <StyledVideo
      style={style}
      onClick={handleClick}
      title={video ? video.FileName : undefined}
    >
      {video ? (
        <Text size={TextSize.s12}>
          {searchQuery
            ? getHighlightedFileName(video.FileName, searchQuery)
            : video.FileName}
        </Text>
      ) : (
        <ContentLoader
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          height={14}
          width={loaderWidth}
        >
          <rect x={0} y={0} rx={4} ry={4} width="100%" height="100%" />
        </ContentLoader>
      )}
    </StyledVideo>
  );
});
