import styled from 'styled-components';
import { PropsWithChildren } from 'react';

export * from './logo';
export * from './main-navigation';
export * from './current-user-navigation';
export * from './dropdown';

const StyledTopBar = styled.nav`
  width: 100vw;
  height: 55px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  z-index: 3;

  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  @media (min-width: 768px) {
    grid-template-columns: auto 1fr auto;
    height: 80px;
  }
`;

const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 0;
  text-align: right;
  height: 55px;
  min-width: 55px;

  @media (min-width: 768px) {
    min-width: 80px;
    z-index: 1;
    height: 80px;
  }
`;

export const NavigationLayout = styled.div<{ readonly $isHidden?: boolean }>`
  flex-grow: 1;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};

  @media screen and (min-width: 768px) {
    overflow-x: hidden;
    pointer-events: none;

    & > * {
      pointer-events: auto;
    }
  }
`;

type TopBarProps = PropsWithChildren<{
  readonly logo: JSX.Element;
  readonly rightSection?: JSX.Element;
}>;

export function TopBar({
  logo,
  rightSection,
  children
}: TopBarProps): JSX.Element {
  return (
    <StyledTopBar>
      {logo}
      {children}
      <RightSection>{rightSection}</RightSection>
    </StyledTopBar>
  );
}
