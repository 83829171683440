import {
  OVERVIEW_TABLE_CELL_SIZE,
  OVERVIEW_TABLE_LABEL_WIDTH,
  OVERVIEW_TABLE_MIN_COL_GAP,
  OverviewTableDataFormatter
} from './utils';
import styled from 'styled-components';

export interface RowLabelProps {
  formatter?: OverviewTableDataFormatter;
  data: string;
  rowNumber: number;
  columnsCount: number;
}

interface LabelWrapperProps {
  row: number;
  columnsCount: number;
}

const LabelWrapper = styled.div<LabelWrapperProps>`
  grid-row: ${props => `${props.row + 1} / ${props.row + 2}`};
  grid-column: 1;
  padding: 5px 5px 5px 0;
  position: sticky;
  left: 0;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  height: ${OVERVIEW_TABLE_CELL_SIZE};

  @media (max-width: ${({ columnsCount }) =>
      columnsCount * OVERVIEW_TABLE_CELL_SIZE +
      OVERVIEW_TABLE_LABEL_WIDTH +
      columnsCount * OVERVIEW_TABLE_MIN_COL_GAP}px) {
    background-color: #ffffff;
  }
`;

export function RowLabel(props: RowLabelProps): JSX.Element {
  const { formatter, data, rowNumber, columnsCount } = props;

  return (
    <LabelWrapper row={rowNumber} columnsCount={columnsCount}>
      {formatter ? formatter(data) : data}
    </LabelWrapper>
  );
}
