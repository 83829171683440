import { ActivityInput } from '@yarmill/components';
import { ActivityData, ActivityItemId } from '../activities/types';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import {
  formatValueByUnit,
  getDisabledReasonMessage
} from '../activities/utils';
import { SingleSeasonEvaluationStore } from './mobx/single-season-evaluation-store';
import { Tippy } from '../components/tippy/tippy';
import { SeasonEvaluationDiaryType } from './types';

export interface SeasonEvaluationValueProps {
  activityItemId: ActivityItemId;
  day: string | null;
  diaryType: SeasonEvaluationDiaryType;
  store: SingleSeasonEvaluationStore;
}

const StyledSeasonEvaluationValue = styled(ActivityInput)`
  min-width: 65px;
  cursor: default;
`;

function InternalSeasonEvaluationValue(
  props: SeasonEvaluationValueProps
): JSX.Element {
  const { activityItemId, day, store, diaryType } = props;
  let value: ActivityData | undefined;
  const activityItem = store.getActivityItem(activityItemId);

  switch (diaryType) {
    case 'annualPlan':
      value = store.getAnnualPlanValue(activityItemId, day);
      break;
    case 'plan':
      value = store.getPlanValue(activityItemId, day);
      break;
    case 'reality':
      value = store.getRealityValue(activityItemId, day);
      break;
  }

  const formattedValue = activityItem
    ? formatValueByUnit(value?.Value, activityItem.Unit)
    : value?.Value;

  return (
    <Tippy tooltipContent={getDisabledReasonMessage('computed-value')}>
      <StyledSeasonEvaluationValue
        value={formattedValue}
        readOnly
        disabled={Boolean(activityItem?.ChildrenActivityItemIds.length)}
      />
    </Tippy>
  );
}

export const SingleSeasonEvaluationValue = observer(
  InternalSeasonEvaluationValue
);
