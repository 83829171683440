import { RootStore } from '../../app/mobx/root-store';
import {
  action,
  computed,
  IObservableArray,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  runInAction,
  when
} from 'mobx';
import { SeasonStore } from '../../seasons/mobx/season-store';
import {
  ATHLETE_SEARCH_PARAM,
  GROUP_SEARCH_PARAM,
  WEEK_SEARCH_PARAM
} from '../../routes/types';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import { UserStore } from '../../users/mobx/user-store';
import { GroupStore } from '../../groups/mobx/group-store';
import { PlannerEventStore } from './planner-event-store';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import { getEvents } from '../api/get-events';
import {
  PlannerAttributeDefinition,
  PlannerEvent,
  PlannerEventFormik,
  PlannerEventSet,
  PlannerEventType
} from '../types';
import { deleteEvent } from '../api/delete-event';
import { updateEvent } from '../api/update-event';
import {
  calculateMaxOverlappingCount,
  getEventsConflictsTree,
  getFirstAvailableOffset,
  hasOverlap
} from '../utils';
import moment from 'moment';
import { sortByDate } from '../../app/utils';
import { createEvent } from '../api/create-event';
import { getDefinition } from '../api/get-definition';
import { publish } from '../api/publish';
import { TextInputAppearance } from '../../components-2/text-input';
import { importEvents } from '../api/import-events';
import {
  MAX_PLANNER_ZOOM_LEVEL,
  PlannerZoomLevel
} from '../../#-components/planner/utils';
import { DATE_FORMAT } from '@yarmill/components';
import { ROUTE_DATE_FORMAT } from '../../diary/utils';

export class PlannerStore {
  private readonly _rootStore: RootStore;

  @observable
  public athleteId: UserId | null = null;

  @observable
  public groupId: UserGroupId | null = null;

  @observable
  public week: string = '';

  @observable
  private _currentSeason: SeasonStore | undefined = undefined;

  @observable
  private _zoomLevel: PlannerZoomLevel = 1;

  @observable
  private _events: IObservableArray<PlannerEventStore> = observable.array();

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  @observable
  private request: RequestStore<PlannerEvent[]> | null = null;

  @observable
  private _eventTypes: IObservableArray<PlannerEventType> = observable.array();
  @observable
  private _attributes: IObservableArray<PlannerAttributeDefinition> =
    observable.array();

  @observable
  private _eventSets: IObservableArray<PlannerEventSet> = observable.array();

  @observable
  private _eventTypeFilter: IObservableArray<PlannerEventType['eventTypeId']> =
    observable.array();

  @observable
  private _savingEvents: IObservableArray<PlannerEventStore> =
    observable.array();

  @observable
  public formEvent: PlannerEventStore | null = null;

  @observable
  public calendarFormEvent: PlannerEventStore | null = null;

  @observable
  public formik: PlannerEventFormik | null = null;

  @observable
  public showImporter: boolean = false;

  @observable
  public scrollToEvent: number | null | 'form-event' = null;

  public lastEventType: string | null = null;

  public closeFormAfterSave = true;

  private reactions: IReactionDisposer[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this._rootStore = rootStore;
    when(
      () => rootStore.isReady,
      () => {
        this.loadDefinition();
        this.registerReactions();
      }
    );
  }

  public disposeReactions(): void {
    this.reactions.forEach(dispose => dispose());
  }

  @computed
  public get athlete(): UserStore | undefined {
    return this._rootStore.usersStore.getUserById(this.athleteId);
  }

  @computed
  public get group(): GroupStore | undefined {
    return this._rootStore.groupsStore.getGroupById(this.groupId);
  }

  public get currentSeason(): SeasonStore | undefined {
    return this._currentSeason;
  }

  public get zoomLevel(): PlannerZoomLevel {
    return this._zoomLevel;
  }

  public increaseZoomLevel(): void {
    if (this._zoomLevel < MAX_PLANNER_ZOOM_LEVEL) {
      this._zoomLevel++;
    }
  }

  public decreaseZoomLevel(): void {
    if (this._zoomLevel > 0) {
      this._zoomLevel--;
    }
  }

  @computed
  public get events(): Readonly<Array<PlannerEventStore>> {
    return this._events;
  }

  @computed
  public get isReady(): boolean {
    return this._status === AsyncStatus.resolved;
  }

  private registerReactions(): void {
    const observeGroupId = reaction(
      () => this._rootStore.historyService.searchParams.get(GROUP_SEARCH_PARAM),
      id => {
        this.groupId = id !== undefined ? Number(id) : null;
        this._events.clear();
      },
      {
        fireImmediately: true
      }
    );

    const observerAthleteId = reaction(
      () =>
        this._rootStore.historyService.searchParams.get(ATHLETE_SEARCH_PARAM),
      id => {
        this.athleteId = id !== undefined ? Number(id) : null;
        this._events.clear();
      },
      {
        fireImmediately: true
      }
    );

    const observeWeek = reaction(
      () => this._rootStore.historyService.searchParams.get(WEEK_SEARCH_PARAM),
      week => {
        this.week = week || '';
        this._currentSeason = this._rootStore.seasonsStore.getSeasonByWeek(
          this.week
        );
        this._events.clear();
      },
      {
        fireImmediately: true
      }
    );

    const dataLoader = reaction(
      () => ({
        athleteId: this.athleteId,
        groupId: this.groupId,
        week: this.week
      }),
      () => {
        this.loadEvents();
      },
      {
        fireImmediately: true
      }
    );

    this.reactions.push(
      observerAthleteId,
      observeGroupId,
      observeWeek,
      dataLoader
    );
  }

  private async loadEvents(): Promise<void> {
    this._status = AsyncStatus.pending;
    if (this.request) {
      this.request.cancel();
      this._rootStore.requestsStore.removeRequest(this.request);
      this.request = null;
    }

    const athleteId = this.athleteId;
    const userGroupId = this.groupId;
    const season = this.currentSeason;

    if ((!athleteId && !userGroupId) || !season) {
      return;
    }

    this.request = this._rootStore.requestsStore.createRequest(cancelToken =>
      getEvents(
        {
          userId: athleteId || undefined,
          userGroupId: athleteId ? undefined : userGroupId || undefined,
          startDate: moment(season.startDate)
            .startOf('month')
            .format(DATE_FORMAT),
          endDate: moment(season.endDate).endOf('month').format(DATE_FORMAT)
        },
        cancelToken
      )
    );

    const response = await this.request.getResponse();

    if (response) {
      runInAction(() => {
        this._events.clear();
        this._events.replace(
          response.map(event => {
            const store = new PlannerEventStore();
            store
              .setId(event.id)
              .setTitle(event.title)
              .setStartDate(event.startDate)
              .setEndDate(event.endDate)
              .setEventTypeId(event.eventTypeId)
              .setNotes(event.notes)
              .setLocation(event.location)
              .setLocationType(event.locationType)
              .setOtherSubscribersCount(event.otherSubscribersCount)
              .setAttributes(event.attributes)
              .addUsers(
                event.users
                  .map(userId => this._rootStore.usersStore.getUserById(userId))
                  .filter<UserStore>((u): u is UserStore => Boolean(u))
              )
              .setIsEditable(event.isEditable)
              .setIsRemovable(event.isRemovable)
              .setIsLocked(event.isLocked)
              .setIsAttendeesEditable(event.isAttendeesEditable);

            return store;
          })
        );
        this.sortEvents();
        this.calculateLayout();
        this._status = AsyncStatus.resolved;
      });
    } else {
      this._status = AsyncStatus.rejected;
    }
  }

  private async loadDefinition(): Promise<void> {
    const request = this._rootStore.requestsStore.createRequest(cancelToken =>
      getDefinition(cancelToken)
    );

    const response = await request.getResponse();

    if (response) {
      runInAction(() => {
        this._eventTypes.clear();
        this._attributes.clear();
        this._eventSets.clear();
        this._eventTypes.push(...response.eventTypes);
        this._eventTypeFilter.push(
          ...response.eventTypes.map(et => et.eventTypeId)
        );
        this._attributes.push(...response.attributes);
        this._eventSets.push(...response.eventSets);
      });
    }
  }

  public async reloadData(): Promise<void> {
    await this.loadEvents();
  }

  public getEvent(id: number): PlannerEventStore | undefined {
    return this._events.find(o => o.id === id);
  }

  public addEvent(event: PlannerEventStore): this {
    this._events.push(event);
    this.sortEvents();
    this.calculateLayout();
    return this;
  }

  public sortEvents(): this {
    this._events.sort((a, b) =>
      sortByDate(b.startDate ?? undefined, a.startDate ?? undefined)
    );

    return this;
  }

  public async removeEvent(event: PlannerEventStore): Promise<void> {
    this._events.remove(event);
    this.calculateLayout();
    const eventId = event.id;
    const userId = this.athleteId;
    const userGroupId = this.groupId;

    if (!eventId) {
      return;
    }

    const request = this._rootStore.requestsStore.createRequest(cancelToken =>
      deleteEvent(
        {
          eventId,
          userId,
          userGroupId
        },
        cancelToken
      )
    );

    await request.getResponse();
  }

  public publish(): RequestStore<boolean> | false {
    const userId = this.athleteId;
    const userGroupId = this.groupId;
    const season = this.currentSeason;

    if (!season || (!userId && !userGroupId)) {
      return false;
    }

    return this._rootStore.requestsStore.createRequest(cancelToken =>
      publish(
        {
          userId: userId || undefined,
          userGroupId: userId ? undefined : userGroupId || undefined,
          startDate: season.startDate,
          endDate: season.endDate
        },
        cancelToken
      )
    );
  }

  public importEvents(eventIds: number[]): RequestStore<boolean> | false {
    const userId = this.athleteId;
    const userGroupId = this.groupId;

    if (!userId && !userGroupId) {
      return false;
    }

    return this._rootStore.requestsStore.createRequest(cancelToken =>
      importEvents(
        {
          userId: userId || undefined,
          userGroupId: userId ? undefined : userGroupId || undefined,
          eventIds
        },
        cancelToken
      )
    );
  }

  @action
  public async saveEvent(
    event: PlannerEventStore,
    userGroupId: number | null,
    userId: number | null,
    saveAttempt: number = 0
  ): Promise<void> {
    const eventData = event.toJS();
    const season = this.currentSeason;
    if (!season || (!userId && !userGroupId)) {
      return;
    }

    if (!this._savingEvents.includes(event)) {
      this._savingEvents.push(event);
    }

    const params = {
      userId: userId || undefined,
      userGroupId: userId ? undefined : userGroupId || undefined,
      event: eventData
    };

    const request = this._rootStore.requestsStore.createRequest(cancelToken =>
      eventData.id
        ? updateEvent(params, cancelToken)
        : createEvent(params, cancelToken)
    );

    const response = await request.getResponse();
    if (request.statusCode === 200 && response) {
      event.setId(response.id);
      event.setLastUpdateDate(response.lastUpdateDate);
      event.hasPendingUpdate = false;
      event.saveFailed = false;
      this._savingEvents.remove(event);

      const currentViewStart = this.currentSeason?.start
        .clone()
        .startOf('month');
      const currentViewEnd = this.currentSeason?.end.clone().endOf('month');

      if (
        !eventData.id &&
        eventData.startDate &&
        (event.start.isBefore(currentViewStart) ||
          event.start.isAfter(currentViewEnd))
      ) {
        const season = this._rootStore.seasonsStore.getSeasonByDate(
          eventData.startDate
        );
        if (season) {
          this._rootStore.historyService.updateSearchParams([
            {
              key: WEEK_SEARCH_PARAM,
              value: season.start.format(ROUTE_DATE_FORMAT)
            }
          ]);
        }
        this.scrollToEvent = response.id;
      }
    } else {
      event.saveFailed = true;

      if (saveAttempt < 5) {
        setTimeout(() => {
          this.saveEvent(event, userGroupId, userId, saveAttempt + 1);
        }, 2000 * saveAttempt + 1);
      }
    }
  }

  @computed
  public get eventTypes() {
    return this._eventTypes.slice();
  }

  @computed
  public get eventSets() {
    return this._eventSets.slice();
  }

  public getEventTypeColor(
    eventTypeId: string | null
  ): TextInputAppearance | undefined {
    return this.eventTypes.find(type => type.eventTypeId === eventTypeId)
      ?.color;
  }

  public get groupedAttributes() {
    return this._attributes;
  }

  @computed
  public get attributeValues() {
    return this._attributes.flatMap(attribute => attribute.values);
  }

  @computed
  public get eventOverlapsPerMonths(): number[] {
    return (
      this.currentSeason?.seasonMonths.map(month => {
        const monthStart = moment(month).startOf('month');
        const monthEnd = moment(month).endOf('month');

        const eventsInMonth = this.events.filter(event =>
          hasOverlap(event, {
            start: monthStart,
            end: monthEnd
          })
        );

        const overlaps = Math.max(
          ...eventsInMonth.map(e => e.layout.conflictingEvents)
        );

        return overlaps;
      }) ?? []
    );
  }

  @action
  public calculateLayout(): void {
    this._events.forEach(event => {
      const conflictingEvents = this._events.filter(
        otherEvent => event !== otherEvent && hasOverlap(event, otherEvent)
      );
      const conflictsTreeEvents = getEventsConflictsTree(
        conflictingEvents,
        this._events
      );
      const conflictsMap = conflictsTreeEvents.map(e => {
        const ce = this._events.filter(
          otherEvent => e !== otherEvent && hasOverlap(e, otherEvent)
        );

        const [count] = calculateMaxOverlappingCount([e, ...ce]);
        return count;
      });

      const conflictingEventsAtSameTime = Math.max(...conflictsMap);

      const previousConflictingEvents = conflictingEvents.filter(dce =>
        dce.start.isSameOrBefore(event.start)
      );

      const offset = getFirstAvailableOffset(previousConflictingEvents);

      event.layout.conflictingEvents =
        conflictingEventsAtSameTime > 1 ? conflictingEventsAtSameTime : 0;
      event.layout.offset = offset;
    });
  }

  public setEventTypeFilter(eventTypes: string[]): void {
    this._eventTypeFilter.replace(eventTypes);
  }

  public get eventTypeFilter(): string[] {
    return this._eventTypeFilter;
  }

  @computed
  public get savingStatus(): AsyncStatus {
    if (this._savingEvents.length === 0) {
      return AsyncStatus.resolved;
    }

    if (this._savingEvents.find(e => e.saveFailed)) {
      return AsyncStatus.rejected;
    }

    if (this._savingEvents.find(e => e.hasPendingUpdate)) {
      return AsyncStatus.pending;
    }

    return AsyncStatus.idle;
  }
}
