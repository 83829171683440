import { useScale } from '../hooks/use-scale';
import { groupStackedBarsByCategories } from './group-stacked-bars-by-categories';
import { ArrayElement } from '../../reporting/types';

export function sortStackerBarsByValue(
  categoryBars: ArrayElement<ReturnType<typeof groupStackedBarsByCategories>>,
  yScale: ReturnType<typeof useScale>
) {
  const categoryHeight = categoryBars.reduce((sum, item) => {
    sum = sum + item.height;
    return sum;
  }, 0);

  const sortedBars = categoryBars.sort((a, b) => b.height - a.height);

  return sortedBars.map((bar, barIdx) => ({
    ...bar,
    y:
      // sum all previous bars
      categoryBars.reduce((sum, item, idx) => {
        if (idx < barIdx) {
          sum = sum + item.height;
        }
        return sum;
      }, 0) +
      yScale.range()[0] -
      categoryHeight
  }));
}
