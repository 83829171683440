import { Category } from '../components-2/category';
import { OkrRootCategory, OkrCategory as OkrCategoryType } from './types';
import { IntlShape, useIntl } from 'react-intl';
import { TextInputProps } from '../components-2/text-input';

const categoryAppearances: Record<
  OkrRootCategory,
  TextInputProps['appearance']
> = {
  conditions: 'sand',
  fitness: 'sand',
  health: 'sand',
  personal: 'sand',
  performance: 'sand',
  technical: 'sand'
};
const defaultAppearance: TextInputProps['appearance'] = 'sand';
interface OkrCategoryProps {
  readonly category: OkrCategoryType;
  readonly limitWidth?: boolean;
  readonly showOnlySubcategory?: boolean;
}

export function getAppearanceForCategory(
  category: OkrCategoryType
): TextInputProps['appearance'] {
  const rootCategory: OkrRootCategory = category.split(
    '.'
  )[0] as OkrRootCategory;
  return categoryAppearances[rootCategory] ?? defaultAppearance;
}

export function getRootCategory(category: OkrCategoryType): OkrRootCategory {
  const categorySplit = category.split('.');
  return categorySplit[0] as OkrRootCategory;
}

export function translateCategory(category: string, intl: IntlShape): string[] {
  const categorySplit = category.split('.');
  const rootCategory = categorySplit[0];
  const translateKeys = [`okrs.category.${rootCategory}`];

  if (categorySplit.length > 1) {
    categorySplit.forEach((c, i) => {
      if (i === 0) {
        // we already have root category
        return;
      }
      translateKeys.push(`${translateKeys[i - 1]}.${c}`);
    });
  }

  return translateKeys.map(key => intl.formatMessage({ id: key }));
}

export function OkrCategory({
  category,
  limitWidth,
  showOnlySubcategory
}: OkrCategoryProps): JSX.Element {
  const intl = useIntl();
  const translatedCategory = translateCategory(category, intl);

  return (
    <Category
      category={
        showOnlySubcategory
          ? translatedCategory[translatedCategory.length - 1]
          : translatedCategory
      }
      appearance={getAppearanceForCategory(category)}
      limitWidth={limitWidth}
    />
  );
}
