import moment from 'moment/moment';
import { PlannerEventStore } from './mobx/planner-event-store';
import { PlannerEventFormStep } from './types';
import { FormattedMessage } from 'react-intl';
import { DATE_FORMAT } from '@yarmill/components';

export function hasOverlap<
  E extends { readonly start: moment.Moment; readonly end: moment.Moment }
>(event1: PlannerEventStore | E, event2: PlannerEventStore | E) {
  return (
    (event1.start.isSameOrBefore(event2.end) &&
      event1.end.isSameOrAfter(event2.start)) ||
    (event2.start.isSameOrBefore(event1.end) &&
      event2.end.isSameOrAfter(event1.start))
  );
}

export function getFormTitle(
  currentStep: PlannerEventFormStep,
  event?: PlannerEventStore | null
): JSX.Element {
  switch (currentStep) {
    case 'attendees':
      return event?.isEditable || event?.isAttendeesEditable ? (
        <FormattedMessage id="planner.form.addAttendees.headline" />
      ) : (
        <FormattedMessage id="planner.form.attendees.headline" />
      );
    case 'attributes':
      return <FormattedMessage id="planner.form.attributes.headline" />;
    case 'date':
      return <FormattedMessage id="planner.form.date.headline" />;
    case 'eventType':
      return <FormattedMessage id="planner.form.eventType.headline" />;
    case 'location':
      return <FormattedMessage id="planner.form.location.headline" />;
    case 'details':
    default:
      return event?.id ? (
        event.isEditable || event.isAttendeesEditable ? (
          <FormattedMessage id="planner.editEvent.headline" />
        ) : (
          <FormattedMessage
            id="planner.event.headline"
            values={{ isLocked: event.isLocked }}
          />
        )
      ) : (
        <FormattedMessage id="planner.newEvent.headline" />
      );
  }
}

export function calculateMaxOverlappingCount(
  events: PlannerEventStore[]
): [number, {}] {
  let maxOverlappingCount = 0;

  // Create an array to store the count of overlapping events at each time point
  const overlappingCounts = {};
  const overlappingEvents = {};

  events.forEach(event => {
    const current = event.start.clone();
    while (!current.isAfter(event.endDate, 'day')) {
      const key = current.format(DATE_FORMAT);
      overlappingCounts[key] = (overlappingCounts[key] || 0) + 1;

      if (overlappingEvents[key]) {
        overlappingEvents[key].push(event);
      } else {
        overlappingEvents[key] = [event];
      }

      // Update the maximum overlapping count
      if (overlappingCounts[key] > maxOverlappingCount) {
        maxOverlappingCount = overlappingCounts[key];
      }
      current.add(1, 'day');
    }
  });

  return [maxOverlappingCount, overlappingEvents];
}

export function getEventsConflictsTree(
  directConflictingEvents: PlannerEventStore[],
  allEvents: PlannerEventStore[]
) {
  let prevRoundConflicts = directConflictingEvents.length;
  let conflictingEvents = directConflictingEvents;

  do {
    prevRoundConflicts = conflictingEvents.length;
    conflictingEvents = [
      ...new Set(
        conflictingEvents.flatMap(event =>
          allEvents.filter(otherEvent => hasOverlap(event, otherEvent))
        )
      )
    ];
  } while (prevRoundConflicts !== conflictingEvents.length);

  return conflictingEvents;
}

export function getFirstAvailableOffset(
  previousConflictingEvents: PlannerEventStore[]
): number {
  let offset = 0;
  const previousOffsets = previousConflictingEvents.map(e => e.layout.offset);

  while (previousOffsets.includes(offset) && offset < previousOffsets.length) {
    offset++;
  }

  return offset;
}

export function copyPlannerEventData(
  source: PlannerEventStore,
  target: PlannerEventStore
): void {
  target
    .setEventTypeId(source.eventTypeId)
    .setTitle(source.title)
    .setStartDate(source.startDate)
    .setEndDate(source.endDate)
    .setId(source.id)
    .setUid(source.uid)
    .setIsEditable(source.isEditable)
    .setIsRemovable(source.isRemovable)
    .setIsLocked(source.isLocked)
    .setIsAttendeesEditable(source.isAttendeesEditable);
  target.layout = source.layout;
}
