import { ReportingSection as IReportingSection } from './types';
import {
  Icon,
  IconSize,
  ReportingSection,
  ReportingSectionIconWrapper,
  Text,
  TextSize
} from '@yarmill/components';
import { Report } from './report';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import { calculateSectionRatio, getIcon } from './utils';
import { createContext, useContext, useMemo } from 'react';
import { useSectionSubgrid } from './hooks/use-section-subgrid';
import { useReportingDataStore } from './hooks/use-reporting-data-store';
import { observer } from 'mobx-react-lite';
import { AsyncStatus } from '../api/mobx/request-store';
import { useReportingStore } from './hooks/use-reporting-store';

export interface SectionProps {
  sectionDefinition: IReportingSection;
  subSections?: IReportingSection[];
}

interface ReportingSectionContextValue {
  readonly isHighlighted: boolean;
}

export const ReportingSectionContext =
  createContext<ReportingSectionContextValue>({ isHighlighted: false });

export const Section = observer(function Section(
  props: SectionProps
): JSX.Element | null {
  const { sectionDefinition, subSections } = props;
  const { isHighlighted: isParentSectionHighlighted } = useContext(
    ReportingSectionContext
  );
  const hasIcon = sectionDefinition.Icon || sectionDefinition.TextIcon;
  const reportingStore = useReportingStore();
  const pageReports = reportingStore.pageReports;
  const isSubSection = Boolean(sectionDefinition.ParentSection);
  const reports = useMemo(
    () =>
      pageReports?.filter(report =>
        sectionDefinition.Layout?.split(' ').includes(report.code)
      ),
    [pageReports, sectionDefinition.Layout]
  );
  const useSubgrid = useSectionSubgrid(reports);
  const dataStore = useReportingDataStore();
  const hideWithoutData = Boolean(sectionDefinition.Params?.HideWithoutData);
  const hasData =
    dataStore?.status !== AsyncStatus.pending &&
    reports.some(report => {
      const data = dataStore?.getReportData(report.code)?.Data;
      return Array.isArray(data) ? data.length !== 0 : Boolean(data);
    });

  const sectionContextValue: ReportingSectionContextValue = useMemo(
    () => ({
      isHighlighted: Boolean(
        sectionDefinition.IsHighlighted || isParentSectionHighlighted
      )
    }),
    [sectionDefinition, isParentSectionHighlighted]
  );

  if (hideWithoutData && !hasData) {
    return null;
  }

  return (
    <ReportingSectionContext.Provider value={sectionContextValue}>
      <ReportingSection
        title={
          hasIcon || sectionDefinition.Title ? (
            <>
              {hasIcon && (
                <ReportingSectionIconWrapper>
                  <Icon size={IconSize.s24}>
                    {sectionDefinition.TextIcon ? (
                      <Text size={TextSize.s16}>
                        {sectionDefinition.TextIcon}
                      </Text>
                    ) : (
                      getIcon(sectionDefinition.Icon)
                    )}
                  </Icon>
                </ReportingSectionIconWrapper>
              )}
              {sectionDefinition.Title && (
                <FormattedHTMLMessage id={sectionDefinition.Title} />
              )}
            </>
          ) : undefined
        }
        description={
          sectionDefinition.Description ? (
            <FormattedHTMLMessage id={sectionDefinition.Description} />
          ) : undefined
        }
        layoutConfig={sectionDefinition.Layout?.trim() ?? ''}
        isHighlighted={sectionDefinition.IsHighlighted}
        isSubSection={isSubSection}
        type={sectionDefinition.Type}
        useSubgrid={useSubgrid}
      >
        {reports.map(report => (
          <Report
            report={report}
            key={report.code}
            sectionArea={report.code}
            sectionRatio={calculateSectionRatio(
              sectionDefinition.Layout ?? '',
              report.code
            )}
            useSubgrid={useSubgrid}
          />
        ))}
        {subSections?.map((subSection, idx) => (
          <Section
            key={`${sectionDefinition.Code}-${subSection.Code ?? idx}`}
            sectionDefinition={subSection}
          />
        ))}
      </ReportingSection>
    </ReportingSectionContext.Provider>
  );
});
