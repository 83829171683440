import { computed, observable, makeObservable, override } from 'mobx';
import { AbstractTrainingDayAttributeStore } from './abstract-training-day-attribute-store';
import { RootStore } from '../../app/mobx/root-store';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { TrainingDayStore } from '../../training-day/mobx/training-day-store';
import {
  InputArrayData,
  InputArrayValue,
  TrainingDayAttribute,
  TrainingDayData
} from '../../training-day/types';
import { InputStore } from './input-store';
import { times } from '../../utils/times';
import { DiaryDataUniqueId } from '../../diary/types';

export class InputArrayAttributeStore extends AbstractTrainingDayAttributeStore {
  @observable
  public readonly _inputs: InputStore[];

  protected userValue: InputArrayValue | null = null;

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    trainingDayStore: TrainingDayStore,
    attribute: TrainingDayAttribute,
    dataId: DiaryDataUniqueId
  ) {
    super(rootStore, diaryStore, trainingDayStore, attribute, dataId);
    makeObservable(this);
    this._inputs = times(attribute.Size).map((_, idx) => new InputStore(idx));
  }

  public get size(): number {
    return this.attribute.Size;
  }

  @override
  public setApiValue(newValue: TrainingDayData) {
    const value = newValue as InputArrayData;
    this._inputs.forEach(input => {
      const apiValue = value.Value[input.index] || null;
      input.setApiValue(apiValue);
    });
  }

  public clear(): void {
    this._inputs.forEach(input => input.clear());
  }

  public get inputs(): InputStore[] {
    return this._inputs;
  }

  @computed
  protected get currentValue(): string[] {
    return this._inputs.map(input => input.currentValue || '');
  }

  protected get isNullValue(): boolean {
    const value = this._userValue;

    return value.every(
      inputValue =>
        inputValue === null ||
        inputValue.Value === 'null' ||
        inputValue.Value === null
    );
  }

  @computed
  protected get _userValue(): InputArrayValue {
    return this._inputs.map(input =>
      input.userValue !== null
        ? {
            Value: input.userValue,
            UpdatedBy: '',
            UpdatedDate: ''
          }
        : null
    );
  }

  @override
  public get hasValue(): boolean {
    return Boolean(
      this._userValue.length !== 0 && this._userValue.some(Boolean)
    );
  }
}
