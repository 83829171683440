import { RightPanelLayout } from '../components-2/right-panel';
import { useWindowSize } from '../utils/use-window-size';
import { ThemeProvider } from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { ImporterForm } from './importer/importer-form';

interface PlannerImportPanelProps {
  readonly isVisible: boolean;
  readonly close: () => void;
}
export const PlannerImportPanel = observer(function PlannerImportPanel({
  isVisible,
  close
}: PlannerImportPanelProps) {
  const { width: windowWidth } = useWindowSize();

  return (
    <ThemeProvider theme="main" dark>
      <RightPanelLayout
        windowWidth={windowWidth ?? 0}
        isVisible={isVisible}
        dark
      >
        {isVisible && <ImporterForm closeImporter={close} />}
      </RightPanelLayout>
    </ThemeProvider>
  );
});
