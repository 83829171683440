import { styled } from '@yarmill/components';
import { Text } from '../text';
import { css } from 'styled-components';
import { Button } from '../button';
import { OkrPriority } from '../../okrs/types';

const LIST_WIDTH = 320;
export const ObjectivesListSidebar = styled.div<{ readonly $isRoot: boolean }>`
  width: 100%;
  margin: 0 auto;
  display: ${({ $isRoot }) => ($isRoot ? 'flex' : 'none')};
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.white};
  flex-shrink: 0;

  @media (min-width: 768px) {
    display: flex;
    width: ${LIST_WIDTH}px;
    border-radius: ${({ theme }) => theme.borderRadius.x2};
    overflow: auto;
  }
`;

export const ObjectivesList = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding: ${({ theme }) => theme.size.x2} ${({ theme }) => theme.size.x1};
  background-color: ${({ theme }) => theme.color.neutralPlate};
`;

export const ObjectiveListItemLayout = styled.div<{
  readonly $isActive?: boolean;
  readonly $isNewObjectivePlaceholder?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: ${({ theme }) => theme.size.x1};
  padding: ${({ theme }) => theme.size.x15} ${({ theme }) => theme.size.x15} 0;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.x15};

  :hover {
    background-color: ${({ theme }) => theme.color.navy_8};
  }

  :focus-visible {
    outline: none;
  }

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.size.x05};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.navy};

      :hover {
        background-color: ${({ theme }) => theme.color.navyDark};
      }

      * {
        color: ${({ theme }) => theme.color.white} !important;
      }

      ${ObjectiveListItemContent} {
        border-bottom: 0;
      }
    `}

  ${({ $isNewObjectivePlaceholder }) =>
    $isNewObjectivePlaceholder &&
    css`
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.navy};
      cursor: default;

      :hover {
        background-color: ${({ theme }) => theme.color.navy};
      }

      * {
        color: ${({ theme }) => theme.color.navyDark} !important;
      }

      ${ObjectiveListItemTitle} {
        color: ${({ theme }) => theme.color.white} !important;
      }

      ${ObjectiveListItemContent} {
        border-bottom: 0;
      }
    `}
`;

export const ObjectiveListItemContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.size.x1};
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.neutral_8};
`;

export const ObjectiveListItemTitle = styled(Text)<{
  readonly priority?: OkrPriority | null;
}>`
  padding-left: ${({ theme }) => theme.size.x05};
  vertical-align: middle;
  text-indent: ${({ theme, priority }) =>
    priority !== 'low' ? theme.size.x3 : 0};
  position: relative;
`;

export const ObjectiveListItemPriorityWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 0;
  width: ${({ theme }) => theme.size.x3};
  height: ${({ theme }) => theme.size.x3};
`;

export const ObjectiveListKeyResultLayout = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.size.x05};
  padding-left: ${({ theme }) => theme.size.x05};
  color: ${({ theme }) => theme.color.neutral};
`;

export const ObjectiveListKeyResultTitle = styled(Text)<{
  readonly isStrikeThrough?: boolean;
}>`
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  text-decoration: ${({ isStrikeThrough }) =>
    isStrikeThrough ? 'line-through' : 'none'};
`;

export const ObjectiveFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: ${({ theme }) => theme.size.x15};
  padding: ${({ theme }) => theme.size.x1} ${({ theme }) => theme.size.x05}
    ${({ theme }) => theme.size.x15};
`;

export const MobileListHeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const FakeButton = styled(Button)`
  visibility: hidden;
  pointer-events: none;
`;
