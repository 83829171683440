export const sk = {
  'auth.email': 'E-mail',
  'auth.error.invalidLogin':
    '<strong> Krutibrko, tyhle prihlasovacie údaje nefungujú. </strong> <br> <div class = "tips"> <p> Skús skontrolovať, že: </p> <ul> <li> zadávaš email, na ktorý si sa registroval </li> <li> nemáš prepnutý jazyk klávesnice </li> <li> sa prihlasuješ do správneho tímu </li> </ul> </div>',
  'auth.error.userDisabled':
    '<strong> Už ťa do denníka nepustíme. Musíš poprosiť administrátora. </strong>',
  'auth.error.userNotActive':
    'Toto nejde. Najskôr sa musíš registrovať. Klikni na odkaz v pozvánke, ktorá ti prišla emailom.',
  'auth.forgottenPassword': 'Zabudnuté heslo',
  'auth.forgottenPasswordBack': 'Späť na prihlasovací formulár',
  'auth.forgottenPasswordSuccess':
    'Pokyny pre resetovanie hesla boli odoslané na váš e-mail',
  'auth.login.capsLock': 'Máš zapnutý Caps Lock',
  'auth.login.inactiveInstance':
    'Tu už Yarmill nie je 🥹. Napísať mu môžeš na <a href="mailto:hello@yarmill.com">hello@yarmill.com</a>',
  'auth.login.tips':
    '<p>Skús skontrolovať, že: </p> <ul> <li>zadávaš email, na ktorý si sa registroval</li> <li>nemáš prepnutý jazyk klávesnice</li> <li>sa prihlasuješ do správneho tímu</li> </ul>',
  'auth.loginButton': 'Prihlásiť sa',
  'auth.loginPageHeading': 'Prihlásenie',
  'auth.password': 'Heslo',
  'auth.submitForgottenPassword': 'Odoslať',
  'auth.username': 'E-mail',
  'fatalError.button.logout': 'Odhlásiť sa',
  'fatalError.button.refresh': 'Obnoviť',
  'fatalError.button.reset': 'Resetovať',
  'fatalError.description':
    'O chybe sme informovali náš technický tím. Ospravedlňujeme sa za prípadné nepríjemnosti. Ak nič z nižšie uvedeného nepomôže, napíš nám na <a href="mailto:support@yarmill.com">support@yarmill.com</a>.',
  'fatalError.headline': '😳 Nastal problém',
  'fatalError.resetClient.description':
    'Resetovanie obnoví celú aplikáciu a môže pomôcť vyriešiť problém.',
  'fatalError.resetClient.checkbox': 'Resetovať aplikáciu'
};
