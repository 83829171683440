export function formatDecimalNumber(
  value: number | string | null | undefined
): string {
  if (value === null || value === undefined) {
    return '';
  }

  const number = typeof value === 'string' ? Number(value) : value;

  return number.toLocaleString('cs', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  });
}
