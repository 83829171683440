import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import { useIntl } from 'react-intl';
import { ErrorBox, ExpiredTokenLayout } from '@yarmill/components';

export function ExpiredToken(): JSX.Element {
  const intl = useIntl();

  return (
    <ExpiredTokenLayout>
      <ErrorBox>
        <FormattedHTMLMessage
          id="app.tokenExpired"
          values={{
            adminEmail: intl.formatMessage({ id: 'ytd.instance.adminEmail' }),
            adminName: intl.formatMessage({ id: 'ytd.instance.adminName' })
          }}
        />
      </ErrorBox>
    </ExpiredTokenLayout>
  );
}
