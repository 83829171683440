import styled, { css } from 'styled-components';
import { ContentBox } from '../content-box';

export const TrainingDaysList = styled.div<{
  readonly isWeekSummaryPinned?: boolean;
}>`
  ${({ isWeekSummaryPinned }) => isWeekSummaryPinned && `margin-top: -16px`};
`;

export const TrainingDayContentBox = styled(ContentBox)`
  position: relative;
  padding: 10px 26px 16px;
  display: flex;
  row-gap: 10px;
  column-gap: 30px;
  flex-wrap: wrap;

  & + & {
    margin-top: 16px;
  }

  &:focus-within {
    border: 1px solid #4a90e2;
  }
`;

export const TrainingWeekSummaryContainer = styled.div<{
  readonly isPinned: boolean;
}>`
  background-color: #edf0f5;
  margin-bottom: 16px;

  ${props =>
    props.isPinned &&
    css`
      position: sticky;
      top: 60px;
      z-index: 10;
      padding-bottom: 16px;
      border-radius: 0px;

      @media (min-width: 768px) {
        top: 85px;
      }
      @media (min-width: 1024px) {
        top: 0px;
      }
    `}
`;

export const TrainingDayHeaderLayout = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

export const TrainingDayAttributeLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 4px;
`;

export const TrainingDayAttributeLabel = styled.label`
  font-family: Ubuntu;
  color: #4a4a4a;
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

export const RichtextAttributeEditorWrapper = styled.div<{
  readonly disabled: boolean;
}>`
  border: 1px solid #ced4da;
  border-radius: 4px;
  position: relative;
  ${props =>
    props.disabled
      ? `background-color: #f5f5f5;
         cursor: not-allowed;`
      : ''};
  :focus-within,
  :hover {
    border-color: #4a90e2;
  }
`;

export const InputArrayLayout = styled.div<{
  readonly colSize: number;
}>`
  display: inline-flex;
  flex-direction: column;
  row-gap: 4px;
  width: ${({ colSize }) => (colSize > 2 ? '100%' : 'calc(50% - 15px)')};

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  input {
    text-align: right;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:hover {
      border-color: #4a90e2;
    }

    &:focus {
      box-shadow: none;
      background: none;
    }
  }
`;

export const InputArrayInputsContainer = styled.div<{
  readonly colSize: number;
}>`
  display: grid;
  column-gap: 30px;
  row-gap: 15px;
  grid-template-columns: ${({ colSize }) =>
    `repeat(${colSize > 2 ? 4 : 2}, 1fr)`};
`;

export const SampleChartListLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
