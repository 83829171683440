import { FormattedMessage, useIntl } from 'react-intl';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { PlannerEvent } from '../types';
import { useFormikContext } from 'formik';
import { Search } from '@yarmill/icons-2';
import { IntegratedRightPanelInput } from '../../components-2/integrated-right-panel-input';
import { usePlannerStore } from '../planner-store-context';
import {
  SelectAllButton,
  SelectionList,
  SelectionListItem
} from '../../components-2/selection-list';
import { useUsersStore } from '../../users/hooks';

import { RoleAvatar } from '../../components-2/role-avatar';
import { AttendeesGroupHeading } from '../../#-components/planner/attendees';
import { UserId } from '../../users/types';
import { filterUserByName } from '../../users/utils/user-matches-table-filter';
import { UserStore } from '../../users/mobx/user-store';
import {
  FilteredRightPanelScrollContainer,
  RightPanelContent
} from '../../components-2/right-panel';
import { FormStepHeader } from './form-step-header';
import { useCurrentUserIsAllowedToWrite } from '../hooks/use-current-user-is-allowed-to-write';
import { SelectAllButtonWrapper } from '../../#-components/planner/attributes';
import {
  KeyboardListNavigationContext,
  useKeyboardListNavigation
} from '../../utils/use-keyboard-list-navigation';

export function AttendeesStep(): JSX.Element {
  const intl = useIntl();
  const formik = useFormikContext<PlannerEvent>();
  const selectedUsers = formik.values.users;
  const usersStore = useUsersStore();
  const plannerStore = usePlannerStore();
  const currentUserIsAllowedToWrite = useCurrentUserIsAllowedToWrite();
  const [filter, setFilter] = useState('');
  const group = plannerStore.group;
  const filterAttributes = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target instanceof HTMLInputElement) {
      setFilter(e.target.value);
    }
  }, []);
  const keyboardNavigationContext = useKeyboardListNavigation();

  const selectUser = useCallback(
    (item: SelectionListItem<UserId>) => {
      const idx = selectedUsers?.indexOf(item.value) ?? -1;
      if (idx !== -1) {
        const updatedValue = [...(selectedUsers ?? [])];
        updatedValue.splice(idx, 1);
        formik.setFieldValue('users', updatedValue);
      } else {
        formik.setFieldValue('users', [...(selectedUsers ?? []), item.value]);
      }
    },
    [formik, selectedUsers]
  );

  function mapUserToSelectionListItem(
    user: UserStore
  ): SelectionListItem<UserId> {
    return {
      icon: <RoleAvatar user={user} />,
      value: user.id,
      isSelected: selectedUsers.includes(user.id),
      title: user.displayName
    };
  }

  const filterOnlySelected =
    !currentUserIsAllowedToWrite ||
    (!formik.values.isAttendeesEditable && !formik.values.isEditable);

  const groupUsers: SelectionListItem<UserId>[] =
    filter || filterOnlySelected
      ? group?.athletes
          .filter(u =>
            filter
              ? filterUserByName(u, filter, false) &&
                (filterOnlySelected ? selectedUsers.includes(u.id) : true)
              : selectedUsers.includes(u.id)
          )
          .map(mapUserToSelectionListItem) ?? []
      : group?.athletes.map(mapUserToSelectionListItem) ?? [];

  const searchResults: SelectionListItem<UserId>[] = filter
    ? usersStore.athletes
        .filter(
          u =>
            (filterOnlySelected ? selectedUsers.includes(u.id) : true) &&
            !group?.athletes.includes(u) &&
            filterUserByName(u, filter, false)
        )
        .map(mapUserToSelectionListItem)
    : [];

  const otherUsers: SelectionListItem<UserId>[] = selectedUsers
    .filter(userId => !group?.athletes.find(u => u.id === userId))
    .map(userId => {
      const user = usersStore.getUserById(userId);
      if (!user) {
        return null;
      }
      return mapUserToSelectionListItem(user);
    })
    .filter(Boolean) as SelectionListItem<UserId>[];

  const isAllSelected = useMemo(
    () =>
      Boolean(
        group?.athletes.every(athlete => selectedUsers.includes(athlete.id))
      ),
    [group, selectedUsers]
  );

  const selectAll = useCallback(() => {
    const athletesToAdd =
      group?.athletes
        .filter(athlete => !selectedUsers.includes(athlete.id))
        .map(a => a.id) ?? [];
    formik.setFieldValue('users', [...selectedUsers, ...athletesToAdd]);
  }, [group, formik, selectedUsers]);

  const unSelectAll = useCallback(() => {
    formik.setFieldValue('users', []);
  }, [formik]);

  return (
    <>
      <FormStepHeader />
      <KeyboardListNavigationContext.Provider value={keyboardNavigationContext}>
        <RightPanelContent noPadding>
          <IntegratedRightPanelInput
            name="search-attribute"
            placeholder={intl.formatMessage({ id: 'planner.searchAttendees' })}
            icon={<Search />}
            onChange={filterAttributes}
            autoFocus
            value={filter}
          />
          <FilteredRightPanelScrollContainer>
            {currentUserIsAllowedToWrite &&
              formik.values.isAttendeesEditable && (
                <SelectAllButtonWrapper>
                  <SelectAllButton
                    isAllSelected={isAllSelected}
                    selectAll={selectAll}
                    unSelectAll={unSelectAll}
                    selectAllLabel={intl.formatMessage({
                      id: 'planner.eventTypeFilter.selectAll'
                    })}
                    unSelectAllLabel={intl.formatMessage({
                      id: 'planner.eventTypeFilter.unSelectAll'
                    })}
                  />
                </SelectAllButtonWrapper>
              )}

            <AttendeesGroupHeading as="h3" appearance="button10" upperCase>
              <FormattedMessage id="planner.attendees.teamMembers.heading" />
            </AttendeesGroupHeading>
            <SelectionList
              items={groupUsers}
              onClick={
                currentUserIsAllowedToWrite && formik.values.isAttendeesEditable
                  ? selectUser
                  : undefined
              }
              inputType="checkbox"
            />
            {(filter || otherUsers.length) && (
              <>
                <AttendeesGroupHeading as="h3" appearance="button10" upperCase>
                  <FormattedMessage id="planner.attendees.otherUsers.heading" />
                </AttendeesGroupHeading>
                <SelectionList
                  items={filter ? searchResults : otherUsers}
                  onClick={
                    currentUserIsAllowedToWrite &&
                    formik.values.isAttendeesEditable
                      ? selectUser
                      : undefined
                  }
                  inputType="checkbox"
                />
              </>
            )}
            {formik.values.otherSubscribersCount > 0 && (
              <AttendeesGroupHeading as="h3" appearance="button10" upperCase>
                <FormattedMessage
                  id="planner.attendees.others.heading"
                  values={{
                    otherUsersCount: formik.values.otherSubscribersCount
                  }}
                />
              </AttendeesGroupHeading>
            )}
          </FilteredRightPanelScrollContainer>
        </RightPanelContent>
      </KeyboardListNavigationContext.Provider>
    </>
  );
}
