import { BarChart } from '../types';
import { Bar } from './primitives/bar';
import { useXYChartContext } from '../xy-chart-context';
import { DataItem } from '../../reporting/types';
import { getBarDimensions } from '../utils/get-bar-dimensions';

interface BarsProps {
  readonly config: BarChart;
  readonly data: DataItem[];
}

export function Bars({ config, data }: BarsProps) {
  const { xScale, yScale } = useXYChartContext();

  return (
    <>
      {data.map((item, idx) => {
        const dimensions = getBarDimensions(config, item, xScale, yScale);

        if (!dimensions) {
          return null;
        }

        const { x, y, width, height } = dimensions;

        return (
          <Bar
            key={idx}
            x={x}
            y={y}
            width={width}
            height={height}
            color={config.getColor(item)}
            opacity={config.getOpacity(item) ?? 1}
          />
        );
      })}
    </>
  );
}
