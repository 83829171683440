import { useMemo } from 'react';
import { useTooltipInPortal } from '@visx/tooltip';
import { scaleBand, scaleLinear } from '@visx/scale';
import styled from 'styled-components';
import { Group } from '@visx/group';
import { AxisLeft } from '@visx/axis';
import { TextProps } from '@visx/text';
import { HorizontalChartConfig } from './utils';
import { HorizontalBarGroup } from './horizontal-bar-group';
import { HorizontalBarStack } from './horizontal-bar-stack';
import { Tooltip } from './tooltip';
import { GridColumns } from '@visx/grid';
import { getSSRStringWidth } from '../reporting/utils/get-ssr-string-width';
import { DataItem, GetTooltipData } from '../reporting/types';

export * from './utils';

const margin = {
  left: 0,
  right: 10,
  top: 0,
  bottom: 0
};

const ChartWrapper = styled.div`
  position: relative;
`;

function getTickLabel(_label: string, maxWidth: number): Partial<TextProps> {
  return {
    fill: '#000',
    fontSize: 11,
    textAnchor: 'end',
    verticalAnchor: 'middle',
    width: maxWidth,
    scaleToFit: 'shrink-only'
  };
}

export interface HorizontalCategoricalChartProps<T extends DataItem> {
  width: number;
  height: number;
  categories: string[];
  data: T[];
  configs: HorizontalChartConfig<T>[];
  domainX: [number, number];
  getTooltipData: GetTooltipData;
  showYAxisLabels?: boolean;
  formatAxisYTick?(value: string): string;
}

export function HorizontalCategoricalChart<T extends DataItem>(
  props: HorizontalCategoricalChartProps<T>
): JSX.Element {
  const {
    categories,
    configs,
    data,
    domainX,
    formatAxisYTick,
    getTooltipData,
    height,
    showYAxisLabels = true,
    width
  } = props;
  const [config] = configs;
  const longestLabelWidth = useMemo(
    () =>
      Math.min(
        Math.max(...categories.map(c => getSSRStringWidth(c))),
        width / 2
      ),
    [categories, width]
  );
  const leftMargin = useMemo(
    () => (showYAxisLabels ? longestLabelWidth + 10 : 0),
    [longestLabelWidth, showYAxisLabels]
  );

  const labelMargin = useMemo(() => {
    const shouldIncludeLabelsMargin =
      config.getShowLabels(data[0]) &&
      (!config.labelPosition || config.labelPosition === 'outside');

    return shouldIncludeLabelsMargin
      ? getSSRStringWidth(
          String(config.formatLabelValue?.(domainX[1], config.keys[0]))
        )
      : 0;
  }, [config, domainX]);

  const xMax =
    width - margin.right - (showYAxisLabels ? leftMargin : 0) - labelMargin;
  const yMax = height - margin.top - margin.bottom;

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
    detectBounds: true,
    debounce: 25
  });

  const yScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, yMax],
        round: false,
        domain: categories,
        padding: 0.2
      }),
    [yMax, categories]
  );
  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax - 20],
        round: true,
        domain: domainX
      }),
    [xMax, domainX]
  );

  const showAxisYLine = (domainX?.[0] || 0) < 0;

  return (
    <ChartWrapper>
      <svg width={width} height={height} ref={containerRef}>
        <Group left={leftMargin}>
          <Tooltip
            categories={categories}
            getTooltipData={getTooltipData}
            TooltipInPortal={TooltipInPortal}
            yScale={yScale}
            xMax={xMax}
          />
          {showAxisYLine && (
            <GridColumns
              scale={xScale}
              height={height}
              numTicks={1}
              tickValues={[Math.min(0, domainX[1])]}
              stroke="#a9a9a9"
            />
          )}
          {[config].map((chartConfig, idx) => {
            switch (chartConfig.type) {
              case 'BarGroup':
                return (
                  <HorizontalBarGroup
                    config={chartConfig}
                    yScale={yScale}
                    xScale={xScale}
                    data={data}
                    xMax={xMax}
                    key={idx}
                  />
                );
              case 'BarStack':
                return (
                  <HorizontalBarStack
                    config={chartConfig}
                    xScale={xScale}
                    yScale={yScale}
                    data={data}
                    key={idx}
                    xMax={xMax}
                  />
                );
              default:
                return null;
            }
          })}
          {showYAxisLabels && (
            <AxisLeft
              hideAxisLine
              hideTicks
              scale={yScale}
              tickLabelProps={l => getTickLabel(l, longestLabelWidth)}
              tickFormat={formatAxisYTick}
              numTicks={categories.length}
            />
          )}
        </Group>
      </svg>
    </ChartWrapper>
  );
}
