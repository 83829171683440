import styled from 'styled-components';
import {
  ContentBox,
  Text,
  TextAlignment,
  TextSize,
  TextTag
} from '@yarmill/components';
import { SeasonGoalRow } from '../goals/season-goal-row';
import { useSeasonGoalsStore } from '../goals/hooks';
import { observer } from 'mobx-react-lite';
import { Fragment, PropsWithChildren, useRef } from 'react';
import {
  useFocusedCycleScroller,
  useSeasonGoalsContentBoxMaxHeight
} from './hooks';
import { FormattedMessage } from 'react-intl';

const SeasonGoalsContainer = styled.div`
  display: grid;
  column-gap: 20px;
  row-gap: 10px;
  overflow-y: auto;
  grid-template-columns: repeat(3, 80vw);

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 50vw);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const SeasonGoalsContentBox = styled(ContentBox)<{ maxHeight: string }>`
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  row-gap: 10px;
  padding: 26px 20px;
  position: relative;
  height: min-content;
  max-height: ${({ maxHeight }) => maxHeight};

  @media (max-width: 1023px) and (max-height: 560px) {
    max-height: unset;
  }
`;

const Separator = styled.div`
  height: 10px;
`;

const FocusedPlaceholder = styled.div`
  position: absolute;
  pointer-events: none;
  background-color: rgb(245, 248, 251);
  grid-column: 1 / 2;
  width: calc(100% + 2 * 26px);
  height: calc(100% + 24px);
  margin: -12px -26px;
  border-top: 1px solid #4a90e2;
  border-bottom: 1px solid #4a90e2;
`;

const FocusedCycle = styled(FocusedPlaceholder)<{
  cycleIndex: number;
}>`
  height: calc(100% + 12px);
  margin: -6px -26px;
  ${({ cycleIndex }) => `grid-row: ${cycleIndex + 1} / ${cycleIndex + 2}`};
`;

const FocusedWeek = styled(FocusedPlaceholder)<{
  cycleIndex: number;
}>`
  ${({ cycleIndex }) =>
    `grid-row: ${cycleIndex * 4 + 1 + cycleIndex} / ${
      cycleIndex * 4 + 5 + cycleIndex
    }`};
  // each cycle has 4 weeks, +1 because grid numbering starts at 1 and + cycleIndex for separators
`;

const FocusedDays = styled(FocusedPlaceholder)<{
  cycleIndex: number;
}>`
  ${({ cycleIndex }) =>
    `grid-row: ${cycleIndex * 4 * 7 + 1 + cycleIndex * 3 + cycleIndex} / ${
      cycleIndex * 4 * 7 + 29 + (cycleIndex + 1) * 3 + cycleIndex
    }`};
`;

function ColumnLabel(props: PropsWithChildren<{}>): JSX.Element {
  return (
    <Text
      tag={TextTag.div}
      textAlign={TextAlignment.center}
      size={TextSize.s14}
    >
      {props.children}
    </Text>
  );
}

function getHighlightIndex(
  focusedCycle: number,
  focusedWeek: number,
  focusedDay: number
): number {
  if (focusedCycle !== -1) {
    return focusedCycle;
  }
  if (focusedWeek !== -1) {
    return Math.floor(focusedWeek / 4);
  }
  if (focusedDay !== -1) {
    return Math.floor(focusedDay / 28);
  }

  return -1;
}

function isWeekHighlighted(
  weekIndex: number,
  focusedCycle: number,
  focusedWeek: number,
  focusedDay: number
): boolean {
  if (focusedCycle !== -1) {
    return Math.floor(weekIndex / 4) === focusedCycle;
  }

  if (focusedWeek !== -1) {
    return focusedWeek === weekIndex;
  }

  if (focusedDay !== -1) {
    return Math.floor(focusedDay / 7) === weekIndex;
  }

  return false;
}

function isDayHighlighted(
  dayIndex: number,
  focusedCycle: number,
  focusedWeek: number,
  focusedDay: number
): boolean {
  if (focusedCycle !== -1) {
    return Math.floor(dayIndex / 28) === focusedCycle;
  }

  if (focusedWeek !== -1) {
    return Math.floor(dayIndex / 7) === focusedWeek;
  }

  if (focusedDay !== -1) {
    return focusedDay === dayIndex;
  }

  return false;
}

export const SeasonGoalsView = observer(
  function SeasonGoalsView(): JSX.Element {
    const store = useSeasonGoalsStore();
    const cyclesContentBox = useRef<HTMLDivElement>(null);
    const weeksContentBox = useRef<HTMLDivElement>(null);
    const daysContentBox = useRef<HTMLDivElement>(null);
    const contentBoxMaxHeight =
      useSeasonGoalsContentBoxMaxHeight(cyclesContentBox);

    const focusedCycle =
      store?.cycleGoals.findIndex(goal => goal.attribute.isFocused) ?? -1;
    const focusedWeek =
      focusedCycle !== -1
        ? -1
        : store?.weekGoals.findIndex(goal => goal.attribute.isFocused) ?? -1;
    const focusedDay =
      focusedCycle === -1 && focusedWeek === -1
        ? store?.dailyGoals.findIndex(goal => goal.attribute.isFocused) ?? -1
        : -1;

    const highlightIndex = getHighlightIndex(
      focusedCycle,
      focusedWeek,
      focusedDay
    );

    useFocusedCycleScroller(
      cyclesContentBox,
      weeksContentBox,
      daysContentBox,
      highlightIndex,
      focusedWeek,
      focusedCycle === -1,
      focusedWeek === -1,
      focusedDay === -1
    );

    return (
      <>
        <SeasonGoalsContainer>
          <ColumnLabel>
            <FormattedMessage id="diary.seasonGoals.cycles" />
          </ColumnLabel>
          <ColumnLabel>
            <FormattedMessage id="diary.seasonGoals.weeks" />
          </ColumnLabel>
          <ColumnLabel>
            <FormattedMessage id="diary.seasonGoals.days" />
          </ColumnLabel>
          <SeasonGoalsContentBox
            className="cycle-goals-container"
            maxHeight={contentBoxMaxHeight}
            ref={cyclesContentBox}
          >
            {highlightIndex !== -1 && (
              <FocusedCycle cycleIndex={highlightIndex} />
            )}

            {store?.cycleGoals.map((goalStore, idx) => (
              <SeasonGoalRow
                attribute={goalStore.attribute}
                cycle
                cycleIndex={idx}
                key={goalStore.currentDate}
                richtextBounds=".cycle-goals-container"
                highlighted={highlightIndex === idx}
              />
            ))}
          </SeasonGoalsContentBox>
          <SeasonGoalsContentBox
            ref={weeksContentBox}
            maxHeight={contentBoxMaxHeight}
            className="week-goals-container"
          >
            {highlightIndex !== -1 && (
              <FocusedWeek cycleIndex={highlightIndex} />
            )}

            {store?.weekGoals.map((goalStore, idx) => (
              <Fragment key={goalStore.currentDate}>
                {idx % 4 === 0 && idx !== 0 && <Separator />}
                <SeasonGoalRow
                  attribute={goalStore.attribute}
                  week
                  cycleIndex={idx}
                  richtextBounds=".week-goals-container"
                  highlighted={isWeekHighlighted(
                    idx,
                    focusedCycle,
                    focusedWeek,
                    focusedDay
                  )}
                />
              </Fragment>
            ))}
          </SeasonGoalsContentBox>
          <SeasonGoalsContentBox
            ref={daysContentBox}
            maxHeight={contentBoxMaxHeight}
            className="daily-goals-container"
          >
            {highlightIndex !== -1 && (
              <FocusedDays cycleIndex={highlightIndex} />
            )}
            {store?.dailyGoals.map((goalStore, idx) => (
              <Fragment key={goalStore.currentDate}>
                {idx % 7 === 0 && idx !== 0 && <Separator />}
                <SeasonGoalRow
                  attribute={goalStore.attribute}
                  cycleIndex={idx}
                  richtextBounds=".daily-goals-container"
                  highlighted={isDayHighlighted(
                    idx,
                    focusedCycle,
                    focusedWeek,
                    focusedDay
                  )}
                />
              </Fragment>
            ))}
          </SeasonGoalsContentBox>
        </SeasonGoalsContainer>
      </>
    );
  }
);
