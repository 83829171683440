import { Header } from '../../okrs/header';
import { RightPanelTitle } from '../../components-2/right-panel';
import { FormattedMessage } from 'react-intl';
import { FakeButton } from '../../components-2/okrs/objectives-list';

interface ImporterHeaderProps {
  readonly backButton: JSX.Element;
  readonly filtersButton?: JSX.Element;
}
export function ImporterHeader({
  backButton,
  filtersButton
}: ImporterHeaderProps): JSX.Element {
  return (
    <Header
      dark
      left={backButton}
      center={
        <RightPanelTitle appearance="text15strong">
          <FormattedMessage id="planner.importer.headline" />
        </RightPanelTitle>
      }
      right={filtersButton ?? <FakeButton />}
    />
  );
}
