import { Icon, IconSize, styled } from '@yarmill/components';
import { Check, Question, Priority1 } from '@yarmill/icons-2';
import { Text } from '../../text';

export interface RatingMetricProps {
  readonly score: number;
  readonly text: string;
  readonly appearance: 'red' | 'sand' | 'green';
}

const Value = styled.div<{
  readonly $appearance: RatingMetricProps['appearance'];
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: ${({ theme }) => theme.size.x05};
  margin-bottom: ${({ theme }) => theme.size.x1};
  color: ${({ theme, $appearance }) => theme.color[$appearance]};
`;

export function getRatingMetricAppearanceIcon(
  appearance: RatingMetricProps['appearance']
): JSX.Element {
  switch (appearance) {
    case 'green':
      return <Check />;
    case 'sand':
      return <Question />;
    case 'red':
      return <Priority1 />;
  }
}

const Label = styled(Text)`
  color: ${({ theme }) => theme.color.neutral};
`;

export const RatingMetricIcon = styled(Icon)<{
  readonly $appearance: RatingMetricProps['appearance'];
}>`
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme, $appearance }) => theme.color[$appearance]};
  border-radius: 50%;
  width: ${({ theme }) => theme.size.x2};
  height: ${({ theme }) => theme.size.x2};

  svg {
    width: ${({ theme }) => theme.size.x15};
    height: ${({ theme }) => theme.size.x15};
  }
`;

const RatingMetricLayout = styled.div``;

export function RatingMetric({
  score,
  text,
  appearance
}: RatingMetricProps): JSX.Element {
  return (
    <RatingMetricLayout>
      <Value $appearance={appearance}>
        <RatingMetricIcon size={IconSize.s16} $appearance={appearance}>
          {getRatingMetricAppearanceIcon(appearance)}
        </RatingMetricIcon>
        <Text appearance="text15strong" inheritColor>
          {score}
        </Text>
      </Value>
      <Label appearance="button10" upperCase>
        {text}
      </Label>
    </RatingMetricLayout>
  );
}
