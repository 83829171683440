import { Fragment } from 'react';
import { Categories } from './categories';
import { RowLabel } from './row-label';
import { OverviewTableDataFormatter, OverviewTableDataRow } from './utils';
import { Value } from './value';

export interface ContentRowProps {
  columns: string[];
  columnsIndexMap: { [key: string]: number };
  row: number;
  data: OverviewTableDataRow;
  valueFormatter?: OverviewTableDataFormatter;
  categoryFormatter?: OverviewTableDataFormatter<string>;
  rowLabelFormatter?: OverviewTableDataFormatter;
  hasRowLabel?: boolean;
}

export function ContentRow(props: ContentRowProps) {
  const {
    row,
    columns,
    data,
    valueFormatter,
    categoryFormatter,
    rowLabelFormatter,
    columnsIndexMap,
    hasRowLabel
  } = props;

  return (
    <Fragment>
      <Categories
        columns={columns}
        row={row}
        data={data}
        formatter={categoryFormatter}
        hasRowLabel={hasRowLabel}
      />
      {data.Values.map(cell => (
        <Value
          key={cell.ColumnKey}
          value={cell}
          formatter={valueFormatter}
          row={row}
          column={columnsIndexMap[cell.ColumnKey] + (hasRowLabel ? 1 : 0)} // +1 for label column
        />
      ))}
      {hasRowLabel && (
        <RowLabel
          columnsCount={columns.length}
          data={data.Key}
          rowNumber={row}
          formatter={rowLabelFormatter}
        />
      )}
    </Fragment>
  );
}
