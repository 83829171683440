export const en = {
  'auth.email': 'Email',
  'auth.error.invalidLogin': 'Invalid login',
  'auth.error.userDisabled': 'User disabled',
  'auth.error.userNotActive': 'Inactive user',
  'auth.forgottenPassword': 'Forgot password?',
  'auth.forgottenPasswordBack': 'Back to log in',
  'auth.forgottenPasswordSuccess':
    'An email containing instructions to reset your password has been sent.',
  'auth.login.capsLock': 'Caps Lock is on',
  'auth.login.inactiveInstance':
    'Yarmill is no longer here 🥹. You can write to him at <a href="mailto:hello@yarmill.com">hello@yarmill.com</a>',
  'auth.login.tips':
    '<p> Try to check if: </ p> <ul> <li> you enter the email you have registered to </ li> <li> you do not have a keyboard language switched </ li><li> you are logged in to the right team </ li> </ ul>',
  'auth.loginButton': 'Login',
  'auth.loginPageHeading': 'Login',
  'auth.password': 'Password',
  'auth.submitForgottenPassword': 'Send',
  'auth.username': 'Email',
  'fatalError.button.logout': 'Logout',
  'fatalError.button.refresh': 'Refresh',
  'fatalError.button.reset': 'Reset',
  'fatalError.description':
    'We have detected an exception and notified our engineering team. We apologize for any inconvenience this may cause. Reach out to us at <a href="mailto:support@yarmill.com">support@yarmill.com</a> if non of the below helps.',
  'fatalError.headline': '😳 Exception detected',
  'fatalError.resetClient.checkbox': 'Reset the application',
  'fatalError.resetClient.description':
    'Resetting will reload all data and might help mitigate this problem.'
};
