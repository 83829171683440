import { AxiosProgressEvent, AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { File } from '../types';
import { devMode, instance } from '../../api/api-mapping';

export function uploadFile(
  fileUploadUrl: string,
  formData: FormData,
  cancelToken: CancelTokenSource,
  onUploadProgress: (e: AxiosProgressEvent) => void
): AxiosPromise<File> {
  return axios.post(fileUploadUrl, formData, {
    onUploadProgress,
    cancelToken: cancelToken.token,
    params: {
      instanceCode: devMode ? instance : undefined
    }
  });
}
