import { useCallback } from 'react';
import moment from 'moment';
import { Location } from 'history';

import { NavItem } from '../nav-item';
import { useDefaultCommonSearchParams } from '../hooks';
import { ROUTE_DATE_FORMAT } from '../../diary/utils';
import { LinkCallback } from '../../routes/types';
import { appendDefaultSearchParams } from '../utils';
import { useCurrentUserStore } from '../../users/hooks';
import { ViewType } from '../../diary/types';
import { useRootStore } from '../../app/root-store-context';
import { observer } from 'mobx-react-lite';

export interface RealityLinkProps {
  className?: string;
  onClick?(): void;
}

const viewTypes: ViewType[] = ['week', 'goals', 'seasonGoals', 'season'];
export function useAllowedViewType(
  diaryType: 'plan' | 'reality'
): ViewType | undefined {
  const currentUser = useCurrentUserStore();
  const rootStore = useRootStore();
  const lastViewType = rootStore.memoryStore.getItem<ViewType>(diaryType);

  if (lastViewType && currentUser.isAllowedTo(`${diaryType}.${lastViewType}`)) {
    return lastViewType;
  }

  return viewTypes.find(viewType =>
    currentUser.isAllowedTo(`${diaryType}.${viewType}`)
  );
}

export function useRealityLink(): LinkCallback {
  const viewType = useAllowedViewType('reality');
  const defaultParams = useDefaultCommonSearchParams({
    week: moment().format(ROUTE_DATE_FORMAT)
  });

  return useCallback(
    (location: Location) => {
      const searchParams = appendDefaultSearchParams(
        location.search,
        defaultParams
      );
      return `/reality/${viewType}?${searchParams}`;
    },
    [defaultParams, viewType]
  );
}

export const RealityLink = observer(function RealityLink(
  props: RealityLinkProps
): JSX.Element {
  const { className, onClick } = props;
  const link = useRealityLink();

  return (
    <NavItem
      to={link}
      moduleKey="reality"
      icon="Notebook"
      className={className}
      onClick={onClick}
    />
  );
});
