import { action, makeObservable, observable } from 'mobx';
import { HeartRateZone } from '../types';

export class HeartRateZoneStore {
  @observable
  private readonly _id: string = '';

  @observable
  private _name: string = '';

  @observable
  private _max: number = 0;

  constructor(zone: HeartRateZone) {
    makeObservable(this);
    this._id = zone.id;
    this._name = zone.name;
    this._max = zone.max;
  }
  public get id() {
    return this._id;
  }
  public get name() {
    return this._name;
  }

  public get max() {
    return this._max;
  }

  @action
  public setName(name: string) {
    this._name = name;
    return this;
  }

  @action
  public setMax(max: number) {
    this._max = max;
    return this;
  }

  public toJS(): HeartRateZone {
    return {
      id: this.id,
      name: this.name,
      max: this.max
    };
  }
}
