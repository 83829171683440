import * as React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { WhiteSpace } from '../text';
import { forwardRef } from 'react';

export interface TableProps {
  head?: React.ReactNode;
  colgroup?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}

const StyledTable = styled.table<{ readonly hasHead: boolean }>`
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;

  ${({ hasHead }) =>
    !hasHead &&
    css`
      margin-top: 16px;
      tbody tr:first-child > td {
        border-top: none;
      }
    `}
`;

const StyledThead = styled.thead``;

const StyledTbody = styled.tbody``;

export const StyledTr = styled.tr`
  &:hover {
    background: #f7f9fb;

    td,
    & > div {
      background: #f7f9fb !important;
    }
  }

  thead &:hover {
    background: unset;
  }
`;

const CellStyles = css`
  text-align: center;
  outline: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:first-of-type {
    text-align: left;
  }
`;

export const StyledTh = styled.th<StyledThProps>`
  ${CellStyles};
  font-weight: normal;
  vertical-align: top;
  padding: 8px;
  transition: all 250ms ease;

  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  ${props => {
    const { sticky } = props;

    return (
      sticky &&
      css`
        position: sticky;
        z-index: 2;
        background-color: #ffffff;
        ${sticky?.left !== undefined && `left: ${sticky.left}`};
        ${sticky?.top !== undefined && `top: ${sticky.top}`};
        ${sticky?.right !== undefined && `right: ${sticky.right}`};
      `
    );
  }}

    ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}
`;

interface StickyDefinition {
  top?: string | 0;
  right?: string | 0;
  left?: string | 0;
}

export interface StyledTdProps {
  disabled?: boolean;
  fixedWidth?: boolean;
  maxWidth?: string;
  minWidth?: string;
  whiteSpace?: WhiteSpace;
  align?: string;
  noBorder?: boolean;
  sticky?: StickyDefinition;
  extraStyles?: FlattenSimpleInterpolation;
  dontBreakWord?: boolean;
}

export interface StyledThProps {
  sticky?: StickyDefinition;
  align?: string;
  maxWidth?: string;
}

export const StyledTd = styled.td<StyledTdProps>`
  ${CellStyles};
  padding: 8px;
  border-top: 1px solid #eaeaea;
  width: ${props => (props.fixedWidth ? '75px' : 'auto')};
  background: ${props => (props.disabled ? '#f5f5f5' : '')};
  word-break: ${props => (props.dontBreakWord ? 'keep-all' : 'break-word')};
  white-space: ${props => props.whiteSpace || 'normal'};
  vertical-align: middle;
  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
      overflow: hidden;
    `}
  ${props =>
    props.fixedWidth &&
    css`
      overflow: hidden;
    `}
  ${props =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}
  ${props =>
    props.align &&
    css`
      text-align: ${props.align} !important;
    `}
  ${props =>
    props.noBorder &&
    css`
      border: 0;
    `}

  ${props => {
    const { sticky } = props;

    return (
      sticky &&
      css`
        padding: 8px 0;
        position: sticky;
        ${sticky?.left !== undefined && `left: ${sticky.left}`};
        ${sticky?.top !== undefined && `top: ${sticky.top}`};
        ${sticky?.right !== undefined && `right: ${sticky.right}`};
        z-index: 1;
        background-color: #ffffff;
      `
    );
  }}

    ${props => props.extraStyles && props.extraStyles}
`;

export const Table = forwardRef<HTMLTableElement, TableProps>(function Table(
  props: TableProps,
  ref
): JSX.Element {
  const { colgroup, className, children, head } = props;

  return (
    <StyledTable className={className} ref={ref} hasHead={Boolean(head)}>
      {colgroup && <colgroup>{colgroup}</colgroup>}
      {head && (
        <StyledThead>
          <StyledTr>{head}</StyledTr>
        </StyledThead>
      )}
      <StyledTbody>{children}</StyledTbody>
    </StyledTable>
  );
});
