import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { Group } from '../types';

export interface RemoveUserFromGroupRequestParams {
  groupId: number;
  userId: number;
}

export function removeUserFromGroup(
  params: RemoveUserFromGroupRequestParams
): AxiosPromise<Group> {
  return axios.delete(`api/UserGroupUsers`, {
    params
  });
}
