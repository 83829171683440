import { MouseEvent, ReactElement, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { Tooltip } from './tooltip';
import { localPoint } from '@visx/event';
import { darkenColor } from '../helpers';
import { css } from '../theme-provider';
import { TooltipData } from '../reporting';

export type HamburgerChartOrientation = 'horizontal' | 'vertical';
export interface HamburgerChartPoint {
  readonly strokeColor: string;
  readonly fillColor: string | null;
  readonly stroke: number;
  readonly radius: number;
  readonly name: string;
}
export interface HamburgerChartLayer {
  readonly color: string;
  readonly highlightColor: string;
  readonly pointColor?: string;
  readonly name: string;
}

type Data = Record<string, number>;

interface HamburgerChartProps {
  readonly orientation?: HamburgerChartOrientation;
  readonly layers: HamburgerChartLayer[];
  readonly points: HamburgerChartPoint[];
  readonly highlights: string[];
  readonly data: Data | undefined;
  readonly startValue: number;
  readonly getTooltipData: (point: number) => TooltipData;
  readonly height: number;
  readonly layersGap?: number;
  readonly borderColor?: string;
}

function getLayerSize(
  idx: number,
  layers: HamburgerChartLayer[],
  data: Data,
  startValue: number,
  orientation: HamburgerChartOrientation
): number {
  const layer = layers[idx];

  return data[layer.name]
    ? data[layer.name] -
        startValue -
        getPreviousLayersSize(idx, layers, data, startValue, orientation)
    : 0;
}

function getPreviousLayersSize(
  idx: number,
  layers: HamburgerChartLayer[],
  data: Data,
  startValue: number,
  orientation: HamburgerChartOrientation
) {
  if (orientation === 'horizontal') {
    let curr = 0;
    let width = 0;

    while (curr < idx) {
      width += getLayerSize(curr, layers, data, startValue, orientation);
      curr++;
    }

    return width;
  } else {
    let curr = idx + 1;
    let width = 0;

    while (curr < layers.length) {
      width += getLayerSize(curr, layers, data, startValue, orientation);
      curr++;
    }

    return width;
  }
}

function getLayout(
  layers: HamburgerChartLayer[],
  data: Data,
  startValue: number,
  orientation: HamburgerChartOrientation
): string {
  const layout = layers.map(
    (_, idx) => `${getLayerSize(idx, layers, data, startValue, orientation)}fr`
  );

  return layout.join(' ');
}

function getLayerColor(
  layer: HamburgerChartLayer,
  highlights: string[],
  data: Record<string, number | string>
): string {
  const highlightedLayers = highlights.map(h => data[h]);

  return highlightedLayers.includes(layer.name)
    ? layer.highlightColor
    : layer.color;
}

function getPointValue(value: number, startValue: number, maxValue: number) {
  return ((value - startValue) / maxValue) * 100;
}

export function getHamburgerPointColorFromLayerColor(
  layers: HamburgerChartLayer[],
  data: Record<string, number | string>,
  value: number
): string {
  const sortedLayers = [...layers].sort(
    (a, b) => Number(data[a.name]) - Number(data[b.name])
  );
  const higherLayers = sortedLayers.filter(
    layer => data[layer.name] && Number(data[layer.name]) >= value
  );
  const closestLayer = higherLayers.length ? higherLayers[0] : sortedLayers[0];

  return (
    closestLayer?.pointColor ??
    darkenColor(closestLayer?.highlightColor ?? '') ??
    ''
  );
}

const HamburgerChartLayout = styled.div<{
  readonly layers: HamburgerChartLayer[];
  readonly $data: Data;
  readonly startValue: number;
  readonly $orientation: HamburgerChartOrientation;
  readonly $height: number;
  readonly layersGap: number;
  readonly borderColor?: string;
}>`
  position: relative;
  height: ${({ $height }) => `${$height}px`};
  display: grid;
  grid-template-columns: ${({ $orientation, layers, $data, startValue }) =>
    $orientation === 'horizontal'
      ? getLayout(layers, $data, startValue, $orientation)
      : '100%'};
  grid-template-rows: ${({ $orientation, layers, $data, startValue }) =>
    $orientation === 'vertical'
      ? getLayout(layers, $data, startValue, $orientation)
      : '100%'};
  gap: ${({ layersGap }) => layersGap}px;
  ${({ borderColor }) =>
    borderColor &&
    css`
      border: 1px solid ${borderColor};
      border-radius: 6px;
      padding: 2px;
    `}
`;

const LayerLayout = styled.section<{
  readonly backgroundColor: string;
}>`
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 6px;
`;

const PointLayout = styled.div<{
  readonly $orientation: HamburgerChartOrientation;
  readonly point: HamburgerChartPoint;
  readonly $value: number | undefined;
  readonly fillColor: string;
}>`
  position: absolute;
  display: ${({ $value }) => ($value === undefined ? 'none' : 'block')};
  bottom: ${({ $orientation, $value }) =>
    $orientation === 'horizontal' ? '50%' : `${$value}%`};
  left: ${({ $orientation, $value }) =>
    $orientation === 'vertical' ? '50%' : `${$value}%`};
  transform: translateX(-50%) translateY(50%);
  transform-origin: center;
  border-radius: 50%;
  width: ${({ point }) => point.radius}px;
  height: ${({ point }) => point.radius}px;
  border: ${({ point }) =>
    point.stroke
      ? `${point.stroke}px solid ${point.strokeColor ?? '#000'}`
      : 'none'};
  background-color: ${({ fillColor }) => fillColor};
`;

const TOOLTIP_TIMEOUT = 50;

const HamburgerChartWrapper = styled.div<{
  readonly $offset: number;
  readonly $orientation: HamburgerChartOrientation;
}>`
  padding-top: ${({ $offset, $orientation }) =>
    $orientation === 'vertical' ? $offset : 0}px;
  padding-bottom: ${({ $offset, $orientation }) =>
    $orientation === 'vertical' ? $offset : 0}px;
  padding-left: ${({ $offset, $orientation }) =>
    $orientation === 'horizontal' ? $offset : 0}px;
  padding-right: ${({ $offset, $orientation }) =>
    $orientation === 'horizontal' ? $offset : 0}px;
`;

export function HamburgerChart({
  layers: unsortedLayers,
  orientation = 'vertical',
  points,
  data,
  startValue,
  highlights,
  getTooltipData,
  height,
  layersGap = 4,
  borderColor
}: HamburgerChartProps): ReactElement | null {
  const tooltipTimeout = useRef<number>(0);
  const layoutRef = useRef<HTMLDivElement | null>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
    debounce: 25
  });

  const tooltip = useTooltip();

  const layers = useMemo(
    () =>
      [...unsortedLayers].sort((a, b) =>
        orientation === 'vertical'
          ? (data?.[b.name] ?? 0) - (data?.[a.name] ?? 0)
          : (data?.[a.name] ?? 0) - (data?.[b.name] ?? 0)
      ),
    [data, unsortedLayers, orientation]
  );

  const offset = useMemo(
    () => Math.ceil(Math.max(...points.map(p => Math.ceil(p.radius))) / 2),
    [points]
  );

  const maxValue = useMemo(
    () => Math.max(...layers.map(l => data?.[l.name] ?? 0)),
    [data, layers]
  );

  if (!data) {
    return null;
  }

  return (
    <HamburgerChartWrapper $offset={offset} $orientation={orientation}>
      <HamburgerChartLayout
        borderColor={borderColor}
        layers={layers}
        layersGap={layersGap}
        $orientation={orientation}
        $data={data}
        startValue={startValue}
        ref={element => {
          containerRef(element);
          layoutRef.current = element;
        }}
        onMouseOut={() => tooltip.hideTooltip()}
        $height={height}
      >
        {layers.map((l, i) => (
          <LayerLayout
            key={i}
            backgroundColor={getLayerColor(l, highlights, data)}
          />
        ))}
        {points.map((p, i) => (
          <PointLayout
            key={i}
            $orientation={orientation}
            point={p}
            $value={
              data[p.name] !== undefined
                ? getPointValue(data[p.name], startValue, maxValue)
                : undefined
            }
            fillColor={
              p.fillColor ??
              getHamburgerPointColorFromLayerColor(layers, data, data[p.name])
            }
            onMouseEnter={(e: MouseEvent) => {
              if (tooltipTimeout.current) {
                clearTimeout(tooltipTimeout.current);
              }
              const layout = layoutRef.current;
              if (!layout) {
                return;
              }
              const mousePosition = localPoint(layout, e);
              const left = mousePosition?.x;
              const tooltipData: TooltipData = getTooltipData(data[p.name]);
              const tooltipTop = mousePosition?.y;
              tooltip.showTooltip({
                tooltipData,
                tooltipTop,
                tooltipLeft: left
              });
            }}
            onMouseOut={() => {
              tooltipTimeout.current = window.setTimeout(
                () => tooltip.hideTooltip(),
                TOOLTIP_TIMEOUT
              );
            }}
          />
        ))}
        {tooltip.tooltipOpen && (
          <Tooltip TooltipInPortal={TooltipInPortal} tooltip={tooltip} />
        )}
      </HamburgerChartLayout>
    </HamburgerChartWrapper>
  );
}
