import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import moment from 'moment';

import {
  AltitudeAndDistance,
  ButtonAppearance,
  ChartWidthProvider,
  ContentBox,
  ContentBoxHead,
  ExternalIcon,
  HeartRate,
  HeartRateZone,
  SegmentedButton,
  SegmentedControl,
  StyledFullScreenLayerContent,
  SyncTooltipHandlers,
  Text,
  TextAlignment,
  TextSize,
  TextTag,
  TickRendererProps,
  ZoneData,
  Zones
} from '@yarmill/components';

import { ChartIcon } from './chart-icon';
import { ChartLoader } from './chart-loader';
import {
  useSampledWorkoutData,
  useWorkoutDetailStore,
  useZonesData,
  useZonesDefinition
} from './hooks';
import { Summary } from './summary';
import { AltitudeAndDistanceTooltip, HeartRateTooltip } from './tooltips';
import {
  formatDistance,
  formatDistanceWithUnit,
  formatSecondsWithUnit,
  formatSecondsWithWhitespaceHack,
  formatZonesDuration
} from './utils';
import { observer } from 'mobx-react-lite';
import { Text as VisXText } from '@visx/text';
import { Tippy } from '../components/tippy/tippy';

const MARGIN_RIGHT = 26;

const StyledGridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'summary   zones'
    'switch switch'
    'heartrate heartrate'
    'distance  distance';
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  @media (max-width: 600px) {
    grid-template-columns: 100%;
    grid-template-areas:
      'summary'
      'zones'
      'switch'
      'heartrate'
      'distance';
  }
`;

const ChartWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;
const StyledSummary = styled(ContentBox)`
  grid-area: summary;
`;

const StyledZones = styled(ContentBox)`
  grid-area: zones;
`;

const StyledChartHead = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: 500;
`;

const StyledHeadline = styled(Text)`
  margin-bottom: 24px;
`;

const StyledHeartRate = styled(ContentBox)`
  grid-area: heartrate;
  padding-right: 0;
`;

const StyledAltitudeAndDistance = styled(ContentBox)`
  grid-area: distance;
  padding-right: 0;
`;

const StyledSwitchContainer = styled.div`
  grid-area: switch;
  text-align: right;
`;

const StyledWorkoutDetail = styled(StyledFullScreenLayerContent)`
  max-width: 1000px;
  margin: 0 auto;
`;

const StyledLoaderWrapper = styled.div`
  margin-right: ${MARGIN_RIGHT}px;
`;

const StyledDate = styled.span`
  text-transform: capitalize;
`;

export const WorkoutDetail = observer(function WorkoutDetail(): JSX.Element {
  const workoutDetailStore = useWorkoutDetailStore();
  const detail = workoutDetailStore.workout;

  const [xAxisKey, setXAxisKey] = useState<'time' | 'distance'>('time');
  const sampledWorkoutData = useSampledWorkoutData(detail, xAxisKey);
  const zonesData: ZoneData[] = useZonesData(detail);
  const zonesDefinition: HeartRateZone[] = useZonesDefinition(detail);
  const syncTooltipHandlersList = useRef<SyncTooltipHandlers[]>([]);

  const setSyncTooltipHandlers = useCallback(
    (handlers: SyncTooltipHandlers) => {
      syncTooltipHandlersList.current.push(handlers);
      return () => {
        const index = syncTooltipHandlersList.current.indexOf(handlers);
        if (index !== -1) {
          syncTooltipHandlersList.current.splice(index, 1);
        }
      };
    },
    [syncTooltipHandlersList]
  );
  const syncTooltipHandlers: SyncTooltipHandlers = useMemo(() => {
    return {
      showTooltip: (index: number, tooltipTop: number, tooltipLeft: number) => {
        syncTooltipHandlersList.current.forEach(handlers => {
          handlers.showTooltip(index, tooltipTop, tooltipLeft);
        });
      },
      hideTooltip: () => {
        syncTooltipHandlersList.current.forEach(handlers => {
          handlers.hideTooltip();
        });
      }
    };
  }, []);

  const totalTime =
    detail && detail.TimeEvolution
      ? detail.TimeEvolution[detail.TimeEvolution.length - 1]
      : 0;

  const totalDistance =
    detail && detail.TotalDistance !== null ? detail.TotalDistance : 0;

  const selectTime = useCallback(() => setXAxisKey('time'), [setXAxisKey]);
  const selectDistance = useCallback(
    () => setXAxisKey('distance'),
    [setXAxisKey]
  );

  const formatZoneLabel = useCallback(
    (zone: number): string => {
      const zoneDefinition = zonesDefinition.find(z => z.id === zone);

      return zoneDefinition?.name ?? `Z${zoneDefinition?.id}`;
    },
    [zonesDefinition]
  );

  const zoneLabelComponent = useCallback(
    ({ zoneId, ...props }: TickRendererProps & { zoneId?: number }) => {
      const zoneDefinition = zonesDefinition.find(z => z.id === zoneId);
      const zoneLabel = props.formattedValue;

      const Comp = forwardRef<SVGSVGElement>((_, ref) => (
        <VisXText {...props} verticalAnchor="middle" innerRef={ref}>
          {zoneLabel}
        </VisXText>
      ));

      return (
        <Tippy
          tooltipContent={
            <Text
              tag={TextTag.div}
              size={TextSize.s12}
              inheritColor
              textAlign={TextAlignment.center}
            >
              {zoneDefinition?.bottom} - {zoneDefinition?.top}
            </Text>
          }
          noWrapper
        >
          <Comp />
        </Tippy>
      );
    },
    [zonesDefinition]
  );

  return (
    <StyledWorkoutDetail>
      <StyledHeadline tag={TextTag.h1} size={TextSize.s24}>
        {detail && (
          <>
            <FormattedMessage id={detail.Label} />
            &nbsp;-&nbsp;
            <StyledDate>
              <FormattedDate
                value={moment(detail.StartTime).toDate()}
                day="numeric"
                month="numeric"
                year="numeric"
                weekday="long"
              />
            </StyledDate>
          </>
        )}
        {!detail && workoutDetailStore.name && workoutDetailStore.date && (
          <>
            <FormattedMessage id={workoutDetailStore.name} />
            &nbsp;-&nbsp;
            <StyledDate>
              <FormattedDate
                value={moment(workoutDetailStore.date).toDate()}
                day="numeric"
                month="numeric"
                year="numeric"
                weekday="long"
              />
            </StyledDate>
          </>
        )}
      </StyledHeadline>
      <StyledGridLayout>
        <StyledSummary>
          <ContentBoxHead>
            <StyledChartHead>
              <ChartIcon>
                <ExternalIcon name="DeviceWatch" />
              </ChartIcon>
              <Text medium>
                <FormattedMessage id="workout.detail.summary" />
              </Text>
            </StyledChartHead>
          </ContentBoxHead>
          <Summary detail={detail} />
        </StyledSummary>
        <StyledZones>
          <ContentBoxHead>
            <StyledChartHead>
              <ChartIcon>
                <ExternalIcon name="ChartArcs3" />
              </ChartIcon>
              <Text medium>
                <FormattedMessage id="workout.detail.zones" />
              </Text>
            </StyledChartHead>
          </ContentBoxHead>
          <ChartWrapper>
            <ChartWidthProvider>
              {width =>
                detail ? (
                  zonesData.length ? (
                    <Zones
                      width={width}
                      height={165}
                      data={zonesData}
                      formatDuration={formatZonesDuration}
                      formatZoneLabel={formatZoneLabel}
                      zoneLabelComponent={zoneLabelComponent}
                    />
                  ) : (
                    <Text size={TextSize.s14}>
                      <FormattedMessage id="workout.detail.missingData" />
                    </Text>
                  )
                ) : (
                  <ChartLoader width={width} height={150} />
                )
              }
            </ChartWidthProvider>
          </ChartWrapper>
        </StyledZones>
        <StyledSwitchContainer>
          <SegmentedControl>
            <SegmentedButton
              active={xAxisKey === 'time'}
              onClick={selectTime}
              appearance={ButtonAppearance.Navigation}
            >
              <FormattedMessage id="workout.detail.xAxis.time" />
            </SegmentedButton>
            <SegmentedButton
              active={xAxisKey === 'distance'}
              onClick={selectDistance}
              appearance={ButtonAppearance.Navigation}
            >
              <FormattedMessage id="workout.detail.xAxis.distance" />
            </SegmentedButton>
          </SegmentedControl>
        </StyledSwitchContainer>
        <StyledHeartRate>
          <ContentBoxHead>
            <StyledChartHead>
              <ChartIcon>
                <ExternalIcon name="Heart" />
              </ChartIcon>
              <Text medium>
                <FormattedMessage id="workout.detail.heartRate" />
              </Text>
            </StyledChartHead>
          </ContentBoxHead>
          <ChartWrapper>
            <ChartWidthProvider>
              {width =>
                detail ? (
                  sampledWorkoutData.validHR ? (
                    <HeartRate
                      data={sampledWorkoutData.chartsData}
                      height={250}
                      width={width}
                      showTooltip
                      showZones
                      zones={zonesDefinition}
                      xAxisKey={xAxisKey}
                      syncTooltipHandlers={syncTooltipHandlers}
                      setSyncTooltipHandlers={setSyncTooltipHandlers}
                      formatXAxisTicks={
                        xAxisKey === 'time'
                          ? formatSecondsWithWhitespaceHack
                          : formatDistance
                      }
                      formatTooltipCursor={
                        xAxisKey === 'time'
                          ? formatSecondsWithUnit
                          : formatDistanceWithUnit
                      }
                      total={xAxisKey === 'time' ? totalTime : totalDistance}
                      domain={
                        detail &&
                        detail.HeartRateMax !== null &&
                        detail.HeartRateMin !== null
                          ? [
                              Math.max(detail.HeartRateMin - 20, 30),
                              Math.min(detail.HeartRateMax + 20, 220)
                            ]
                          : undefined
                      }
                      formatTooltip={HeartRateTooltip}
                    />
                  ) : (
                    <Text size={TextSize.s14}>
                      <FormattedMessage id="workout.detail.missingData" />
                    </Text>
                  )
                ) : (
                  <StyledLoaderWrapper>
                    <ChartLoader width={width - MARGIN_RIGHT} height={250} />
                  </StyledLoaderWrapper>
                )
              }
            </ChartWidthProvider>
          </ChartWrapper>
        </StyledHeartRate>
        <StyledAltitudeAndDistance>
          <ContentBoxHead>
            <StyledChartHead>
              <ChartIcon>
                <ExternalIcon name="Mountain" />
              </ChartIcon>
              <Text medium>
                <FormattedMessage id="workout.detail.altitudeAndDistance" />
              </Text>
            </StyledChartHead>
          </ContentBoxHead>
          <ChartWrapper>
            <ChartWidthProvider>
              {width =>
                detail ? (
                  sampledWorkoutData.validAltitude &&
                  sampledWorkoutData.validDistance ? (
                    <AltitudeAndDistance
                      data={sampledWorkoutData.chartsData}
                      height={250}
                      width={width}
                      showTooltip
                      total={xAxisKey === 'time' ? totalTime : totalDistance}
                      xAxisKey={xAxisKey}
                      formatXAxisTicks={
                        xAxisKey === 'time'
                          ? formatSecondsWithWhitespaceHack
                          : formatDistance
                      }
                      formatTooltipCursor={
                        xAxisKey === 'time'
                          ? formatSecondsWithUnit
                          : formatDistanceWithUnit
                      }
                      formatTooltip={AltitudeAndDistanceTooltip}
                      syncTooltipHandlers={syncTooltipHandlers}
                      setSyncTooltipHandlers={setSyncTooltipHandlers}
                    />
                  ) : (
                    <Text size={TextSize.s14}>
                      <FormattedMessage id="workout.detail.missingData" />
                    </Text>
                  )
                ) : (
                  <StyledLoaderWrapper>
                    <ChartLoader width={width} height={250} />
                  </StyledLoaderWrapper>
                )
              }
            </ChartWidthProvider>
          </ChartWrapper>
        </StyledAltitudeAndDistance>
      </StyledGridLayout>
    </StyledWorkoutDetail>
  );
});
