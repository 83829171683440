export enum Units {
  km = 'km',
  min = 'min',
  shot = 'shot',
  count = 'count',
  hod = 'hod',
  m = 'm',
  bit = 'bit',
  longTime = 'longTime'
}
