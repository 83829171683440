import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { trackMainNavClick } from '../google-analytics/utils';
import { LinkCallback } from '../routes/types';
import { NavLinkContent } from './nav-link-conent';
import styled from 'styled-components';
import {
  ExternalIcon,
  ExternalIconName,
  NavigationLink
} from '@yarmill/components';
import { useNavigationStore } from './hooks';
import { observer } from 'mobx-react-lite';
import { useLocation } from '../routes/hooks';
import { useContext } from 'react';
import { DropdownContext } from './utils';

export interface NavItemProps {
  to: LinkCallback;
  moduleKey: string;
  linkText?: string;
  icon?: ExternalIconName;
  className?: string;
  onClick?(): void;
  isLinkActive?(pathname: string): boolean;
}

const StyledNavLink = styled(NavLink)<{ readonly $isActiveLink: boolean }>`
  ${NavigationLink}
`;

function isActive(pathname: string, moduleKey: string): boolean {
  if (pathname.includes('evidence')) {
    const regex = new RegExp(`\\b${moduleKey}\\b`);
    return pathname.search(regex) > 0;
  }
  return pathname.startsWith(`/${moduleKey}`);
}

function InternalNavItem(props: NavItemProps): JSX.Element {
  const { to, icon, moduleKey, onClick, linkText, isLinkActive } = props;

  const navigationStore = useNavigationStore();
  const location = useLocation();
  const closeDropdown = useContext(DropdownContext);
  const handleClick = React.useCallback(() => {
    trackMainNavClick(moduleKey);
    navigationStore.closeNavigation();
    closeDropdown?.();
    onClick?.();
  }, [moduleKey, navigationStore, onClick, closeDropdown]);

  const isActiveLink = isLinkActive
    ? isLinkActive(location.pathname)
    : isActive(location.pathname, moduleKey);

  const link = icon && <ExternalIcon name={icon} />;

  const text = linkText || (
    <FormattedMessage id={`header.navigation.${moduleKey}`} />
  );

  return (
    <StyledNavLink
      data-cy={moduleKey}
      to={to}
      isActive={(match, location) =>
        isLinkActive
          ? isLinkActive(location.pathname)
          : isActive(location.pathname, moduleKey)
      }
      onClick={handleClick}
      $isActiveLink={isActiveLink}
    >
      <NavLinkContent linkText={text} linkIcon={link} />
    </StyledNavLink>
  );
}

export const NavItem = React.memo(observer(InternalNavItem));
