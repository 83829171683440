import { CSSProperties, MouseEvent, useRef } from 'react';
import { ScaleTypeToD3Scale } from '@visx/scale';
import { Bar } from '@visx/shape';
import styled from 'styled-components';
import { useTooltip } from '@visx/tooltip';
import { FunctionComponent } from 'react';
import { TooltipInPortalProps } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import { localPoint } from '@visx/event';
import { ChartTooltip } from '../chart-tooltip';
import { GetTooltipData, TooltipData } from '../reporting/types';

export interface TooltipBarsProps {
  yScale: ScaleTypeToD3Scale<string, string>['band'];
  xMax: number;
  categories: string[];
  TooltipInPortal: FunctionComponent<TooltipInPortalProps>;
  getTooltipData: GetTooltipData;
  showYAxisLabelsInTooltip?: boolean;
}

const StyledTooltipBar = styled(Bar)`
  fill: transparent;
  :hover {
    fill: rgba(0, 0, 0, 0.05);
  }
`;

const tooltipStyle: CSSProperties = {
  top: 0,
  left: 0,
  position: 'absolute',
  backgroundColor: 'transparent',
  pointerEvents: 'none'
};

const TOOLTIP_TIMEOUT = 50;

export function Tooltip(props: TooltipBarsProps): JSX.Element {
  const { yScale, xMax, TooltipInPortal, getTooltipData, categories } = props;
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
  } = useTooltip<TooltipData>();
  const tooltipTimeout = useRef<number>(0);

  const bandwidth = yScale.bandwidth();
  const step = yScale.step();

  const handleMouseLeave = () => {
    tooltipTimeout.current = window.setTimeout(
      () => hideTooltip(),
      TOOLTIP_TIMEOUT
    );
  };

  return (
    <>
      {categories.map(category => {
        const barY = (yScale(category) || 0) - (step - bandwidth) / 2;
        return (
          <StyledTooltipBar
            key={category}
            width={xMax}
            y={barY}
            height={step}
            onMouseMove={(event: MouseEvent) => {
              if (tooltipTimeout.current) {
                clearTimeout(tooltipTimeout.current);
              }
              const mousePosition = localPoint(event);
              const tooltipData: TooltipData = getTooltipData(category);

              showTooltip({
                tooltipData,
                tooltipTop: mousePosition?.y,
                tooltipLeft: mousePosition?.x
              });
            }}
            onMouseLeave={handleMouseLeave}
          />
        );
      })}
      {tooltipOpen && tooltipData && tooltipData.values.length > 0 && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyle}
        >
          <ChartTooltip
            key={tooltipData.key}
            label={tooltipData.showLabel ? tooltipData.label : ''}
            items={tooltipData.values}
            tableLayout={tooltipData.tableLayout}
            showTableColumnsLabels={tooltipData.showColumnLabels}
            tableColumnsLabels={tooltipData.tableColumnsLabels}
          />
        </TooltipInPortal>
      )}
    </>
  );
}
