import * as React from 'react';
import styled from 'styled-components';

export interface WorkoutSampleProps {
  name: React.ReactNode;
  time: React.ReactNode;
  duration: React.ReactNode;
  distance: React.ReactNode;
  hr: React.ReactNode;
  chart: React.ReactNode;
  onClick?(): void;
}

const WorkoutSampleWrapper = styled.div`
  display: grid;
  grid-template-columns: 210px auto;
  grid-column-gap: 20px;
  grid-row-gap: 0;
  text-align: left;
  padding: 0;
  cursor: pointer;

  @media (min-width: 991px) and (max-width: 1240px), (max-width: 374px) {
    grid-column-gap: 10px;
  }

  background-color: #f5f7fa;
  border-radius: 4px;
  border: 1px solid transparent;

  @media (min-width: 991px) and (max-width: 1240px), (max-width: 374px) {
    grid-template-columns: 140px auto;
  }

  &:hover {
    border-color: #4a90e2;
    cursor: pointer;
  }
`;

const StyledWorkoutName = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  color: #4a4a4a;
  margin-top: 14px;
  margin-left: 28px;
  white-space: nowrap;

  @media (min-width: 991px) and (max-width: 1240px) {
    margin-top: 7px;
    margin-left: 14px;
  }
`;
const StyledData = styled.div`
  grid-area: 2 / 1 / 2 / 2;
  color: #9b9b9b;
  margin-left: 28px;
  margin-bottom: 14px;
  white-space: nowrap;

  @media (min-width: 991px) and (max-width: 1240px) {
    margin-bottom: 7px;
    margin-left: 14px;
  }
`;

const StyledChartWrapper = styled.div`
  grid-area: 1 / 2 / 3 / 3;
  margin-top: 13px;
  margin-bottom: 13px;
  margin-right: 28px;
  align-self: stretch;
  justify-self: stretch;
  display: flex;
  justify-content: stretch;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (min-width: 991px) and (max-width: 1240px), (max-width: 374px) {
    margin-top: 7px;
    margin-bottom: 7px;
    margin-right: 14px;
  }
`;

const StyledSeparator = styled.span`
  display: inline-block;
  margin: 0 8px;
  opacity: 0.5;
`;

const StyledTime = styled.span`
  color: #9b9b9b;
  display: inline-block;
  margin-left: 5px;
`;

const StyledHR = styled.span`
  @media (min-width: 991px) and (max-width: 1240px), (max-width: 374px) {
    display: none;
  }
`;

function Separator(): JSX.Element {
  return <StyledSeparator>|</StyledSeparator>;
}

export function WorkoutSample(props: WorkoutSampleProps): JSX.Element {
  return (
    <WorkoutSampleWrapper onClick={props.onClick} role="button">
      <StyledWorkoutName>
        {props.name}
        <StyledTime>{props.time}</StyledTime>
      </StyledWorkoutName>
      <StyledData>
        {props.duration}
        <Separator />
        {props.distance}
        <StyledHR>
          <Separator />
          {props.hr}
        </StyledHR>
      </StyledData>
      <StyledChartWrapper>{props.chart}</StyledChartWrapper>
    </WorkoutSampleWrapper>
  );
}
