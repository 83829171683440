import { KeyResult } from './types';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import {
  DatepickerButton,
  KeyResultDragHandle,
  KeyResultsTableTd,
  KeyResultTdBg
} from '../components-2/okrs/detail/key-result-detail';
import { useContext } from 'react';
import { getStatusAppearance } from '../components-2/status';
import { Cal, Grid } from '@yarmill/icons-2';

import { KeyResultCheckbox } from '../components-2/okrs/key-result-checkbox';
import { KeyResultStore } from './mobx/key-result-store';
import { Icon, IconSize } from '@yarmill/components';
import { FlexCenterContainer } from '../components-2/flex-utils';
import { TextInput } from '../components-2/text-input';
import { KeyResultsSelectionContext } from './key-results-selection-context';
import { StatusDropdown } from './dropdowns/status';
import { ButtonAppearance } from '../components-2/button';
import moment from 'moment/moment';

interface KeyResultDetailDraggedProps {
  readonly keyResult: KeyResultStore;
}

interface KeyResultTextInputProps {
  readonly keyResult: KeyResultStore;
  readonly name: keyof KeyResult;
  readonly alignLeft?: boolean;
}

function KeyResultTextInput({
  name,
  keyResult,
  alignLeft
}: KeyResultTextInputProps): JSX.Element {
  const intl = useIntl();

  return (
    <>
      <KeyResultTdBg
        appearance={getStatusAppearance(keyResult.status ?? undefined)}
      />
      <TextInput
        placeholder={intl.formatMessage({
          id: `okrs.form.keyResult.${name}.placeholder`
        })}
        name={name}
        align={alignLeft ? 'left' : 'right'}
        defaultValue={keyResult[name]}
        appearance={getStatusAppearance(keyResult.status ?? undefined)}
        as="textarea"
        rows={1}
        maxRows={2}
      />
    </>
  );
}

function DraggedDragHandle(): JSX.Element {
  return (
    <KeyResultDragHandle isDragged>
      <Icon size={IconSize.s16}>
        <Grid />
      </Icon>
    </KeyResultDragHandle>
  );
}

export const KeyResultDetailDragged = observer(function KeyResultDetail({
  keyResult
}: KeyResultDetailDraggedProps): JSX.Element {
  const selectionStore = useContext(KeyResultsSelectionContext);

  return (
    <>
      <KeyResultsTableTd>
        <DraggedDragHandle />
      </KeyResultsTableTd>
      <KeyResultsTableTd textAlign="left">
        <FlexCenterContainer as="label">
          <KeyResultCheckbox
            isChecked={selectionStore.selectedKeyResults.has(keyResult.uid)}
          />
        </FlexCenterContainer>
      </KeyResultsTableTd>
      <KeyResultsTableTd>
        <StatusDropdown
          selectedValue={keyResult.status}
          handleSelect={() => null}
          hideText
          noBackground
          variant="large"
        />
      </KeyResultsTableTd>
      <KeyResultsTableTd>
        <KeyResultTextInput name="title" keyResult={keyResult} alignLeft />
      </KeyResultsTableTd>
      <KeyResultsTableTd>
        <KeyResultTextInput name="startValue" keyResult={keyResult} />
      </KeyResultsTableTd>
      <KeyResultsTableTd>
        <KeyResultTextInput name="currentValue" keyResult={keyResult} />
      </KeyResultsTableTd>
      <KeyResultsTableTd>
        <KeyResultTextInput name="targetValue" keyResult={keyResult} />
      </KeyResultsTableTd>
      <KeyResultsTableTd>
        <DatepickerButton
          as="div"
          $appearance={ButtonAppearance.Secondary}
          $appearanceStyle="tangerine"
          stretch
        >
          <Icon size={IconSize.s16}>
            <Cal />
          </Icon>
          {keyResult.targetDate ? (
            <FormattedDate
              value={keyResult.targetDate}
              day="numeric"
              month="numeric"
              year={
                moment(keyResult.targetDate).isSame(moment(), 'year')
                  ? undefined
                  : 'numeric'
              }
            />
          ) : (
            <FormattedMessage id="okrs.form.keyResult.targetDate.addDate" />
          )}
        </DatepickerButton>
      </KeyResultsTableTd>
      <KeyResultsTableTd>&nbsp;</KeyResultsTableTd>
    </>
  );
});
