import { Fragment, PropsWithChildren } from 'react';
import styled from 'styled-components';

const PrintWrapper = styled.div`
  display: block;
  position: relative;
  page-break-inside: avoid;
`;
export function PageBreakPrintWrapper(
  props: PropsWithChildren<{}>
): JSX.Element {
  const { children } = props;
  const isBrowser = typeof 'window' === 'object';
  const Wrapper = isBrowser ? Fragment : PrintWrapper;

  return <Wrapper>{children}</Wrapper>;
}
