import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { Cgm, CgmId } from '../types';

export function getCgmDetail(
  cgmId: CgmId,
  cancelToken?: CancelTokenSource
): AxiosPromise<Cgm> {
  return axios.get('api/data-services/cgm/details', {
    params: cgmId,
    cancelToken: cancelToken?.token
  });
}
