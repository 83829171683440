import { Button, ButtonAppearance } from '../../components-2/button';
import { Icon, IconSize } from '@yarmill/components';
import { useIntl } from 'react-intl';
import { Pastebox } from '@yarmill/icons-2';
import { Tippy } from '../../components/tippy/tippy';

interface ShowImporterProps {
  readonly show: () => void;
}
export function ShowImporter({ show }: ShowImporterProps): JSX.Element {
  const intl = useIntl();
  return (
    <Tippy tooltipContent="planner.showImporter" noWrapper>
      <Button
        $appearance={ButtonAppearance.Secondary}
        $appearanceStyle="neutral"
        $iconOnly
        onClick={show}
        aria-label={intl.formatMessage({
          id: 'planner.showImporter'
        })}
        type="button"
      >
        <Icon size={IconSize.s24}>
          <Pastebox />
        </Icon>
      </Button>
    </Tippy>
  );
}
