import * as React from 'react';
import { TextArea, TextInput } from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { FormFieldProps } from './form-field';
import { useErrorMessage, useInvalidFieldFocusHandler } from '../hooks';
import { getFormFieldAttributes } from '../utils';
import { useIntl } from 'react-intl';

export const Text = observer(function Text(props: FormFieldProps): JSX.Element {
  const { attributeStore, autoFocus = true } = props;
  const intl = useIntl();
  const commonAttributes = getFormFieldAttributes(attributeStore, intl);
  const error = useErrorMessage(attributeStore);
  const elementRef = useInvalidFieldFocusHandler<
    HTMLInputElement & HTMLTextAreaElement
  >(attributeStore);

  const Element =
    attributeStore.definition.AttributeTypeKey === 'long-text'
      ? TextArea
      : TextInput;

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { value }
      } = e;

      attributeStore?.onChange(value);
    },
    [attributeStore]
  );

  const value = attributeStore.formValue;
  const rows = Element === TextArea ? value.split('\n').length + 1 : undefined;

  const isCondensedObject =
    attributeStore.objectDataStore.objectStore.definition.Features?.Condensed;

  return (
    <Element
      {...commonAttributes}
      autoFocus={autoFocus}
      id={String(attributeStore.id)}
      onChange={handleChange}
      onBlur={attributeStore.onBlur}
      onFocus={attributeStore.onFocus}
      error={error}
      value={value}
      ref={elementRef}
      rows={rows}
      autoSize
      noLabel={isCondensedObject && !commonAttributes.label}
      noError={isCondensedObject && !error}
    />
  );
});
