import {
  AddHRZoneButton,
  Button,
  ButtonAppearance,
  ExternalIcon,
  FormControlVariant,
  FullScreenLayerCloseButtonWrapper,
  HRZonesFormButtonsWrapper,
  HRZonesFormContainer,
  HRZonesFormElementsWrapper,
  Icon,
  PATTERN_INPUT_EMPTY_VALUE,
  SelectBox,
  StyledFullScreenLayerContent,
  Text,
  TextSize,
  TextTag
} from '@yarmill/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Layer } from '../layer-manager/mobx/layer';
import { HeartRateZonesSetStore } from './mobx/heart-rate-zones-set-store';
import { PatternInputDate } from '../components/pattern-input-date';
import {
  createDateFormatValidator,
  extractDateFormat,
  extractDelimiters
} from '../utils/extract-date-format';
import {
  ComponentType,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { HeartRateZoneRow } from './heart-rate-zone-row';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Tippy } from '../components/tippy/tippy';
import { useRootStore } from '../app/root-store-context';
import moment from 'moment';
import { DATE_FORMAT, ROUTE_DATE_FORMAT } from '../diary/utils';
import { AvailableSport } from './types';
import { toast } from '../components/toast-message';
import { HeartRateZoneStore } from './mobx/heart-rate-zone-store';
import { runInAction } from 'mobx';

interface AddLayerProps {
  layer: Layer;
  heartRateZonesSet: HeartRateZonesSetStore;
}

const HeartRazeZonesForm = styled(StyledFullScreenLayerContent)`
  margin: 0 auto;
`;

const NonEditableTooltip: ComponentType<any> = ({
  children
}: {
  children: JSX.Element;
}) => (
  <Tippy
    tooltipContent="settings.heartRateZones.defaultSet.notEditable"
    Wrapper="div"
  >
    {children}
  </Tippy>
);

export const HeartRateZoneForm = observer(function HeartRateZoneForm({
  layer,
  heartRateZonesSet
}: AddLayerProps): JSX.Element {
  const intl = useIntl();
  const dateFormat = extractDateFormat(intl);
  const formRef = useRef<HTMLFormElement>(null);
  const heartRateZoneManager = useRootStore().heartRateZonesManagerStore;
  const isNew = useRef(
    !heartRateZoneManager.heartRateZonesSets.includes(heartRateZonesSet)
  ).current;
  const originalValues = useRef(heartRateZonesSet.toJS());
  const dateFormatDelimiter = extractDelimiters(dateFormat);
  const dateFormatValidator = createDateFormatValidator(dateFormat);
  const availableSports = heartRateZoneManager.availableSports;
  const [validFrom, setValidFrom] = useState(
    heartRateZonesSet.validFrom
      ? moment(heartRateZonesSet.validFrom).format(dateFormat)
      : ''
  );
  const [validTo, setValidTo] = useState(
    heartRateZonesSet.validTo
      ? moment(heartRateZonesSet.validTo).format(dateFormat)
      : ''
  );

  const translatedAvailableSports = useMemo(
    () =>
      availableSports.map(sport => ({
        ...sport,
        label: intl.formatMessage({ id: sport.label })
      })),
    [availableSports, intl]
  );

  useEffect(() => {
    if (moment(validFrom, dateFormat) > moment(validTo, dateFormat)) {
      setValidTo('');
      heartRateZonesSet.setValidTo('');
    }
  }, [dateFormat, heartRateZonesSet, validFrom, validTo]);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    let isNew = false;
    const currentDateStamp = moment().format(DATE_FORMAT);

    if (!heartRateZoneManager.heartRateZonesSets.includes(heartRateZonesSet)) {
      heartRateZoneManager.heartRateZonesSets.push(heartRateZonesSet);
      heartRateZonesSet.setCreated(currentDateStamp);
      isNew = true;
    }
    heartRateZonesSet.setLastUpdated(currentDateStamp);

    if (await heartRateZoneManager.save()) {
      toast(
        `toast.success.heartRateZones.${isNew ? 'add' : 'update'}Zone`,
        'success'
      );
    } else {
      toast(
        `toast.error.heartRateZones.${isNew ? 'add' : 'update'}Zone`,
        'error'
      );
    }
    layer.close();
  }

  const cancel = useCallback(
    function cancel(): void {
      const previousValue = originalValues.current;
      runInAction(() => {
        heartRateZonesSet.setSport(previousValue.sport);
        heartRateZonesSet.setValidFrom(previousValue.validFrom);
        heartRateZonesSet.setValidTo(previousValue.validTo);
        heartRateZonesSet.heartRateZones.clear();
        previousValue.zones.forEach(zone => {
          const zoneStore = new HeartRateZoneStore(zone);
          heartRateZonesSet.heartRateZones.push(zoneStore);
        });
      });
      layer.close();
    },
    [heartRateZonesSet, layer]
  );

  function onDateChange(
    value: string,
    setValue: (value: string) => void,
    setValueToStore: (value: string) => void
  ): void {
    setValue(value);
    if (
      value.length === dateFormat.length &&
      !value.includes(PATTERN_INPUT_EMPTY_VALUE)
    ) {
      const date = moment(value, dateFormat);
      if (date.isValid()) {
        setValueToStore(date.format(ROUTE_DATE_FORMAT));
      }
    } else if (!value) {
      setValueToStore('');
    }
  }

  useEffect(() => {
    const formElement = formRef.current;
    function handleKeyDown(e: KeyboardEvent): void {
      if (e.key === 'Escape') {
        const activeElement = document.activeElement;
        const shouldCloseLayer =
          !activeElement?.className.includes('react_select') ||
          !activeElement
            ?.closest('.react_select__control')
            ?.className.includes('menu-is-open');

        if (shouldCloseLayer) {
          cancel();
        }
      }
    }

    formElement?.addEventListener('keydown', handleKeyDown);

    return () => {
      formElement?.addEventListener('keydown', handleKeyDown);
    };
  }, [cancel]);

  return (
    <>
      <HeartRazeZonesForm as="form" onSubmit={handleSubmit} ref={formRef}>
        <HRZonesFormElementsWrapper>
          <Text tag={TextTag.h1} size={TextSize.s24}>
            <FormattedMessage
              id={
                isNew
                  ? `settings.heartRateZones.newItemHeadline`
                  : `settings.heartRateZones.updateItemHeadline`
              }
            />
          </Text>
          <SelectBox
            disabled={heartRateZonesSet.isDefault}
            variant={FormControlVariant.big}
            label={intl.formatMessage({
              id: 'settings.heartRateZones.sport'
            })}
            id="sport"
            name="sport"
            autoFocus={!heartRateZonesSet.isDefault}
            value={translatedAvailableSports.find(
              ({ value }) => value === heartRateZonesSet.sport
            )}
            options={translatedAvailableSports}
            onChange={(option: AvailableSport) =>
              heartRateZonesSet.setSport(option?.value ?? '')
            }
            tooltip={
              heartRateZonesSet.isDefault ? NonEditableTooltip : undefined
            }
            disablePortal
          />
          <PatternInputDate
            disabled={heartRateZonesSet.isDefault}
            readOnly={heartRateZonesSet.isDefault}
            variant={FormControlVariant.big}
            label={intl.formatMessage({
              id: 'settings.heartRateZones.validFrom'
            })}
            id="validFrom"
            name="validFrom"
            pattern={dateFormat}
            value={validFrom}
            validateValue={dateFormatValidator}
            delimiter={dateFormatDelimiter}
            onChange={value =>
              onDateChange(value, setValidFrom, v =>
                heartRateZonesSet.setValidFrom(v)
              )
            }
            tooltip={
              heartRateZonesSet.isDefault ? NonEditableTooltip : undefined
            }
          />
          <PatternInputDate
            disabled={heartRateZonesSet.isDefault}
            readOnly={heartRateZonesSet.isDefault}
            variant={FormControlVariant.big}
            label={intl.formatMessage({
              id: 'settings.heartRateZones.validTo'
            })}
            id="validTo"
            name="validTo"
            pattern={dateFormat}
            validateValue={dateFormatValidator}
            delimiter={dateFormatDelimiter}
            minBookingDate={
              validFrom
                ? moment(validFrom, dateFormat).toDate()
                : moment().toDate()
            }
            value={validTo}
            onChange={value =>
              onDateChange(value, setValidTo, v =>
                heartRateZonesSet.setValidTo(v)
              )
            }
            tooltip={
              heartRateZonesSet.isDefault ? NonEditableTooltip : undefined
            }
          />
          <HRZonesFormContainer>
            {heartRateZonesSet.heartRateZones.map((zone, idx) => (
              <HeartRateZoneRow
                key={zone.id}
                isFirst={idx === 0}
                isLast={idx === heartRateZonesSet.heartRateZones.length - 1}
                zone={zone}
                prevZone={heartRateZonesSet.heartRateZones[idx - 1]}
                nextZone={heartRateZonesSet.heartRateZones[idx + 1]}
                removeZone={() => heartRateZonesSet.removeZone(zone)}
                autoFocus={idx === 0 && heartRateZonesSet.isDefault}
              />
            ))}
          </HRZonesFormContainer>
          <Tippy
            tooltipContent="settings.heartRateZones.form.maxZones"
            placement="top"
            noWrapper
            isEnabled={
              heartRateZonesSet.heartRateZones.length ===
              HeartRateZonesSetStore.MAX_ZONES
            }
          >
            <AddHRZoneButton
              appearance={ButtonAppearance.Link}
              type="button"
              disabled={
                heartRateZonesSet.heartRateZones.length ===
                HeartRateZonesSetStore.MAX_ZONES
              }
              onClick={heartRateZonesSet.addZone}
            >
              <Icon>
                <ExternalIcon name="CirclePlus" />
              </Icon>
              &nbsp;
              <FormattedMessage id="settings.heartRateZones.form.addZone" />
            </AddHRZoneButton>
          </Tippy>
          <HRZonesFormButtonsWrapper>
            <Button
              appearance={ButtonAppearance.Secondary}
              variant={FormControlVariant.big}
              type="button"
              onClick={cancel}
            >
              <FormattedMessage id="settings.heartRateZones.close" />
            </Button>
            <Button
              appearance={ButtonAppearance.Primary}
              variant={FormControlVariant.big}
              type="submit"
              disabled={!heartRateZonesSet.isValid}
            >
              <FormattedMessage id="settings.heartRateZones.submit" />
            </Button>
          </HRZonesFormButtonsWrapper>
        </HRZonesFormElementsWrapper>
      </HeartRazeZonesForm>
      <FullScreenLayerCloseButtonWrapper>
        <Button appearance={ButtonAppearance.Link} onClick={cancel}>
          <Icon>
            <ExternalIcon name="X" />
          </Icon>
          <br />
          <FormattedMessage id="settings.modal.closeButton" />
        </Button>
      </FullScreenLayerCloseButtonWrapper>
    </>
  );
});
