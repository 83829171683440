import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { ObjectivePreview } from '../types';
import { SeasonId } from '../../seasons/types';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';
import { UserId } from '../../users/types';

export interface GetObjectivesRequestParams {
  userGroupId: number | undefined;
  seasonId: SeasonId;
}

export type GetObjectivesForGroupResponse = {
  UserId: UserId;
  Objectives: ObjectivePreview[];
}[];

export function getObjectivesForGroup(
  params: GetObjectivesRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<GetObjectivesForGroupResponse> {
  return axios.get(
    `${coreApiTarget}/api/okrs/userGroupId/${params.userGroupId}/seasonId/${params.seasonId}`,
    {
      cancelToken: cancelToken?.token,
      params: {
        instanceCode: devMode ? instance : undefined
      }
    }
  );
}
