import * as React from 'react';
import {
  ChangeEventHandler,
  ComponentType,
  KeyboardEventHandler,
  useMemo
} from 'react';

export interface PatternInputConfig {
  defaultValue?: string;
  pattern: string;
  delimiter?: string | string[];
  value?: string;
  tooltip?: ComponentType;
  validateValue?(newValue: string): boolean;
  onChange?(value: string): void;
}

interface PatternInputHandlers {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
  persistedValue: string;
}

type CursorPosition = number | { start: number; end: number };

export const PATTERN_INPUT_EMPTY_VALUE = '\xa0';

export function usePatternInput(
  config: PatternInputConfig
): PatternInputHandlers {
  const {
    validateValue,
    pattern,
    delimiter,
    onChange: passedOnChange,
    defaultValue,
    value
  } = config;

  const [persistedValue, setPersistedValue] = React.useState(
    defaultValue ? String(defaultValue) : ''
  );

  const onChange = React.useCallback(
    (e: React.FormEvent) => {
      const { target } = e;
      if (target instanceof HTMLInputElement) {
        let { value: newValue } = target;
        const isValueValid = validateValue ? validateValue(newValue) : true;
        if (isValueValid) {
          if (passedOnChange) {
            passedOnChange(newValue);
          }
          setPersistedValue(newValue);
        } else {
          e.preventDefault();
        }
      }
    },
    [validateValue, passedOnChange]
  );

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      const { target } = e;
      const previousValue = value !== undefined ? value : persistedValue;
      if (target instanceof HTMLInputElement) {
        let result: { value: string; cursorPosition: CursorPosition } | null =
          null;

        if (e.key === 'Backspace') {
          result = handleBackspace(
            target,
            previousValue,
            pattern,
            delimiter,
            e
          );
          result = clearEmptyValue(result, delimiter);
        } else if (e.key === 'Delete') {
          result = handleDelete(target, previousValue, pattern, delimiter, e);
          result = clearEmptyValue(result, delimiter);
        } else if (!e.ctrlKey && isTextInput(e)) {
          result = handleTextInput(target, pattern, delimiter, e.key, e);
        }

        const isValidValue = validateValue
          ? validateValue(result?.value || '')
          : true;

        if (result !== null && isValidValue) {
          if (value !== undefined && passedOnChange) {
            passedOnChange(result.value);
          }
          setPersistedValue(result.value);

          const selectionStart =
            typeof result.cursorPosition === 'number'
              ? result.cursorPosition
              : result.cursorPosition.start;
          const selectionEnd =
            typeof result.cursorPosition === 'number'
              ? result.cursorPosition
              : result.cursorPosition.end;

          setTimeout(
            () => target.setSelectionRange(selectionStart, selectionEnd),
            0
          );
        } else if (isTextInput(e) && !isValidValue) {
          e.preventDefault();
        }
      }
    },
    [value, persistedValue, validateValue, pattern, delimiter, passedOnChange]
  );

  return useMemo(
    () => ({ onKeyDown, onChange, persistedValue }),
    [onKeyDown, onChange, persistedValue]
  );
}

function isDelimiter(
  pattern: string,
  delimiter: string | string[] | undefined,
  cursorPosition: number
): boolean {
  if (!delimiter) {
    return false;
  }
  return Array.isArray(delimiter)
    ? delimiter.includes(pattern[cursorPosition])
    : delimiter === pattern[cursorPosition];
}

function handleTextInput(
  input: HTMLInputElement,
  pattern: string,
  delimiter: string | string[] | undefined,
  key: string,
  e: React.KeyboardEvent
): { value: string; cursorPosition: CursorPosition } | null {
  const newValue = input.value;
  const cursorPosition = input.selectionStart;

  if (
    cursorPosition !== null &&
    newValue.length < pattern.length &&
    isDelimiter(pattern, delimiter, cursorPosition + 1) &&
    !isDelimiter(newValue, delimiter, cursorPosition + 1)
  ) {
    const characters = newValue.split('');
    characters[cursorPosition] = key;
    characters[cursorPosition + 1] = pattern[cursorPosition + 1];
    const joinedValue = characters.join('');

    const newCursorPosition = Math.min(pattern.length, cursorPosition + 2);
    e.preventDefault();

    return { value: joinedValue, cursorPosition: newCursorPosition };
  }

  if (
    cursorPosition !== null &&
    newValue.length < pattern.length &&
    isDelimiter(pattern, delimiter, cursorPosition) &&
    !isDelimiter(newValue, delimiter, cursorPosition)
  ) {
    const characters = newValue.split('');
    characters[cursorPosition] = pattern[cursorPosition];
    characters[cursorPosition + 1] = key;
    const joinedValue = characters.join('');

    const newCursorPosition = Math.min(pattern.length, cursorPosition + 2);
    e.preventDefault();

    return { value: joinedValue, cursorPosition: newCursorPosition };
  }

  if (
    cursorPosition !== null &&
    isDelimiter(newValue, delimiter, cursorPosition) &&
    newValue[cursorPosition + 1] === PATTERN_INPUT_EMPTY_VALUE
  ) {
    const characters = newValue.split('');
    characters[cursorPosition + 1] = key;
    const joinedValue = characters.join('');

    const newCursorPosition = Math.min(pattern.length, cursorPosition + 2);
    e.preventDefault();

    return { value: joinedValue, cursorPosition: newCursorPosition };
  }

  if (cursorPosition !== null) {
    const characters = newValue.split('');
    characters[cursorPosition] = key;
    const joinedValue = characters.join('');

    let newCursorPosition = Math.min(pattern.length, cursorPosition + 1);

    if (isDelimiter(joinedValue, delimiter, newCursorPosition)) {
      newCursorPosition++;
    }

    e.preventDefault();

    return { value: joinedValue, cursorPosition: newCursorPosition };
  }

  return null;
}

function handleBackspace(
  input: HTMLInputElement,
  previousValue: string,
  pattern: string,
  delimiter: string | string[] | undefined,
  e: React.KeyboardEvent
): { value: string; cursorPosition: CursorPosition } | null {
  const cursorPosition = input.selectionStart;
  const cursorEnd = input.selectionEnd;

  if (
    cursorPosition !== null &&
    cursorEnd !== null &&
    cursorPosition === cursorEnd &&
    isDelimiter(pattern, delimiter, cursorPosition - 1)
  ) {
    const characters = previousValue.split('');
    characters[cursorPosition - 2] = PATTERN_INPUT_EMPTY_VALUE;
    const joinedValue = characters.join('');
    const newCursorPosition = Math.max(0, cursorPosition - 2);
    e.preventDefault();

    return { value: joinedValue, cursorPosition: newCursorPosition };
  }

  if (
    cursorPosition !== null &&
    cursorEnd !== null &&
    cursorPosition !== previousValue.length
  ) {
    const characters = previousValue.split('');
    const selectionLength = Math.abs(cursorEnd - cursorPosition) || 1;

    if (selectionLength === 1 && cursorPosition === cursorEnd) {
      characters[cursorPosition - 1] = PATTERN_INPUT_EMPTY_VALUE;
    } else if (
      selectionLength === pattern.length ||
      selectionLength === previousValue.length
    ) {
      characters.splice(0, selectionLength);
    } else {
      for (let i = cursorPosition; i < cursorEnd; i++) {
        if (!isDelimiter(characters[i], delimiter, 0)) {
          characters[i] = PATTERN_INPUT_EMPTY_VALUE;
        }
      }
    }

    const joinedValue = characters.join('');
    e.preventDefault();

    const newCursorPosition = Math.max(
      0,
      selectionLength === 1 && cursorPosition === cursorEnd
        ? cursorPosition - 1
        : cursorPosition
    );
    return { value: joinedValue, cursorPosition: newCursorPosition };
  }

  if (
    cursorPosition !== null &&
    cursorPosition === cursorEnd &&
    isDelimiter(pattern, delimiter, cursorPosition - 2) &&
    cursorPosition === previousValue.length
  ) {
    const updatedValue = previousValue.slice(0, -2);
    e.preventDefault();
    const newCursorPosition = Math.max(0, cursorPosition - 2);

    return { value: updatedValue, cursorPosition: newCursorPosition };
  }

  return null;
}

function handleDelete(
  input: HTMLInputElement,
  previousValue: string,
  pattern: string,
  delimiter: string | string[] | undefined,
  e: React.KeyboardEvent
): { value: string; cursorPosition: CursorPosition } | null {
  const cursorPosition = input.selectionStart;
  const cursorEnd = input.selectionEnd;

  if (
    cursorPosition !== null &&
    cursorEnd !== null &&
    cursorPosition === cursorEnd &&
    isDelimiter(pattern, delimiter, cursorPosition)
  ) {
    const characters = previousValue.split('');
    characters[cursorPosition + 1] = PATTERN_INPUT_EMPTY_VALUE;
    const joinedValue = characters.join('');
    const newCursorPosition = Math.max(0, cursorPosition + 1);
    e.preventDefault();

    return { value: joinedValue, cursorPosition: newCursorPosition };
  }

  if (
    cursorPosition !== null &&
    cursorEnd !== null &&
    cursorPosition !== previousValue.length
  ) {
    const characters = previousValue.split('');
    const selectionLength = Math.abs(cursorEnd - cursorPosition) || 1;

    if (selectionLength === 1 && cursorPosition === cursorEnd) {
      characters[cursorPosition] = PATTERN_INPUT_EMPTY_VALUE;
    } else if (
      selectionLength === pattern.length ||
      selectionLength === previousValue.length
    ) {
      characters.splice(0, selectionLength);
    } else {
      for (let i = cursorPosition; i < cursorEnd; i++) {
        if (!isDelimiter(characters[i], delimiter, 0)) {
          characters[i] = PATTERN_INPUT_EMPTY_VALUE;
        }
      }
    }

    const joinedValue = characters.join('');
    e.preventDefault();

    const newCursorPosition = Math.max(0, cursorPosition);
    return { value: joinedValue, cursorPosition: newCursorPosition };
  }

  return null;
}

function isTextInput(e: React.KeyboardEvent): boolean {
  const key = e.key.toLowerCase();
  if (key.length !== 1) {
    return false;
  }

  const isLetter = key >= 'a' && key <= 'z';
  const isNumber = key >= '0' && key <= '9';

  return isLetter || isNumber;
}

function clearEmptyValue(
  result: { value: string; cursorPosition: CursorPosition } | null,
  delimiters: string | string[] | undefined
): { value: string; cursorPosition: CursorPosition } | null {
  if (!result) {
    return result;
  }

  const { value, cursorPosition } = result;

  const characters = value.split('');
  const valueChars = characters.filter(
    char =>
      char !== PATTERN_INPUT_EMPTY_VALUE && !isDelimiter(char, delimiters, 0)
  ).length;
  const clearedValue = valueChars > 0 ? value : '';

  return { value: clearedValue, cursorPosition };
}
