import Select, { components, OptionProps } from 'react-select';
import styled, { css } from 'styled-components';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Text, TextSize } from '../text';
import { BaseMultiSelectStyles, Control } from './base-styles';

const StyledGroupsSelect = styled(Select)`
  ${BaseMultiSelectStyles}

  .groups-select__menu {
    margin-bottom: 24px;
  }
`;

const OptionLayout = styled.div<{ readonly $isFocused?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px;

  ${({ $isFocused }) =>
    $isFocused &&
    css`
      background-color: rgb(222, 235, 255);
      cursor: pointer;
    `};
`;

const Option = (props: OptionProps<GroupsSelectOption>) => {
  const { data, isFocused, innerProps } = props;

  return (
    <OptionLayout
      data-cy="group-select-option"
      {...innerProps}
      $isFocused={isFocused}
      ref={props.innerRef}
    >
      <Text size={TextSize.s14}>{data.label}</Text>
    </OptionLayout>
  );
};

export interface GroupsSelectOption {
  label: string;
  value: number;
}

const StyledMenuPortal = styled(components.MenuPortal)`
  z-index: 5000 !important;
  box-sizing: border-box;
  font-family: Ubuntu;

  .groups-select__menu-notice {
    font-size: 14px;
  }
`;

const customComponents = {
  Control,
  Option,
  MenuPortal: StyledMenuPortal
};
interface GroupsSelectProps {
  autoFocus?: boolean;
  options: GroupsSelectOption[];
  placeholder: string;
  onChange(groupIds: GroupsSelectOption[]): void;
  handleSubmit?(): void;
  noOptionsMessage?: string;
}
export function GroupsSelect({
  handleSubmit,
  onChange,
  autoFocus,
  placeholder,
  options,
  noOptionsMessage
}: GroupsSelectProps): JSX.Element {
  const isInitialMount = useRef<boolean>(true);
  const selectRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (!isMenuOpen) {
        const select = selectRef?.current as HTMLInputElement | null;
        select?.focus();
      }
    }
  }, [isMenuOpen]);

  const onKeyDown = useCallback(
    (evt: React.KeyboardEvent) => {
      if (!isMenuOpen && evt.key === 'Enter' && handleSubmit) {
        handleSubmit();
      }
      if (evt.key === 'Escape' && isMenuOpen) {
        evt.stopPropagation();
      }
    },
    [isMenuOpen, handleSubmit]
  );

  return (
    <div
      data-cy="group-select"
      className="group-select"
      aria-expanded={isMenuOpen}
    >
      <StyledGroupsSelect
        ref={selectRef}
        autoFocus={autoFocus}
        classNamePrefix="groups-select"
        minMenuHeight={200}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        onKeyDown={onKeyDown}
        onChange={onChange as any}
        components={customComponents as any}
        options={options}
        placeholder={placeholder}
        isMulti
        noOptionsMessage={() => noOptionsMessage}
      />
    </div>
  );
}
