import { RootStore } from '../../app/mobx/root-store';
import { DiaryStore } from '../../diary/mobx/diary-store';
import {
  TextAreaBasicData,
  TrainingDayAttribute
} from '../../training-day/types';
import { action, computed, makeObservable } from 'mobx';
import { GoalStore } from './goal-store';
import { getGoalIndexForDay } from '../utils';
import { times } from '../../utils/times';
import moment from 'moment';
import { getWeekEnd, getWeekStart, ROUTE_DATE_FORMAT } from '../../diary/utils';
import { DiaryDataUniqueId, DiaryEventData } from '../../diary/types';
import { groupBy } from 'ramda';

export const NUMBER_OF_GOALS_WEEKS = 4;

export const BASE_GOAL_ATTRIBUTE: TrainingDayAttribute = {
  AttributeItemId: 0,
  DataTypeIdent: 'textarea-basic',
  IsEditable: true,
  IsExtra: false,
  IsShown: true,
  Name: 'week-goal',
  Size: 0,
  SortCode: 0,
  ToolTip: '',
  Type: 'P'
};

export class GoalsStore {
  private readonly rootStore: RootStore;
  private readonly diaryStore: DiaryStore;
  private readonly dataId: DiaryDataUniqueId;
  private _goals: GoalStore[] = [];

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    dataId: DiaryDataUniqueId
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.diaryStore = diaryStore;
    this.dataId = dataId;
    this._goals = this.initGoals();
  }

  private initGoals(): GoalStore[] {
    // 3 weeks * (7 day goals + week goal + cycle goal)
    const goals: GoalStore[] = [];
    let index = 0;
    times(NUMBER_OF_GOALS_WEEKS).forEach(() =>
      times(9).forEach((_, idx) => {
        const id =
          idx === 0
            ? this.diaryStore.goalsAttributes?.cycle
            : idx === 1
            ? this.diaryStore.goalsAttributes?.week
            : this.diaryStore.goalsAttributes?.day;

        goals.push(
          new GoalStore(
            this.rootStore,
            this.diaryStore,
            index++,
            [{ ...BASE_GOAL_ATTRIBUTE, AttributeItemId: id || 0 }],
            this.dataId
          )
        );
      })
    );

    return goals;
  }

  @computed
  public get attributes(): GoalStore[] {
    return this._goals;
  }

  public disposeReactions(): void {
    this._goals.forEach(goal => goal.disposeReactions());
  }

  @action
  public setGoalsData(
    data: TextAreaBasicData[],
    events: DiaryEventData[]
  ): void {
    if (!this.diaryStore.goalsAttributes) {
      return;
    }

    data.forEach(item => {
      const index = getGoalIndexForDay(
        this.dataId.week,
        this.diaryStore.goalsAttributes!,
        item.Date,
        item.AttributeItemId
      );

      this._goals[index]?.attribute.setApiValue(item);
    });

    const eventsByDay = groupBy(e => e.EventDay, events);
    const dayAttribute = this.diaryStore.goalsAttributes?.day;

    Object.entries(eventsByDay).forEach(([date, dayEvents]) => {
      const index = getGoalIndexForDay(
        this.dataId.week,
        this.diaryStore.goalsAttributes!,
        date,
        dayAttribute
      );

      this._goals[index]?.setEvents(dayEvents);
    });
  }

  @action
  public setPlaceholders(data: TextAreaBasicData[]): void {
    if (!this.rootStore.configStore.goalsAttributes?.reality) {
      return;
    }

    data.forEach(item => {
      const index = getGoalIndexForDay(
        this.dataId.week,
        this.rootStore.configStore.goalsAttributes?.reality!,
        item.Date,
        item.AttributeItemId
      );
      this._goals[index]?.setPlaceholder(item);
    });
  }

  public getWeekRange(index: number): {
    start: moment.Moment;
    end: moment.Moment;
  } {
    const currentDate = moment(this.dataId.week);
    const date = currentDate.add(index, 'weeks').format(ROUTE_DATE_FORMAT);
    return {
      start: getWeekStart(date),
      end: getWeekEnd(date)
    };
  }

  public enableApiSynchronization(): void {
    this._goals.forEach(goal => goal.enableApiSynchronization());
  }
}
