import { css, styled } from '@yarmill/components';
import { Text } from './text';

export const RIGHT_PANEL_WIDTH = 248;
export const RightPanelLayout = styled.div.attrs<{
  readonly isVisible?: boolean;
  readonly dark?: boolean;
  readonly windowWidth: number;
  readonly disableMobileHideAnimation?: boolean;
}>(({ isVisible, windowWidth, theme }) => ({
  style: {
    right: isVisible
      ? 0
      : -(windowWidth < 768
          ? windowWidth
          : RIGHT_PANEL_WIDTH + parseInt(theme.size.x2))
  }
}))<{
  readonly isVisible?: boolean;
  readonly windowWidth: number;
  readonly dark?: boolean;
  readonly disableMobileHideAnimation?: boolean;
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -100%;
  flex-shrink: 0;
  width: 100vw;
  background-color: ${({ theme, dark }) =>
    dark ? theme.color.text : theme.color.white};
  overflow: hidden;
  transition-property: background, color, font-weight, margin, right;
  transition-timing-function: ease-out;
  transition-duration: 250ms;
  display: flex;
  flex-direction: column;
  z-index: 2;

  ${({ disableMobileHideAnimation, isVisible }) =>
    !isVisible &&
    disableMobileHideAnimation &&
    css`
      @media (hover: none) {
        transition: none;
      }
    `};

  @media (min-width: 768px) {
    border-radius: ${({ theme }) => theme.borderRadius.x2};
    margin: ${({ theme }) => theme.size.x2} ${({ theme }) => theme.size.x2}
      ${({ theme }) => theme.size.x2} 0;
    width: ${RIGHT_PANEL_WIDTH}px;
    right: -${RIGHT_PANEL_WIDTH}px;
  }

  @media print {
    ${({ isVisible }) =>
      !isVisible &&
      css`
        display: none;
      `}
  }
`;
export const RightPanelTitle = styled(Text)`
  color: ${({ theme }) => theme.color.white};
`;
export const RightPanelContent = styled.div<{ readonly noPadding?: boolean }>`
  flex-grow: 1;
  overflow: auto;
  ${({ noPadding, theme }) => !noPadding && `padding: ${theme.size.x1};`};
`;

export const RightPanelSectionHeading = styled(Text)`
  color: ${({ theme }) => theme.color.neutral};
  margin-bottom: ${({ theme }) => theme.size.x2};
`;

export const FilteredRightPanelScrollContainer = styled.div<{
  readonly inputHeight?: number;
}>`
  height: calc(
    100% -
      ${({ theme, inputHeight }) =>
        inputHeight ? `${inputHeight}px` : theme.size.x4}
  );
  overflow: auto;
`;

export const RightPanelContentWrapper = styled.div`
  height: 100%;
`;
