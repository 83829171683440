import { ObjectiveStore } from './mobx/objective-store';
import {
  ObjectiveFooter,
  ObjectiveListItemContent,
  ObjectiveListItemLayout,
  ObjectiveListItemPriorityWrapper,
  ObjectiveListItemTitle
} from '../components-2/okrs/objectives-list';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ObjectiveListKeyResult } from './objective-list-key-result';
import { PriorityIcon } from '../components-2/priority';
import { Status } from '../components-2/status';
import { OkrCategory } from './okr-category';
import { MouseEvent, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { KeyResultStore } from './mobx/key-result-store';
import { OkrCategory as iOkrCategory } from './types';

interface ObjectiveListItemProps {
  readonly objective?: ObjectiveStore;
  readonly isActive?: boolean;
  readonly isNewObjectivePlaceholder?: boolean;
}

export const ObjectiveListItem = observer(function ObjectiveListItem({
  objective,
  isActive,
  isNewObjectivePlaceholder
}: ObjectiveListItemProps): JSX.Element {
  const { url } = useRouteMatch();
  const history = useHistory();
  const itemRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const emptyKeyResultPlaceholder = useRef(
    new KeyResultStore().setTitle(
      intl.formatMessage({ id: 'okrs.objectiveList.addKeyResult' })
    )
  );

  function edit(e: MouseEvent) {
    e.preventDefault();
    const { location } = history;
    history.push({ ...location, pathname: `${url}/${objective?.id ?? 'add'}` });
  }

  useEffect(() => {
    if (isActive) {
      itemRef.current?.focus();
    }
  }, [isActive]);

  return (
    <ObjectiveListItemLayout
      onMouseDown={isNewObjectivePlaceholder ? undefined : edit}
      $isActive={isActive}
      $isNewObjectivePlaceholder={isNewObjectivePlaceholder}
      tabIndex={0}
      role="button"
      ref={itemRef}
    >
      <ObjectiveListItemContent>
        <ObjectiveListItemTitle
          ellipsis
          as="div"
          appearance="nav16strong"
          priority={objective?.priority}
          lineClamp={2}
        >
          {objective?.title ||
            intl.formatMessage({ id: 'okrs.objectiveList.newObjective' })}
          {objective && objective.priority !== 'low' && (
            <ObjectiveListItemPriorityWrapper>
              <PriorityIcon priority={objective.priority ?? 'low'} />
            </ObjectiveListItemPriorityWrapper>
          )}
        </ObjectiveListItemTitle>
        {objective?.keyResults.length ? (
          objective.keyResults.map(keyResult => (
            <ObjectiveListKeyResult key={keyResult.id} keyResult={keyResult} />
          ))
        ) : (
          <ObjectiveListKeyResult
            keyResult={emptyKeyResultPlaceholder.current}
          />
        )}
        <ObjectiveFooter>
          <Status status={objective?.status ?? 'not-started'} />
          <OkrCategory
            category={objective?.category || ('noCategory' as iOkrCategory)}
            showOnlySubcategory
            limitWidth
          />
        </ObjectiveFooter>
      </ObjectiveListItemContent>
    </ObjectiveListItemLayout>
  );
});
