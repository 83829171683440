import { TextInput } from '../text-input';
import styled from 'styled-components';
import { ChangeEvent, useState } from 'react';
import { Checkbox } from '../checkbox';
import { TableFilterOption } from './index';

const OptionList = styled.ul`
  padding: 0;
  margin-top: 0;
  list-style-type: none;
  max-height: 135px;
  overflow-y: auto;
`;

const StyledFilterOption = styled.li`
  padding: 4px 0;
  color: #4a4a4a;
`;

function normalizeString(str: string): string {
  return str
    .normalize('NFD')
    .toLowerCase()
    .replace(/\p{Diacritic}/gu, '');
}

interface FilterOptionProps extends TableFilterOption {
  checked: boolean;
  onChange(value: string): void;
}

function FilterOption(props: FilterOptionProps): JSX.Element {
  const { onChange, checked, value, label } = props;

  function handleChange() {
    onChange(value);
  }

  return (
    <StyledFilterOption title={label}>
      <Checkbox
        onChange={handleChange}
        checked={checked}
        value={value}
        label={label}
        narrow
      />
    </StyledFilterOption>
  );
}

export interface OptionsFilterProps {
  options: TableFilterOption[];
  currentValue: string[];
  setCurrentValue: (value: string[]) => void;
}

export function OptionsFilter(props: OptionsFilterProps): JSX.Element {
  const { options, currentValue, setCurrentValue } = props;
  const [optionsFilter, setOptionsFilter] = useState<string | null>(null);

  function handleOptionsFilter(e: ChangeEvent): void {
    if (e.target instanceof HTMLInputElement) {
      const { value } = e.target;
      setOptionsFilter(value || '');
    }
  }

  function onSelectFilterChange(value: string): void {
    if (Array.isArray(currentValue)) {
      const idx = currentValue.indexOf(value);
      if (idx === -1) {
        setCurrentValue([...currentValue, value]);
      } else {
        const updatedValue = [...currentValue];
        updatedValue.splice(idx, 1);
        setCurrentValue(updatedValue);
      }
    }
  }

  return (
    <>
      <TextInput
        noLabel
        noError
        id="options-filter"
        value={optionsFilter || ''}
        onChange={handleOptionsFilter}
      />
      <OptionList>
        {options
          .filter(option => {
            if (optionsFilter) {
              const filter = normalizeString(optionsFilter);
              return (
                normalizeString(option.label).includes(filter) ||
                normalizeString(option.value).includes(filter)
              );
            }
            return true;
          })
          .map(option => (
            <FilterOption
              key={option.value}
              label={option.label}
              value={option.value}
              checked={Boolean(
                currentValue &&
                  Array.isArray(currentValue) &&
                  currentValue.includes(option.value)
              )}
              onChange={onSelectFilterChange}
            />
          ))}
      </OptionList>
    </>
  );
}
