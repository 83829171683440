import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';
import { Video } from '../../videoselector/types';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import { RootStore } from '../../app/mobx/root-store';
import { getVideo } from '../api/get-video';

export class VideoService {
  private readonly rootStore: RootStore;

  @observable
  private videoSrc: string | null = null;

  @observable
  private _video: Video | null = null;

  @observable
  private loadedVideos: Map<string, Video> = new Map();

  @observable
  private request: RequestStore<Video> | null = null;

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  public openVideoPlayer(src: string): void {
    this.videoSrc = src;
    if (this.loadedVideos.has(this.videoSrc)) {
      this._video = this.loadedVideos.get(this.videoSrc)!;
    } else {
      this.loadVideo(src);
    }
  }

  @action
  public closeVideoPlayer = (): void => {
    this.videoSrc = null;
    this._video = null;
  };

  @computed
  public get video(): Video | null {
    return this._video || null;
  }

  @computed
  public get status(): AsyncStatus {
    return this._status;
  }

  @action
  private async loadVideo(src: string): Promise<void> {
    if (this.request?.status === AsyncStatus.rejected) {
      this.rootStore.requestsStore.removeRequest(this.request);
    }
    this._status = AsyncStatus.pending;

    this.request = this.rootStore.requestsStore.createRequest(() =>
      getVideo(src)
    );
    const response = await this.request.getResponse();

    if (response) {
      runInAction(() => {
        this.loadedVideos.set(src, response);
        if (this.videoSrc === src) {
          this._video = response;
        }
        this._status = AsyncStatus.resolved;
      });
    } else {
      this._status = AsyncStatus.rejected;
    }
  }
}
