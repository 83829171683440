import { AsyncStatus } from '../../api/mobx/request-store';
import { EvidenceDataObject, EvidenceObject } from '../types';
import { RootStore } from '../../app/mobx/root-store';
import { getEvidenceData } from '../api/get-evidence-data';
import { action, computed, makeObservable, observable } from 'mobx';
import { EvidenceObjectStore } from './evidence-object-store';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import { EvidenceStore } from './evidence-store';

export class EvidenceDataStore {
  private readonly rootStore: RootStore;
  private readonly moduleKey: string;
  private readonly userId: UserId | null;
  private readonly groupId: UserGroupId | null;
  private readonly dataUrl: string;
  private readonly isValidParamsConfiguration: boolean;

  @observable
  private _data: Map<string, EvidenceObjectStore> = new Map();

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  constructor(
    rootStore: RootStore,
    definition: EvidenceObject[],
    moduleKey: string,
    userId: UserId | null,
    groupId: UserGroupId | null,
    dataUrl: string = EvidenceStore.DEFAULT_DATA_URL,
    isValidParamsCombination: boolean
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.moduleKey = moduleKey;
    this.userId = userId;
    this.groupId = groupId;
    this.dataUrl = dataUrl;
    this.isValidParamsConfiguration = isValidParamsCombination;
    this.initObjectStores(definition);
  }

  public async load(): Promise<void> {
    const transaction = this.rootStore.navbarStore.createTransaction(
      'loadingData',
      'evidence'
    );
    await this.loadData();
    transaction.finished();
  }

  @computed
  public get status(): AsyncStatus {
    return this._status;
  }

  public getObjectStore(objectKey: string): EvidenceObjectStore | undefined {
    return this._data.get(objectKey);
  }

  public clear(): void {
    this._data.forEach(object => object.clear());
  }

  private initObjectStores(definition: EvidenceObject[]): void {
    definition.forEach(object => {
      if (object.Attributes && object.Attributes.length !== 0) {
        this._data.set(
          object.ObjectKey,
          new EvidenceObjectStore(
            this.rootStore,
            object,
            this.userId,
            this.groupId,
            this.dataUrl
          )
        );
      } else if (object.Children.length !== 0) {
        this.initObjectStores(object.Children);
      }
    });
  }

  private async loadData(): Promise<void> {
    if (!this.isValidParamsConfiguration) {
      return;
    }
    this._status = AsyncStatus.pending;
    const language = this.rootStore.intlStore.locale;
    const request = this.rootStore.requestsStore.createRequest(() =>
      getEvidenceData(this.dataUrl, {
        userId: this.userId,
        groupId: this.groupId,
        moduleKey: this.moduleKey,
        language
      })
    );

    const response = await request.getResponse();
    if (response) {
      this.setData(response);
    }
  }

  public get objectStores() {
    return this._data;
  }

  @action
  public setData(data: EvidenceDataObject[]): void {
    data.forEach(objectData => {
      const evidenceObject = this._data.get(objectData.ObjectCategoryKey);
      if (!evidenceObject) {
        return;
      }
      const dataStore =
        evidenceObject.createNewEvidenceObjectDataStore(objectData);
      evidenceObject.addDataObject(dataStore);
    });
    this._status = AsyncStatus.resolved;
    this._data.forEach(evidenceObject => evidenceObject.setLoadedStatus());
  }

  @computed
  public get hasLoadedData(): boolean {
    return this.status === AsyncStatus.resolved;
  }
}
