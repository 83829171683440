import { useScale } from '../hooks/use-scale';
import { getAxisPosition } from '../../reporting/utils/get-axis-position';

export function getBarHeight(
  yScale: ReturnType<typeof useScale>,
  value: number
) {
  if ('bandwidth' in yScale) {
    throw new Error('Bars are available only for categorical scale');
  }

  const domainY = yScale.domain();
  const axisPosition = getAxisPosition(domainY as number[]);

  return Math.abs(yScale(value) - yScale(axisPosition));
}
