import * as React from 'react';
import { AttachmentAttributeStore } from '../attributes/mobx/attachment-attribute-store';
import {
  toastErrorFileUpload,
  toastSuccessFileUpload
} from '../fileupload/file-upload';
import { useFileUpload } from '../fileupload/hooks/use-file-upload';
import { useCallback } from 'react';
import { FileUploadStore } from '../fileupload/mobx/file-upload-store';
import { Id } from 'react-toastify';

export function useTrainingDayAttributeFileUpload(
  attribute: AttachmentAttributeStore,
  position: string = 'default'
): [string, React.RefObject<HTMLInputElement>, React.ChangeEventHandler] {
  const onSuccess = useCallback(
    (fileUpload: FileUploadStore, toastId: Id) => {
      const uploadDate = attribute.trainingDayStore.currentDate;
      const athleteId = attribute.diaryStore.athleteId;
      const userGroupId = attribute.diaryStore.groupId;
      attribute
        .assignAttachment(fileUpload, userGroupId, athleteId, uploadDate)
        .then(success => {
          if (!success) {
            toastErrorFileUpload(toastId);
          } else {
            toastSuccessFileUpload(toastId);
          }
        });
    },
    [attribute]
  );

  const [inputRef, onChange] = useFileUpload(onSuccess);
  const inputId = `${attribute.trainingDayStore.index}-${attribute.id}-${position}`;

  return [inputId, inputRef, onChange];
}
