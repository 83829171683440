import { CSSProperties } from 'react';
import { FunctionComponent } from 'react';
import { TooltipInPortalProps } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import { ChartTooltip } from '../chart-tooltip';
import { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip';

export interface TooltipProps {
  readonly TooltipInPortal: FunctionComponent<TooltipInPortalProps>;
  readonly tooltip: UseTooltipParams<any>;
}

const tooltipStyle: CSSProperties = {
  top: 0,
  left: 0,
  position: 'absolute',
  backgroundColor: 'transparent',
  pointerEvents: 'none'
};

export function Tooltip(props: TooltipProps): JSX.Element {
  const { TooltipInPortal, tooltip } = props;
  const { tooltipData, tooltipTop, tooltipLeft } = tooltip;

  return (
    <>
      {tooltipData && tooltipData.values.length > 0 && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyle}
          detectBounds
        >
          <ChartTooltip
            key={tooltipData.key}
            label={tooltipData.showLabel ? tooltipData.label : ''}
            items={tooltipData.values}
            tableLayout={tooltipData.tableLayout}
            showTableColumnsLabels={tooltipData.showColumnLabels}
            tableColumnsLabels={tooltipData.tableColumnsLabels}
          />
        </TooltipInPortal>
      )}
    </>
  );
}
