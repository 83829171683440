import * as React from 'react';
import { getDefaultMainRoute } from './utils';
import { useRootStore } from './root-store-context';
import { ConfigStore } from '../config/config-store';

type Handler = (e?: React.SyntheticEvent) => void;

export function useCollapsible<T extends HTMLElement>(
  defaultVisible: boolean = false
): [boolean, Handler, Handler, React.RefObject<T>] {
  const [visible, setVisible] = React.useState(defaultVisible);
  const ref = React.useRef<T>(null);

  const show = React.useCallback(
    (e?: React.SyntheticEvent): void => {
      if (e) {
        e.preventDefault();
      }
      setVisible(true);
    },
    [setVisible]
  );

  const hide = React.useCallback(
    (e?: React.SyntheticEvent): void => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      setVisible(false);
    },
    [setVisible]
  );

  const closeOnClickOutside = React.useCallback(
    (e: MouseEvent) => {
      if (ref.current && e.target instanceof Node) {
        if (!ref.current.contains(e.target)) {
          hide();
        }
      }
    },
    [ref, hide]
  );

  React.useEffect(() => {
    if (visible) {
      window.addEventListener('click', closeOnClickOutside);
    }

    return () => {
      window.removeEventListener('click', closeOnClickOutside);
    };
  }, [visible, closeOnClickOutside]);

  return [visible, show, hide, ref];
}

export function useDefaultMainRoute(): string {
  const rootStore = useRootStore();
  return getDefaultMainRoute(rootStore);
}

type ConfigKey = keyof ConfigStore;
export function useConfig<P extends ConfigKey>(configKey: P): ConfigStore[P] {
  return useRootStore().configStore[configKey];
}
