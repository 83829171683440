import { RegistrationProcess } from '../register/registration-process';
import { RouteConfiguration } from './types';
import { BaseRoute } from './base-route';
import { Login } from '../auth/login';
import { ForgottenPassword } from '../auth/forgotten-password';
import { ExpiredToken } from '../expired-token/expired-token';
import { ResetPassword } from '../profile/reset-password';
import { TermsAndConditions } from '../terms/terms-and-conditions';
import { Analytics } from '../analytics/analytics';
import { AddGroup } from '../groups/add-group';
import { Groups } from '../groups/groups';
import { Profile } from '../profile/profile';
import { Users } from '../users/users';
import { NoGroup } from '../no-group/no-group';
import { dashboardTypes } from '../analytics/types';
import { DiaryRouteRoot } from '../diary';
import { FilesOverviewRouteRoot } from '../files-overview';
import { Reporting } from '../reporting';
import { EvidenceRouteRoot } from '../evidence';
import { AttendancePageRoot } from '../attendance';
import { SeasonEvaluationRouteRoot } from '../season-evaluation';
import { OkrsRouteRoot } from '../okrs';
import { PlannerRouteRoot } from '../planner';

export const routes: RouteConfiguration[] = [
  {
    path: '/',
    public: true,
    exact: true,
    component: BaseRoute,
    permissionScope: null
  },
  { path: '/login', public: true, component: Login, permissionScope: null },
  {
    path: '/forgottenPassword',
    public: true,
    component: ForgottenPassword,
    permissionScope: null
  },
  {
    path: '/register',
    public: true,
    component: RegistrationProcess,
    permissionScope: null
  },
  {
    path: '/tokenExpired',
    public: true,
    component: ExpiredToken,
    permissionScope: null
  },
  {
    path: '/resetPassword',
    public: true,
    component: ResetPassword,
    permissionScope: null
  },
  {
    path: '/terms-and-conditions',
    public: true,
    component: TermsAndConditions,
    permissionScope: null
  },

  // Private Routes
  {
    component: DiaryRouteRoot,
    path: '/:diaryType(plan|reality)/:viewType(week|season|goals|seasonGoals)',
    permissionScope: ['plan', 'reality']
  },
  {
    component: Analytics,
    path: `/:diaryType(analytics)/:dashboardType(${dashboardTypes.join('|')})`,
    permissionScope: 'analytics'
  },
  {
    component: Reporting,
    path: `/reporting/:dashboardType`,
    permissionScope: 'reporting'
  },
  {
    component: AttendancePageRoot,
    path: `/attendance/:viewType(week|season)`,
    permissionScope: 'attendance'
  },
  {
    component: EvidenceRouteRoot,
    path: '/evidence/:moduleKey',
    permissionScope: 'evidence'
  },
  {
    component: Groups,
    path: '/settings/groups/:groupId?',
    permissionScope: 'settings.groups'
  },
  {
    component: AddGroup,
    path: '/settings/groups/createNew',
    permissionScope: 'settings.groups'
  },
  {
    component: Profile,
    path: '/settings/users/:userId',
    permissionScope: 'settings.users.detail'
  },
  {
    component: Users,
    path: '/settings/users',
    permissionScope: 'settings.users'
  },
  {
    component: Profile,
    path: '/settings/personal',
    permissionScope: 'settings.personal'
  },
  {
    component: FilesOverviewRouteRoot,
    path: '/filesOverview',
    permissionScope: 'filesOverview'
  },
  {
    component: SeasonEvaluationRouteRoot,
    path: '/seasonEvaluation',
    permissionScope: 'seasonEvaluation'
  },

  {
    component: OkrsRouteRoot,
    path: '/okr',
    permissionScope: 'okr',
    exact: false
  },

  {
    component: PlannerRouteRoot,
    path: '/planner',
    permissionScope: 'planner',
    exact: false
  },
  { path: '/noGroup', component: NoGroup, permissionScope: null }
];
