import {
  createContext,
  memo,
  PropsWithChildren,
  useContext,
  useRef
} from 'react';
import { VideoSelectorStore } from './mobx/video-selector-store';
import { useRootStore } from '../app/root-store-context';

export const VideoSelectorStoreContext = createContext<
  VideoSelectorStore | undefined
>(undefined);

export function useVideoSelectorStore(): VideoSelectorStore {
  const diaryStore = useContext(VideoSelectorStoreContext);
  if (!diaryStore) {
    throw new Error('VideoSelector Store not available');
  }

  return diaryStore;
}

export function InternalVideoSelectorStoreContextProvider({
  done,
  children
}: PropsWithChildren<{
  done: () => void;
}>): JSX.Element {
  const rootStore = useRootStore();
  const videoSelector = useRef(new VideoSelectorStore(rootStore, done)).current;

  return (
    <VideoSelectorStoreContext.Provider value={videoSelector}>
      {children}
    </VideoSelectorStoreContext.Provider>
  );
}

export const VideoSelectorStoreContextProvider = memo(
  InternalVideoSelectorStoreContextProvider
);
