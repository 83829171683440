import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { ChartConfig } from './types';
import { useXYChartContext } from './xy-chart-context';
import { getLineLabel } from './utils/get-line-label';
import { getBarLabel } from './utils/get-bar-label';
import { LABEL_FONT_SIZE } from '../reporting/const';
import { DataItem } from '../reporting/types';
import { getRotatedHeight } from '../reporting/utils/get-rotated-height';
import { getSSRStringWidth } from '../reporting/utils/get-ssr-string-width';
import { getRotatedWidth } from '../reporting/utils/get-rotated-width';
import { getBarGroupLabel } from './utils/get-bar-group-label';
import { useMemo } from 'react';
import { isValidRange } from './utils/is-valid-range';

interface DataLabelsProps {
  readonly data: DataItem[];
  readonly configs: ChartConfig[];
}

export function DataLabels({ data, configs }: DataLabelsProps) {
  const { xScale, yScale } = useXYChartContext();

  const xRange = xScale.range();
  const yRange = yScale.range();

  const labels = useMemo(
    () =>
      isValidRange(xRange) && isValidRange(yRange)
        ? data.flatMap(d =>
            configs
              .flatMap((chartConfig, idx) => {
                switch (chartConfig.type) {
                  case 'MultiLine':
                  case 'Line':
                    return getLineLabel(d, xScale, yScale, chartConfig, idx);
                  case 'Bar':
                    return getBarLabel(d, xScale, yScale, chartConfig, idx);
                  case 'BarGroup':
                    return getBarGroupLabel(
                      d,
                      xScale,
                      yScale,
                      chartConfig,
                      idx
                    );
                  default:
                    return undefined;
                }
              })
              .filter(
                label =>
                  label &&
                  label.originalValue &&
                  label.originalValue >= yScale.domain()[0] &&
                  label.originalValue <= yScale.domain()[1]
              )
              .map(label => {
                const width = getRotatedWidth(
                  getSSRStringWidth(String(label?.text)),
                  LABEL_FONT_SIZE,
                  label?.angle ?? 0
                );
                const height = getRotatedHeight(
                  getSSRStringWidth(String(label?.text)),
                  LABEL_FONT_SIZE,
                  label?.angle ?? 0
                );

                const top = Number(label?.x) - height / 2;
                const bottom = Number(label?.x) + height / 2;
                const left = Number(label?.y) - width / 2;
                const right = Number(label?.y) + width / 2;

                return {
                  ...label,
                  width,
                  height,
                  top,
                  bottom,
                  left,
                  right
                };
              })
          )
        : [],
    [configs, data, xScale, yScale, xRange, yRange]
  );

  return (
    <Group>
      {labels.map((label, idx) => (
        <Text
          key={`${idx}-${label.text}-${label.x}-${label.y}`}
          x={label.x}
          y={label.y}
          width={Math.abs(label.angle ?? 0) === 90 ? undefined : label.maxWidth}
          verticalAnchor="middle"
          textAnchor="middle"
          angle={label.angle}
          fontSize={Math.min(
            LABEL_FONT_SIZE,
            label.maxHeight ?? LABEL_FONT_SIZE
          )}
          fill={label.color || '#4a4a4a'}
          pointerEvents="none"
          scaleToFit="shrink-only"
        >
          {label.text}
        </Text>
      ))}
    </Group>
  );
}
