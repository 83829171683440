import {
  Icon,
  IconSize,
  styled,
  ThemeDefinition,
  useTheme
} from '@yarmill/components';
import { css } from 'styled-components';
import { forwardRef, InputHTMLAttributes, useEffect, useRef } from 'react';
import { Text } from './text';
import { useMergeRefs } from '@floating-ui/react';

export type TextInputAppearance =
  | 'green'
  | 'red'
  | 'tangerine'
  | 'orangine'
  | 'sand'
  | 'navy'
  | 'neutral'
  | 'pink'
  | 'white'
  | 'black'
  | 'violet'
  | 'blue';
export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  readonly value?: string;
  readonly defaultValue?: string;
  readonly label?: string;
  readonly align?: 'left' | 'center' | 'right';
  readonly icon?: JSX.Element;
  readonly iconPosition?: 'left' | 'right';
  readonly name: string;
  readonly appearance?: TextInputAppearance;
  readonly variant?: 'compact' | 'default';
  readonly as?: 'input' | 'textarea';
  readonly rows?: number;
  readonly cols?: number;
  readonly maxRows?: number;
}

export function getAppearanceColors(
  appearance: TextInputAppearance = 'navy',
  theme: ThemeDefinition
): {
  base: string;
  text: string;
  background: string;
  focus: string;
  additional: string;
  dark: string;
} {
  switch (appearance) {
    case 'green':
      return {
        base: theme.color.green,
        text: theme.color.greenDark,
        background: theme.color.green_8,
        focus: theme.color.green_24,
        additional: theme.color.green_40,
        dark: theme.color.greenDark
      };
    case 'red':
      return {
        base: theme.color.red,
        text: theme.color.redDark,
        background: theme.color.red_8,
        focus: theme.color.red_24,
        additional: theme.color.red_40,
        dark: theme.color.redDark
      };
    case 'tangerine':
      return {
        base: theme.color.tangerine,
        text: theme.color.tangerineDark,
        background: theme.color.tangerine_8,
        focus: theme.color.tangerine_24,
        additional: theme.color.tangerine_40,
        dark: theme.color.tangerineDark
      };
    case 'orangine':
      return {
        base: theme.color.orangine,
        text: theme.color.orangineDark,
        background: theme.color.orangine_8,
        focus: theme.color.orangine_24,
        additional: theme.color.orangine_40,
        dark: theme.color.orangineDark
      };
    case 'sand':
      return {
        base: theme.color.sand,
        text: theme.color.sand,
        background: theme.color.sand_8,
        focus: theme.color.sand_24,
        additional: theme.color.sand_40,
        dark: theme.color.sandDark
      };
    case 'navy':
      return {
        base: theme.color.navy,
        text: theme.color.navyDark,
        background: theme.color.navy_8,
        focus: theme.color.navy_24,
        additional: theme.color.navy_40,
        dark: theme.color.navyDark
      };
    case 'neutral':
      return {
        base: theme.color.neutral,
        text: theme.color.neutralDark,
        background: theme.color.neutral_8,
        focus: theme.color.neutral_24,
        additional: theme.color.neutral_40,
        dark: theme.color.neutral
      };
    case 'pink':
      return {
        base: theme.color.pink,
        text: theme.color.pink,
        background: theme.color.pink_8,
        focus: theme.color.pink_24,
        additional: theme.color.pink_40,
        dark: theme.color.pinkDark
      };
    case 'white':
      return {
        base: theme.color.neutral,
        text: theme.color.neutralDark,
        background: theme.color.text,
        focus: theme.color.neutral_24,
        additional: theme.color.neutral_40,
        dark: theme.color.neutral
      };
    case 'black':
      return theme.dark
        ? {
            base: theme.color.black,
            text: theme.color.white,
            background: theme.color.blackHover,
            focus: theme.color.black,
            additional: theme.color.neutralDark,
            dark: theme.color.neutral
          }
        : {
            base: theme.color.black,
            text: theme.color.white,
            background: theme.color.black,
            focus: theme.color.blackHover,
            additional: theme.color.neutral,
            dark: theme.color.black
          };
    case 'violet':
      return {
        base: theme.color.violet,
        text: theme.color.violetDark,
        background: theme.color.violet_8,
        focus: theme.color.violet_24,
        additional: theme.color.violet_40,
        dark: theme.color.violetDark
      };
    case 'blue':
      return {
        base: theme.color.blue,
        text: theme.color.blueDark,
        background: theme.color.blue_8,
        focus: theme.color.blue_24,
        additional: theme.color.blue_40,
        dark: theme.color.blueDark
      };
  }
}

export const IconWrapper = styled.div<{
  readonly position: 'left' | 'right';
  readonly appearance?: TextInputAppearance;
}>`
  position: absolute;
  ${({ position, theme }) =>
    position === 'right'
      ? `right: ${theme.size.x025};`
      : `left: ${theme.size.x025};`};
  ${({ position, theme }) =>
    position === 'right'
      ? css`
          border-top-right-radius: ${theme.borderRadius.x05};
          border-bottom-right-radius: ${theme.borderRadius.x05};
        `
      : css`
          border-top-left-radius: ${theme.borderRadius.x05};
          border-bottom-left-radius: ${theme.borderRadius.x05};
        `};
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme, appearance }) =>
    getAppearanceColors(appearance, theme).text};
  background-color: ${({ theme, appearance }) =>
    getAppearanceColors(appearance, theme).background};
  padding: ${({ theme }) => theme.size.x05};
`;
export const StyledTextInput = styled.input<TextInputProps>`
  width: 100%;
  padding: ${({ theme }) => theme.size.x075} ${({ theme }) => theme.size.x1};
  font-size: ${({ theme }) => theme.text.appearance.task13.fontSize};
  font-family: ${({ theme }) => theme.text.font.default};
  line-height: ${({ theme }) => theme.text.appearance.task13.lineHeight};
  font-weight: 500;
  color: ${({ theme, appearance }) =>
    getAppearanceColors(appearance, theme).text};
  flex-shrink: 0;
  height: ${({ theme }) => theme.size.x4};

  text-align: ${({ align }) => align ?? 'left'};

  border-radius: ${({ theme }) => theme.borderRadius.x075};
  background: ${({ theme, appearance }) =>
    getAppearanceColors(appearance, theme).background};
  border: ${({ theme }) => theme.size.x025} solid
    ${({ theme, appearance }) =>
      getAppearanceColors(appearance, theme).background};

  :focus-visible {
    outline: none;
    color: ${({ theme, appearance }) =>
      getAppearanceColors(appearance, theme).text};
    border-color: ${({ theme, appearance }) =>
      getAppearanceColors(appearance, theme).focus};

    ${({ theme, appearance }) =>
      theme.dark &&
      css`
        border-color: ${getAppearanceColors(appearance, theme).background};
        background-color: ${getAppearanceColors(appearance, theme).focus};
      `}

    & + ${IconWrapper} {
      color: ${({ theme, appearance }) =>
        getAppearanceColors(appearance, theme).text};
      background-color: ${({ theme, appearance }) =>
        getAppearanceColors(appearance, theme).focus};
    }
  }

  ::placeholder {
    color: ${({ theme, appearance }) =>
      getAppearanceColors(appearance, theme).additional};
    z-index: 1;
  }

  :not(:focus):placeholder-shown {
    & + ${IconWrapper} {
      color: ${({ theme, appearance }) =>
        getAppearanceColors(appearance, theme).additional};
    }
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button,
  ::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    margin: 0;
    display: none;

    -moz-appearance: textfield;
  }

  resize: none;
`;

export const TextInputWrapper = styled.div<{
  readonly icon?: JSX.Element;
  readonly iconPosition?: 'left' | 'right';
}>`
  position: relative;

  ${({ icon, iconPosition, theme }) =>
    icon &&
    css`
      ${iconPosition === 'right'
        ? `padding-right: ${theme.size.x4};`
        : `padding-left: ${theme.size.x4};`};
    `}
`;

export const TextInputLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.size.x1};
`;

export const TextInputLabel = styled(Text)<{
  readonly appearanceStyle?: TextInputAppearance;
}>`
  color: ${({ theme, appearanceStyle }) =>
    getAppearanceColors(appearanceStyle, theme).dark};
`;

export const TextInput = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  TextInputProps
>(function TextInput(
  { label, align, icon, iconPosition, maxRows, ...textInputProps },
  passedRef
): JSX.Element {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const ref = useMergeRefs([passedRef, inputRef]);
  const theme = useTheme();

  useEffect(() => {
    const area = inputRef.current;

    if (!(area instanceof HTMLTextAreaElement)) {
      return;
    }
    function heightListener() {
      const area = inputRef.current;

      if (area) {
        const rowHeight = parseInt(theme.text.appearance.task13.lineHeight, 10);
        const borderWidth = 2 * 2;
        const padding = parseInt(theme.size.x075, 10) * 2;
        area.style.height = 'auto';
        if (maxRows) {
          area.style.height =
            Math.min(
              area.scrollHeight + borderWidth,
              maxRows * rowHeight + padding + borderWidth
            ) + 'px';
        } else {
          area.style.height = area.scrollHeight + borderWidth + 'px';
        }
      }
    }
    heightListener();

    area?.addEventListener('input', heightListener);

    return () => {
      area?.removeEventListener('input', heightListener);
    };
  }, [maxRows, theme]);

  return (
    <TextInputLayout>
      {label && (
        <TextInputLabel
          as="label"
          appearance="button10"
          htmlFor={textInputProps.id}
          align={align}
          whiteSpace="noWrap"
          upperCase
          appearanceStyle={textInputProps.appearance}
        >
          {label}
        </TextInputLabel>
      )}
      <TextInputWrapper icon={icon} iconPosition={iconPosition}>
        <StyledTextInput
          {...textInputProps}
          align={align}
          ref={ref}
          icon={icon}
          iconPosition={iconPosition}
          value={textInputProps.value === null ? '' : textInputProps.value}
        />
        {icon && (
          <IconWrapper
            position={iconPosition ?? 'left'}
            appearance={textInputProps.appearance}
          >
            <Icon size={IconSize.s16}>{icon}</Icon>
          </IconWrapper>
        )}
      </TextInputWrapper>
    </TextInputLayout>
  );
});
