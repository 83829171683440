import { RootStore } from '../../app/mobx/root-store';
import {
  IObservableArray,
  makeObservable,
  observable,
  runInAction
} from 'mobx';
import { DashboardStore, DashboardType } from '../types';
import { getDashboards } from '../api/get-dashboards';
import { GeneralDashboardStore } from './general-dashboard-store';
import { SlcrDashboardStore } from './slcr-dashboard-store';

export class LegacyAnalyticsStore {
  private readonly _rootStore: RootStore;
  @observable
  private readonly _dashboards: DashboardStore[] = [];

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeObservable(this);
  }

  public async loadDashboards(): Promise<void> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      getDashboards()
    );
    const response = await request.getResponse();

    runInAction(() => {
      const stores =
        response?.map(dashboard => {
          switch (dashboard.Ident) {
            case 'general.defaultDashboard':
              return new GeneralDashboardStore(this._rootStore, dashboard);
            default:
              return new SlcrDashboardStore(this._rootStore, dashboard);
          }
        }) ?? [];

      (this._dashboards as IObservableArray<DashboardStore>).replace(stores);
    });
  }

  public get dashboards(): DashboardStore[] {
    return this._dashboards;
  }

  public getDashboard(id: DashboardType): DashboardStore | undefined {
    return this._dashboards.find(dashboard => dashboard.id === id);
  }
}
