import { UserId } from '../users/types';
import { Layer } from '../layer-manager/mobx/layer';
import styled from 'styled-components';
import {
  AlertLayerCloseButtonWrapper,
  Button,
  ButtonAppearance,
  lowerCaseFirstLetter,
  Text,
  TextSize,
  TextTag
} from '@yarmill/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUsersStore } from '../users/hooks';
import { ConflictResolution, CopyMode } from './types';
import { UserStore } from '../users/mobx/user-store';
import { ReactNode, useContext } from 'react';
import { UserGroupId } from '../groups/types';
import { useGroupsStore } from '../groups/hooks';
import { sortGroupStores } from '../groups/utils';
import { DiaryStoreContext } from '../diary/diary-store-context';
import { GroupStore } from '../groups/mobx/group-store';
import { CloseButton } from '../components/close-button/close-button';
import { sortUserStores } from '../users/utils/sort-user-stores';

export interface ConflictLayerContentProps {
  layer: Layer;
  conflictingIds: UserId[] | UserGroupId[];
  mode: CopyMode | 'attendance';
  submitCopy: (resolution: ConflictResolution) => void;
  conflictResolutions: ConflictResolution[];
  sourceType: 'athlete' | 'group';
}

const ConflictsLayer = styled.div`
  padding: 0 32px;
`;

const ConflictLayerScrollContainer = styled.div`
  overflow: auto;
  max-height: calc(100vh - 150px - 250px);

  @media (min-width: 768px) {
    max-height: calc(100vh - 150px - 155px);
  }
`;

const ConflictingUsersList = styled.ul`
  margin: 0;
  list-style-position: inside;
`;

const ButtonsWrapper = styled.div<{ buttonsCount: number }>`
  display: grid;
  grid-template-columns: repeat(${({ buttonsCount }) => buttonsCount}, auto);
  grid-template-rows: auto;
  gap: 20px;
  padding-bottom: 32px;
  padding-top: 20px;
  background-color: #ffffff;

  @media (max-width: 450px) {
    grid-template-columns: repeat(3, auto);
  }
`;

const HeadlineWrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 8px;
  background-color: #ffffff;
`;

const StickyWrapper = styled.div`
  position: sticky;
  top: 0;
  margin: 0 -32px;
  z-index: 1;
`;

function isConflictingUser(
  sourceType: 'athlete' | 'group',
  copyMode: CopyMode | 'attendance'
): boolean {
  return (
    copyMode === 'groupToAthletesInGroup' ||
    copyMode === 'athleteToOtherAthletes' ||
    copyMode === 'attendance' ||
    ((copyMode === 'otherWeek' || copyMode === 'otherSeason') &&
      sourceType === 'athlete')
  );
}

export function ConflictLayerContent(
  props: ConflictLayerContentProps
): JSX.Element {
  const {
    layer,
    submitCopy,
    conflictingIds,
    mode,
    sourceType,
    conflictResolutions
  } = props;
  const usersStore = useUsersStore();
  const diaryStore = useContext(DiaryStoreContext);
  const module = diaryStore?.diaryType || 'attendance';
  const viewType =
    module === 'attendance' ? 'week' : diaryStore?.viewType || 'week';
  const groupsStore = useGroupsStore();
  const isConflictingUsers = isConflictingUser(sourceType, mode);
  const intl = useIntl();
  const messageId = intl.messages[`${module}.${viewType}.copy.conflict.${mode}`]
    ? `${module}.${viewType}.copy.conflict.${mode}`
    : `plan.copy.conflict.conflictingItems`;

  const listItems = isConflictingUsers
    ? conflictingIds
        .map(id => usersStore.getUserById(id) as UserStore)
        .filter(Boolean)
        .sort(sortUserStores)
        .map(user => <li key={user.id}>{user.displayName}</li>)
    : conflictingIds
        .map(id => groupsStore.getGroupById(id) as GroupStore)
        .filter(Boolean)
        .sort(sortGroupStores)
        .map(group => <li key={group.id}>{group.name}</li>);

  const conflictingItems = (
    <ConflictingUsersList>{listItems}</ConflictingUsersList>
  );

  return (
    <ConflictLayerContentWrapper
      layer={layer}
      submitCopy={submitCopy}
      buttonsConfig={conflictResolutions}
    >
      <FormattedMessage id={messageId} values={{ conflictingItems }} />
    </ConflictLayerContentWrapper>
  );
}

export interface ConflictLayerContentWrapperProps {
  layer: Layer;
  submitCopy: (resolution: ConflictResolution) => void;
  children: ReactNode;
  buttonsConfig: ConflictResolution[];
}

const conflictResolutionButtons: ConflictResolution[] = [
  'FullOverwrite',
  'SkipConflicts',
  'Merge'
];

export function ConflictLayerContentWrapper(
  props: ConflictLayerContentWrapperProps
): JSX.Element {
  const { layer, submitCopy, children, buttonsConfig } = props;
  const diaryStore = useContext(DiaryStoreContext);
  const module = diaryStore?.diaryType || 'attendance';
  const intl = useIntl();
  const viewType =
    module === 'attendance' ? 'week' : diaryStore?.viewType || 'week';

  const handleClick = (resolution: ConflictResolution): void => {
    layer.close();
    submitCopy(resolution);
  };

  const filteredButtons = conflictResolutionButtons.filter(button =>
    buttonsConfig.includes(button)
  );

  const resolutions = buttonsConfig.map(resolution => (
    <div>
      <strong>
        <FormattedMessage id={`copy.conflict.resolution.${resolution}.title`} />
      </strong>
      &nbsp;-&nbsp;
      <FormattedMessage
        id={`copy.conflict.resolution.${resolution}.description`}
      />
    </div>
  ));

  const descriptionId = intl.messages[
    `${module}.${viewType}.copy.conflict.description`
  ]
    ? `${module}.${viewType}.copy.conflict.description`
    : 'copy.conflict.description';

  return (
    <ConflictsLayer data-cy="copy-conflicts-layer">
      <StickyWrapper>
        <AlertLayerCloseButtonWrapper>
          <CloseButton onClick={layer.close} hideText />
        </AlertLayerCloseButtonWrapper>
      </StickyWrapper>
      <HeadlineWrapper>
        <Text tag={TextTag.h2} size={TextSize.s16}>
          <FormattedMessage id="plan.copy.conflict.headline" />
        </Text>
      </HeadlineWrapper>
      <ConflictLayerScrollContainer>
        <Text tag={TextTag.p} size={TextSize.s14}>
          {children}
        </Text>
        <Text tag={TextTag.p} size={TextSize.s14}>
          <FormattedMessage
            id={descriptionId}
            values={{
              resolutions,
              div: c => <div>{c}</div>,
              p: c => <p>{c}</p>
            }}
          />
        </Text>
      </ConflictLayerScrollContainer>
      <ButtonsWrapper buttonsCount={filteredButtons.length + 1}>
        <Button
          type="button"
          appearance={ButtonAppearance.Link}
          noShadow
          data-cy="cancel"
          onClick={layer.close}
        >
          <FormattedMessage id="plan.copy.conflict.button.cancel" />
        </Button>
        {filteredButtons.map((conflictResolution, idx) => (
          <Button
            key={conflictResolution}
            type="button"
            appearance={
              idx === filteredButtons.length - 1
                ? ButtonAppearance.Primary
                : ButtonAppearance.Secondary
            }
            noShadow
            data-cy={lowerCaseFirstLetter(conflictResolution)}
            onClick={() => handleClick(conflictResolution)}
          >
            <FormattedMessage
              id={`plan.copy.conflict.button.${lowerCaseFirstLetter(
                conflictResolution
              )}`}
            />
          </Button>
        ))}
      </ButtonsWrapper>
    </ConflictsLayer>
  );
}
