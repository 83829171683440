import { RootStore } from '../../app/mobx/root-store';
import {
  IReactionDisposer,
  observable,
  reaction,
  makeObservable,
  action
} from 'mobx';
import { Cgm, CGM_URL_PARAM, CgmId } from '../types';
import { RequestStore } from '../../api/mobx/request-store';
import { getCgmDetail } from '../api/get-cgm-detail';
import { ATHLETE_SEARCH_PARAM } from '../../routes/types';

export class CgmStore {
  private readonly rootStore: RootStore;
  private readonly reactions: IReactionDisposer[] = [];

  @observable
  private _cgmId: CgmId | null = null;

  @observable
  private _cgm: Cgm | null = null;

  @observable
  private request: RequestStore<Cgm> | null = null;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.registerReactions();
  }

  public disposeReactions(): void {
    this.reactions.forEach(dispose => dispose());
  }

  public get cgm(): Cgm | null {
    return this._cgm;
  }

  public get cgmId(): CgmId | null {
    return this._cgmId;
  }

  @action
  public clear(): void {
    this._cgmId = null;
    this._cgm = null;
  }

  private registerReactions(): void {
    const loadData = reaction(
      () => this._cgmId,
      () => {
        if (this._cgmId) {
          this.loadCgmData();
        }
      }
    );

    const cgmId = reaction(
      () => ({
        selectedDay:
          this.rootStore.historyService.searchParams.get(CGM_URL_PARAM),
        userId:
          this.rootStore.historyService.searchParams.get(ATHLETE_SEARCH_PARAM)
      }),
      cgmId => {
        this._cgmId =
          cgmId.userId && cgmId.selectedDay
            ? { selectedDay: cgmId.selectedDay, userId: Number(cgmId.userId) }
            : null;
      },
      {
        fireImmediately: true
      }
    );

    this.reactions.push(cgmId, loadData);
  }

  private async loadCgmData(): Promise<void> {
    const cgmId = this.cgmId;
    if (!cgmId) {
      return;
    }

    if (this.request) {
      this.request.cancel();
      this.rootStore.requestsStore.removeRequest(this.request);
    }

    this.request = this.rootStore.requestsStore.createRequest(cancelToken =>
      getCgmDetail(cgmId, cancelToken)
    );
    const response = await this.request.getResponse();

    if (response) {
      this._cgm = response;
    }
  }
}
