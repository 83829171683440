import { useCollapsible } from '../../app/hooks';
import * as React from 'react';
import { EvidenceObjectDataStore } from '../mobx/evidence-object-data-store';
import { EvidenceObjectStore } from '../mobx/evidence-object-store';

export interface EvidenceTableFormHandler {
  addRow: (e: React.MouseEvent) => void;
  closeForm: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  editedItem: EvidenceObjectDataStore | null;
  editRow: (e: React.MouseEvent, item: EvidenceObjectDataStore) => void;
  showForm: boolean;
}

export function useEvidenceTableFormHandler(
  dataStore: EvidenceObjectStore | null
): EvidenceTableFormHandler {
  const [showForm, showEvidenceTableItemForm, closeEvidenceTableItemForm] =
    useCollapsible();
  const [editedItem, setEditedItem] =
    React.useState<EvidenceObjectDataStore | null>(null);

  const closeForm = React.useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>): void => {
      setEditedItem(null);
      closeEvidenceTableItemForm(e);
    },
    [setEditedItem, closeEvidenceTableItemForm]
  );

  const editRow = React.useCallback(
    (e: React.MouseEvent, item: EvidenceObjectDataStore): void => {
      setEditedItem(item);
      showEvidenceTableItemForm(e);
    },
    [setEditedItem, showEvidenceTableItemForm]
  );

  const addRow = React.useCallback(
    (e: React.MouseEvent) => {
      if (dataStore) {
        const objectDataStore = dataStore.createNewEvidenceObjectDataStore();
        setEditedItem(objectDataStore);
        objectDataStore.setDefaultValues();
        showEvidenceTableItemForm(e);
      }
    },
    [dataStore, showEvidenceTableItemForm]
  );

  return {
    addRow,
    closeForm,
    editedItem,
    editRow,
    showForm
  };
}
