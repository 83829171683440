import { useState, useEffect, useRef, RefObject } from 'react';

interface UseSizeResult {
  width: number | null;
  height: number | null;
}
export function useSize(ref: RefObject<HTMLElement>): UseSizeResult {
  const obs = useRef<ResizeObserver>();
  const [, setIgnored] = useState(0);
  const [size, setSize] = useState<UseSizeResult>({
    width: null,
    height: null
  });

  useEffect(() => {
    function observe(entries: ResizeObserverEntry[]) {
      const { width, height } = entries[0].contentRect;
      setSize(s =>
        s.width !== width || s.height !== height ? { width, height } : s
      );
    }
    obs.current = new ResizeObserver(observe);
    return () => {
      obs.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    const forceUpdate = () => setIgnored(c => c + 1);
    const item = ref.current;
    if (item) {
      obs.current?.observe(item);
      window.setTimeout(forceUpdate, 0);
    }
    return () => {
      item && obs.current?.unobserve(item);
    };
  }, [obs, ref]);

  return size;
}
