import { PropsWithChildren, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { styled, keyframes } from '@yarmill/components';

type TransitionType = 'left' | 'right';

type RightPanelTransitionProps = PropsWithChildren<{
  readonly stepName: string;
  readonly transition: TransitionType;
  readonly active: boolean;
}>;

const slideLeftIn = keyframes`
    from {
      transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
`;

const slideLeftOut = keyframes`
    from {
      transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
`;

const slideRightIn = keyframes`
    from {
      transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
`;

const slideRightOut = keyframes`
    from {
      transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
`;

export const RIGHT_PANEL_TRANSITION_DURATION = 250;

const TransitionContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;

  &.slide-left-enter {
    transform: translateX(-100%);
  }
  &.slide-left-enter-active {
    animation: ${slideLeftIn} ${RIGHT_PANEL_TRANSITION_DURATION}ms ease-out
      forwards;
  }
  &.slide-left-exit {
    transform: translateX(100%);
  }
  &.slide-left-exit-active {
    animation: ${slideLeftOut} ${RIGHT_PANEL_TRANSITION_DURATION}ms ease-out
      forwards;
  }
  &.slide-right-enter {
    transform: translateX(100%);
  }
  &.slide-right-enter-active {
    animation: ${slideRightIn} ${RIGHT_PANEL_TRANSITION_DURATION}ms ease-out
      forwards;
  }
  &.slide-right-exit {
    transform: translateX(-100%);
  }
  &.slide-right-exit-active {
    animation: ${slideRightOut} ${RIGHT_PANEL_TRANSITION_DURATION}ms ease-out
      forwards;
  }
`;
export function RightPanelTransition({
  children,
  stepName,
  transition,
  active
}: RightPanelTransitionProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <CSSTransition
      key={stepName}
      classNames={`slide-${transition}`}
      timeout={RIGHT_PANEL_TRANSITION_DURATION}
      nodeRef={containerRef}
      unmountOnExit
      mountOnEnter
      in={active}
    >
      <TransitionContainer ref={containerRef}>{children}</TransitionContainer>
    </CSSTransition>
  );
}
