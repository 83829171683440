import { CgmStore } from './mobx/cgm-store';
import { useRootStore } from '../app/root-store-context';
import * as React from 'react';
import { times } from 'ramda';
import { CgmZone, ZoneData } from '@yarmill/components';
import { sortHeartRateZones } from '../workouts/utils';
import { Cgm, CgmChartsData } from './types';
import { useIntl } from 'react-intl';

export function useCgmStore(): CgmStore {
  return useRootStore().cgmStore;
}

export function useSampledCgmData(detail: Cgm | null): CgmChartsData[] {
  const fullData: CgmChartsData[] = React.useMemo(() => {
    if (!detail) {
      return [];
    }

    return times(
      (i: number) => ({
        time:
          detail.TimeEvolution && typeof detail.TimeEvolution[i] === 'number'
            ? detail.TimeEvolution[i]
            : null,
        egv:
          detail.CgmEvolution && typeof detail.CgmEvolution[i] === 'number'
            ? detail.CgmEvolution[i]
            : null,
        x:
          detail.TimeEvolution && typeof detail.TimeEvolution[i] === 'number'
            ? detail.TimeEvolution[i]
            : 0,
        y:
          detail.CgmEvolution[i] && typeof detail.CgmEvolution[i] === 'number'
            ? detail.CgmEvolution[i]
            : 0
      }),
      detail?.TimeEvolution?.length || 0
    );
  }, [detail]);

  return fullData;
}

export function useZonesData(detail: Cgm | null): ZoneData[] {
  return React.useMemo(() => {
    if (!detail) {
      return [];
    }
    const zoneTimes = detail.TotalZoneTimes || [];
    const hrZones = detail.ZonesDefinition || [];

    return zoneTimes
      .map(totalZoneTime => {
        const zone = hrZones.find(z => z.ZoneId === totalZoneTime.ZoneId);

        return {
          id: totalZoneTime.ZoneId,
          time: totalZoneTime.TimeInZone,
          top: zone ? zone.UpperLimit : 0,
          bottom: zone ? zone.LowerLimit : 0,
          name: zone?.Name ?? null
        };
      })
      .sort(sortHeartRateZones);
  }, [detail]);
}

export function useZonesDefinition(detail: Cgm | null): CgmZone[] {
  const intl = useIntl();
  return React.useMemo(() => {
    if (!detail) {
      return [];
    }

    const hrZones = detail.ZonesDefinition || [];

    return hrZones
      .map(zone => ({
        bottom: zone.LowerLimit,
        top: zone.UpperLimit,
        name: intl.formatMessage({ id: `cgm.zone.${zone.ZoneId}` })
      }))
      .sort(sortHeartRateZones);
  }, [detail, intl]);
}
