import { Button, ButtonAppearance } from '../../components-2/button';
import { Icon, IconSize } from '@yarmill/components';
import { Validation } from '@yarmill/icons-2';
import { useIntl } from 'react-intl';
import { ObjectiveStore } from '../mobx/objective-store';
import { calculateObjectiveValidationScore } from '../utils';
import { getScoreAppearance } from '../../components-2/okrs/detail/rating-score';
import { observer } from 'mobx-react-lite';
import { Text } from '../../components-2/text';
import { Tippy } from '../../components/tippy/tippy';
import { useOkrsStore } from '../okrs-store-context';

interface RatingProps {
  readonly objective?: ObjectiveStore;
}
export const Rating = observer(function Rating({
  objective
}: RatingProps): JSX.Element {
  const intl = useIntl();
  const score = calculateObjectiveValidationScore(objective);
  const okrsStore = useOkrsStore();
  async function handleClick() {
    const currentPanel = okrsStore.visibleSidePanel;
    okrsStore.setVisibleRightPanel(
      currentPanel === 'validation' ? null : 'validation'
    );
  }

  return (
    <Tippy tooltipContent="okrs.detail.keyResults.ratingButtonLabel" noWrapper>
      <Button
        $appearance={ButtonAppearance.Primary}
        $appearanceStyle={getScoreAppearance(score)}
        onClick={objective ? handleClick : undefined}
        $iconOnly
        disabled={!objective}
        aria-label={intl.formatMessage({
          id: 'okrs.detail.keyResults.ratingButtonLabel'
        })}
      >
        <Icon size={IconSize.s24}>
          <Validation />
        </Icon>
        <Text appearance="text15strong" inheritColor>
          {score}%
        </Text>
      </Button>
    </Tippy>
  );
});
