import { useCallback } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import moment from 'moment';

import {
  Cgm as CgmChart,
  ContentBox,
  ContentBoxHead,
  ChartWidthProvider,
  StyledFullScreenLayerContent,
  ZoneData,
  Zones,
  CgmZone,
  Text,
  TextTag,
  TextSize,
  ExternalIcon
} from '@yarmill/components';

import {
  useCgmStore,
  useSampledCgmData,
  useZonesData,
  useZonesDefinition
} from './hooks';
import { Summary } from './summary';
import { CgmTooltip } from './tooltips';
import { ChartLoader } from '../workouts/chart-loader';
import {
  formatSeconds,
  formatSecondsWithWhitespaceHack,
  formatZonesDuration
} from '../workouts/utils';
import { ChartIcon } from '../workouts/chart-icon';
import { observer } from 'mobx-react-lite';

const MARGIN_RIGHT = 26;

const StyledGridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'summary   zones'
    'cgm cgm';
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  @media (max-width: 600px) {
    grid-template-columns: 100%;
    grid-template-areas:
      'summary'
      'zones'
      'cgm';
  }
`;

const StyledSummary = styled(ContentBox)`
  grid-area: summary;
`;

const StyledZones = styled(ContentBox)`
  grid-area: zones;
`;

const StyledChartHead = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: 500;
`;

const StyledHeadline = styled(Text)`
  margin-bottom: 24px;
`;

const StyledCgm = styled(ContentBox)`
  grid-area: cgm;
  padding-right: 2px;
`;

const StyledCgmDetail = styled(StyledFullScreenLayerContent)`
  max-width: 1000px;
  margin: 0 auto;
`;

const StyledLoaderWrapper = styled.div`
  margin-right: ${MARGIN_RIGHT}px;
`;

const StyledDate = styled.span`
  text-transform: capitalize;
`;

const ChartWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const CgmDetail = observer(function CgmDetail(): JSX.Element {
  const cgmStore = useCgmStore();
  const detail = cgmStore.cgm;

  const intl = useIntl();
  const sampledWorkoutData = useSampledCgmData(detail);
  const zonesData: ZoneData[] = useZonesData(detail);
  const zonesDefinition: CgmZone[] = useZonesDefinition(detail);

  const totalTime =
    detail && detail.TimeEvolution
      ? detail.TimeEvolution[detail.TimeEvolution.length - 1]
      : 0;

  const formatZoneLabel = useCallback(
    (zone: number): string => intl.formatMessage({ id: `cgm.zone.${zone}` }),
    [intl]
  );

  return (
    <StyledCgmDetail>
      <StyledHeadline tag={TextTag.h1} size={TextSize.s24}>
        {detail && (
          <>
            <FormattedMessage id="cgm.detail.headline" />
            &nbsp;-&nbsp;
            <StyledDate>
              <FormattedDate
                value={moment(detail.SelectedDay).toDate()}
                day="numeric"
                month="numeric"
                year="numeric"
                weekday="long"
              />
            </StyledDate>
          </>
        )}
        {!detail && cgmStore.cgmId && (
          <>
            <FormattedMessage id="cgm.detail.headline" />
            &nbsp;-&nbsp;
            <StyledDate>
              <FormattedDate
                value={moment(cgmStore.cgmId.selectedDay).toDate()}
                day="numeric"
                month="numeric"
                year="numeric"
                weekday="long"
              />
            </StyledDate>
          </>
        )}
      </StyledHeadline>
      <StyledGridLayout>
        <StyledSummary>
          <ContentBoxHead>
            <StyledChartHead>
              <ChartIcon>
                <ExternalIcon name="Sum" />
              </ChartIcon>
              <Text medium>
                <FormattedMessage id="cgm.detail.summary" />
              </Text>
            </StyledChartHead>
          </ContentBoxHead>
          <Summary detail={detail} />
        </StyledSummary>
        <StyledZones>
          <ContentBoxHead>
            <StyledChartHead>
              <ChartIcon>
                <ExternalIcon name="ChartArcs3" />
              </ChartIcon>
              <Text medium>
                <FormattedMessage id="cgm.detail.zones" />
              </Text>
            </StyledChartHead>
          </ContentBoxHead>
          <ChartWrapper>
            <ChartWidthProvider>
              {width =>
                cgmStore ? (
                  zonesData.length ? (
                    <Zones
                      width={width}
                      height={165}
                      data={zonesData}
                      formatDuration={formatZonesDuration}
                      formatZoneLabel={formatZoneLabel}
                    />
                  ) : (
                    <Text size={TextSize.s14}>
                      <FormattedMessage id="cgm.detail.missingData" />
                    </Text>
                  )
                ) : (
                  <ChartLoader width={width} height={150} />
                )
              }
            </ChartWidthProvider>
          </ChartWrapper>
        </StyledZones>
        <StyledCgm>
          <ContentBoxHead>
            <StyledChartHead>
              <ChartIcon>
                <ExternalIcon name="ChartBubble" />
              </ChartIcon>
              <Text medium>
                <FormattedMessage id="cgm.detail.egv" />
              </Text>
            </StyledChartHead>
          </ContentBoxHead>
          <ChartWrapper>
            <ChartWidthProvider>
              {width =>
                detail ? (
                  sampledWorkoutData.length ? (
                    <CgmChart
                      data={sampledWorkoutData}
                      height={250}
                      width={width}
                      showTooltip
                      showZones
                      zones={zonesDefinition}
                      xAxisKey="time"
                      formatXAxisTicks={formatSecondsWithWhitespaceHack}
                      formatTooltipCursor={formatSeconds}
                      total={totalTime}
                      domain={
                        detail &&
                        detail.EgvMax !== null &&
                        detail.EgvMin !== null
                          ? [detail.EgvMin - 20, detail.EgvMax + 20]
                          : undefined
                      }
                      formatTooltip={CgmTooltip}
                    />
                  ) : (
                    <Text size={TextSize.s14}>
                      <FormattedMessage id="cgm.detail.missingData" />
                    </Text>
                  )
                ) : (
                  <StyledLoaderWrapper>
                    <ChartLoader width={width - MARGIN_RIGHT} height={250} />
                  </StyledLoaderWrapper>
                )
              }
            </ChartWidthProvider>
          </ChartWrapper>
        </StyledCgm>
      </StyledGridLayout>
    </StyledCgmDetail>
  );
});
