import moment from 'moment';
import { useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  Icon,
  IconSize,
  StyledAttachmentIcon,
  StyledTd,
  StyledTr,
  Tag,
  TagAppearance,
  Text,
  TextAlignment,
  TextSize,
  WhiteSpace
} from '@yarmill/components';

import { AttachmentStore } from '../attributes/mobx/attachment-store';
import { FileOverview } from './types';
import { useFilesOverviewStore } from './files-overview-store-context';
import { FileLink } from '../attributes/attachment/file-link';
import {
  getDiaryRouteLink,
  getIndexForDate,
  ROUTE_DATE_FORMAT
} from '../diary/utils';
import { useFocusedDayService } from '../diary/hooks';
import { FileIcon } from './file-icon';
import { Link } from '../components/link';
import { toast } from '../components/toast-message';
import { useSize } from '../utils/use-size';
import { useRenameFileHandler } from './hooks/use-rename-file-handler';
import { Tippy } from '../components/tippy/tippy';

export interface FileRowProps {
  file: AttachmentStore;
}

const StyledButtonWrapper = styled.div`
  margin: -8px 0 -8px;
  display: flex;
  align-items: center;
  background-color: #fff;

  tr:hover & {
    background-color: #f7f9fb;
  }
`;

const ExtraControlButtonsStyles = css`
  height: 100%;
`;

const StyledDownloadLink = styled.div`
  display: inline-block;
  width: 39px;
  height: 36px;
`;

const AttachmentIcon = styled(StyledAttachmentIcon)`
  width: 24px;
  height: 24px;
`;

function stripTag(msg: string | string[]): string {
  const str = Array.isArray(msg) ? msg[0] : msg;
  return str.slice(0, 1);
}

export function FileRow(props: FileRowProps): JSX.Element {
  const { file } = props;
  const filesOverviewStore = useFilesOverviewStore();
  const attachment = file.attachment as FileOverview;
  const intl = useIntl();
  const focusedDayService = useFocusedDayService();
  const tagsRef = useRef<HTMLTableCellElement>(null);
  const tagsSize = useSize(tagsRef);
  const stripTags = (tagsSize.width || 130) <= 120;
  const isAllowedToModify =
    filesOverviewStore.isAllowedToDeleteFile(attachment);
  const [onRenameClick, renameFileLayer] = useRenameFileHandler(file);

  const removeRow = useCallback(async () => {
    const fileName = file.attachment.FileName;
    const message = intl.formatMessage(
      {
        id: 'trainingDay.attributes.attachment.removeConfirm'
      },
      { file: fileName }
    );

    if (window.confirm(message)) {
      const result = await file.remove();
      if (result) {
        toast('toast.success.removeAttachment', 'success', { file: fileName });
      } else {
        toast('toast.error.removeAttachment', 'error', { file: fileName });
      }
    }
  }, [file, intl]);

  const setFocusedDay = (): void => {
    setTimeout(
      () =>
        focusedDayService.setFocusedDay(
          getIndexForDate(attachment.ReferenceDate)
        ),
      0
    );
  };

  function dateLinkTarget(): string {
    return getDiaryRouteLink({
      diaryType: attachment.Type === 'P' ? 'plan' : 'reality',
      viewType: 'week',
      week: moment(attachment.ReferenceDate).format(ROUTE_DATE_FORMAT),
      groupId: filesOverviewStore.groupId || 0,
      athleteId: filesOverviewStore?.athleteId || 0
    });
  }

  return (
    <StyledTr>
      <StyledTd>
        <FileLink file={attachment}>
          <AttachmentIcon fileType={attachment.FileType}>
            <Icon size={IconSize.s12}>
              <FileIcon fileType={attachment.FileType} />
            </Icon>
          </AttachmentIcon>
        </FileLink>
      </StyledTd>
      <StyledTd align="left">
        <FileLink file={attachment}>
          <Text size={TextSize.s12} inheritColor>
            {attachment.FileName}
          </Text>
        </FileLink>
      </StyledTd>
      <StyledTd ref={tagsRef}>
        {attachment.Type === 'P' ? (
          <FormattedMessage id="filesOverview.tags.p">
            {(msg: string[]) => (
              <Tag appearance={TagAppearance.lightYellow}>
                {stripTags ? stripTag(msg) : msg}
              </Tag>
            )}
          </FormattedMessage>
        ) : (
          <FormattedMessage id="filesOverview.tags.r">
            {(msg: string[]) => (
              <Tag appearance={TagAppearance.darkYellow}>
                {stripTags ? stripTag(msg) : msg}
              </Tag>
            )}
          </FormattedMessage>
        )}
      </StyledTd>
      <StyledTd>
        <Link to={dateLinkTarget} onClick={setFocusedDay}>
          <Text
            size={TextSize.s12}
            textAlign={TextAlignment.right}
            inheritColor
          >
            <FormattedDate value={moment(attachment.ReferenceDate).toDate()} />
          </Text>
        </Link>
      </StyledTd>
      <StyledTd
        whiteSpace={WhiteSpace.noWrap}
        sticky={{ right: 0 }}
        extraStyles={ExtraControlButtonsStyles}
      >
        <StyledButtonWrapper>
          <Tippy tooltipContent="trainingDay.attributes.attachment.download">
            <StyledDownloadLink>
              <FileLink file={file.attachment} forceDownload>
                <Icon>
                  <ExternalIcon name="CloudDownload" />
                </Icon>
              </FileLink>
            </StyledDownloadLink>
          </Tippy>
          <Tippy tooltipContent="trainingDay.attributes.attachment.rename">
            <Button
              onClick={isAllowedToModify ? onRenameClick : undefined}
              disabled={!isAllowedToModify}
              appearance={ButtonAppearance.Link}
            >
              <Icon>
                <ExternalIcon name="Forms" />
              </Icon>
            </Button>
          </Tippy>
          {renameFileLayer}
          <Tippy tooltipContent="trainingDay.attributes.attachment.remove">
            <Button
              onClick={isAllowedToModify ? removeRow : undefined}
              disabled={!isAllowedToModify}
              appearance={ButtonAppearance.Link}
            >
              <Icon>
                <ExternalIcon name="Trash" />
              </Icon>
            </Button>
          </Tippy>
        </StyledButtonWrapper>
      </StyledTd>
    </StyledTr>
  );
}
