import { ExportSeasonSummary } from '../api/export-season-summary-to-xlsx';
import { api } from '../api';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { ExportSeasonEvaluationToXlsxRequestParams } from '../api/export-season-evaluation-to-xlsx';
import { ExportSeasonEvaluationToPdfRequestParams } from '../api/export-season-evaluation-to-pdf';
import {
  showExportErrorToastMessage,
  showExportSuccessToastMessage,
  showExportToastMessage
} from '../export-toast-message';
import { RootStore } from '../../app/mobx/root-store';
import { Id as ToastId } from 'react-toastify';
import { ExportReportingPageToPdfRequestParams } from '../api/export-reporting-page-to-pdf';
import { ExportDiaryAttributesToPdfRequestParams } from '../api/export-diary-attributes-to-pdf';
import { ExportDiaryActivitiesToPdfRequestParams } from '../api/export-diary-activities-to-pdf';
import { ExportOkrsToPdfRequestParams } from '../api/export-okrs-to-pdf';

export class ExportService {
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  public async exportSeasonSummaryToXlsx(
    params: ExportSeasonSummary,
    fileName: string
  ): Promise<void> {
    await this.export(
      api.exportSeasonSummaryToXlsx,
      params,
      `${fileName}.xlsx`
    );
  }

  public async exportSeasonEvaluationToXlsx(
    params: ExportSeasonEvaluationToXlsxRequestParams,
    fileName: string
  ): Promise<void> {
    await this.export(
      api.exportSeasonEvaluationToXlsx,
      params,
      `${fileName}.xlsx`
    );
  }

  public async exportSeasonEvaluationToPdf(
    params: ExportSeasonEvaluationToPdfRequestParams,
    fileName: string
  ): Promise<void> {
    await this.export(
      api.exportSeasonEvaluationToPdf,
      params,
      `${fileName}.pdf`
    );
  }

  public async exportReportingPageToPdf(
    params: ExportReportingPageToPdfRequestParams,
    fileName: string
  ): Promise<void> {
    await this.export(api.exportReportingPageToPdf, params, `${fileName}.pdf`);
  }

  public async exportDiaryAttributesToPdf(
    params: ExportDiaryAttributesToPdfRequestParams,
    fileName: string
  ): Promise<void> {
    await this.export(
      api.exportDiaryAttributesToPdf,
      params,
      `${fileName}.pdf`
    );
  }

  public async exportDiaryActivitiesToPdf(
    params: ExportDiaryActivitiesToPdfRequestParams,
    fileName: string
  ): Promise<void> {
    await this.export(
      api.exportDiaryActivitiesToPdf,
      params,
      `${fileName}.pdf`
    );
  }

  public async exportOkrsToPdf(
    params: ExportOkrsToPdfRequestParams,
    fileName: string
  ): Promise<void> {
    await this.export(api.exportOkrsToPdf, params, `${fileName}.pdf`);
  }

  private async export<P>(
    doExport: (p: P, cancelToken: CancelTokenSource) => AxiosPromise<BlobPart>,
    params: P,
    fileName: string
  ): Promise<void> {
    let toastId: ToastId = '';

    try {
      const request = this.rootStore.requestsStore.createRequest(cancelToken =>
        doExport(params, cancelToken)
      );
      let canceled = false;

      toastId = showExportToastMessage(fileName, () => {
        canceled = true;
        request.cancel();
      });

      const data = await request.getResponse();

      if (canceled) {
        return;
      }

      if (!data) {
        throw new Error('Invalid export response');
      }

      ExportService.downloadFile(data, fileName);

      showExportSuccessToastMessage(toastId, fileName);
    } catch (e: unknown) {
      console.error(e);
      showExportErrorToastMessage(toastId, fileName);
    }
  }

  private static downloadFile(fileLink: BlobPart, fileName: string): void {
    const element = document.createElement('a');
    element.download = fileName;
    element.href = window.URL.createObjectURL(new Blob([fileLink]));
    element.target = '_blank';
    element.rel = 'noopener noreferrer';

    element.click();
  }
}
