import * as React from 'react';
import styled, { css } from 'styled-components';

import { Button, ButtonProps } from '../button';

export const StyledSegmentedButton = styled(Button)<SegmentedButtonProps>`
  box-shadow: none;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  border-color: transparent;
  color: #4a4a4a;

  :hover,
  :active,
  :focus {
    box-shadow: none;
    background-color: #ffffff;
    border-color: transparent;
  }

  &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  &:first-child:not(:only-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child:not(:only-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ${props =>
    props.active &&
    css`
      border: 1px solid #4a90e2 !important;
      border-radius: 4px !important;
      color: #4a90e2;
    `}
`;

const StyledSegmentedControl = styled.div`
  display: inline-flex;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #ccc;
`;

export interface SegmentedButtonProps extends ButtonProps {
  active?: boolean;
}

export function SegmentedButton(props: SegmentedButtonProps) {
  return <StyledSegmentedButton {...props} />;
}

export function SegmentedControl(
  props: React.PropsWithChildren<{}>
): JSX.Element {
  return <StyledSegmentedControl>{props.children}</StyledSegmentedControl>;
}
