import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { AttachmentValue } from '../../training-day/types';

export interface RenameAttachmentRequestParams {
  FileId: string;
  FileName: string;
}

export function renameAttachament(
  params: RenameAttachmentRequestParams
): AxiosPromise<AttachmentValue> {
  return axios.post('api/TrainingDayAttributeAttachment/rename', params);
}
