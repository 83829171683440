import moment from 'moment';
import { groupBy, mapObjIndexed } from 'ramda';
import { FormattedMessage } from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  Bar,
  BarChart,
  LabelList,
  LabelProps,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';

import { ChartTooltip } from '../../chart-tooltip';
import { StressCompare as IStressCompare } from './types';
import { formatNumber } from './utils';
import { observer } from 'mobx-react-lite';

export interface StressCompareProps {
  stressCompare: IStressCompare[];
  seasonMonths: string[];
}

function formatValue(value: number): number {
  return value * 100;
}

const getTooltipLabel = (props: TooltipProps<number, string>): string => {
  const label =
    props.payload && props.payload[0] && props.payload[0].payload
      ? props.payload[0].payload.tooltipLabel
      : props.label;

  return label;
};

export const StressCompare = observer(function StressCompare(
  props: StressCompareProps
): JSX.Element {
  const { stressCompare, seasonMonths } = props;
  const groupedData = groupBy(item => item.ActivityStress, stressCompare);

  const data = seasonMonths.map(month => ({
    label: moment(month).format('MMM'),
    ...mapObjIndexed(dataset => {
      const item = dataset.find(d => d.Month === month);

      return item ? item.Value : 0;
    }, groupedData)
  }));

  return (
    <AutoSizer disableHeight style={{ height: 200, width: '100%' }}>
      {({ width }) => (
        <BarChart
          throttleDelay={0}
          width={width}
          height={200}
          margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
          data={data}
        >
          <XAxis dataKey="label" axisLine={false} tickLine={false} />
          <YAxis axisLine={false} tickLine={false} tick={false} width={10} />
          <Tooltip
            cursor={{ fill: 'rgba(206, 206, 206, 0.2)' }}
            content={(props: TooltipProps<number, string>) => (
              <ChartTooltip
                label={getTooltipLabel(props)}
                items={
                  props.payload
                    ? props.payload.map(item => ({
                        color: item.color as string,
                        id: String(item.name),
                        label: (
                          <FormattedMessage
                            id={`analytics.sps-slcr.label.stressCompare.${item.name}`}
                          />
                        ),
                        value: `${formatNumber(
                          formatValue(item.value as number)
                        )}%`
                      }))
                    : []
                }
              />
            )}
          />
          <Bar dataKey="Low" stackId={0} fill="#C6DFF4" barSize={15}>
            <LabelList
              dataKey="Low"
              position="left"
              offset={0}
              content={(props: LabelProps) =>
                `${formatNumber(formatValue(props.value as number))}%`
              }
            />
          </Bar>
          <Bar dataKey="High" stackId={0} fill="#4B7BA2" barSize={15}>
            <LabelList
              dataKey="High"
              offset={0}
              position="left"
              content={(props: LabelProps) =>
                `${formatNumber(formatValue(props.value as number))}%`
              }
            />
          </Bar>
        </BarChart>
      )}
    </AutoSizer>
  );
});
