import { BarChart, DataLabelDefinition } from '../types';
import { DataItem, getSSRStringWidth } from '@yarmill/components';
import { useScale } from '../hooks/use-scale';
import { getBarLabelY } from './get-bar-label-y';
import { getBarDimensions } from './get-bar-dimensions';
import { getBarLabelOffset } from './get-bar-label-offset';
import { getRotatedHeight } from '../../reporting/utils/get-rotated-height';
import { LABEL_FONT_SIZE } from '../../reporting/const';

export function getBarLabel(
  item: DataItem,
  xScale: ReturnType<typeof useScale>,
  yScale: ReturnType<typeof useScale>,
  chartConfig: BarChart,
  priority: number
): DataLabelDefinition | undefined {
  const dimensions = getBarDimensions(chartConfig, item, xScale, yScale);
  const showLabel = chartConfig.getShowLabels(item);
  if (!dimensions || !showLabel || !('bandwidth' in xScale)) {
    return undefined;
  }

  const { x, height: barHeight, width } = dimensions;
  const yValue = chartConfig.getYValue(item);
  const formattedValue = chartConfig.formatLabelValue?.(yValue) ?? yValue;
  const color = chartConfig.getLabelColor(item);
  const position = chartConfig.labelPosition;
  const y = getBarLabelY(yScale, yValue);
  const angle = chartConfig.getLabelAngle(item);
  const labelWidth = getSSRStringWidth(formattedValue);
  const labelOffset = getBarLabelOffset(
    barHeight,
    yValue,
    position,
    angle,
    labelWidth
  );
  const labelHeight = getRotatedHeight(labelWidth, LABEL_FONT_SIZE, angle);

  if (
    labelHeight + Math.abs(labelOffset) > barHeight &&
    position !== 'outside'
  ) {
    return undefined;
  }

  return {
    x: x + width / 2,
    y: y - labelOffset,
    originalValue: yValue,
    text: String(formattedValue),
    angle,
    color,
    priority,
    maxWidth: position !== 'outside' ? width : xScale.step(),
    maxHeight: xScale.step()
  };
}
