import { BarStack as VisxBarStack } from '@visx/shape/lib/types';
import { StackedBarChart } from '../../types';
import { useMemo } from 'react';
import { groupStackedBarsByCategories } from '../../utils/group-stacked-bars-by-categories';
import { sortStackerBarsByValue } from '../../utils/sort-stacker-bars-by-value';
import { useXYChartContext } from '../../xy-chart-context';
import { BarStack } from './bar-stack';
import { DataItem } from '../../../reporting/types';

interface BarsStacksProps {
  readonly config: StackedBarChart;
  readonly stacks: VisxBarStack<DataItem, string>[];
  readonly data: DataItem[];
}
export function BarsStacks({ stacks, config, data }: BarsStacksProps) {
  const { yScale } = useXYChartContext();
  const groupedStacks = useMemo(
    () => groupStackedBarsByCategories(stacks),
    [stacks]
  );
  const sortedStacks = useMemo(
    () =>
      groupedStacks.map(group =>
        config.sort.toLowerCase() === 'value'
          ? sortStackerBarsByValue(group, yScale)
          : group
      ),
    [config.sort, groupedStacks, yScale]
  );

  const filteredStacks = useMemo(
    () => sortedStacks.map(stack => stack.filter(bar => bar.height)),
    [sortedStacks]
  );

  return (
    <>
      {filteredStacks.map((stack, idx) => (
        <BarStack
          key={idx}
          stack={stack}
          stackIndex={idx}
          config={config}
          item={data[stack[0]?.index]}
        />
      ))}
    </>
  );
}
