import { KpiReportData } from './types';
import { SingleValueReport } from '@yarmill/components';
import { useIntl } from 'react-intl';
import { formatValueByBusinessFormat, getIcon } from './utils';
import { observer } from 'mobx-react-lite';
import { KpiStore } from './mobx/kpi-store';
import { ReportContentBox } from './report-content-box';
import { useReportingDataStore } from './hooks/use-reporting-data-store';
import { Fragment } from 'react';

export interface KpiReportProps {
  readonly report: KpiStore;
  readonly data?: KpiReportData;
  readonly noReportWrapper?: boolean;
}

export const KpiReport = observer(function KpiReport(
  props: KpiReportProps
): JSX.Element {
  const { report, data: passedData, noReportWrapper } = props;
  const dataStore = useReportingDataStore();
  const intl = useIntl();

  const data =
    passedData ??
    (dataStore?.getReportData(report.code) as KpiReportData | undefined);

  const formattedValue = formatValueByBusinessFormat(
    data?.Data,
    report.valueDefinition?.BusinessFormat,
    report.valueDefinition?.Format ?? null
  );

  const Wrapper = noReportWrapper ? Fragment : ReportContentBox;

  return (
    <Wrapper>
      <SingleValueReport
        icon={getIcon(report.icon)}
        textIcon={report.textIcon}
        value={intl.formatMessage(
          { id: report.kpiValue },
          {
            value: formattedValue
          }
        )}
        description={intl.formatMessage({
          id: report.kpiDescription
        })}
      />
    </Wrapper>
  );
});
