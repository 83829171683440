import { createElement } from 'react';

export function ListBullet() {
  return createElement(
    'svg',
    {
      viewBox: '0 0 18 18',
      xmlns: 'http://www.w3.org/2000/svg',
      fill: 'currentColor',
      role: 'img'
    },
    createElement('path', {
      fill: 'none',
      stroke: 'currentColor',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.6',
      d: 'M6 4h9M6 9h9M6 14h9M3 4h0M3 9h0M3 14h0'
    })
  );
}
