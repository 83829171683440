import styled from 'styled-components';
import { PropsWithChildren } from 'react';
import { Text, TextSize, TextTag } from '../text';

const StyledKeyboardShortcut = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
`;
export function KeyboardShortcut(props: PropsWithChildren<{}>): JSX.Element {
  const { children } = props;

  return (
    <StyledKeyboardShortcut>
      <Text tag={TextTag.span} size={TextSize.s12} inheritColor>
        {children}
      </Text>
    </StyledKeyboardShortcut>
  );
}
