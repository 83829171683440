import { ReactElement, useCallback } from 'react';
import { File as iFile } from '../../fileupload/types';
import { FileLink } from '../../attributes/attachment/file-link';
import {
  AttachmentFileInput,
  AttachmentIcon,
  Button,
  ButtonAppearance,
  ExternalIcon,
  Icon,
  IconSize,
  Text,
  TextSize,
  TextTag
} from '@yarmill/components';
import { FileIcon } from '../../files-overview/file-icon';
import { Tippy } from '../../components/tippy/tippy';
import {
  AddFileButton,
  FileAttributeLayout,
  FileLayout,
  FilesLayout,
  NoValueWrapper,
  StyledButtonsWrapper,
  StyledDownloadLink
} from '../components/files';
import { EvidenceAttachmentStore } from '../mobx/evidence-attachment-store';
import { useEvidenceFileUpload } from '../hooks/use-evidence-file-upload';
import { useEvidenceRenameFileHandler } from '../hooks/use-evidence-rename-file-handler';
import { observer } from 'mobx-react-lite';
import { toast } from '../../components/toast-message';
import { FormattedMessage, useIntl } from 'react-intl';

type Variant = 'table-form' | 'default';

interface FileAttributeProps {
  readonly attribute: EvidenceAttachmentStore;
  readonly hideAdd?: boolean;
  readonly variant?: Variant;
  readonly autoSave?: boolean;
}

interface FileProps {
  readonly file: iFile;
  readonly isCurrentUserAllowedToWrite: boolean;
  readonly removeFile: (file: iFile) => void;
  readonly variant: Variant;
  readonly save: () => Promise<boolean>;
  readonly autoSave: boolean;
}
function File({
  file,
  isCurrentUserAllowedToWrite,
  removeFile,
  variant,
  save,
  autoSave
}: FileProps) {
  const intl = useIntl();
  const isAllowedToModify = isCurrentUserAllowedToWrite;
  const [onRenameClick, renameFileLayer] = useEvidenceRenameFileHandler(
    file,
    save,
    autoSave
  );
  const remove = async () => {
    const fileName = file.FileName;
    const message = intl.formatMessage(
      {
        id: 'trainingDay.attributes.attachment.removeConfirm'
      },
      { file: fileName }
    );

    if (window.confirm(message)) {
      removeFile(file);
      if (autoSave) {
        const result = await save();
        if (result) {
          toast('toast.success.removeAttachment', 'success', {
            file: fileName
          });
        } else {
          toast('toast.error.removeAttachment', 'error', { file: fileName });
        }
      }
    }
  };

  return (
    <FileLayout variant={variant}>
      <FileLink file={file} square>
        <AttachmentIcon fileType={file.FileType}>
          <Icon size={IconSize.s12}>
            <FileIcon fileType={file.FileType} />
          </Icon>
        </AttachmentIcon>
      </FileLink>
      <FileLink file={file}>
        <Text size={TextSize.s12} inheritColor ellipsis tag={TextTag.div}>
          {file.FileName}
        </Text>
      </FileLink>
      <StyledButtonsWrapper>
        <Tippy tooltipContent="trainingDay.attributes.attachment.download">
          <StyledDownloadLink>
            <FileLink file={file} forceDownload iconOnly>
              <Icon>
                <ExternalIcon name="CloudDownload" />
              </Icon>
            </FileLink>
          </StyledDownloadLink>
        </Tippy>
        <Tippy tooltipContent="trainingDay.attributes.attachment.rename">
          <Button
            onClick={isAllowedToModify ? onRenameClick : undefined}
            disabled={!isAllowedToModify}
            appearance={ButtonAppearance.Link}
            iconOnly
            type="button"
          >
            <Icon>
              <ExternalIcon name="Forms" />
            </Icon>
          </Button>
        </Tippy>
        {renameFileLayer}
        <Tippy tooltipContent="trainingDay.attributes.attachment.remove">
          <Button
            onClick={isAllowedToModify ? remove : undefined}
            disabled={!isAllowedToModify}
            appearance={ButtonAppearance.Link}
            iconOnly
            type="button"
          >
            <Icon>
              <ExternalIcon name="Trash" />
            </Icon>
          </Button>
        </Tippy>
      </StyledButtonsWrapper>
    </FileLayout>
  );
}

export const FileAttribute = observer(function FileAttribute({
  attribute,
  hideAdd = false,
  variant = 'default',
  autoSave = true
}: FileAttributeProps): ReactElement {
  const files = attribute.attachments;
  const isCurrentUserAllowedToWrite =
    attribute.objectDataStore.isCurrentUserAllowedToWrite;
  const [inputRef, onChange] = useEvidenceFileUpload(attribute);
  const save = useCallback(
    async () => await attribute.objectDataStore.syncValue(),
    [attribute]
  );

  return (
    <FileAttributeLayout hideAdd={hideAdd}>
      {files.length === 0 && (
        <NoValueWrapper>
          <Text size={TextSize.s14}>
            {variant === 'default' ? (
              '-'
            ) : (
              <FormattedMessage id="evidence.attributes.attachment.noFiles" />
            )}
          </Text>
        </NoValueWrapper>
      )}
      {files.length !== 0 && (
        <FilesLayout>
          {files.map(file => (
            <File
              file={file}
              key={file.FileId}
              isCurrentUserAllowedToWrite={isCurrentUserAllowedToWrite}
              removeFile={attribute.removeAttachment}
              save={save}
              variant={variant}
              autoSave={autoSave}
            />
          ))}
        </FilesLayout>
      )}
      {!hideAdd && (
        <AddFileButton disabled={!isCurrentUserAllowedToWrite}>
          <Icon>+</Icon>
          <AttachmentFileInput
            ref={inputRef}
            type="file"
            name="fileData"
            onChange={onChange}
            multiple
            disabled={!isCurrentUserAllowedToWrite}
          />
        </AddFileButton>
      )}
    </FileAttributeLayout>
  );
});
