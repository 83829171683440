export const cs = {
  'auth.email': 'E-mail',
  'auth.error.invalidLogin':
    '<strong>Krutibrko, tyhle přihlašovací údaje nefungují.</strong><br><div class="tips"><p>Zkus zkontrolovat, že:</p><ul><li>zadáváš email, na který jsi se registroval</li><li>nemáš přepnutý jazyk klávesnice</li><li>se přihlašuješ do správného týmu</li></ul></div>',
  'auth.error.userDisabled':
    '<strong>Už tě do deníku nepustíme. Musíš poprosit administrátora.</strong>',
  'auth.error.userNotActive':
    'Tohle nejde. Nejdřív se musíš registrovat. Klikni na odkaz v pozvánce, která ti přisla emailem.',
  'auth.forgottenPassword': 'Zapomenuté heslo',
  'auth.forgottenPasswordBack': 'Zpět na přihlašovací formulář',
  'auth.forgottenPasswordSuccess':
    'Pokyny pro resetování hesla byly odeslány na váš e-mail',
  'auth.login.capsLock': 'Máš zapnutý Caps Lock',
  'auth.login.inactiveInstance':
    'Tady už Yarmill není 🥹. Napsat mu můžeš na <a href="mailto:hello@yarmill.com">hello@yarmill.com</a>',
  'auth.login.tips':
    '<p>Zkus zkontrolovat, že:</p>\n<ul>\n<li>zadáváš email, na který jsi se registroval</li>\n<li>nemáš přepnutý jazyk klávesnice</li>\n<li>se přihlašuješ do správného týmu</li>\n</ul>',
  'auth.loginButton': 'Přihlásit se',
  'auth.loginPageHeading': 'Přihlášení',
  'auth.password': 'Heslo',
  'auth.submitForgottenPassword': 'Odeslat',
  'auth.username': 'E-mail',
  'fatalError.button.logout': 'Odhlásit se',
  'fatalError.button.refresh': 'Obnovit',
  'fatalError.button.reset': 'Resetovat',
  'fatalError.description':
    'O chybě jsme informovali náš technický tým. Omlouváme se za případné nepříjemnosti. Pokud nic z níže uvedeného nepomůže, napiš nám na <a href="mailto:support@yarmill.com">support@yarmill.com</a>.',
  'fatalError.headline': '😳 Nastal problém',
  'fatalError.resetClient.checkbox': 'Resetovat aplikaci',
  'fatalError.resetClient.description':
    'Resetování obnoví celou aplikaci a může pomoci vyřešit problém.'
};
