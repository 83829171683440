import { useScale } from '../hooks/use-scale';
import { getAxisPosition } from '../../reporting/utils/get-axis-position';
import { getBarHeight } from './get-bar-height';

export function getBarLabelY(
  yScale: ReturnType<typeof useScale>,
  barValue: number
): number {
  const domainY = yScale.domain();
  const axisPosition = getAxisPosition(domainY as number[]);

  if ('bandwidth' in yScale) {
    throw new Error('Bars are currently supported only in categorical scale');
  }

  const barHeight = getBarHeight(yScale, barValue);

  return barValue < 0 ? yScale(axisPosition) + barHeight : yScale(barValue);
}
