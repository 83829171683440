import {
  useTooltip as useVisxTooltip,
  useTooltipInPortal
} from '@visx/tooltip';
import { createContext, FC, useContext, useMemo } from 'react';
import { TooltipInPortalProps } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import { GetTooltipData, TooltipData } from '../../reporting/types';
import { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip';

interface TooltipContextValue {
  readonly TooltipInPortal: FC<TooltipInPortalProps>;
  readonly tooltipOpen: boolean;
  readonly tooltipLeft?: number;
  readonly tooltipTop?: number;
  readonly tooltipData?: TooltipData;
  readonly showTooltip: UseTooltipParams<TooltipData>['showTooltip'];
  readonly hideTooltip: UseTooltipParams<TooltipData>['hideTooltip'];
  readonly getTooltipData: GetTooltipData;
}
export const TooltipContext = createContext<TooltipContextValue | undefined>(
  undefined
);

export function useTooltipContext(): TooltipContextValue {
  const value = useContext(TooltipContext);
  if (!value) {
    throw new Error('TooltipContext not defined');
  }
  return value;
}

export function useTooltip(
  getTooltipData: GetTooltipData
): [(element: HTMLElement | SVGElement | null) => void, TooltipContextValue] {
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
    detectBounds: true,
    debounce: 25
  });

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
  } = useVisxTooltip<TooltipData>();

  const contextValue = useMemo(
    () => ({
      TooltipInPortal,
      tooltipOpen,
      tooltipLeft,
      tooltipTop,
      tooltipData,
      hideTooltip,
      showTooltip,
      getTooltipData
    }),
    [
      TooltipInPortal,
      hideTooltip,
      showTooltip,
      tooltipData,
      tooltipLeft,
      tooltipOpen,
      tooltipTop,
      getTooltipData
    ]
  );

  return [containerRef, contextValue];
}
