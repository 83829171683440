import axios, { AxiosResponse } from 'axios';
import { GoogleMapsPlace } from '../types';

interface SearchPlacesRequestParams {
  textQuery: string;
}
export function searchPlaces(params: SearchPlacesRequestParams): Promise<
  AxiosResponse<{
    places: GoogleMapsPlace[];
  }>
> {
  return axios.post(
    'https://places.googleapis.com/v1/places:searchText',
    params,
    {
      headers: {
        'X-Goog-Api-Key': 'AIzaSyCmdXYnczCIkzlDRLztDiuQ4wyHBV_5NT8',
        'X-Goog-FieldMask':
          'places.displayName,places.formattedAddress,places.location,places.name'
      }
    }
  );
}
