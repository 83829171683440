import {
  Mark,
  markInputRule,
  markPasteRule,
  mergeAttributes
} from '@tiptap/core';
import styled, { css } from 'styled-components';
import { Editor } from '@tiptap/react';
import { getSelectedText } from '../utils/get-selected-text';

export interface HighlightOptions {
  multicolor: boolean;
  HTMLAttributes: Record<string, any>;
}

export const RICHTEXT_HIGHLIGHT_COLORS = {
  orange: 'rgb(247, 204, 98)',
  pink: 'rgb(255, 191, 181)',
  green: 'rgb(181, 220, 175)',
  blue: 'rgb(214, 232, 250)',
  purple: 'rgb(216, 195, 255)'
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    highlight: {
      /**
       * Set a highlight mark
       */
      setHighlight: (attributes?: { color: string }) => ReturnType;
      /**
       * Toggle a highlight mark
       */
      toggleHighlight: (attributes?: { color: string }) => ReturnType;
      /**
       * Unset a highlight mark
       */
      unsetHighlight: () => ReturnType;
    };
  }
}

export const inputRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))$/;
export const pasteRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))/g;

export const Highlight = Mark.create<HighlightOptions>({
  name: 'highlight',

  addOptions() {
    return {
      multicolor: true,
      HTMLAttributes: {}
    };
  },

  addAttributes() {
    if (!this.options.multicolor) {
      return {};
    }

    return {
      color: {
        default: null,
        parseHTML: (element: HTMLElement) =>
          element.getAttribute('data-highlightColor') ||
          element.style.backgroundColor ||
          element.className
            .split(' ')
            ?.find(c => c.includes('highlight'))
            ?.replace('highlight-', ''),
        renderHTML: attributes => {
          if (!attributes.color) {
            return {};
          }

          return {
            class: `highlight-${attributes.color}`
          };
        }
      },
      tag: {
        default: null,
        parseHTML: (element: HTMLElement) => element.nodeName,
        renderHTML: attributes => {
          if (!attributes.tag) {
            return {};
          }

          return {
            tag: attributes.tag
          };
        }
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: '*',
        getAttrs: element =>
          element instanceof HTMLElement &&
          element.className.includes('highlight')
            ? { color: true, tag: true }
            : false
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { tag, ...otherAttributes } = HTMLAttributes;
    return [
      tag || 'span',
      mergeAttributes(this.options.HTMLAttributes, otherAttributes),
      0
    ];
  },

  addCommands() {
    return {
      setHighlight:
        attributes =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes);
        },
      toggleHighlight:
        attributes =>
        ({ commands }) => {
          return commands.toggleMark(this.name, attributes);
        },
      unsetHighlight:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        }
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-h': () => this.editor.commands.toggleHighlight()
    };
  },

  addInputRules() {
    return [
      markInputRule({
        find: inputRegex,
        type: this.type
      })
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: pasteRegex,
        type: this.type
      })
    ];
  }
});

const PickerContainer = styled.div`
  display: flex;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(99, 114, 130, 0.16) 0 0 0 1px,
    rgba(27, 39, 51, 0.08) 0 8px 16px;

  border-radius: 3px;
  z-index: 1;
  padding: 3px 5px;
  white-space: nowrap;
`;

const PickerButton = styled.button<{
  color?: string;
  clear?: boolean;
  isActive?: boolean;
}>`
  cursor: pointer;
  border: 1px solid transparent;
  float: left;
  padding: 0;
  margin: 7px 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: transform 100ms ease-in 0s, box-shadow 100ms ease-in 0s;
  transform-origin: center center;
  box-shadow: none;
  ${({ color }) =>
    color && `background-color: ${RICHTEXT_HIGHLIGHT_COLORS[color]}`};

  :hover {
    transform: scale(1.25, 1.25);
    box-shadow: rgb(193 199 205) 0px 3px 8px;
  }

  ${({ isActive, color }) =>
    isActive &&
    css`
      position: relative;
      :hover {
        transform: none;
        box-shadow: none;
      }
      :after {
        ${color && `background-color: ${RICHTEXT_HIGHLIGHT_COLORS[color]}`};
        position: absolute;
        bottom: -11px;
        left: 0;
        content: '';
        height: 3px;
        width: 100%;
      }
    `}

  ${({ clear }) =>
    clear &&
    css`
      border-width: 2px;
      border-color: #637282;
      background-color: transparent;
      position: relative;
      margin-left: 16px;

      &:hover {
        transform: none;
        border-color: #1b2733;
        box-shadow: none;

        &:after {
          background-color: #1b2733;
        }
      }

      &:before {
        position: absolute;
        left: -13px;
        top: -7px;
        content: '';
        height: 30px;
        width: 1px;
        background-color: #e6e8eb;
      }

      &:after {
        position: absolute;
        content: '';
        height: 20px;
        width: 2px;
        background-color: #637282;
        left: 7px;
        top: -2px;
        transform: rotate(45deg);
      }
    `}
`;

export function HighlightColorPicker({
  editor,
  onSelect
}: {
  editor: Editor;
  onSelect: () => void;
}): JSX.Element {
  const selectedColor = editor.getAttributes('highlight')?.color;

  return (
    <PickerContainer onClick={() => editor.chain().focus().run()}>
      {Object.keys(RICHTEXT_HIGHLIGHT_COLORS).map(color => (
        <PickerButton
          key={color}
          color={color}
          onClick={() => {
            onSelect();
            const selectedText = getSelectedText(editor);
            if (selectedText) {
              editor
                .chain()
                .focus()
                .setHighlight({ color })
                .insertContent(selectedText)
                .run();
            } else {
              editor.chain().focus().toggleHighlight({ color }).run();
            }
          }}
          isActive={selectedColor === color}
        />
      ))}
      <PickerButton
        clear
        onClick={() => {
          editor.chain().focus().unsetHighlight().run();
          onSelect();
        }}
      />
    </PickerContainer>
  );
}
