import { TextInput, TextInputProps } from '../text-input';
import * as React from 'react';
import { forwardRef } from 'react';

export interface NumberInputProps extends Omit<TextInputProps, 'onChange'> {
  isInteger?: boolean;
  defaultValue?: string;
  value?: string;
  onChange?(value: string): void;
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  function NumberInput(props, ref): JSX.Element {
    const { defaultValue } = props;
    const {
      isInteger,
      onChange: passedOnChange,
      value,
      ...textInputProps
    } = props;

    const [persistedValue, setPersistedValue] = React.useState(
      defaultValue ? String(defaultValue) : ''
    );

    const onChange = React.useCallback(
      (e: React.FormEvent) => {
        const { target } = e;
        if (target instanceof HTMLInputElement) {
          let { value: newValue } = target;
          const dotValue = newValue.replace(',', '.');
          const isValueValid =
            (!Number.isNaN(Number(dotValue)) &&
              (isInteger ? !dotValue.includes('.') : true)) ||
            (dotValue.length === 1 && dotValue === '-');

          if (isValueValid) {
            const trimmedValue = newValue.trim();
            if (passedOnChange) {
              passedOnChange(trimmedValue);
            } else {
              setPersistedValue(trimmedValue);
            }
          } else {
            e.preventDefault();
          }
        }
      },
      [passedOnChange, isInteger]
    );

    return (
      <TextInput
        {...textInputProps}
        onChange={onChange}
        ref={ref}
        value={value !== undefined ? value : persistedValue}
      />
    );
  }
);

export const IntegerInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (props, ref) => <NumberInput {...props} ref={ref} isInteger />
);
