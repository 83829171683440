import { ReactNode } from 'react';
import { ScaleTypeToD3Scale } from '@visx/scale';

import { getAxisPosition } from '../reporting/utils/get-axis-position';
import { LABEL_FONT_SIZE, LABEL_OFFSET } from '../reporting/const';
import { DataItem, LabelPosition } from '../reporting/types';
import { ChartType } from './types';

export type HorizontalChartConfig<T extends DataItem> =
  | HorizontalBarGroupChart<T>
  | HorizontalStackedBarChart<T>;

export interface HorizontalStackedBarChart<T extends DataItem>
  extends BaseHorizontalChartConfig<T> {
  type: 'BarStack';
  showCategoryGaps?: boolean;
  sort: 'value' | 'category';
}

interface BaseHorizontalChartConfig<T extends DataItem> {
  code: string;
  type: ChartType;
  getYValue(item: T): string;
  getShowLabels(item: T, key?: string): boolean;
  labelPosition?: LabelPosition;
  keys: string[];
  formatLabelValue?: (value?: number, key?: string) => ReactNode;
  getColor(item: T, key?: string): string;
  getLabelColor(item: T, key?: string): string;
  getOpacity(item: T, key?: string): number | undefined;
}

export interface HorizontalBarGroupChart<T extends DataItem>
  extends BaseHorizontalChartConfig<T> {
  type: 'BarGroup';
  getBarWidth(item: T, key?: string): number;
  getLabelAngle(item: T, key?: string): number;
}

export function shouldShowBarLabelHorizontal(
  showLabels: boolean | undefined,
  position: LabelPosition | undefined,
  barWidth: number,
  barHeight: number,
  textWidth: number = 0,
  xValue: number = 0
): boolean {
  if (!showLabels || !barWidth || !barHeight || !textWidth) {
    return false;
  }

  if (barHeight < LABEL_FONT_SIZE) {
    return false;
  }

  if (position === 'outside' && Number(xValue) < 0) {
    return true;
  }

  if (position === 'inside-center' || position === 'inside-top') {
    if (textWidth + LABEL_OFFSET > barWidth) {
      return false;
    }
  }

  return true;
}

export function getBarLabelX(
  xScale: ScaleTypeToD3Scale<number>['linear'],
  _xMax: number,
  barWidth: number,
  barValue: number,
  position?: LabelPosition
): (s: number) => number {
  const domain = xScale.domain();
  const barX = xScale(getAxisPosition(domain));

  return (s: number) => {
    if (barValue >= 0) {
      switch (position) {
        case 'inside-center': {
          return barX + s * (barWidth / 2);
        }
        case 'inside-top': {
          return barX + s * barWidth - LABEL_OFFSET * 2;
        }
        case 'outside':
        default:
          return barX + s * barWidth + LABEL_OFFSET;
      }
    } else {
      switch (position) {
        case 'inside-center': {
          return barX - s * (barWidth / 2);
        }
        case 'inside-top': {
          return barX - s * barWidth + LABEL_OFFSET;
        }
        case 'outside':
        default:
          return barX + 2 * LABEL_OFFSET;
      }
    }
  };
}

export function getBarStackLabelX(
  barWidth: number,
  barX: number,
  position: LabelPosition,
  textWidth: number
): number {
  switch (position) {
    case 'inside-center': {
      return barX + barWidth / 2;
    }
    case 'inside-top': {
      const offsetLeft = barWidth - textWidth - LABEL_OFFSET * 2;
      const offsetRight = LABEL_OFFSET * 2;

      if (offsetLeft < offsetRight) {
        return barX + barWidth / 2;
      }

      return barX + barWidth - LABEL_OFFSET * 2;
    }
    case 'outside':
    default:
      return barX + barWidth + LABEL_OFFSET;
  }
}

export function getHorizontalBarLabelTextAnchor(
  position?: LabelPosition,
  xValue?: number,
  textWidth?: number,
  barWidth?: number
): 'start' | 'end' | 'middle' {
  switch (position) {
    case 'inside-center': {
      return 'middle';
    }
    case 'inside-top': {
      if (textWidth && barWidth) {
        const offsetLeft = barWidth - textWidth - LABEL_OFFSET * 2;
        const offsetRight = LABEL_OFFSET * 2;

        if (offsetLeft < offsetRight) {
          return 'middle';
        }
      }
      return (Number(xValue) || 0) < 0 ? 'start' : 'end';
    }
    case 'outside':
    default:
      return 'start';
  }
}
