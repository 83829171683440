import { ButtonAppearance, Spinner } from '@yarmill/components';
import { Fragment } from 'react';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import { AsyncState } from '../components/async-button/async-button';
import { toast } from '../components/toast-message';
import { User, userCookies, UserInvitation } from './types';

export function isValidInvitation(invitation: UserInvitation): boolean {
  const ignoreWS = { ignore_whitespace: true };

  try {
    return (
      // @ts-ignore wrong typedef, missing options
      !isEmpty(invitation.FirstName, ignoreWS) &&
      // @ts-ignore
      !isEmpty(invitation.LastName, ignoreWS) &&
      isEmail(invitation.Email) &&
      !invitation.EmailAlreadyExist
    );
  } catch (e: unknown) {
    return false;
  }
}

export function isEmptyInvitation(invitation: UserInvitation): boolean {
  return !invitation.FirstName && !invitation.LastName && !invitation.Email;
}

export function isInvitationHasEmail(invitation: UserInvitation): boolean {
  const ignoreWS = { ignore_whitespace: true };

  return !isEmpty(invitation.Email, ignoreWS);
}

export const getUserName = (a: Partial<User>): string =>
  `${a.LastName} ${a.FirstName}`;

export function toastInviteUsers(invitations: UserInvitation[]): void {
  if (invitations.length === 1) {
    toast('toast.success.inviteUser', 'success', {
      user: getUserName({
        LastName: invitations[0].LastName,
        FirstName: invitations[0].FirstName
      })
    });
  } else {
    toast('toast.success.inviteUsers', 'success', {
      count: String(invitations.length)
    });
  }
}

export function getSubmitButtonIcon(state: AsyncState): JSX.Element | null {
  let icon = null;

  if (state.loading) {
    icon = (
      <Fragment>
        <Spinner />
        &nbsp;
      </Fragment>
    );
  }
  if (state.error) {
    icon = (
      <Fragment>
        <span>✖</span>
        &nbsp;
      </Fragment>
    );
  }

  if (state.loaded && !state.error) {
    icon = (
      <Fragment>
        <span>✓</span>
        &nbsp;
      </Fragment>
    );
  }

  return icon;
}

export function getButtonAppearance(state: AsyncState): ButtonAppearance {
  if (state.loading) {
    return ButtonAppearance.Primary;
  }

  if (state.error) {
    return ButtonAppearance.Error;
  }

  if (state.loaded && !state.error) {
    return ButtonAppearance.Success;
  }

  return ButtonAppearance.Primary;
}

export function persistCurrentUser(user: User): void {
  window.localStorage.setItem(userCookies.USER, String(user.UserId));
}

export function loadPersistedCurrentUser(): number | null {
  const value =
    typeof window !== 'undefined'
      ? window.localStorage.getItem(userCookies.USER)
      : null;

  if (value) {
    try {
      return parseInt(value, 10);
    } catch (e: unknown) {
      return null;
    }
  }

  return null;
}

export const isAdmin = (currentUser: User | null): boolean =>
  Boolean(currentUser && currentUser.Role === 'admin');
export const isAthlete = (currentUser: User | null): boolean =>
  Boolean(currentUser && currentUser.Role === 'athlete');
export const isCoach = (currentUser: User | null): boolean =>
  Boolean(currentUser && currentUser.Role === 'coach');
