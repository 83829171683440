import { useIntl } from 'react-intl';
import { PlannerEvent } from '../types';
import { useFormikContext } from 'formik';
import { PlannerDateInput } from '../../#-components/planner/planner-date-input';
import { Datepicker } from '../../components-2/datepicker';
import {
  DateStepInputsWrapper,
  DateStepLayout
} from '../../#-components/planner/form';
import { RightPanelContent } from '../../components-2/right-panel';
import { FormStepHeader } from './form-step-header';
import { useCallback } from 'react';

export function DateStep(): JSX.Element {
  const intl = useIntl();
  const formik = useFormikContext<PlannerEvent>();

  const { values } = formik;

  const onDatepickerChange = useCallback(
    (start: string, end: string) => {
      formik.setFieldValue('startDate', start);
      formik.setFieldValue('endDate', end);
    },
    [formik]
  );

  return (
    <>
      <FormStepHeader />
      <RightPanelContent noPadding>
        <DateStepLayout>
          <Datepicker
            selectRange="range"
            rangeStart={values.startDate ?? undefined}
            rangeEnd={values.endDate ?? undefined}
            onChange={onDatepickerChange}
          />
          <DateStepInputsWrapper>
            <PlannerDateInput
              id="startDate"
              name="startDate"
              label={intl.formatMessage({ id: 'planner.event.startDate' })}
              value={values.startDate ?? ''}
              onChange={value => formik.setFieldValue('startDate', value)}
            />
            <PlannerDateInput
              id="endDate"
              name="endDate"
              label={intl.formatMessage({ id: 'planner.event.endDate' })}
              value={values.endDate ?? ''}
              onChange={value => formik.setFieldValue('endDate', value)}
            />
          </DateStepInputsWrapper>
        </DateStepLayout>
      </RightPanelContent>
    </>
  );
}
