import { ReportingNavigationLink } from '../reporting/types';
import { RealityLink } from './links/reality-link';
import { PlanLink } from './links/plan-link';
import { AnalyticsLink } from './links/analytics-link';
import { AttendanceLink } from './links/attendance-link';
import { FilesOverviewLink } from './links/files-overview-link';
import { SeasonEvaluationLink } from './links/season-evaluation-link';
import { EvidenceModuleConfiguration } from '../evidence/types';
import { PermissionScope } from '../permissions/types';
import { ModulesStore } from '../modules/mobx/modules-store';
import { ComponentType } from 'react';
import { OkrsLink } from './links/okrs-link';
import { PlannerLink } from './links/planner-link';

interface NavigationLinkProps {
  onClick?(): void;

  className?: string;
}

interface AnalyticsLinkProps extends NavigationLinkProps {
  dashboardName?: string;
}

export interface MainNavigationLink {
  module: keyof ModulesStore;
  Component: ComponentType<NavigationLinkProps>;
  permissionScope: PermissionScope | PermissionScope[] | null;
}

export type EvidenceNavigationLink = EvidenceModuleConfiguration & {
  permissionScope: PermissionScope;
};

export interface AnalyticsNavigationLink {
  module: 'analytics';
  Component: ComponentType<AnalyticsLinkProps>;
  permissionScope: PermissionScope | PermissionScope[] | null;
}

export interface SettingsNavigationLink {
  module: 'settings';
  permissionScope: null;
}

export const LINKS: (MainNavigationLink | AnalyticsNavigationLink)[] = [
  { module: 'reality', Component: RealityLink, permissionScope: 'reality' },
  { module: 'plan', Component: PlanLink, permissionScope: 'plan' },
  {
    module: 'reporting',
    Component: AnalyticsLink,
    permissionScope: ['reporting', 'analytics']
  },
  {
    module: 'attendance',
    Component: AttendanceLink,
    permissionScope: 'attendance'
  },
  {
    module: 'filesOverview',
    Component: FilesOverviewLink,
    permissionScope: 'filesOverview'
  },
  {
    module: 'seasonEvaluation',
    Component: SeasonEvaluationLink,
    permissionScope: 'seasonEvaluation'
  },
  {
    module: 'okr',
    Component: OkrsLink,
    permissionScope: 'okr'
  },
  {
    module: 'planner',
    Component: PlannerLink,
    permissionScope: 'planner'
  }
];

export type HeaderLink =
  | MainNavigationLink
  | EvidenceNavigationLink
  | ReportingNavigationLink
  | SettingsNavigationLink;
