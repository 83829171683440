import { Button, ButtonAppearance } from '@yarmill/components';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Tippy } from '../components/tippy/tippy';
import { useConfig } from '../app/hooks';
import { useCopyService } from '../copy/hooks';
import { useLayer } from '../layer-manager/hooks';
import { ConflictLayerContent } from '../copy/conflict-layer-content';
import { LayerPortal } from '../layer-manager/layer-portal';
import { AsyncState, useAsyncState } from '../utils/use-async-state';
import { ConflictResolution } from '../copy/types';
import { useAttendanceStore } from './attendance-store-context';
import { observer } from 'mobx-react-lite';
import { toastCopyAttendanceSuccess } from '../copy/utils';
import { useCurrentUserStore } from '../users/hooks';
import { CopyConflictErrorResponse } from '../copy/copy-conflict-error-response';
import { ServerErrorResponse } from '../api/server-error-response';
import { toast } from '../components/toast-message';

const StyledCopyAttendanceButton = styled.span`
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 7px;

    span,
    button {
      width: 100%;
    }
  }
`;

export const CopyAttendanceButton = observer(
  function CopyAttendanceButton(): JSX.Element | null {
    const attendanceStore = useAttendanceStore();
    const copyService = useCopyService();
    const intl = useIntl();
    const currentUser = useCurrentUserStore();
    const isAllowedToCopy = currentUser.isAllowedTo('attendance.copy');
    const isCopyEnabled = useConfig('attendanceCopyPlanToReality');
    const conflictLayer = useLayer('alert', { showShim: true });
    const [state, setAsyncState, conflictResponse] =
      useAsyncState<CopyConflictErrorResponse>();
    const disabled =
      attendanceStore.viewType === 'season' || state === AsyncState.pending;

    const submitCopy = async (
      resolution: ConflictResolution
    ): Promise<void> => {
      setAsyncState(AsyncState.pending);
      if (!attendanceStore.group || !attendanceStore.week) {
        return;
      }
      const response = await copyService.copyAttendance(
        attendanceStore.group.id,
        attendanceStore.week,
        resolution
      );
      if (
        response instanceof CopyConflictErrorResponse &&
        !conflictLayer.isOpened
      ) {
        setAsyncState(AsyncState.rejected, response);
        conflictLayer.open();
        return;
      } else if (response instanceof ServerErrorResponse) {
        toast(response.id, 'error');
        setAsyncState(AsyncState.rejected);
      } else if (response) {
        setAsyncState(AsyncState.resolved);
        toastCopyAttendanceSuccess(
          attendanceStore.group.athletes.length,
          resolution,
          conflictResponse?.conflictingIDs.length ?? 0
        );
      } else {
        toast(`toast.error.attendance.copy`, 'error');
        setAsyncState(AsyncState.rejected);
      }
    };

    const onClick = async () => {
      if (
        window.confirm(intl.formatMessage({ id: 'attendance.copy.confirm' }))
      ) {
        await submitCopy('FailOnConflict');
      }
    };

    if (!isCopyEnabled || !isAllowedToCopy) {
      return null;
    }

    return (
      <>
        <StyledCopyAttendanceButton>
          <Tippy
            tooltipContent="attendance.copyButton.disabledTooltip"
            isEnabled={disabled}
          >
            <Button
              appearance={ButtonAppearance.Primary}
              onClick={onClick}
              disabled={disabled}
              wide
            >
              <FormattedMessage id="navbar.attendance.copyButton" />
            </Button>
          </Tippy>
        </StyledCopyAttendanceButton>
        <LayerPortal
          layerHandle={conflictLayer}
          getContent={layer => (
            <ConflictLayerContent
              layer={layer}
              conflictingIds={conflictResponse?.conflictingIDs ?? []}
              conflictResolutions={conflictResponse?.resolutions ?? []}
              submitCopy={submitCopy}
              mode="attendance"
              sourceType="group"
            />
          )}
        />
      </>
    );
  }
);
