import { useRootStore } from '../../app/root-store-context';
import { useIntl } from 'react-intl';
import { ATHLETE_SEARCH_PARAM } from '../../routes/types';

export function useContextValue(): string[] {
  const rootStore = useRootStore();
  const intl = useIntl();
  const historyService = rootStore.historyService;
  const usersStore = rootStore.usersStore;

  const module = intl.formatMessage({
    id: historyService.pathname.includes('plan')
      ? 'header.navigation.plan'
      : 'header.navigation.reality'
  });

  const user = usersStore.getUserById(
    Number(historyService.searchParams.get(ATHLETE_SEARCH_PARAM))
  );

  return [user?.displayName ?? '', module].filter(Boolean);
}
