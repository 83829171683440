import { memo } from 'react';
import { observer } from 'mobx-react-lite';
import { AppContainer, PageContentWrapper } from '../components-2/page-layout';
import { PrimarySidebar } from '../sidebar';
import { OkrsStoreContextProvider, useOkrsStore } from './okrs-store-context';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ObjectivesList } from './objectives-list';
import { ObjectiveDetail } from './objective-detail';
import { ThemeProvider } from '@yarmill/components';
import { KeyboardShortcutsContextProvider } from '../utils/use-keyboard-shortcut';
import { useRootStore } from '../app/root-store-context';
import { useEnsureUrlParameters } from './hooks/use-ensure-url-parameters';
import Helmet from 'react-helmet';
import { useSidebarStore } from '../sidebar/hooks/use-sidebar-store';
import { ObjectivesGroupOverview } from './objectives-group-overview';
import { EmptyPage } from './empty-page';

function InternalOkrsRouteRoot(): JSX.Element | null {
  useEnsureUrlParameters();
  let { path } = useRouteMatch();
  const rootStore = useRootStore();
  const sidebarStore = useSidebarStore();
  const okrsStore = useOkrsStore();
  const showObjectivesList = okrsStore.athleteId;

  return rootStore.isReady ? (
    <AppContainer>
      <Helmet>
        <meta name="theme-color" content="#edf0f5" />
      </Helmet>
      <PrimarySidebar />
      <PageContentWrapper isSidebarVisible={sidebarStore.isVisible}>
        {showObjectivesList && <ObjectivesList />}
        <Switch>
          <Route exact path={path}>
            {okrsStore.athleteId ? <EmptyPage /> : <ObjectivesGroupOverview />}
          </Route>
          <Route path={`${path}/:id`}>
            <ObjectiveDetail />
          </Route>
        </Switch>
      </PageContentWrapper>
    </AppContainer>
  ) : null;
}

const ObserverOkrsRouteRoot = observer(InternalOkrsRouteRoot);

export const OkrsRouteRoot = memo(() => (
  <OkrsStoreContextProvider>
    <ThemeProvider theme="main">
      <KeyboardShortcutsContextProvider>
        <ObserverOkrsRouteRoot />
      </KeyboardShortcutsContextProvider>
    </ThemeProvider>
  </OkrsStoreContextProvider>
));
