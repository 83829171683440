import moment from 'moment';
import { useCallback } from 'react';

import { ROUTE_DATE_FORMAT } from '../diary/utils';
import { trackChangeSeasonClick } from '../google-analytics/utils';
import { sortSeasonStores } from '../seasons/utils';
import { generateUrl } from '../utils/generate-url';
import { WEEK_SEARCH_PARAM } from '../routes/types';
import { useCurrentWeek } from '../utils/use-current-week';
import { useCurrentSeasonByDay, useSeasonsStore } from '../seasons/hooks';
import { useHistory } from '../routes/hooks';
import { observer } from 'mobx-react-lite';
import { SeasonStore } from '../seasons/mobx/season-store';
import {
  DropdownOption,
  DropdownProvider
} from '../components-2/dropdown-provider/dropdown-provider';
import { Text } from '../components-2/text';
import { SeasonId } from '../seasons/types';
import { Icon, IconSize } from '@yarmill/components';
import { Cal } from '@yarmill/icons-2';
import { DropdownButtonWrapper } from '../components-2/dropdown-provider/dropdown-button-wrapper';
import { useIntl } from 'react-intl';
import { Tippy } from '../components/tippy/tippy';

function getSeasonLink(season: SeasonStore, currentWeek: string): string {
  let week = `${season.startYear}${currentWeek.slice(4)}`;
  const newWeek = moment(week);
  const seasonStart = moment(season.startDate);
  const seasonEnd = moment(season.endDate);

  // if we are out of season, use start date of season
  if (newWeek.diff(seasonStart) <= 0 || newWeek.diff(seasonEnd) >= 0) {
    week = seasonStart.format(ROUTE_DATE_FORMAT);
  }

  return week;
}

export const SeasonSelector = observer(function SeasonSelector(): JSX.Element {
  const currentWeek = useCurrentWeek();
  const seasonsStore = useSeasonsStore();
  const seasons = seasonsStore.seasons;
  const currentSeason = useCurrentSeasonByDay(currentWeek);
  const history = useHistory();
  const currentSeasonId = currentSeason?.id;
  const intl = useIntl();

  const setSeason = useCallback(
    (seasonId: SeasonId): void => {
      const season = seasonsStore.getSeasonById(seasonId);
      if (!season) {
        return;
      }
      const newWeek = getSeasonLink(season, currentWeek);
      const link = generateUrl({ [WEEK_SEARCH_PARAM]: newWeek });
      trackChangeSeasonClick(season.label);
      history.push(link);
    },
    [seasonsStore, currentWeek, history]
  );

  const items: DropdownOption<SeasonId>[] = seasons
    .sort(sortSeasonStores)
    .map(season => ({
      label: season.label,
      value: season.id
    }));

  const currentValue = items.find(i => i.value === currentSeasonId);

  return (
    <Tippy tooltipContent="seasonSelector.label" noWrapper>
      <DropdownProvider
        options={items}
        handleSelect={setSeason as any}
        selectedValue={currentValue?.value}
        label={intl.formatMessage({ id: 'navbar.viewSelector.season' })}
      >
        <DropdownButtonWrapper appearance="tangerine">
          <Icon size={IconSize.s16}>
            <Cal />
          </Icon>
          <Text inheritColor bold appearance="button10" monoSpace>
            {currentValue?.label}
          </Text>
        </DropdownButtonWrapper>
      </DropdownProvider>
    </Tippy>
  );
});
