import { Icon, IconSize, styled } from '@yarmill/components';
import { Text } from './text';

interface UpdateStatusProps {
  readonly message: string;
  readonly appearance: 'neutral' | 'red' | 'green';
  readonly icon: JSX.Element;
}

const UpdateStatusLayout = styled(Text)<{
  readonly colorAppearance: UpdateStatusProps['appearance'];
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ colorAppearance, theme }) => theme.color[colorAppearance]};
  column-gap: ${({ theme }) => theme.size.x05};
`;
export function UpdateStatus({
  message,
  appearance,
  icon
}: UpdateStatusProps): JSX.Element {
  return (
    <UpdateStatusLayout appearance="label10strong" colorAppearance={appearance}>
      <Icon size={IconSize.s16}>{icon}</Icon>
      {message}
    </UpdateStatusLayout>
  );
}
