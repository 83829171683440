import {
  Icon,
  IconSize,
  styled,
  ThemeDefinition,
  useTheme
} from '@yarmill/components';
import { Team } from '@yarmill/icons-2';
import { Children } from 'react';
import { Text } from './text';

type Size = ThemeDefinition['size']['x2'] | ThemeDefinition['size']['x3'];
interface SelectedUsersProps {
  readonly avatars: JSX.Element[];
  readonly name?: string;
  readonly noUserText?: string;
  readonly multipleUsersText?: string;
  readonly hideText?: boolean;
  readonly size?: Size;
}

const SelectedUsersLayout = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.size.x05};
  align-items: center;
  white-space: nowrap;
  color: ${({ theme }) => theme.color.sand};
  width: 100%;
`;
const AvatarsList = styled.div<{ readonly size: Size }>`
  display: block;
  height: ${({ size }) => size};
`;
const AvatarWrapper = styled.div<{
  readonly isFirst: boolean;
  readonly size: Size;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  margin-left: ${({ theme, isFirst }) =>
    isFirst ? 0 : `calc(-1 * ${theme.size.x05})`};
  background-color: ${({ theme }) => theme.color.text};
  border: 1px solid ${({ theme }) => theme.color.neutral_8};
`;

const StyledText = styled(Text)`
  padding-left: ${({ theme }) => theme.size.x05};
`;
export function SelectedUsers({
  avatars,
  name,
  noUserText,
  multipleUsersText,
  hideText,
  size: passedSize
}: SelectedUsersProps): JSX.Element {
  const theme = useTheme();
  const size = passedSize ?? theme.size.x3;

  return (
    <SelectedUsersLayout>
      {avatars.length > 0 && (
        <AvatarsList size={size}>
          {Children.map(avatars, (avatar, idx) => (
            <AvatarWrapper key={avatar.key} isFirst={idx === 0} size={size}>
              {avatar}
            </AvatarWrapper>
          ))}
        </AvatarsList>
      )}
      {avatars.length === 0 && (
        <Icon size={IconSize.s16}>
          <Team />
        </Icon>
      )}
      {!hideText && (
        <StyledText
          inheritColor
          as="div"
          ellipsis
          appearance="button10"
          upperCase
        >
          {avatars.length === 0
            ? noUserText
            : avatars.length === 1
            ? name
            : multipleUsersText}
        </StyledText>
      )}
    </SelectedUsersLayout>
  );
}
