import {
  forwardRef,
  HTMLAttributes,
  LabelHTMLAttributes,
  PropsWithChildren
} from 'react';
import { css, styled } from '../theme-provider';

export enum TextTag {
  span = 'span',
  div = 'div',
  label = 'label',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  p = 'p'
}

export enum TextSize {
  s8 = 8,
  s10 = 10,
  s12 = 12,
  s13 = 13,
  s14 = 14,
  s16 = 16,
  s18 = 18,
  s20 = 20,
  s24 = 24,
  s28 = 28,
  s36 = 36,
  inherit = 'inherit'
}

export enum TextAlignment {
  center = 'center',
  left = 'left',
  right = 'right'
}

export enum WhiteSpace {
  preWrap = 'pre-wrap',
  noWrap = 'nowrap'
}

export type TextProps = PropsWithChildren<
  {
    tag?: TextTag;
    size?: TextSize;
    bold?: boolean;
    medium?: boolean;
    light?: boolean;
    sup?: boolean;
    sub?: boolean;
    wordBreak?: string;
    textAlign?: TextAlignment;
    whiteSpace?: WhiteSpace;
    className?: string;
    inheritColor?: boolean;
    monoSpace?: boolean;
    ellipsis?: boolean;
    color?: string;
  } & HTMLAttributes<HTMLElement> &
    LabelHTMLAttributes<HTMLLabelElement>
>;

interface InternalTextProps extends TextProps {
  readonly className?: string;
  readonly inheritColor?: boolean;
}

const StyledTextComponent = styled.span<InternalTextProps>`
  font-family: ${({ monoSpace, theme }) =>
      monoSpace ? theme.text.font.mono : theme.text.font.default},
    'Apple Color Emoji', 'Noto Color Emoji', serif;
  position: ${props => (props.sub || props.sup) && 'relative'};
  top: ${props => (props.sup ? '-1em' : '')};
  bottom: ${props => (props.sub ? '-0.25em' : '')};
  word-break: ${props => (props.wordBreak && 'break-word') || 'inherit'};
  font-weight: ${props =>
    props.bold
      ? 'bold'
      : props.medium
      ? '500'
      : props.light
      ? '300'
      : 'normal'};
  font-size: ${props => props.size}px;
  text-align: ${props => props.textAlign};
  white-space: ${props => props.whiteSpace};
  color: ${({ inheritColor, color }) =>
    inheritColor ? 'inherit' : color ?? '#4a4a4a'};
  margin: 0;
  padding-top: 0;
  line-height: 1.5;
  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const Text = forwardRef<HTMLElement, TextProps>(function Text(
  props,
  ref
) {
  const {
    tag = TextTag.span,
    children,
    bold,
    sub,
    sup,
    textAlign,
    wordBreak,
    size = TextSize.s16,
    whiteSpace,
    className,
    medium,
    inheritColor,
    light,
    monoSpace,
    ellipsis,
    ...htmlAttributes
  } = props;

  return (
    <StyledTextComponent
      ref={ref}
      {...htmlAttributes}
      as={TextTag[tag]}
      className={className}
      bold={bold}
      sub={sub}
      sup={sup}
      tag={tag}
      textAlign={textAlign}
      wordBreak={wordBreak}
      size={size}
      whiteSpace={whiteSpace}
      medium={medium}
      light={light}
      inheritColor={inheritColor}
      monoSpace={monoSpace}
      ellipsis={ellipsis}
    >
      {children}
    </StyledTextComponent>
  );
});

export const StyledFormattedHTMLMessage = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;

    thead th {
      border-bottom: 2px solid #dee2e6;
      vertical-align: bottom;
      padding: 0.3rem;
      text-align: inherit;
    }

    td {
      border-top: 1px solid #dee2e6;
      padding: 0.3rem;
      vertical-align: top;
      text-align: inherit;
    }
  }

  p {
    margin-bottom: 1rem;
    margin-top: 0;
  }

  a {
    color: #4a4a4a;
    text-decoration: none;
    cursor: pointer;

    :hover {
      color: #4a90e2;
    }
  }
`;
