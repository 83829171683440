import { toast } from '../components/toast-message';
import { UserGroupId } from '../groups/types';
import { UserId } from '../users/types';
import {
  ConflictResolution,
  CopyConflictResponse,
  CopyElement,
  CopyFormValues,
  CopyMode
} from './types';
import { CurrentUserStore } from '../users/mobx/current-user-store';
import { DiaryStore } from '../diary/mobx/diary-store';
import { capitalizeFirstLetter } from '@yarmill/components';
import { RootStore } from '../app/mobx/root-store';
import { ServerError } from '../app/app-types';

export function getAllowedWeekCopyModes(
  currentUser: CurrentUserStore,
  groupId: UserGroupId | null,
  userId: UserId | null
): CopyMode[] {
  const modes: CopyMode[] = [];
  const hasWritePermission = currentUser.hasWritePermission(groupId, userId);

  if (userId) {
    modes.push('athleteToOtherAthletes');
    modes.push('athleteToGroups');

    if (hasWritePermission) {
      modes.push('otherWeek');
    }
  } else {
    if (hasWritePermission) {
      modes.push('groupToAthletesInGroup');
    }
    modes.push('groupToOtherGroup');

    if (hasWritePermission) {
      modes.push('otherWeek');
    }
  }

  return modes;
}

export function getAllowedSeasonCopyModes(
  currentUser: CurrentUserStore,
  groupId: UserGroupId | null,
  userId: UserId | null,
  _viewType: 'season' | 'seasonGoals'
): CopyMode[] {
  const modes: CopyMode[] = [];
  const hasWritePermission = currentUser.hasWritePermission(groupId, userId);

  if (userId) {
    modes.push('athleteToOtherAthletes');
    modes.push('athleteToGroups');

    if (hasWritePermission) {
      modes.push('otherSeason');

      // if (viewType === 'season') {
      //   modes.push('importFromReality');
      // }
    }
  } else {
    modes.push('groupToAthletesInGroup');
    modes.push('groupToOtherGroup');

    if (hasWritePermission) {
      modes.push('otherSeason');
    }
  }

  return modes;
}

function getFinalCountAfterResolution(
  count: number,
  resolution: ConflictResolution,
  numberOfConflicts: number
): number {
  if (resolution === 'SkipConflicts') {
    return count - numberOfConflicts;
  }
  return count;
}

export function toastCopyToUsers(
  userIds: number[],
  rootStore: RootStore,
  type: 'plan' | 'goals' = 'plan',
  resolution: ConflictResolution,
  numberOfConflicts: number
): void {
  if (userIds.length === 1) {
    toast(`toast.success.${type}.copy.otherAthlete`, 'success', {
      user: rootStore.usersStore.getUserById(userIds[0])?.displayName || ''
    });
  } else {
    toast(`toast.success.${type}.copy.otherAthletes`, 'success', {
      count: String(
        getFinalCountAfterResolution(
          userIds.length,
          resolution,
          numberOfConflicts
        )
      )
    });
  }
}

export function toastCopyToGroups(
  groupIds: number[],
  rootStore: RootStore,
  diaryType: 'plan' | 'goals' = 'plan',
  resolution: ConflictResolution,
  numberOfConflicts: number
): void {
  if (groupIds.length === 1) {
    toast(`toast.success.${diaryType}.copy.otherGroup`, 'success', {
      group: rootStore.groupsStore.getGroupById(groupIds[0])?.name || ''
    });
  } else {
    toast(`toast.success.${diaryType}.copy.otherGroups`, 'success', {
      count: String(
        getFinalCountAfterResolution(
          groupIds.length,
          resolution,
          numberOfConflicts
        )
      )
    });
  }
}

export function toastCopySuccess(
  copyMode: CopyMode,
  values: Record<string, unknown>,
  rootStore: RootStore,
  type: 'plan' | 'goals' = 'plan',
  resolution: ConflictResolution,
  numberOfConflicts: number
): void {
  switch (copyMode) {
    case 'athleteToGroups':
    case 'groupToOtherGroup':
      toastCopyToGroups(
        (type === 'goals'
          ? values.DestinationGroupsIds
          : values.TargetUserGroupIds) as number[],
        rootStore,
        type,
        resolution,
        numberOfConflicts
      );
      break;
    case 'otherSeason':
    case 'otherWeek':
      toast(`toast.success.${type}.copy.otherPeriod`, 'success');
      break;
    case 'importFromReality':
      toast(`toast.success.${type}.copy.importFromReality`, 'success');
      break;
    default:
      toastCopyToUsers(
        (type === 'goals'
          ? values.DestinationUsersIds
          : values.TargetUserIds) as number[],
        rootStore,
        type,
        resolution,
        numberOfConflicts
      );
  }
}

export function toastCopyAttendanceSuccess(
  athletesCount: number,
  resolution: ConflictResolution,
  numberOfConflicts: number
): void {
  toast(`toast.success.attendance.copy`, 'success', {
    count: String(
      getFinalCountAfterResolution(athletesCount, resolution, numberOfConflicts)
    )
  });
}

export function getElementsForCopyMode(
  mode: CopyMode,
  diaryStore: DiaryStore
): CopyElement[] {
  const seasonSections =
    diaryStore.currentSeason?.seasonViewType === 'month'
      ? 'sourceMonths'
      : 'sourceCycles';

  if (diaryStore.viewType === 'goals') {
    switch (mode) {
      // Plan modes
      case 'athleteToOtherAthletes':
        return ['sourceWeek', 'athletes'];
      case 'athleteToGroups':
        return ['sourceWeek', 'groups'];
      case 'groupToAthletesInGroup':
        return ['sourceWeek', 'athletesInGroup'];
      case 'groupToOtherGroup':
        return ['sourceWeek', 'groups'];
      case 'otherWeek':
        return ['sourceWeek', 'targetWeek'];
      case 'importFromReality':
        throw new Error('Invalid combination of parameters');
    }
  }

  if (
    diaryStore.viewType === 'season' ||
    diaryStore.viewType === 'seasonGoals'
  ) {
    switch (mode) {
      case 'athleteToOtherAthletes':
        return [seasonSections, 'seasonId', 'athletes'];
      case 'athleteToGroups':
        return [seasonSections, 'seasonId', 'groups'];
      case 'groupToAthletesInGroup':
        return [seasonSections, 'seasonId', 'athletesInGroup'];
      case 'groupToOtherGroup':
        return [seasonSections, 'seasonId', 'groups'];
      case 'otherSeason':
        return [seasonSections, 'sourceSeason', 'targetSeason'];
      case 'importFromReality':
        return [seasonSections, 'sourceSeasonSelector', 'currentSeason'];
    }
  }

  switch (mode) {
    // week modes
    case 'athleteToOtherAthletes':
      return ['sourceDay', 'athletes', 'includeWeekGoal'];
    case 'athleteToGroups':
      return ['sourceDay', 'groups', 'includeWeekGoal'];
    case 'groupToAthletesInGroup':
      return ['sourceDay', 'athletesInGroup', 'includeWeekGoal'];
    case 'groupToOtherGroup':
      return ['sourceDay', 'groups', 'includeWeekGoal'];
    case 'otherWeek':
      return ['sourceDay', 'targetWeek', 'includeWeekGoal'];
    case 'otherSeason':
    case 'importFromReality':
      throw new Error('Invalid combination of parameters');
  }
}

const copyElementApiMap: { [key in CopyElement]: string } = {
  sourceDay: 'Days',
  athletes: 'TargetUserIds',
  groups: 'TargetUserGroupIds',
  targetWeek: 'TargetWeek',
  athletesInGroup: 'TargetUserIds',
  targetSeason: 'TargetSeasonId',
  seasonId: 'SeasonId',
  sourceSeason: 'SourceSeasonId',
  sourceCycles: 'Cycles',
  sourceMonths: 'Months',
  sourceWeek: 'SourceWeekStartDate',
  currentSeason: 'TargetSeasonId',
  sourceSeasonSelector: 'SourceSeasonId',
  includeWeekGoal: 'IncludeWeekGoal'
};

const copyElementGoalsApiMap: { [key in CopyElement]: string } = {
  sourceDay: 'Days',
  athletes: 'DestinationUsersIds',
  groups: 'DestinationGroupsIds',
  targetWeek: 'DestinationWeekStartDate',
  athletesInGroup: 'DestinationUsersIds',
  targetSeason: 'TargetSeasonId',
  seasonId: 'SeasonId',
  sourceSeason: 'SourceSeasonId',
  sourceCycles: 'Cycles',
  sourceMonths: 'Months',
  sourceWeek: 'SourceWeekStartDate',
  currentSeason: 'TargetSeasonId',
  sourceSeasonSelector: 'SourceSeasonId',
  includeWeekGoal: 'IncludeWeekGoal'
};

export function mapCopyElementToApiParam(
  element: CopyElement,
  diaryStore: DiaryStore
): string {
  return diaryStore.viewType === 'goals'
    ? copyElementGoalsApiMap[element]
    : copyElementApiMap[element];
}

export function isValidCopyModeParams(
  copyMode: CopyMode,
  formValues: CopyFormValues,
  diaryStore: DiaryStore
): boolean {
  const elements = getElementsForCopyMode(copyMode, diaryStore);

  return elements.every(element => {
    const value = formValues[element];

    if (element === 'includeWeekGoal') {
      return true;
    }

    if (!value) {
      return false;
    }

    if (Array.isArray(value) && value.length === 0) {
      return false;
    }

    return true;
  });
}

export function appendSourceId(
  formValues: CopyFormValues,
  diaryStore: DiaryStore
): Record<string, unknown> {
  let sourceId = diaryStore.groupId;

  if (diaryStore.athleteId) {
    sourceId = diaryStore.athleteId;
  }

  if (diaryStore.viewType === 'goals') {
    return { ...formValues, SourceId: sourceId };
  } else if (diaryStore.athleteId) {
    return { ...formValues, SourceUserId: sourceId };
  } else {
    return { ...formValues, SourceUserGroupId: sourceId };
  }
}

export function appendTargetId(
  formValues: CopyFormValues,
  diaryStore: DiaryStore,
  mode: CopyMode
): Record<string, unknown> {
  if (diaryStore.viewType === 'goals' && mode === 'otherWeek') {
    if (diaryStore.athleteId) {
      return { ...formValues, DestinationUsersIds: [diaryStore.athleteId] };
    } else {
      return { ...formValues, DestinationGroupsIds: [diaryStore.groupId] };
    }
  }

  return formValues;
}

export function isConflictResponse(
  x: boolean | CopyConflictResponse | ServerError
): x is CopyConflictResponse {
  return typeof x !== 'boolean' && Array.isArray(x);
}

export function mapCopyModeElementsToApiParams(
  formValues: CopyFormValues,
  elements: CopyElement[],
  diaryStore: DiaryStore
) {
  return Object.fromEntries(
    (Object.entries(formValues) as [CopyElement, unknown][])
      // Filter out values that are not relevant for copy mode
      .filter(([elementType]) => {
        return elements.includes(elementType);
      })
      // Map Element Types to Api Params
      .map(([elementType, value]) => [
        mapCopyElementToApiParam(elementType, diaryStore),
        value
      ])
  );
}

export function mapCopyTypeToApiParams(diaryStore: DiaryStore): {
  diaryType: 'Plan';
  type: 'Week' | 'Goals' | 'Season' | 'Cycle' | 'SeasonGoals';
} {
  const seasonView = diaryStore.currentSeason?.seasonViewType;
  const type =
    diaryStore.viewType === 'season'
      ? seasonView === 'month'
        ? 'Season'
        : 'Cycle'
      : (capitalizeFirstLetter(diaryStore.viewType) as
          | 'Week'
          | 'Goals'
          | 'SeasonGoals');

  const diaryType = 'Plan';

  return { diaryType, type };
}
