import { AxiosPromise, CancelTokenSource } from 'axios';
import { ActivityData } from '../../activities/types';
import { axios } from '../../api/axios';
import { DiaryType } from '../types';

export interface GetSeasonPlanForAthleteRequestParams {
  userId: number;
  seasonId: number;
  referenceTime?: string;
}

export function getSeasonDataForAthlete(
  diaryType: DiaryType,
  params: GetSeasonPlanForAthleteRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<ActivityData[]> {
  return axios.post(`api/${diaryType}V2/Season/Athlete`, params, {
    cancelToken: cancelToken?.token
  });
}
