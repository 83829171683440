import { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { Video as IVideo } from '../videoselector/types';
import { Sentry } from '../sentry/set-up-sentry';

import ReactPlayer from 'react-player/file';

export interface VideoProps {
  src: IVideo;
  fullWidth?: boolean;
}

const StyledVideo = styled.div<{ fullWidth?: boolean }>`
  overflow: hidden;
  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      max-width: 60vw;
      @media (max-width: 600px) {
        width: 90vw;
        max-width: 90vw;
      }

      video {
        max-height: calc(100vh - 150px); // same as for alert layer
      }
    `}

  video {
    display: block;
  }
`;

export function Video(props: VideoProps): JSX.Element {
  const { src, fullWidth } = props;

  const onError = useCallback(() => {
    Sentry?.captureEvent({
      message: `Unable to load video ${src.FileName}`,
      extra: JSON.parse(JSON.stringify(src))
    });
  }, [src]);

  return (
    <StyledVideo fullWidth={fullWidth}>
      <ReactPlayer
        url={src.AzureLink}
        controls
        width="100%"
        height="auto"
        onError={onError}
      />
    </StyledVideo>
  );
}
