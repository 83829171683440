import { UserStore } from '../users/mobx/user-store';
import { IconSize, styled } from '@yarmill/components';
import { Coach } from '@yarmill/icons-2';
import { CoachSymbol, RoleAvatarIcon } from './sidebar-navigation';
import { useAvatar } from '../profile/hooks';
import { observer } from 'mobx-react-lite';
import { CurrentUserStore } from '../users/mobx/current-user-store';

const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
`;

interface RoleAvatarProps {
  readonly user: UserStore | CurrentUserStore;
  readonly size?: IconSize.s16 | IconSize.s24 | IconSize.s32;
}
export const RoleAvatar = observer(function RoleAvatar({
  user,
  size = IconSize.s16
}: RoleAvatarProps): JSX.Element {
  const avatar = useAvatar(user.avatar);

  return (
    <RoleAvatarIcon size={size} isCoach={!user.isAthlete}>
      <>
        <AvatarImg src={avatar} alt={user.displayName} />
        {!user.isAthlete && (
          <CoachSymbol
            size={
              size === IconSize.s16
                ? IconSize.s8
                : size === IconSize.s24
                ? IconSize.s12
                : IconSize.s16
            }
          >
            <Coach />
          </CoachSymbol>
        )}
      </>
    </RoleAvatarIcon>
  );
});
