import { copyUserPlanToReality } from './copy-user-plan-to-reality';
import { copyGoalsFromUser } from './copy-goals-from-user';
import { copyGoalsFromGroup } from './copy-goals-from-group';
import { copy } from './copy';

export const api = {
  copy,
  copyGoalsFromGroup,
  copyGoalsFromUser,
  copyUserPlanToReality
};
