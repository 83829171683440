import { TrainingDayStore } from './training-day-store';
import { RootStore } from '../../app/mobx/root-store';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { WeekGoalAttributeStore } from './week-goal-attribute-store';
import { action, observable, makeObservable } from 'mobx';
import {
  TrainingDayData,
  TextAreaBasicData,
  TrainingDayExtraActionType,
  weekSummary
} from '../types';
import { DiaryDataUniqueId } from '../../diary/types';

export class WeekSummaryStore extends TrainingDayStore {
  public readonly weekGoal: WeekGoalAttributeStore;

  @observable
  private _isPinnedToTop: boolean = !!window.localStorage.getItem(
    weekSummary.PINNED
  );

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    dataId: DiaryDataUniqueId
  ) {
    super(rootStore, diaryStore, 0, [], dataId);
    makeObservable(this);
    this.weekGoal = this.createWeekGoalAttribute();
  }

  @action
  public pinToTop = (): void => {
    window.localStorage.setItem(weekSummary.PINNED, 'true');
    this._isPinnedToTop = true;
  };

  @action
  public unpinFromTop = (): void => {
    window.localStorage.removeItem(weekSummary.PINNED);
    this._isPinnedToTop = false;
  };

  public get isPinned(): boolean {
    return this._isPinnedToTop;
  }

  public get extraActions(): TrainingDayExtraActionType[] {
    const actions: TrainingDayExtraActionType[] = ['pin-to-top'];

    return actions;
  }

  public get showExtraDropdown(): boolean {
    return true;
  }

  clear() {
    this.weekGoal.clear();
  }

  @action
  public setWeekGoalValue(value: TrainingDayData): void {
    this.weekGoal.setApiValue(value);
  }

  @action
  public setWeekGoalPlaceholder(data: TextAreaBasicData): void {
    this.weekGoal.setPlaceholderText(data);
  }

  private createWeekGoalAttribute(): WeekGoalAttributeStore {
    const weekGoalAttribute = this.diaryStore.goalsAttributes?.week;

    return new WeekGoalAttributeStore(
      this.rootStore,
      this.diaryStore,
      this,
      weekGoalAttribute,
      this.dataId
    );
  }

  public enableApiSynchronization(): void {
    this.weekGoal.enableApiSynchronization();
  }
}
