import { observer } from 'mobx-react-lite';
import { useSeasonEvaluationStore } from './season-evaluation-store-context';
import { CsvTable } from '../csv-table/csv-table';
import {
  ContentBox,
  FixedContentBox,
  PageScrollContainer,
  ResponsiveTableWrapper,
  Text,
  TextSize
} from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Fragment } from 'react';

const StyledFixedContentBox = styled(FixedContentBox)`
  padding-left: 0;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

const StyledContentBox = styled(ContentBox)`
  padding-left: 0;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding-left: 24px;

  @media (min-width: 768px) {
    padding-left: 26px;
  }
`;

const StyledResponsiveTableWrapper = styled(ResponsiveTableWrapper)`
  padding-left: 24px;

  @media (min-width: 768px) {
    padding-left: 26px;
  }
`;

export const SeasonOverSeasonEvaluation = observer(
  function SeasonOverSeasonEvaluation(): JSX.Element {
    const seasonEvaluationStore = useSeasonEvaluationStore();
    const seasonOverSeasonStore =
      seasonEvaluationStore.seasonOverSeasonEvaluationStore;
    const tables = seasonOverSeasonStore.definition;
    const store = useSeasonEvaluationStore().seasonOverSeasonEvaluationStore;

    if (!seasonEvaluationStore.athleteId) {
      return (
        <ContentBox>
          <Text size={TextSize.s14}>
            <FormattedMessage id="seasonEvaluation.sos.noAthlete" />
          </Text>
        </ContentBox>
      );
    }

    const ScrollableContentBox =
      tables.length > 1 ? StyledContentBox : StyledFixedContentBox;
    const ScrollableTableWrapper =
      tables.length > 1 ? TableWrapper : StyledResponsiveTableWrapper;
    const PageContentWrapper =
      tables.length > 1 ? PageScrollContainer : Fragment;

    return (
      <PageContentWrapper>
        {tables.map((table, idx) => (
          <ScrollableContentBox key={idx}>
            <ScrollableTableWrapper>
              <CsvTable
                table={table}
                data={store.getData(idx)}
                expandable={store.getExpandable(idx)}
              />
            </ScrollableTableWrapper>
          </ScrollableContentBox>
        ))}
      </PageContentWrapper>
    );
  }
);
