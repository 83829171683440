import { RootStore } from '../../app/mobx/root-store';
import {
  computed,
  IObservableArray,
  IReactionDisposer,
  makeObservable,
  observable,
  ObservableMap,
  reaction,
  runInAction,
  toJS
} from 'mobx';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from '../../routes/types';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import { FileOverview, FilesOverviewSummary } from '../types';
import { getFiles } from '../api/get-files';
import { AttachmentListStore } from '../../attributes/mobx/attachment-list-store';
import { AttachmentStore } from '../../attributes/mobx/attachment-store';
import { FileType } from '../../fileupload/types';
import { TableFiltersStore } from '../../evidence/mobx/table-filters-store';

export class FilesOverviewStore implements AttachmentListStore {
  private readonly rootStore: RootStore;
  public readonly filtersStore: TableFiltersStore;

  @observable
  public athleteId: UserId | null = null;

  @observable
  public groupId: UserGroupId | null = null;

  @observable
  private filesOverview: IObservableArray<AttachmentStore> = observable.array();

  @observable
  private request: RequestStore<FileOverview[]> | null = null;

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  private reactions: IReactionDisposer[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.filtersStore = new TableFiltersStore();
    this.registerReactions();
  }

  public removeAttachment(attachment: AttachmentStore): void {
    this.filesOverview.remove(attachment);
  }

  @computed
  public get summary(): FilesOverviewSummary {
    let images = 0;
    let videos = 0;
    let others = 0;

    this.filesOverview.forEach(file => {
      switch (file.attachment.FileType) {
        case FileType.picture: {
          images++;
          break;
        }
        case FileType.video: {
          videos++;
          break;
        }
        default:
          others++;
      }
    });

    return { images, videos, others };
  }

  public isAllowedToDeleteFile(attachment: FileOverview): boolean {
    const currentUser = this.rootStore.currentUserStore;
    const attachmentType = attachment.Type;

    return (
      currentUser.hasWritePermission(this.groupId, this.athleteId) &&
      ((currentUser.isAllowedTo('reality.week.attributes.write') &&
        attachmentType === 'R') ||
        (currentUser.isAllowedTo('plan.week.attributes.write') &&
          attachmentType === 'P'))
    );
  }

  public disposeReactions(): void {
    this.reactions.forEach(dispose => dispose());
  }

  public get files(): AttachmentStore[] {
    return this.filesOverview;
  }

  public get status(): AsyncStatus {
    return this._status;
  }

  private async loadFiles(): Promise<void> {
    if (this.request) {
      this.request.cancel();
      this.rootStore.requestsStore.removeRequest(this.request);
    }

    const athleteId = this.athleteId;
    const groupId = this.groupId;
    const filters = toJS(this.filtersStore.filters as ObservableMap);
    const language = this.rootStore.intlStore.locale;
    const sortConfig = this.filtersStore.sortConfig
      ? { ...this.filtersStore.sortConfig, language }
      : null;

    if (!athleteId && !groupId) {
      return;
    }

    this._status = AsyncStatus.pending;

    this.request = this.rootStore.requestsStore.createRequest(cancelToken =>
      getFiles(
        {
          userGroupId: athleteId ? undefined : groupId ?? undefined,
          userId: athleteId || undefined,
          filters,
          sortConfig
        },
        cancelToken
      )
    );

    const response = await this.request.getResponse();

    if (response) {
      runInAction(() => {
        this.filesOverview.clear();
        this.filesOverview.push(
          ...response.map(
            file => new AttachmentStore(this.rootStore, this, file)
          )
        );
        this._status = AsyncStatus.resolved;
      });
    } else {
      this._status = AsyncStatus.rejected;
    }
  }

  private registerReactions(): void {
    const observeGroupId = reaction(
      () => this.rootStore.historyService.searchParams.get(GROUP_SEARCH_PARAM),
      id => {
        this.groupId = id !== undefined ? Number(id) : null;
        this.filesOverview.clear();
      },
      {
        fireImmediately: true
      }
    );

    const observerAthleteId = reaction(
      () =>
        this.rootStore.historyService.searchParams.get(ATHLETE_SEARCH_PARAM),
      id => {
        this.athleteId = id !== undefined ? Number(id) : null;
        this.filesOverview.clear();
      },
      {
        fireImmediately: true
      }
    );

    const dataLoader = reaction(
      () => ({
        athleteId: this.athleteId,
        groupId: this.groupId,
        filters: (this.filtersStore.filters as ObservableMap).toJSON(),
        sortConfig: this.filtersStore.sortConfig
      }),
      () => this.loadFiles(),
      {
        fireImmediately: true
      }
    );

    this.reactions.push(observerAthleteId, observeGroupId, dataLoader);
  }
}
