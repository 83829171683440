import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';

export function sendMissingTranslations(
  language: string | null,
  translations: { code: string; url: string }[]
): AxiosPromise<boolean> {
  return axios.post(`api/log/MissingTranslations`, {
    language,
    translations
  });
}
