import { createRoot } from 'react-dom/client';
import { App } from './app/app';
import { IntlProvider } from './intl/intl-provider';
import packageJson from '../package.json';

import './intl/register-polyfill';
import { showNewVersionAnnouncement } from './announcement/new-version';
import { registerVersionWorker } from './registerVersionWorker';
import { RootStore } from './app/mobx/root-store';
import { RootStoreContext } from './app/root-store-context';
import { disabledInstances, instance } from './api/api-mapping';
import { DisabledInstance } from './disabled-instance';
import { configure } from 'mobx';
import { Sentry, setUpSentry } from './sentry/set-up-sentry';
import Axios from 'axios';
import { FatalError } from './fatal-error/fatal-error';
import { ErrorBoundary } from '@yarmill/components';
import { ErrorInfo } from 'react';
import { debugValue } from './utils/debug-value';

configure({
  enforceActions: 'never'
});

const target = document.getElementById('yarmill-diary-app');

// Unsupported browser
if (!target) {
  throw new Error('Unsupported browser');
}

const root = createRoot(target);
let rootStore: RootStore;

const FatalErrorComponent = () => (
  <RootStoreContext.Provider value={rootStore}>
    <IntlProvider>
      <FatalError />
    </IntlProvider>
  </RootStoreContext.Provider>
);
const renderFatalError = () => root.render(<FatalErrorComponent />);

function logToSentry(e: Error, errorInfo?: ErrorInfo): void {
  // Do not log unauthorized
  if (Axios.isAxiosError(e) && e.response?.status !== 401) {
    Sentry?.captureException(e, {
      extra: {
        errorInfo
      }
    });
  }
}

const render = (AppComponent: typeof App) =>
  root.render(
    <ErrorBoundary fallback={<FatalErrorComponent />} onCatch={logToSentry}>
      <RootStoreContext.Provider value={rootStore}>
        <IntlProvider>
          <AppComponent history={rootStore.historyService.history} />
        </IntlProvider>
      </RootStoreContext.Provider>
    </ErrorBoundary>
  );

try {
  rootStore = new RootStore();
  debugValue(rootStore, 'rootStore');

  registerVersionWorker(packageJson.version, showNewVersionAnnouncement);

  setUpSentry(rootStore);

  const renderDisabledInstance = () => {
    root.render(<DisabledInstance />);
  };

  if (disabledInstances.includes(instance)) {
    renderDisabledInstance();
  } else {
    render(App);
  }
} catch (e: any) {
  logToSentry(e);
  renderFatalError();
}

if (module.hot) {
  module.hot.accept('./app/app', () => {
    const NextApp = require('./app/app').App;
    render(NextApp);
  });
}
