import moment from 'moment';
import * as React from 'react';

import {
  OnDatesChangeProps,
  useDatepicker,
  weekdayLabelFormat as weekdayLabelFormatFn
} from '@datepicker-react/hooks';

import styled from 'styled-components';

import { Button, ButtonAppearance } from '../button';
import { ContentBox } from '../content-box';
import { capitalizeFirstLetter, SelectValueProps } from '../helpers';
import { Icon, IconSize } from '../icon';
import { SelectBox } from '../select-box';

import { Month } from './month';
import { getYears } from './utils';
import { ExternalIcon } from '../external-icon';

export { ISO_DATE_FORMAT } from './utils';

interface DatepickerContextData {
  focusedDate: Date | null;
  isDateFocused(date: Date): boolean;
  isDateSelected(date: Date): boolean;
  isDateHovered(date: Date): boolean;
  onDateFocus(date: Date): void;
  onDateHover(date: Date | null): void;
  onDateSelect(date: Date): void;
  isDateBlocked(date: Date): boolean;
  isFirstOrLastSelectedDate(date: Date): boolean;
}

export const DatepickerContext = React.createContext<DatepickerContextData>({
  focusedDate: null,
  isDateFocused: () => false,
  isDateSelected: () => false,
  isDateHovered: () => false,
  isDateBlocked: () => false,
  isFirstOrLastSelectedDate: () => false,
  onDateFocus: () => undefined,
  onDateHover: () => undefined,
  onDateSelect: () => undefined
});

export type SelectRange = 'day' | 'week' | 'range';

export interface DatepickerProps {
  value?: string;
  format?: string;
  selectRange?: SelectRange;
  todayButtonLabel?: JSX.Element;
  minBookingDate?: Date;
  maxBookingDate?: Date;
  rangeStart?: string;
  rangeEnd?: string;
  onChange?(value: string): void;
  handleChangeMonth?(data: Date, reset: () => void): void;
}

const START_DATE = 'startDate';

const StyledDatepickerWrapper = styled.div`
  width: 320px;
  max-width: 100vw;
  position: relative;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
`;

const StyledSelectContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const StyledNavigationWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 10px;
  width: 100%;
`;

const StyledContentBox = styled(ContentBox)`
  display: grid;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.15);
  padding: 16px;
  justify-items: center;
`;

const StyledTodayButtonWrapper = styled.div`
  text-align: center;
  padding-top: 10px;
`;

export function Datepicker(props: DatepickerProps): JSX.Element {
  const datepickerWrapperRef = React.useRef<HTMLDivElement>(null);

  const {
    value,
    format = 'YYYY-MM-DD',
    onChange,
    minBookingDate = moment('1900-01-01').toDate(),
    todayButtonLabel,
    maxBookingDate = moment().add(50, 'years').toDate(),
    selectRange = 'day',
    rangeStart,
    rangeEnd
  } = props;

  const maybeValue = value ? moment(value, format) : moment();
  const validValue = maybeValue.isValid() ? maybeValue : moment();
  const currentDate = validValue.toDate();
  const rangeStartValue = rangeStart ? moment(rangeStart, format) : undefined;
  const rangeEndValue = rangeEnd ? moment(rangeEnd, format) : undefined;

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
    goToDate
  } = useDatepicker({
    startDate: rangeStartValue?.toDate() ?? currentDate,
    endDate: rangeEndValue?.toDate() ?? null,
    numberOfMonths: 1,
    minBookingDate,
    focusedInput: 'startDate',
    maxBookingDate,
    onDatesChange: handleDataChange
  });
  const months = moment.months('format').map((label, value) => ({
    label: capitalizeFirstLetter(label),
    value: value + 1
  }));
  const years = getYears(minBookingDate, maxBookingDate).map(year => ({
    label: String(year),
    value: year
  }));

  const disableTodayButton = isDateBlocked(new Date());

  function handleDataChange(data: OnDatesChangeProps): void {
    const { startDate } = data;
    const newDate = startDate ? moment(startDate).format(format) : '';
    onChange?.(newDate);
  }

  function selectActualWeek(): void {
    const todayDate = moment().toDate();

    handleDataChange({
      focusedInput: START_DATE,
      startDate: todayDate,
      endDate: null
    });
  }

  function getMonthValue(): SelectValueProps {
    const activeMonth = activeMonths[0];
    return months[activeMonth.month];
  }

  function getYearValue(): SelectValueProps {
    const activeMonth = activeMonths[0];
    const firstYear = years[0].value;
    const activeYear = activeMonth.year;
    const index = activeYear - firstYear;

    return years[index];
  }

  function handleMonthChange(selected: SelectValueProps): void {
    const activeMonth = activeMonths[0];
    const month = (selected.value as number) - 1;
    const newDate = new Date(activeMonth.date.getTime());
    newDate.setMonth(month);
    goToDate(newDate);
  }

  function handleYearChange(selected: SelectValueProps): void {
    const activeMonth = activeMonths[0];
    const newDate = new Date(activeMonth.date.getTime());
    newDate.setFullYear(selected.value as number);
    goToDate(newDate);
  }

  function isButtonDisabled(button: 'prev' | 'next'): boolean {
    const activeMonth = activeMonths[0];

    if (button === 'prev') {
      return (
        activeMonth.year === minBookingDate.getFullYear() &&
        activeMonth.month === minBookingDate.getMonth()
      );
    } else {
      return (
        activeMonth.year === maxBookingDate.getFullYear() &&
        activeMonth.month === maxBookingDate.getMonth()
      );
    }
  }

  function weekdayLabelFormat(date: Date): string {
    return moment(date).toDate().toLocaleString(moment.locale(), {
      weekday: 'short'
    });
  }

  return (
    <StyledDatepickerWrapper ref={datepickerWrapperRef} data-cy="datepicker">
      <StyledContentBox>
        <DatepickerContext.Provider
          value={{
            focusedDate,
            isDateFocused,
            isDateSelected,
            isDateHovered,
            isDateBlocked,
            isFirstOrLastSelectedDate,
            onDateSelect,
            onDateFocus,
            onDateHover
          }}
        >
          <StyledNavigationWrapper data-cy="datepicker-navigation">
            <Button
              square
              noShadow
              type="button"
              onClick={goToPreviousMonths}
              appearance={ButtonAppearance.Navigation}
              disabled={isButtonDisabled('prev')}
              data-cy="previous-month"
            >
              <Icon size={IconSize.s14}>
                <ExternalIcon name="ArrowNarrowLeft" />
              </Icon>
            </Button>
            <StyledSelectContainer>
              <SelectBox
                noShadow
                id="datepicker-month"
                value={getMonthValue()}
                options={months}
                isSearchable={false}
                onChange={handleMonthChange}
                noSeparator
                longList
                noExtraLabel
                disablePortal
                noLabel
                maxMenuHeight={230}
              />
              <SelectBox
                noShadow
                id="datepicker-year"
                isSearchable={false}
                value={getYearValue()}
                options={years}
                onChange={handleYearChange}
                noSeparator
                longList
                noExtraLabel
                disablePortal
                noLabel
                maxMenuHeight={230}
              />
            </StyledSelectContainer>
            <Button
              type="button"
              square
              noShadow
              onClick={goToNextMonths}
              appearance={ButtonAppearance.Navigation}
              disabled={isButtonDisabled('next')}
              data-cy="next-month"
            >
              <Icon size={IconSize.s14}>
                <ExternalIcon name="ArrowNarrowRight" />
              </Icon>
            </Button>
          </StyledNavigationWrapper>
          {activeMonths.map(month => (
            <Month
              key={`${month.year}-${month.month}`}
              year={month.year}
              month={month.month}
              selectMode={selectRange}
              selectedDay={currentDate}
              firstDayOfWeek={firstDayOfWeek}
              weekdayLabelFormat={weekdayLabelFormat || weekdayLabelFormatFn}
              rangeStart={rangeStartValue?.toDate()}
              rangeEnd={rangeEndValue?.toDate()}
            />
          ))}
          {todayButtonLabel && (
            <StyledTodayButtonWrapper>
              <Button
                noShadow
                onClick={disableTodayButton ? undefined : selectActualWeek}
                appearance={ButtonAppearance.Navigation}
                disabled={disableTodayButton}
              >
                {todayButtonLabel}
              </Button>
            </StyledTodayButtonWrapper>
          )}
        </DatepickerContext.Provider>
      </StyledContentBox>
    </StyledDatepickerWrapper>
  );
}
