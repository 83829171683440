import { ReactElement, useCallback } from 'react';
import { useWindowSize } from '../utils/use-window-size';
import {
  ContentWrapper,
  MainPageContentContainer
} from '../components-2/page-layout';
import { Header } from './header';
import { IconSize, ThemeProvider } from '@yarmill/components';
import { useOkrsStore } from './okrs-store-context';
import { RightPanelTitle } from '../components-2/right-panel';
import { SideMenuToggle } from './buttons/side-menu-toggle';
import {
  AthleteLink,
  OverviewTableAthleteWrapper,
  OverviewTableColumnLabel,
  OverviewTableLayout,
  OverviewTableRow,
  OverviewTableValueContainer
} from './components/overview-table';
import { FormattedMessage } from 'react-intl';
import { StatusIcon } from '../components-2/status';
import { RoleAvatar } from '../components-2/role-avatar';
import { UserStore } from '../users/mobx/user-store';
import { Location } from 'history';
import { ATHLETE_SEARCH_PARAM } from '../routes/types';
import { observer } from 'mobx-react-lite';

interface AthleteProps {
  readonly user?: UserStore;
}

function Athlete({ user }: AthleteProps) {
  const userLink = useCallback(
    (location: Location) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(ATHLETE_SEARCH_PARAM, String(user?.id));
      return { ...location, search: searchParams.toString() };
    },
    [user]
  );
  return (
    <AthleteLink to={userLink}>
      <OverviewTableAthleteWrapper appearance="text12strong">
        {user && <RoleAvatar user={user} size={IconSize.s32} />}
        {user?.displayName}
      </OverviewTableAthleteWrapper>
    </AthleteLink>
  );
}

export const ObjectivesGroupOverview = observer(
  function ObjectivesGroupOverview(): ReactElement {
    const { width: windowWidth = 0 } = useWindowSize();
    const okrsStore = useOkrsStore();

    return (
      <ThemeProvider theme="main" dark>
        <MainPageContentContainer windowWidth={windowWidth}>
          <Header
            dark
            left={<SideMenuToggle />}
            center={<RightPanelTitle>{okrsStore.group?.name}</RightPanelTitle>}
          />
          <ContentWrapper>
            <OverviewTableLayout>
              <div />
              <OverviewTableColumnLabel appearance="button10" upperCase>
                <FormattedMessage id="okrs.overview.goals" />
              </OverviewTableColumnLabel>
              <OverviewTableColumnLabel appearance="button10" upperCase>
                <FormattedMessage id="okrs.overview.keyResults" />
              </OverviewTableColumnLabel>
              {okrsStore.group?.athletes.map(user => {
                const objectives =
                  okrsStore.objectivesPerUser.get(user.id) ?? [];

                return (
                  <OverviewTableRow key={user.id}>
                    <Athlete user={user} />
                    <OverviewTableValueContainer>
                      {objectives.map(o => (
                        <StatusIcon
                          key={o.id}
                          status={o.status ?? 'not-started'}
                          variant="x-large"
                        />
                      ))}
                    </OverviewTableValueContainer>
                    <OverviewTableValueContainer withGap>
                      {objectives.map(o => (
                        <OverviewTableValueContainer fixedHeight>
                          {o.keyResults.map(k => (
                            <StatusIcon
                              key={k.id}
                              status={k.status ?? 'not-started'}
                              variant="large"
                            />
                          ))}
                        </OverviewTableValueContainer>
                      ))}
                    </OverviewTableValueContainer>
                  </OverviewTableRow>
                );
              })}
            </OverviewTableLayout>
          </ContentWrapper>
          <Header dark position="footer" />
        </MainPageContentContainer>
      </ThemeProvider>
    );
  }
);
