import {
  useLocation as useRouterLocation,
  useHistory as useRouterHistory
} from 'react-router-dom';
import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from './types';
import { useEffect, useMemo } from 'react';
import {
  Action,
  Path,
  Location as HLocation,
  TransitionPromptHook,
  UnregisterCallback
} from 'history';

export interface CommonUrlParams {
  athlete: number | null;
  group: number | null;
}

export function useCommonUrlParams(): CommonUrlParams {
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const group = params.get(GROUP_SEARCH_PARAM);
  const athlete = params.get(ATHLETE_SEARCH_PARAM);
  const groupId = group ? parseInt(group, 10) : null;
  const athleteId = athlete ? parseInt(athlete, 10) : null;

  return useMemo(() => {
    return {
      group: groupId,
      athlete: athleteId
    };
  }, [groupId, athleteId]);
}

export interface Location<S = unknown> {
  pathname: string;
  search: string;
  state?: S;
}

interface History {
  push(path: Path, state?: any): void;
  replace(path: Path, state?: any): void;
  listen(
    callback: (location: HLocation<any>, action: Action) => void
  ): UnregisterCallback;
  block(
    prompt?: boolean | string | TransitionPromptHook<any>
  ): UnregisterCallback;
}

export function useLocation<T = unknown>(): Location<T> {
  const { search, pathname, state } = useRouterLocation<T>();

  return useMemo(
    () => ({
      search,
      pathname,
      state
    }),
    [search, pathname, state]
  );
}

export function useHistory(): History {
  const history = useRouterHistory();
  const { push, listen, block, replace } = history;

  return useMemo(
    () => ({
      push,
      listen,
      block,
      replace
    }),
    [push, listen, block, replace]
  );
}

export function useSameLocationTransitionBlocker(): void {
  const history = useHistory();

  useEffect(() => {
    let prevPath = window.location.pathname;
    let prevSearch = window.location.search;

    function blockSameUrlTransition(location: HLocation<any>, action: Action) {
      const nextLocation = location.pathname + location.search;

      if (action === 'PUSH') {
        if (prevPath + prevSearch === nextLocation) {
          return false;
        }
      }

      prevPath = location.pathname;
      prevSearch = location.search;
    }

    return history.block(blockSameUrlTransition);
  }, [history]);
}
