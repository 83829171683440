import { axios } from '../../api/axios';
import { devMode, instance } from '../../api/api-mapping';
import { YollandaInteractionId } from '../types';

export interface SendFeedbackRequestParams {
  readonly interactionId: YollandaInteractionId;
  readonly qualityResponse: number;
}

export function sendFeedback(url: string, params: SendFeedbackRequestParams) {
  return axios.put(`${url}/api/interaction`, undefined, {
    params: { ...params, instanceCode: devMode ? instance : undefined }
  });
}
