import * as React from 'react';

import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  FormControlVariant,
  Icon,
  IconSize,
  SelectBox,
  SelectValueProps,
  TextInput
} from '@yarmill/components';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import isEmpty from 'validator/lib/isEmpty';
import styled from 'styled-components';
import isEmail from 'validator/lib/isEmail';
import {
  trackInviteUserFormFieldBlur,
  trackInviteUserFormFieldFocus
} from '../google-analytics/utils';

import { UserInvitation } from './types';
import { isInvitationHasEmail } from './utils';
import { values } from 'ramda';
import { checkIfEmailExists } from './api/check-if-email-exists';

export interface InviteUserRowProps {
  id: string;
  invitation: UserInvitation;
  invitations: { [key: string]: UserInvitation };
  onChange(id: string, field: string, value: string | boolean | number[]): void;
  onDeleteClick(id: string): void;
  onAddClick(): void;
}

export interface InviteUserRowState {
  isEmailValid: boolean;
  emailErrorMessage: string | null;
  isFirstNameValid: boolean;
  firstNameErrorMessage: string | null;
  isLastNameValid: boolean;
  lastNameErrorMessage: string | null;
}

const StyledInviteUserRow = styled.div`
  display: grid;
  grid-row-gap: 10px;
  @media (min-width: 576px) {
    position: relative;
    grid-template-columns: 4fr 3fr 3fr 2fr 1fr;
    column-gap: 10px;
  }
`;

const StyledDeleteButton = styled.div`
  svg {
    color: #d0021b;
  }
  justify-content: start;
  @media (min-width: 576px) {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      color: #4a90e2;
      margin-bottom: 18px;
    }
  }
`;
StyledDeleteButton.displayName = 'StyledDeleteButton';

const StyledLabel = styled.label`
  display: block;

  @media (min-width: 576px) {
    display: none;
  }
`;

const StyledSelectRole = styled.div``;

function InviteUserRow(props: InviteUserRowProps & WrappedComponentProps) {
  let emailField: HTMLInputElement | null = null;

  const [state, setState] = React.useState<InviteUserRowState>({
    isEmailValid: true,
    emailErrorMessage: null,
    isFirstNameValid: true,
    firstNameErrorMessage: null,
    isLastNameValid: true,
    lastNameErrorMessage: null
  });

  const { invitation, intl, id } = props;
  const { emailErrorMessage, firstNameErrorMessage, lastNameErrorMessage } =
    state;

  const options = [
    {
      label: intl.formatMessage({ id: 'settings.users.role.athlete' }),
      value: 'athlete'
    },
    {
      label: intl.formatMessage({ id: 'settings.users.role.coach' }),
      value: 'coach'
    }
  ];

  function onChange(e: React.FormEvent<HTMLInputElement | HTMLSelectElement>) {
    const { target } = e;
    if (
      target instanceof HTMLInputElement ||
      target instanceof HTMLSelectElement
    ) {
      const { name, value = '' } = target;
      trackInviteUserFormFieldBlur(name);
      props.onChange(props.id, name, value);
    }
  }

  function onChangeRole(selectedItem: SelectValueProps): void {
    trackInviteUserFormFieldBlur('Role');
    props.onChange(props.id, 'Role', selectedItem.value as string);
  }

  function onFocus(
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>
  ): void {
    const { target } = e;
    if (
      target instanceof HTMLInputElement ||
      target instanceof HTMLSelectElement
    ) {
      const { name } = target;
      trackInviteUserFormFieldFocus(name);
    }
  }

  function validateEmail(e: React.FormEvent<HTMLInputElement>): void {
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      const { value, name } = target;
      trackInviteUserFormFieldBlur(name);
      const isEmailValid = isEmail(value) || !value;
      if (!isEmailValid && emailField) {
        setError('settings.users.incorrectEmail');
      } else if (Object.keys(props.invitations).length > 1) {
        checkIfEmailDuplicated(value);
      } else {
        checkExistingEmail(value);
      }
    }
  }

  function validateTextField(e: React.FormEvent<HTMLInputElement>): void {
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      trackInviteUserFormFieldBlur(target.name);
      const isValid = !isEmpty(target.value) || props.invitation.Email === '';
      const errorMessage = !isValid ? 'evidence.attribute.required' : null;
      if (target.name === 'FirstName') {
        setState(prevState => ({
          ...prevState,
          isFirstNameValid: isValid,
          firstNameErrorMessage: errorMessage
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          isLastNameValid: isValid,
          lastNameErrorMessage: errorMessage
        }));
      }
    }
  }

  const checkExistingEmail = async (email: string): Promise<void> => {
    const { onChange, id } = props;
    const { data } = await checkIfEmailExists(email);
    if (data) {
      setError('settings.users.emailExists');
      focusEmailField();
      onChange(id, 'EmailAlreadyExist', true);
    } else {
      resetError();
      onChange(id, 'EmailAlreadyExist', false);
    }
  };

  function onDeleteClick(): void {
    props.onDeleteClick(props.id);
  }

  function resetError(): void {
    setState(prevState => ({
      ...prevState,
      emailErrorMessage: null,
      isEmailValid: true
    }));
  }

  function setError(errorMessage: string): void {
    setState(prevState => ({
      ...prevState,
      emailErrorMessage: errorMessage,
      isEmailValid: false
    }));
  }

  function focusEmailField(): void {
    if (emailField) {
      emailField.focus();
    }
  }

  function checkIfEmailDuplicated(value: string): void {
    const { invitations } = props;
    const notEmptyInvitations =
      values(invitations).filter(isInvitationHasEmail);
    const invitationEmails = notEmptyInvitations.map(
      invitation => invitation.Email
    );

    const isDuplicated =
      new Set(invitationEmails).size !== invitationEmails.length;

    if (isDuplicated) {
      setError('error.account.invite.duplicateEmail');
      focusEmailField();
    } else {
      resetError();
      checkExistingEmail(value);
    }
  }

  return (
    <StyledInviteUserRow>
      <div>
        <StyledLabel>
          <FormattedMessage id="settings.users.table.header.email" />
        </StyledLabel>
        <TextInput
          autoFocus={id === '0'}
          variant={FormControlVariant.big}
          noLabel
          error={
            emailErrorMessage
              ? intl.formatMessage({ id: emailErrorMessage })
              : ''
          }
          id="email"
          onChange={onChange}
          ref={ref => (emailField = ref)}
          onFocus={onFocus}
          onBlur={validateEmail}
          type="email"
          name="Email"
          placeholder="homer.simpson@czech-ski.com"
        />
      </div>
      <div>
        <StyledLabel>
          <FormattedMessage id="settings.users.table.header.firstName" />
        </StyledLabel>
        <TextInput
          variant={FormControlVariant.big}
          noLabel
          id="firstName"
          error={
            firstNameErrorMessage
              ? intl.formatMessage({ id: firstNameErrorMessage })
              : ''
          }
          onChange={onChange}
          onFocus={onFocus}
          onBlur={validateTextField}
          value={invitation.FirstName}
          type="text"
          name="FirstName"
          placeholder="Homer"
        />
      </div>
      <div>
        <StyledLabel>
          <FormattedMessage id="settings.users.table.header.lastName" />
        </StyledLabel>
        <TextInput
          variant={FormControlVariant.big}
          noLabel
          id="lastName"
          error={
            lastNameErrorMessage
              ? intl.formatMessage({ id: lastNameErrorMessage })
              : ''
          }
          onChange={onChange}
          onFocus={onFocus}
          onBlur={validateTextField}
          value={invitation.LastName}
          name="LastName"
          placeholder="Simpson"
          type="text"
        />
      </div>
      <StyledSelectRole>
        <StyledLabel>
          <FormattedMessage id="settings.users.table.header.role" />
        </StyledLabel>
        <SelectBox
          id="role"
          variant={FormControlVariant.big}
          label=""
          defaultValue={{
            label: intl.formatMessage({ id: 'settings.users.role.athlete' }),
            value: 'athlete'
          }}
          options={options}
          isSearchable={false}
          onChange={onChangeRole}
          noSeparator
          longList
          noExtraLabel
          disablePortal
          maxMenuHeight={350}
          data-cy="role"
        />
      </StyledSelectRole>
      <StyledDeleteButton>
        <Button
          onClick={onDeleteClick}
          appearance={ButtonAppearance.Link}
          type="button"
        >
          <Icon size={IconSize.s22}>
            <ExternalIcon name="CircleX" />
          </Icon>
        </Button>
      </StyledDeleteButton>
    </StyledInviteUserRow>
  );
}

const withIntl = injectIntl(InviteUserRow);
export { withIntl as InviteUserRow };
