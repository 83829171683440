import * as React from 'react';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormState, ServerError } from '../../app/app-types';
import { Button, FormControlVariant } from '@yarmill/components';
import {
  getSubmitButtonAppearance,
  getSubmitButtonIcon
} from '../../evidence/table/utils';

export interface AsyncState {
  loading?: boolean;
  loaded?: boolean;
  error?: boolean;
  errorMessages?: ServerError[];
}

export type AsyncButtonProps = PropsWithChildren<{
  disabled?: boolean;
  loading?: boolean;
  error?: boolean;
  success?: boolean;
  message?: string;
  tabIndex?: number;
  dataTest?: string;
  type?: 'button' | 'submit';
  innerRef?: React.RefObject<HTMLButtonElement>;
  variant?: FormControlVariant;
  onClick?(): void;
}>;

export function AsyncButton(props: AsyncButtonProps): JSX.Element {
  const {
    loading,
    error,
    success,
    disabled,
    type = 'button',
    tabIndex,
    dataTest,
    innerRef,
    variant
  } = props;

  const state: FormState = loading
    ? 'submitting'
    : success
    ? 'success'
    : error
    ? 'error'
    : null;

  return (
    <Button
      ref={innerRef}
      type={type}
      data-cy={dataTest}
      appearance={getSubmitButtonAppearance(state)}
      wide
      disabled={disabled || loading}
      onClick={props.onClick}
      tabIndex={tabIndex}
      variant={variant}
    >
      {getSubmitButtonIcon(state)}
      {props.children || <FormattedMessage id="plan.copy.doCopy" />}
    </Button>
  );
}
