import {
  action,
  computed,
  IObservableArray,
  observable,
  ObservableMap,
  reaction,
  runInAction,
  toJS,
  makeObservable
} from 'mobx';
import {
  EvidenceDataObject,
  EvidenceObject,
  EvidenceObjectHistoryItem,
  EvidenceObjectTags
} from '../types';
import { RootStore } from '../../app/mobx/root-store';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import { TableFiltersStore } from './table-filters-store';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import { getEvidenceObjectData } from '../api/get-evidence-object-data';
import { EvidenceObjectDataStore } from './evidence-object-data-store';
import { Locale } from '../../intl/types';
const DEFAULT_VISIBLE_LIMIT = 200;
export class EvidenceObjectStore {
  private readonly rootStore: RootStore;
  private readonly _definition: EvidenceObject;
  private readonly userId: UserId | null;
  private readonly groupId: UserGroupId | null;
  private readonly dataUrl: string;
  private readonly filtersStore: TableFiltersStore = new TableFiltersStore();

  @observable
  private visibleRowsCount = DEFAULT_VISIBLE_LIMIT;

  @observable
  private _data: EvidenceObjectDataStore[] = [];

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  @observable
  private request: RequestStore<EvidenceDataObject[]> | null = null;

  @observable
  private _tags: EvidenceObjectTags | undefined = undefined;

  constructor(
    rootStore: RootStore,
    definition: EvidenceObject,
    userId: UserId | null,
    groupId: UserGroupId | null,
    dataUrl: string
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this._definition = definition;
    this.userId = userId;
    this.groupId = groupId;
    this.dataUrl = dataUrl;

    this.registerReactions();
  }

  public get filters(): TableFiltersStore {
    return this.filtersStore;
  }

  @action
  public clear(): void {
    (this._data as IObservableArray).clear();
    this.filtersStore.clear();
  }

  @computed
  public get status(): AsyncStatus {
    return this._status || AsyncStatus.idle;
  }

  public setLoadedStatus(): void {
    this._status = AsyncStatus.resolved;
  }

  public setTags(tags: EvidenceObjectTags | undefined): void {
    this._tags = tags;
  }

  public get tags() {
    return this._tags;
  }

  public async loadData(): Promise<void> {
    this._status = AsyncStatus.pending;
    this.visibleRowsCount = DEFAULT_VISIBLE_LIMIT;
    const filters = toJS(this.filtersStore.filters as ObservableMap);
    const sortConfig = this.filtersStore.sortConfig
      ? { ...this.filtersStore.sortConfig, language: this.language }
      : null;

    const transaction = this.rootStore.navbarStore.createTransaction(
      'loadingData',
      'evidence'
    );
    this.request = this.rootStore.requestsStore.createRequest(() =>
      getEvidenceObjectData(this.dataUrl, {
        userId: this.userId,
        groupId: this.groupId,
        moduleKey: this.definition.ModuleKey,
        objectKey: this.definition.ObjectKey,
        filters,
        sortConfig
      })
    );

    const response = await this.request.getResponse();
    if (response) {
      runInAction(() => {
        const data = response.map(
          evidenceDataObject =>
            new EvidenceObjectDataStore(
              this.rootStore,
              this,
              this.definition,
              this.userId,
              this.groupId,
              this.dataUrl,
              evidenceDataObject
            )
        );
        (this._data as IObservableArray).replace(data);
        transaction.finished();
        this._status = AsyncStatus.resolved;
      });
    } else {
      runInAction(() => {
        transaction.error();
        this._status = AsyncStatus.rejected;
      });
    }
  }

  @computed
  public get data(): EvidenceObjectDataStore[] {
    return this._data.slice(0, this.visibleRowsCount);
  }

  @computed
  public get totalRowsCount(): number {
    return this._data.length;
  }

  public get definition(): EvidenceObject {
    return this._definition;
  }

  @action
  public readonly showMoreRows = (): void => {
    this.visibleRowsCount += DEFAULT_VISIBLE_LIMIT;
  };

  @action
  public addDataObject(dataObject: EvidenceObjectDataStore): void {
    this._data.push(dataObject);
  }

  public createNewEvidenceObjectDataStore(
    data?: EvidenceDataObject
  ): EvidenceObjectDataStore {
    return new EvidenceObjectDataStore(
      this.rootStore,
      this,
      this.definition,
      this.userId,
      this.groupId,
      this.dataUrl,
      data
    );
  }

  @computed
  private get language(): Locale {
    return this.rootStore.intlStore.locale;
  }

  private registerReactions(): void {
    reaction(
      () => ({
        sortConfig: this.filtersStore.sortConfig,
        filters: this.filtersStore.jsFilters
      }),
      () => {
        this.loadData();
      }
    );
  }

  @action
  public removeItem(item: EvidenceObjectDataStore): void {
    (this._data as IObservableArray<EvidenceObjectDataStore>).remove(item);
  }

  @action
  public addSubmitHistoryItem(item: EvidenceObjectHistoryItem): void {
    this.rootStore.evidenceSubmitHistory.addHistoryItem(
      this.definition.ObjectKey,
      item
    );
  }

  public get submitHistory(): EvidenceObjectHistoryItem[] {
    return (
      this.rootStore.evidenceSubmitHistory.getHistoryForObject(
        this.definition.ObjectKey
      ) ?? []
    );
  }
}
