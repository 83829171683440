import { ForwardedRef, forwardRef, useContext } from 'react';
import { useFloatingTree, useListItem, useMergeRefs } from '@floating-ui/react';
import { css, Icon, IconSize, styled } from '@yarmill/components';
import { DropdownContext } from './dropdown-context';
import { DropdownOption, DropdownProvider } from './dropdown-provider';
import { MultiSelectCheckbox } from './multiselect-checkbox';
import { Text } from '../text';
import { Right } from '@yarmill/icons-2';
import { Radio } from '../radio';

const OptionContentWrapper = styled.span`
  display: flex;
  width: 100%;
  column-gap: ${({ theme }) => theme.size.x1};
  color: ${({ theme }) => theme.color.white};
  user-select: none;
`;
const OptionChildrenWrapper = styled.span`
  flex-grow: 1;
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.size.x1};
`;

export const OptionLabelText = styled(Text)`
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const SingleSelectOptionWrapper = styled.div<{
  readonly $isActive: boolean;
  readonly $isSelectAll?: boolean;
}>`
  white-space: nowrap;
  display: inline-flex;
  flex: 0 0 100%;
  align-items: center;
  transition: color 150ms;
  cursor: pointer;
  opacity: 1;
  position: relative;
  padding: ${({ theme }) => theme.size.x1};
  outline: none;
  border-radius: ${({ theme }) => theme.borderRadius.x1};
  column-gap: ${({ theme }) => theme.size.x1};
  min-width: min-content;
  width: 100%;
  text-overflow: ellipsis;

  :hover {
    background-color: ${({ theme }) => theme.color.blackHover};
  }

  ${({ theme, $isActive }) =>
    $isActive &&
    css`
      background-color: ${theme.color.blackHover};
    `}

  ${({ theme, $isSelectAll }) =>
    $isSelectAll &&
    css`
      margin-bottom: ${({ theme }) => theme.size.x1};
      position: relative;
      :after {
        content: ' ';
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(${theme.size.x05});
        width: 100%;
        height: 1px;
        background-color: ${theme.color.blackHover};
      }
    `};
`;

const IconWrapper = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.white};
  width: ${({ theme }) => theme.size.x2};
  height: ${({ theme }) => theme.size.x2};
`;

function containsSelectedValue<V>(
  selectedValue: V,
  subOptions: DropdownOption<V>[] | undefined
): boolean {
  return Boolean(
    subOptions?.find(
      o =>
        o.value === selectedValue ||
        containsSelectedValue(selectedValue, o.children)
    )
  );
}
export const Option = forwardRef(function Option<V>(
  {
    children,
    value,
    label,
    icon,
    onClick,
    isAdditional,
    showRadio,
    ...otherProps
  }: DropdownOption<V>,
  forwardedRef: ForwardedRef<HTMLDivElement>
): JSX.Element {
  const {
    activeIndex,
    getItemProps,
    handleSelect,
    selectedValue,
    setHasFocusInside,
    hasSubcategories,
    multiSelect
  } = useContext(DropdownContext);

  const { ref, index } = useListItem({
    label
  });
  const tree = useFloatingTree();
  const mergedRef = useMergeRefs([ref, forwardedRef]);

  const isActive = activeIndex === index;
  const isSelected =
    (((Array.isArray(selectedValue)
      ? selectedValue.includes(value)
      : selectedValue === value) ||
      containsSelectedValue(selectedValue, children)) &&
      value !== undefined) ||
    (isAdditional && otherProps.isSelected);
  const hasChildren = (children?.length ?? 0) > 0;

  const option = (
    <OptionContentWrapper>
      <OptionChildrenWrapper>
        {icon && <Icon size={IconSize.s16}>{icon}</Icon>}
        <Text inheritColor appearance={isSelected ? 'text12strong' : 'text12'}>
          {label}
        </Text>
      </OptionChildrenWrapper>
      {multiSelect && !hasChildren && (
        <MultiSelectCheckbox
          isActive={isActive}
          isChecked={isSelected}
          isAdditional={isAdditional}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            handleSelect(index, value, true);
          }}
        />
      )}
      {showRadio && !hasChildren && (
        <Radio
          hideInput
          isChecked={isSelected}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            handleSelect(index, value);
          }}
        />
      )}
      {hasChildren && (
        <IconWrapper aria-hidden>
          <Right />
        </IconWrapper>
      )}
      {hasSubcategories && !hasChildren && (
        <IconWrapper aria-hidden>
          <Right />
        </IconWrapper>
      )}
    </OptionContentWrapper>
  );

  if (children?.length) {
    return (
      <DropdownProvider
        ref={mergedRef as any}
        options={children}
        selectedValue={selectedValue}
        handleSelect={value => handleSelect(index, value)}
        {...otherProps}
      >
        {option}
      </DropdownProvider>
    );
  } else {
    return (
      <SingleSelectOptionWrapper
        ref={mergedRef}
        role="option"
        aria-selected={isActive && isSelected}
        $isActive={isActive}
        tabIndex={isActive ? 0 : -1}
        $isSelectAll={isAdditional}
        {...getItemProps({
          ...otherProps,
          onClick: e => {
            onClick?.(e);
            handleSelect(index, value);
            tree?.events.emit('click');
          },
          onKeyDown: e => {
            if (e.key === ' ') {
              handleSelect(index, value, multiSelect);
            } else if (e.key === 'Enter') {
              handleSelect(index, value);
            }
          },
          onFocus() {
            setHasFocusInside(true);
          }
        })}
      >
        {option}
      </SingleSelectOptionWrapper>
    );
  }
});
