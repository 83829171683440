import HtmlParser from 'react-html-parser';
import { FormattedMessage, useIntl } from 'react-intl';
import { ComponentProps } from 'react';
import { StyledFormattedHTMLMessage } from '@yarmill/components';
import { useDefaultValues } from './hooks/use-default-values';

function replaceValues(
  message: string,
  values: Record<string, string>
): string {
  let result = message;

  Object.entries(values || {}).forEach(([key, value]) => {
    const regex = new RegExp(`{${key}}`, 'g');
    result = result.replace(regex, value ?? key);
  });

  return result;
}

export function FormattedHTMLMessage({
  id,
  defaultMessage,
  values
}: ComponentProps<typeof FormattedMessage>): JSX.Element {
  const intl = useIntl();
  const defaultValues = useDefaultValues(id);

  const translatedString = intl.formatMessage(
    { id: id, defaultMessage: defaultMessage },
    defaultValues ? { ...defaultValues, ...values } : undefined,
    { ignoreTag: true }
  ) as string;

  const message = replaceValues(
    translatedString,
    values as Record<string, string>
  );

  return (
    <StyledFormattedHTMLMessage>
      {HtmlParser(message)}
    </StyledFormattedHTMLMessage>
  );
}
