import { BaseDashboardStore } from './base-dashboard-store';
import {
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction
} from 'mobx';
import {
  Fact as SlcrFact,
  Indicator as SlcrIndicator,
  StressCompare as SlcrStressCompare,
  Trend as SlcrTrend,
  VerticalDimension as SlcrVerticalDimension
} from '../dashboards/slcr/types';
import {
  Fact as SpsSlcrFact,
  Indicator as SpsSlcrIndicator,
  StressCompare as SpsSlcrStressCompare,
  Trend as SpsSlcrTrend,
  VerticalDimension as SpsSlcrVerticalDimension
} from '../dashboards/sps-slcr/types';
import { api as slcrApi } from '../dashboards/slcr/api';
import { api as spsSlcrApi } from '../dashboards/sps-slcr/api';
import { sortBySortCode } from '../dashboards/slcr/utils';
import { AsyncStatus } from '../../api/mobx/request-store';
import { groupBy } from 'ramda';
import { RootStore } from '../../app/mobx/root-store';
import { Dashboard } from '../types';

type Fact = SlcrFact | SpsSlcrFact;
type Indicator = SlcrIndicator | SpsSlcrIndicator;
type StressCompare = SlcrStressCompare | SpsSlcrStressCompare;
type Trend = SlcrTrend | SpsSlcrTrend;
type VerticalDimension = SlcrVerticalDimension | SpsSlcrVerticalDimension;
interface VerticalDimensions {
  cyclicDimensions: VerticalDimension[];
  nonCyclicDimensions: VerticalDimension[];
}

export class SlcrDashboardStore extends BaseDashboardStore {
  @observable
  private readonly _facts: Fact[] = [];
  @observable
  private readonly _indicators: Indicator[] = [];
  @observable
  private readonly _stressCompare: StressCompare[] = [];
  @observable
  private readonly _trends: Trend[] = [];
  @observable
  private readonly _verticalDimensions: VerticalDimension[] = [];

  constructor(rootStore: RootStore, dashboard: Dashboard) {
    super(rootStore, dashboard);
    makeObservable(this);
  }

  public async loadData(): Promise<void> {
    const requestsStore = this._rootStore.requestsStore;
    const filterValues = Object.fromEntries(this._filterValues.entries());
    const api = this.id.includes('sps-') ? spsSlcrApi : slcrApi;
    const isForGroups = this.id.includes('perGroupDashboard');

    const requests = [
      requestsStore.createRequest(() =>
        api.loadFacts(filterValues, isForGroups)
      ),
      requestsStore.createRequest(
        () => api.loadIndicators(filterValues, isForGroups) as any
      ),
      requestsStore.createRequest(
        () => api.loadStressCompare(filterValues, isForGroups) as any
      ),
      requestsStore.createRequest(
        () => api.loadTrend(filterValues, isForGroups) as any
      )
    ];

    const responses = await Promise.all(requests.map(r => r.getResponse()));

    runInAction(() => {
      const [facts, indicators, stressCompare, trends] = responses;
      (this._facts as IObservableArray<Fact>).replace(facts as Fact[]);
      (this._indicators as IObservableArray<Indicator>).replace(
        indicators as Indicator[]
      );
      (this._stressCompare as IObservableArray<StressCompare>).replace(
        stressCompare as StressCompare[]
      );
      (this._trends as IObservableArray<Trend>).replace(trends as Trend[]);
      this._status = AsyncStatus.resolved;
    });
  }

  public async loadVerticalDimensions(): Promise<void> {
    const api = this.id.includes('sps-') ? spsSlcrApi : slcrApi;

    const request = this._rootStore.requestsStore.createRequest(() =>
      api.loadVerticalDimensions(this.id)
    );

    const response = await request.getResponse();
    runInAction(() => {
      if (response) {
        (
          this._verticalDimensions as IObservableArray<VerticalDimension>
        ).replace(response);
      }
    });
  }

  @computed
  public get facts(): Record<string, Fact[]> {
    return groupBy(item => item.VerticalDimensionIdent, this._facts);
  }

  public get indicators(): Record<string, Indicator[]> {
    return groupBy(item => item.VerticalDimensionIdent, this._indicators);
  }

  public get stressCompare(): StressCompare[] {
    return this._stressCompare;
  }

  public get trends(): Trend[] {
    return this._trends;
  }

  public get status(): AsyncStatus {
    if (this._status !== AsyncStatus.resolved) {
      return this._status;
    }

    return this._status === AsyncStatus.resolved &&
      this._verticalDimensions.length !== 0
      ? AsyncStatus.resolved
      : AsyncStatus.pending;
  }

  @computed
  public get verticalDimensions(): VerticalDimensions {
    const cyclicDimensions: VerticalDimension[] = [];
    const nonCyclicDimensions: VerticalDimension[] = [];

    this._verticalDimensions
      .slice()
      .sort(sortBySortCode)
      .forEach(d => {
        if (d.CyclicCategory) {
          cyclicDimensions.push(d);
        } else {
          nonCyclicDimensions.push(d);
        }
      });

    return {
      cyclicDimensions,
      nonCyclicDimensions
    };
  }
}
