import {
  ComponentType,
  PropsWithChildren,
  ReactElement,
  useContext
} from 'react';
import { ReportingSectionContext } from './section';
import { ReportingContentBox } from '@yarmill/components';

export function ReportContentBox({
  children,
  customContentBoxComponent
}: PropsWithChildren<{
  readonly customContentBoxComponent?: ComponentType;
}>): ReactElement {
  const { isHighlighted } = useContext(ReportingSectionContext);

  if (isHighlighted) {
    return <>{children}</>;
  }

  const ContentBoxComponent = customContentBoxComponent ?? ReportingContentBox;

  return <ContentBoxComponent>{children}</ContentBoxComponent>;
}
