import {
  PageScrollContainer,
  WeekGoalsContentBox,
  WeekGoalsLayout
} from '@yarmill/components';
import { WeekContainer } from '../goals/week-container';
import { NUMBER_OF_GOALS_WEEKS } from '../goals/mobx/goals-store';
import { times } from '../utils/times';
import { HeaderColumn } from '../goals/header-column';

export function GoalsView(): JSX.Element | null {
  return (
    <PageScrollContainer>
      <WeekGoalsLayout
        className="goals-container"
        numberOfGoalsWeeks={NUMBER_OF_GOALS_WEEKS}
      >
        {times(NUMBER_OF_GOALS_WEEKS).map((_, index) => (
          <WeekGoalsContentBox $column={index + 1} key={index} />
        ))}
        <HeaderColumn />
        {times(NUMBER_OF_GOALS_WEEKS).map((_, index) => (
          <WeekContainer index={index} key={index} />
        ))}
      </WeekGoalsLayout>
    </PageScrollContainer>
  );
}
