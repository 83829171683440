import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import {
  AlertLayerCloseButtonWrapper,
  Button,
  ButtonAppearance,
  Text,
  TextSize,
  TextTag
} from '@yarmill/components';
import { instance } from '../api/api-mapping';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import { AnnouncementStore } from './mobx/announcement-store';
import { observer } from 'mobx-react-lite';
import { useLayer } from '../layer-manager/hooks';
import { LayerPortal } from '../layer-manager/layer-portal';
import { useEffect } from 'react';
import { CloseButton } from '../components/close-button/close-button';

const StyledHeadline = styled.h2<{ alignImgLeft: boolean }>`
  @media (min-width: 768px) {
    ${({ alignImgLeft }) =>
      alignImgLeft &&
      css`
        margin-left: 70px;
      `}
  }
`;

const StyledTextWrapper = styled.div`
  padding-bottom: 40px;

  @media (min-width: 768px) {
    padding-bottom: 50px;
    padding-left: 25px;
  }
  @media (min-width: 992px) {
    padding-left: 50px;
    padding-bottom: 70px;
  }
`;

const StyledContentContainer = styled.div`
  margin-top: 40px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 2fr 3fr;
  }
`;
interface StyledImageWrapperProps {
  readonly fullHeight?: boolean;
}
const StyledImageWrapper = styled.div<StyledImageWrapperProps>`
  margin-bottom: 40px;
  min-width: 200px;
  padding-bottom: ${props => (props.fullHeight ? 0 : '40px')};

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;

    ${props =>
      props.fullHeight &&
      css`
        justify-content: flex-end;
      `}
  }
`;

const StyledCloseButtonWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
  text-align: center;

  @media (min-width: 992px) {
    margin-top: 60px;
    text-align: left;
  }

  button {
    border-radius: 4px;
    padding: 10px 50px;
  }
`;

const StyledAlert = styled.div<{ alignImgLeft: boolean }>`
  padding: 40px 30px;

  @media (min-width: 768px) {
    padding: 60px 70px 0;
    ${({ alignImgLeft }) =>
      alignImgLeft &&
      css`
        padding-left: 0;
        padding-bottom: 0;
      `}
  }

  @media (min-width: 992px) {
    padding: 70px 70px 0;
    ${({ alignImgLeft }) =>
      alignImgLeft &&
      css`
        padding-left: 0;
        padding-bottom: 0;
      `}
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;
`;

interface AlertAnnouncementProps {
  announcement: AnnouncementStore;
}

export const AlertAnnouncement = observer(function AlertAnnouncement(
  props: AlertAnnouncementProps
): JSX.Element | null {
  const { announcement } = props;
  const intl = useIntl();
  const alertLayer = useLayer('alert', {
    showShim: true,
    wide: true,
    onClose: () => announcement.close()
  });

  const imgSrc = intl.formatMessage({
    id: `announcement.${announcement.code}.image`
  });

  const fullHeight = imgSrc.includes('--full-height');
  const alignLeft = imgSrc.includes('--align-left');

  useEffect(() => {
    if (!alertLayer.isOpened) {
      alertLayer.open();
    }
  }, [alertLayer]);

  return (
    <LayerPortal
      layerHandle={alertLayer}
      getContent={layer => (
        <StyledAlert alignImgLeft={alignLeft} data-cy="alert">
          <StyledHeadline alignImgLeft={alignLeft}>
            <Text bold size={TextSize.s20}>
              <FormattedMessage
                id={`announcement.${announcement.code}.headline`}
              />
            </Text>
            <AlertLayerCloseButtonWrapper>
              <CloseButton onClick={layer.close} hideText />
            </AlertLayerCloseButtonWrapper>
          </StyledHeadline>
          <StyledContentContainer>
            <StyledImageWrapper fullHeight={fullHeight}>
              <Img
                src={imgSrc}
                alt={intl.formatMessage({
                  id: `announcement.${announcement.code}.imageAltText`
                })}
              />
            </StyledImageWrapper>
            <StyledTextWrapper>
              <Text tag={TextTag.span}>
                <FormattedHTMLMessage
                  id={`announcement.${announcement.code}.text`}
                  values={{ instanceUrl: instance }}
                />
              </Text>
              <StyledCloseButtonWrapper>
                <Button
                  data-cy="submit"
                  wide
                  onClick={layer.close}
                  appearance={ButtonAppearance.Primary}
                  inverted
                  noShadow
                  autoFocus
                >
                  <FormattedMessage
                    id={`announcement.${announcement.code}.button`}
                  />
                </Button>
              </StyledCloseButtonWrapper>
            </StyledTextWrapper>
          </StyledContentContainer>
        </StyledAlert>
      )}
    />
  );
});
