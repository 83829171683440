import { RootStore } from '../../app/mobx/root-store';
import { regenerateToken } from '../api/regenerate-token';
import { action, makeObservable, observable } from 'mobx';
import { getQueryParam } from '../../utils/get-query-param';
import { clearAuthToken, getAuthToken, saveAuthToken } from '../utils';
import { setAuthToken } from '../../api/axios';
import { logIn } from '../api/login';
import { logOut } from '../api/logout';
import { forgottenPassword } from '../api/forgotten-password';
import { changePassword } from '../api/change-password';
import { setResetedPassword } from '../api/set-reseted-password';

export class AuthStore {
  private readonly _rootStore: RootStore;

  @observable
  private _isLoggedIn: boolean = false;

  @observable
  private _token: string | null = null;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    this.prepareInitialState();
    makeObservable(this);
  }

  @action
  public async regenerateAuthToken(): Promise<void> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      regenerateToken()
    );
    const response = await request.getResponse();

    if (response) {
      this._token = response.Token;
    }
  }

  public get token(): string | null {
    return this._token;
  }

  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  public async logIn(username: string, password: string): Promise<void> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      logIn({ username, password })
    );
    const response = await request.getResponse();

    if (response) {
      this.setAuthToken(response.access_token);
    } else {
      throw request.error;
    }
  }

  @action
  public async logOut(): Promise<void> {
    const request = this._rootStore.requestsStore.createRequest(() => logOut());
    await request.getResponse();

    clearAuthToken();
    this._token = null;
    this._isLoggedIn = false;
  }

  @action
  public async requestForgottenPassword(email: string): Promise<void> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      forgottenPassword({ Email: email })
    );
    const response = await request.getResponse();

    if (!response) {
      throw request.error;
    }
  }

  @action
  public async changePassword(
    oldPassword: string,
    newPassword: string,
    newPasswordConfirm: string
  ): Promise<void> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      changePassword({
        OldPassword: oldPassword,
        NewPassword: newPassword,
        ConfirmPassword: newPasswordConfirm
      })
    );
    const response = await request.getResponse();

    if (!response) {
      throw request.error;
    }
  }

  @action
  public async resetForgottenPassword(newPassword: string): Promise<void> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      setResetedPassword({ NewPassword: newPassword })
    );
    const response = await request.getResponse();

    if (!response) {
      throw request.error;
    }
  }

  @action
  private prepareInitialState(): void {
    const tokenFromUrl = getQueryParam('token');
    const tokenFromCookie = getAuthToken();
    this._token = tokenFromUrl || tokenFromCookie || null;
    this._isLoggedIn = Boolean(tokenFromCookie);
    setAuthToken(this._token);
  }

  @action
  private setAuthToken(token: string): void {
    this._token = token;
    this._isLoggedIn = true;
    saveAuthToken(token);
  }
}
