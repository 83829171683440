import { Component, PropsWithChildren } from 'react';
import { Breakpoints } from '../helpers';

export type BreakpointWrapperProps = PropsWithChildren<{
  readonly min?: Breakpoints | number;
  readonly max?: Breakpoints | number;
  readonly ssrBreakpoint?: Breakpoints | number;
}>;

export interface BreakpointWrapperState {
  readonly isVisible?: boolean;
}

export class BreakpointWrapper extends Component<
  BreakpointWrapperProps,
  BreakpointWrapperState
> {
  private mediaQueryList?: MediaQueryList;
  private readonly mediaQuery: string;

  public constructor(props: BreakpointWrapperProps) {
    super(props);
    let { min, max, ssrBreakpoint } = this.props;

    if (min === Breakpoints.default) {
      min = undefined;
    }
    if (max === Breakpoints.default) {
      max = undefined;
    }
    if (ssrBreakpoint === Breakpoints.default) {
      ssrBreakpoint = undefined;
    }

    const mediaQueryMin = min ? ` and (min-width: ${min}px)` : '';
    const mediaQueryMax = max ? ` and (max-width: ${max - 1}px)` : '';
    this.mediaQuery = `all${mediaQueryMin}${mediaQueryMax}`;

    let isVisible = false;
    if (min && max && ssrBreakpoint) {
      isVisible = min <= ssrBreakpoint && max > ssrBreakpoint;
    } else if (min && ssrBreakpoint) {
      isVisible = min <= ssrBreakpoint;
    } else if (max && ssrBreakpoint) {
      isVisible = max > ssrBreakpoint;
    } else if (ssrBreakpoint) {
      isVisible = true;
    } else if (!min) {
      isVisible = true;
    }

    this.state = {
      isVisible
    };
  }

  public componentDidMount(): void {
    this.mediaQueryList = window.matchMedia(this.mediaQuery);
    this.setState({ isVisible: this.mediaQueryList.matches });

    if (!this.mediaQueryList.addEventListener) {
      // Fallback for Safari
      // tslint:disable-next-line
      this.mediaQueryList.addListener(this.handleMatchMedia);

      return;
    }
    this.mediaQueryList.addEventListener('change', this.handleMatchMedia);
  }

  public componentWillUnmount(): void {
    if (this.mediaQueryList && this.mediaQueryList.removeEventListener) {
      this.mediaQueryList.removeEventListener('change', this.handleMatchMedia);
    }
  }

  public render(): JSX.Element | null {
    const { children } = this.props;
    const { isVisible } = this.state;

    return (isVisible && <>{children}</>) || null;
  }

  private readonly handleMatchMedia = (event: MediaQueryListEvent) => {
    this.setState({ isVisible: event.matches });
  };
}
