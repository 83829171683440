import {
  KeyResultsListHeader,
  KeyResultsListLayout,
  KeyResultsTable,
  KeyResultsTableResponsiveWrapper
} from '../components-2/okrs/detail/key-results-list';
import { FormattedMessage } from 'react-intl';
import { ObjectiveStore } from './mobx/objective-store';
import { observer } from 'mobx-react-lite';
import { KeyResultDetail } from './key-result-detail';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { KeyResultsTableHeader } from './key-results-table-header';
import { Text } from '../components-2/text';
import { KeyResultsTableFooter } from './key-results-table-footer';
import { KeyResultStore } from './mobx/key-result-store';
import { runInAction } from 'mobx';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { DraggableKeyResult } from './draggable-key-result';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { KeyResultDetailDragged } from './key-result-detail-dragged';
import { useOkrsStore } from './okrs-store-context';

interface KeyResultsListProps {
  readonly objective: ObjectiveStore;
  readonly save: () => Promise<void>;
}

const emptyKeyResults: KeyResultStore[] = [];
export const KeyResultsList = observer(function KeyResultsList({
  objective,
  save
}: KeyResultsListProps): JSX.Element {
  const okrsStore = useOkrsStore();
  const keyResults = objective.keyResults ?? emptyKeyResults;
  const [newKeyResultPlaceholder, setNewKeyResultPlaceholder] =
    useState<KeyResultStore | null>(new KeyResultStore());
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const handleDragStart = useCallback(function handleDragStart(
    event: DragStartEvent
  ) {
    setActiveId(event.active.id);
  },
  []);
  const theadRef = useRef<HTMLTableSectionElement>(null);

  const handleDragEnd = useCallback(
    function handleDragEnd(event: DragEndEvent) {
      const { active, over } = event;
      if (active.id !== over?.id) {
        runInAction(() => {
          const activeResult = keyResults.find(r => r.uid === active.id);
          const overResult = keyResults.find(r => r.uid === over?.id);

          if (!activeResult || !overResult) {
            return;
          }

          const oldSortCode = keyResults.indexOf(activeResult);
          const newSortCode = keyResults.indexOf(overResult);

          const sortedKeyResults = arrayMove(
            keyResults,
            oldSortCode,
            newSortCode
          );
          sortedKeyResults.forEach((store, idx) => store.setSortCode(idx));
          objective.sortKeyResults();
          save();
        });
      }

      setActiveId(null);
    },
    [keyResults, objective, save]
  );

  const handleDragCancel = useCallback(function handleDragCancel() {
    setActiveId(null);
  }, []);

  const draggedKeyResult = useMemo(
    () => (activeId ? keyResults.find(k => k.uid === activeId) : undefined),
    [activeId, keyResults]
  );

  useEffect(() => {
    if (objective?.hasInvalidKeyResult) {
      setNewKeyResultPlaceholder(null);
    } else if (!objective?.hasInvalidKeyResult && !newKeyResultPlaceholder) {
      setNewKeyResultPlaceholder(new KeyResultStore());
    }
  }, [newKeyResultPlaceholder, objective?.hasInvalidKeyResult]);

  const createKeyResult = useCallback(async () => {
    if (newKeyResultPlaceholder) {
      runInAction(() => {
        newKeyResultPlaceholder.setSortCode(objective.keyResults.length);
        objective?.addKeyResults(newKeyResultPlaceholder);
        setNewKeyResultPlaceholder(null);
        save();
      });
    }
  }, [objective, newKeyResultPlaceholder, save]);

  const keyResultsWithPlaceholder =
    newKeyResultPlaceholder && okrsStore.isCurrentUserAllowedToWrite(objective)
      ? [...keyResults, newKeyResultPlaceholder]
      : keyResults;

  return (
    <KeyResultsListLayout>
      <KeyResultsListHeader>
        <Text upperCase appearance="task13strong" inheritColor>
          <FormattedMessage id="okrs.form.keyResults.headline" />
        </Text>
      </KeyResultsListHeader>

      <KeyResultsTableResponsiveWrapper>
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
        >
          <KeyResultsTable>
            <KeyResultsTableHeader objective={objective} ref={theadRef} />
            <tbody>
              <SortableContext
                items={objective.keyResultsUIds ?? emptyKeyResults}
                strategy={verticalListSortingStrategy}
              >
                {keyResultsWithPlaceholder.map(keyResult => (
                  <DraggableKeyResult
                    key={keyResult.uid}
                    id={keyResult.uid}
                    disabled={!Boolean(keyResult.id)}
                    status={keyResult.status}
                  >
                    <KeyResultDetail
                      key={keyResult.uid}
                      objective={objective}
                      keyResult={keyResult}
                      save={keyResult.id ? save : createKeyResult}
                    />
                  </DraggableKeyResult>
                ))}
              </SortableContext>
            </tbody>
            <KeyResultsTableFooter />
          </KeyResultsTable>
          <DragOverlay>
            {draggedKeyResult && (
              <KeyResultsTable>
                <tbody>
                  <tr>
                    <KeyResultDetailDragged keyResult={draggedKeyResult} />
                  </tr>
                </tbody>
              </KeyResultsTable>
            )}
          </DragOverlay>
        </DndContext>
      </KeyResultsTableResponsiveWrapper>
    </KeyResultsListLayout>
  );
});
