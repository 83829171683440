import {
  ButtonAppearance,
  ExternalIcon,
  Icon,
  IconSize,
  PopoverToggleButton
} from '@yarmill/components';
import * as React from 'react';

export interface EllipsisButtonProps {
  'data-cy'?: string;
}

export const EllipsisButton = React.forwardRef(
  (
    props: EllipsisButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ): JSX.Element => (
    <PopoverToggleButton
      ref={ref}
      type="button"
      data-cy={props['data-cy']}
      appearance={ButtonAppearance.Secondary}
      noShadow
    >
      <Icon size={IconSize.s20}>
        <ExternalIcon name="Dots" />
      </Icon>
    </PopoverToggleButton>
  )
);
