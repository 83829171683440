import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { FileOverview } from '../types';
import {
  ApiSortConfig,
  EvidenceTableFilters
} from '../../evidence/table/types';

export interface GetFilesRequestParams {
  userId: number | undefined;
  userGroupId: number | undefined;
  filters: EvidenceTableFilters;
  sortConfig: ApiSortConfig | null;
}

export function getFiles(
  params: GetFilesRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<FileOverview[]> {
  return axios.get(`api/file`, {
    params: {
      ...params,
      sortConfig: params.sortConfig
        ? JSON.stringify(params.sortConfig)
        : undefined,
      filters: JSON.stringify(Object.fromEntries(params.filters.entries()))
    },
    cancelToken: cancelToken?.token
  });
}
