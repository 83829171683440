import { FormEvent } from 'react';
import { AbstractActivityStore } from './abstract-activity-store';
import { RootStore } from '../../app/mobx/root-store';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { ActivityItem } from '../types';

export class NumericActivityStore extends AbstractActivityStore {
  private static MAX_DECIMAL_PLACES = 3;
  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    index: number,
    activityItem: ActivityItem
  ) {
    super(rootStore, diaryStore, index, activityItem);
    this.onChange = this.onChange.bind(this);
  }

  public onChange(e: FormEvent<HTMLInputElement>): void {
    const { target } = e;

    if (!(target instanceof HTMLInputElement)) {
      return;
    }
    const newValue = target.value;

    let hasDecimal = NumericActivityStore.hasTrailingDecimal(newValue);
    const correctedValue = hasDecimal ? newValue.slice(0, -1) : newValue;
    if (correctedValue.indexOf('.') !== -1) {
      hasDecimal = false;
    }
    const numberValue = Number(correctedValue);
    let formattedValue = String(numberValue);

    // We need to allow writing numbers like 1.01 so 1.0 needs to pass
    if (
      !hasDecimal &&
      correctedValue.indexOf('.') !== -1 &&
      correctedValue.endsWith('0')
    ) {
      formattedValue = correctedValue;
    }

    if (
      correctedValue.indexOf('.') !== -1 &&
      correctedValue.length >
        correctedValue.indexOf('.') +
          NumericActivityStore.MAX_DECIMAL_PLACES +
          (numberValue < 0 ? 2 : 1)
    ) {
      formattedValue = correctedValue.slice(0, -1);
    }

    if (newValue === '-') {
      formattedValue = '-';
    } else if (newValue === '') {
      formattedValue = '';
    } else if (Number.isNaN(numberValue)) {
      return;
    } else if (hasDecimal) {
      formattedValue = `${formattedValue}.`;
    }

    this.setUserValue(formattedValue);
  }

  private static hasTrailingDecimal(value: string): boolean {
    const decimal = value[value.length - 1];
    return decimal === '.' || decimal === ',';
  }
}
