import { css, Icon, IconSize, styled } from '@yarmill/components';
import { Text } from './text';
import { getAppearanceColors, TextInputAppearance } from './text-input';
import { Line } from '@yarmill/icons-2';
export const HeaderLayout = styled.header<{
  readonly as: 'header' | 'footer';
  readonly inverted?: boolean;
  readonly dark?: boolean;
}>`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 0;
  flex-shrink: 0;
  column-gap: ${({ theme }) => theme.size.x1};
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 100vw;
  min-height: ${({ theme }) => theme.size.x6};
  padding: 0 ${({ theme }) => theme.size.x1};
  background-color: ${({ inverted, dark, theme }) =>
    inverted
      ? theme.color.neutralPlate
      : dark
      ? theme.color.blackHover
      : theme.color.white};
  position: relative;
`;

export const HeaderNavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.size.x25};
  flex: 1 1 0;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
`;

export const HeaderButtonsContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
`;

export const HeaderButtonsLayout = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: ${({ theme }) => theme.size.x1};
  max-width: 100%;
`;

export const HeaderPageTitle = styled(Text)<{
  readonly absolutelyCentered?: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.neutralDark};

  ${({ absolutelyCentered }) =>
    absolutelyCentered &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}}
`;

export const HeaderPageTitleTeamIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.neutral};
`;

interface HeaderButtonsDividerProps {
  readonly appearance?: TextInputAppearance;
  readonly size?: IconSize;
}

const DividerIcon = styled(Icon)<{ readonly $appearance: TextInputAppearance }>`
  color: ${({ theme, $appearance }) =>
    getAppearanceColors($appearance, theme).background};
`;
export function HeaderButtonsDivider({
  appearance = 'neutral',
  size
}: HeaderButtonsDividerProps): JSX.Element {
  return (
    <DividerIcon size={size} $appearance={appearance}>
      <Line />
    </DividerIcon>
  );
}
