import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { Objective } from '../types';
import { SeasonId } from '../../seasons/types';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';

export interface PutObjectiveRequestParams {
  userId: number | undefined;
  seasonId: SeasonId;
  objective: Omit<Objective, 'id'> & { id?: string | number };
}

export function putObjective(
  params: PutObjectiveRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<Objective> {
  return axios.put(
    `${coreApiTarget}/api/okrs/userId/${params.userId}/seasonId/${params.seasonId}`,
    params.objective,
    {
      cancelToken: cancelToken?.token,
      params: {
        instanceCode: devMode ? instance : undefined
      }
    }
  );
}
