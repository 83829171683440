import { HeartRateZone } from '../heart-rate';
import styled from 'styled-components';
import { Fragment, useCallback, useMemo } from 'react';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Group } from '@visx/group';
import { AxisLeft, AxisRight, TickRendererProps } from '@visx/axis';
import { Bar as VisxBar, BarGroupHorizontal } from '@visx/shape';
import { getStringWidth, TextProps } from '@visx/text';
import { animated, useSpring } from 'react-spring';

import { getSSRStringWidth } from '../reporting/utils/get-ssr-string-width';
import { ANIMATION_DURATION } from '../reporting/const';
import { isBrowser } from '../helpers';

export interface ZoneData extends HeartRateZone {
  time: number;
  name: string | null;
}

export type { TickRendererProps } from '@visx/axis';
export interface ZonesProps {
  data: ZoneData[];
  height: number;
  width: number;
  formatZoneLabel(zone: number): string;
  zoneLabelComponent?: (props: TickRendererProps) => JSX.Element;
  formatDuration?(value: number): string;
}

const AnimatedBar = animated(VisxBar);

const ChartWrapper = styled.div`
  position: relative;
`;

const margin = {
  top: 10,
  bottom: 0
};

const labelFont = {
  fontSize: 14,
  fontFamily: 'Ubuntu'
};

const AXIS_GAP = 20;
const NATIVE_GAP = 10;

const TICK_FORMAT: TextProps = {
  ...labelFont,
  fill: '#4A4A4A',
  fontSize: 14,
  textAnchor: 'end',
  verticalAnchor: 'middle',
  transform: `translate(-${NATIVE_GAP} 0)`
};

const TICK_FORMAT_RIGHT: TextProps = {
  ...TICK_FORMAT,
  fontFamily: 'Roboto Mono',
  transform: undefined,
  textAnchor: 'start',
  width: 70
};

const TICK_FORMAT_PERCENTS: TextProps = {
  ...TICK_FORMAT_RIGHT,
  fill: '#d4d6d9',
  width: 30
};

export function Zones(props: ZonesProps): JSX.Element {
  const {
    width,
    height,
    data,
    formatZoneLabel,
    formatDuration,
    zoneLabelComponent
  } = props;

  const total = useMemo(
    () => data.reduce((sum, item) => sum + item.time, 0),
    [data]
  );

  const categories = useMemo(
    () => data.map(d => d.id).sort((a, b) => b - a),
    [data]
  );

  const longestLabelWidth = useMemo(
    () =>
      Math.ceil(
        Math.max(
          ...categories.map(
            c =>
              getStringWidth(formatZoneLabel(c), labelFont) ||
              (isBrowser ? 0 : getSSRStringWidth(formatZoneLabel(c)))
          )
        )
      ),
    [categories, formatZoneLabel]
  );

  const timeInZoneLabel = useCallback(
    (zone: number): string => {
      const zoneData = data.find(d => d.id === zone);
      if (zoneData) {
        return formatDuration
          ? `${formatDuration(zoneData.time)}`
          : `${zoneData.time}`;
      }

      return '';
    },
    [data, formatDuration]
  );

  const percentsInZoneLabel = useCallback(
    (zone: number): string => {
      const zoneData = data.find(d => d.id === zone);
      const allInOne = data.find(d => d.time === total);

      if (zoneData) {
        const percents = Math.round(zoneData.time / (total / 100));

        return `${percents < 10 ? '\u00A0' : ''}${
          percents < 100 && allInOne ? '\u00A0' : ''
        }${percents}%`;
      }

      return '';
    },
    [data, total]
  );

  const leftLabelWidth = Math.min(longestLabelWidth, 85);

  const xMax =
    width -
    leftLabelWidth -
    Number(TICK_FORMAT_RIGHT.width) -
    Number(TICK_FORMAT_PERCENTS.width) -
    3 * AXIS_GAP;
  const yMax = height - margin.top - margin.bottom;

  const yScale = useMemo(
    () =>
      scaleBand<number>({
        range: [0, yMax],
        round: false,
        domain: categories,
        padding: 0.2
      }),
    [categories, yMax]
  );
  const keysScale = useMemo(
    () =>
      scaleBand<string>({
        domain: ['zone'],
        padding: 0.1
      }).rangeRound([0, yScale.bandwidth()]),
    [yScale]
  );

  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax],
        round: true,
        domain: [0, total]
      }),
    [xMax, total]
  );

  const { scale: animation } = useSpring({
    from: { scale: isBrowser ? 0 : 1 },
    to: { scale: 1 },
    config: {
      duration: ANIMATION_DURATION
    }
  });

  return (
    <ChartWrapper>
      <svg width={width} height={height}>
        <Group left={AXIS_GAP + leftLabelWidth} top={margin.top}>
          <AxisLeft
            hideAxisLine
            hideTicks
            scale={yScale}
            tickLabelProps={id => ({
              ...TICK_FORMAT,
              width: leftLabelWidth,
              zoneId: id
            })}
            tickFormat={formatZoneLabel}
            tickComponent={zoneLabelComponent}
            numTicks={categories.length}
            left={0}
          />
          <AxisRight
            hideAxisLine
            hideTicks
            scale={yScale}
            tickLabelProps={() => TICK_FORMAT_PERCENTS}
            tickFormat={percentsInZoneLabel}
            numTicks={categories.length}
            left={
              width -
              leftLabelWidth -
              Number(TICK_FORMAT_PERCENTS.width) -
              Number(TICK_FORMAT_RIGHT.width) -
              2 * AXIS_GAP -
              NATIVE_GAP
            }
          />
          <AxisRight
            hideAxisLine
            hideTicks
            scale={yScale}
            tickLabelProps={() => TICK_FORMAT_RIGHT}
            tickFormat={timeInZoneLabel}
            numTicks={categories.length}
            left={
              width -
              leftLabelWidth -
              Number(TICK_FORMAT_RIGHT.width) -
              AXIS_GAP -
              NATIVE_GAP
            }
          />
          <BarGroupHorizontal
            data={data}
            color={() => '#f5f6f8'}
            keys={['time']}
            y0={item => item.id}
            xScale={xScale}
            y0Scale={yScale}
            y1Scale={keysScale}
            width={xMax}
          >
            {barGroups =>
              barGroups.map(group => (
                <Group key={group.index} top={group.y0}>
                  {group.bars.map(bar => {
                    return (
                      <Fragment key={bar.index}>
                        <rect
                          x={xScale(0)}
                          y={bar.y}
                          fill="#f5f6f8"
                          height={bar.height}
                          width={xScale(xScale.domain()[1]) - xScale(0)}
                          rx={4}
                        />
                        {bar.value && (
                          <AnimatedBar
                            key={bar.key}
                            x={xScale(0)}
                            y={bar.y}
                            width={animation.to(
                              s => s * Math.abs(xScale(bar.value) - xScale(0))
                            )}
                            height={bar.height}
                            fill="#000"
                            rx={4}
                            pointerEvents="none"
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </Group>
              ))
            }
          </BarGroupHorizontal>
        </Group>
      </svg>
    </ChartWrapper>
  );
}
