import { useScale } from './use-scale';
import { useMemo } from 'react';
import { getSSRStringWidth } from '../../reporting/utils/get-ssr-string-width';
import { getRotatedHeight } from '../../reporting/utils/get-rotated-height';
import { AXIS_TICK_LABEL_OFFSET, LABEL_FONT_SIZE } from '../../reporting/const';
import { AxisValue, XAxisConfig } from '../../reporting/types';

export function useXAxisHeight(
  xScale: ReturnType<typeof useScale>,
  xAxisConfig: XAxisConfig<AxisValue> | undefined
): number {
  return useMemo(() => {
    if (!xAxisConfig || !xAxisConfig.showTickLabels) {
      return 0;
    }

    const baseHeight = xAxisConfig.hideAxisLine ? 0 : 1;

    const ticks = 'ticks' in xScale ? xScale.ticks() : xScale.domain();
    const tickHeight = Math.max(
      ...ticks.map(tick => {
        const value = xAxisConfig.formatTick(tick);
        const width = getSSRStringWidth(value);
        return getRotatedHeight(
          width,
          LABEL_FONT_SIZE,
          xAxisConfig.tickAngle || 0
        );
      })
    );

    return baseHeight + tickHeight + AXIS_TICK_LABEL_OFFSET;
  }, [xAxisConfig, xScale]);
}
