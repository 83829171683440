import { css, styled } from '@yarmill/components';

import { RIGHT_PANEL_WIDTH } from './right-panel';

export const SIDEBAR_WIDTH = 280;
export const AppContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  align-items: stretch;
  background-color: ${({ theme }) => theme.color.neutralPlateDark};
`;

export const PageContentWrapper = styled.div.attrs<{
  readonly isSidebarVisible?: boolean;
}>(({ isSidebarVisible }) => ({
  style: {
    marginLeft: isSidebarVisible ? SIDEBAR_WIDTH : 0
  }
}))<{
  readonly isSidebarVisible?: boolean;
}>`
  display: ${({ isSidebarVisible }) => (isSidebarVisible ? 'none' : 'flex')};
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow: hidden;

  @media (min-width: 768px) {
    display: flex;
    padding: ${({ theme }) => theme.size.x2};
    column-gap: ${({ theme }) => theme.size.x2};
    transition-property: padding, column-gap, margin;
    transition-timing-function: ease;
    transition-duration: 250ms;
  }
`;

export const MainPageContentContainer = styled.main.attrs<{
  readonly isSidebarVisible?: boolean;
  readonly windowWidth?: number;
}>(({ isSidebarVisible, theme, windowWidth = 0 }) => ({
  style: {
    marginRight:
      isSidebarVisible && windowWidth >= 768
        ? RIGHT_PANEL_WIDTH + parseInt(theme.size.x2)
        : 0
  }
}))<{
  readonly isSidebarVisible?: boolean;
  readonly windowWidth?: number;
}>`
  display: flex;
  flex-shrink: initial;
  flex-basis: initial;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  position: relative;
  overflow: auto;
  -webkit-box-align: stretch;
  place-items: stretch;
  background-color: ${({ theme }) =>
    theme.dark ? theme.color.text : theme.color.neutralPlate};
  scrollbar-gutter: auto;
  transition: margin 250ms ease-out;

  @media (min-width: 768px) {
    border-radius: ${({ theme }) => theme.borderRadius.x2};
  }
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
  position: relative;
  padding: ${({ theme }) => theme.size.x4} 0;
  background-color: ${({ theme }) =>
    theme.dark ? theme.color.text : theme.color.white};
`;

export const PrimarySidebarContainer = styled.div.attrs<{
  readonly isVisible?: boolean;
  readonly windowWidth: number;
}>(({ isVisible, windowWidth }) => ({
  style: {
    left: isVisible ? 0 : -(windowWidth < 768 ? windowWidth : SIDEBAR_WIDTH)
  }
}))<{
  readonly isVisible?: boolean;
  readonly windowWidth: number;
}>`
  position: fixed;
  width: 100%;
  left: -100%;
  top: 0;
  bottom: 0;

  @media (min-width: 768px) {
    transition: left 250ms ease-out;
    width: ${SIDEBAR_WIDTH}px;
    left: -${SIDEBAR_WIDTH}px;
    padding: ${({ theme }) => theme.size.x2} 0;
  }

  @media print {
    ${({ isVisible }) =>
      !isVisible &&
      css`
        display: none;
      `}
  }
`;
