export function parseHTMLToSting(
  HTMLElement: string,
  type: DOMParserSupportedType = 'text/html'
): string {
  try {
    const domParser = new DOMParser();

    return domParser.parseFromString(HTMLElement, type).body.innerText;
  } catch (e: unknown) {
    console.error(e);

    return '';
  }
}
