import {
  AddGroupButtonWrapper,
  Button,
  ButtonAppearance,
  SecondarySidebarDropdownToggle,
  SecondarySidebarListWrapper,
  SidebarContainer,
  SidebarLinkContent,
  SidebarList
} from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import { Tippy } from '../components/tippy/tippy';
import { trackCreateGroupClick } from '../google-analytics/utils';
import { SidebarItem } from './sidebar-item';
import { useRootStore } from '../app/root-store-context';
import { observer } from 'mobx-react-lite';
import { useCurrentUserStore } from '../users/hooks';
import { useLayer } from '../layer-manager/hooks';
import { LayerPortal } from '../layer-manager/layer-portal';
import { AddGroup } from './add-group';
import { useGroup } from './hooks';
import { useCollapsible } from '../app/hooks';

export interface SecondarySidebarProps {
  currentGroupId?: number;
}

export const SecondarySidebar = observer(function SecondarySidebar(
  props: SecondarySidebarProps
): JSX.Element {
  const { currentGroupId } = props;
  const rootStore = useRootStore();
  const groupsStore = rootStore.groupsStore;
  const currentUser = useCurrentUserStore();
  const createGroupLayer = useLayer('full-screen');
  const selectedGroup = useGroup(currentGroupId ?? -1);
  const [isOpened, openList, closeList] = useCollapsible<HTMLDivElement>();

  const handleButtonClick = (): void => {
    trackCreateGroupClick();
    if (!createGroupLayer.isOpened) {
      createGroupLayer.open();
    }
  };

  return (
    <>
      <SidebarContainer $stickyButton data-cy="secondary-sidebar">
        <SecondarySidebarDropdownToggle
          type="button"
          onClick={() => (isOpened ? closeList() : openList())}
        >
          <SidebarLinkContent
            secondaryInfo={
              <FormattedMessage
                id="settings.sidebar.groups.activeAthletes"
                values={{ count: selectedGroup?.athletes.length ?? 0 }}
              />
            }
          >
            {selectedGroup?.name}
          </SidebarLinkContent>
        </SecondarySidebarDropdownToggle>
        <SecondarySidebarListWrapper isOpened={isOpened}>
          <SidebarList>
            {groupsStore.sortedGroups.map(group => (
              <SidebarItem
                key={group.id}
                isActive={group.id === currentGroupId}
                group={group}
                onClick={closeList}
              />
            ))}
          </SidebarList>
        </SecondarySidebarListWrapper>
        <Tippy
          tooltipContent="settings.groups.tooltip.adminAction.text"
          isEnabled={!currentUser.isAdmin}
          Wrapper={AddGroupButtonWrapper}
        >
          <Button
            appearance={ButtonAppearance.Primary}
            inverted
            noShadow
            onClick={handleButtonClick}
            disabled={!currentUser.isAdmin}
            data-cy="add-group"
          >
            <FormattedMessage id="settings.groups.sidebar.addGroupButton" />
          </Button>
        </Tippy>
      </SidebarContainer>
      <LayerPortal
        layerHandle={createGroupLayer}
        getContent={layer => <AddGroup layer={layer} />}
      />
    </>
  );
});
