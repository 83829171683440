import { FormattedMessage } from 'react-intl';
import {
  ListChildComponentProps,
  ListOnItemsRenderedProps,
  VariableSizeList as List
} from 'react-window';
import styled from 'styled-components';

import { ChartWidthProvider, Text, TextSize } from '@yarmill/components';

import { Video } from './video';
import { observer } from 'mobx-react-lite';
import { useVideoSelectorStore } from './video-selector-context';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { AsyncStatus } from '../api/mobx/request-store';
import { encodeHtmlEntities } from '../utils/encode-html-entities';

const StyledHeading = styled.div`
  color: #9a9a9a;
  margin-top: 32px;
  margin-bottom: 8px;
`;

const VideoListWrapper = styled.div`
  position: relative;
  height: 100%;
  height: -webkit-fill-available;
`;

interface VideoListProps {
  readonly containerRef: RefObject<HTMLDivElement>;
}
export const VideoList = observer(function VideoList({
  containerRef
}: VideoListProps): JSX.Element {
  const videoSelectorStore = useVideoSelectorStore();
  const videos = videoSelectorStore.videos;
  const hasAllVideos = videoSelectorStore.hasAllVideos;
  const searchQuery = videoSelectorStore.highlightedSearchTerm;
  const listRef = useRef<List>(null);
  const headingRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);

  const rowRenderer = useCallback(
    (props: ListChildComponentProps): JSX.Element => {
      const { index, style } = props;
      const video =
        videoSelectorStore.videos.length > index
          ? videoSelectorStore.videos[index]
          : undefined;

      return <Video key={video?.FileId || index} video={video} style={style} />;
    },
    [videoSelectorStore]
  );

  const onRowsRendered = useCallback(
    (info: ListOnItemsRenderedProps): void => {
      if (
        !videoSelectorStore.hasAllVideos &&
        videoSelectorStore.status !== AsyncStatus.pending &&
        videoSelectorStore.status !== AsyncStatus.idle &&
        info.overscanStopIndex > videoSelectorStore.videos.length &&
        videoSelectorStore.videos.length
      ) {
        videoSelectorStore.increaseOffset();
      }
    },
    [videoSelectorStore]
  );

  useEffect(() => {
    if (videos.length && listRef.current) {
      listRef.current.resetAfterIndex(0, false);
    }
  }, [videos.length]);

  useEffect(() => {
    const heading = headingRef.current;
    const container = containerRef.current;
    if (heading && container) {
      const CONTAINER_PADDING = 32;
      const headingRect = heading.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      setHeight(containerRect.bottom - headingRect.bottom - CONTAINER_PADDING);
    }
  }, [containerRef]);

  return (
    <>
      <StyledHeading ref={headingRef}>
        {!searchQuery && (
          <FormattedMessage id="videoSelector.list.results">
            {msg => (
              <Text size={TextSize.s12} inheritColor>
                {msg}
              </Text>
            )}
          </FormattedMessage>
        )}
      </StyledHeading>
      {hasAllVideos && videos.length === 0 && searchQuery ? (
        <Text size={TextSize.s14}>
          <FormattedMessage
            id="videoSelector.list.empty"
            values={{
              video: encodeHtmlEntities(searchQuery),
              strong: content => <strong>{content}</strong>
            }}
          />
        </Text>
      ) : (
        <VideoListWrapper>
          <ChartWidthProvider setWidth={setWidth} />
          {width && height && (
            <List
              ref={listRef}
              width={width}
              height={height}
              itemCount={hasAllVideos ? videos.length : videos.length + 50}
              itemSize={() => 38}
              onItemsRendered={onRowsRendered}
            >
              {rowRenderer}
            </List>
          )}
        </VideoListWrapper>
      )}
    </>
  );
});
