import { createElement } from 'react';
export var CrystalBallAnimating = function () {
  return createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 24 24',
      strokeWidth: '1.5',
      stroke: 'currentColor',
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      role: 'img'
    },
    createElement(
      'defs',
      null,
      createElement(
        'linearGradient',
        { id: 'a', x1: '0%', y1: '0%', x2: '100%', y2: '0%' },
        createElement(
          'stop',
          { offset: '0%', 'stop-color': '#F61C8E' },
          createElement('animate', {
            attributeName: 'stop-color',
            values: '#F61C8E; #FFFFFF; #F61C8E',
            dur: '1s',
            repeatCount: 'indefinite'
          })
        ),
        createElement(
          'stop',
          { offset: '100%', 'stop-color': '#FFF' },
          createElement('animate', {
            attributeName: 'stop-color',
            values: '#FFFFFF; #F61C8E; #FFFFFF',
            dur: '2s',
            repeatCount: 'indefinite'
          })
        )
      )
    ),
    createElement('path', {
      d: 'M6.73 17.018a8 8 0 1 1 10.54 0',
      fill: 'url(#a)'
    }),
    createElement('path', {
      d: 'M5 19a2 2 0 0 0 2 2h10a2 2 0 1 0 0-4H7a2 2 0 0 0-2 2zM11 7a3 3 0 0 0-3 3'
    })
  );
};
