import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { Objective } from '../types';
import { SeasonId } from '../../seasons/types';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';

export interface GetObjectivesForUserRequestParams {
  userId: number | undefined;
  seasonId: SeasonId;
}

export type GetObjectivesForUserResponse = Objective[];

export function getObjectivesForUser(
  params: GetObjectivesForUserRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<GetObjectivesForUserResponse> {
  return axios.get(
    `${coreApiTarget}/api/okrs/userId/${params.userId}/seasonId/${params.seasonId}`,
    {
      cancelToken: cancelToken?.token,
      params: {
        instanceCode: devMode ? instance : undefined
      }
    }
  );
}
