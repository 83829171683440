import { PlannerStore } from './mobx/planner-store';
import {
  memo,
  useContext,
  createContext,
  PropsWithChildren,
  useRef,
  useEffect
} from 'react';
import { useRootStore } from '../app/root-store-context';

export const PlannerStoreContext = createContext<PlannerStore | undefined>(
  undefined
);

export function usePlannerStore(): PlannerStore {
  const diaryStore = useContext(PlannerStoreContext);
  if (!diaryStore) {
    throw new Error('Planner Store not available');
  }

  return diaryStore;
}

export function InternalPlannerStoreContextProvider(
  props: PropsWithChildren<{}>
): JSX.Element {
  const rootStore = useRootStore();
  const plannerStore = useRef(new PlannerStore(rootStore)).current;

  useEffect(() => {
    return () => {
      plannerStore.disposeReactions();
    };
  }, [plannerStore]);

  return (
    <PlannerStoreContext.Provider value={plannerStore}>
      {props.children}
    </PlannerStoreContext.Provider>
  );
}

export const PlannerStoreContextProvider = memo(
  InternalPlannerStoreContextProvider
);
