import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  FieldSet,
  FormControlVariant,
  FullScreenLayerCloseButtonWrapper,
  Icon,
  IconSize,
  Text,
  TextSize,
  TextTag
} from '@yarmill/components';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { AsyncState } from '../components/async-button/async-button';
import {
  trackAddUserInvitation,
  trackInviteUsersToAppSubmit,
  trackRemoveUserInvitation
} from '../google-analytics/utils';
import { GroupSelect } from '../groups/group-select';
import { InviteUserRow } from './invite-user-row';
import { inviteNewUsers } from './api/invite-new-users';
import { UserInvitation } from './types';
import {
  getButtonAppearance,
  getSubmitButtonIcon,
  isEmptyInvitation,
  isValidInvitation,
  toastInviteUsers
} from './utils';
import { assocPath, dissocPath, mapObjIndexed, pipe, values } from 'ramda';
import { withRootStore, WithRootStoreProps } from '../app/root-store-context';
import { toast } from '../components/toast-message';
import { observer } from 'mobx-react-lite';
import { CloseButton } from '../components/close-button/close-button';

export interface InviteUsersProps extends RouteComponentProps {
  hideForm: () => void;
}

export interface InviteUsersState extends AsyncState {
  nextId: number;
  groupIds: number[];
  invitations: { [key: string]: UserInvitation };
}

const StyledInviteUserRowLabels = styled.div`
  display: none;
  @media (min-width: 576px) {
    display: grid;
    grid-template-columns: 4fr 3fr 3fr 2fr 1fr;
    position: relative;
  }
`;

const StyledHeadline = styled(Text)`
  display: flex;
  align-items: center;
  column-gap: 16px;
  text-align: left;
`;

const StyledAddNewInvitationWrapper = styled.div`
  flex: 0 0 100%;
  max-width: 100%;

  button {
    min-height: 26px;
    color: #4a90e2;
    font-size: 16px;
    padding: 0;

    &:hover {
      color: #396fad;
      text-decoration: none;
    }
  }
`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 0 50px 50px;
  position: relative;

  @media (min-width: 576px) {
    padding: 50px calc(10% + 50px);
  }
`;

const StyledGroupSelectWrapper = styled.div`
  margin: 20px 0 10px;
`;

const StyledSelectGroupContainer = styled.div`
  display: grid;
  column-gap: 10px;
  @media (min-width: 576px) {
    grid-template-columns: 10fr 1fr;
  }

  @media (min-width: 766px) {
    grid-template-columns: 12fr 1fr;
  }
`;

const StyledInviteButtonWrapper = styled.div`
  margin: 30px 0 20px;

  text-align: right;
`;

const EMPTY_INVITATION: UserInvitation = {
  Email: '',
  FirstName: '',
  LastName: '',
  Role: 'athlete'
};

class InviteUsers extends React.PureComponent<
  InviteUsersProps & WithRootStoreProps,
  InviteUsersState
> {
  public constructor(props: InviteUsersProps & WithRootStoreProps) {
    super(props);
    this.state = {
      invitations: { 0: { ...EMPTY_INVITATION } },
      groupIds: [],
      nextId: 1
    };
  }

  public componentDidMount(): void {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  public componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  public render(): JSX.Element {
    const { rootStore } = this.props;
    const instanceName = rootStore.configStore.appName;
    const { invitations, loading } = this.state;
    const isSubmitDisabled =
      Object.keys(invitations).length === 0 ||
      this.hasInvalidInvitation() ||
      this.allEmptyInvitations();

    return (
      <StyledFormWrapper>
        <StyledHeadline size={TextSize.s24}>
          <span>
            <FormattedMessage
              id="settings.users.inviteUsersHeading"
              values={{ instance: <strong>{instanceName}</strong> }}
            />
          </span>
          <Icon size={IconSize.s24}>
            <ExternalIcon name="Send" />
          </Icon>
        </StyledHeadline>
        <form onSubmit={this.submit}>
          <FieldSet disabled={loading}>
            {this.renderHeader()}
            {values(
              mapObjIndexed(
                (invitation: UserInvitation, id: string) => (
                  <InviteUserRow
                    key={id}
                    id={id}
                    invitation={invitation}
                    invitations={invitations}
                    onChange={this.onUserFieldChange}
                    onAddClick={this.addInvitation}
                    onDeleteClick={this.deleteInvitation}
                  />
                ),
                invitations
              )
            )}
            <div>
              <StyledAddNewInvitationWrapper>
                <Button
                  type="button"
                  onClick={this.addInvitation}
                  appearance={ButtonAppearance.Link}
                >
                  <Icon>
                    <ExternalIcon name="CirclePlus" />
                  </Icon>
                  &nbsp;
                  <FormattedMessage id="settings.users.inviteNewRow" />
                </Button>
              </StyledAddNewInvitationWrapper>
            </div>
            <StyledSelectGroupContainer>
              <div>
                <StyledGroupSelectWrapper>
                  <Text size={TextSize.s14}>
                    <FormattedMessage id="settings.users.inviteUsersToGroups" />
                  </Text>
                  <GroupSelect onChange={this.onGroupChange} />
                </StyledGroupSelectWrapper>
                <StyledInviteButtonWrapper>
                  <Button
                    type={'submit'}
                    appearance={getButtonAppearance(this.state)}
                    variant={FormControlVariant.big}
                    wide
                    disabled={isSubmitDisabled}
                    data-cy="invite-new-submit"
                  >
                    {getSubmitButtonIcon(this.state)}
                    <FormattedMessage id="settings.users.inviteNewSubmit" />
                  </Button>
                </StyledInviteButtonWrapper>
              </div>
              <div />
            </StyledSelectGroupContainer>
          </FieldSet>
        </form>
        <FullScreenLayerCloseButtonWrapper>
          <CloseButton onClick={this.props.hideForm} />
        </FullScreenLayerCloseButtonWrapper>
      </StyledFormWrapper>
    );
  }

  private readonly handleKeyDown = (e: KeyboardEvent) => {
    if (
      e.key === 'Escape' &&
      !document.activeElement?.id.includes('group-select')
    ) {
      this.props.hideForm();
    }
  };

  private readonly renderHeader = (): JSX.Element => (
    <StyledInviteUserRowLabels>
      <Text size={TextSize.s14} tag={TextTag.div}>
        <FormattedMessage id="settings.users.table.header.email" />
      </Text>
      <Text size={TextSize.s14} tag={TextTag.div}>
        <FormattedMessage id="settings.users.table.header.firstName" />
      </Text>
      <Text size={TextSize.s14} tag={TextTag.div}>
        <FormattedMessage id="settings.users.table.header.lastName" />
      </Text>
      <Text size={TextSize.s14} tag={TextTag.div}>
        <FormattedMessage id="settings.users.table.header.role" />
      </Text>
      <div />
    </StyledInviteUserRowLabels>
  );

  private readonly onGroupChange = (
    groupIds: { label: string; value: number }[] | null
  ): void => {
    const val = groupIds ? groupIds.map(option => option.value) : [];

    this.setState(s => ({
      ...s,
      groupIds: val
    }));
  };

  private readonly onUserFieldChange = (
    id: string,
    field: string,
    value: string | number[]
  ): void => {
    this.setState(
      s => assocPath(['invitations', id, field], value, s),
      () => {
        const validInvitations = values(this.state.invitations).filter(
          isValidInvitation
        );
        const invitations = values(this.state.invitations);

        if (validInvitations.length === invitations.length) {
          this.addInvitation();
        }
      }
    );
  };

  private readonly addInvitation = (): void => {
    const { nextId } = this.state;
    trackAddUserInvitation();
    const updateState = pipe(
      assocPath(['invitations', nextId], { ...EMPTY_INVITATION }),
      assocPath(['nextId'], nextId + 1)
    );
    this.setState(updateState);
  };

  private readonly deleteInvitation = (id: string): void => {
    trackRemoveUserInvitation();
    this.setState(
      (s: InviteUsersState): InviteUsersState =>
        dissocPath(['invitations', String(id)], s)
    );
  };

  private readonly submit = async (e: React.FormEvent): Promise<void> => {
    const { invitations, groupIds } = this.state;
    const { rootStore, hideForm } = this.props;

    e.preventDefault();
    trackInviteUsersToAppSubmit();
    const newUsersInv = values(invitations).filter(isValidInvitation);

    this.setState(s => ({ ...s, loading: true }));

    const request = rootStore.requestsStore.createRequest(() =>
      inviteNewUsers({
        groupIds,
        UserRegistrationInfo: newUsersInv
      })
    );

    const response = await request.getResponse();

    if (response) {
      void rootStore.currentUserStore.loadPermissions();
      rootStore.usersStore.setUsers(response);
      groupIds.forEach(groupId => {
        const group = rootStore.groupsStore.getGroupById(groupId);
        if (group) {
          response.forEach(user => {
            const userStore = rootStore.usersStore.getUserById(user.UserId);
            if (userStore) {
              group.addInvitedUser(userStore);
            }
            userStore?.groups.push(group);
          });
        }
      });
      toastInviteUsers(newUsersInv);
      hideForm();
    } else {
      toast('toast.error.inviteUser', 'error');
      this.setState(s => ({
        ...s,
        loaded: true,
        loading: false,
        error: true
      }));
    }
  };

  private readonly hasInvalidInvitation = (): boolean =>
    values(this.state.invitations).some((invitation: UserInvitation) => {
      if (isValidInvitation(invitation)) {
        return false;
      }

      return !isEmptyInvitation(invitation);
    });

  private readonly allEmptyInvitations = (): boolean =>
    values(this.state.invitations).every(invitation =>
      isEmptyInvitation(invitation)
    );
}
const WithRootStore = observer(withRootStore(InviteUsers));
const WithRouter = withRouter(WithRootStore);
export { WithRouter as InviteUsers };
