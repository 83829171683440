import Axios from 'axios';
import { apiTarget } from './api-mapping';

const axios = Axios.create({
  baseURL: apiTarget,
  headers: {
    Accept: 'application/json'
  }
});

export function createAuthToken(token: string): string {
  return `Bearer ${token}`;
}

export function setAuthToken(token: string | null) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = createAuthToken(token);
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

export { axios };
