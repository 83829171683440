import { GoalsStore } from './mobx/goals-store';
import { useDiaryStore } from '../diary/diary-store-context';
import { SeasonGoalsStore } from './mobx/season-goals-store';
import { useCallback } from 'react';
import { getDiaryRouteLink, ROUTE_DATE_FORMAT } from '../diary/utils';
import moment from 'moment';
import { GoalAttributeStore } from './mobx/goal-attribute-store';
import { useFocusedDayService } from '../diary/hooks';

export function useGoalsStore(): GoalsStore | null {
  const diaryStore = useDiaryStore();
  return diaryStore.goalsStore;
}

export function useSeasonGoalsStore(): SeasonGoalsStore | null {
  const diaryStore = useDiaryStore();
  return diaryStore.seasonGoalsStore;
}

export function useGoalLabelLinkAttributes(attribute: GoalAttributeStore): {
  to: () => string;
  onClick: () => void;
} {
  const focusedDayService = useFocusedDayService();

  const to = useCallback(() => {
    const { diaryStore } = attribute;
    const { diaryType, athleteId, groupId } = diaryStore;

    if (!groupId) {
      return '';
    }

    return getDiaryRouteLink({
      viewType: 'week',
      diaryType,
      athleteId,
      groupId,
      week: moment(attribute.trainingDayStore.currentDate).format(
        ROUTE_DATE_FORMAT
      )
    });
  }, [attribute]);

  const onClick = useCallback(() => {
    focusedDayService.setFocusedDay(-1);
    setTimeout(
      () =>
        focusedDayService.setFocusedDay(
          moment(attribute.trainingDayStore.currentDate).isoWeekday() - 1
        ),
      0
    );
  }, [attribute, focusedDayService]);

  return { to, onClick };
}
