import { useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Filter as IFilter } from './types';
import {
  useFilterChangeHandler,
  useLegacyAnalyticsStore,
  useUrlFilters
} from './hooks';
import { useCurrentUserStore } from '../users/hooks';
import { useAnalyticsRouteParams } from './analytics-route-params-context';
import { observer } from 'mobx-react-lite';
import { ReportingFilters, SelectBox } from '@yarmill/components';

export const Filters = observer(function Filters(): JSX.Element | null {
  const { activeDashboard } = useAnalyticsRouteParams();
  const dashboard = useLegacyAnalyticsStore().getDashboard(activeDashboard);
  const currentUser = useCurrentUserStore();
  const urlFilters = useUrlFilters();
  const onChange = useFilterChangeHandler();
  const intl = useIntl();

  useEffect(() => {
    dashboard?.setFilters(urlFilters);
  }, [urlFilters, dashboard]);

  if (!currentUser || currentUser.isAthlete) {
    return null;
  }

  return (
    <ReportingFilters
      headline={intl.formatMessage({ id: 'analytics.filters' })}
      filters={dashboard?.filters.map(filter => (
        <Filter
          filter={filter}
          onChange={onChange}
          key={`${activeDashboard}_${filter.Ident}`}
          value={urlFilters[filter.Ident] || filter.Value || String(-1)}
        />
      ))}
    ></ReportingFilters>
  );
});

interface FilterProps {
  filter: IFilter;
  value?: string | number;
  onChange(id: string, value: string): void;
}

const Filter = observer(function Filter(props: FilterProps): JSX.Element {
  const {
    filter: { Ident, Options, Title },
    value,
    onChange
  } = props;
  const intl = useIntl();

  const handleChange = useCallback(
    (newValue: string): void => {
      onChange(Ident, newValue);
    },
    [Ident, onChange]
  );

  const options = Options.map(val => ({
    label: val.Title,
    value: val.Value
  }));

  return (
    <div>
      <SelectBox
        id={Ident}
        options={options}
        onChange={(v: { label: string; value: string }) =>
          handleChange(v.value)
        }
        label={intl.formatMessage({ id: Title })}
        value={options.find(o => o.value === value)}
        noError
        disablePortal
      />
    </div>
  );
});
