export const LABEL_FONT_SIZE = 11;
export const LABEL_OFFSET = 8;
export const AXIS_TICK_LABEL_OFFSET = 12;
export const ANIMATION_DURATION = 500;
export const AXIS_TICK_FONT_STYLES = {
  fill: '#4A4A4A',
  fontSize: LABEL_FONT_SIZE
};
export const TOOLTIP_TIMEOUT = 50;
export const DEFAULT_MARKER_SIZE = 4;
