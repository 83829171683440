import { scaleBand, ScaleTypeToD3Scale } from '@visx/scale';
import { useMemo } from 'react';
import { Group } from '@visx/group';
import { BarGroupHorizontal } from '@visx/shape';
import { useSpring } from 'react-spring';
import { HorizontalBarGroupChart } from './utils';
import { HorizontalBar } from './horizontal-bar';
import { isBrowser } from '../helpers';
import { ANIMATION_DURATION } from '../reporting/const';
import { DataItem } from '../reporting/types';

export interface BarGroupProps<T extends DataItem> {
  config: HorizontalBarGroupChart<T>;
  yScale: ScaleTypeToD3Scale<string, string>['band'];
  xScale: ScaleTypeToD3Scale<number>['linear'];
  data: T[];
  xMax: number;
}

const noop = () => '';

export function HorizontalBarGroup<T extends DataItem>(
  props: BarGroupProps<T>
): JSX.Element {
  const { data, config, yScale, xScale, xMax } = props;

  const yBandwidth = yScale.bandwidth();
  const keysScale = useMemo(
    () =>
      scaleBand<string>({
        domain: config.keys,
        padding: 0.1
      }).rangeRound([0, yBandwidth]),
    [config.keys, yBandwidth]
  );

  const { scale: animation } = useSpring({
    from: { scale: isBrowser ? 0 : 1 },
    to: { scale: 1 },
    config: {
      duration: ANIMATION_DURATION
    }
  });

  return (
    <BarGroupHorizontal
      data={data}
      keys={config.keys}
      width={xMax}
      y0={config.getYValue}
      y0Scale={yScale}
      y1Scale={keysScale}
      xScale={xScale}
      color={noop}
    >
      {barGroups =>
        barGroups.map(barGroup => (
          <Group
            key={`bar-group-${barGroup.index}-${barGroup.y0}`}
            top={barGroup.y0}
          >
            {barGroup.bars
              .filter(bar => bar.value)
              .map(bar => (
                <HorizontalBar
                  key={bar.key}
                  xValue={bar.value}
                  yValue={bar.key}
                  animation={animation}
                  xScale={xScale}
                  yScale={yScale}
                  xMax={xMax}
                  color={config.getColor(data[barGroup.index], bar.key)}
                  barY={
                    bar.y +
                    ((1 - config.getBarWidth(data[barGroup.index], bar.key)) *
                      bar.height) /
                      2
                  }
                  barHeight={
                    bar.height *
                    config.getBarWidth(data[barGroup.index], bar.key)
                  }
                  showLabel={config.getShowLabels(data[barGroup.index])}
                  labelPosition={config.labelPosition}
                  formatLabelValue={val =>
                    config.formatLabelValue?.(val, bar.key)
                  }
                  labelColor={config.getLabelColor(
                    data[barGroup.index],
                    bar.key
                  )}
                  opacity={
                    config.getOpacity(data[barGroup.index], bar.key) ?? 1
                  }
                />
              ))}
          </Group>
        ))
      }
    </BarGroupHorizontal>
  );
}
