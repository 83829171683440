import {
  action,
  computed,
  observable,
  ObservableMap,
  toJS,
  makeObservable
} from 'mobx';
import { SortConfig, EvidenceTableFilters } from '../table/types';

export class TableFiltersStore {
  @observable
  private _sortConfig: SortConfig | null = null;

  @observable
  private _filters: EvidenceTableFilters = new Map();

  constructor(defaultFilters?: EvidenceTableFilters) {
    makeObservable(this);
    (this.filters as ObservableMap).merge(defaultFilters);
  }

  @computed.struct
  public get sortConfig(): SortConfig | null {
    return this._sortConfig;
  }

  public get filters(): EvidenceTableFilters {
    return this._filters;
  }

  public getFilter(
    attributeId: string | number
  ): string | string[] | undefined {
    return this._filters.get(attributeId);
  }

  @computed.struct
  public get jsFilters(): EvidenceTableFilters {
    return toJS(this.filters as ObservableMap);
  }

  @computed
  public get apiFilters(): Record<string, string> {
    return Object.fromEntries(
      Array.from(this._filters.entries())
        .map(([key, value]) => [key, value])
        .filter(([_, value]) => Boolean(value))
    );
  }

  @action
  public readonly handleFilterChange = (
    attributeId: string | number,
    value: string | string[],
    sort: SortConfig['order'] | null
  ): void => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      this._filters.delete(attributeId);
    } else {
      this._filters.set(attributeId, value);
    }

    if (sort) {
      this._sortConfig = { order: sort, sort: attributeId };
    } else if (!sort && this._sortConfig?.sort === attributeId) {
      this._sortConfig = null;
    }
  };

  public clear(): void {
    this._filters.clear();
    this._sortConfig = null;
  }
}
