import { Button, ButtonAppearance } from '../../components-2/button';
import { Icon, IconSize } from '@yarmill/components';
import { usePlannerStore } from '../planner-store-context';
import { ZoomOut } from '@yarmill/icons-2';
import { useIntl } from 'react-intl';
import { Tippy } from '../../components/tippy/tippy';

interface DecreaseZoomLevelProps {
  readonly isZoomOutAvailable: boolean;
}
export function DecreaseZoomLevel({
  isZoomOutAvailable
}: DecreaseZoomLevelProps): JSX.Element {
  const plannerStore = usePlannerStore();
  const isEnabled = plannerStore.zoomLevel > 0 && isZoomOutAvailable;
  const intl = useIntl();

  return (
    <Tippy tooltipContent="planner.zoomOut" noWrapper>
      <Button
        $appearance={ButtonAppearance.Secondary}
        onClick={isEnabled ? () => plannerStore.decreaseZoomLevel() : undefined}
        $appearanceStyle="neutral"
        $iconOnly
        disabled={!isEnabled}
        type="button"
        aria-label={intl.formatMessage({ id: 'planner.zoomOut' })}
      >
        <Icon size={IconSize.s24}>
          <ZoomOut />
        </Icon>
      </Button>
    </Tippy>
  );
}
