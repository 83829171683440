import { InteractionLayerOptions, LayerComponentProps } from './types';
import {
  InteractionLayer as InteractionLayerComponent,
  InteractionLayerSize
} from '@yarmill/components';
import { LayerContent } from './layer-content';

export function InteractionLayer(props: LayerComponentProps): JSX.Element {
  const { layer, active, ...ownProps } = props;
  const options = layer.options as InteractionLayerOptions;

  return (
    <InteractionLayerComponent
      {
        ...ownProps /* Used to pass through the animation props */
      }
      size={options.size ?? InteractionLayerSize.B}
      active={Boolean(active)}
    >
      <LayerContent layer={layer} />
    </InteractionLayerComponent>
  );
}
