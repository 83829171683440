import { css, styled, ThemeDefinition } from '@yarmill/components';

export interface TextProps {
  readonly appearance?: keyof ThemeDefinition['text']['appearance'];
  readonly monoSpace?: boolean;
  readonly bold?: boolean;
  readonly medium?: boolean;
  readonly whiteSpace?: 'noWrap' | 'pre-wrap';
  readonly align?: 'left' | 'center' | 'right';
  readonly ellipsis?: boolean;
  readonly inheritColor?: boolean;
  readonly upperCase?: boolean;
  readonly strikeThrough?: boolean;
  readonly lineClamp?: number;
  readonly capitalize?: boolean;
}

export function getTextAppearanceStyles(
  appearance: keyof ThemeDefinition['text']['appearance'] = 'text15'
) {
  return css`
    font-size: ${({ theme }) => theme.text.appearance[appearance].fontSize};
    font-weight: ${({ theme }) => theme.text.appearance[appearance].fontWeight};
    line-height: ${({ theme }) => theme.text.appearance[appearance].lineHeight};
  `;
}
export const Text = styled.span<TextProps>`
  font-family: ${({ theme, monoSpace }) =>
    `${monoSpace ? theme.text.font.mono : theme.text.font.default}, ${
      theme.text.font.emoji
    }`};
  ${({ appearance }) => getTextAppearanceStyles(appearance)}
  ${({ bold, medium }) =>
    bold || medium ? `font-weight: ${bold ? 700 : medium ? 500 : 0}` : ''};
  white-space: ${({ whiteSpace }) => whiteSpace};
  text-align: ${({ align }) => align ?? 'initial'};
  color: ${({ theme, inheritColor }) =>
    inheritColor ? 'inherit' : theme.color.text};
  padding: 0;
  margin: 0;
  transition: font-size, color, font-weight, line-height 250ms ease;

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${({ upperCase }) =>
    upperCase &&
    css`
      text-transform: uppercase;
    `}

  ${({ capitalize }) =>
    capitalize &&
    css`
      text-transform: capitalize;
    `}

  ${({ strikeThrough }) =>
    strikeThrough &&
    css`
      text-decoration: line-through;
    `}

  ${({ lineClamp }) =>
    lineClamp &&
    css`
      display: -moz-box;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-box-pack: end;
      -moz-box-orient: vertical;
      line-clamp: ${lineClamp};
      -webkit-line-clamp: ${lineClamp};
      overflow: hidden;
    `}
`;
