import { AsyncToastMessage } from '@yarmill/components';

import {
  toast as reactToastify,
  toast,
  Id as ToastId,
  ToastOptions
} from 'react-toastify';
import { AsyncStatus } from '../api/mobx/request-store';
import { useIntl } from 'react-intl';

function getTitle(state: AsyncStatus, type: 'export' | 'download'): string {
  switch (state) {
    case AsyncStatus.resolved:
      return `${type}.success`;
    case AsyncStatus.rejected:
      return `${type}.error`;
    default:
      return `${type}.exporting`;
  }
}

export interface ExportToastMessageProps {
  fileName: string;
  status: AsyncStatus;
  type?: 'export' | 'download';
}

export function ExportToastMessage(
  props: ExportToastMessageProps
): JSX.Element {
  const { fileName, status, type = 'export' } = props;
  const title = getTitle(status, type);
  const intl = useIntl();

  return (
    <AsyncToastMessage
      title={intl.formatMessage({ id: title })}
      description={fileName}
      status={status}
    />
  );
}

const showOptions: ToastOptions = {
  autoClose: false,
  closeOnClick: true,
  position: reactToastify.POSITION.BOTTOM_RIGHT,
  className: 'ytd-toast',
  icon: false,
  hideProgressBar: true,
  closeButton: false
};

const successExportOptions: ToastOptions = {
  ...showOptions,
  autoClose: 5000,
  className: 'ytd-toast success'
};

const errorExportOptions: ToastOptions = {
  ...showOptions,
  autoClose: 5000,
  className: 'ytd-toast error'
};

export const showExportToastMessage = (
  fileName: string,
  cancelExport: () => void,
  type?: 'export' | 'download'
): ToastId =>
  toast(
    <ExportToastMessage
      fileName={fileName}
      status={AsyncStatus.pending}
      type={type}
    />,
    {
      ...showOptions,
      onClose: cancelExport
    }
  );

export const showExportSuccessToastMessage = (
  id: ToastId,
  fileName: string,
  type?: 'export' | 'download'
) =>
  toast.update(id, {
    ...successExportOptions,
    render: (
      <ExportToastMessage
        fileName={fileName}
        status={AsyncStatus.resolved}
        type={type}
      />
    )
  });

export const showExportErrorToastMessage = (
  id: ToastId,
  fileName: string,
  type?: 'export' | 'download'
) => {
  const toastContent = (
    <ExportToastMessage
      fileName={fileName}
      status={AsyncStatus.rejected}
      type={type}
    />
  );

  if (!id) {
    toast(toastContent, errorExportOptions);
  } else {
    toast.update(id, {
      ...errorExportOptions,
      render: toastContent
    });
  }
};
