import { useXYChartContext } from './xy-chart-context';
import { CategoricalTooltip } from './tooltip/categorical-tooltip';
import { ContinuousTooltip } from './tooltip/continuous-tooltip';
import { useTooltipContext } from './hooks/use-tooltip';
import { ChartTooltip } from '../chart-tooltip';
import { CSSProperties } from 'react';

const tooltipStyle: CSSProperties = {
  top: 0,
  left: 0,
  position: 'absolute',
  backgroundColor: 'transparent',
  pointerEvents: 'none'
};

export function Tooltip() {
  const { xScale } = useXYChartContext();
  const { TooltipInPortal, tooltipTop, tooltipLeft, tooltipOpen, tooltipData } =
    useTooltipContext();

  const isCategorical = 'bandwidth' in xScale;

  return (
    <>
      {isCategorical ? <CategoricalTooltip /> : <ContinuousTooltip />}
      {tooltipOpen && tooltipData && tooltipData.values.length > 0 && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyle}
          detectBounds
        >
          <ChartTooltip
            key={tooltipData.key}
            label={tooltipData.showLabel ? tooltipData.label : ''}
            items={tooltipData.values}
            tableLayout={tooltipData.tableLayout}
            showTableColumnsLabels={tooltipData.showColumnLabels}
            tableColumnsLabels={tooltipData.tableColumnsLabels}
          />
        </TooltipInPortal>
      )}
    </>
  );
}
