import { RootStore } from '../../app/mobx/root-store';
import { makeObservable, observable } from 'mobx';
import { AttendanceValue, WeekAttendanceItem } from '../types';
import { api } from '../api';
import { UpdateWeekAttendanceItemRequestParams } from '../api/update-attendace-item';

export class WeekAttendanceItemStore {
  private readonly _rootStore: RootStore;

  @observable
  private _value: AttendanceValue;

  private readonly _date: string;
  private readonly _userId: number;
  private readonly _phaseId: number | null;

  constructor(
    rootStore: RootStore,
    item: WeekAttendanceItem,
    phaseId: number | null
  ) {
    this._rootStore = rootStore;
    this._value = item.Value;
    this._date = item.Date;
    this._userId = item.UserId;
    this._phaseId = phaseId;

    // TODO: remove when api
    if (typeof this._value === 'boolean') {
      this._value = this._value
        ? AttendanceValue.Present
        : AttendanceValue.Unexcused;
    }

    makeObservable(this);
  }

  public async setValue(value: AttendanceValue): Promise<void> {
    this._value = value;
    const transaction = this._rootStore.navbarStore.createTransaction(
      'loading',
      'attendance'
    );

    const params: UpdateWeekAttendanceItemRequestParams = {
      UserId: this._userId,
      Date: this._date,
      Value: value,
      ActivityItemId: this._phaseId
    };
    const request = this._rootStore.requestsStore.createRequest(() =>
      api.updateAttendanceItem(params)
    );

    await request.getResponse();

    if (!request.error) {
      transaction.success();
    } else {
      transaction.error();
    }
  }

  public get value(): AttendanceValue {
    return this._value;
  }
}
