import * as React from 'react';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  FormControlVariant,
  Icon,
  IconSize,
  Text,
  TextSize,
  TextTag
} from '@yarmill/components';

import { FormState } from '../../app/app-types';
import { getSubmitButtonAppearance, getSubmitButtonIcon } from './utils';
import { EvidenceObjectDataStore } from '../mobx/evidence-object-data-store';
import { handleFormSubmit } from '../utils';
import { FormField } from '../form-fields/form-field';
import { observer } from 'mobx-react-lite';
import { toast } from '../../components/toast-message';
import { useLayer } from '../../layer-manager/hooks';
import { LayerPortal } from '../../layer-manager/layer-portal';
import { PrefillLayer } from './prefill-layer';
import { Tippy } from '../../components/tippy/tippy';
import { EvidenceAttachmentStore } from '../mobx/evidence-attachment-store';
import { Attachments } from '../form-fields/attachments';

export interface EvidenceTableFormProps {
  item: EvidenceObjectDataStore;
  close(): void;
}

const StyledHeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const StyledFormControl = styled.div``;

const StyledButtonsWrapper = styled(StyledFormControl)`
  margin-top: 30px;

  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledSubmitWrapper = styled.div`
  margin-top: 20px;

  button {
    width: 100%;
  }

  @media (min-width: 769px) {
    margin-top: 0;
    margin-left: 20px;

    button {
      width: auto;
    }
  }
`;

const PrefillButtonWrapper = styled.div`
  margin-right: -12px;
`;

export const EvidenceTableForm = observer(function EvidenceTableForm(
  props: EvidenceTableFormProps
): JSX.Element {
  const { item, close } = props;
  const intl = useIntl();
  const [state, setState] = useState<FormState>(null);
  const prefillLayer = useLayer('alert', {
    showShim: true,
    closeOnShimClick: true
  });

  useEffect(() => {
    if (state === 'error') {
      setTimeout(() => {
        setState(null);
      }, 5000);
    } else if (state === 'success') {
      close();
    }
  }, [state, close]);

  const handleSubmit = useCallback(
    async (e: FormEvent): Promise<void> => {
      e.preventDefault();
      setState('submitting');
      const isNew = item.arrayIndex === null;
      const success = await handleFormSubmit(item);

      if (success === null) {
        // FE validation failed, request to BE was not send
        setState(null);
        return;
      } else if (success) {
        setState('success');

        if (isNew) {
          toast(
            `toast.success.evidence.${item.definition.ObjectKey}.addItem`,
            'success'
          );
        } else {
          toast(
            `toast.success.evidence.${item.definition.ObjectKey}.updateItem`,
            'success'
          );
        }
      } else {
        setState('error');

        if (isNew) {
          toast(
            `toast.error.evidence.${item.definition.ObjectKey}.addItem`,
            'error'
          );
        } else {
          toast(
            `toast.error.evidence.${item.definition.ObjectKey}.updateItem`,
            'error'
          );
        }
      }
    },
    [item]
  );

  const handleReset = React.useCallback(
    (e?: React.FormEvent) => {
      e?.preventDefault();
      item?.reset();
      close();
    },
    [item, close]
  );

  return (
    <>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <StyledHeadlineWrapper>
          <Text size={TextSize.s24} tag={TextTag.h1}>
            <FormattedMessage
              id={
                // TODO: check translations
                item.arrayIndex !== null
                  ? `evidence.${item.definition.ObjectKey}.updateItemHeadline`
                  : `evidence.${item.definition.ObjectKey}.newItem`
              }
            />
          </Text>
          {item.objectStore.submitHistory.length > 0 && (
            <Tippy tooltipContent="evidence.prefill.tooltip">
              <PrefillButtonWrapper>
                <Button
                  appearance={ButtonAppearance.Link}
                  type="button"
                  onClick={() => !prefillLayer.isOpened && prefillLayer.open()}
                >
                  <Icon size={IconSize.s20}>
                    <ExternalIcon name="FileImport" />
                  </Icon>
                </Button>
              </PrefillButtonWrapper>
            </Tippy>
          )}
        </StyledHeadlineWrapper>
        {item.definition.Attributes?.map((attribute, idx) => {
          const attributeStore = item.getAttribute(attribute.AttributeId);
          if (!attributeStore) {
            return null;
          }
          if (attributeStore instanceof EvidenceAttachmentStore) {
            return (
              <Attachments
                attributeStore={attributeStore}
                key={attribute.AttributeId}
              />
            );
          }
          return (
            <FormField
              autoFocus={idx === 0}
              attributeStore={attributeStore}
              key={attribute.AttributeId}
            />
          );
        })}
        <StyledButtonsWrapper>
          <Button type="reset" variant={FormControlVariant.big}>
            {intl.formatMessage({ id: 'evidence.form.close' })}
          </Button>
          <StyledSubmitWrapper>
            <Tippy
              tooltipContent="evidence.form.pendingAttachments"
              Wrapper="div"
              isEnabled={item.hasPendingAttachments}
            >
              <Button
                type={!state ? 'submit' : 'button'}
                appearance={getSubmitButtonAppearance(state)}
                variant={FormControlVariant.big}
                wide
                disabled={
                  state === 'submitting' ||
                  item.hasPendingAttachments ||
                  (!item.isValid && item.submitButtonClicked)
                }
              >
                {getSubmitButtonIcon(state)}
                {intl.formatMessage({
                  id: `evidence.form.${item.definition.ObjectKey}.submit`
                })}
              </Button>
            </Tippy>
          </StyledSubmitWrapper>
        </StyledButtonsWrapper>
      </form>
      <LayerPortal
        layerHandle={prefillLayer}
        getContent={layer => (
          <PrefillLayer
            layer={layer}
            history={item.objectStore.submitHistory}
            attributes={item.objectStore.definition.Attributes}
            dataStore={item}
          />
        )}
      />
    </>
  );
});
