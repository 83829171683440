import { observer } from 'mobx-react-lite';
import {
  CoachSymbol,
  NavigationButton,
  NavigationButtonText,
  NavigationItemWrapper,
  RoleAvatarIcon
} from '../components-2/sidebar-navigation';
import { IconSize } from '@yarmill/components';
import { LinkProps } from 'react-router-dom';
import { Coach } from '@yarmill/icons-2';
import { useSidebarStore } from './hooks/use-sidebar-store';

interface NavigationItemProps extends LinkProps {
  readonly icon?: JSX.Element;
  readonly text: string;
  readonly isActive?: boolean;
  readonly isAthlete?: boolean;
  readonly isCoach?: boolean;
}
export const NavigationItem = observer(function NavigationItem(
  props: NavigationItemProps
): JSX.Element {
  const { icon, text, isActive, isAthlete, isCoach, ...linkProps } = props;
  const sidebarStore = useSidebarStore();

  return (
    <NavigationItemWrapper>
      <NavigationButton
        {...linkProps}
        $isActive={isActive}
        onClick={() => {
          if (window.innerWidth < 768) {
            sidebarStore.hide();
          }
        }}
      >
        {icon && (
          <RoleAvatarIcon size={IconSize.s24} isCoach={isCoach}>
            <>
              {icon}
              {isCoach && (
                <CoachSymbol size={IconSize.s12}>
                  <Coach />
                </CoachSymbol>
              )}
            </>
          </RoleAvatarIcon>
        )}
        <NavigationButtonText inheritColor>{text}</NavigationButtonText>
      </NavigationButton>
    </NavigationItemWrapper>
  );
});
