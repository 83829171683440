import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import { SEARCH_PAGE_SIZE, Video } from '../types';
import { RootStore } from '../../app/mobx/root-store';
import {
  action,
  computed,
  IObservableArray,
  observable,
  reaction,
  runInAction,
  makeObservable
} from 'mobx';
import debounce from 'lodash.debounce';
import { searchVideo, SearchVideoRequestParams } from '../api/search-videos';

export class VideoSelectorStore {
  private readonly rootStore: RootStore;
  public readonly doneCallback: () => void;
  @observable
  private _searchTerm: string = '';
  @observable
  private _highlightedSearchTerm: string = '';
  @observable
  private _videos: IObservableArray<Video> = observable.array();
  @observable
  private _offset: number = 0;
  @observable
  private _hasAllVideos: boolean = false;
  private _request: RequestStore<Video[]> | null = null;
  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  constructor(rootStore: RootStore, doneCallback: () => void) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.doneCallback = doneCallback;

    reaction(() => this._searchTerm, this.searchVideosDebounced);

    void this.searchVideos();
  }

  @action
  public readonly setSearchTerm = (term: string): void => {
    this._searchTerm = term;
    this._offset = 0;
  };

  @action
  public increaseOffset(): void {
    this._offset = this._offset + SEARCH_PAGE_SIZE;
    void this.searchVideos();
  }

  public get searchTerm(): string {
    return this._searchTerm;
  }

  public get highlightedSearchTerm(): string {
    return this._highlightedSearchTerm;
  }

  @computed
  public get videos(): IObservableArray<Video> {
    return this._videos;
  }

  public get hasAllVideos(): boolean {
    return this._hasAllVideos;
  }

  public get status(): AsyncStatus {
    return this._status;
  }

  private async searchVideos(): Promise<void> {
    this._status = AsyncStatus.pending;
    this._request?.cancel();
    const searchText = this._searchTerm;
    const skip = this._offset;
    const params: SearchVideoRequestParams = {
      searchText,
      skip,
      limit: SEARCH_PAGE_SIZE
    };
    this._request = this.rootStore.requestsStore.createRequest(cancelToken =>
      searchVideo(params, cancelToken.token)
    );

    const response = await this._request.getResponse();
    if (response) {
      runInAction(() => {
        if (this._offset === 0) {
          this._videos.replace(response);
        } else {
          this._videos.push(...response);
        }
        this._hasAllVideos = response.length < SEARCH_PAGE_SIZE;
        this._highlightedSearchTerm = searchText;
        this._status = AsyncStatus.resolved;
      });
    } else {
      this._status = AsyncStatus.rejected;
    }
  }

  private readonly searchVideosDebounced = debounce(
    () => this.searchVideos(),
    100
  );
}
