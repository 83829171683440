import { TextInputProps } from './text-input';
import { PatternInput } from './pattern-input';
import { useIntl } from 'react-intl';
import {
  createDateFormatValidator,
  extractDateFormat,
  extractDelimiters
} from '../utils/extract-date-format';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment/moment';
import { PATTERN_INPUT_EMPTY_VALUE } from '@yarmill/components';
import { ROUTE_DATE_FORMAT } from '../diary/utils';

interface DateInputProps extends Omit<TextInputProps, 'onChange'> {
  readonly onChange?: (newValue: string) => void;
}

export function DateInput(props: DateInputProps) {
  const intl = useIntl();
  const dateFormat = extractDateFormat(intl);
  const dateFormatDelimiter = extractDelimiters(dateFormat);
  const dateFormatValidator = createDateFormatValidator(dateFormat);
  const [value, setValue] = useState(
    props.value ? moment(props.value).format(dateFormat) : ''
  );

  useEffect(() => {
    setValue(props.value ? moment(props.value).format(dateFormat) : '');
  }, [props.value, dateFormat]);

  const onDateChange = useCallback(
    function onDateChange(
      value: string,
      setValue: (value: string) => void,
      setValueToStore?: (value: string) => void
    ): void {
      setValue(value);
      if (
        value.length === dateFormat.length &&
        !value.includes(PATTERN_INPUT_EMPTY_VALUE)
      ) {
        const date = moment(value, dateFormat);
        if (date.isValid()) {
          setValueToStore?.(date.format(ROUTE_DATE_FORMAT));
        }
      } else if (!value) {
        setValueToStore?.('');
      }
    },
    [dateFormat]
  );

  const onChange = useCallback(
    (value: string) => {
      onDateChange(value, setValue, props.onChange);
    },
    [onDateChange, props.onChange]
  );

  return (
    <PatternInput
      {...props}
      value={value}
      onChange={onChange}
      pattern={dateFormat}
      validateValue={dateFormatValidator}
      delimiter={dateFormatDelimiter}
    />
  );
}
