import moment from 'moment';
import { IntlShape } from 'react-intl';
import { EvidenceAttributeType } from './types';
import {
  FULL_TIME_PATTERN_WITH_MS,
  TIME_PATTERN_HHMMSS,
  TIME_PATTERN_SS,
  TIME_PATTERN_SSS,
  TIME_PATTERN_WITH_MS
} from '../components/pattern-input-time';
import { PATTERN_INPUT_EMPTY_VALUE } from '@yarmill/components';

export function validateDecimalNumber(value: string): boolean {
  return Boolean(value.match(/^[+-]?([0-9]*[.,])?[0-9]+$/));
}

export function validateInteger(value: string): boolean {
  return Boolean(value.match(/^-?\d+$/));
}

const timeValidityTable = [/[0-9]/, /[0-9]/, /:/, /[0-5]/, /[0-9]/];
const timeValidityTableWithMs = [...timeValidityTable, /\./, /[0-9]/, /[0-9]/];
const timeHhMmSsValidityTable = [...timeValidityTable, /:/, /[0-5]/, /[0-9]/];
const fullTimeValidityTableWithMs = [
  ...timeHhMmSsValidityTable,
  /\./,
  /[0-9]/,
  /[0-9]/
];
const timeSsValidityTable = [/[0-5]/, /[0-9]/, /\./, /[0-9]/, /[0-9]/];
const timeSssValidityTable = [
  /[0-5]/,
  /[0-9]/,
  /\./,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/
];

export function validateByValidityTable(
  value: string,
  validityTable: RegExp[],
  partialMatch: boolean
): boolean {
  for (let i = 0; i < value.length; i++) {
    if (value.length > validityTable.length) {
      return false;
    }
    if (
      !validityTable[i].test(value[i]) &&
      (!partialMatch ||
        (partialMatch && value[i] !== PATTERN_INPUT_EMPTY_VALUE))
    ) {
      return false;
    }
  }

  return true;
}

export function validateTimeValue(
  value: string = '',
  partialMatch = true
): boolean {
  const valid = validateByValidityTable(value, timeValidityTable, partialMatch);

  return partialMatch
    ? valid
    : valid && value.length === timeValidityTable.length;
}

export function validateTimeSsValue(
  value: string = '',
  partialMatch = true
): boolean {
  const valid = validateByValidityTable(
    value,
    timeSsValidityTable,
    partialMatch
  );

  return partialMatch
    ? valid
    : valid && value.length === timeSsValidityTable.length;
}

export function validateTimeSssValue(
  value: string = '',
  partialMatch = true
): boolean {
  const valid = validateByValidityTable(
    value,
    timeSssValidityTable,
    partialMatch
  );

  return partialMatch
    ? valid
    : valid && value.length === timeSssValidityTable.length;
}

const timeOfDayValidityTable = [/:/, /[0-5]/, /[0-9]/];

export function validateTimeOfDayValue(
  value: string = '',
  partialMatch = true
): boolean {
  const expectedPattern = 'HH:MM';
  let valid = true;
  if (value.length === 1) {
    valid = Number(value) >= 0 && Number(value) < 3;
  } else if (value.length === 2) {
    valid = Number(value) >= 0 && Number(value) < 24;
  } else if (value.length >= 3) {
    const hours = value.slice(0, 2);
    const validHours = Number(hours) >= 0 && Number(hours) < 24;
    valid =
      validHours &&
      validateByValidityTable(
        value.slice(2),
        timeOfDayValidityTable,
        partialMatch
      );
  }

  return partialMatch
    ? valid
    : valid && value.length === expectedPattern.length;
}

export function validateTimeValueWithMs(
  value: string = '',
  partialMatch = true
): boolean {
  const trimmedValue = value.slice(0, timeValidityTableWithMs.length);
  const valid = validateByValidityTable(
    trimmedValue,
    timeValidityTableWithMs,
    partialMatch
  );

  return partialMatch
    ? valid
    : valid && trimmedValue.length === timeValidityTableWithMs.length;
}

export function validateFullTimeValueWithMs(
  value: string = '',
  partialMatch = true
): boolean {
  const trimmedValue = value.slice(0, fullTimeValidityTableWithMs.length);
  const valid = validateByValidityTable(
    value,
    fullTimeValidityTableWithMs,
    partialMatch
  );

  return partialMatch
    ? valid
    : valid && trimmedValue.length === fullTimeValidityTableWithMs.length;
}

export function validateHhMmSsTimeValue(
  value: string = '',
  partialMatch = true
): boolean {
  const trimmedValue = value.slice(0, timeHhMmSsValidityTable.length);
  const valid = validateByValidityTable(
    value,
    timeHhMmSsValidityTable,
    partialMatch
  );

  return partialMatch
    ? valid
    : valid && trimmedValue.length === timeHhMmSsValidityTable.length;
}

const dateValidityTable = [
  /[1-2]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[/-]/,
  /[0-1]/,
  /[0-9]/,
  /[/-]/,
  /[0-3]/,
  /[0-9]/
];
export function validateDateValue(value: string): boolean {
  if (value.length === 10) {
    return moment(value).isValid();
  }

  for (let i = 0; i < value.length; i++) {
    if (!dateValidityTable[i] || !dateValidityTable[i].test(value[i])) {
      return false;
    }
    if (i === 6 && value[5] === '1' && Number(value[6]) > 2) {
      return false;
    }
    if (i === 9 && value[8] === '3' && Number(value[9]) > 1) {
      return false;
    }
  }

  return true;
}

export function validateEvidenceAttribute(
  type: EvidenceAttributeType,
  value: string,
  format?: string | null
): boolean {
  switch (type) {
    case 'decimal-number':
      return validateDecimalNumber(value);
    case 'number':
      return validateInteger(value);
    case 'time-of-day': {
      return validateTimeOfDayValue(value, false);
    }
    case 'time': {
      if (format === FULL_TIME_PATTERN_WITH_MS) {
        return validateFullTimeValueWithMs(value, false);
      } else if (format === TIME_PATTERN_WITH_MS) {
        return validateTimeValueWithMs(value, false);
      } else if (format === TIME_PATTERN_SS) {
        return validateTimeSsValue(value, false);
      } else if (format === TIME_PATTERN_SSS) {
        return validateTimeSssValue(value, false);
      } else if (format === TIME_PATTERN_HHMMSS) {
        return validateHhMmSsTimeValue(value, false);
      } else {
        return validateTimeValue(value, false);
      }
    }
    case 'date':
      return validateDateValue(value);
    default:
      return true;
  }
}

export function getErrorMessage(
  type: EvidenceAttributeType,
  hasValue: Boolean,
  isRequired: boolean,
  isValid: boolean,
  intl: IntlShape,
  format: string | null
): string | undefined {
  if (isValid) {
    return undefined;
  }

  if (!hasValue && !isRequired) {
    return undefined;
  }

  if (!hasValue && isRequired) {
    return intl.formatMessage({ id: 'evidence.attribute.required' });
  }

  switch (type) {
    case 'decimal-number':
      return intl.formatMessage({
        id: 'evidence.attribute.invalidDecimalNumber'
      });
    case 'number':
      return intl.formatMessage({ id: 'evidence.attribute.invalidNumber' });
    case 'time':
      return intl.formatMessage(
        {
          id: 'evidence.attribute.invalidTime'
        },
        { format }
      );
    case 'date':
      return intl.formatMessage({ id: 'evidence.attribute.invalidDate' });
  }

  return undefined;
}
