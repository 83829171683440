import { ComponentProps, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export function useDefaultValues(
  id: string | undefined
): ComponentProps<typeof FormattedMessage>['values'] | undefined {
  const intl = useIntl();

  return useMemo(() => {
    if (!id) {
      return undefined;
    }
    const translation = intl.messages[id];
    if (!translation || typeof translation !== 'string') {
      return undefined;
    }

    const placeholders = translation.match(/{(.*?)}/g);
    if (!placeholders) {
      return undefined;
    }

    return placeholders.reduce((values, placeholder) => {
      const key = placeholder.replace('{', '').replace('}', '');
      values[key] = '-';
      return values;
    }, {});
  }, [intl, id]);
}
