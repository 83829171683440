import { SeasonEvaluationStore } from './mobx/season-evaluation-store';
import {
  memo,
  useContext,
  createContext,
  PropsWithChildren,
  useRef
} from 'react';
import { useRootStore } from '../app/root-store-context';

export const SeasonEvaluationStoreContext = createContext<
  SeasonEvaluationStore | undefined
>(undefined);

export function useSeasonEvaluationStore(): SeasonEvaluationStore {
  const diaryStore = useContext(SeasonEvaluationStoreContext);
  if (!diaryStore) {
    throw new Error('Season Evaluation Store not available');
  }

  return diaryStore;
}

export function InternalSeasonEvaluationStoreContextProvider(
  props: PropsWithChildren<{}>
): JSX.Element {
  const rootStore = useRootStore();
  const diaryStore = useRef(new SeasonEvaluationStore(rootStore)).current;

  return (
    <SeasonEvaluationStoreContext.Provider value={diaryStore}>
      {props.children}
    </SeasonEvaluationStoreContext.Provider>
  );
}

export const SeasonEvaluationStoreContextProvider = memo(
  InternalSeasonEvaluationStoreContextProvider
);
