import { User } from '../users/types';
import { Location } from '../routes/hooks';
import { AttendanceValue } from '../attendance/types';

interface GtagData {
  [key: string]: number | string | boolean | undefined;
}

declare global {
  interface Window {
    gtag?(event: string, action: string, data?: GtagData): void;
  }
}

function internalGtag(event: string, action: string, data?: GtagData): void {
  if (window.gtag) {
    window.gtag(event, action, data);
  }
}

export function trackPageView(trackingId: string, currentUser: User): void {
  internalGtag('config', trackingId, {
    page_location: window.location.pathname,
    user_role: currentUser.Role
  });
}

export function trackUserId(userId: string) {
  internalGtag('set', JSON.stringify({ user_id: userId }));
}

interface TrackEventParams {
  action: string;
  category: string;
  label?: string;
  value?: number;
}

function trackEvent({
  action,
  category,
  label,
  value
}: TrackEventParams): void {
  internalGtag('event', action, {
    event_category: category,
    event_label: label,
    value
  });
}

export function trackMainNavClick(label: string): void {
  trackEvent({ category: 'navigation', action: 'main nav click', label });
}

export function trackSidebarLinkClick(label: string): void {
  trackEvent({ category: 'navigation', action: 'sidebar click', label });
}

export function trackSecondarySidebarLinkClick(label: string): void {
  trackEvent({
    category: 'navigation',
    action: 'secondary sidebar click',
    label
  });
}

export function trackUserMenuClick(label: string): void {
  trackEvent({ category: 'navigation', action: 'user menu click', label });
}

export function trackSettingsMenuClick(label: string): void {
  trackEvent({
    category: 'navigation',
    action: 'settings menu click',
    label
  });
}

export function trackChangeSeasonClick(label: string): void {
  trackEvent({ category: 'navigation', action: 'change season', label });
}

export function trackChangeWeekClick(label: string): void {
  trackEvent({ category: 'navigation', action: 'change week', label });
}

export function trackChangeWeekDayClick(label: string): void {
  trackEvent({ category: 'navigation', action: 'change week day', label });
}

export function trackChangeViewTypeClick(label: string): void {
  trackEvent({ category: 'navigation', action: 'change view type', label });
}

export function trackChangePhaseClick(label: string): void {
  trackEvent({
    category: 'navigation',
    action: 'change attendance phase',
    label
  });
}

export function trackCloseButtonClick(): void {
  trackEvent({ category: 'navigation', action: 'close button click' });
}

export function trackDropdownClick(
  label: string,
  type: 'open' | 'close'
): void {
  trackEvent({ category: 'dropdown', action: `${type} dropdown`, label });
}

export function trackCopyButtonClick(): void {
  trackEvent({ category: 'copy', action: 'show copy dialog' });
}

export function trackSubscribeReportingPageButtonClick(label?: string): void {
  trackEvent({
    category: 'reporting',
    action: 'subscribe reporting page',
    label
  });
}

export function trackExportButtonClick(): void {
  trackEvent({ category: 'export', action: 'show export dialog' });
}

export function trackCopyModeChange(label: string): void {
  trackEvent({ category: 'copy', action: 'change copy mode', label });
}

export function trackLogout(): void {
  trackEvent({ category: 'auth', action: 'logout button click' });
}

export function trackChangePasswordClick(): void {
  trackEvent({ category: 'auth', action: 'change password click' });
}

export function trackChangePasswordSubmitClick(): void {
  trackEvent({ category: 'auth', action: 'change password submit click' });
}

export function trackAttendanceValueButtonClick(label: AttendanceValue): void {
  trackEvent({
    category: 'attendance',
    action: 'select user`s attendance',
    label: String(label)
  });
}

export function trackAttendanceSelectAllClick(): void {
  trackEvent({ category: 'attendance', action: 'select whole day' });
}

export function trackExternalServiceConnectClick(label: string): void {
  trackEvent({ category: 'external services', action: 'connect', label });
}

export function trackExternalServiceDisconnectClick(label: string): void {
  trackEvent({ category: 'external services', action: 'disconnect', label });
}

export function trackEditProfileClick(): void {
  trackEvent({ category: 'profile', action: 'edit profile button click' });
}

export function trackSelectAvatarClick(isRegistration?: boolean): void {
  const category = isRegistration ? 'registration' : 'profile';
  trackEvent({ category, action: 'avatar click' });
}

export function trackProfileNextButtonClick(isRegistration?: boolean): void {
  const category = isRegistration ? 'registration' : 'profile';
  trackEvent({ category, action: 'next button click' });
}

export function trackProfileBackButtonClick(isRegistration?: boolean): void {
  const category = isRegistration ? 'registration' : 'profile';
  trackEvent({ category, action: 'back button click' });
}

export function trackProfileFormFieldFocus(
  isRegistration: boolean | undefined,
  label: string
): void {
  const category = isRegistration ? 'registration' : 'profile';
  trackEvent({ category, action: 'form field focus', label });
}

export function trackProfileFormFieldBlur(
  isRegistration: boolean | undefined,
  label: string
): void {
  const category = isRegistration ? 'registration' : 'profile';
  trackEvent({ category, action: 'form field blur', label });
}

export function trackCreateGroupClick(): void {
  trackEvent({
    category: 'settings / groups',
    action: 'create group button click'
  });
}

export function trackRenameGroupClick(): void {
  trackEvent({
    category: 'settings / groups',
    action: 'rename group button click'
  });
}

export function trackRenameGroupCancel(): void {
  trackEvent({
    category: 'settings / groups',
    action: 'rename group cancel click'
  });
}

export function trackRenameGroupSubmit(): void {
  trackEvent({
    category: 'settings / groups',
    action: 'rename group submit click'
  });
}

export function trackRemoveGroupClick(): void {
  trackEvent({
    category: 'settings / groups',
    action: 'remove group click'
  });
}

export function trackRemoveGroupConfirmClick(): void {
  trackEvent({
    category: 'settings / groups',
    action: 'remove group confirm click'
  });
}

export function trackRemoveGroupCancelClick(): void {
  trackEvent({
    category: 'settings / groups',
    action: 'remove group cancel click'
  });
}

export function trackInviteUsersToGroupClick(): void {
  trackEvent({
    category: 'settings / groups',
    action: 'invite users to group click'
  });
}

export function trackInviteUsersToGroupSelectChange(): void {
  trackEvent({
    category: 'settings / groups',
    action: 'invite users to group select change'
  });
}

export function trackInviteUsersToGroupSubmit(): void {
  trackEvent({
    category: 'settings / groups',
    action: 'invite users to group submit'
  });
}

export function trackChangeUserRights(label: string): void {
  trackEvent({
    category: 'settings / groups',
    action: 'change user rights',
    label
  });
}

export function trackShowUserDetails(label: string): void {
  trackEvent({
    category: 'settings / groups',
    action: 'show user details click',
    label
  });
}

export function trackRemoveUserFromGroupClick(label: string): void {
  trackEvent({
    category: 'settings / groups',
    action: 'remove user from group click',
    label
  });
}

export function trackRemoveUserFromGroupConfirmClick(label: string): void {
  trackEvent({
    category: 'settings / groups',
    action: 'remove user from group cancel',
    label
  });
}

export function trackRemoveUserFromGroupCancelClick(label: string): void {
  trackEvent({
    category: 'settings / groups',
    action: 'remove user from group confirm click',
    label
  });
}

export function trackAddUserInvitation(): void {
  trackEvent({
    category: 'settings / users',
    action: 'invite users to app - add invitation'
  });
}

export function trackRemoveUserInvitation(): void {
  trackEvent({
    category: 'settings / users',
    action: 'invite users to app - remove invitation'
  });
}

export function trackInviteUsersToAppSubmit(): void {
  trackEvent({
    category: 'settings / users',
    action: 'invite users to app submit click'
  });
}

export function trackInviteUserFormFieldFocus(label: string): void {
  trackEvent({
    category: 'settings / users',
    action: 'invite users form field focus',
    label
  });
}

export function trackInviteUserFormFieldBlur(label: string): void {
  trackEvent({
    category: 'settings / users',
    action: 'invite users form field blur',
    label
  });
}

export function trackResendInvitationClick(label: string): void {
  trackEvent({
    category: 'settings / users',
    action: 'resend invitation click',
    label
  });
}

export function trackResendInvitationConfirmClick(label: string): void {
  trackEvent({
    category: 'settings / users',
    action: 'resend invitation confirm click',
    label
  });
}

export function trackResendInvitationCancelClick(label: string): void {
  trackEvent({
    category: 'settings / users',
    action: 'resend invitation cancel click',
    label
  });
}

export function trackUsersShowUsersDetailsClick(label: string): void {
  trackEvent({
    category: 'settings / users',
    action: 'show user details click',
    label
  });
}

export function trackDeactivateUserClick(label: string): void {
  trackEvent({
    category: 'settings / users',
    action: 'deactivate user click',
    label
  });
}

export function trackDeactivateUserConfirmClick(label: string): void {
  trackEvent({
    category: 'settings / users',
    action: 'deactivate user confirm click',
    label
  });
}

export function trackDeactivateUserCancelClick(label: string): void {
  trackEvent({
    category: 'settings / users',
    action: 'deactivate user cancel click',
    label
  });
}

export function trackReactivateUserClick(label: string): void {
  trackEvent({
    category: 'settings / users',
    action: 'reactivate user click',
    label
  });
}

export function trackReactivateUserConfirmClick(label: string): void {
  trackEvent({
    category: 'settings / users',
    action: 'reactivate user confirm click',
    label
  });
}

export function trackReactivateUserCancelClick(label: string): void {
  trackEvent({
    category: 'settings / users',
    action: 'reactivate user cancel click',
    label
  });
}

export function trackActivityItemFocus(label: string): void {
  trackEvent({
    category: 'diary',
    action: 'focus activity item field',
    label
  });
}

export function trackActivityItemBlur(label: string): void {
  trackEvent({
    category: 'diary',
    action: 'blur activity item field',
    label
  });
}

export function trackDiaryAttributeFocus(label: string): void {
  trackEvent({ category: 'diary', action: 'focus attribute field', label });
}

export function trackDiaryAttributeBlur(label: string): void {
  trackEvent({ category: 'diary', action: 'blur attribute field', label });
}

export function trackHistoricalAthletesClick(type: 'show' | 'hide'): void {
  trackEvent({ category: 'diary', action: `${type} historical athletes` });
}

export function trackChangeDashboardClick(label: string): void {
  trackEvent({ category: 'analytics', action: 'change dashboard', label });
}

export function trackChangeFilterClick(label: string): void {
  trackEvent({ category: 'analytics', action: 'change filter', label });
}

export function getPageFromLocation(location: Location): string {
  return location.pathname.split('/')[1];
}
