import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable
} from 'mobx';
import { HeartRateZoneStore } from './heart-rate-zone-store';
import moment from 'moment';
import { DATE_FORMAT } from '../../diary/utils';
import { RootStore } from '../../app/mobx/root-store';
import { getAgeInYearsFromBirthday, getDefaultHeartRateZones } from '../utils';
import { uniqId } from '../../app/utils';
import { HeartRateZonesSet } from '../types';

export class HeartRateZonesSetStore {
  public static readonly MAX_ZONES = 10;
  public static readonly DEFAULT_SPORT = 'general';

  private readonly _rootStore: RootStore;

  @observable
  private _id: string = uniqId();

  @observable
  private _sport: string = HeartRateZonesSetStore.DEFAULT_SPORT;

  @observable
  private _validFrom: string | null = moment().format(DATE_FORMAT);

  @observable
  private _validTo: string | null = null;

  @observable
  private _isDefault: boolean = false;

  @observable
  private _heartRateZones: IObservableArray<HeartRateZoneStore> =
    observable.array();

  @observable
  private _created: string | null = null;

  @observable
  private _lastUpdated: string | null = null;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeObservable(this);
  }

  public get id() {
    return this._id;
  }
  public get sport() {
    return this._sport;
  }

  public get validFrom() {
    return this._validFrom;
  }
  public get validTo() {
    return this._validTo;
  }

  public get isDefault() {
    return this._isDefault;
  }

  @action
  public setId(id: string) {
    this._id = id;
    return this;
  }
  @action
  public setSport(sport: string) {
    this._sport = sport;
    return this;
  }

  @action
  public setValidFrom(validFrom: string | null) {
    this._validFrom = validFrom;
    return this;
  }

  @action
  public setValidTo(validTo: string | null) {
    this._validTo = validTo;
    return this;
  }

  @action
  public setIsDefault() {
    this._isDefault = true;
    return this;
  }

  @action
  public setCreated(created: string | null) {
    this._created = created;
    return this;
  }

  @action
  public setLastUpdated(lastUpdated: string | null) {
    this._lastUpdated = lastUpdated;
    return this;
  }

  public get heartRateZones() {
    return this._heartRateZones;
  }

  @action
  public removeZone(zone: HeartRateZoneStore): void {
    this._heartRateZones.remove(zone);
  }

  @action
  public readonly addZone = () => {
    const lastZone = this._heartRateZones[this._heartRateZones.length - 1];
    const secondLastZone =
      this._heartRateZones[this._heartRateZones.length - 2];
    const zone = new HeartRateZoneStore({
      id: uniqId(),
      name: `Z`,
      max: Math.round(lastZone.max - (lastZone.max - secondLastZone.max) / 2)
    });

    this._heartRateZones.splice(-1, 0, zone);
  };

  @computed
  public get isValid(): boolean {
    return Boolean(
      ((this.sport && this.validFrom) || this.isDefault) &&
        this.heartRateZones.every(
          (zone, idx, zones) =>
            (!zones[idx - 1] || zones[idx - 1].max < zone.max) &&
            zone.name &&
            zone.max &&
            zone.max <= 250
        )
    );
  }

  @action
  public createDefaultZones(): void {
    const age = getAgeInYearsFromBirthday(
      this._rootStore.currentUserStore.data?.Birthday ?? ''
    );
    const zones = getDefaultHeartRateZones(age);
    this._heartRateZones.replace(
      zones.map(zone => new HeartRateZoneStore(zone))
    );
  }

  public toJS(): HeartRateZonesSet {
    return {
      id: this.id,
      sport: this.sport,
      validFrom: this.validFrom,
      validTo: this.validTo || null,
      zones: this._heartRateZones.map(zone => zone.toJS()),
      isDefault: this.isDefault,
      created: this._created,
      lastUpdated: this._lastUpdated
    };
  }
}
