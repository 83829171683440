import { memo } from 'react';
import { observer } from 'mobx-react-lite';
import { AppContainer, PageContentWrapper } from '../components-2/page-layout';
import { PrimarySidebar } from '../sidebar';
import {
  PlannerStoreContextProvider,
  usePlannerStore
} from './planner-store-context';

import { ThemeProvider } from '@yarmill/components';
import { KeyboardShortcutsContextProvider } from '../utils/use-keyboard-shortcut';
import { useRootStore } from '../app/root-store-context';
import { Planner } from './planner';
import { useSidebarStore } from '../sidebar/hooks/use-sidebar-store';
import { useCurrentUserStore } from '../users/hooks';
import { Redirect } from 'react-router-dom';

function InternalPlannerRouteRoot(): JSX.Element | null {
  const rootStore = useRootStore();
  const sidebarStore = useSidebarStore();
  const plannerStore = usePlannerStore();
  const currentUser = useCurrentUserStore();

  if (
    rootStore.isReady &&
    !currentUser.isAthlete &&
    !plannerStore.group &&
    (!plannerStore.athleteId || plannerStore.athleteId === currentUser.id)
  ) {
    return <Redirect to="/noGroup" />;
  }

  return rootStore.isReady ? (
    <AppContainer>
      <PrimarySidebar />
      <PageContentWrapper isSidebarVisible={sidebarStore.isVisible}>
        <Planner />
      </PageContentWrapper>
    </AppContainer>
  ) : null;
}

const ObserverPlannerRouteRoot = observer(InternalPlannerRouteRoot);

export const PlannerRouteRoot = memo(() => (
  <PlannerStoreContextProvider>
    <ThemeProvider theme="main">
      <KeyboardShortcutsContextProvider>
        <ObserverPlannerRouteRoot />
      </KeyboardShortcutsContextProvider>
    </ThemeProvider>
  </PlannerStoreContextProvider>
));
