import {
  ButtonAppearance,
  SegmentedButton,
  SegmentedControl
} from '@yarmill/components';
import { ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ViewType } from '../diary/types';
import { trackChangeViewTypeClick } from '../google-analytics/utils';
import { useHistory, useLocation } from '../routes/hooks';
import { useIsMobile } from '../utils/use-is-mobile';
import { ModulesStore } from '../modules/mobx/modules-store';
import { useRootStore } from '../app/root-store-context';

export const StyledViewSelector = styled.div`
  box-shadow: none;
  border-radius: 4px;
  background: #fff;

  input[type='radio'],
  input[type='checkbox'] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }

  button {
    font-size: 14px;
  }

  @media (min-width: 991px) {
    margin-right: 25px;
  }

  @media print {
    display: none;
  }
`;

export interface ViewSelectorProps {
  moduleKey: keyof ModulesStore;
  activeViewType: ViewType;
  allowedViewTypes: ViewType[];
}

interface ViewTypeButtonProps {
  active: boolean;
  moduleKey: string;
  viewType: string;
}

function shortenViewName(view: ReactNode): string {
  const name = Array.isArray(view) ? view[0] : view;
  return name
    .split('-')
    .map((part: string) => part.slice(0, 1).toUpperCase())
    .join('');
}

function ViewTypeButton(props: ViewTypeButtonProps): JSX.Element {
  const { active, moduleKey, viewType } = props;
  const isMobile = useIsMobile();
  const rootStore = useRootStore();
  const { search } = useLocation();
  const history = useHistory();
  const translateKey =
    moduleKey === 'plan' && viewType === 'season'
      ? `navbar.viewSelector.plan.${viewType}`
      : `navbar.viewSelector.${viewType}`;

  const onChange = useCallback(() => {
    const link = `/${moduleKey}/${viewType}${search}`;
    rootStore.memoryStore.setItem(moduleKey, viewType);
    trackChangeViewTypeClick(viewType);
    history.push(link);
  }, [moduleKey, viewType, search, history, rootStore]);

  return (
    <SegmentedButton
      appearance={ButtonAppearance.Navigation}
      active={active}
      onClick={onChange}
      data-cy={viewType}
    >
      <FormattedMessage id={translateKey}>
        {msg => <>{isMobile && msg ? shortenViewName(msg) : msg}</>}
      </FormattedMessage>
    </SegmentedButton>
  );
}

export function ViewSelector(props: ViewSelectorProps): JSX.Element {
  const { moduleKey, allowedViewTypes, activeViewType } = props;

  return (
    <StyledViewSelector data-cy="view-selector">
      <SegmentedControl>
        {allowedViewTypes.map(viewType => (
          <ViewTypeButton
            active={viewType === activeViewType}
            moduleKey={moduleKey}
            viewType={viewType}
            key={viewType}
          />
        ))}
      </SegmentedControl>
    </StyledViewSelector>
  );
}
