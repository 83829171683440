import Select, { components, OptionProps } from 'react-select';
import styled, { css } from 'styled-components';
import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { Text, TextSize } from '../text';
import { BaseMultiSelectStyles, Control } from './base-styles';

const StyledUsersSelect = styled(Select)`
  ${BaseMultiSelectStyles}
`;

const OptionLayout = styled.div<{ readonly $isFocused?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px;
  column-gap: 8px;
  max-width: 100%;

  ${({ $isFocused }) =>
    $isFocused &&
    css`
      background-color: rgb(222, 235, 255);
      cursor: pointer;
    `};
`;

const AvatarWrapper = styled.div`
  flex-shrink: 0;
  width: 36px;
  @media (min-width: 768px) {
    width: 60px;
  }
`;

const UserDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  flex-shrink: 1;
  overflow: hidden;
`;

const RoleWrapper = styled.div`
  margin-left: auto;

  @media (min-width: 768px) {
    margin-left: unset;
  }
`;

const Option = (props: OptionProps<UsersSelectOption>) => {
  const { data, isFocused, innerProps } = props;

  return (
    <OptionLayout
      data-cy="user-select-option"
      {...innerProps}
      $isFocused={isFocused}
      ref={props.innerRef}
    >
      <AvatarWrapper>{data.avatar}</AvatarWrapper>
      <UserDataContainer>
        <Text size={TextSize.s14} ellipsis>
          {data.label}
        </Text>
        <Text size={TextSize.s12} ellipsis>
          {data.email}
        </Text>
      </UserDataContainer>
      <RoleWrapper>{data.role}</RoleWrapper>
    </OptionLayout>
  );
};

export interface UsersSelectOption {
  label: string;
  value: number;
  role: JSX.Element;
  avatar: JSX.Element;
  email: string;
}

export interface FilterOptionOption<Option> {
  readonly label: string;
  readonly value: string;
  readonly data: Option;
}

const StyledMenuPortal = styled(components.MenuPortal)`
  z-index: 5000 !important;
  box-sizing: border-box;
  font-family: Ubuntu;

  .users-select__menu-notice {
    font-size: 14px;
  }
`;

const customComponents = {
  Control,
  Option,
  MenuPortal: StyledMenuPortal
};
interface UsersSelectProps {
  autoFocus?: boolean;
  options: UsersSelectOption[];
  placeholder: string;
  onChange(userIds: UsersSelectOption[]): void;
  handleSubmit?(): void;
  filterOption(
    filterCandidate: FilterOptionOption<UsersSelectOption>,
    input: string
  ): boolean;
  noOptionsMessage?: string;
}
export function UsersSelect({
  handleSubmit,
  onChange,
  autoFocus,
  filterOption,
  placeholder,
  options,
  noOptionsMessage
}: UsersSelectProps): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const selectRef = useRef(null);

  const onKeyDown = useCallback(
    (evt: React.KeyboardEvent) => {
      if (!isMenuOpen && evt.key === 'Enter' && handleSubmit) {
        handleSubmit();
      }
      if (evt.key === 'Escape' && isMenuOpen) {
        evt.stopPropagation();
      }
    },
    [isMenuOpen, handleSubmit]
  );

  return (
    <div
      data-cy="user-select"
      className="user-select"
      aria-expanded={isMenuOpen}
    >
      <StyledUsersSelect
        ref={selectRef}
        autoFocus={autoFocus}
        classNamePrefix="users-select"
        minMenuHeight={200}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        onKeyDown={onKeyDown}
        onChange={onChange as any}
        components={customComponents as any}
        options={options}
        filterOption={filterOption as any}
        placeholder={placeholder}
        isMulti
        noOptionsMessage={() => noOptionsMessage}
        openMenuOnFocus
      />
    </div>
  );
}
