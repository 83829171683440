import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { Export } from '../export/export';
import { useLocale } from '../intl/hooks';
import { ExportItem } from '../export/types';
import { useRootStore } from '../app/root-store-context';
import { useIntl } from 'react-intl';
import { ExportReportingPageToPdfRequestParams } from '../export/api/export-reporting-page-to-pdf';
import { useReportingStore } from './hooks/use-reporting-store';

export const ExportReportingPageButton = observer(
  function ExportReportingPage(): JSX.Element | null {
    const exportService = useRootStore().exportService;
    const reportingStore = useReportingStore();
    const language = useLocale();
    const intl = useIntl();
    const reportingPage = reportingStore.currentPage;

    const fileName = reportingPage?.title
      ? `${intl.formatMessage({
          id: reportingPage?.title
        })}`
      : 'report';

    const exportToPdf = useCallback(async () => {
      const reportPageCode = reportingStore.reportingPageCode;
      if (!reportPageCode) return;
      const params: ExportReportingPageToPdfRequestParams = {
        language,
        reportPageCode,
        filters: reportingStore.dataStore?.apiFilters || {}
      };

      return exportService.exportReportingPageToPdf(params, fileName);
    }, [reportingStore, language, exportService, fileName]);

    const exportItems: ExportItem[] = useMemo(
      () => [{ format: 'pdf', doExport: exportToPdf }],
      [exportToPdf]
    );

    return reportingPage ? <Export exportItems={exportItems} /> : null;
  }
);
