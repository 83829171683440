import 'styled-components';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { PropsWithChildren, useMemo } from 'react';
import { ThemeDefinition } from './theme-definition';
import { legacy } from './legacy';
import { main } from './main';

const themes = {
  main,
  legacy
};

function getTheme(theme: keyof typeof themes): ThemeDefinition {
  return themes[theme];
}

type ThemeProviderProps = PropsWithChildren<{
  readonly theme: keyof typeof themes;
  readonly dark?: boolean;
}>;
export function ThemeProvider({
  theme,
  dark,
  children
}: ThemeProviderProps): JSX.Element {
  const themeDefinition = useMemo(
    () => ({ ...getTheme(theme), dark }),
    [theme, dark]
  );

  return (
    <StyledComponentsThemeProvider theme={themeDefinition}>
      {children}
    </StyledComponentsThemeProvider>
  );
}
