import { StackedBarChart } from '../../types';
import { groupStackedBarsByCategories } from '../../utils/group-stacked-bars-by-categories';
import { Text } from '@visx/text';
import { ArrayElement, DataItem } from '../../../reporting/types';
import { shouldShowBarStackLabel } from '../../utils/should-show-bar-stack-label';
import { LABEL_FONT_SIZE } from '../../../reporting/const';
import { getBarLabelOffset } from '../../utils/get-bar-label-offset';

interface StackedBarRectLabelProps {
  readonly bar: ArrayElement<
    ArrayElement<ReturnType<typeof groupStackedBarsByCategories>>
  >;
  readonly config: StackedBarChart;
  readonly item: DataItem;
  readonly barHeight: number;
  readonly barY: number;
}
export function StackedBarRectLabel({
  bar,
  config,
  item,
  barHeight,
  barY
}: StackedBarRectLabelProps) {
  const labelText = config.formatLabelValue?.(
    (bar.bar.data[bar.key] as number | undefined) || 0,
    bar.key
  ) as string;

  if (
    !shouldShowBarStackLabel(
      config.getShowLabels(bar.bar.data, bar.key),
      config.labelPosition || 'inside-top',
      barHeight
    )
  ) {
    return null;
  }

  return (
    <Text
      y={
        barY -
        getBarLabelOffset(barHeight, 1, config.labelPosition ?? 'inside-top')
      }
      x={bar.x + bar.width / 2}
      width={bar.width}
      fill={config.getLabelColor(item, bar.key) || '#4a4a4a'}
      fontSize={LABEL_FONT_SIZE}
      verticalAnchor="middle"
      textAnchor="middle"
      scaleToFit="shrink-only"
    >
      {labelText}
    </Text>
  );
}
