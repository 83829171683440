import { createElement } from 'react';

export function LetterCase() {
  return createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      className: 'icon icon-tabler icon-tabler-letter-case',
      viewBox: '0 0 24 24',
      strokeWidth: '1.5',
      stroke: 'currentColor',
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      role: 'img'
    },
    createElement('path', { d: 'M0 0h24v24H0z', stroke: 'none' }),
    createElement('path', {
      d: 'M14 15.5a3.5 3.5 0 1 0 7 0 3.5 3.5 0 1 0-7 0M3 19V8.5a3.5 3.5 0 0 1 7 0V19M3 13h7M21 12v7'
    })
  );
}
