import moment from 'moment';

import { WORKOUT_URL_PARAM } from './types';

export function formatSeconds(
  seconds: number,
  whiteSpace = `\u00A0\u00A0\u00A0`
): string {
  return `${moment.duration(seconds, 'seconds').format('h:mm', {
    trim: false
  })}${whiteSpace}`;
}

export function formatSecondsWithWhitespaceHack(seconds: number): string {
  return formatSeconds(seconds);
}

export function formatSecondsWithUnit(seconds: number): string {
  return moment.duration(seconds, 'seconds').format('h:mm:ss', {
    trim: false
  });
}

function formatDistanceWithoutUnit(meters: number): string {
  return (meters / 1000).toLocaleString('cs', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
}

export function formatDistance(meters: number): string {
  return `${formatDistanceWithoutUnit(meters)}\u00A0\u00A0\u00A0`;
}

export function formatDistanceWithUnit(meters: number): string {
  return `${formatDistanceWithoutUnit(meters)}\u00A0KM`;
}

export function formatAscentWithUnit(meters: number): string {
  return `${meters.toLocaleString('cs', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  })}\u00A0M`;
}

export function sortHeartRateZones<T extends { bottom: number }>(
  a: T,
  b: T
): number {
  return b.bottom - a.bottom;
}

export function formatZonesDuration(duration: number): string {
  return new Date(duration * 1000).toISOString().substr(11, 8);
}

export function getWorkoutLink(workoutId: number | null): string {
  const url = new URL(window.location.href, window.location.origin);

  if (workoutId) {
    url.searchParams.set(WORKOUT_URL_PARAM, String(workoutId));
  } else {
    url.searchParams.delete(WORKOUT_URL_PARAM);
  }

  return `${url.pathname}${url.search}`;
}
