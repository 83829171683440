import {
  getHorizontalBarLabelTextAnchor,
  getBarStackLabelX,
  HorizontalStackedBarChart,
  shouldShowBarLabelHorizontal
} from './utils';
import { ScaleTypeToD3Scale } from '@visx/scale';
import { BarStackHorizontal } from '@visx/shape';
import { Fragment } from 'react';

import styled from 'styled-components';
import { Text } from '@visx/text';
import { ArrayElement, DataItem } from '../reporting/types';
import { GroupBy } from '../helpers/group-by';
import { BarStack } from '@visx/shape/lib/types';
import { getSSRStringWidth } from '../reporting/utils/get-ssr-string-width';
import { LABEL_FONT_SIZE } from '../reporting/const';
import { getBarRadius } from '../reporting/utils/get-bar-radius';

export interface BarStackProps<T extends DataItem> {
  config: HorizontalStackedBarChart<T>;
  xScale: ScaleTypeToD3Scale<number>['linear'];
  yScale: ScaleTypeToD3Scale<string, string>['band'];
  data: T[];
  xMax: number;
}

function groupBarsByCategories(barStacks: BarStack<DataItem, string>[]) {
  return Array.from(
    GroupBy(barStacks.map(barStack => barStack.bars).flat(), 'y').entries()
  ).map(([, categoryBars]) => categoryBars);
}

const StyledRect = styled.rect`
  pointer-events: none;
`;

const noop = () => '';

function RectLabel<T extends DataItem>(props: {
  bar: ArrayElement<ArrayElement<ReturnType<typeof groupBarsByCategories>>>;
  config: HorizontalStackedBarChart<T>;
  data: T[];
}): JSX.Element | null {
  const { bar, config, data } = props;
  const labelText = config.formatLabelValue?.(
    ((bar.bar.data as T)[bar.key] as number | undefined) || 0,
    bar.key
  ) as string;
  const labelTextWidth = getSSRStringWidth(String(labelText));
  if (
    !shouldShowBarLabelHorizontal(
      config.getShowLabels(bar.bar.data as T),
      config.labelPosition || 'inside-top',
      bar.width,
      bar.height,
      labelTextWidth
    )
  ) {
    return null;
  }

  const showCategoryGaps = config.showCategoryGaps;

  return (
    <Text
      x={getBarStackLabelX(
        showCategoryGaps ? bar.width - 2 : bar.width,
        showCategoryGaps ? bar.x + 1 : bar.x,
        config.labelPosition || 'inside-top',
        labelTextWidth
      )}
      y={bar.y + bar.height / 2}
      width={bar.width}
      height={bar.height}
      fill={config.getLabelColor(data[bar.index], bar.key) || '#4a4a4a'}
      fontSize={LABEL_FONT_SIZE}
      textAnchor={getHorizontalBarLabelTextAnchor(
        config.labelPosition || 'inside-top',
        undefined,
        labelTextWidth,
        bar.width
      )}
      verticalAnchor="middle"
    >
      {labelText}
    </Text>
  );
}

export function HorizontalBarStack<T extends DataItem>(
  props: BarStackProps<T>
): JSX.Element {
  const { data, config, xScale, yScale, xMax } = props;
  const showCategoryGaps = config.showCategoryGaps;

  return (
    <BarStackHorizontal<T, string>
      data={data}
      keys={config.keys}
      y={config.getYValue}
      xScale={xScale}
      yScale={yScale}
      color={noop}
      width={xMax}
    >
      {barStacks =>
        groupBarsByCategories(barStacks)
          .map(barStack => barStack.filter(bar => bar.width))
          .filter(bars => bars.length)
          .map((bars, categoryIndex) => {
            const maskId = `${config.code}_cat_${categoryIndex}`;
            const firstBar = bars[0];
            const maskWidth = bars.reduce((sum, bar) => {
              sum = bar.width + sum;
              return sum;
            }, 0);

            return (
              <g key={`stack-${categoryIndex}`}>
                <mask id={maskId}>
                  <rect
                    x={firstBar?.x}
                    y={firstBar?.y}
                    width={maskWidth}
                    height={firstBar.height}
                    rx={getBarRadius(firstBar.height)}
                    fill="white"
                  />
                </mask>
                <g mask={`url(#${maskId})`}>
                  {bars.map((bar, barIndex) => (
                    <Fragment
                      key={`cat-barstack-horizontal-${categoryIndex}-${barIndex}`}
                    >
                      <StyledRect
                        x={showCategoryGaps ? bar.x + 1 : bar.x}
                        y={bar.y}
                        width={showCategoryGaps ? bar.width - 2 : bar.width}
                        height={bar.height}
                        fill={config.getColor(data[bar.index], bar.key)}
                        opacity={config.getOpacity(data[bar.index], bar.key)}
                        rx={
                          showCategoryGaps
                            ? getBarRadius(bar.height)
                            : undefined
                        }
                      />
                      <RectLabel bar={bar} config={config} data={data} />
                    </Fragment>
                  ))}
                </g>
              </g>
            );
          })
      }
    </BarStackHorizontal>
  );
}
