import { LayerComponentProps } from './types';
import { FullscreenLayer as FullscreenLayerComponent } from '@yarmill/components';
import { LayerContent } from './layer-content';

export function FullScreenLayer(props: LayerComponentProps): JSX.Element {
  const { layer, active, ...ownProps } = props;

  return (
    <FullscreenLayerComponent
      {
        ...ownProps /* Used to pass through the animation props */
      }
      active={Boolean(active)}
    >
      <LayerContent layer={layer} />
    </FullscreenLayerComponent>
  );
}
