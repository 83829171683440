import { Group } from '@visx/group';
import { useXYChartContext } from '../xy-chart-context';
import { Bar } from '@visx/shape';
import { styled } from '../../theme-provider';
import { localPoint } from '@visx/event';
import { TooltipData } from '../../reporting/types';
import { useRef, MouseEvent, useCallback } from 'react';
import { TOOLTIP_TIMEOUT } from '../../reporting/const';
import { useTooltipContext } from '../hooks/use-tooltip';

const TooltipBar = styled(Bar)`
  fill: transparent;
  :hover {
    fill: rgba(0, 0, 0, 0.05);
  }
`;

export function CategoricalTooltip() {
  const { xScale, chartRect } = useXYChartContext();

  if (!('bandwidth' in xScale)) {
    throw new Error('Categorical tooltip requires categorical scale');
  }

  const { showTooltip, hideTooltip, getTooltipData } = useTooltipContext();
  const tooltipTimeout = useRef<number>(0);

  const handleMouseLeave = useCallback(() => {
    tooltipTimeout.current = window.setTimeout(
      () => hideTooltip(),
      TOOLTIP_TIMEOUT
    );
  }, [hideTooltip]);

  const bandwidth = xScale.bandwidth();
  const step = xScale.step();
  const categories = xScale.domain();

  return (
    <Group>
      {categories.map(category => {
        const barX = (xScale(category) || 0) - (step - bandwidth) / 2;

        return (
          <TooltipBar
            key={category}
            width={step}
            y={chartRect.top}
            x={barX}
            height={chartRect.bottom - chartRect.top}
            onMouseMove={(event: MouseEvent) => {
              if (tooltipTimeout.current) {
                clearTimeout(tooltipTimeout.current);
              }
              const mousePosition = localPoint(event);
              const left = mousePosition?.x;
              const tooltipData: TooltipData = getTooltipData(category);
              const tooltipTop = mousePosition?.y;

              showTooltip({
                tooltipData,
                tooltipTop,
                tooltipLeft: left
              });
            }}
            onMouseLeave={handleMouseLeave}
          />
        );
      })}
    </Group>
  );
}
